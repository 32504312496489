import React from "react";
import EditCredentialComponent from "./EditCredentialComponent";
const StaffCredentialSetup = () => {
  return (
    <EditCredentialComponent
      accountType={3}
      title=""
      showActivateButton={false}
    />
  );
};

export default StaffCredentialSetup;
