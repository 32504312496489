/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { Grid } from "@mui/material";
import Card from "../../Dashboard/Card";
import api from "../../../services/ControlPanel";
import { useSelector } from "react-redux";
import Loader from "../../pageLoader/loader";
// import { FaChalkboardTeacher } from "react-icons/fa";
// import { MdOutlineCalendarToday } from "react-icons/md";
// import { VscFeedback } from "react-icons/vsc";
// import { BsFillHouseDoorFill } from "react-icons/bs";
// import { RiParentFill } from "react-icons/ri";
import { IoMdStats } from "react-icons/io";
import { MenuDiv } from "../../../pages/Dashboard/styles/dashboard.style";
import { BigText } from "../../../pages/auth/Login.style";
const Stats = ({ loading, stats, arr, toggleCard }) => {
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <MenuDiv>
        {stats.length > 0 &&
          stats.map((item, index) => (
            <div key={index} style={{ padding: "10px 20px" }}>
              <BigText
                style={{
                  fontSize: "23px",
                  color: "#671e78",
                  lineHeight: "15px",
                }}
              >
                {item.categoryName}
              </BigText>
              {item.categoryItems.length > 0 &&
                item.categoryItems.map((i, index) => (
                  <div key={index}>
                    <BigText style={{ fontSize: "19px", lineHeight: "15px" }}>
                      Department: {i.departmentName}
                    </BigText>

                    <Grid container spacing={2} padding={3}>
                      {i.items.length > 0 ? (
                        i.items.map((i, index) => (
                          <Grid item lg={4} sm={6} xs={12} key={index}>
                            <Card
                              height={"90px"}
                              ta="end"
                              jc="space-between"
                              showCheckIcon={true}
                              label={i.name}
                              // isChecked={a}
                              isChecked={arr.includes(String(i.id))}
                              icon=<IoMdStats />
                              onClick={() => toggleCard(i.id)}
                            />
                          </Grid>
                        ))
                      ) : (
                        <>No Stats Cards At The Moment</>
                      )}
                    </Grid>
                  </div>
                ))}
            </div>
          ))}
      </MenuDiv>
      {/* <MenuDiv>
        <Grid container spacing={2} padding={3}>
          {stats.length > 0 ? (
            stats.map((item, index) => (
              <Grid item lg={4} sm={6} xs={12} key={index}>
                <Card
                  height={"90px"}
                  ta="end"
                  jc="space-between"
                  showCheckIcon={true}
                  label={item.name}
                  // isChecked={a}
                  isChecked={arr.includes(String(item.id))}
                  icon=<IoMdStats />
                  onClick={() => toggleCard(item.id)}
                />
              </Grid>
            ))
          ) : (
            <>No Stats Cards At The Moment</>
          )}
        </Grid>
      </MenuDiv> */}
    </>
  );
};

export default Stats;
