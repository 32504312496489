import React from "react";
import classes from "./Switch.module.css";
import PropTypes from "prop-types";

const Switch = ({ onChange, value, disabled }) => {
  return (
    <input
      type="checkbox"
      className={classes.switch}
      checked={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

Switch.propTypes = {
  disabled: PropTypes.bool,
};

Switch.defaultProps = {
  disabled: false,
};

export default Switch;
