export default function LoginIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="feather feather-log-in"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: "2px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    >
      <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
      <polyline points="10 17 15 12 10 7"></polyline>
      <line x1="15" y1="12" x2="3" y2="12"></line>
    </svg>
  );
}
