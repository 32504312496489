import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import {
  BigHeader,
  Container,
  SmallHeader,
} from "../Dashboard/styles/dashboard.style";
import GlobalStyles from "../../assets/globalStyle";
import { TabRouteContainer, TabRoute } from "../student_mgt/stu_mgt.style";
import Title from "../../components/Dashboard/Title";

const Library = () => {
  const location = useLocation();
  return (
    <Container>
      <GlobalStyles />
      <div style={{marginBottom:'30px'}}>
        <Title title="Library Management" />
        {/* <SmallHeader>Hi there! Welcome</SmallHeader>
        <BigHeader></BigHeader> */}
      </div>
      <TabRouteContainer >
        <TabRoute isActive={location.pathname.includes("books")}>
          <Link to="/library/books">Book Setup</Link>
        </TabRoute>
        <TabRoute isActive={location.pathname.includes("book-lending")}>
          <Link to="/library/book-lending">Book Lending</Link>
        </TabRoute>
        <TabRoute isActive={location.pathname.includes("library-settings")}>
          <Link to="/library/library-settings">Library Setup</Link>
        </TabRoute>
        {/* </TabRoute> */}
      </TabRouteContainer>
      <Outlet />
    </Container>
  );
};

export default Library;
