import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import { BsPeople, BsReceipt } from "react-icons/bs";
import { MdOutlineReceiptLong } from "react-icons/md";
import CreditCardIcon from '@mui/icons-material/CreditCard';


export const PDNavData=[
    {
            id: 1,
            label: "Profile",
            link: "/parentmanagement/profile",
            icon: < PermIdentityIcon/>,
            badge:null
          
    },
    {
            id: 2,
            label: "Admission",
            link: "/parentmanagement/admission",
            icon: < SchoolOutlinedIcon/>,
            badge:5
          
    },
    {
            id: 3,
            label: "Student",
            link: "/parentmanagement/student",
            icon: < BsPeople/>,
            badge:7
          
    },
    {
        
            id: 4,
            label: "Online Payment",
            link: "/parentmanagement/onlinepayment",
            icon: < CreditCardIcon/>,
            badge:null
          
    },
    {
        
            id: 5,
            label: "Invoices",
            link: "/parentmanagement/invoices",
            icon: < MdOutlineReceiptLong/>,
            badge:2
          
    },
    {
        
            id: 6,
            label: "Receipts",
            link: "/parentmanagement/reciepts",
            icon: < BsReceipt/>,
            badge:2
          
    },
]