/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
const endpoint_one = process.env.REACT_APP_API_ENDPOINT_ONE;
const api_version = process.env.REACT_APP_API_VERSION;

export default {
  getHostels: (token, schoolId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/Hostel/GetHostels?schoolId=${schoolId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getHostelFloors: (token, schoolId, hostelId) => {
    let url = `${endpoint_two}/api/v${api_version}/Hostel/GetHostelFloors?schoolId=${schoolId}`;
    if (hostelId !== undefined && hostelId !== null) {
      url += `&hostelId=${hostelId}`;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);
  },
  getRooms: (token, schoolId, hostelId, floorId) => {
    let url = `${endpoint_two}/api/v${api_version}/Hostel/GetRooms?schoolId=${schoolId}`;
    if (floorId !== undefined && floorId !== null) {
      url += `&floorId=${floorId}`;
    }
    if (hostelId !== undefined && hostelId !== null) {
      url += `&hostelId=${hostelId}`;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);
  },
  getBeds: (token, schoolId, hostelId, roomId) => {
    let url = `${endpoint_two}/api/v${api_version}/Hostel/GetBeds?schoolId=${schoolId}`;
    if (hostelId !== undefined && hostelId !== null) {
      url += `&hostelId=${hostelId}`;
    }
    if (roomId !== undefined && roomId !== null) {
      url += `&roomId=${roomId}`;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);
  },
  allocateHostel: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/Hostel/update-student-hostel-allocation`,
        data,
        {
          headers: {
            Authorization: `Bearer  ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getManagers: (token) =>
    axios
      .get(`${endpoint_two}/api/v${api_version}/Hostel/GetManagers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response),
  getOccupants: (token, hostelId, floorId, roomId, sessionId, schoolId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/Hostel/GetHostelOccupants?hostelId=${hostelId}&floorId=${floorId}&roomId=${roomId}&sessionId=${sessionId}&schoolId=${schoolId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  createHouse: (token, data) =>
    axios
      .post(`${endpoint_two}/api/v${api_version}/Hostel/CreateHostel`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response),
  EditHouse: (token, data) =>
    axios
      .put(`${endpoint_two}/api/v${api_version}/Hostel/UpdateHostel`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response),
  createHostelFloor: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/Hostel/CreateHostelfloor`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  editHostelFloor: (token, data) =>
    axios
      .put(
        `${endpoint_two}/api/v${api_version}/Hostel/UpdateHostelfloor`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  deleteFloor: (token, floorId) =>
    axios
      .delete(
        `${endpoint_two}/api/v${api_version}/Hostel/DeleteHostelfloor?floorId=${floorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  createHostelRoom: (token, data) =>
    axios
      .post(
        `${endpoint_two}/api/v${api_version}/Hostel/CreateHostelroom`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  editHostelRoom: (token, data) =>
    axios
      .put(
        `${endpoint_two}/api/v${api_version}/Hostel/UpdateHostelRoom`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  deleteRoom: (token, roomId) =>
    axios
      .delete(
        `${endpoint_two}/api/v${api_version}/Hostel/DeleteHostelRoom?roomId=${roomId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  addBedSpace: (token, data) =>
    axios
      .post(`${endpoint_two}/api/v${api_version}/Hostel/AddbedSpace`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response),
  editBedSpace: (token, data) =>
    axios
      .put(`${endpoint_two}/api/v${api_version}/Hostel/Update-BedSpace`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response),
  deleteBedSpace: (token, bedId) =>
    axios
      .delete(
        `${endpoint_two}/api/v${api_version}/Hostel/DeleteBedSpace?bedId=${bedId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
};
