import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "./baseQuery";
import { API } from "./constant";

export const AssignmentApi = createApi({
  reducerPath: "assignment",
  baseQuery,
  endpoints: (builder) => ({
    getAllAssignments: builder.query({
      query: ({ ...values }) => ({
        url: API.GET_ASSIGNMENTS,
        params: { ...values },
        method: "GET",
      }),
      providesTags: (result, error, arg) =>
        result ? [...(result?.data ?? []), "assignments"] : ["assignments"],
    }),
    deleteAssignments: builder.mutation({
      query: ({ ...values }) => ({
        url: API.DELETE_ASSIGNMENT,
        params: { ...values },
        method: "DELETE",
      }),
      invalidatesTags: ["assignments"],
    }),
  }),
});

export const { useGetAllAssignmentsQuery, useDeleteAssignmentsMutation } =
  AssignmentApi;
