import React, { useState, useEffect } from "react";
import {
  AuthWrapper,
  AuthLeftWrapper,
  LeftWrapperContent,
  LogoWrapper,
  HeaderWrapper,
  BigText,
  MediumText,
  SmallText,
  InputWrapper,
  AuthButton,
  ButtonFlex,
} from "./auth.style";
import { useSelector, useDispatch } from "react-redux";
import colors from "../../assets/colors.json";
import GlobalStyles from "../../assets/globalStyle";
import LoginIcon from "../../assets/icons/Login";
import { useNavigate, Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import ReactCodeInput from "react-code-input";
import logo from "../../assets/school-logo.png";
import "../../assets/style.css";
import "react-phone-input-2/lib/style.css";
import HomeIcon from "../../assets/icons/home";
import RightSide from "../../components/RightSide";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import FormLoader from "../../components/FormLoader";
import {
  adminLogin,
  resetAuthProcess,
  logout,
} from "../../features/auth/authSlice";
import ErrorService from "../../services/errorService";
const Login = () => {
  const [code, setCode] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const navigate = useNavigate();
  const { admPhone, loginToken, admAccountType } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const onFormButton = async (e) => {
    e.preventDefault();
    onFormSubmit();
  };
  const onFormSubmit = async () => {
    setFormLoading(true);
    try {
      let data = {
        phoneNumber: admPhone,
        pin: code,
        browserToken: loginToken,
        accountType: admAccountType,
      };
      let { payload } = await dispatch(adminLogin(data));
      // // track 401 error or Incorrect password
      if (payload.status === 401 && payload.data.statusCode === 53) {
        setFormLoading(false);
        ErrorService.displayErrorAlert(payload);
      }
      if (payload.status === 401 && payload.data.statusCode === 54) {
        setFormLoading(false);
        ErrorService.displayErrorAlert(payload);
        dispatch(resetAuthProcess());
        return navigate("/verify");
      }
      // Browser Changed
      if (payload.status === 500 && payload.data.statusCode === 52) {
        setFormLoading(false);
        ErrorService.displayErrorAlert(payload);
        dispatch(resetAuthProcess());
        return navigate("/verify");
      }

      if (payload.status === true && payload.data.isLogin === true) {
        localStorage.setItem("admToken", payload.data.authToken);
        localStorage.setItem(
          "user",
          JSON.stringify(payload.data.accountDetails)
        );
        localStorage.setItem(
          "accessInstance",
          JSON.stringify(payload.data.accountAccess)
        );
        localStorage.setItem("admAuthStatus", payload.data.isLogin);
        setFormLoading(false);
        // toast.success(payload.message);
        toast.success("User Logged In Successfully");
        navigate("/dashboard");
      } else {
        setFormLoading(false);
        ErrorService.displayErrorAlert(payload);
      }
    } catch (error) {
      toast.error("An Error Occured Try Again Later");
      setFormLoading(false);
    }
  };
  useEffect(() => {
    if (code.length === 6) {
      onFormSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  useEffect(() => {
    if (admAccountType === "" || loginToken === "" || admPhone === "") {
      navigate("/verify");
    }
  }, [admAccountType, admPhone, loginToken, navigate]);

  return (
    <AuthWrapper>
      <GlobalStyles />
      <AuthLeftWrapper>
        <LeftWrapperContent>
          <LogoWrapper>
            <img src={logo} alt="" />
            <div>
              <p>Lagos Anglican Schools</p>
              <p>Management Board</p>
            </div>
          </LogoWrapper>
          <HeaderWrapper>
            <BigText>Admin Portal Login</BigText>
            <MediumText>
              Welcome back!. Log in with your credentials.
            </MediumText>
          </HeaderWrapper>

          <form
            onSubmit={(e) => onFormButton(e)}
            style={{
              marginTop: "20px",
            }}
          >
            <InputWrapper>
              <label htmlFor="phone number">Phone Number</label>
              <PhoneInput
                disabled={true}
                inputClass={"phone-input"}
                buttonClass={"phone-btn"}
                country={"ng"}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                value={"234" + admPhone.slice(1)}
                onChange={(phone, country) => {
                  let input = phone;
                  if (country.countryCode === "ng") {
                    if (input.slice(0, 3) === "234") {
                      const a = input.slice(3);
                      let b = a[0];
                      if (b !== "0") {
                        b = `0${a}`;
                      } else {
                        input = a;
                      }
                    }
                  }
                }}
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="">Pin</label>
              <ReactCodeInput
                disabled={formLoading}
                type="password"
                className="code-input"
                fields={6}
                value={code}
                onChange={(e) => {
                  setCode(e);
                }}
              />
            </InputWrapper>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              {" "}
              <SmallText
                style={{
                  textAlign: "right",
                }}
              >
                <Link
                  to="/resetpassword"
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Forgot Password?
                </Link>
              </SmallText>
            </div>

            <ButtonFlex>
              <AuthButton
                as="a"
                href="https://lasmab.org/"
                width={isMobile ? "100%" : "35%"}
                color={colors.primary}
                bg="white"
              >
                {" "}
                <div>
                  <HomeIcon />
                  <span>Back To Home</span>
                </div>
              </AuthButton>

              <AuthButton
                disabled={formLoading || code.length < 6}
                width={isMobile ? "100%" : "60%"}
              >
                {formLoading ? (
                  <FormLoader />
                ) : (
                  <div>
                    <LoginIcon />
                    <span>Login</span>
                  </div>
                )}
              </AuthButton>
            </ButtonFlex>
          </form>
          <SmallText
            style={{
              textAlign: "center",
            }}
          >
            Having Problem With Your Account?{" "}
            <span
              style={{
                fontWeight: "bold",
                color: "black",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(logout());
                dispatch(resetAuthProcess());
                navigate("/verify");
              }}
            >
              RESET NOW
            </span>
          </SmallText>
        </LeftWrapperContent>
      </AuthLeftWrapper>
      <RightSide />
    </AuthWrapper>
  );
};

export default Login;
