import React, { useState } from "react";
import { SiAdobeacrobatreader, SiAdobe } from "react-icons/si";
import { BsSearch, BsFillPrinterFill } from "react-icons/bs";
import { IconButton, MenuItem, MenuList } from "@mui/material";
import Pagination from "../pagination_one/pagination";
import colors from "../../assets/colors.json";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "../../assets/Images/avatar.jpg";
import { useMediaQuery } from "react-responsive";
import { AuthButton } from "../../pages/auth/Login.style";
import { Box } from "@mui/system";
import Popover from "@mui/material/Popover";
import { AiFillFileExcel } from "react-icons/ai";

import { ImgTextDiv, StatusBar } from "../../pages/student_mgt/stu_mgt.style";
import {
  Icon,
  Input,
  TabContainer,
  TabLinkContainer,
  Search,
  AccountSummaryWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
import { useSearchParams, useLocation } from "react-router-dom";
const AdmissionList = () => {
  const [pageCount, setPageCount] = useState(50);
  const [currentPage, setCurrentPage] = useState(2);
  const [showForm, setShowForm] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isTab = useMediaQuery({ maxWidth: 992 });
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [searchParams, setSearchParams] = useSearchParams();
  const page_id = Number(new URLSearchParams(location.search).get("page")) || 1;
  const parent_id = new URLSearchParams(location.search).get("parent_id");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      <TabContainer>
        <TabLinkContainer
          bg="#F0E8F1"
          style={{
            position: "relative",
            borderRadius: "20px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
          }}
        >
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search " />
          </Input>
          <div style={{ display: "none" }} className="div">
            <AiFillFileExcel
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
            <SiAdobeacrobatreader
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
            <BsFillPrinterFill
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
          </div>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table>
            <tr
              className="table-head"
              style={{ backgroundColor: "#F0E8F1", color: "black" }}
            >
              <th>Admission ID</th>
              <th>Date</th>
              <th>School</th>

              <th>Status</th>
              <th></th>
            </tr>
            <tr>
              <td>12332</td>
              <td>20/07/2022</td>
              <td>Anglican School </td>
              <td>
                <StatusBar status="saved">Saved</StatusBar>
              </td>
              <td>
                <button
                  style={{
                    borderRadius: "10px",
                    color: colors.primary,
                    border: `1px solid ${colors.secondary}`,
                    padding: "0.4rem 0.4rem",
                  }}
                  onClick={() => {
                    setSearchParams({
                      ...searchParams,
                      page: page_id,
                      parent_id: parent_id,
                      admission_id: "jdjdj",
                    });
                  }}
                >
                  View Application
                </button>
              </td>
            </tr>
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Pagination
          pageCount={pageCount > 1 && pageCount}
          range={1}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem>Details</MenuItem>
            {/* <MenuItem>Edit</MenuItem>
            <MenuItem>Delete</MenuItem> */}
          </MenuList>
        </Box>
      </Popover>
    </div>
  );
};

export default AdmissionList;
