import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import colors from "../../../assets/colors.json";
import { GiCancel, GiCheckMark } from "react-icons/gi";
import { ImCheckmark } from "react-icons/im";
import {
  SmallHeader,
  SubMenuDiv,
  MenuDiv,
} from "../../../pages/Dashboard/styles/dashboard.style";
import Switch from "../../ToogleSwitch/Switch";
import Loader from "../../pageLoader/loader";
import PropTypes from "prop-types";

const MenuListComp = ({
  loading,
  stats,
  arr,
  toggleCard,
  selectAll,
  name,
  showToggleAll,
  selectedSchools,
  unSelectedSchools,
  addDelSchool,
  showNewForm,
  addDelDepart,
  selectedDepartments,
  unSelectedDepartments,
  assignDefault,
}) => {
  const isTab = useMediaQuery({ maxWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 768 });
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <MenuDiv
        minH="max-content"
        style={{ padding: isTab ? "0.6rem" : "0.8rem" }}
      >
        <div
          style={{
            width: isTab ? "100%" : "75%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: colors.secondary,
            borderRadius: "5px",
            padding: "0.7rem",
          }}
        >
          <SmallHeader style={{ fontWeight: "bold" }}>{name}</SmallHeader>
          {/* {showToggleAll && (
            <div
              style={{
                display: "inline-flex",
              }}
            >
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                <label
                  htmlFor=""
                  style={{
                    marginRight: "0.3rem",
                    fontWeight: "semibold",
                    fontSize: "13px",
                  }}
                >
                  {stats.length === arr.length ? "Remove All" : "Select All"}
                </label>

                <Switch
                  value={stats.length === arr.length}
                  onChange={() => selectAll()}
                />
              </div>
            </div>
          )} */}
        </div>
        {stats.length > 0 ? (
          stats.map((item, index) => (
            <div
              key={index}
              style={{
                border: `1px solid ${colors.primary}`,
                marginTop: "0.4rem",
                borderRadius: "10px",
              }}
            >
              <SubMenuDiv style={{ width: isTab ? "100%" : "80%" }}>
                {/* <div></div> */}
                <SmallHeader style={{ fontSize: "18px", fontWeight: "bold" }}>
                  {item.name}
                </SmallHeader>
                <Switch
                  value={arr.includes(String(item.id))}
                  onChange={() => toggleCard(item.id)}
                />
              </SubMenuDiv>
              <div style={{ paddingLeft: "0.8rem", paddingRight: "0.8rem" }}>
                {Array.isArray(item?.subMenus) &&
                  item?.subMenus.length > 0 &&
                  item.subMenus.map((i, iIndex) => (
                    <div key={iIndex}>
                      <SubMenuDiv
                        style={{
                          width: isTab ? "90%" : "70%",
                          // padding: "0.8rem",
                        }}
                      >
                        <div></div>
                        <SmallHeader
                          style={{ fontSize: "16px", fontWeight: "bold" }}
                        >
                          {i.name}
                        </SmallHeader>
                        <Switch
                          value={arr.includes(String(i.id))}
                          onChange={() => toggleCard(i.id)}
                        />
                      </SubMenuDiv>
                      <div
                        style={{
                          paddingLeft: "0.7rem",
                          paddingRight: "0.7rem",
                        }}
                      >
                        {i?.items &&
                          Array.isArray(i.items) &&
                          i.items.length > 0 &&
                          i.items.map((item, index) => (
                            <div key={index}>
                              <SubMenuDiv
                                style={{
                                  width: isTab ? "80%" : "60%",
                                  padding: "0.5rem 0.7rem",
                                }}
                              >
                                {/* <div></div> */}
                                <SmallHeader
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item.name}
                                </SmallHeader>
                                <Switch
                                  value={arr.includes(String(item.id))}
                                  onChange={() => toggleCard(item.id)}
                                />
                              </SubMenuDiv>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))
        ) : (
          <div>No {name} List Available </div>
        )}
      </MenuDiv>
      {showNewForm && (
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            padding: "0.2rem 0.3rem",
            justifyContent: "space-between",
          }}
        >
          {arr.includes("2") && (
            <div
              style={{
                width: isMobile ? "100%" : "47%",
                border: `1px solid ${colors.primary}`,
                borderRadius: "20px",
                padding: "0.5rem 0.6rem",
              }}
            >
              <SmallHeader>Select Schools</SmallHeader>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  flexWrap: "wrap",
                  height: "max-content",
                }}
              >
                {selectedSchools.map((item, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      style={{
                        marginTop: "0.8rem",
                        backgroundColor: colors.primary,
                        display: "flex",
                        alignItems: "center",
                        padding: "0.3rem 0.6rem",
                        borderRadius: "10px",
                        marginRight: "0.2rem",
                        flexWrap: "wrap",
                        borderColor: colors.primary,
                        cursor: "pointer",
                      }}
                      onClick={() => addDelSchool(item.schoolid, false)}
                    >
                      {item.defaultschool && (
                        <ImCheckmark
                          color="green"
                          style={{ marginRight: "0.3rem" }}
                        />
                      )}

                      <SmallHeader
                        style={{
                          color: "white",
                          marginRight: "0.2rem",
                          fontSize: isTab ? "12px" : "13px",
                        }}
                      >
                        {item.schoolname}
                      </SmallHeader>
                      <GiCancel
                        color="red"
                        style={{ marginLeft: "0.2rem", cursor: "pointer" }}
                        // onClick={() => addDelSchool(item.schoolid, false)}
                      />
                    </div>
                    {!item.defaultschool && (
                      <button
                        onClick={() => assignDefault(item.schoolid)}
                        style={{
                          marginTop: "0.8rem",
                          marginLeft: "0.4rem",
                          backgroundColor: colors.gray,
                          color: "white",
                          padding: "0.2rem 0.6rem",
                          borderRadius: "13px",
                        }}
                      >
                        Mark As Default
                      </button>
                    )}
                  </div>
                ))}
                {unSelectedSchools.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      marginTop: "0.8rem",
                      backgroundColor: "white",
                      display: "flex",
                      alignItems: "center",
                      padding: "0.3rem 0.6rem",
                      borderRadius: "10px",
                      marginRight: "0.2rem",
                      cursor: "pointer",

                      flexWrap: "wrap",
                      border: `1px solid ${colors.primary}`,
                    }}
                    onClick={() => addDelSchool(item.schoolid, true)}
                  >
                    <SmallHeader
                      style={{
                        color: colors.primary,
                        marginRight: "0.2rem",
                        fontSize: isTab ? "12px" : "13px",
                      }}
                    >
                      {item.schoolname}
                    </SmallHeader>
                    {/* <GiCancel
                  color="red"
                  style={{ marginLeft: "0.2rem", cursor: "pointer" }}
                  onClick={() => {}}
                /> */}
                  </div>
                ))}
              </div>
            </div>
          )}

          {arr.includes("3") && (
            <div
              style={{
                width: isMobile ? "100%" : "47%",
                border: `1px solid ${colors.primary}`,
                borderRadius: "20px",
                padding: "0.5rem 0.6rem",
              }}
            >
              <SmallHeader>Select Departments</SmallHeader>

              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  flexWrap: "wrap",
                  height: "max-content",
                }}
              >
                {selectedDepartments.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      marginTop: "0.8rem",
                      backgroundColor: colors.primary,
                      display: "flex",
                      alignItems: "center",
                      padding: "0.3rem 0.6rem",
                      borderRadius: "10px",
                      marginRight: "0.2rem",
                      flexWrap: "wrap",
                      borderColor: colors.primary,
                      cursor: "pointer",
                    }}
                    onClick={() => addDelDepart(item.departmentId, false)}
                  >
                    <SmallHeader
                      style={{
                        color: "white",
                        marginRight: "0.2rem",
                        fontSize: isTab ? "12px" : "13px",
                      }}
                    >
                      {item.departmentName}
                    </SmallHeader>
                    <GiCancel
                      color="red"
                      style={{ marginLeft: "0.2rem", cursor: "pointer" }}
                      // onClick={() => addDelDepart(item.departmentId, false)}
                    />
                  </div>
                ))}
                {unSelectedDepartments.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      marginTop: "0.8rem",
                      backgroundColor: "white",
                      display: "flex",
                      alignItems: "center",
                      padding: "0.3rem 0.6rem",
                      borderRadius: "10px",
                      marginRight: "0.2rem",
                      cursor: "pointer",

                      flexWrap: "wrap",
                      border: `1px solid ${colors.primary}`,
                    }}
                    onClick={() => addDelDepart(item.departmentId, true)}
                  >
                    <SmallHeader
                      style={{
                        color: colors.primary,
                        marginRight: "0.2rem",
                        fontSize: isTab ? "12px" : "13px",
                      }}
                    >
                      {item.departmentName}
                    </SmallHeader>
                    {/* <GiCancel
                  color="red"
                  style={{ marginLeft: "0.2rem", cursor: "pointer" }}
                  onClick={() => {}}
                /> */}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

MenuListComp.propTypes = {
  showToggleAll: PropTypes.bool,
  showNewForm: PropTypes.bool,
};

MenuListComp.defaultProps = {
  showToggleAll: true,
  showNewForm: false,
};

export default MenuListComp;
