import React from "react";
import EditCredentialComponent from "./EditCredentialComponent";

const StudentCredentialSetup = () => {
  return (
    <EditCredentialComponent accountType={1} title="Manage Student Account" />
  );
};

export default StudentCredentialSetup;
