/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import {
  BigHeader,
  Container,
  FormFlex,
  FormFlexDiv,
  TabContainer,
  TabContentWrapper,
  TabLink,
  TabLinkContainer,
} from "./styles/dashboard.style";
import { isNumber, isObjEmpty } from "../../services/utils";
import colors from "../../assets/colors.json";
import { toast } from "react-toastify";
import Modal from "../../components/modal/Modal";
import Select from "../../components/select/select";
import GlobalStyles from "../../assets/globalStyle";
import { AuthButton, InputWrapper } from "../auth/auth.style";
import { BsFillHouseDoorFill } from "react-icons/bs";
import Card from "../../components/Dashboard/Card";
import { Grid } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import DatePickerComponent from "../../components/datepicker/DatePicker";
import { getSchoolList, getSchools } from "../../services/MySchool";
import { useSelector } from "react-redux";
import ErrorService from "../../services/errorService";
import FormLoader from "../../components/FormLoader";
import {
  AddAssetCategory,
  AddAsset,
  GetAllCategory,
  GetSchoolAsset,
  DeleteAssetCategory,
  EditAssetCategory,
  GetSchoolCategory,
  GetAllAsset,
  EditAsset,
} from "../../services/Asset";
import AssetsTable from "../../components/Assets/AssetsTable";
import CategoriesTable from "../../components/Assets/CategoriesTable";
import Title from "../../components/Dashboard/Title";
// import { set } from "date-fns/esm/fp";
const waArr = [
  {
    id: 1,
    name: "6 Months",
  },
  {
    id: 2,
    name: "1 Year",
  },
  {
    id: 3,
    name: "18 Months",
  },
  {
    id: 4,
    name: "2 Years",
  },
  {
    id: 5,
    name: "No Warranty",
  },
];
let defaultArr = [
  {
    schoolId: "AS",
    schoolName: "All Schools",
  },
];
const initialAssetObj = {
  schoolId: "",
  assetName: "",
  model: "",
  site: "",
  department: "",
  location: "",
  assetCondition: "",
  capacity: "",
  purchaseDate: "",
  vendorName: "",
  vendorNumber: "",
  vendorEmail: "",
  warranty: "",
  warrantyPeriod: "",
  categoryId: "",
  tagId: "",
  assignedTo: "",
};
const initialCateObj = {
  schoolId: "",
  category: "",
};
const Assets = () => {
  const [cLoading, setCLoading] = useState(false);
  const [sLoading, setSLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [listLoading, setListLoading] = useState(true);
  const [delLoading, setDelLoading] = useState(false);
  const [cateLoading, setCateLoading] = useState(true);
  const [asLoading, setAsLoading] = useState(true);
  const [cateModal, setCateModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [cateList, setCateList] = useState([]);
  const [schoolId, setSchoolId] = useState("AS");
  const [categories, setCategories] = useState([]);
  const [assets, setAssets] = useState([]);
  const { admToken } = useSelector((state) => state.auth);
  const isTab = useMediaQuery({ maxWidth: 922 });
  const [arr, setArr] = useState([]);
  const [tab, setTab] = useState(1);
  const [obj, setObj] = useState(initialAssetObj);
  const [cateObj, setCateObj] = useState(initialCateObj);
  // All Done
  const getAllSchools = useCallback(async () => {
    try {
      const res = await getSchools(admToken);
      res.status && res.statusCode === 2 && setArr(res.data);

      (res.status === 500 || res.status === 400) &&
        ErrorService.displayErrorAlert(res.data);
      setListLoading(false);
    } catch (error) {
      console.log(error);
      setListLoading(false);
    }
  }, [admToken]);
  // Done
  const getSchoolStaff = useCallback(
    async (id) => {
      try {
        setSLoading(true);
        const res = await getSchoolList(admToken, "StaffList", id);
        console.log(res);
        res.status && res.statusCode === 2 && setStaffList(res.data);
        setSLoading(false);
      } catch (error) {
        console.log(error);
        setSLoading(false);
      }
    },
    [admToken]
  );
  // done
  const getSchoolCategories = useCallback(
    async (id, setData = setCategories) => {
      try {
        setCLoading(true);
        const res = await GetSchoolCategory(admToken, id);
        console.log(res);
        res.status && res.statusCode === 2 && setData(res.data);
        setCateLoading(false);
        setCLoading(false);
      } catch (error) {
        console.log(error);
        setCLoading(false);
        setCateLoading(false);
      }
    },
    [admToken]
  );
  const getCategories = useCallback(async () => {
    try {
      const res = await GetAllCategory(admToken);
      console.log(res);
      res.status && res.statusCode === 2 && setCategories(res.data);
      setCateLoading(false);
    } catch (error) {
      console.log(error);
      setCateLoading(false);
    }
  }, [admToken]);
  const getAssets = useCallback(async () => {
    try {
      const res = await GetAllAsset(admToken);
      console.log(res);
      res.status && res.statusCode === 2 && setAssets(res.data);
      setAsLoading(false);
    } catch (error) {
      console.log(error);
      setAsLoading(false);
    }
  }, [admToken]);
  const getSchoolAssets = useCallback(
    async (id) => {
      try {
        const res = await GetSchoolAsset(admToken, id);
        console.log(res);
        res.status && res.statusCode === 2 && setAssets(res.data);
        setAsLoading(false);
      } catch (error) {
        console.log(error);
        setAsLoading(false);
      }
    },
    [admToken]
  );

  useEffect(() => {
    getAllSchools();
    getAssets();
    getCategories();
  }, [getAllSchools, getCategories, getAssets]);

  // done
  const createCate = async () => {
    try {
      setFormLoading(true);
      console.log(cateObj);
      const res = await AddAssetCategory(admToken, cateObj);
      setFormLoading(false);
      console.log(res);
      if (res.status && res.statusCode === 2) {
        toast.success("Category Created Successfully");
        setCateObj(initialCateObj);
        setCateModal(!cateModal);
        schoolId === "AS" ? getCategories() : getSchoolCategories(schoolId);
      }

      !res.data.status && ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      setFormLoading(false);
      console.log(error);
    }
  };
  // done
  const editCate = async () => {
    try {
      setFormLoading(true);
      console.log(cateObj);
      const res = await EditAssetCategory(admToken, {
        ...cateObj,
        categoryId: cateObj.id,
      });
      setFormLoading(false);
      console.log(res);
      if (res.status && res.statusCode === 2) {
        toast.success("Category Created Successfully");
        setCateObj(initialCateObj);
        setCateModal(!cateModal);
        schoolId === "AS" ? getCategories() : getSchoolCategories(schoolId);
      }

      !res.data.status && ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      setFormLoading(false);

      console.log(error);
    }
  };
  // done
  const delCate = async (id) => {
    try {
      console.log(typeof id);
      setDelLoading(true);
      const res = await DeleteAssetCategory(admToken, id);
      console.log(res);
      res.status === 200 &&
        toast.success("Category Deleted Successfully") &&
        getCategories();
      setDelLoading(false);
    } catch (error) {
      setDelLoading(false);
      console.log(error);
    }
  };

  // done
  const createAsset = async () => {
    let formIsValid = isObjEmpty(obj);
    if (!formIsValid) {
      return toast.error("Please Make Sure You Fill All The Form");
    }
    try {
      setFormLoading(true);
      console.log(obj);
      const res = await AddAsset(admToken, obj);
      setFormLoading(false);
      console.log(res);
      if (res.status && res.statusCode === 2) {
        toast.success("Assets Created Successfully");
        setObj(initialAssetObj);
        setShowModal(!showModal);
        schoolId === "AS" ? getAssets() : getSchoolAssets(schoolId);
      }
      !res.data.status && ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      setFormLoading(false);
      console.log(error);
    }
  };

  // done
  const editAsset = async () => {
    let formIsValid = isObjEmpty(obj);
    if (!formIsValid) {
      return toast.error("Please Make Sure You Fill All The Form");
    }
    try {
      setFormLoading(true);
      console.log(obj);
      const res = await EditAsset(admToken, obj);
      setFormLoading(false);
      console.log(res);
      if (res.status && res.statusCode === 2) {
        toast.success("Assets Edited Successfully");
        setObj(initialAssetObj);
        setShowModal(!showModal);
        schoolId === "AS" ? getAssets() : getSchoolAssets(schoolId);
      }
      !res.data.status && ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      setFormLoading(false);
      console.log(error);
    }
  };
  // done
  const showEditModal = async () => {
    getSchoolCategories(obj.schoolId, setCateList);
    getSchoolStaff(obj.schoolId);
    setShowModal(true);
  };

  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }}>
        {" "}
        {obj.assetId ? "Edit" : "Add"} Asset
      </BigHeader>
      <TabContentWrapper>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Asset Name</label>
              <input
                type="text"
                onChange={(e) => setObj({ ...obj, assetName: e.target.value })}
                value={obj.assetName}
                placeholder="Asset Name"
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Model</label>
              <input
                type="text"
                onChange={(e) => setObj({ ...obj, model: e.target.value })}
                value={obj.model}
                placeholder="Asset Model"
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Select School</label>
              <Select
                title="School"
                thirdOption="schoolId"
                index="schoolName"
                selected={obj.schoolId}
                options={arr}
                setSelected={(val) => {
                  setObj({
                    ...obj,
                    schoolId: val.schoolId.toString(),
                    assignedTo: "",
                    categoryId: "",
                  });
                  getSchoolCategories(val.schoolId, setCateList);
                  getSchoolStaff(val.schoolId);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Assign To</label>
              {obj.schoolId === "" && (
                <span
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    color: colors.primary,
                  }}
                >
                  Select School To Get List Of Staff
                </span>
              )}
              {sLoading && (
                <span
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    color: colors.primary,
                  }}
                >
                  Please wait...
                </span>
              )}
              {obj.schoolId !== "" && !sLoading && staffList.length > 0 && (
                <Select
                  title="Staff"
                  index="name"
                  thirdOption="staffId"
                  selected={obj.assignedTo}
                  options={staffList}
                  setSelected={(val) => {
                    setObj({ ...obj, assignedTo: val.staffId.toString() });
                  }}
                />
              )}
              {obj.schoolId !== "" && !sLoading && staffList.length === 0 && (
                <span
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    color: colors.primary,
                  }}
                >
                  Please Add A Staff To This School Selected
                </span>
              )}
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Category</label>
              {obj.schoolId === "" && (
                <span
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    color: colors.primary,
                  }}
                >
                  Select School To Get List Of Categories
                </span>
              )}
              {cLoading && (
                <span
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    color: colors.primary,
                  }}
                >
                  Please wait...
                </span>
              )}
              {obj.schoolId !== "" && !cLoading && cateList.length > 0 && (
                <Select
                  title="Category"
                  index="category"
                  selected={obj.categoryId}
                  options={cateList}
                  setSelected={(val) => {
                    setObj({ ...obj, categoryId: val.id });
                  }}
                />
              )}
              {obj.schoolId !== "" && !cLoading && cateList.length === 0 && (
                <span
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    color: colors.primary,
                  }}
                >
                  Please Add Asset Category To This School Selected
                </span>
              )}
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Department</label>
              <input
                type="text"
                onChange={(e) => setObj({ ...obj, department: e.target.value })}
                value={obj.department}
                placeholder="Department"
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Site</label>
              <input
                type="text"
                onChange={(e) => setObj({ ...obj, site: e.target.value })}
                value={obj.site}
                placeholder="Site"
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Location</label>
              <input
                type="text"
                onChange={(e) => setObj({ ...obj, location: e.target.value })}
                value={obj.location}
                placeholder="Asset Location"
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>

        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Warranty</label>
              <input
                type="text"
                onChange={(e) => setObj({ ...obj, warranty: e.target.value })}
                value={obj.warranty}
                placeholder="Warranty"
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Length of Warranty</label>
              <input
                type="text"
                onChange={(e) =>
                  setObj({ ...obj, warrantyPeriod: e.target.value })
                }
                value={obj.warrantyPeriod}
                placeholder="Warranty Period"
              />
              {/* <Select
                title="Length Of Warranty"
                index="name"
                selected={obj.warrantyPeriod}
                options={waArr}
                setSelected={(val) => {
                  // console.log(val);
                  setObj({ ...obj, warrantyPeriod: val.name });
                }}
              /> */}
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Condition</label>
              <input
                type="text"
                onChange={(e) =>
                  setObj({ ...obj, assetCondition: e.target.value })
                }
                value={obj.assetCondition}
                placeholder="Asset Condition"
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Working Capcity</label>
              <input
                type="text"
                onChange={(e) => setObj({ ...obj, capacity: e.target.value })}
                value={obj.capacity}
                placeholder="Working Capacity"
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Tag ID</label>
              <input
                type="text"
                onChange={(e) => setObj({ ...obj, tagId: e.target.value })}
                value={obj.tagId}
                placeholder="Asset's Tag ID"
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Date of Purchse</label>
              <DatePickerComponent
                selected={
                  obj.purchaseDate === "" || obj.purchaseDate === null
                    ? ""
                    : new Date(Date.parse(obj.purchaseDate))
                }
                setSelected={(val) => {
                  setObj({ ...obj, purchaseDate: val });

                  // calculateAge(val);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Vendor/Supplier Name</label>
              <input
                type="text"
                onChange={(e) => setObj({ ...obj, vendorName: e.target.value })}
                value={obj.vendorName}
                placeholder="Vendor's Name"
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Vendor/Supplier Email</label>
              <input
                type="text"
                onChange={(e) =>
                  setObj({ ...obj, vendorEmail: e.target.value })
                }
                value={obj.vendorEmail}
                placeholder="Asset Condition"
              />
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Vendor/Supplier Phone</label>
              <input
                type="text"
                onKeyDown={(e) => isNumber(e)}
                onChange={(e) =>
                  setObj({ ...obj, vendorNumber: e.target.value })
                }
                value={obj.vendorNumber}
                placeholder="Vendor's Phone Number"
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <AuthButton
            width={isTab ? "100%" : "80%"}
            onClick={() => (obj.assetId ? editAsset() : createAsset())}
            disabled={formLoading}
          >
            {formLoading ? (
              <FormLoader />
            ) : (
              <div>
                <span> {obj.assetId ? "Edit" : "Add"} Asset</span>
              </div>
            )}
          </AuthButton>
        </div>
      </TabContentWrapper>
    </>
  );
  // done
  const cateContent = (
    <>
      <BigHeader fs="20px" style={{ textAlign: "center" }}>
        {cateObj.id ? "Edit" : "Add"} Asset Category
      </BigHeader>
      <div>
        {listLoading ? (
          <p
            style={{
              fontWeight: "bold",
              color: colors.primary,
              textAlign: "center",
            }}
          >
            Loading Schools...
          </p>
        ) : (
          <InputWrapper pt="0px">
            <label htmlFor="">Select School</label>
            <Select
              title="School"
              thirdOption="schoolId"
              index="schoolName"
              selected={cateObj.schoolId}
              options={arr}
              setSelected={(val) => {
                setCateObj({ ...cateObj, schoolId: val.schoolId.toString() });
              }}
            />
          </InputWrapper>
        )}

        <InputWrapper>
          <label htmlFor="">Category</label>
          <input
            type="text"
            value={cateObj.category}
            onChange={(e) =>
              setCateObj({
                ...cateObj,
                category: `${
                  e.target.value.charAt(0).toUpperCase() +
                  e.target.value.slice(1)
                }`,
              })
            }
          />{" "}
        </InputWrapper>

        <div>
          <AuthButton
            onClick={() => (cateObj.id ? editCate() : createCate())}
            width="100%"
            mt="0.6rem"
            disabled={
              formLoading || cateObj.schoolId === "" || cateObj.category === ""
            }
          >
            {formLoading ? (
              <FormLoader />
            ) : (
              <div>
                <span> {cateObj.id ? "Edit" : "Add"} Asset Category</span>
              </div>
            )}
          </AuthButton>
        </div>
      </div>
    </>
  );

  return (
    <>
      <Title title="Asset Management"/>
      <Container>

        {showModal && (
          <Modal
            start={true}
            show={showModal}
            content={content}
            size={"large"}
            onClose={setShowModal}
            pd={"0px"}
          />
        )}
        {cateModal && (
          <Modal
            show={cateModal}
            content={cateContent}
            size={"large"}
            onClose={setCateModal}
          />
        )}
        <GlobalStyles />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BigHeader></BigHeader>
          <div>
            <AuthButton
              onClick={() => {
                setObj(initialAssetObj);
                setShowModal(!showModal);
              }}
              style={{ display: "block" }}
            >
              Add Asset
            </AuthButton>
            <AuthButton
              onClick={() => {
                setCateObj(initialCateObj);
                setCateModal(!cateModal);
              }}
              style={{ marginTop: "0.3rem", display: "block" }}
            >
              Add Asset Category
            </AuthButton>{" "}
          </div>
        </div>
        <Grid container spacing={2} padding={3}>
          <Grid item lg={4} sm={6} xs={12}>
            <Card
              label="Total Assets"
              value={assets.length}
              icon=<BsFillHouseDoorFill />
            />
          </Grid>
        </Grid>

        <div style={{ width: "100%", padding: "0.2rem 0.4rem" }}>
          <InputWrapper>
            <label htmlFor="">Select School</label>
            <Select
              title="School"
              thirdOption="schoolId"
              index="schoolName"
              selected={schoolId}
              options={[...defaultArr, ...arr]}
              setSelected={(val) => {
                // console.log(val);
                setSchoolId(val.schoolId);
                val.schoolId === "AS"
                  ? getCategories()
                  : getSchoolCategories(val.schoolId);
                val.schoolId === "AS"
                  ? getAssets()
                  : getSchoolAssets(val.schoolId);
              }}
            />
          </InputWrapper>
        </div>

        <TabContainer style={{ paddingBottom: "0px" }}>
          <TabLinkContainer>
            <TabLink
              isActive={tab === 1}
              onClick={() => setTab(1)}
              minWidth={"50%"}
            >
              Assets
            </TabLink>
            <TabLink
              isActive={tab === 2}
              onClick={() => setTab(2)}
              minWidth={"50%"}
            >
              Assets Category
            </TabLink>
          </TabLinkContainer>
          {tab === 1 && (
            <AssetsTable
              data={assets}
              loading={asLoading}
              obj={obj}
              setObj={setObj}
              openModal={showEditModal}
            />
          )}
          {tab === 2 && (
            <CategoriesTable
              data={categories}
              deleteAction={delCate}
              obj={cateObj}
              setObj={setCateObj}
              delLoading={delLoading}
              openModal={setCateModal}
              loading={cateLoading}
            />
          )}
        </TabContainer>
      </Container>
    </>
  );
};

export default Assets;
