/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Outlet } from "react-router-dom";
import { DashboardNavbar, DashboardHeader } from "../components/Dashboard";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAccountProfile } from "../features/auth/authSlice";
import { toast } from "react-toastify";

const SharedLayouts = () => {
  const isPhone = useMediaQuery({ maxWidth: 768 });
  const [open, setOpen] = useState(false);
  const { authStatus, admToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (authStatus) {
  //     // Revalidate User Profile
  //     dispatch(getAccountProfile(admToken));
  //   }
  //   if (!authStatus) {
  //     toast.error("Revalidate User Account");
  //     navigate("/");
  //     return;
  //   }
  // }, [location, authStatus, dispatch, admToken, navigate]);
  return (
    <Box sx={{ display: "flex" }}>
      <DashboardNavbar
        controlNav={() => {
          if (open === true) {
            setOpen(false);
          }
        }}
        isOpen={open}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { lg: "90%", md: "90%", sm: "100%", xs: "100%" },
        }}
      >
        <DashboardHeader />
        <Box
          mt={isPhone ? 12 : 13}
          sx={{ mx: "auto", width: isPhone ? "95%" : "85%" }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default SharedLayouts;
