import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import colors from "../../assets/colors.json";
import { TabLink } from "../Dashboard/styles/dashboard.style";
import RequestLeave from "../../components/HR/RequestLeave";
import ApprovalLeave from "../../components/HR/ApprovalLeave";
import ApprovalTransfer from "../../components/HR/ApprovalTransfer";
import ApprovalTraining from "../../components/HR/ApprovalTraining";
const Approval = () => {
  const isTab = useMediaQuery({ maxWidth: 992 });
  const [tab, setTab] = useState(1);

  return (
    <>
      <div
        style={{
          width: isTab ? "100%" : "70%",
          display: "flex",
          alignItems: "center",
          padding: "8px 16px",
          backgroundColor: colors.secondary,
          borderRadius: "5px",
          flexWrap: "wrap",
        }}
      >
        <TabLink isActive={tab === 1} onClick={() => setTab(1)}>
          Request Leave
        </TabLink>
        <TabLink isActive={tab === 2} onClick={() => setTab(2)}>
          Redeployment/Transfer Requests
        </TabLink>
        <TabLink isActive={tab === 3} onClick={() => setTab(3)}>
          Training Requests
        </TabLink>
      </div>

      {tab === 1 && <ApprovalLeave />}
      {tab === 2 && <ApprovalTransfer />}

      {tab === 3 && <ApprovalTraining />}
    </>
  );
};

export default Approval;
