import BusinessIcon from "@mui/icons-material/Business";
import ComputerIcon from "@mui/icons-material/Computer";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { BsBook, BsFillJournalBookmarkFill } from "react-icons/bs";
import { CiBookmarkCheck } from "react-icons/ci";
import { HiDocumentReport } from "react-icons/hi";
import {
  MdOutlineDashboard,
  MdOutlineEditNote,
  MdOutlineNoteAlt,
  MdOutlineSchool,
} from "react-icons/md";
import { RiHospitalLine, RiParentFill } from "react-icons/ri";
import { TbBuildingWarehouse } from "react-icons/tb";
import { VscLibrary, VscSettingsGear } from "react-icons/vsc";

export const NavData = [
  {
    id: 1,
    label: "Dashboard",
    link: "/dashboard",
    icon: <MdOutlineDashboard />,
  },
  {
    id: 2,
    label: "Profile",
    link: "/profile",
    icon: <PermIdentityIcon />,
  },
  {
    id: 3,
    label: "My Schools",
    link: "/myschools",
    altPath: "myschool",
    icon: <BusinessIcon />,
  },
  {
    id: 4,
    label: "Student Management",
    altPath: "studentmgt",
    link: "/studentmgt",
    icon: <MdOutlineSchool />,
  },
  {
    id: 5,
    label: "Parent Management",
    link: "/parentmgt",
    icon: <RiParentFill />,
  },
  {
    id: 18,
    label: "Academic Activities",
    link: "/academicActivities",
    icon: <BsBook />,
  },
  {
    id: 11,
    label: "Hostel",
    link: "/hostel",
    icon: <TbBuildingWarehouse />,
  },
  {
    id: 10,
    label: "Library",
    link: "/library/books",
    icon: <VscLibrary />,
  },
  {
    id: 12,
    label: "Fleet",
    link: "/fleet/vehicle",
    icon: <EmojiTransportationIcon />,
  },
  {
    id: 13,
    label: "Asset",
    link: "/assets",
    icon: <BsFillJournalBookmarkFill />,
  },
  {
    id: 14,
    label: "Clinic",
    link: "/clinic",
    icon: <RiHospitalLine />,
  },

  {
    id: 16,
    label: "Finance",
    link: "/finance",
    icon: <BsBook />,
  },
  {
    id: 17,
    label: "Human Resource",
    link: "/humanresources",
    icon: <BsBook />,
  },

  {
    id: 18,
    label: "Quality Assurance",
    link: "/qualityassurance",
    icon: <CiBookmarkCheck />,
  },

  {
    id: 19,
    label: "Control Panel",
    link: "/controlpanel",
    icon: <VscSettingsGear />,
  },
];
