import React from 'react'
import {Box, Typography, Button} from '@mui/material'
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import StudentBio from './Components/StudentBio';
import SocialData from './Components/SocialData';
import Health from './Components/Health';
import Attachment from './Components/Attachment';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ mt: 3, overflowY:'scroll', height:'50vh'}}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
const Admission_Form = () => {

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    }

  return (
   <Box p={1}>
<Typography variant="h6" color="initial" sx={{fontFamily:'Gilroy-medium', fontSize:'25px' }}>Admission Form</Typography>

<Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label=" Student Bio Data"
                {...a11yProps(0)}
                sx={{
                  textTransform: "initial",
                  fontFamily: "Gilroy-Regular",
                  fontSize: "14px",
                }}
              />
              <Tab
                label="Social Data"
                {...a11yProps(1)}
                sx={{
                    textTransform: "initial",
                    fontFamily: "Gilroy-Regular",
                    fontSize: "14px",
                }}
              />
              <Tab
                label="Health Data"
                {...a11yProps(2)}
                sx={{
                    textTransform: "initial",
                    fontFamily: "Gilroy-Regular",
                    fontSize: "14px",
                }}
              />
              <Tab
                label="Attachment"
                {...a11yProps(3)}
                sx={{
                    textTransform: "initial",
                    fontFamily: "Gilroy-Regular",
                    fontSize: "14px",
                }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
        <StudentBio/>
         
          </TabPanel>
          <TabPanel value={value} index={1}>
           <SocialData/>
          </TabPanel>
          <TabPanel value={value} index={2}>
           <Health/>
          </TabPanel>
          <TabPanel value={value} index={3}>
           <Attachment/>
          </TabPanel>
       
        </Box>
   </Box>

  )
}

export default Admission_Form