import { createTheme, ThemeProvider, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Button, IconButton, MenuItem, MenuList } from '@mui/material'
import { BsSearch } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputBase from '@mui/material/InputBase';
import Pagination from '@mui/material/Pagination';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popover from '@mui/material/Popover';
import Checkbox from '@mui/material/Checkbox';

const theme = createTheme({
  palette:{
    primary:{
      main:'#671e78'
    }
  }
})

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#FFF',

  border:'1px solid #691e78',
  borderRadius:"100px",
  '&:hover': {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width:'345px',

  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
   
  },

}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily:'Gilroy-Bold'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily:'Gilroy-Regular'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor:'pointer',
  "&:nth-of-type(even)": {
    backgroundColor: ' rgba(103, 30, 120, 0.1)'
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function createData( date, purpose, beneficiary, amount, status) {
  return { date, purpose, beneficiary, amount, status };
}

const rows = [
  createData( '12/2/2023 : 10:20am', 'lorem suium pwisheb','Adeola James','$20,000' ),
  createData('12/2/2023 : 10:20am', 'lodn d hdjsj sjdj','Adeola James', '$20,000'),
  
];
const OnlinePayment = () => {

  return (
 <>
 <ThemeProvider theme={theme}>
   <Box>
          <Typography
            variant="body"
            sx={{ fontFamily: "Gilroy-Medium", fontSize: "26px" }}
          >
            <span style={{ opacity: 0.3, fontFamily: "Gilroy-Regular" }}>
              Parent Management &#62;&#62;
            </span>{" "}
            Parent Dashboard - Mr James Olu(2) &#62;&#62; Online Payment(s)
          </Typography>
          <Box>

<Box
        mt={5}
        sx={{
          border: "1px solid #671E78",
          borderRadius: "20px",
     
        }}
      >
                    <Box sx={{ borderRadius:'20px 20px 0px 0px' ,pt:4, background: 'rgba(103, 30, 120, 0.1)', height:'100px', pl:{xs:2.5}}}>

                    <Search sx={{width:{lg:'345px', md:'330px', sm:'auto', xs:'auto', pl:{xs:6}}}}>
        <SearchIconWrapper>
          <BsSearch />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
        />
      </Search>
                    </Box>
        <TableContainer 
       
        >
          <Table sx={{ minWidth: 700, fontFamily:'Gilroy-Regular' }} aria-label="customized table">
            <TableHead sx={{  backgroundColor: "rgba(103, 30, 120, 0.1)", width:'100%',}}>
  
              <TableRow>
                <StyledTableCell>
                  <Checkbox/>
                </StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell >Purpose</StyledTableCell>
                <StyledTableCell >
                Beneficiary
                </StyledTableCell>
                <StyledTableCell >
                Amount
                </StyledTableCell>
                <StyledTableCell>
                Status
                </StyledTableCell>
             
              </TableRow>
            </TableHead>
            <TableBody sx={{fontFamily:'Gilroy-Regular'}}>
              {rows.map((row, index) => (
                <StyledTableRow key={index} hover >
                  <StyledTableCell component="th" scope="row">
               <Checkbox/>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.date}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.purpose}
                  </StyledTableCell>
                  <StyledTableCell > {row.beneficiary}</StyledTableCell>
                  <StyledTableCell >
                     {row.amount}
                  </StyledTableCell>
            
                  <StyledTableCell  >
               <Typography variant='body' sx={{color:'green'}}>Completed</Typography>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
     
        </TableContainer>
      </Box>
      <Box sx={{display:'flex', justifyContent:'right', mt:3}}>
        <Pagination count={10} color="primary" />
        </Box>

      
   
</Box>
        </Box>
        </ThemeProvider>
 </>
  )
}

export default OnlinePayment