import axios from "axios";
const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
const api_version = process.env.REACT_APP_API_VERSION;

export const AddVehicle = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/FleetManagement/AddVehicle`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const EditVehicle = (token, data) =>
  axios
    .put(
      `${endpoint_two}/api/v${api_version}/FleetManagement/EditVehicle`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const GetAllVehicles = (token) =>
  axios
    .get(`${endpoint_two}/api/v${api_version}/FleetManagement/GetVehicles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response);

export const GetSchoolVehicles = (token, id) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/FleetManagement/GetVehicleBySchoolId?schoolId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
