import React, { useState } from "react";
import { AuthButton, InputWrapper } from "../auth/auth.style";
import { useMediaQuery } from "react-responsive";
import {
  AccountSummaryWrapper,
  BigHeader,
  Icon,
  Input,
  Search,
  FormFlex,
  FormFlexDiv,
  TabContainer,
  TabContentWrapper,
  TabLinkContainer,
  TabLink,
} from "../Dashboard/styles/dashboard.style";
import { BsSearch } from "react-icons/bs";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/system";
import Popover from "@mui/material/Popover";
import Modal from "../../components/modal/Modal";
import Select from "../../components/select/select";

import { IconButton, MenuItem, MenuList } from "@mui/material";
const LogIssue = () => {
  const [showModal, setShowModal] = useState(false);
  const [logModal, setLogModal] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [opt, setOpt] = useState("");

  const isTab = useMediaQuery({ maxWidth: 922 });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const optionsArr = [
    {
      id: 1,
      name: "Value 1",
    },
    {
      id: 2,
      name: "Value 2",
    },
  ];
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const [tab, setTab] = useState(1);

  const id = open ? "simple-popover" : undefined;
  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }}>Log Issue/Request</BigHeader>
      <TabContentWrapper>
        <InputWrapper>
          <label htmlFor="">Vehicle</label>
          <Select
            title="Vehicle"
            index="name"
            selected={"Value 1"}
            options={optionsArr}
            setSelected={(val) => {
              console.log(val);
              setOpt(val.name);
            }}
          />
        </InputWrapper>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Log Type</label>
              <Select
                title="Log"
                index="name"
                selected={"Value 1"}
                options={optionsArr}
                setSelected={(val) => {
                  console.log(val);
                  setOpt(val.name);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Log Category</label>
              <Select
                title="Category"
                index="name"
                selected={"Value 1"}
                options={optionsArr}
                setSelected={(val) => {
                  console.log(val);
                  setOpt(val.name);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Issue/Request</label>
              <input
                type="text"
                value={"Hello World"}
                onChange={(e) => console.log(e.target.value)}
                placeholder="Hello World"
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Cost Of Transaction</label>
              <input
                type="text"
                value={"Hello World"}
                onChange={(e) => console.log(e.target.value)}
                placeholder="Hello World"
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <InputWrapper>
          <label htmlFor="">Description</label>
          <textarea
            name=""
            id=""
            cols="30"
            rows="3"
            onChange={(e) => console.log(e.target.value)}
            placeholder="Description"
            style={{ resize: "none" }}
          ></textarea>
        </InputWrapper>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Service Date</label>
              <input
                type="text"
                value={"Hello World"}
                onChange={(e) => console.log(e.target.value)}
                placeholder="Hello World"
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Action to be carried out</label>
              <input
                type="text"
                value={"Hello World"}
                onChange={(e) => console.log(e.target.value)}
                placeholder="Hello World"
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <AuthButton width={isTab ? "100%" : "80%"} onSubmit={null}>
            <div>
              <span>Save</span>
            </div>
            {/* Send Code */}
          </AuthButton>
        </div>
      </TabContentWrapper>
    </>
  );
  const logContent = (
    <>
      <BigHeader style={{ textAlign: "center" }}>Title Name</BigHeader>
      <TabContentWrapper>
        <TabContainer>
          <TabLinkContainer>
            <TabLink
              isActive={tab === 1}
              onClick={() => setTab(1)}
              minWidth={"50%"}
            >
              Online Issue/Request
            </TabLink>
            <TabLink
              isActive={tab === 2}
              onClick={() => setTab(2)}
              minWidth={"50%"}
            >
              Offline Issue/Request
            </TabLink>
          </TabLinkContainer>
          <TabContainer
            style={{ marginTop: "0px", borderRadius: "0px", border: "none" }}
          >
            <TabLinkContainer bg="#F0E8F1" style={{ borderRadius: "0px" }}>
              <Input>
                <Icon>
                  <BsSearch />
                </Icon>
                <Search type="text" placeholder="Search" />
              </Input>
            </TabLinkContainer>
            <AccountSummaryWrapper pt="0px">
              <table style={{ borderRadius: "0px" }}>
                <tr
                  className="table-head"
                  style={{ background: "#F0E8F1", color: "black" }}
                >
                  <th>Log Type</th>
                  <th>Log Category</th>
                  <th>Issue/Request</th>
                  <th>Cost</th>
                  <th>Status</th>
                  <th>Service Date</th>
                  <th>Log By</th>
                  <th>Log Date</th>
                  <th></th>
                </tr>
                <tr>
                  <td>Issue</td>
                  <td>Fuelling</td>
                  <td>60 Litres of Fuel</td>
                  <td>10,000</td>
                  <td>
                    <span
                      style={{
                        color: "yellow",
                        padding: "0.4rem",
                        textAlign: "center",
                        background: "#FFEAA5",
                        borderRadius: "5px",
                      }}
                    >
                      Pending
                    </span>
                  </td>
                  <td>21st April 2023</td>
                  <td>Mr Denny</td>
                  <td>24th April 2023</td>
                  <td>
                    <IconButton onClick={handleClick}>
                      <MoreVertIcon />
                    </IconButton>
                  </td>
                </tr>
                <tr>
                  <td>Request</td>
                  <td>Maintenance</td>
                  <td>Servicing Of Motor</td>
                  <td>10,000</td>
                  <td>
                    <span
                      style={{
                        color: "red",
                        padding: "0.4rem",
                        textAlign: "center",
                        background: "#f296ab",
                        borderRadius: "5px",
                      }}
                    >
                      Decline
                    </span>
                  </td>
                  <td>21st April 2023</td>
                  <td>Mr Denny</td>
                  <td>24th April 2023</td>
                  <td>
                    <IconButton onClick={handleClick}>
                      <MoreVertIcon />
                    </IconButton>
                  </td>
                </tr>
              </table>
            </AccountSummaryWrapper>
          </TabContainer>
        </TabContainer>
      </TabContentWrapper>
    </>
  );
  return (
    <>
      {showModal && (
        <Modal
          start={true}
          show={showModal}
          content={content}
          size={"large"}
          onClose={setShowModal}
          pd="0px"
        />
      )}

      {logModal && (
        <Modal
          show={logModal}
          content={logContent}
          size={"large"}
          onClose={setLogModal}
          pd="0px"
        />
      )}
      <div style={{ display: "flex", justifyContent: "end" }}>
        <AuthButton
          mt="0.6rem"
          pl="0px"
          width={isTab ? "50%" : "30%"}
          onClick={() => setShowModal(!showModal)}
        >
          Log Issue/Request
        </AuthButton>
      </div>

      <TabContainer>
        <TabLinkContainer bg="#F0E8F1">
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search" />
          </Input>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table style={{ borderRadius: "0px" }}>
            <tr
              className="table-head"
              style={{ background: "#F0E8F1", color: "black" }}
            >
              <th>Log Type</th>
              <th>Log Category</th>
              <th>Issue/Request</th>
              <th>Cost</th>
              <th>Status</th>
              <th>Service Date</th>
              <th>Log By</th>
              <th>Log Date</th>
              <th></th>
            </tr>
            <tr>
              <td>Issue</td>
              <td>Fuelling</td>
              <td>60 Litres of Fuel</td>
              <td>10,000</td>
              <td>
                <span
                  style={{
                    color: "yellow",
                    padding: "0.4rem",
                    textAlign: "center",
                    background: "#FFEAA5",
                    borderRadius: "5px",
                  }}
                >
                  Pending
                </span>
              </td>
              <td>21st April 2023</td>
              <td>Mr Denny</td>
              <td>24th April 2023</td>
              <td>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              </td>
            </tr>
            <tr>
              <td>Request</td>
              <td>Maintenance</td>
              <td>Servicing Of Motor</td>
              <td>10,000</td>
              <td>
                <span
                  style={{
                    color: "red",
                    padding: "0.4rem",
                    textAlign: "center",
                    background: "#f296ab",
                    borderRadius: "5px",
                  }}
                >
                  Decline
                </span>
              </td>
              <td>21st April 2023</td>
              <td>Mr Denny</td>
              <td>24th April 2023</td>
              <td>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              </td>
            </tr>
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem
              onClick={() => {
                setLogModal(false);
                setShowModal(!showModal);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem>Assign Driver</MenuItem>
            <MenuItem>Assign Control Account</MenuItem>
            <MenuItem onClick={() => setLogModal(logModal)}>
              Renewal, Refuelling, Servicing, Maintenance
            </MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </>
  );
};

export default LogIssue;
