import React from "react";
import PhoneInput from "react-phone-input-2";
import PropTypes from "prop-types";

const PhoneInputComp = ({ phoneInput, setPhoneVal, disabled }) => {
  return (
    <PhoneInput
      disabled={disabled}
      style={{
        marginTop: "5px",
      }}
      inputClass={"phone-input"}
      buttonClass={"phone-btn"}
      country={"ng"}
      inputProps={{
        name: "phone",
        required: true,
        autoFocus: true,
      }}
      value={phoneInput}
      onChange={(phone, country) => {
        console.log(phone);
        let input = phone;
        if (country.countryCode === "ng") {
          if (input.slice(0, 3) === "234") {
            const a = input.slice(3);
            console.log(a);

            let b = a[0];
            if (b !== "0") {
              b = `0${a}`;
              input = b;
            } else {
              input = a;
            }
          }
        }
        setPhoneVal(input);
        console.log(input);
      }}
    />
  );
};

PhoneInputComp.propTypes = {
  disabled: PropTypes.bool,
};

PhoneInputComp.defaultProps = {
  disabled: false,
};
export default PhoneInputComp;
