import React, { useState, useEffect } from "react";
import { InputWrapper } from "../../pages/auth/Login.style";
import Select from "../select/select";
import colors from "../../assets/colors.json";
import { AuthButton } from "../../pages/auth/Login.style";
import hostelApi from "../../services/hostel";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ErrorService from "../../services/errorService";
import { genderOptions } from "../../assets/data/miscData";

const AddHostel = ({
  staff,
  edit = false,
  selectedHostel,
  getHostels,
  setOpenEditHostelModal,
  setOpenCreateHostelModal,
}) => {
  const [loading, setLoading] = useState(false);
  const { admToken, user } = useSelector((state) => state.auth);
  const [hostelObj, setHostelObj] = useState({
    name: "",
    gender: "",
    manager: "",
    capacity: 0,
  });
  const [selectedStaff, setSelectedStaff] = useState({
    id: "",
    name: "",
  });
  const [selectedGender, setSelectedGender] = useState({
    id: "",
    name: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      hostelName: hostelObj.name,
      gender: selectedGender.id,
      manager: selectedStaff.id,
      schoolid: user.schoolId,
      capacity: hostelObj.capacity,
    };
    const editData = {
      hostel_id: hostelObj.hostel_id,
      hostel_name: hostelObj.name,
      gender: selectedGender.id,
      manager: selectedStaff.id,
      schoolid: user.schoolId,
      capacity: hostelObj.capacity,
    };
    try {
      setLoading(true);
      const res = await (edit
        ? hostelApi.EditHouse(admToken, editData)
        : hostelApi.createHouse(admToken, data));
      if (res?.status && res?.statusCode === 2) {
        toast.success(res.message);
        getHostels();
        !edit && setOpenCreateHostelModal(false);
        edit && setOpenEditHostelModal(false);
        setLoading(false);
      } else if (res?.status === 500 || res?.status === 400 || !res?.status) {
        ErrorService.displayErrorAlert(res);
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (edit && selectedHostel) {
      setHostelObj({
        name: selectedHostel.hostel_name,
        gender: selectedHostel.gender,
        manager: selectedHostel.manager,
        hostel_id: selectedHostel.hostel_id,
        capacity: selectedHostel.capacity,
      });
      setSelectedGender({
        id: selectedHostel.gender,
        name:
          genderOptions.find(
            (option) => option.id === selectedHostel?.gender
          ) || "",
      });
      setSelectedStaff({
        id: selectedHostel.manager,
        name:
          staff.find((option) => option.credentialId === selectedHostel.manager)
            ?.fullName || "",
      });
    }
  }, [edit, selectedHostel, staff]);

  return (
    <section>
      <h1 className="text-[1.5rem] text-center border-b-[0.5px] border-gray-400 w-[calc(100%+18px)] lg:w-[calc(100%+79px)] -ml-[9px] lg:-ml-[39px] pb-5">
        {edit ? "Edit" : "Add"} Hostel
      </h1>
      <form onSubmit={handleSubmit} className="mt-8">
        <div className="grid grid-cols-1 gap-2 md:gap-x-7 xl:gap-x-24 md:grid-cols-2">
          <InputWrapper>
            <label>Hostel Name</label>
            <input
              type="text"
              name=""
              id=""
              value={hostelObj.name}
              onChange={(e) =>
                setHostelObj({
                  ...hostelObj,
                  name: e.target.value,
                })
              }
            />
          </InputWrapper>
          <InputWrapper>
            <label>Gender</label>
            <Select
              title="Gender"
              index="gender"
              selected={selectedGender.id || selectedHostel?.gender}
              options={genderOptions}
              setSelected={(val) => {
                setSelectedGender({
                  ...selectedGender,
                  id: val.id,
                  name: val.gender,
                });
              }}
              // color="white"
            />
          </InputWrapper>
          <InputWrapper>
            <label>House Master</label>
            <Select
              title="House Master"
              index="fullName"
              selected={selectedStaff.id || selectedHostel?.manager}
              thirdOption="credentialId"
              options={staff}
              setSelected={(val) => {
                setSelectedStaff({
                  id: val.credentialId,
                  name: val.fullName,
                });
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <label>Capacity</label>
            <input
              type="number"
              name=""
              id=""
              value={hostelObj.capacity}
              onChange={(e) =>
                setHostelObj({
                  ...hostelObj,
                  capacity: parseInt(e.target.value),
                })
              }
            />
          </InputWrapper>
        </div>

        <AuthButton
          bg={colors.primary}
          disabled={
            loading ||
            !selectedStaff.id ||
            !selectedGender.id ||
            !hostelObj.name ||
            !hostelObj.capacity
          }
          className="!rounded-md w-full"
          type="submit"
        >
          {loading ? "Loading..." : `${edit ? "Edit" : "Add"} Hostel`}
        </AuthButton>
      </form>
    </section>
  );
};

export default AddHostel;
