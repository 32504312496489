import React from 'react'
import {Box, Typography, Divider, List, ListItem, Button} from '@mui/material'

const Report_PDA = () => {
  return (
   <>
   <Box p={2}>
<Typography variant="h5" sx={{fontSize:'25px', fontFamily:'Gilroy-Medium', fontWeight:'bolder'}}>Report</Typography>
<Divider/>
<Box>
  <List sx={{width:'70%'}}>
<ListItem sx={{justifyContent:'space-between'}}>
  <Typography variant='body'>Exam Result</Typography>
  <Typography variant='body' >66%</Typography>
</ListItem>
<ListItem sx={{justifyContent:'space-between'}}>
  <Typography variant='body'>Status</Typography>
  <Typography variant='body' sx={{bgcolor:"#5e9c091a", px:2, py:0.5, color:'#5e9c09', borderRadius:'20px',}}>Shortlisted</Typography>
</ListItem>
<ListItem sx={{justifyContent:'space-between'}}>
  <Typography variant='body'>Interview Date/Time</Typography>
  <Typography variant='body'  >12/2//2022 11:30am</Typography>
</ListItem>
<ListItem sx={{justifyContent:'space-between'}}>
  <Typography variant='body'>Interview Status</Typography>
  <Typography variant='body' sx={{bgcolor:"#5e9c091a", px:2, py:0.5, color:'#5e9c09', borderRadius:'20px',}}>Passed</Typography>
</ListItem>
<ListItem sx={{justifyContent:'space-between'}}>
  <Typography variant='body'>Admission Status</Typography>
  <Typography variant='body' sx={{bgcolor:"#5e9c091a", px:2, py:0.5, color:'#5e9c09', borderRadius:'20px',}}>Admitted</Typography>
</ListItem>
<ListItem sx={{justifyContent:'space-between'}}>
  <Typography variant='body'>Admission No</Typography>
  <Typography variant='body' >LAGGS/2022/001</Typography>
</ListItem>
<ListItem sx={{justifyContent:'space-between'}}>
  <Typography variant='body'>Class</Typography>
  <Typography variant='body' >Jss 1(Purple)</Typography>
</ListItem>
  </List>
  <Box mt={3}>
    <Button variant="contained">Print Admission Letter</Button>
  </Box>
</Box>
   </Box>
   </>
  )
}

export default Report_PDA