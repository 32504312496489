/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createRef, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  AccountSummaryWrapper,
  BigHeader,
  FormFlex,
  FormFlexDiv,
  // InlineFormDiv,
  InlineLabelGroup,
  InlineLabelWrapper,
  TabContainer,
  TabLinkContainer,
  TabContentWrapper,
  Input,
  Icon,
  Search,
  AttachmentUploadContainer,
} from "../Dashboard/styles/dashboard.style";
import Loader from "../../components/pageLoader/loader";
// import { AiFillCar } from "react-icons/ai";
// import { BiSolidBookAlt } from "react-icons/bi";
import { BiBookAdd } from "react-icons/bi";
import { Box } from "@mui/system";
import Popover from "@mui/material/Popover";
import Pagination from "../../components/pagination_one/pagination";
import DatePickerComponent from "../../components/datepicker/DatePicker";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useMediaQuery } from "react-responsive";
import { ButtonFlex, AuthButton, InputWrapper } from "../auth/auth.style";
import colors from "../../assets/colors.json";
import Select from "../../components/select/select";
import Modal from "../../components/modal/Modal";
import { BsSearch } from "react-icons/bs";
import { IconButton, MenuItem, MenuList } from "@mui/material";
import { getSchools } from "../../services/MySchool";
import { GetBookSetup, AddBook } from "../../services/Asset";
import ErrorService from "../../services/errorService";
import { toast } from "react-toastify";
import { isNumber, isObjEmpty, paginateData } from "../../services/utils";
import FormLoader from "../../components/FormLoader";
import moment from "moment";
const initialObj = {
  schoolId: "3",
  bin: "BTR4",
  title: "Book Thursday 4",
  author: "string",
  serialNo: "BTR4",
  isbn: "BTR4",
  subjectId: 4,
  published: "string",
  // category: "string",
  status: 0,
  categoryId: 6,
  publisher: "string",
  placeOfpublication: "string",
  editor: "string",
  edition: "string",
  copywriteDate: "",
  copies: 90,
  librarySectionId: 6,
  shelfNoId: 6,
  rowNo: 90,
  bookClassId: 5,
  vendorId: 6,
  coverPageFile: "",
  coverType: 1,
  file: "",
};

const BookSetup = () => {
  const { admToken } = useSelector((state) => state.auth);
  // const [sLoading, setSLoading] = useState(true);
  const [sections, setSections] = useState([]);
  // const [shLoading, setSHLoading] = useState(true);
  const [shelves, setShelves] = useState([]);
  const [classes, setClasses] = useState([]);
  // const [cLoading, setCLoading] = useState(true);
  const [cates, setCates] = useState([]);
  // const [vLoading, setVLoading] = useState(true);
  const [vendors, setVendors] = useState([]);
  // const [suLoading, setSULoading] = useState(true);
  const [subjects, setSubjects] = useState([]);
  const [arr, setArr] = useState([]);
  // const [listLoading, setListLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  // const [opt, setOpt] = useState("");
  const isTab = useMediaQuery({ maxWidth: 992 });
  const [showModal, setShowModal] = useState(false);
  const onPickFileRef = createRef();
  // const [fileName, setFileName] = useState("");
  // const [base64, setBase64] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [obj, setObj] = useState(initialObj);
  const [formLoading, setFormLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [list, setList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(17);
  const [itemOffSet, setitemOffSet] = useState(0);

  const getAllSchools = useCallback(async () => {
    try {
      const res = await getSchools(admToken);
      res.status && res.statusCode === 2 && setArr(res.data);
      (res.status === 500 || res.status === 400) &&
        ErrorService.displayErrorAlert(res.data);
      // console.log(res);
      // setListLoading(false);
    } catch (error) {
      console.log(error);
      // setListLoading(false);
    }
  }, [admToken]);
  const getSections = useCallback(async () => {
    try {
      const res = await GetBookSetup(admToken, "ListLibrarySections");
      // console.log(res);
      res.status && res.statusCode === 2 && setSections(res.data);
      (!res.status || res.status === 400 || res.status === 500) &&
        toast.error("An Error  Occurred, Please try again later");
      // setSLoading(false);
    } catch (error) {
      // setSLoading(false);
      console.log(error);
    }
  }, [admToken]);
  const getShelves = useCallback(async () => {
    try {
      const res = await GetBookSetup(admToken, "ListShelfNos");
      // console.log(res);
      res.status && res.statusCode === 2 && setShelves(res.data);
      (!res.status || res.status === 400 || res.status === 500) &&
        toast.error("An Error  Occurred, Please try again later");
      // setSHLoading(false);
    } catch (error) {
      // setSHLoading(false);
      console.log(error);
    }
  }, []);
  const getCates = useCallback(async () => {
    try {
      const res = await GetBookSetup(admToken, "ListCategories");
      // console.log(res);
      res.status && res.statusCode === 2 && setCates(res.data);
      (!res.status || res.status === 400 || res.status === 500) &&
        toast.error("An Error  Occurred, Please try again later");
      // setCLoading(false);
    } catch (error) {
      // setCLoading(false);
      console.log(error);
    }
  }, [admToken]);
  const getVendors = useCallback(async () => {
    try {
      const res = await GetBookSetup(admToken, "ListVendors");
      // console.log(res);
      res.status && res.statusCode === 2 && setVendors(res.data);
      (!res.status || res.status === 400 || res.status === 500) &&
        toast.error("An Error  Occurred, Please try again later");
      // setVLoading(false);
    } catch (error) {
      // setVLoading(false);
      console.log(error);
    }
  }, [admToken]);
  const getSubjects = useCallback(async () => {
    try {
      const res = await GetBookSetup(admToken, "ListSubjects");
      // console.log(res);
      res.status && res.statusCode === 2 && setSubjects(res.data);
      (!res.status || res.status === 400 || res.status === 500) &&
        toast.error("An Error  Occurred, Please try again later");
      // setSULoading(false);
    } catch (error) {
      // setSULoading(false);
      console.log(error);
    }
  }, [admToken]);
  const getClass = useCallback(async () => {
    try {
      const res = await GetBookSetup(admToken, "ListBookClass");
      // console.log(res);
      res.status && res.statusCode === 2 && setClasses(res.data);
      (!res.status || res.status === 400 || res.status === 500) &&
        toast.error("An Error  Occurred, Please try again later");
      // setCLoading(false);
    } catch (error) {
      // setCLoading(false);
      console.log(error);
    }
  }, [admToken]);

  const performAction = async () => {
    let formIsValid = isObjEmpty(obj);
    if (!formIsValid) {
      return toast.error("Please Make Sure You Fill All The Form");
    }
    try {
      setFormLoading(true);
      const res = await AddBook(admToken, obj);
      res.status &&
        res.statusCode === 2 &&
        toast.success("Book Added Successfully ") &&
        setShowModal(false);
      res.status && res.statusCode === 2 && getAllBooks();
      setFormLoading(false);
      console.log(res);
      res.status === 400 && ErrorService.displayErrorAlert(res);
      !res.status && ErrorService.displayErrorAlert(res);
    } catch (error) {
      console.log(error);
      setFormLoading(false);
    }
  };

  const getAllBooks = useCallback(async () => {
    try {
      const res = await GetBookSetup(admToken, "ListBooks");
      res.status && res.statusCode === 2 && setItems(res.data);
      (!res.status || res.status === 400 || res.status === 500) &&
        toast.error("An Error  Occurred, Please try again later");
      console.log(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [admToken]);

  useEffect(() => {
    getAllBooks();
    getShelves();
    getSections();
    getAllSchools();
    getCates();
    getSubjects();
    getClass();
    getVendors();
  }, [getAllSchools, getSections]);

  useEffect(() => {
    paginateData(
      items,
      setList,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [items, currentPage]);

  const pickFile = () => {
    onPickFileRef.current.click();
  };
  const onPickFile = (event) => {
    const files = event.target.files;
    //  setObj({ ...obj, fileName });
    //  setFileName(files[0].name);
    // setObj({ ...obj, file: files[0] });
    let filename = files[0].name;
    if (
      filename.includes(".jpg") ||
      filename.includes(".jpeg") ||
      filename.includes(".png")
    ) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        // setBase64(fileReader.result);

        setObj({
          ...obj,
          file: files[0],
          coverPageFile: fileReader.result,
        });
      });
      fileReader.readAsDataURL(files[0]);
    } else {
      alert("Please upload a picture");
    }
  };

  const optionsArr = [
    {
      id: 1,
      name: "Value 1",
    },
    {
      id: 2,
      name: "Value 2",
    },
  ];

  const coverOptions = [
    { id: 1, name: "Hard Cover" },
    { id: 2, name: "Soft Cover" },
    { id: 3, name: "EBook" },
  ];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }}>Add Book</BigHeader>
      <TabContentWrapper>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">School</label>
              <Select
                title="School"
                thirdOption="schoolId"
                index="schoolName"
                selected={obj.schoolId}
                options={arr}
                setSelected={(val) => {
                  setObj({ ...obj, schoolId: val.schoolId.toString() });
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Library Section</label>
              <Select
                title="Library Section"
                index="name"
                selected={obj.librarySectionId}
                options={sections}
                setSelected={(val) => {
                  console.log(val);
                  setObj({ ...obj, librarySectionId: val.id });
                  // setOpt(val.name);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Shelf No</label>
              <Select
                title="Shelf No"
                index="name"
                selected={obj.shelfNoId}
                options={shelves}
                setSelected={(val) => {
                  console.log(val);
                  setObj({ ...obj, shelfNoId: val.id });
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Row No</label>
              <input
                type="text"
                onKeyDown={(e) => isNumber(e)}
                placeholder="Row No"
                value={obj.rowNo}
                onChange={(e) =>
                  setObj({ ...obj, rowNo: Number(e.target.value) })
                }
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Serial No</label>
              <input
                type="text"
                placeholder="Serial No"
                value={obj.serialNo}
                onChange={(e) => setObj({ ...obj, serialNo: e.target.value })}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <InputWrapper>
          <label htmlFor="">Book TItle</label>
          <input
            type="text"
            placeholder="Book Title"
            value={obj.title}
            onChange={(e) => setObj({ ...obj, title: e.target.value })}
          />
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="">Author</label>
          <input
            type="text"
            placeholder="Book Title"
            value={obj.author}
            onChange={(e) => setObj({ ...obj, author: e.target.value })}
          />
        </InputWrapper>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">ISBN</label>
              <input
                type="text"
                placeholder="ISBN"
                value={obj.isbn}
                onChange={(e) => setObj({ ...obj, isbn: e.target.value })}
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Publisher</label>
              <input
                type="text"
                placeholder="Book Publisher"
                value={obj.publisher}
                onChange={(e) => setObj({ ...obj, publisher: e.target.value })}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <InputWrapper>
          <label htmlFor="">Place Of Publication</label>
          <input
            type="text"
            placeholder="Place Of Publication"
            value={obj.placeOfpublication}
            onChange={(e) =>
              setObj({ ...obj, placeOfpublication: e.target.value })
            }
          />
        </InputWrapper>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Editor</label>
              <input
                type="text"
                placeholder="Editor"
                value={obj.editor}
                onChange={(e) => setObj({ ...obj, editor: e.target.value })}
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Edition</label>
              <input
                type="text"
                placeholder="Edition"
                value={obj.edition}
                onChange={(e) => setObj({ ...obj, edition: e.target.value })}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Copywrite Date</label>
              <DatePickerComponent
                selected={obj.copywriteDate}
                setSelected={(val) => {
                  setObj({ ...obj, copywriteDate: val });
                  console.log(val);
                  // calculateAge(val);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Category</label>
              <Select
                title="Category"
                index="name"
                selected={obj.categoryId}
                options={cates}
                setSelected={(val) => {
                  console.log(val);
                  setObj({ ...obj, categoryId: val.id });
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>{" "}
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Cover Type</label>
              <Select
                title="Cover Type"
                index="name"
                selected={obj.coverType}
                options={coverOptions}
                setSelected={(val) => {
                  setObj({ ...obj, coverType: val.id });
                }}
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">BIN</label>
              <input
                type="text"
                placeholder="BIN"
                value={obj.bin}
                onChange={(e) => setObj({ ...obj, bin: e.target.value })}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>{" "}
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Subject</label>
              <Select
                title="Subject"
                index="subname"
                thirdOption="subid"
                selected={obj.subjectId}
                options={subjects}
                setSelected={(val) => {
                  console.log(val);
                  setObj({ ...obj, subjectId: val.subid });
                  // setOpt(val.name);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Class</label>
              <Select
                title="Class"
                index="name"
                selected={obj.bookClassId}
                options={classes}
                setSelected={(val) => {
                  console.log(val);
                  setObj({ ...obj, bookClassId: val.id });
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>{" "}
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Copies</label>
              <input
                type="text"
                onKeyDown={(e) => isNumber(e)}
                placeholder="Copies"
                value={obj.copies}
                onChange={(e) =>
                  setObj({ ...obj, copies: Number(e.target.value) })
                }
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Vendor</label>
              <Select
                title="Vendor"
                index="name"
                selected={obj.vendorId}
                options={vendors}
                setSelected={(val) => {
                  console.log(val);
                  setObj({ ...obj, vendorId: val.id });
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <AttachmentUploadContainer
              onClick={pickFile}
              width="100%"
              zin={"0"}
              style={{ padding: "10px 5px", background: "transparent" }}
            >
              {obj.coverPageFile.length === 0 && (
                <>
                  <BiBookAdd
                    style={{ color: colors.primary, fontSize: "40px" }}
                  />
                </>
              )}
              {obj.coverPageFile.length > 0 && (
                <img src={obj.coverPageFile} alt="" />
              )}
              <input
                type="file"
                style={{
                  display: "none",
                }}
                onChange={onPickFile}
                ref={onPickFileRef}
              />
            </AttachmentUploadContainer>
            {obj.coverPageFile.length === 0 ? (
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                Please Upload A Book Cover
              </p>
            ) : (
              <p
                style={{
                  textAlign: "center",
                  color: colors.primary,
                  fontWeight: "bold",
                }}
              >
                Please Click To Update Photo
              </p>
            )}
          </FormFlexDiv>
          {/* <input
            type="file"
            style={{ display: "none" }}
            onChange={onPickFile}
            ref={onPickFileRef}
          /> */}

          {/* <FormFlexDiv> */}
          {/* <InlineFormDiv style={{ padding: "0px" }}>
            <div style={{ width: isTab ? "100%" : "75%" }}>
              <InputWrapper>
                <label htmlFor="">Cover Page</label>
                <input
                  type="text"
                  value={fileName + "" + base64}
                  disabled={true}
                  placeholder="File Name"
                />
              </InputWrapper>
            </div>
            <AuthButton
              width={isTab ? "100%" : "20%"}
              onClick={pickFile}
              bg={colors.secondary}
              color={"black"}
            >
              Choose
            </AuthButton>
          </InlineFormDiv> */}
          {/* </FormFlexDiv> */}
        </FormFlex>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <AuthButton
            width={isTab ? "100%" : "80%"}
            onClick={performAction}
            disabled={formLoading}
          >
            {formLoading ? (
              <FormLoader />
            ) : (
              <div>
                <span>Add Book</span>
              </div>
            )}

            {/* Send Code */}
          </AuthButton>
        </div>
      </TabContentWrapper>
    </>
  );

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      {showModal && (
        <Modal
          start={true}
          show={showModal}
          content={content}
          size={"large"}
          onClose={setShowModal}
          pd={"0px"}
        />
      )}
      <TabContainer style={{ background: colors.primary }}>
        <InlineLabelGroup>
          <InlineLabelWrapper flex={5}>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={2}>
                <InputWrapper>
                  <Select
                    color={"white"}
                    title="School"
                    index="name"
                    selected={"Value 1"}
                    options={optionsArr}
                    setSelected={(val) => {
                      console.log(val);
                      // setOpt(val.name);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={1}>
                <InputWrapper>
                  <Select
                    color={"white"}
                    title="School"
                    index="name"
                    selected={"Value 1"}
                    options={optionsArr}
                    setSelected={(val) => {
                      console.log(val);
                      // setOpt(val.name);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={1}>
                <InputWrapper>
                  <Select
                    color={"white"}
                    title="School"
                    index="name"
                    selected={"Value 1"}
                    options={optionsArr}
                    setSelected={(val) => {
                      console.log(val);
                      // setOpt(val.name);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={1}>
                <InputWrapper>
                  <Select
                    color={"white"}
                    title="School"
                    index="name"
                    selected={"Value 1"}
                    options={optionsArr}
                    setSelected={(val) => {
                      console.log(val);
                      // setOpt(val.name);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
          </InlineLabelWrapper>
          <InlineLabelWrapper flex={1}>
            <ButtonFlex>
              <AuthButton
                style={{ marginTop: "1rem" }}
                disabled={false}
                width={isMobile ? "100%" : "90%"}
                onClick={null}
                color={colors.primary}
                bg="white"
              >
                <div style={{ textAlign: "center" }}>Load</div>
              </AuthButton>
            </ButtonFlex>
          </InlineLabelWrapper>
        </InlineLabelGroup>
      </TabContainer>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <AuthButton
          mt="0.6rem"
          pl="0px"
          width={isTab ? "50%" : "30%"}
          onClick={() => setShowModal(!showModal)}
        >
          <div>
            <span>Add new Book</span>
          </div>
          {/* Send Code */}
        </AuthButton>
      </div>

      <TabContainer style={{ padding: "0px" }}>
        <TabLinkContainer bg="#F0E8F1">
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search" />
          </Input>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table style={{ borderRadius: "0px" }}>
            <tr
              className="table-head"
              style={{ backgroundColor: "#F0E8F1", color: "black" }}
            >
              <th>Library Section</th>
              <th>Book Title</th>
              <th>Subject</th>
              <th>Author</th>
              <th>Shelf</th>
              <th>Row No</th>
              {/* <th>Copies</th> */}
              <th>Copyright Date </th>
              {/* <th>Created By</th> */}
              <th></th>
            </tr>
            {list.length > 0 ? (
              list.map((item, index) => (
                <tr style={{ fontWeight: "bold" }} key={index}>
                  <td>{item.librarySection}</td>

                  <td>{item.title}</td>
                  <td>{item.subject}</td>
                  <td>{item.author}</td>
                  <td>{item.shelfNo}</td>
                  <td>{item.rowNo}</td>
                  {/* <td>31</td> */}
                  <td>
                    {item.copywriteDate !== null
                      ? moment(item.copywriteDate).format("DD/MM/YYYY")
                      : ""}{" "}
                  </td>
                  {/* <td>Mr Paul Shegs</td> */}
                  <td>
                    <IconButton onClick={handleClick}>
                      <MoreVertIcon />
                    </IconButton>
                  </td>
                </tr>
              ))
            ) : (
              <tr style={{ fontWeight: "bold" }}>
                <td colSpan={8} style={{ textAlign: "center" }}>
                  No Books Available At The Moment{" "}
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        {pageCount > 1 && (
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            range={3}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage - 1}
          />
        )}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem>Edit</MenuItem>
            <MenuItem>Deactivate</MenuItem>
            <MenuItem>View Lending History</MenuItem>
            <MenuItem>Delete</MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </>
  );
};
export default BookSetup;
