import React, { useEffect, useCallback, useState } from "react";
import colors from "../../assets/colors.json";
import ErrorService from "../../services/errorService";
import { useSelector } from "react-redux";
import {
  getAllClasses,
  getSessions,
  getTerms,
  GetRemarkClasses,
} from "../../services/Subject";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import {
  InlineLabelGroup,
  InlineLabelWrapper,
  TabContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import Select from "../select/select";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { AuthButton, InputWrapper } from "../../pages/auth/Login.style";
import FormLoader from "../FormLoader";
import {
  getCummSpreadSheet,
  getSpreadSheet,
} from "../../services/ParentStudent";
import SpreadSheetModal from "./SpreadSheetModal";
import { HiOutlineSwitchVertical } from "react-icons/hi";
import CummSpreadSheet from "./CummSpreadSheet";

const ResultReport = () => {
  const [formLoading, setFormLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [terms, setTerms] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [classes, setClasses] = useState([]);
  const [showForm, setShowForm] = useState(1);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [showSheet, setShowSheet] = useState(null);
  const [loadClass, setLoadClass] = useState(false);
  const [spreadSheetObj, setSpreadSheetObj] = useState(null);
  const [cummSpreadSheetObj, setCummSpreadSheetObj] = useState(null);
  const [classObj, setClassObj] = useState({
    id: "",
    name: "",
  });
  const [session, setSession] = useState({
    id: "",
    name: "",
  });
  const [term, setTerm] = useState({
    id: "",
    name: "",
  });
  const reportType = [
    { type: "Monitoring", id: 1 },
    { type: "Final", id: 2 },
  ];
  const [reportTypeObj, setReportTypeObj] = useState({
    id: "",
    name: "",
  });

  useEffect(() => {
    async function getData() {
      try {
        const res = await getTerms(admToken);
        if (res.status && res.statusCode === 2) {
          setTerms(res.data);
        }
        (res.status === 500 || res.data.status === 400 || !res.status) &&
          ErrorService.displayErrorAlert(res.data);
      } catch (error) {
        console.log("Error OCurred");
      }
    }

    getData();
  }, [admToken]);

  useEffect(() => {
    async function getData() {
      try {
        const res = await getSessions(admToken);
        if (res.status && res.statusCode === 2) {
          setSessions(res.data);
        }
        (res.status === 500 || res.data.status === 400 || !res.status) &&
          ErrorService.displayErrorAlert(res.data);
      } catch (error) {
        console.log("Error OCurred");
      }
    }
    getData();
  }, [admToken]);

  const getClasses = useCallback(
    async (id) => {
      try {
        setLoadClass(true);
        const res = await GetRemarkClasses(admToken, user.schoolId, id);
        res.status && res.statusCode === 2 && setClasses(res.data);
        (res.status === 500 || res.status === 400 || !res.status) &&
          ErrorService.displayErrorAlert(res);
        setLoadClass(false);
      } catch (error) {
        console.log(error);
        setLoadClass(false);
      }
    },

    [admToken, user]
  );

  const getTermSheet = useCallback(async () => {
    setLoading(true);
    let data = {
      schoolId: user.schoolId,
      termId: term.id,
      sessionId: session.id,
      classId: classObj.id,
      reportType: reportTypeObj.id,
    };
    try {
      const res = await getSpreadSheet(admToken, data);
      if (res.status && res.statusCode === 2) {
        setSpreadSheetObj(res.data);
        setShowForm(null);
      }
      if (res.statusCode === 6 || !res.status) {
        toast.warning(res.message);
      }
      if (
        res.status === 500 ||
        res.status === 400 ||
        res.data.status === 400 ||
        !res.status
      ) {
        ErrorService.displayErrorAlert(res.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("An error Occurred");
      console.log(error);
    }
  }, [
    admToken,
    classObj.id,
    reportTypeObj.id,
    session.id,
    term.id,
    user.schoolId,
  ]);

  const getCummSheet = useCallback(async () => {
    setLoading(true);
    let data = {
      schoolId: user.schoolId,
      sessionId: session.id,
      classId: classObj.id,
    };
    try {
      const res = await getCummSpreadSheet(admToken, data);
      if (res.status && res.statusCode === 2) {
        setCummSpreadSheetObj(res.data);
        setShowForm(null);
      }
      if (res.statusCode === 6 || !res.status) {
        toast.warning(res.message);
      }
      if (
        res.status === 500 ||
        res.status === 400 ||
        res.data.status === 400 ||
        !res.status
      ) {
        ErrorService.displayErrorAlert(res.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("An error Occurred");
      console.log(error);
    }
  }, [admToken, classObj.id, session.id, user.schoolId]);

  const handleShowForm = () => {
    if (showForm === 1) {
      setShowForm(2);
    } else if (showForm === 2) {
      setShowForm(null);
    } else {
      setShowForm(1);
    }
    // setSession({
    //   id: "",
    //   name: "",
    // });
    // setTerm({
    //   id: "",
    //   name: "",
    // });
    // setClassObj({
    //   id: "",
    //   name: "",
    // });
  };

  const displaySheet = () => {
    if (showSheet) {
      if (showSheet === 1 && spreadSheetObj) {
        return (
          <SpreadSheetModal
            close={() => setShowSheet(null)}
            resultData={spreadSheetObj}
            note={`${classObj.name} - ${session.name} - ${term.name}`}
          />
        );
      } else if (showSheet === 2 && cummSpreadSheetObj) {
        return (
          <CummSpreadSheet
            close={() => setShowSheet(null)}
            resultData={cummSpreadSheetObj}
            note={`${classObj.name} - ${session.name}`}
          />
        );
      }
    }
  };

  return (
    <>
      <div
        onClick={() => handleShowForm()}
        style={{
          backgroundColor: colors.primary,
          borderRadius: "10px",
          marginTop: "0.3rem",
          padding: "0.8rem",
          display: "flex",
          justifyContent: "space-between",
          color: "white",
          fontWeight: "bold",
          cursor: "pointer",
        }}
      >
        {showForm ? (
          <div>
            <span className="mr-2 font-bold">
              {showForm === 1 ? "Term Spreadsheet" : " Cummulative Spreadsheet"}
            </span>
            {showForm === 1 ? (
              <span>
                {session.name} {session.name.length > 0 ? "-" : ""} {term.name}{" "}
                {term.name.length > 0 && classObj.name.length > 0 ? "-" : ""}
                {classObj.name}
              </span>
            ) : (
              <span>
                {session.name} {session.name.length > 0 ? "-" : ""}
                {classObj.name}
              </span>
            )}
          </div>
        ) : (
          <BiSolidUpArrow color="white" />
        )}
        <HiOutlineSwitchVertical className="w-5 h-5 " />
      </div>
      {showForm !== null &&
        (showForm === 1 ? (
          <TabContainer
            style={{
              marginTop: "0.5rem",
              background: colors.primary,
              paddingBottom: "10px",
              paddingTop: "10px",
            }}
          >
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                  <Select
                    color="white"
                    title="Session"
                    index="session" // no
                    disabled={loading}
                    selected={session.id} // false
                    options={sessions}
                    setSelected={(val) => {
                      setClassObj({ ...classObj, id: "", name: "" });
                      setSession({
                        ...session,
                        id: val.id,
                        name: val.session,
                      });
                      getClasses(val.id);

                      setSpreadSheetObj(null);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                  <Select
                    disabled={loading}
                    title="Term"
                    index="term" // no
                    selected={term.id} // false
                    options={terms}
                    setSelected={(val) => {
                      setSpreadSheetObj(null);

                      setTerm({ ...term, id: val.id, name: val.term });
                    }}
                    color="white"
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                  <Select
                    title={loadClass ? "Loading" : "Class"}
                    index="className" // no
                    selected={classObj.id} // false
                    options={classes}
                    thirdOption="classId"
                    disabled={loading || loadClass}
                    extraTitle="classArm"
                    setSelected={(val) => {
                      setSpreadSheetObj(null);
                      setClassObj({
                        ...classObj,
                        id: val.classId,
                        name: `${val.className}  ${val.classArm}
                    
                    `,
                      });
                    }}
                    color="white"
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                  <Select
                    title={"Type"}
                    index="type"
                    selected={reportTypeObj.id}
                    options={reportType}
                    thirdOption="reportTypeId"
                    disabled={loading}
                    setSelected={(val) => {
                      setSpreadSheetObj(null);
                      setReportTypeObj({
                        ...reportTypeObj,
                        id: val.id,
                        name: val.name,
                      });
                    }}
                    color="white"
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={1}>
                <AuthButton
                  style={{ marginTop: "0.3rem" }}
                  bg="white"
                  mt="0rem"
                  color={colors.primary}
                  onClick={getTermSheet}
                  disabled={
                    term.id === "" ||
                    session.id === "" ||
                    classObj.id === "" ||
                    reportTypeObj.id === "" ||
                    loading
                  }
                  width={isMobile ? "100%" : "90%"}
                >
                  {loading ? (
                    <FormLoader color={colors.primary} />
                  ) : (
                    "Get Sheet"
                  )}
                </AuthButton>
              </InlineLabelWrapper>
            </InlineLabelGroup>
          </TabContainer>
        ) : (
          <TabContainer
            style={{
              marginTop: "0.5rem",
              background: colors.primary,
              paddingBottom: "10px",
              paddingTop: "10px",
            }}
          >
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                  <Select
                    color="white"
                    title="Session"
                    index="session" // no
                    disabled={loading}
                    selected={session.id} // false
                    options={sessions}
                    setSelected={(val) => {
                      setClassObj({ ...classObj, id: "", name: "" });
                      setSession({
                        ...session,
                        id: val.id,
                        name: val.session,
                      });
                      getClasses(val.id);

                      setSpreadSheetObj(null);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                  <Select
                    title={loadClass ? "Loading" : "Class"}
                    index="className" // no
                    selected={classObj.id} // false
                    options={classes}
                    thirdOption="classId"
                    disabled={loading || loadClass}
                    extraTitle="classArm"
                    setSelected={(val) => {
                      setSpreadSheetObj(null);
                      setClassObj({
                        ...classObj,
                        id: val.classId,
                        name: `${val.className}  ${val.classArm}
                    
                    `,
                      });
                    }}
                    color="white"
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={1}>
                <AuthButton
                  style={{ marginTop: "0.3rem" }}
                  bg="white"
                  mt="0rem"
                  color={colors.primary}
                  onClick={getCummSheet}
                  disabled={session.id === "" || classObj.id === "" || loading}
                  width={isMobile ? "100%" : "90%"}
                >
                  {loading ? (
                    <FormLoader color={colors.primary} />
                  ) : (
                    "Get Sheet"
                  )}
                </AuthButton>
              </InlineLabelWrapper>
            </InlineLabelGroup>
          </TabContainer>
        ))}

      {spreadSheetObj !== null && (
        <div
          style={{
            backgroundColor: colors.primary,
            borderRadius: "10px",
            marginTop: "0.9rem",
            padding: "0.6rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "white",
            fontWeight: "bold",
          }}
        >
          <div>
            <span>
              Term Spread Sheet {session.name}{" "}
              {session.name.length > 0 ? "-" : ""} {term.name}{" "}
              {term.name.length > 0 && classObj.name.length > 0 ? "-" : ""}
              {classObj.name}
            </span>
          </div>
          <button
            onClick={() => setShowSheet(1)}
            style={{
              backgroundColor: "white",
              color: colors.primary,
              padding: "5px 10px",
              borderRadius: "9px",
            }}
          >
            Show Term Spread Sheet
          </button>
          {/* {showForm ? (
            <BiSolidDownArrow color="white" />
          ) : (
            <BiSolidUpArrow color="white" />
          )} */}
        </div>
      )}

      {cummSpreadSheetObj !== null && (
        <div
          style={{
            backgroundColor: colors.primary,
            borderRadius: "10px",
            marginTop: "0.9rem",
            padding: "0.6rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "white",
            fontWeight: "bold",
          }}
        >
          <div>
            <span>
              Cummulative Spread Sheet {session.name}{" "}
              {session.name.length > 0 ? "-" : ""} {classObj.name}
            </span>
          </div>
          <button
            onClick={() => setShowSheet(2)}
            style={{
              backgroundColor: "white",
              color: colors.primary,
              padding: "5px 10px",
              borderRadius: "9px",
            }}
          >
            Show Cummulative Spread Sheet
          </button>
        </div>
      )}
      {displaySheet()}
    </>
  );
};

export default ResultReport;
