import React from 'react'
import { Box, Button, IconButton, Typography, MenuItem, Popover,MenuList } from '@mui/material'
import Title from '../Dashboard/Title'
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputBase from "@mui/material/InputBase";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: "rgba(103, 30, 120, 0.1)",
      fontFamily: "Gilroy-Bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontFamily: "Gilroy-Regular",
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: " rgba(103, 30, 120, 0.1)",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  function createData(
    session,
    createdBy,
    phone,
    studentClass,
    name,
    status,
    email
  ) {
    return { session, createdBy, phone, studentClass, name, status, email };
  }
  
  const rows = [
    createData(
      "2021/2022",
      "Olaoluwa Thomson",
      "What is the design process according to James John",
      "Jss 2 Grace",
      "Adeola Grace (2)",
      "Submitted",
      "abc@gmail.com"
    ),
    createData(
      "2021/2022",
      "Olaoluwa Thomson",
      "What is the design process according to James John",
      "Jss 1 Purple",
      "Lawal Tolulope(2)",
      "Submitted",
      "abc@gmail.com"
    ),
    createData(
      "2021/2022",
      "Olaoluwa Thomson",
      "What is the design process according to James John",
      "Jss 2 Grace",
      "Mrs Adeniyi",
      "Saved",
      "abc@gmail.com"
    ),
  
  ];
export const CBTThird = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };
  
    
      const open = Boolean(anchorEl);
      const id = open ? "simple-popover" : undefined;
  return (
 <>
   <Box
       
        >
            <Box    sx={{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center'
          }}>
            <Box>
          <Title title="CBT - Add Question" />
         <Typography variant='h6' sx={{fontFamily:'Gilroy-Medium'}}>English Language(Oral English)</Typography>
         </Box>
         <Button variant='contained' sx={{textTransform:'initial', fontFamily:'Gilroy-Regular'}}>
            Add Question
         </Button>
         </Box>
         <Box mt={3}>
            <Link to="/cbt">
            <IconButton sx={{bgcolor:'#671e781a'}}>
<KeyboardBackspaceIcon sx={{color:'#671E78'}}/>

            </IconButton>
            </Link>
            <Typography variant='caption' sx={{fontFamily:'Gilroy-Medium', ml:2}}>Back to Quiz</Typography>
         </Box>
         <Box
      mt={5}
      sx={{
        border: "1px solid #671E78",
        borderRadius: "20px",
      }}
    >
      <Box
        sx={{
          borderRadius: "20px 20px 0px 0px",
          pt: 4,
          background: "rgba(103, 30, 120, 0.1)",
          pl: { xs: 2.5 },
        }}
      >
      </Box>
      <TableContainer>
        <Table
          sx={{ minWidth: 700, fontFamily: "Gilroy-Regular" }}
          aria-label="customized table"
        >
          <TableHead
            sx={{
              backgroundColor: "rgba(103, 30, 120, 0.1)",
              width: "100%",
            }}
          >
            <TableRow>
              <StyledTableCell>Question</StyledTableCell>
         

              <StyledTableCell align='right'></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
            {rows.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {row.phone}
                </StyledTableCell>
          

                <StyledTableCell align='right'>
                <IconButton onClick={handleClick }>
                    <MoreVertIcon />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
        </Box>


        <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box
        sx={{
          bgcolor: "#671E78",
          color: "#fff",
          fontFamily: "Gilroy-Regular",
          px: 3,
        }}
      >
        <MenuList >
          <MenuItem sx={{ fontFamily: "Gilroy-Regular" }}>Edit</MenuItem>
          <MenuItem sx={{ fontFamily: "Gilroy-Regular" }}>Delete</MenuItem>
  
          
        </MenuList>
      </Box>
    </Popover>
 </>
  )
}
