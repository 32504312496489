import { toast } from "react-toastify";

export default class ErrorService {
  // eslint-disable-next-line no-useless-constructor
  constructor() {}

  static onError(error) {
    console.log(error.message);
  }

  static initHandler() {
    const scope = this;
    window.onerror = (message, url, lineNo, columnNo, error) => {
      console.log(error, "test");

      if (error) {
        scope.onError(error);
        console.log(message, url, lineNo, columnNo, error);
      }
    };
  }

  static displayErrorAlert(error) {
    console.log(error);

    if (error.errors && Object.keys(error.errors).length) {
      let errorMessage = "";
      const errorKeys = Object.keys(error.errors);

      for (let i = 0; i < errorKeys.length; i++) {
        errorMessage += error.errors[errorKeys[i]][0] + " ";
      }

      return toast(errorMessage.trim(), {
        autoClose: 3000,
        position: toast.POSITION.BOTTOM_LEFT,
        type: "error",
        theme: "colored",
      });
    }

    toast(
      error?.Message ||
        error?.message ||
        error?.data?.message ||
        error?.data?.Message,
      {
        autoClose: 3000,
        position: toast.POSITION.BOTTOM_LEFT,
        type: "error",
        theme: "colored",
      }
    );
  }
}
