import { Box } from "@mui/system";
import {
  Link,
  useLocation,
  useSearchParams,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import logo from "../../assets/school-logo.png";
import {
  useMediaQuery,
  useTheme,
  Avatar,
  Button,
  List,
  Typography,
} from "@mui/material";
import GlobalStyles from "../../assets/globalStyle";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SSLink } from "../../pages/Dashboard/styles/dashboard.style";
import { useEffect } from "react";
let Body = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
    secondary: {
      main: "#edf2ff",
    },
  },
  typography: {
    fontFamily: "Gilroy-Regular",
    h1: {
      fontFamily: "Gilroy-Bold",
      fontSize: "52px",
    },
  },
});
const Navbar = ({ navData, pathname }) => {
  const location = useLocation();
  const parentId = new URLSearchParams(location.search).get("parent_id");
  const schoolId = new URLSearchParams(location.search).get("school_id");
  const studentId = new URLSearchParams(location.search).get("student_id");
  const staffId = new URLSearchParams(location.search).get("staff_id");
  const page_id = Number(new URLSearchParams(location.search).get("page")) || 1;
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  // const { slug } = useParams();

  return (
    <>
      <GlobalStyles />
      {isMatch ? (
        <Box></Box>
      ) : (
        <ThemeProvider theme={Body}>
          <Box
            sx={{
              width: "301px",
              bgcolor: "#671E78",
              display: "flex",
              // height: "100%",
              minHeight: "100vh",
              flexDirection: "column",
              alignItems: "center",
              pt: 5,
            }}
          >
            <Avatar
              src={logo}
              sx={{ bgcolor: "white", width: "85px", height: "85px", mb: 5 }}
            />
            <Box sx={{ width: "100%", mt: 4, mb: 3 }}>
              <Typography
                sx={{ ml: 6, color: "#fff", fw: "bold" }}
                variant="caption"
              >
                MENU
              </Typography>

              <List>
                {navData.map((nav, id) => (
                  <SSLink
                    key={id}
                    isActive={page_id === nav.id}
                    onClick={() => {
                      if (staffId) {
                        searchParams.staff_id = staffId;
                      }
                      if (studentId) {
                        searchParams.student_id = studentId;
                      }
                      if (parentId) {
                        searchParams.parent_id = parentId;
                      }
                      if (schoolId) {
                        searchParams.school_id = schoolId;
                      }
                      setSearchParams({
                        ...searchParams,
                        page: nav.id,
                        // school_id: schoolId,
                      });
                    }}
                  >
                    {nav.icon} <span>{nav.label}</span>
                  </SSLink>
                  // <div key={id}>kello</div>
                ))}
              </List>
            </Box>
            <Box>
              <Link
                // to={{
                //   pathname: pathname,
                // }}
                to={`${pathname}`}
              >
                <Button
                  // onClick={() => navigate(-1)}
                  variant="contained"
                  sx={{
                    bgcolor: "#fff",
                    color: "#671e78",
                    fontFamily: "gilroy-semibold",
                    textTransform: "inherit",
                    width: "200px",
                    mt: 6,
                    "&:hover": {
                      color: "#fff",
                    },
                  }}
                >
                  Back to previous
                </Button>
              </Link>
            </Box>
          </Box>
        </ThemeProvider>
      )}
    </>
  );
};

export default Navbar;
