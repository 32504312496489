import { Button, IconButton, MenuItem, MenuList } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { BsSearch } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputBase from "@mui/material/InputBase";
import Pagination from "@mui/material/Pagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Title from "../../components/Dashboard/Title";
import { Outlet } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
  },
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gilroy-Regular",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function createData(
  session,
  createdBy,
  phone,
  studentClass,
  name,
  status,
  email
) {
  return { session, createdBy, phone, studentClass, name, status, email };
}

const rows = [
  createData(
    "2021/2022",
    "Olaoluwa Thomson",
    "08132182983",
    "Jss 2 Grace",
    "Adeola Grace (2)",
    "Submitted",
    "abc@gmail.com"
  ),
  createData(
    "2021/2022",
    "Olaoluwa Thomson",
    "08132182983",
    "Jss 1 Purple",
    "Lawal Tolulope(2)",
    "Submitted",
    "abc@gmail.com"
  ),
  createData(
    "2021/2022",
    "Olaoluwa Thomson",
    "08132182983",
    "Jss 2 Grace",
    "Mrs Adeniyi",
    "Saved",
    "abc@gmail.com"
  ),
  createData(
    "2021/2022",
    "Olaoluwa Thomson",
    "08132182983",
    "Jss 2 Grace",
    "Mrs Adeniyi",
    "Submitted",
    "abc@gmail.com"
  ),
  createData(
    "2021/2022",
    "Olaoluwa Thomson",
    "08132182983",
    "Jss 2 Grace",
    "Mrs Adeniyi",
    "Submitted",
    "abc@gmail.com"
  ),
];

const CreateSchools = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <ThemeProvider theme={theme}>
  <Outlet/>
    </ThemeProvider>
  );
};

export default CreateSchools;
