import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Drawer, IconButton, List, Button } from "@mui/material";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/system";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { BsArrowLeftCircle, BsSearch } from "react-icons/bs";
import logo from "../../assets/school-logo.png";
import colors from "../../assets/colors.json";
import { checkAccess } from "../../services/utils";
import { useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  ControlPanelLink,
  ControlPanelNavTab,
  InlineFormDiv,
} from "../../pages/Dashboard/styles/dashboard.style";
import { InputWrapper, SmallText } from "../../pages/auth/auth.style";
import { CiSearch } from "react-icons/ci";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../features/auth/authSlice";

let Body = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
    secondary: {
      main: "#edf2ff",
    },
  },
  typography: {
    fontFamily: "Gilroy-Regular",
    h1: {
      fontFamily: "Gilroy-Bold",
      fontSize: "52px",
    },
  },
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const CPDrawers = ({ title, navData }) => {
  const { accessObj, user } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const page_id = Number(new URLSearchParams(location.search).get("page")) || 1;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <>
      <ThemeProvider theme={Body}>
        <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
          <List
            sx={{
              background: colors.lightGray,

              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <Box
              sx={{
                width: "301px",
                bgcolor: colors.lightGray,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                pt: 5,
              }}
            >
              <Avatar
                src={logo}
                sx={{ bgcolor: "white", width: "85px", height: "85px", mb: 5 }}
              />

              <ControlPanelNavTab>
                <SmallText style={{ textAlign: "center" }}>
                  {user.name}
                </SmallText>
                <SmallText
                  style={{
                    textAlign: "center",
                    textOverflow: "ellipsis",
                    marginBottom: "0.2rem",
                  }}
                >
                  {user.schoolName}
                </SmallText>
                <Link to="/controlpanel">
                  <BsArrowLeftCircle />
                  <span style={{ fontWeight: "bold", fontSize: "22px" }}>
                    Control Panel
                  </span>
                </Link>
              </ControlPanelNavTab>

              <InlineFormDiv style={{ width: "90%" }}>
                <div style={{ width: "100%" }}>
                  <InputWrapper style={{ position: "relative" }}>
                    <CiSearch
                      style={{
                        position: "absolute",
                        left: "0.5rem",
                        top: "64%",
                        fontSize: "27px",
                        color: colors.gray,
                        transform: "translateY(-50%)",
                      }}
                    />
                    <input
                      type="text"
                      placeholder=""
                      style={{ paddingLeft: "2.3rem" }}
                    />
                  </InputWrapper>
                </div>
              </InlineFormDiv>
              <Box sx={{ width: "85%", mt: 4, mb: 3 }}>
                <SmallText
                  color="black"
                  style={{
                    fontSize: "18px",
                    fontWeight: "Semibold",
                    marginLeft: "0.5rem",
                  }}
                >
                  {title}
                </SmallText>
              </Box>
              <Box sx={{ width: "90%", mt: 4 }}>
                <List>
                  {navData.map((nav, id) => (
                    <div key={id}>
                      {checkAccess(accessObj.menuItems, nav.label) && (
                        <ControlPanelLink
                          key={id}
                          onClick={() => {
                            setSearchParams({ ...searchParams, page: nav.id });
                          }}
                          isActive={page_id === nav.id}
                        >
                          {nav.icon}
                          <span>{nav.label}</span>
                        </ControlPanelLink>
                      )}
                    </div>
                  ))}
                </List>
              </Box>
              <Box sx={{ pl: 3, mt: 4 }}>
                <Button
                  variant=""
                  sx={{ color: "#fff", fontFamily: "Gilroy-regular" }}
                  onClick={() => {
                    dispatch(logout());
                    navigate("/");
                  }}
                >
                  <LogoutIcon sx={{ mr: 2 }} /> Logout
                </Button>
              </Box>
            </Box>
          </List>
        </Drawer>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pt: 5,
            pb: 3,
            px: 4,
          }}
        >
          <Avatar>S</Avatar>

          <Search
            sx={{
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
              ml: 2,
            }}
          >
            <SearchIconWrapper>
              <BsSearch />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "15%",
            }}
          >
            <Badge badgeContent={2} color="error">
              <NotificationsNoneIcon />
            </Badge>
            <IconButton
              sx={{ color: "#000" }}
              onClick={() => setOpenDrawer(!openDrawer)}
            >
              <MenuIcon />
            </IconButton>

            {/* <MenuIcon style={{ fontSize: "25px", cursor:'pointer' }} /> */}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default CPDrawers;
