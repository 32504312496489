import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import colors from "../../assets/colors.json";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import {
  InlineLabelGroup,
  InlineLabelWrapper,
  TabContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import { AuthButton, InputWrapper } from "../../pages/auth/Login.style";
import Select from "../select/select";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import FormLoader from "../FormLoader";
import ErrorService from "../../services/errorService";
import { getSessions, GetRemarkClasses } from "../../services/Subject";
import { getSubjectReport } from "../../services/ParentStudent";
import SubjectReportSheet from "./SubjectReportSheet";
import { TeacherClassSubject } from "../../services/Subject";

const ReportSubject = () => {
  const { admToken, user } = useSelector((state) => state.auth);
  const [sessions, setSessions] = useState([]);
  const [classes, setClasses] = useState([]);
  const [showForm, setShowForm] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showSheet, setShowSheet] = useState(false);
  const [loadClass, setLoadClass] = useState(false);
  const [spreadSheetObj, setSpreadSheetObj] = useState(null);
  const [subjectsLoading, setSubjectsLoading] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [classObj, setClassObj] = useState({
    id: "",
    name: "",
  });
  const [session, setSession] = useState({
    id: "",
    name: "",
  });

  useEffect(() => {
    async function getData() {
      try {
        const res = await getSessions(admToken);
        if (res.status && res.statusCode === 2) {
          setSessions(res.data);
        }
        (res.status === 500 || res.data.status === 400 || !res.status) &&
          ErrorService.displayErrorAlert(res.data);
      } catch (error) {
        console.log("Error OCurred");
      }
    }
    getData();
  }, [admToken]);

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const getClasses = useCallback(
    async (id) => {
      try {
        setLoadClass(true);
        const res = await GetRemarkClasses(admToken, user.schoolId, id);
        console.log(res);
        res.status && res.statusCode === 2 && setClasses(res.data);
        (res.status === 500 || res.status === 400 || !res.status) &&
          ErrorService.displayErrorAlert(res);
        setLoadClass(false);
      } catch (error) {
        console.log(error);
        setLoadClass(false);
      }
    },

    [admToken, user]
  );

  const getClassSubjects = useCallback(
    async (par) => {
      try {
        setSubjectsLoading(true);
        const res = await TeacherClassSubject(admToken, user.schoolId, par);
        if (res.status && res.statusCode === 2) {
          setSubjects(res.data);
        } else {
          ErrorService.displayErrorAlert(res);
        }
        setSubjectsLoading(false);
        console.log(res);
      } catch (error) {
        setSubjectsLoading(false);
        console.log(error);
      }
    },
    [admToken, user]
  );

  const getSheet = useCallback(async () => {
    setLoading(true);

    try {
      const res = await getSubjectReport(
        admToken,
        classObj.id,
        session.id,
        user.schoolId
      );
      if (res.status && res.statusCode === 2) {
        setSpreadSheetObj(res.data);
        setShowForm(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("An error Occurred");
      console.log(error);
    }
  }, [admToken, classObj.id, session.id]);

  return (
    <>
      <div
        onClick={() => setShowForm(!showForm)}
        style={{
          backgroundColor: colors.primary,
          borderRadius: "10px",
          marginTop: "0.3rem",
          padding: "0.4rem",
          display: "flex",
          justifyContent: "space-between",
          color: "white",
          fontWeight: "bold",
        }}
      >
        <div>
          <span>
            {session.name} {session.name.length > 0 ? "-" : ""} {classObj.name}
          </span>
        </div>

        {showForm ? (
          <BiSolidDownArrow color="white" />
        ) : (
          <BiSolidUpArrow color="white" />
        )}
      </div>
      {showForm && (
        <TabContainer
          style={{
            marginTop: "0.5rem",
            background: colors.primary,
            paddingBottom: "10px",
            paddingTop: "10px",
          }}
        >
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  color="white"
                  title="Session"
                  index="session" // no
                  disabled={loading}
                  selected={session.id} // false
                  options={sessions}
                  setSelected={(val) => {
                    setClassObj({ ...classObj, id: "", name: "" });
                    setSession({
                      ...session,
                      id: val.id,
                      name: val.session,
                    });
                    getClasses(val.id);

                    setSpreadSheetObj(null);
                  }}
                />
              </InputWrapper>
            </InlineLabelWrapper>

            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title={loadClass ? "Loading" : "Class"}
                  index="className" // no
                  selected={classObj.id} // false
                  options={classes}
                  thirdOption="classId"
                  disabled={loading || loadClass}
                  extraTitle="classArm"
                  setSelected={(val) => {
                    setSpreadSheetObj(null);
                    setClassObj({
                      ...classObj,
                      id: val.classId,
                      name: `${val.className}  ${val.classArm}`,
                      category: val.category,
                    });
                    getClassSubjects(val.category);
                  }}
                  color="white"
                />
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={1}>
              <AuthButton
                style={{ marginTop: "0.3rem" }}
                bg="white"
                mt="0rem"
                color={colors.primary}
                onClick={getSheet}
                disabled={session.id === "" || classObj.id === "" || loading}
                width={isMobile ? "100%" : "90%"}
              >
                {loading ? <FormLoader color={colors.primary} /> : "Get Report"}
              </AuthButton>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </TabContainer>
      )}
      {spreadSheetObj !== null && (
        <div
          style={{
            backgroundColor: colors.primary,
            borderRadius: "10px",
            marginTop: "0.9rem",
            padding: "0.6rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "white",
            fontWeight: "bold",
          }}
        >
          <div>
            <span>
              Assigned subjects {session.name}{" "}
              {session.name.length > 0 ? "-" : ""} {classObj.name}
            </span>
          </div>
          <button
            onClick={() => setShowSheet(true)}
            style={{
              backgroundColor: "white",
              color: colors.primary,
              padding: "5px 10px",
              borderRadius: "9px",
            }}
          >
            Show Report
          </button>
          {/* {showForm ? (
            <BiSolidDownArrow color="white" />
          ) : (
            <BiSolidUpArrow color="white" />
          )} */}
        </div>
      )}
      {showSheet && spreadSheetObj !== null && (
        <SubjectReportSheet
          close={() => setShowSheet(false)}
          resultData={spreadSheetObj}
          note={`${classObj.name} - ${session.name}`}
          subjects={subjects}
        />
      )}
    </>
  );
};

export default ReportSubject;
