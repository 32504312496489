/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import colors from "../../assets/colors.json";
import {
  AccountSummaryWrapper,
  TabContainer,
  Icon,
  Input,
  Search,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import Pagination from "../pagination_one/pagination";
import { BsSearch } from "react-icons/bs";
import PropTypes from "prop-types";
import { paginateData } from "../../services/utils";
import FormLoader from "../FormLoader";
import Loader from "../pageLoader/loader";
const CategoriesTable = ({
  data,
  deleteAction,
  obj,
  setObj,
  delLoading,
  openModal,
  loading,
}) => {
  const [list, setList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffSet, setitemOffSet] = useState(0);

  useEffect(() => {
    paginateData(
      data,
      setList,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [data, currentPage]);
  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }
  return (
    <>
      <TabContainer
        style={{
          marginTop: "0px",
          borderRadius: "0px",
          border: "none",
          paddingBottom: "0px",
        }}
      >
        <TabLinkContainer
          bg="rgb(238, 224, 241)"
          style={{ position: "relative", borderRadius: "0px" }}
        >
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search" />
          </Input>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table
            style={{
              borderRadius: "0px",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <tr
              className="table-head"
              style={{
                color: "black",
                fontWeight: "bold",
              }}
            >
              <th style={{ textAlign: "center" }}>Category Name</th>
              <th></th>{" "}
            </tr>
            {list.length > 0 ? (
              list.map((item, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center" }}>{item.category}</td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        style={{
                          backgroundColor: colors.primary,
                          padding: "0.4rem 0.8rem",
                          color: "white",
                          borderRadius: "10px",
                          marginRight: "0.2rem",
                        }}
                        onClick={() => {
                          setObj({
                            ...obj,
                            id: item.id,
                            category: item.category,
                            schoolId: item.schoolid,
                          });
                          openModal(true);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => {
                          let newObj = Object.assign(obj, item);
                          setObj(newObj);
                          deleteAction(item.id);
                        }}
                        style={{
                          marginLeft: "0.2rem",
                          color: "white",
                          padding: "0.4rem 0.8rem",
                          backgroundColor: "red",
                          borderColor: "red",
                          borderRadius: "10px",
                        }}
                      >
                        {obj.id === item.id && delLoading ? (
                          <FormLoader />
                        ) : (
                          "Delete"
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  No Category Available
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      {pageCount > 1 && (
        <div
          style={{
            marginTop: "2px",
            marginBottom: "2px",
          }}
        >
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            range={3}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </>
  );
};

CategoriesTable.propTypes = {
  data: PropTypes.array,
  deleteAction: PropTypes.func,
  setObj: PropTypes.func,
  obj: PropTypes.object,
  delLoading: PropTypes.bool,
  loading: PropTypes.bool,
  openModal: PropTypes.func,
};

CategoriesTable.defaultProps = {
  data: [],
  delLoading: false,
  loading: true,
};
export default CategoriesTable;
