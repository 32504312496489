import React, {
  useState,
  createRef,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  PageWrapper,
  SlidingImg,
  FormFlex,
  FormFlexDiv,
  SmallHeader,
} from "../../../pages/Dashboard/styles/dashboard.style";
import PropTypes from "prop-types";
import api from "../../../services/ControlPanel";
import { InputWrapper, AuthButton } from "../../../pages/auth/Login.style";
import Avatar from "../../../assets/Images/avatar.jpg";
import colors from "../../../assets/colors.json";
import FormLoader from "../../FormLoader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ErrorService from "../../../services/errorService";
import { checkAccess } from "../../../services/utils";
const Board = ({ arr, getData }) => {
  const [query, setQuery] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { admToken, accessObj } = useSelector((state) => state.auth);
  const myRef = useRef();
  const onPickBoardFileRef = createRef();
  const [formIsValid, setFormIsValid] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _obj = {
    fullName: "",
    position: "",
    profileImage: "",
    description: "",
    address: "",
    socialMediaLink: "",
  };
  const [boardObj, setBoardObj] = useState(_obj);
  const pickFile = (ref) => {
    ref.current.click();
  };
  const onPickFile = (event) => {
    const files = event.target.files;
    let filename = files[0].name;
    console.log(files[0]);
    if (
      filename.includes(".jpg") ||
      filename.includes(".png") ||
      filename.includes(".jpeg")
    ) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        setBoardObj({ ...boardObj, profileImage: fileReader.result });
      });
      fileReader.readAsDataURL(files[0]);
    } else {
      alert("Please Upload A Photo");
    }
  };

  useEffect(() => {
    if (
      boardObj.fullName !== "" &&
      boardObj.address !== "" &&
      boardObj.profileImage !== "" &&
      boardObj.description !== "" &&
      boardObj.position !== "" &&
      boardObj.socialMediaLink !== ""
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [boardObj]);
  const submitBoard = useCallback(async () => {
    setFormLoading(true);

    try {
      const res = await api.postControlPanelLasmabSetup(
        boardObj,
        admToken,
        boardObj.id ? "Update-lasmab-BoardTeam" : "AddBoardTeam"
      );

      if (res.status && res.statusCode === 2) {
        setBoardObj(Object.assign({}, _obj));
        toast.success(res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        getData();
      }
      res.status === 500 && ErrorService.displayErrorAlert(res.data);
      res.status === 400 && ErrorService.displayErrorAlert(res.data);
      !res.status && ErrorService.displayErrorAlert(res);
      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);

      console.log(error);
    }
  }, [_obj, admToken, boardObj, getData]);
  const deleteItem = useCallback(
    async (id) => {
      setDeleteLoading(true);
      try {
        const res = await api.deleteControlPanelLasmabSetup(
          id,
          admToken,
          "BoardTeam"
        );
        if (res.status && res.statusCode === 2) {
          toast.success(res.message, {
            autoClose: 4000,
            position: toast.POSITION.BOTTOM_LEFT,
          });
          // setFile({ ...file, name: "", base64: "" });
          getData();
        }
        res.status === 500 && ErrorService.displayErrorAlert(res.data);
        res.status === 400 && ErrorService.displayErrorAlert(res.data);
        setDeleteLoading(false);
      } catch (error) {
        setDeleteLoading(false);
        console.log(error);
      }
    },
    [admToken, getData]
  );

  return (
    <PageWrapper width="95%" ref={myRef}>
      <FormFlex reverse={true} style={{}}>
        <FormFlexDiv width="75%">
          <InputWrapper>
            <label htmlFor="">Name</label>
            <input
              type="text"
              value={boardObj.fullName}
              onChange={(e) =>
                setBoardObj({ ...boardObj, fullName: e.target.value })
              }
              placeholder="Board Member's name"
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="">Position</label>
            <input
              type="text"
              value={boardObj.position}
              onChange={(e) =>
                setBoardObj({
                  ...boardObj,
                  position: e.target.value,
                })
              }
              placeholder="Board Member's Role"
            />
          </InputWrapper>
        </FormFlexDiv>
        <FormFlexDiv
          width="23%"
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: "15px",
          }}
        >
          <img
            src={boardObj.profileImage !== "" ? boardObj.profileImage : Avatar}
            alt=""
            style={{
              width: "150px",
              height: "160px",
              borderRadius: "10px",
            }}
          />

          <input
            type="file"
            style={{ display: "none" }}
            onChange={(e) => onPickFile(e)}
            ref={onPickBoardFileRef}
          />

          <button
            style={{
              color: "white",
              marginTop: "4px",
              backgroundColor: colors.primary,
              padding: "5px",
              borderRadius: "5px",
            }}
            onClick={() => pickFile(onPickBoardFileRef)}
          >
            Change Photo
          </button>
        </FormFlexDiv>
      </FormFlex>
      <InputWrapper>
        <label htmlFor="">Address</label>
        <input
          type="text"
          value={boardObj.address}
          onChange={(e) =>
            setBoardObj({ ...boardObj, address: e.target.value })
          }
          placeholder="Address Of The Member"
        />
      </InputWrapper>

      <InputWrapper>
        <label htmlFor="about-us">Profile</label>
        <textarea
          name=""
          id=""
          cols="30"
          placeholder="Board's Member Profile"
          rows="5"
          value={boardObj.description}
          onChange={(e) =>
            setBoardObj({ ...boardObj, description: e.target.value })
          }
        ></textarea>
      </InputWrapper>

      <SmallHeader style={{ fontWeight: "bold", marginTop: "10px" }}>
        Social Media Link
      </SmallHeader>
      <div style={{ marginTop: "10px" }}>
        <InputWrapper>
          <label
            htmlFor="facebook"
            style={{ display: "block", fontWeight: "bold" }}
          >
            Facebook
          </label>
          <input
            type="text"
            value={boardObj.socialMediaLink}
            placeholder="Social Media Link"
            onChange={(e) =>
              setBoardObj({
                ...boardObj,
                socialMediaLink: e.target.value,
              })
            }
          />
        </InputWrapper>
        <div style={{ display: "inline-flex", marginTop: "10px" }}>
          {boardObj.fullName.length > 0 && (
            <div
              style={{
                padding: "0.4rem 1.1rem",
                border: `1px solid ${colors.primary}`,
                borderRadius: "5px",
                marginRight: "5px",
                background: "transparent",
                fontWeight: "bold",
              }}
            >
              <span>@{boardObj.fullName.replace(" ", "")}</span>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          borderBottom: `2px solid ${colors.primary}`,
          paddingBottom: "10px",
        }}
      >
        {((boardObj.id && checkAccess(accessObj.rights, "Edit")) ||
          checkAccess(accessObj.rights, "Save")) && (
          <AuthButton
            mt="0.3rem"
            onClick={() => submitBoard()}
            disabled={!formIsValid || formLoading}
          >
            {formLoading ? (
              <FormLoader />
            ) : (
              <> {boardObj.id ? "Update Member" : "Add Member"}</>
            )}
          </AuthButton>
        )}

        {boardObj.id && (
          <AuthButton
            style={{ marginLeft: "0.3rem" }}
            mt="0.3rem"
            bg="red"
            onClick={() => setBoardObj(Object.assign({}, _obj))}
          >
            Clear Fields
          </AuthButton>
        )}
      </div>

      {arr.length > 0 ? (
        arr.map((item, index) => (
          <SlidingImg key={index}>
            <div>
              <img
                src={
                  item.profileImage === "" || item.profileImage === null
                    ? Avatar
                    : item.profileImage
                }
                alt=""
              />
              <span>{item.fullName}</span>
              <span>{item.position}</span>
            </div>
            <div>
              {checkAccess(accessObj.rights, "Edit") && (
                <button
                  style={{ backgroundColor: colors.primary, color: "white" }}
                  onClick={() => {
                    const element = myRef.current;
                    const extraOffset = 50; // Adjust this value to your preference
                    window.scrollTo({
                      top: element.offsetTop - extraOffset,
                      behavior: "smooth",
                    });
                    setBoardObj(Object.assign({}, item));
                  }}
                >
                  Edit
                </button>
              )}

              {checkAccess(accessObj.rights, "Delete") && (
                <button
                  disabled={query === item.id && deleteLoading}
                  onClick={() => {
                    console.log("here");
                    setQuery(item.id);
                    deleteItem(item.id);
                  }}
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    borderColor: "red",
                  }}
                >
                  {query === item.id && deleteLoading
                    ? "Please wait..."
                    : "Delete"}
                </button>
              )}
            </div>
          </SlidingImg>
        ))
      ) : (
        <SmallHeader>No Board Team At The Moment</SmallHeader>
      )}
    </PageWrapper>
  );
};

Board.propTypes = {
  arr: PropTypes.array,
  getData: PropTypes.func,
};

Board.defaultProps = {
  arr: [],
};
export default Board;
