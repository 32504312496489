import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  FormFlex,
  FormFlexDiv,
  PageWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
import DatePickerComponent from "../datepicker/DatePicker";
import Loader from "../pageLoader/loader";
import { useLocation } from "react-router-dom";
import colors from "../../assets/colors.json";
import { InputWrapper } from "../../pages/auth/auth.style";
import { toast } from "react-toastify";
import { GetStudentMedicalInfo } from "../../services/ParentStudent";
const HealthRecordPersonalData = ({ studentId }) => {
  const [bioData, setBioData] = useState(null);
  const location = useLocation();
  const schoolId = new URLSearchParams(location.search).get("school_id") || "";
  const { admToken } = useSelector((state) => state.auth);
  // const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  // const token = localStorage.lasmabAuthToken || "";
  const [loading, setLoading] = useState(true);
  // const [dob, setDob] = useState(new Date(2014, 0, 3));

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      let res = await GetStudentMedicalInfo(
        admToken,
        studentId,
        schoolId,
        "personal-data"
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setBioData(res.data);
        setLoading(false);
      } else {
        toast.error(res.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error("An Error Occured! Try Again Later");
      setLoading(false);
      // console.log(error);
    }
  }, [admToken, schoolId, studentId]);

  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : bioData ? (
        <PageWrapper>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="full name">Full name</label>
                <input
                  type="text"
                  value={bioData.fullName}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                  placeholder="Enter Your Full Name"
                />
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="age">Age</label>
                <input
                  type="text"
                  value={bioData.age}
                  disabled={true}
                  placeholder="Age"
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="Date_of_Birth">Date of Birth</label>
                <DatePickerComponent
                  selected={new Date(bioData.birthDate)}
                  setSelected={(val) => {
                    console.log(val);
                  }}
                  disabled={true}
                />
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="age">Place of Birth</label>
                <input
                  type="text"
                  value={bioData.birthPlace}
                  placeholder="Place of Birth"
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          {/* parents */}
          <div
            style={{
              marginTop: "15px",
            }}
          >
            <p
              style={{
                fontFamily: "Gilroy-Bold",
                fontSize: "14px",
                color: "#000000",
                opacity: "0.54",
              }}
            >
              FOR PARENTS
            </p>
          </div>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="full name">Name of Parent</label>
                <input
                  type="text"
                  value={bioData.parentName}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                  placeholder="Parent's Name"
                />
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="address_parent">Address of Parent</label>
                <input
                  type="text"
                  value={bioData.parentAddress}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                  placeholder="33 Parker Street, New york USA."
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="telepohne_number">Telephone Number(HOME)</label>
                <input
                  type="text"
                  value={bioData.parentHomePhone}
                  placeholder="12345678901"
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="age">Telephone Number(OFFICE)</label>
                <input
                  type="text"
                  value={bioData.parentOfficePhone}
                  placeholder="12345654321"
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          {/* For Guardian */}
          <div
            style={{
              marginTop: "15px",
            }}
          >
            <p
              style={{
                fontFamily: "Gilroy-Bold",
                fontSize: "14px",
                color: "#000000",
                opacity: "0.54",
              }}
            >
              FOR GUARDIAN
            </p>
          </div>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="full name">Name of Guardian</label>
                <input
                  type="text"
                  value={bioData.guardianName}
                  onChange={(e) => console.log(e.target.value)}
                  disabled={true}
                  placeholder="Guardian's Name"
                />
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="guardian_parent">Address of Guardian</label>
                <input
                  type="text"
                  value={bioData.guardianAddress}
                  placeholder="33 Parker Street, New york USA."
                  onChange={(e) => console.log(e.target.value)}
                  disabled={true}
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="Date_of_Birth">Telephone Number(HOME)</label>
                <input
                  type="text"
                  value={bioData.guardianHomePhone}
                  placeholder="12345678901"
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="age">Telephone Number(OFFICE)</label>
                <input
                  type="text"
                  value={bioData.guardianOfficePhone}
                  placeholder="12345678901"
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
        </PageWrapper>
      ) : (
        <div
          style={{
            fontWeight: "bold",
            textAlign: "center",
            color: colors.primary,
          }}
        >
          No Personal Data For Student FOund
        </div>
      )}
    </>
  );
};

export default HealthRecordPersonalData;
