import { useState, useCallback, useEffect } from "react";
import {
  Container,
  SmallHeader,
  TabContainer,
  TabContent,
  TabLink,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import GlobalStyles from "../../assets/globalStyle";
import colors from "../../assets/colors.json";
import PropTypes from "prop-types";
import Loader from "../pageLoader/loader";
import HealthRecord from "../medical/MedicalRecord";
import DrugUsage from "../medical/DrugUsage";
import InOutRecord from "../medical/InOutRecord";
import Complaints from "../medical/Complaints";
import { Box } from "@mui/material";
const Medical = ({ name, loading, studentId }) => {
  const [tab, setTab] = useState(3);

  if (loading) {
    return <Loader />;
  }
  return (
    <Container>
      <GlobalStyles />
      <Box
        borderBottom={`2px solid ${colors.primary}`}
        paddingBottom={"3px"}
        marginBottom={"24px"}
      >
        <SmallHeader
          style={{
            fontWeight: "bold",
            color: colors.lightGray,
            marginBottom: "1.9rem",
          }}
        >
          {/* <span style={{ marginRight: "0.9rem" }}>School</span> */}
          {name.length > 0 && (
            <>
              <span style={{ marginRight: "0.9rem" }}>
                Student Info- {name} {">>"}
              </span>
              <span style={{ color: "black" }}>Medical</span>
            </>
          )}
        </SmallHeader>
      </Box>
      {studentId === null ? (
        <SmallHeader>An Error Occurred, Please Try Again Later</SmallHeader>
      ) : (
        <>
          <TabContainer>
            <TabLinkContainer>
              <TabLink
                isActive={tab === 1}
                onClick={() => setTab(1)}
                style={{ marginTop: "5px" }}
              >
                <p>Medical Record</p>
              </TabLink>
              <TabLink
                isActive={tab === 2}
                onClick={() => setTab(2)}
                style={{ marginTop: "5px" }}
              >
                <p>Student Drug Usage</p>
              </TabLink>
              <TabLink
                isActive={tab === 3}
                onClick={() => setTab(3)}
                style={{ marginTop: "5px" }}
              >
                <p>Student IN/OUT Record</p>
              </TabLink>
              <TabLink
                isActive={tab === 4}
                onClick={() => setTab(4)}
                style={{ marginTop: "5px" }}
              >
                <p>Medical Complaints Form</p>
              </TabLink>
            </TabLinkContainer>

            <TabContent>
              {tab === 1 && <HealthRecord studentId={studentId} />}
              {tab === 2 && <DrugUsage studentId={studentId} />}
              {tab === 3 && <InOutRecord studentId={studentId} />}
              {tab === 4 && <Complaints studentId={studentId} />}
            </TabContent>
          </TabContainer>
        </>
      )}
    </Container>
  );
};

Medical.propTypes = {
  loading: PropTypes.bool,
  studentId: PropTypes.string,
};

Medical.defaultProps = {
  loading: false,
  studentId: null,
};
export default Medical;
