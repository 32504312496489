import React, { useState, useEffect } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import { IconButton, MenuItem, MenuList } from "@mui/material";
import PropTypes from "prop-types";
import {
  AccountSummaryWrapper,
  TabContainer,
  Icon,
  Input,
  Search,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import moment from "moment";
import Loader from "../pageLoader/loader";
import { Box } from "@mui/system";
import { BsSearch } from "react-icons/bs";
import { paginateData } from "../../services/utils";
import colors from "../../assets/colors.json";
import Pagination from "../pagination_one/pagination";
const AssetsTable = ({ data, obj, setObj, loading, openModal }) => {
  const [list, setList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffSet, setitemOffSet] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    paginateData(
      data,
      setList,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [data, currentPage]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <TabContainer
        style={{
          marginTop: "0px",
          borderRadius: "0px",
          border: "none",
          paddingBottom: "0px",
        }}
      >
        {/* Assets */}
        <TabLinkContainer
          bg="rgb(238, 224, 241)"
          style={{ position: "relative", borderRadius: "0px" }}
        >
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search" />
          </Input>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table
            style={{
              borderRadius: "0px",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <tr
              className="table-head"
              style={{ color: "black", fontWeight: "bold" }}
            >
              <th>Asset Name</th>
              <th>Model</th>
              <th>Tag ID</th>
              <th>Location</th>
              <th>Purchase Date</th>
              <th></th>{" "}
            </tr>
            {list.length > 0 ? (
              list.map((item, index) => (
                <tr key={index}>
                  <td>{item.assetName}</td>
                  <td>{item.model}</td>
                  <td>{item.tagId}</td>
                  <td>{item.location}</td>
                  <td>{moment(item.purchaseDate).format("DD/MM/YYYY")}</td>
                  <td>
                    <IconButton
                      onClick={(e) => {
                        let newObj = Object.assign(obj, item);
                        console.log(newObj);
                        setObj(newObj);
                        handleClick(e);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={6}
                  style={{ textAlign: "center", color: colors.primary }}
                >
                  No Assets Available
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>

        {/* ENd of Assets */}
      </TabContainer>
      {pageCount > 1 && (
        <div
          style={{
            marginTop: "2px",
            marginBottom: "2px",
          }}
        >
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            range={3}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem
              onClick={() => {
                openModal(true);
                handleClose();
              }}
            >
              Edit
            </MenuItem>
            {/* <MenuItem>Delete</MenuItem>
            <MenuItem>Deactivate</MenuItem> */}
          </MenuList>
        </Box>
      </Popover>
    </>
  );
};
AssetsTable.propTypes = {
  data: PropTypes.array,

  setObj: PropTypes.func,
  obj: PropTypes.object,

  loading: PropTypes.bool,
  openModal: PropTypes.func,
};

export default AssetsTable;
