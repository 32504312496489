import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";

import {
  ProfileWrapper,
  TabContentWrapper,
  WrapperCenter,
  WrapperLeft,
  WrapperRight,
} from "../../pages/Dashboard/styles/dashboard.style";

import PersonalData from "./PersonalData";
import ImmunizationHistory from "./ImmunizationHistory";
import MedicalHistory from "./MedicalHistory";
import ClinicalExamination from "./ClinicalExamination";
import PathologicalExamination from "./PathologicalExamination";
const HealthRecord = ({ studentId }) => {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [dataShow, setDataShow] = useState({
    accId: 1,
    accName: "accOne",
  });

  return (
    <TabContentWrapper>
      {/* accordion one */}
      <div
        style={{
          marginTop: "5px",
        }}
      >
        <ProfileWrapper
          onClick={() => {
            if (dataShow.accId === 1 && dataShow.accName === "accOne") {
              setDataShow({
                accId: "",
                accName: "",
              });
            } else {
              setDataShow({
                accId: 1,
                accName: "accOne",
              });
            }
          }}
        >
          <WrapperLeft>Personal Data</WrapperLeft>
          <WrapperCenter></WrapperCenter>
          <WrapperRight>
            {dataShow.accId === 1 ? (
              <BiSolidUpArrow color="white" />
            ) : (
              <BiSolidDownArrow color="white" />
            )}
          </WrapperRight>
        </ProfileWrapper>
        {dataShow.accName === "accOne" && (
          <PersonalData studentId={studentId} />
        )}
      </div>
      {/* accordion two */}
      <div style={{ marginTop: "5px" }}>
        <ProfileWrapper
          onClick={() => {
            if (dataShow.accId === 2 && dataShow.accName === "accTwo") {
              setDataShow({
                accId: "",
                accName: "",
              });
            } else {
              setDataShow({
                accId: 2,
                accName: "accTwo",
              });
            }
          }}
        >
          <WrapperLeft>Immunization history</WrapperLeft>
          <WrapperCenter></WrapperCenter>
          <WrapperRight>
            {dataShow.accId === 2 ? (
              <BiSolidUpArrow color="white" />
            ) : (
              <BiSolidDownArrow color="white" />
            )}
          </WrapperRight>
        </ProfileWrapper>
        {dataShow.accName === "accTwo" && (
          <ImmunizationHistory studentId={studentId} />
        )}
      </div>
      {/* Accordion Three */}
      <div style={{ marginTop: "5px" }}>
        <ProfileWrapper
          onClick={() => {
            if (dataShow.accId === 3 && dataShow.accName === "accThree") {
              setDataShow({
                accId: "",
                accName: "",
              });
            } else {
              setDataShow({
                accId: 3,
                accName: "accThree",
              });
            }
          }}
        >
          <WrapperLeft>Personal Medical history</WrapperLeft>
          <WrapperCenter></WrapperCenter>
          <WrapperRight>
            {dataShow.accId === 3 ? (
              <BiSolidUpArrow color="white" />
            ) : (
              <BiSolidDownArrow color="white" />
            )}
          </WrapperRight>
        </ProfileWrapper>
        {dataShow.accName === "accThree" && (
          <MedicalHistory studentId={studentId} />
        )}
      </div>
      {/* Accordion Fout */}
      <div style={{ marginTop: "5px" }}>
        <ProfileWrapper
          onClick={() => {
            if (dataShow.accId === 4 && dataShow.accName === "accFour") {
              setDataShow({
                accId: "",
                accName: "",
              });
            } else {
              setDataShow({
                accId: 4,
                accName: "accFour",
              });
            }
          }}
        >
          <WrapperLeft>Clinical Examination</WrapperLeft>
          <WrapperCenter></WrapperCenter>
          <WrapperRight>
            {dataShow.accId === 4 ? (
              <BiSolidUpArrow color="white" />
            ) : (
              <BiSolidDownArrow color="white" />
            )}
          </WrapperRight>
        </ProfileWrapper>

        {dataShow.accName === "accFour" && (
          <ClinicalExamination studentId={studentId} />
        )}
      </div>
      {/* Accordion Five */}
      <div style={{ marginTop: "5px" }}>
        <ProfileWrapper
          onClick={() => {
            if (dataShow.accId === 5 && dataShow.accName === "accFive") {
              setDataShow({
                accId: "",
                accName: "",
              });
            } else {
              setDataShow({
                accId: 5,
                accName: "accFive",
              });
            }
          }}
        >
          <WrapperLeft>Pathological Examination Result</WrapperLeft>
          <WrapperCenter></WrapperCenter>
          <WrapperRight>
            {dataShow.accId === 5 ? (
              <BiSolidUpArrow color="white" />
            ) : (
              <BiSolidDownArrow color="white" />
            )}
          </WrapperRight>
        </ProfileWrapper>
        {dataShow.accName === "accFive" && (
          <PathologicalExamination studentId={studentId} />
        )}
      </div>
    </TabContentWrapper>
  );
};

export default HealthRecord;
