import {
  Button,
  Box,
  Typography,
  IconButton,
  Divider,
  InputLabel,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import React from "react";
import Modal from "@mui/material/Modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputBase from "@mui/material/InputBase";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";
import LoaderLine from "../../LoaderLine";
import { useSelector } from "react-redux";
import axios from "axios";
import SuccessAlert from "../../SuccessAlert";
import ErrorAlert from "../../ErrorAlert";
import { useGetAllAssignmentsQuery } from "../../../api/assigmentAPi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  border: "1px solid #671E78",
  boxShadow: 24,
  p: 2,
  borderRadius: 3,
  height: 600,
  overflow: "scroll",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gilroy-Regular",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AddQuestion = ({
  id,
  fetchQuestions,
  subject,
  title,
  open,
  handleOpen,
  setOpen,
}) => {
  const [selectedFile, setSelectedFile] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState(false);
  const [question, setQuestion] = React.useState("");
  const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
  const api_version = process.env.REACT_APP_API_VERSION;
  const userInfo = useSelector((state) => state.auth.user);
  const adtoken = localStorage.getItem("admToken");

  const { refetch } = useGetAllAssignmentsQuery({
    schoolId: userInfo.schoolId,
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file from the input
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result;
        setSelectedFile(base64Data);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCreateQuestion = async () => {
    setResponseMessage(false);
    setErrorMessage(false);
    setLoading(true);
    const url = `${endpoint_two}/api/v${api_version}/Assignment/CreateQuestionAssignment`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adtoken}`,
    };
    const requestBody = {
      assignmentId: id,
      questionid: 0,
      cid: 0,
      question: question,
      filePath: selectedFile ?? "",
      mark: 0,
    };

    try {
      const response = await axios.post(url, requestBody, { headers });
      setResponseMessage(true);
      setLoading(false);
      fetchQuestions();
      refetch();
      setOpen(false);
      setQuestion("");
    } catch (error) {
      setErrorMessage(true);
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <LoaderLine />}
      {responseMessage && (
        <SuccessAlert
          openState={responseMessage}
          message={"Question created Successfully"}
        />
      )}
      {errorMessage && (
        <ErrorAlert
          openState={errorMessage}
          message={"Error, please try again"}
        />
      )}
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{ textTransform: "initial", fontFamily: "gilroy-regular", px: 5 }}
      >
        Add Question
      </Button>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box align="right">
            <IconButton onClick={() => setOpen(false)}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box align="center" sx={{ mb: 3 }}>
            <Typography sx={{ fontFamily: "gilroy-bold", fontSize: "30px" }}>
              Add Question
            </Typography>
            <Box className="flex items-center justify-center" sx={{ mb: 2 }}>
              <Typography sx={{ fontFamily: "gilroy-regular" }}>
                {subject} - {title}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ mt: 3, px: 3 }}>
            <InputLabel
              sx={{
                fontFamily: "gilroy-regular",
                color: "#333",
                fontSize: "12px",
                mb: 1,
              }}
            >
              Enter Question in the textbox below
            </InputLabel>
            <TextField
              size="large"
              fullWidth
              multiline
              rows={6}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              InputProps={{
                style: {
                  fontFamily: "Gilroy-regular",
                  fontSize: "13px",
                  borderRadius: "5px",
                  offset: " 0.5px solid #671E78",
                  border: " 0.5px solid #671E78",
                  // Replace with your desired font family
                },
              }}
            />
            <InputLabel
              sx={{
                fontFamily: "gilroy-regular",
                color: "#333",
                fontSize: "12px",
                mb: 1,
                mt: 3,
              }}
            >
              Upload Picture(optional)
            </InputLabel>
            <TextField
              type="file"
              fullWidth
              onChange={handleFileChange}
              //   size="small"
              InputProps={{
                style: {
                  fontFamily: "Gilroy-regular",
                  fontSize: "13px",
                  borderRadius: "5px",
                  offset: " 0.5px solid #671E78",
                  border: " 0.5px solid #671E78",
                  // Replace with your desired font family
                },
              }}
            />
          </Box>
          <Box sx={{ mt: 4 }}>
            {/* <TableContainer sx={{ borderRadius: 3 }}>
              <Table
                sx={{
                  minWidth: 500,
                  fontFamily: "Gilroy-Regular",
                  border: "1px solid #671E78",
                  borderRadius: 3,
                }}
                aria-label="customized table"
              >
                <TableHead
                  sx={{
                    backgroundColor: "rgba(103, 30, 120, 0.1)",
                    width: "100%",
                  }}
                >
                  <TableRow>
                    <StyledTableCell>S/N</StyledTableCell>
                    <StyledTableCell>File Name</StyledTableCell>
                    <StyledTableCell>Upload Date</StyledTableCell>

                    <StyledTableCell align="right"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
                  <StyledTableRow>
                    <StyledTableCell>
                  
                    </StyledTableCell>
                    <StyledTableCell>
                      Introduction to organic chemistry
                   
                    </StyledTableCell>

                    <StyledTableCell>12/2/2023</StyledTableCell>
                    <StyledTableCell align="right">
                      <Box>
                        <Button variant="outlined" size="small" sx={{ mr: 1 }}>
                          Delete
                        </Button>
                        <Button variant="outlined" size="small">
                          View
                        </Button>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>
             
                    </StyledTableCell>
                    <StyledTableCell>
                      Introduction to organic chemistry
      
                    </StyledTableCell>

                    <StyledTableCell>12/2/2023</StyledTableCell>
                    <StyledTableCell align="right">
                      <Box>
                        <Button variant="outlined" size="small" sx={{ mr: 1 }}>
                          Delete
                        </Button>
                        <Button variant="outlined" size="small">
                          View
                        </Button>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer> */}
          </Box>
          <Button
            disabled={!question}
            variant="contained"
            fullWidth
            sx={{
              mt: 4,
              textTransform: "initial",
              fontFamily: "gilroy-regular",
            }}
            onClick={handleCreateQuestion}
          >
            Add Question
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default AddQuestion;
