import React from 'react'
import Modal from "@mui/material/Modal";
import { Box, Typography, IconButton, Divider, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
border:'1px solid #671e78',
  boxShadow: 24,
  p: 3,
  borderRadius:2
};

const AssignStaff = ({open , onClose}) => {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box align="right">
            <IconButton onClick={onClose}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box sx={{ mb: 3 }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              sx={{ textAlign: "center", fontFamily: "gilroy-bold" }}
            >
              Assign Staff
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ mt: 2, pl:3 }}>
            <FormGroup>
              <FormControlLabel
                sx={{ "& > span": { fontFamily: "gilroy-regular" } }}
                control={<Checkbox />}
                label="Staff 1"
              />
              <FormControlLabel
                sx={{ "& > span": { fontFamily: "gilroy-regular" } }}
                control={<Checkbox />}
                label="Staff 2"
              />
              <FormControlLabel
                sx={{ "& > span": { fontFamily: "gilroy-regular" } }}
                control={<Checkbox />}
                label="Staff 3"
              />
              <FormControlLabel
                sx={{ "& > span": { fontFamily: "gilroy-regular" } }}
                control={<Checkbox />}
                label="Staff 4"
              />
              <FormControlLabel
                sx={{ "& > span": { fontFamily: "gilroy-regular" } }}
                control={<Checkbox />}
                label="Staff 5"
              />
              <FormControlLabel
                sx={{ "& > span": { fontFamily: "gilroy-regular" } }}
                control={<Checkbox />}
                label="Staff 6"
              />
              <FormControlLabel
                sx={{ "& > span": { fontFamily: "gilroy-regular" } }}
                control={<Checkbox />}
                label="Staff 7"
              />
            </FormGroup>
          </Box>
          <Button variant='contained' fullWidth sx={{mt:4, textTransform:'initial', fontFamily:'gilroy-regular'}}>Assign Staff</Button>
        </Box>
      </Modal>
    </>
  );
}

export default AssignStaff
