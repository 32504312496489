import React, { useState, useEffect, useCallback } from "react";
import {
  PageWrapper,
  FormFlex,
  FormFlexDiv,
  SmallHeader,
} from "../../../pages/Dashboard/styles/dashboard.style";
import PropTypes from "prop-types";
import { checkAccess, isNumber } from "../../../services/utils";
import api from "../../../services/ControlPanel";
import { InputWrapper, AuthButton } from "../../../pages/auth/Login.style";
import colors from "../../../assets/colors.json";
import FormLoader from "../../FormLoader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ErrorService from "../../../services/errorService";
const ContactUs = ({ dataObj, getData }) => {
  const { admToken, accessObj } = useSelector((state) => state.auth);
  const [formIsValid, setFormIsValid] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  let _obj = {
    email: "",
    phoneNumber: "",
    adminContact: "",
    helpLine: "",
    // socialMedial: "",
    address: "",
  };
  const [contactObj, setContactObj] = useState(_obj);
  useEffect(() => {
    if (dataObj !== null) {
      setContactObj(Object.assign({}, dataObj));

      setContactObj({
        ...contactObj,
        email: dataObj.email ? dataObj.email : "",
        phoneNumber: dataObj.phoneNumber ? dataObj.phoneNumber : "",
        adminContact: dataObj.adminContact ? dataObj.adminContact : "",
        helpLine: dataObj.helpLine ? dataObj.helpLine : "",
        socialMedial: dataObj.socialMedial ? dataObj.socialMedial : "",
        address: dataObj.address ? dataObj.address : "",
        id: dataObj.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      contactObj.address !== "" &&
      contactObj.email !== "" &&
      contactObj.phoneNumber !== "" &&
      // contactObj.adminContact !== "" &&
      contactObj.helpLine !== "" &&
      contactObj.socialMedial !== ""
    ) {
      // setContactForm(true);
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [contactObj]);
  const submitContact = useCallback(async () => {
    setFormLoading(true);
    try {
      const res = await api.postControlPanelLasmabSetup(
        contactObj,
        admToken,
        contactObj.id ? "Update-lasmab-BoardContactus" : "Add-ContactUs"
      );
      console.log(res);
      if (res.status && res.statusCode === 2) {
        toast.success(res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        getData();
      }
      if (res.status === 400 || res.status === 500 || res.status === false) {
        ErrorService.displayErrorAlert(res.data);
      }
      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);
      console.log(error);
    }
  }, [admToken, contactObj, getData]);
  return (
    <PageWrapper width="95%">
      <InputWrapper>
        <label htmlFor="address">Address</label>
        <input
          type="text"
          value={contactObj.address}
          placeholder="54 Road Block A Indiana"
          onChange={(e) =>
            setContactObj({ ...contactObj, address: e.target.value })
          }
        />
      </InputWrapper>

      <FormFlex reverse={true} style={{ alignItems: "center" }}>
        {/* <FormFlexDiv width="55%">
          <InputWrapper>
            <label htmlFor="">Name</label>
            <input
              type="text"
              value={contactObj.adminContact}
              onChange={(e) =>
                setContactObj({
                  ...contactObj,
                  adminContact: e.target.value,
                })
              }
              placeholder="Admin Contact's Name"
            />
          </InputWrapper>
        </FormFlexDiv> */}
        <FormFlexDiv width="55%">
          <InputWrapper>
            <label htmlFor="">Phone Number</label>
            <input
              onKeyDown={(e) => isNumber(e)}
              type="text"
              value={contactObj.phoneNumber}
              onChange={(e) =>
                setContactObj({
                  ...contactObj,
                  phoneNumber: e.target.value,
                })
              }
              placeholder="08012345678"
            />
          </InputWrapper>
        </FormFlexDiv>
      </FormFlex>
      <InputWrapper>
        <label htmlFor="">Help Line</label>
        <input
          onKeyDown={(e) => isNumber(e)}
          type="text"
          value={contactObj.helpLine}
          onChange={(e) =>
            setContactObj({
              ...contactObj,
              helpLine: e.target.value,
            })
          }
          placeholder="08012345678"
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="email">Email</label>
        <input
          type="text"
          placeholder="xyzEnterPrise@setup.com"
          value={contactObj.email}
          onChange={(e) =>
            setContactObj({
              ...contactObj,
              email: e.target.value,
            })
          }
        />
      </InputWrapper>
      <SmallHeader style={{ fontWeight: "bold", marginTop: "10px" }}>
        Social Media Link
      </SmallHeader>
      <div style={{ marginTop: "10px" }}>
        <InputWrapper>
          <label
            htmlFor="facebook"
            style={{ display: "block", fontWeight: "bold" }}
          >
            Facebook
          </label>
          <input
            type="text"
            placeholder="Social Media Link"
            value={contactObj.socialMedial}
            onChange={(e) =>
              setContactObj({
                ...contactObj,
                socialMedial: e.target.value,
              })
            }
          />
        </InputWrapper>
        {/* <div style={{ display: "inline-flex", marginTop: "10px" }}>
          {contactObj.adminContact.length > 0 && (
            <div
              style={{
                padding: "0.4rem 1.1rem",
                border: `1px solid ${colors.primary}`,
                borderRadius: "5px",
                marginRight: "5px",
                background: "transparent",
                fontWeight: "bold",
              }}
            >
              <span>@{contactObj.adminContact.replace(" ", "")}</span>
            </div>
          )}
        </div> */}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          borderBottom: `2px solid ${colors.primary}`,
          paddingBottom: "10px",
        }}
      >
        {checkAccess(accessObj.rights, "Save") && (
          <AuthButton
            onClick={() => submitContact()}
            disabled={!formIsValid || formLoading}
          >
            {formLoading ? <FormLoader /> : <>Update Info</>}
          </AuthButton>
        )}
      </div>
    </PageWrapper>
  );
};

ContactUs.propTypes = {
  dataObj: PropTypes.object,
  getData: PropTypes.func,
};

ContactUs.defaultProps = {
  dataObj: null,
};

export default ContactUs;
