/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  SmallHeader,
  TabContainer,
  TabLinkContainer,
  TabLink,
  TabContent,
} from "../../../pages/Dashboard/styles/dashboard.style";
import { useSelector } from "react-redux";
import ErrorService from "../../../services/errorService";

import { AuthButton } from "../../../pages/auth/Login.style";
import colors from "../../../assets/colors.json";
import ToggleListComp from "./ToogleListComp";
import Stats from "./Stats";
// import Menu from "./Menu";
// import Activities from "./Activities";
// import Rights from "./Rights";
import { useMediaQuery } from "react-responsive";
import api from "../../../services/ControlPanel";
import FormLoader from "../../FormLoader";
import MenuListComp from "./MenuListComp";
import { checkAccess } from "../../../services/utils";
import ActivitiesListComp from "./ActivitiesListComp";
const AssignPriviledge = ({
  role,
  setups,
  schoolId,
  reloadFunction,
  closeModal,
  createRole,
  staffSetup,
  showForm,
  update,
}) => {
  const [formLoading, setFormLoading] = useState(false);
  const getPresetPermissions = useCallback(() => {
    let arr = setups.filter((item) => item.roleId === role.roleId);
    if (arr.length > 0) {
      let obj = arr[0];
      setArr(obj?.dashboardStatids ? obj.dashboardStatids.split(",") : []);
      setMenuArr(obj?.menuIds ? obj.menuIds.split(",") : []);
      setRightArr(obj?.rightIds ? obj.rightIds.split(",") : []);
      setActArr(obj?.priviledgeIds ? obj.priviledgeIds.split(",") : []);
    }
    console.log(arr);
  }, [role.roleId, setups]);

  const getUpdatePreset = useCallback(() => {
    if (staffSetup !== null) {
      setArr(
        staffSetup?.dashboardStatids
          ? staffSetup.dashboardStatids.split(",")
          : []
      );
      setMenuArr(staffSetup?.menuIds ? staffSetup.menuIds.split(",") : []);
      setRightArr(staffSetup?.rightIds ? staffSetup.rightIds.split(",") : []);
      setActArr(
        staffSetup?.priviledgeIds ? staffSetup.priviledgeIds.split(",") : []
      );
    }
  }, [staffSetup]);

  const { admToken, accessObj, user } = useSelector((state) => state.auth);
  const [tab, setTab] = useState(1);
  const isTab = useMediaQuery({ maxWidth: 992 });
  const [loading, setLoading] = useState(true);
  // dashboard Stats
  const [stats, setStats] = useState([]);
  const [arr, setArr] = useState([]);
  // Menu List
  const [mLoading, setMLoading] = useState(true);
  const [menus, setMenus] = useState([]);
  const [menuArr, setMenuArr] = useState([]);
  // Rights
  const [rLoading, setRLoading] = useState(true);
  const [rights, setRights] = useState([]);
  const [rightArr, setRightArr] = useState([]);

  // activities
  const [aLoading, setALoading] = useState(true);
  const [acts, setActs] = useState([]);
  const [actArr, setActArr] = useState([]);

  useEffect(() => {
    const getDashboardItems = async () => {
      try {
        const res = update
          ? await api.getDashboardItemsStaff(admToken, user.staffId)
          : await api.getDashboardItems(admToken);
        console.log(res);
        if (res.status && res.statusCode === 2) {
          setStats(res.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    const getMenuItems = async () => {
      try {
        const res = update
          ? await api.getMenuItemsStaff(admToken, user.staffId)
          : await api.getMenuItems(admToken);
        console.log(res);
        if (res.status && res.statusCode === 2) {
          setMenus(res.data.mainMenus);
        }

        setMLoading(false);
      } catch (error) {
        setMLoading(false);

        console.log(error);
      }
    };

    const getRights = async () => {
      try {
        const res = update
          ? await api.getRightsStaff(admToken, user.staffId)
          : await api.getRights(admToken);
        console.log(res);
        if (res.status && res.statusCode === 2) {
          setRights(res.data);
        }

        setRLoading(false);
      } catch (error) {
        setRLoading(false);

        console.log(error);
      }
    };

    const getActivities = async () => {
      try {
        const res = await api.getPriviledges(admToken);
        console.log(res);
        if (res.status && res.statusCode === 2) {
          setActs(res.data);
        }

        setALoading(false);
      } catch (error) {
        setALoading(false);

        console.log(error);
      }
    };

    getDashboardItems();
    getMenuItems();
    getRights();
    getActivities();
  }, [admToken, update, user.staffId]);

  // const getDashboardItems = useCallback(async () => {
  //   try {
  //     const res = update
  //       ? await api.getDashboardItemsStaff(admToken, user.staffId)
  //       : await api.getDashboardItems(admToken);
  //     console.log(res);
  //     if (res.status && res.statusCode === 2) {
  //       setStats(res.data);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log(error);
  //   }
  // }, [admToken, update, user.staffId]);
  // const getMenuItems = useCallback(async () => {
  //   try {
  //     const res = update
  //       ? await api.getMenuItemsStaff(admToken, user.staffId)
  //       : await api.getMenuItems(admToken);
  //     console.log(res);
  //     if (res.status && res.statusCode === 2) {
  //       setMenus(res.data.mainMenus);
  //     }

  //     setMLoading(false);
  //   } catch (error) {
  //     setMLoading(false);

  //     console.log(error);
  //   }
  // }, [admToken, update, user.staffId]);
  // const getRights = useCallback(async () => {
  //   try {
  //     const res = update
  //       ? await api.getRightsStaff(admToken, user.staffId)
  //       : await api.getRights(admToken);
  //     console.log(res);
  //     if (res.status && res.statusCode === 2) {
  //       setRights(res.data);
  //     }

  //     setRLoading(false);
  //   } catch (error) {
  //     setRLoading(false);

  //     console.log(error);
  //   }
  // }, [admToken, update, user.staffId]);
  // const getActivities = useCallback(async () => {
  //   try {
  //     const res = await api.getPriviledges(admToken);
  //     console.log(res);
  //     if (res.status && res.statusCode === 2) {
  //       setActs(res.data);
  //     }

  //     setALoading(false);
  //   } catch (error) {
  //     setALoading(false);

  //     console.log(error);
  //   }
  // }, [admToken]);

  // useEffect(() => {
  //   getDashboardItems();
  //   getMenuItems();
  //   getRights();
  //   getActivities();
  // }, [getMenuItems, getActivities, getRights, getDashboardItems]);

  // Dashboard Stats
  const changeFunc = (id) => {
    if (arr.includes(id.toString())) {
      setArr(arr.filter((v) => v !== id.toString()));
    } else {
      setArr([...arr, id.toString()]);
    }
  };
  // Menu List
  const toggleMenu = (id) => {
    console.log(id);
    if (menuArr.includes(id.toString())) {
      setMenuArr(menuArr.filter((v) => v !== id.toString()));
    } else {
      setMenuArr([...menuArr, id.toString()]);
    }
  };
  // Toggle Menu List
  const toggleAllMenu = () => {
    if (menuArr.length === menus.length) {
      setMenuArr([]);
    } else {
      const newList = menus.map((item) => item.id.toString());
      console.log(newList);
      setMenuArr(newList);
    }
  };
  // Right List
  const toggleRight = (id) => {
    console.log(id);
    if (rightArr.includes(id.toString())) {
      setRightArr(rightArr.filter((v) => v !== id.toString()));
    } else {
      setRightArr([...rightArr, id.toString()]);
    }
  };
  // Toggle Rights List
  const toggleAllRight = () => {
    if (rightArr.length === rights.length) {
      setRightArr([]);
    } else {
      const newList = rights.map((item) => item.id.toString());
      console.log(newList);
      setRightArr(newList);
    }
  };
  // Right List
  const toggleAct = (id) => {
    console.log(id);
    if (id === 1 || id === 4) {
      if (actArr.includes(id.toString())) {
        setActArr(actArr.filter((v) => v !== id.toString()));
      } else {
        setActArr([id.toString()]);
      }
    } else {
      if (actArr.includes(id.toString())) {
        setActArr(actArr.filter((v) => v !== id.toString()));
      } else {
        setActArr([
          ...actArr.filter((v) => v !== "1" && v !== "4"),
          id.toString(),
        ]);
      }
    }
  };
  // Toggle Rights List
  const toggleAllAct = () => {
    if (actArr.length === acts.length) {
      setActArr([]);
    } else {
      const newList = acts.map((item) => item.id.toString());
      console.log(newList);
      setActArr(newList);
    }
  };

  // 26
  const addRoleSetups = async () => {
    let data = {
      roleId: role.roleId,
      menuIds: menuArr.join(","),
      priviledgeIds: actArr.join(","),
      rightIds: rightArr.join(","),
      dashboardStatIds: arr.join(","),
      departmentId: role.departmentId,
      schoolId: schoolId,
      categoryId: role.categoryId,
    };
    console.log(data);
    setFormLoading(true);
    try {
      const res = await api.addRoleSetups(data, admToken);
      console.log(res);
      res.status &&
        res.statusCode === 2 &&
        toast.success("Role Setups Was Created Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 3000,
        });
      setFormLoading(false);
      reloadFunction();
      closeModal();
    } catch (error) {
      setFormLoading(false);
      console.log(error);
    }
  };

  // const update staff setups
  const updateStaffSetups = async () => {
    console.log(staffSetup);
    let data = {
      ...staffSetup,
      menuIds: menuArr.join(","),
      priviledgeIds: actArr.join(","),
      rightIds: rightArr.join(","),
      dashboardStatIds: arr.join(","),
    };

    console.log(data);
    setFormLoading(true);
    try {
      const res = await api.updateStaffRoleSetups(data, admToken);
      console.log(res);
      res.status &&
        res.statusCode === 2 &&
        toast.success("Role Setups Was Updated Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 3000,
        });

      res.status === 500 && toast.error(res.data.message);
      setFormLoading(false);
      reloadFunction();
      closeModal();
    } catch (error) {
      setFormLoading(false);
      console.log(error);
    }
  };

  const [selectedSchools, setSelectedSchools] = useState([]);
  const [unSelectedSchools, setUnSelectedSchools] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [unSelectedDepartments, setUnSelectedDepartments] = useState([]);
  const getSelectedDepartFunc = useCallback(async () => {
    try {
      const res = await api.getSelectedDepartment(
        admToken,
        role.phoneNumber,
        3,
        role.schoolId
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setSelectedDepartments(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [role, admToken]);
  const getUnSelectedDepartFunc = useCallback(async () => {
    try {
      const res = await api.getSelectedDepartment(
        admToken,
        user.phoneNumber,
        checkAccess(accessObj.privileges, 1) ? 1 : 3,
        user.schoolId
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setUnSelectedDepartments(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken, user.phoneNumber, user.schoolId, accessObj.privileges]);
  const getSelectedSchoolFunc = useCallback(async () => {
    try {
      const res = await api.getSelectedSchool(admToken, role.phoneNumber, 2);
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setSelectedSchools(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken, role.phoneNumber]);
  const getUnSelectedSchoolFunc = useCallback(async () => {
    try {
      const res = await api.getSelectedSchool(
        admToken,
        user.phoneNumber,
        checkAccess(accessObj.privileges, 1) ? 1 : 2
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setUnSelectedSchools(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [accessObj.privileges, admToken, user.phoneNumber]);
  const assignDefaultSchool = useCallback(
    async (id) => {
      toast.info("Please wait...");
      try {
        const res = await api.assignDefaultSchoolToStaff(
          admToken,
          id,
          role.staffId
        );
        if (res.status === true && res.statusCode === 2) {
          toast.success(res.message);
          getSelectedSchoolFunc();
          getUnSelectedSchoolFunc();
        }

        if (res.status === 400 || res.status === 500 || res.status === false) {
          ErrorService.displayErrorAlert(res);
        }
      } catch (error) {
        toast.error("An Error Occurred");
      }
    },
    [admToken, getSelectedSchoolFunc, getUnSelectedSchoolFunc, role.staffId]
  );
  const addDelSchool = useCallback(
    async (id, bool) => {
      console.log(id, bool);
      let data = {
        staffId: role.staffId,
        schoolId: role.schoolId,
        selectedSchools: [
          {
            schoolId: id,
            isTicked: bool,
          },
        ],
      };
      try {
        const res = await api.addDeleteSchoolStaff(admToken, data);
        console.log(res);

        if (res.status === true && res.statusCode === 2) {
          toast.success(res.message);
          getSelectedSchoolFunc();
          getUnSelectedSchoolFunc();
        }

        if (res.status === 400 || res.status === 500 || res.status === false) {
          ErrorService.displayErrorAlert(res);
        }
      } catch (error) {
        toast.error("An Error Occurred");
      }
    },
    [
      admToken,
      getSelectedSchoolFunc,
      getUnSelectedSchoolFunc,
      role.schoolId,
      role.staffId,
    ]
  );
  const addDelDepart = useCallback(
    async (id, bool) => {
      console.log(id, bool);
      let data = {
        staffId: role.staffId,
        // departmentID: id,
        selectedDepartments: [
          {
            departmentID: id,
            isTicked: bool,
          },
        ],
      };
      try {
        const res = await api.addDeleteDepartmentStaff(admToken, data);
        console.log(res);
        if (res.status === true && res.statusCode === 2) {
          toast.success(res.message);
          getUnSelectedDepartFunc();
          getSelectedDepartFunc();
        }

        if (res.status === 400 || res.status === 500 || res.status === false) {
          ErrorService.displayErrorAlert(res);
        }
      } catch (error) {
        toast.error("An Error Occurred");
      }
    },
    [admToken, getSelectedDepartFunc, getUnSelectedDepartFunc, role.staffId]
  );

  useEffect(() => {
    console.log(role);
    if (role && role?.schoolId && role?.phoneNumber) {
      getSelectedSchoolFunc();
      getUnSelectedDepartFunc();
      getSelectedDepartFunc();
    }

    // if (
    //   role &&
    //   role?.schoolId &&
    //   role?.phoneNumber &&
    //   checkAccess(accessObj.privileges, 1)
    // ) {
    getUnSelectedSchoolFunc();
    // }
  }, [
    accessObj.privileges,
    getSelectedDepartFunc,
    getSelectedSchoolFunc,
    getUnSelectedDepartFunc,
    getUnSelectedSchoolFunc,
    role,
  ]);

  useEffect(() => {
    if (!createRole && staffSetup !== null) {
      // console.log(staffSetup);
      // console.log(role);
      getUpdatePreset();
    }
  }, [createRole, getUpdatePreset, staffSetup]);

  useEffect(() => {
    if (setups.length > 0 && createRole) {
      console.log("Yes Working");
      getPresetPermissions();
    }
  }, [getPresetPermissions, setups, createRole]);
  return (
    <>
      <SmallHeader
        style={{
          textAlign: "center",
          fontWeight: "bold",
          background: colors.primary,
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          padding: "1.4rem",
          color: "white",
        }}
      >
        Assign Privileges {role?.name && <span>For {role.name}</span>}
      </SmallHeader>

      <TabContainer style={{ marginTop: "0px", borderRadius: "0px" }}>
        <TabLinkContainer style={{ borderRadius: "0px" }}>
          <TabLink
            isActive={tab === 1}
            onClick={() => setTab(1)}
            minWidth="23%"
          >
            <p>Dashboard Stats</p>
          </TabLink>{" "}
          <TabLink
            isActive={tab === 2}
            onClick={() => setTab(2)}
            minWidth="23%"
          >
            <p>Menu</p>
          </TabLink>{" "}
          <TabLink
            isActive={tab === 3}
            onClick={() => setTab(3)}
            minWidth="23%"
          >
            <p>Rights</p>
          </TabLink>{" "}
          <TabLink
            isActive={tab === 4}
            onClick={() => setTab(4)}
            minWidth="23%"
          >
            <p>Activities</p>
          </TabLink>
        </TabLinkContainer>
        <TabContent>
          {tab === 1 && (
            <Stats
              loading={loading}
              stats={stats}
              arr={arr}
              toggleCard={changeFunc}
            />
          )}
          {tab === 2 && (
            <MenuListComp
              loading={mLoading}
              stats={menus}
              arr={menuArr}
              toggleCard={toggleMenu}
              selectAll={toggleAllMenu}
              name="Menu"
            />
          )}

          {tab === 3 && (
            <ToggleListComp
              name="Rights"
              showToggleAll={false}
              loading={rLoading}
              stats={rights}
              arr={rightArr}
              toggleCard={toggleRight}
              selectAll={toggleAllRight}
            />
          )}
          {tab === 4 && (
            <>
              {update ? (
                <ActivitiesListComp
                  name="Activities"
                  showToggleAll={false}
                  loading={aLoading}
                  stats={acts}
                  arr={actArr}
                  toggleCard={toggleAct}
                  selectAll={toggleAllAct}
                  selectedSchools={selectedSchools}
                  unSelectedSchools={unSelectedSchools}
                  selectedDepartments={selectedDepartments}
                  unSelectedDepartments={unSelectedDepartments}
                  addDelSchool={addDelSchool}
                  addDelDepart={addDelDepart}
                  showNewForm={showForm}
                  assignDefault={assignDefaultSchool}
                />
              ) : (
                <ToggleListComp
                  name="Activities"
                  showToggleAll={false}
                  loading={aLoading}
                  stats={acts}
                  arr={actArr}
                  toggleCard={toggleAct}
                  selectAll={toggleAllAct}
                  selectedSchools={selectedSchools}
                  unSelectedSchools={unSelectedSchools}
                  selectedDepartments={selectedDepartments}
                  unSelectedDepartments={unSelectedDepartments}
                  addDelSchool={addDelSchool}
                  addDelDepart={addDelDepart}
                  showNewForm={showForm}
                  assignDefault={assignDefaultSchool}
                />
              )}
            </>
          )}
        </TabContent>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <AuthButton
            width={isTab ? "90%" : "80%"}
            onClick={createRole ? addRoleSetups : updateStaffSetups}
            disabled={formLoading}
          >
            {formLoading ? (
              <FormLoader />
            ) : (
              <> {createRole ? "Save Setup" : "Update Setup"}</>
            )}
            {/* Send Code */}
          </AuthButton>
        </div>
      </TabContainer>
    </>
  );
};
AssignPriviledge.propTypes = {
  role: PropTypes.object,
  update: PropTypes.object,
};
AssignPriviledge.defaultProps = {
  createRole: true,
  update: false,
  setups: [],
  role: null,
};

export default AssignPriviledge;
