import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { checkAccess, paginateData } from "../../../services/utils";
import GlobalStyles from "../../../assets/globalStyle";
import colors from "../../../assets/colors.json";
import { BsSearch } from "react-icons/bs";
import { InputWrapper } from "../../../pages/auth/Login.style";
import { useMediaQuery } from "react-responsive";
import api from "../../../services/ControlPanel";
import { toast } from "react-toastify";
import {
  TabLinkContainer,
  AccountSummaryWrapper,
  BigHeader,
  Container,
  Icon,
  Input,
  MenuDiv,
  Search,
  TabContainer,
} from "../../../pages/Dashboard/styles/dashboard.style";
import Pagination from "../../pagination_one/pagination";
import Modal from "../../modal/Modal";
import Loader from "../../pageLoader/loader";

const HODSetup = () => {
  const isTab = useMediaQuery({ maxWidth: 992 });
  const [sQuery, setSQuery] = useState("");
  const [itemOffSet, setitemOffSet] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(true);
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [allData, setAllData] = useState([]);
  const [arr, setArr] = useState([]);
  const [openAssign, setOpenAssign] = useState(false);
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [staffQuery, setStaffQuery] = useState("");
  const [staffId, setStaffId] = useState("");
  const [rowId, setRowId] = useState("");
  const [assignLoading, setAssignLoading] = useState(false);
  const [departmentObj, setDepartmentObj] = useState({
    id: "",
    name: "",
  });

  const filterSearch = useCallback(
    async (e) => {
      let arr = [];
      const queryRegex = new RegExp(e.target.value, "i");
      allData.forEach((item) => {
        Object.values(item).some((val) =>
          queryRegex.test(val) ? arr.push(item) : ""
        );
      });

      setArr(arr);
      setPageCount(0);
    },
    [allData]
  );
  const resetData = () => {
    setCurrentPage(1);
    paginateData(allData, setArr, 1, itemOffSet, setitemOffSet, setPageCount);
  };

  const filterStaff = useCallback(
    async (e) => {
      let arr = [];
      const queryRegex = new RegExp(e.target.value, "i");
      data.forEach((item) => {
        Object.values(item).some((val) =>
          queryRegex.test(val) ? arr.push(item) : ""
        );
      });
      setList(arr);
    },
    [data]
  );

  const getAllStaff = useCallback(async () => {
    try {
      const res = await api.getUsersCredential(admToken, user.schoolId, 3);
      if (res.status === true && res.statusCode === 2) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken, user.schoolId]);
  const getAllDepartments = useCallback(async () => {
    try {
      const res = await api.getSchoolDepartmentsForHod(admToken, user.schoolId);
      setAllData(res.data);
      setPageLoading(false);
      if (!res) {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
      setPageLoading(false);
    }
  }, [admToken, user.schoolId]);

  const assignHod = useCallback(
    async (staffId, departmentId) => {
      try {
        setAssignLoading(true);
        const res = await api.assignHod(
          admToken,
          user.schoolId,
          staffId,
          departmentId
        );
        if (res.status && res.statusCode === 2) {
          toast.success(res.message, {
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setOpenAssign(false);
          setCurrentPage(1);
          getAllDepartments();
        }
        setAssignLoading(false);
        setRowId("");
      } catch (error) {
        toast.error("Error Assigning Principal");
        setRowId("");
      }
    },
    [admToken, getAllDepartments, user.schoolId]
  );

  const unAssignHod = useCallback(
    async (departmentId) => {
      try {
        setAssignLoading(true);
        const res = await api.unAssignHod(
          admToken,
          user.schoolId,
          departmentId
        );
        if (res.status && res.statusCode === 2) {
          toast.success(res.message, {
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_LEFT,
          });

          setCurrentPage(1);
          getAllDepartments();
        }
        setAssignLoading(false);
      } catch (error) {
        toast.error("Error Un-assigning Hod");
      }
    },
    [admToken, getAllDepartments]
  );

  useEffect(() => {
    paginateData(
      allData,
      setArr,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allData, currentPage, itemOffSet]);

  useEffect(() => {
    getAllDepartments();
    getAllStaff();
  }, [getAllStaff, getAllDepartments]);

  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }} fs="13px">
        Assign HOD To {departmentObj.name}
      </BigHeader>
      <InputWrapper>
        <input
          type="text"
          placeholder="Search For Department"
          onChange={(e) => {
            filterStaff(e);
            setStaffQuery(e.target.value);
          }}
          value={staffQuery}
        />
      </InputWrapper>
      <MenuDiv style={{ padding: isTab ? "0.6rem" : "0.8rem" }}>
        {list.length > 0 &&
          list.map((item, index) => (
            <div
              key={index}
              style={{
                marginTop: "0.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>{item.fullName}</div>
              <button
                disabled={staffId === item.credentialId && assignLoading}
                style={{
                  backgroundColor: colors.primary,
                  color: "white",
                  borderRadius: "20px",
                  padding: "0.3rem 0.6rem",
                }}
                onClick={() => {
                  setStaffId(item.credentialId);
                  assignHod(item.credentialId, departmentObj.id);
                }}
              >
                {staffId === item.credentialId && assignLoading
                  ? "Please wait..."
                  : "Assign"}
              </button>
            </div>
          ))}
      </MenuDiv>
    </>
  );

  if (pageLoading) {
    return <Loader />;
  }

  return (
    <Container>
      {/* <GlobalStyles />
      {openAssign && (
        <Modal content={content} size="large" onClose={setOpenAssign} />
      )}
      <div>
        <BigHeader
          style={{
            borderBottom: `4px solid ${colors.primary}`,
            paddingBottom: "4px",
          }}
        >
          HOD Setup
        </BigHeader>
      </div>
      <TabContainer
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          paddingBottom: "0px",
        }}
      >
        <TabLinkContainer bg="rgb(238, 224,241)">
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search
              type="text"
              placeholder="Search For Staff"
              value={sQuery}
              onChange={(e) => {
                setSQuery(e.target.value);
                if (e.target.value.length > 2) {
                  filterSearch(e);
                }

                if (e.target.value === "") {
                  resetData();
                  console.log("hellow world inside jsx");
                }
              }}
            />
          </Input>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table
            style={{
              borderRadius: "0px",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <tr
              className="tabel-head"
              style={{ color: "black", fontWeight: "bold" }}
            >
              <th>S/N</th>
              <th>Department ({allData.length > 0 ? allData.length : 0}) </th>
              <th>HOD</th>
              <th></th>
            </tr>
            {arr.length > 0 ? (
              arr.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.departmentName}</td>
                  <td>{item.hodName}</td>
                  <td>
                    {checkAccess(accessObj.rights, "Assign") && (
                      <button
                        disabled={
                          staffId === item.headId &&
                          rowId === item.departmentId &&
                          assignLoading
                        }
                        style={{
                          backgroundColor: item.headId
                            ? colors.primary
                            : "white",
                          color: item.headId ? "white" : colors.primary,
                          fontWeight: "bold",
                          border: `1px solid ${colors.primary}`,
                          borderRadius: "08px",
                          padding: "0.3rem 0.6rem",
                        }}
                        onClick={() => {
                          setRowId(item.departmentId);
                          if (item.headId !== "0") {
                            setStaffId(item.headId);
                            rowId === item.departmentId &&
                              unAssignHod(item.departmentId);
                          } else {
                            setDepartmentObj({
                              ...departmentObj,
                              id: item.departmentId,
                              name: item.departmentName,
                            });

                            setOpenAssign(true);
                          }
                        }}
                      >
                        {staffId === item.headId &&
                        rowId === item.departmentId &&
                        assignLoading
                          ? "Please wait..."
                          : item.headId !== "0"
                          ? "Unassign HOD"
                          : "Assign HOD"}
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} style={{ textAlign: "center" }}>
                  No Department Available
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      {pageCount > 1 && (
        <div
          style={{
            marginTop: "2px",
            marginBottom: "2px",
          }}
        >
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            currentPage={currentPage - 1}
            range={3}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )} */}
    </Container>
  );
};

export default HODSetup;
