import { Container } from "../Dashboard/styles/dashboard.style";
import GlobalStyles from "../../assets/globalStyle";
import { TabRoute, TabRouteContainer } from "../student_mgt/stu_mgt.style";
import { useLocation, useSearchParams } from "react-router-dom";

import { useSelector } from "react-redux";

import Title from "../../components/Dashboard/Title";
import {
  CreateSchool,
  WebsiteInfo,
  SchoolClass,
  SchoolStaffInfo,
} from "./index";
import { checkAccess } from "../../services/utils";
const MySchool = () => {
  const location = useLocation();
  const { accessObj } = useSelector((state) => state.auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const tab_id = Number(new URLSearchParams(location.search).get("tab"));
  const handleChange = (event, newValue) => {
    console.log(newValue);
    setSearchParams({ ...searchParams, tab: newValue });
  };

  return (
    <Container>
      <GlobalStyles />
      <div>
        <Title title="My School" />
      </div>
      <TabRouteContainer>
        {checkAccess(accessObj.menuItems, "Create School") && (
          <TabRoute isActive={tab_id === 1} onClick={(e) => handleChange(e, 1)}>
            <span>Create School</span>
          </TabRoute>
        )}
        {checkAccess(accessObj.menuItems, "Website Info.") && (
          <TabRoute isActive={tab_id === 2} onClick={(e) => handleChange(e, 2)}>
            <span>Website Info</span>
          </TabRoute>
        )}
        {checkAccess(accessObj.menuItems, "School-Class-Student Info.") && (
          <TabRoute isActive={tab_id === 3} onClick={(e) => handleChange(e, 3)}>
            <span> School-Class-Student-Info</span>
          </TabRoute>
        )}
        {checkAccess(accessObj.menuItems, "School-Staff Info.") && (
          <TabRoute isActive={tab_id === 4} onClick={(e) => handleChange(e, 4)}>
            <span> School-Staff-Info</span>
          </TabRoute>
        )}
      </TabRouteContainer>
      {checkAccess(accessObj.menuItems, "Create School") && tab_id === 1 && (
        <CreateSchool />
      )}
      {checkAccess(accessObj.menuItems, "Website Info.") && tab_id === 2 && (
        <WebsiteInfo />
      )}
      {checkAccess(accessObj.menuItems, "School-Class-Student Info.") &&
        tab_id === 3 && <SchoolClass />}
      {checkAccess(accessObj.menuItems, "School-Staff Info.") &&
        tab_id === 4 && <SchoolStaffInfo />}

      {/* <Outlet /> */}
    </Container>
  );
};

export default MySchool;
