import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import colors from "../../../assets/colors.json";
import { GiCancel, GiCheckMark } from "react-icons/gi";
import { ImCheckmark } from "react-icons/im";
import {
  SmallHeader,
  SubMenuDiv,
  MenuDiv,
} from "../../../pages/Dashboard/styles/dashboard.style";
import Switch from "../../ToogleSwitch/Switch";
import Loader from "../../pageLoader/loader";
import PropTypes from "prop-types";
import { AiFillPushpin } from "react-icons/ai";
import { useSelector } from "react-redux";
import { checkAccess } from "../../../services/utils";
const ActivitiesListComp = ({
  loading,
  stats,
  arr,
  toggleCard,
  selectAll,
  name,
  showToggleAll,
  selectedSchools,
  unSelectedSchools,
  addDelSchool,
  showNewForm,
  addDelDepart,
  selectedDepartments,
  unSelectedDepartments,
  assignDefault,
}) => {
  const isTab = useMediaQuery({ maxWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { accessObj } = useSelector((state) => state.auth);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <MenuDiv
        minH="max-content"
        style={{ padding: isTab ? "0.6rem" : "0.8rem" }}
      >
        <div
          style={{
            width: isTab ? "100%" : "75%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: colors.secondary,
            borderRadius: "5px",
            padding: "0.7rem",
          }}
        >
          <SmallHeader style={{ fontWeight: "bold" }}>{name}</SmallHeader>

          {showToggleAll && (
            <div
              style={{
                display: "inline-flex",
              }}
            >
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                <label
                  htmlFor=""
                  style={{
                    marginRight: "0.3rem",
                    fontWeight: "semibold",
                    fontSize: "13px",
                  }}
                >
                  {stats.length === arr.length ? "Remove All" : "Select All"}
                </label>

                <Switch
                  value={stats.length === arr.length}
                  onChange={() => selectAll()}
                />
              </div>
            </div>
          )}
        </div>
        {stats.length > 0 ? (
          stats.map((item, index) => (
            <SubMenuDiv style={{ width: isTab ? "100%" : "60%" }} key={index}>
              <div></div>
              <SmallHeader style={{ fontSize: "13px", fontWeight: "bold" }}>
                {item.name}
              </SmallHeader>
              <Switch
                disabled={!checkAccess(accessObj.privileges, 1) ? true : false}
                value={arr.includes(String(item.id))}
                onChange={() => {
                  toggleCard(item.id);
                }}
              />
            </SubMenuDiv>
          ))
        ) : (
          <div>No {name} List Available </div>
        )}
      </MenuDiv>
      {showNewForm && (
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            padding: "0.2rem 0.3rem",
            justifyContent: "space-between",
          }}
        >
          {arr.includes("2") && (
            <div
              style={{
                width: isMobile ? "100%" : "47%",
                border: `1px solid ${colors.primary}`,
                borderRadius: "20px",
                padding: "0.5rem 0.6rem",
              }}
            >
              <SmallHeader>Select Schools</SmallHeader>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  flexWrap: "wrap",
                  height: "max-content",
                }}
              >
                {unSelectedSchools
                  .filter((item) => item.schoolid !== 0)
                  .map((item, index) => {
                    const bool = selectedSchools.find(
                      (i) => i.schoolid === item.schoolid
                    );
                    console.log(bool);
                    return (
                      <div
                        key={index}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            marginTop: "0.8rem",
                            backgroundColor:
                              bool && bool.schoolid === item.schoolid
                                ? colors.primary
                                : "white",
                            display: "flex",
                            alignItems: "center",
                            padding: "0.3rem 0.6rem",
                            borderRadius: "10px",
                            marginRight: "0.2rem",
                            flexWrap: "wrap",
                            border: `1px solid ${colors.primary}`,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            addDelSchool(
                              item.schoolid,
                              bool && bool.schoolid === item.schoolid
                                ? false
                                : true
                            )
                          }
                        >
                          {bool && bool.defaultschool && (
                            <ImCheckmark
                              color="green"
                              style={{ marginRight: "0.3rem" }}
                            />
                          )}

                          <SmallHeader
                            style={{
                              color:
                                bool && bool.schoolid === item.schoolid
                                  ? "white"
                                  : colors.primary,
                              marginRight: "0.2rem",
                              fontSize: isTab ? "12px" : "13px",
                            }}
                          >
                            {item.schoolname}
                          </SmallHeader>
                          {bool && bool.schoolid === item.schoolid && (
                            <GiCancel
                              color="red"
                              style={{
                                marginLeft: "0.2rem",
                                cursor: "pointer",
                              }}
                              // onClick={() => addDelSchool(item.schoolid, false)}
                            />
                          )}
                        </div>
                        {bool && !bool.defaultschool && (
                          <button
                            onClick={() => assignDefault(item.schoolid)}
                            style={{
                              marginTop: "0.8rem",
                              marginLeft: "0.4rem",
                              backgroundColor: "white",
                              border: `1px solid ${colors.lightGray}`,
                              color: "white",
                              padding: "0.2rem 0.6rem",
                              borderRadius: "13px",
                            }}
                          >
                            <AiFillPushpin color={colors.primary} />
                            {/* Mark As Default */}
                          </button>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          )}

          {arr.includes("3") && (
            <div
              style={{
                width: isMobile ? "100%" : "47%",
                border: `1px solid ${colors.primary}`,
                borderRadius: "20px",
                padding: "0.5rem 0.6rem",
              }}
            >
              <SmallHeader>Select Departments</SmallHeader>

              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  flexWrap: "wrap",
                  height: "max-content",
                }}
              >
                {unSelectedDepartments.map((item, index) => {
                  const bool = selectedDepartments.find(
                    (i) => item.departmentId === i.departmentId
                  );

                  return (
                    <div
                      key={index}
                      style={{
                        marginTop: "0.8rem",
                        backgroundColor:
                          bool && bool.departmentId === item.departmentId
                            ? colors.primary
                            : "white",
                        display: "flex",
                        alignItems: "center",
                        padding: "0.3rem 0.6rem",
                        borderRadius: "10px",
                        marginRight: "0.2rem",
                        flexWrap: "wrap",
                        border: `1px solid ${colors.primary}`,
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        addDelDepart(
                          item.departmentId,
                          bool && bool.departmentId === item.departmentId
                            ? false
                            : true
                        )
                      }
                    >
                      <SmallHeader
                        style={{
                          color:
                            bool && bool.departmentId === item.departmentId
                              ? "white"
                              : colors.primary,
                          marginRight: "0.2rem",
                          fontSize: isTab ? "12px" : "13px",
                        }}
                      >
                        {item.departmentName}
                      </SmallHeader>
                      {bool && bool.departmentId === item.departmentId && (
                        <GiCancel
                          color="red"
                          style={{ marginLeft: "0.2rem", cursor: "pointer" }}
                          // onClick={() => addDelDepart(item.departmentId, false)}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

ActivitiesListComp.propTypes = {
  showToggleAll: PropTypes.bool,
  showNewForm: PropTypes.bool,
  selectedDepartments: PropTypes.array,
  unSelectedDepartments: PropTypes.array,
  selectedSchools: PropTypes.array,
  unSelectedSchools: PropTypes.array,
};

ActivitiesListComp.defaultProps = {
  showToggleAll: true,
  showNewForm: false,
  unSelectedDepartments: [],
  selectedDepartments: [],
  selectedSchools: [],
  unSelectedSchools: [],
};

export default ActivitiesListComp;
