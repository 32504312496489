export const genderOptions = [
  {
    id: "male",
    gender: "Male",
  },
  {
    id: "female",
    gender: "Female",
  },
];
