import { API } from "./constant";
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";

export const classworkApi = createApi({
  reducerPath: "classwork",
  baseQuery,
  tagTypes: [
    "classWorks",
    "tutorials",
    "questions",
    "submission",
    "tutorialFiles",
  ],
  endpoints: (builder) => ({
    getAllClasswork: builder.query({
      query: ({ schoolId }) => ({
        url: API.ALL_CLASSWORK,
        method: "GET",
        params: { schoolId },
      }),
      providesTags: (result, error, arg) =>
        result ? [...(result?.data ?? []), "classWorks"] : ["classWorks"],
    }),
    getAllClassworkQuestion: builder.query({
      query: ({ classWorkId, schoolId }) => ({
        url: API.ALL_CLASSWORK_QUESTION,
        method: "GET",
        params: { classWorkId, schoolId },
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...(result?.data?.map(({ id }) => ({ type: "questions", id })) ??
                []),
              "questions",
            ]
          : ["questions"],
    }),
    createClassworkQuestion: builder.mutation({
      query: ({ ...values }) => ({
        url: API.ADD_CLASSWORK_QUESTION,
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["questions", "classWorks"],
    }),
    updateClassworkQuestion: builder.mutation({
      query: ({ ...values }) => ({
        url: API.UPDATE_CLASSWORK_QUESTION,
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["questions"],
    }),
    deleteClassworkQuestion: builder.mutation({
      query: ({ classWorkId, questionId }) => ({
        url: API.DELETE_CLASSWORK_QUESTION,
        method: "DELETE",
        params: { classWorkId, questionId },
      }),
      invalidatesTags: ["questions"],
    }),
    getClassList: builder.query({
      query: ({ subjectId, schoolId }) => ({
        url: API.GET_ALL_CLASS,
        method: "GET",
        params: { subjectId, schoolId },
      }),
    }),
    viewSubmittedClasswork: builder.query({
      query: ({ classWorkId, studentId }) => ({
        url: API.VIEW_SUBMITTED_CLASSWORK,
        method: "GET",
        params: { classWorkId, studentId },
      }),
      invalidatesTags: ["submission"],
    }),
    getSubmittedClasswork: builder.query({
      query: ({ classWorkId, schoolId }) => ({
        url: API.ALL_SUBMITTED_CLASSWORK,
        method: "GET",
        params: { classWorkId, schoolId },
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...(result?.data?.map(({ id }) => ({ type: "submission", id })) ??
                []),
              "submission",
            ]
          : ["submission"],
    }),
    gradeClasswork: builder.mutation({
      query: ({ ...values }) => ({
        url: API.GRADE_CLASSWORK,
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["submission"],
    }),
    createClasswork: builder.mutation({
      query: ({ ...values }) => ({
        url: API.CREATE_CLASSWORK,
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["classWorks"],
    }),
    editClasswork: builder.mutation({
      query: ({ classWorkId, ...values }) => ({
        url: API.EDIT_CLASSWORK,
        method: "PATCH",
        body: { ...values },
        params: { classWorkId },
      }),
      invalidatesTags: ["classWorks"],
    }),
    deleteClasswork: builder.mutation({
      query: ({ ...values }) => ({
        url: API.DELETE_CLASSWORK,
        method: "DELETE",
        params: { ...values },
      }),
      invalidatesTags: ["classWorks"],
    }),
    getAllSubjects: builder.query({
      query: ({ schoolId }) => ({
        url: API.GET_SUBJECTS,
        method: "GET",
        params: { schoolId },
      }),
    }),
    createTutorial: builder.mutation({
      query: ({ ...values }) => ({
        url: API.CREATE_TUTORIAL,
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["tutorials"],
    }),
    editTutorial: builder.mutation({
      query: ({ ...values }) => ({
        url: API.EDIT_TUTORIAL,
        method: "PATCH",
        body: { ...values },
      }),
      invalidatesTags: ["tutorials"],
    }),
    getTutorials: builder.query({
      query: ({ schoolId }) => ({
        url: API.GET_TUTORIALS,
        method: "GET",
        params: { schoolId },
      }),
      providesTags: (result, error, arg) =>
        result ? [result, "tutorials"] : ["tutorials"],
    }),
    deleteTutorial: builder.mutation({
      query: ({ TutorialId }) => ({
        url: API.DELETE_TUTORIAL,
        method: "DELETE",
        params: { TutorialId },
      }),
      invalidatesTags: ["tutorials"],
    }),
    addTutorialFile: builder.mutation({
      query: ({ ...values }) => ({
        url: API.ADD_TUTORIAL_FILE,
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["tutorialFiles"],
    }),
    getAllTutorialUploadedFiles: builder.query({
      query: ({ tutorialId }) => ({
        url: API.ALL_TUTORIAL_FILES,
        method: "GET",
        params: { tutorialId },
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...(result?.data?.map(({ id }) => ({
                type: "tutorialFiles",
                id,
              })) ?? []),
              "tutorialFiles",
            ]
          : ["tutorialFiles"],
    }),
    deleteTutorialAddedFiles: builder.mutation({
      query: ({ fileId }) => ({
        url: API.DELETE_TUTORIAL_ADDED_FILE,
        method: "DELETE",
        params: { fileId },
      }),
      invalidatesTag: ["tutorialFiles"],
    }),
    activateTutorial: builder.mutation({
      query: ({ tutorialId, IsActivate }) => ({
        url: API.ACTIVATE_TUTORIAL,
        method: "POST",
        params: { tutorialId, IsActivate },
      }),
    }),
    activateDeactivate: builder.mutation({
      query: ({ ...values }) => ({
        url: API.ACTIVATE_DEACTIVATE,
        method: "POST",
        params: { ...values },
      }),
      invalidatesTags: ["classWorks", "questions", "submission"],
    }),
    getClassworkComments: builder.query({
      query: ({ classWorkId }) => ({
        url: API.GET_COMMENTS,
        method: "GET",
        params: { classWorkId },
      }),
    }),
    sendClassworkComments: builder.mutation({
      query: ({ ...values }) => ({
        url: API.SEND_COMMENTS,
        method: "POST",
        body: { ...values },
      }),
    }),
  }),
});

export const {
  useGetAllClassworkQuery,
  useGetClassListQuery,
  useCreateClassworkMutation,
  useEditClassworkMutation,
  useGetAllSubjectsQuery,
  useAddTutorialMutation,
  useEditTutorialMutation,
  useGetTutorialsQuery,
  useDeleteTutorialMutation,
  useCreateTutorialMutation,
  useDeleteClassworkMutation,
  useActivateDeactivateMutation,
  useGetSubmittedClassworkQuery,
  useViewSubmittedClassworkQuery,
  useGetAllClassworkQuestionQuery,
  useCreateClassworkQuestionMutation,
  useUpdateClassworkQuestionMutation,
  useDeleteClassworkQuestionMutation,
  useAddTutorialFileMutation,
  useGetAllTutorialUploadedFilesQuery,
  useActivateTutorialMutation,
  useGradeClassworkMutation,
  useDeleteTutorialAddedFilesMutation,
  useGetClassworkCommentsQuery,
  useSendClassworkCommentsMutation,
} = classworkApi;
