import { createTheme, ThemeProvider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Outlet } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#671e78",
    },
  },
});

const Admission = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box>
          <Typography
            variant="body"
            sx={{ fontFamily: "Gilroy-Medium", fontSize: "26px" }}
          >
            <span style={{ opacity: 0.3, fontFamily: "Gilroy-Regular" }}>
              Parent Management &#62;&#62;
            </span>{" "}
            Parent Dashboard - Mr James Olu(2) &#62;&#62; Admission
          </Typography>
          <Box>
            <Outlet />
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Admission;
