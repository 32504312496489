import React from "react";
import { Modal, Typography, Box, Button } from "@mui/material";
import FormLoader from "../../FormLoader";

const ActivateDeactivate = ({
  activateDeactivate,
  handleActivate,
  handleDeactivate,
  data,
  handleActivateModal,
  loading,
  styleDelete,
}) => {
  return (
    <Modal
      open={activateDeactivate}
      onClose={handleActivateModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleDelete}>
        <Typography>Activation</Typography>
        <Box sx={{ mt: 2 }} className="flex gap-5 items-center">
          <Button
            variant="contained"
            sx={{
              color: "#fff",
              ml: 2,
              px: 3,
              textTransform: "initial",
              fontFamily: "gilroy-regular",
              "&:hover": {
                bgcolor: "#d3302f",
                color: "#fff",
              },
            }}
            onClick={handleDeactivate}
            disabled={
              loading ||
              data?.isActivate === false ||
              //   data?.isQuestionAdded === true ||
              data?.isSubmitted === true ||
              data?.isClosed === true
            }
          >
            {loading ? <FormLoader /> : <span>Deactivate</span>}
          </Button>
          <Button
            onClick={handleActivate}
            disabled={
              loading ||
              data?.isActivate === true ||
              data?.isQuestionadded === false ||
              data?.isClosed === true
            }
            variant="contained"
            sx={{
              color: "#fff",
              ml: 2,
              px: 3,
              textTransform: "initial",
              fontFamily: "gilroy-regular",
              "&:hover": {
                bgcolor: "green",
                color: "#fff",
              },
            }}
          >
            {loading ? <FormLoader /> : <span>Activate</span>}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ActivateDeactivate;
