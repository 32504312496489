/* eslint-disable no-unused-vars */
import React, { useState, createRef } from "react";
import { SiAdobeacrobatreader } from "react-icons/si";
import { BsSearch, BsFillPrinterFill } from "react-icons/bs";
import { IconButton, MenuItem, MenuList } from "@mui/material";
import Pagination from "../pagination_one/pagination";
import colors from "../../assets/colors.json";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "../../assets/Images/avatar.jpg";
import { useMediaQuery } from "react-responsive";
import { AuthButton } from "../../pages/auth/Login.style";
import { Box } from "@mui/system";
import Popover from "@mui/material/Popover";
import { AiFillFileExcel } from "react-icons/ai";

import { ImgTextDiv, StatusBar } from "../../pages/student_mgt/stu_mgt.style";
import {
  Icon,
  Input,
  TabContainer,
  TabLinkContainer,
  Search,
  AccountSummaryWrapper,
  BigHeader,
  SmallHeader,
  TabContentWrapper,
  InlineFormDiv,
  ButtonFlex,
  SubMenuDiv,
} from "../../pages/Dashboard/styles/dashboard.style";
import Modal from "../modal/Modal";
import { InputWrapper } from "../../pages/auth/auth.style";

const StaffList = () => {
  const onPickFileRef = createRef();

  const [fileName, setFileName] = useState("");
  const [base64, setBase64] = useState("");
  const [pageCount, setPageCount] = useState(50);
  const [currentPage, setCurrentPage] = useState(2);
  const [showForm, setShowForm] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isTab = useMediaQuery({ maxWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [attachModal, setAttachModal] = useState(false);
  const [assignClassModal, setAssignClassModal] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const pickFile = () => {
    onPickFileRef.current.click();
  };
  const onPickFile = (event) => {
    const files = event.target.files;
    setFileName(files[0].name);
    let filename = files[0].name;
    if (
      filename.includes(".pdf") ||
      filename.includes(".doc") ||
      filename.includes(".docx")
    ) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        setBase64(fileReader.result);
      });
      fileReader.readAsDataURL(files[0]);
    } else {
      alert("Please upload a document");
    }

    console.log("We are working");
  };
  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }}>Attachment</BigHeader>
      <SmallHeader
        style={{
          textAlign: "center",
          margin: "0 auto",
          background: colors.primary,
          borderRadius: "5px",
          color: "white",
          width: "fit-content",
          padding: "5px 10px",
        }}
      >
        Mr james John
      </SmallHeader>

      <TabContentWrapper>
        <input
          type="file"
          style={{
            display: "none",
          }}
          onChange={onPickFile}
          ref={onPickFileRef}
        />
        <InlineFormDiv width="100%">
          <div style={{ width: isTab ? "100%" : "70%" }}>
            <InputWrapper>
              <label htmlFor="filename">Upload Picture</label>
              <input
                type="text"
                value={fileName + "" + base64}
                disabled={true}
              />
            </InputWrapper>
          </div>
          <AuthButton width={isTab ? "100%" : "24%"} onClick={pickFile}>
            Upload
          </AuthButton>
        </InlineFormDiv>

        <TabContainer style={{ borderRadius: "10px" }}>
          <AccountSummaryWrapper pt="0px">
            <table>
              <tr
                className="table-head"
                style={{ color: "black", fontWeight: "bold" }}
              >
                <th>S/N</th>
                <th>File Name</th>
                <th>Upload Date</th>

                <th></th>
              </tr>
              <tr>
                <td>1</td>
                <td>Introduction to organic Chemistry</td>
                <td>12/12/2023</td>
                <td>
                  <ButtonFlex>
                    <button>Delete</button>
                    <button>View</button>
                  </ButtonFlex>
                </td>
              </tr>
            </table>
          </AccountSummaryWrapper>
        </TabContainer>
      </TabContentWrapper>
    </>
  );

  const assignClassContent = (
    <>
      <BigHeader style={{ textAlign: "center" }}>Assign Class</BigHeader>
      <SmallHeader
        style={{
          textAlign: "center",
          margin: "0 auto",
          background: colors.primary,
          borderRadius: "5px",
          color: "white",
          width: "fit-content",
          padding: "5px 10px",
        }}
      >
        Mr james John
      </SmallHeader>

      <TabContentWrapper>
        {/* <div style={{ padding: isMobile ? "0.6rem" : "0.9rem" }}> */}
        <SubMenuDiv
          jc="start"
          style={{
            width: isMobile ? "100%" : "100%",
            borderBottom: `1px solid ${colors.secondary}`,
          }}
        >
          <span style={{ marginRight: "0.8rem" }}>
            <input type="checkbox" style={{ marginTop: "0px" }} />
          </span>
          <SmallHeader
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginRight: "0.4rem",
            }}
          >
            SS1
          </SmallHeader>
        </SubMenuDiv>

        <SubMenuDiv
          jc="start"
          style={{
            width: isMobile ? "100%" : "100%",
            borderBottom: `1px solid ${colors.secondary}`,
          }}
        >
          <span style={{ marginRight: "0.8rem" }}>
            <input type="checkbox" style={{ marginTop: "0px" }} />
          </span>
          <SmallHeader
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginRight: "0.4rem",
            }}
          >
            Jss3
          </SmallHeader>
        </SubMenuDiv>
        <SubMenuDiv
          jc="start"
          style={{
            width: isMobile ? "100%" : "100%",
            borderBottom: `1px solid ${colors.secondary}`,
          }}
        >
          <span style={{ marginRight: "0.8rem" }}>
            <input type="checkbox" style={{ marginTop: "0px" }} />
          </span>
          <SmallHeader
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginRight: "0.4rem",
            }}
          >
            Jss 2
          </SmallHeader>
        </SubMenuDiv>
        {/* </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <AuthButton width={isTab ? "100%" : "80%"} onSubmit={null}>
            <div>
              <span>Assign Class</span>
            </div>
            {/* Send Code */}
          </AuthButton>
        </div>
      </TabContentWrapper>
    </>
  );
  return (
    <div>
      {attachModal && (
        <Modal content={content} size={"large"} onClose={setAttachModal} />
      )}
      {assignClassModal && (
        <Modal
          content={assignClassContent}
          size={"large"}
          onClose={setAssignClassModal}
        />
      )}
      <TabContainer style={{ marginTop: "1rem" }}>
        <TabLinkContainer bg="#F0E8F1" style={{ position: "relative" }}>
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search " />
          </Input>
          <div style={{ display: "inline-flex" }} className="div">
            <AiFillFileExcel
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
            <SiAdobeacrobatreader
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
            <BsFillPrinterFill
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
          </div>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table style={{ borderRadius: "0px" }}>
            <tr
              className="table-head"
              style={{ backgroundColor: "#F0E8F1", color: "black" }}
            >
              <th>
                <input type="checkbox" />
              </th>
              <th>Staff Name </th>

              <th>Staff Id</th>
              <th>Department</th>
              <th>Unit</th>
              <th>Status</th>
              <th></th>
            </tr>
            <tr>
              <td>
                <input type="checkbox" />
              </td>

              <td>
                <ImgTextDiv>
                  <img src={Avatar} alt="" />
                  <span>Adekitan Sandra</span>
                </ImgTextDiv>
              </td>
              <td>CMS/2022/001</td>
              <td>Admin</td>
              <td>Library</td>
              <td>
                <StatusBar status="saved">Saved</StatusBar>
              </td>

              <td>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              </td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" />
              </td>
              <td>
                <ImgTextDiv>
                  <img src={Avatar} alt="" />
                  <span>Adekitan Sandra</span>
                </ImgTextDiv>
              </td>
              <td>CMS/2022/001</td>
              <td>Admin</td>
              <td>Library</td>
              <td>
                <StatusBar status="pending">Pending</StatusBar>
              </td>

              <td>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              </td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" />
              </td>
              <td>
                <ImgTextDiv>
                  <img src={Avatar} alt="" />
                  <span>Adekitan Sandra</span>
                </ImgTextDiv>
              </td>
              <td>CMS/2022/001</td>
              <td>Admin</td>
              <td>Library</td>
              <td>
                <StatusBar status="saved">Saved</StatusBar>
              </td>

              <td>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              </td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" />
              </td>
              <td>
                <ImgTextDiv>
                  <img src={Avatar} alt="" />
                  <span>Adekitan Sandra</span>
                </ImgTextDiv>
              </td>
              <td>CMS/2022/001</td>
              <td>Admin</td>
              <td>Library</td>
              <td>
                <StatusBar>Submitted</StatusBar>
              </td>

              <td>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              </td>
            </tr>
          </table>

          <div
            style={{
              display: "flex",
              marginTop: "1rem",
              justifyContent: "end",
              padding: "10px 20px",
            }}
          >
            <button
              style={{
                borderRadius: "8px",
                // padding: "8px",
                minWidth: "121px",
                color: "white",
                backgroundColor: colors.primary,
                cursor: "pointer",
                lineHeight: "2.125rem",
                border: `1px solid ${colors.primary}`,
                outline: "none",
                fontWeight: "bold",
              }}
            >
              Approve
            </button>
            <button
              style={{
                marginLeft: "0.5rem",
                borderRadius: "8px",
                fontSize: "16px",
                // padding: "8px",
                minWidth: "121px",
                backgroundColor: "white",
                color: colors.primary,
                cursor: "pointer",
                lineHeight: "2.125rem",
                border: `1px solid ${colors.primary}`,
                outline: "none",
                fontWeight: "bold",
              }}
            >
              Delete
            </button>
          </div>
        </AccountSummaryWrapper>
      </TabContainer>
      <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Pagination
          pageCount={pageCount > 1 && pageCount}
          range={1}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem>Edit Profile</MenuItem>
            <MenuItem
              onClick={() => {
                setAttachModal(!attachModal);
                handleClose();
              }}
            >
              Attachment
            </MenuItem>
            <MenuItem>Assign Class</MenuItem>
            <MenuItem>Assign Subject</MenuItem>
            <MenuItem>Weekly Report</MenuItem>
            <MenuItem>Deactivate</MenuItem>
            <MenuItem>Chat</MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </div>
  );
};

export default StaffList;
