import React, { useCallback, useState } from "react";
import colors from "../../assets/colors.json";
import Modal from "../modal/Modal";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import GlobalStyles from "../../assets/globalStyle";
import api from "../../services/user";
import { toast } from "react-toastify";
import { MenuItem } from "@mui/material";
import FormLoader from "../FormLoader";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { SmallHeader } from "../../pages/Dashboard/styles/dashboard.style";
import {
  AuthButton,
  InputWrapper,
  SmallText,
} from "../../pages/auth/Login.style";
import { isNumber } from "../../services/utils";
import ErrorService from "../../services/errorService";

const ChangePin = ({ color }) => {
  const { admToken, user, loginToken } = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [oldPin, setOldPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const changePinFun = useCallback(async () => {
    let data = {
      newPin,
      oldPin,
      accountType: 3,
      phoneNumber: user.phoneNumber,
    };
    try {
      setFormLoading(true);
      const res = await api.postChangePin(admToken, data, loginToken);
      console.log(res);
      // eslint-disable-next-line eqeqeq
      if (res.status && res.statusCode == 2) {
        toast.success(res.message, {
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setShowModal(false);
      }

      if (res.status && res.statusCode === 3) {
        toast.error(res.message);
      }

      setFormLoading(false);

      res.status === 500 && ErrorService.displayErrorAlert(res.data);
      res.status === 400 && ErrorService.displayErrorAlert(res.data);
      !res.status && ErrorService.displayErrorAlert(res);
    } catch (error) {
      setFormLoading(false);
      toast.error("An Error Occurred");
    }
  }, [admToken, loginToken, newPin, oldPin, user.phoneNumber]);
  const content = (
    <>
      <SmallHeader
        style={{
          textAlign: "center",
          fontWeight: "bold",
          background: colors.primary,
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          padding: "1.4rem",
          color: "white",
        }}
      >
        Change Pin
      </SmallHeader>
      <div style={{ padding: "0.8rem" }}>
        <InputWrapper>
          <label htmlFor="">Old Pin</label>
          <input
            type="password"
            value={oldPin}
            maxLength={6}
            placeholder="Please Enter Old Pin"
            onKeyDown={(e) => isNumber(e)}
            onChange={(e) => setOldPin(e.target.value)}
          />
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="">Pin</label>
          <input
            type="password"
            value={newPin}
            maxLength={6}
            placeholder="Please Enter New Pin"
            onKeyDown={(e) => isNumber(e)}
            onChange={(e) => setNewPin(e.target.value)}
          />
        </InputWrapper>{" "}
        <InputWrapper>
          <label htmlFor="">Confirm Pin</label>
          <input
            type="password"
            value={confirmPin}
            maxLength={6}
            placeholder="Confirm  Pin"
            onKeyDown={(e) => isNumber(e)}
            onChange={(e) => setConfirmPin(e.target.value)}
          />
        </InputWrapper>
        <SmallText
          color="red"
          style={{
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {(newPin.length && confirmPin.length) === 6 && newPin !== confirmPin
            ? "Pin Not Matched"
            : ""}
        </SmallText>
        <div>
          <AuthButton
            width="100%"
            disabled={
              formLoading ||
              ((newPin.length && confirmPin.length) === 6 &&
                newPin !== confirmPin) ||
              oldPin.length < 6
            }
            onClick={() => changePinFun()}
          >
            {formLoading ? <FormLoader /> : "Change Pin"}
          </AuthButton>
        </div>
      </div>
    </>
  );
  return (
    <>
      <GlobalStyles />
      {showModal && (
        <Modal content={content} size={"large"} onClose={setShowModal} />
      )}
      <MenuItem
        sx={{ fontSize: "15px", color: color }}
        onClick={() => {
          setNewPin("");
          setOldPin("");
          setConfirmPin("");
          setShowModal(!showModal);
        }}
      >
        <LockOpenIcon color={color} sx={{ mr: 1 }} /> Change Pin
      </MenuItem>
    </>
  );
};

ChangePin.propTypes = {
  color: PropTypes.string,
};

ChangePin.defaultProps = {
  color: "black",
};
export default ChangePin;
