import { useRoutes } from "react-router-dom";
import Verify from "../pages/auth/Verify";
import Login from "../pages/auth/Login";
import CBTFirst from "../components/CBT/CBTFirst";
import { CBTSecond } from "../components/CBT/CBTSecond";

import {
  Dashboard,
  Assets,
  Assignments,
  Cbt,
  Classwork,
  Clinic,
  ControlPanel,
  Finance,
  // Fleet,
  Hostel,
  HumanResources,
  // Library,
  ParentsMgt,
  Profile,
  QualityAssurance,
  Report,
  Tutorials,
  LessonNote,
  SharedLayouts,
} from "../pages/Dashboard";

import { StudentMgt } from "../pages/student_mgt";
import { MySchool } from "../pages/MySchool";
import { SingleStaff, SingleStudent, SingleParent } from "../pages/Single";
import CreateNewParent from "../components/Parent/CreateNewParent";
import LasmabSetup from "../pages/ControlPanel/LasmabSetup";
import UsersSetup from "../pages/ControlPanel/UsersSetup";
import AcademicSetup from "../pages/ControlPanel/AcademicSetup";
import UserGuideSetup from "../pages/ControlPanel/UserGuideSetup";
import {
  Admission,
  Invoices,
  OnlinePayment,
  PDProfile,
  Reciepts,
  Student,
  PDSharedLayout,
} from "../pages/ParentDashboard";
import { Fleet, LogIssue, VehicleSetup } from "../pages/Fleet";
import {
  BookSetup,
  BookLending,
  Library,
  LibrarySetup,
} from "../pages/Library";
import { PDA_First, PDA_Second } from "../components/PDAdmission";
import {
  Admission_Form,
  Examination_Slip,
  Report_PDA,
} from "../components/PDAdmission/Pages";
import Assignment_First from "../components/Assignment/Assignment_First";
import Assignment_Second from "../components/Assignment/Assignment_Second";
import {
  Payroll,
  Request,
  StaffManagement,
  NominalRoll,
  Approval,
} from "../pages/HR";
import { CBTThird } from "../components/CBT/CBTThird";
import AddAssignment from "../components/Assignment/Modals/AddAssignment";
import Submission from "../components/Assignment/Modals/Submission";

import Classwork_First from "../components/classwork/Classwork_First";

import PublicRoutes from "./PublicRoutes";
import ProtectedRoutes from "./ProtectedRoutes";
import { Tutorial_First } from "../components/tutorial/Tutorial_First";
import { Tutorial_Second } from "../components/tutorial/Tutorial_Second";
import { Classwork_Second } from "../components/classwork/Classwork_Second";
import ResetPassword from "../pages/auth/ResetPassword";
import Classwork_Third from "../components/classwork/Classwork_Tree";
import NonAcademicSetup from "../pages/ControlPanel/NonAcademicSetup";
import AcademicActivivities from "../pages/academic_activities/AcademicActivivities";
import StaffProfile from '../../src/pages/Dashboard/Profile'

export default function Routes() {
  let element = useRoutes([
    {
      element: <ProtectedRoutes />,
      children: [
        {
          children: [
            {
              path: "/academic-setup",
              element: <AcademicSetup />,
            },
            {
              path: "/school-staff-info",
              element: <StaffProfile/>,
            },
            // {
            //   path: "/non-academic-setup",
            //   element: <NonAcademicSetup />,
            // },
            // {
            //   path: "/hr-setup",
            //   element: <HRSetup />,
            // },
            // {
            //   path: "/admin-setup",
            //   element: <AdminSetup />,
            // },
            // {
            //   path: "/chat-setup",
            //   element: <ChatSetup />,
            // },
            {
              path: "/user-guide-setup",
              element: <UserGuideSetup />,
            },
            {
              path: "/non-academic-setup",
              element: <NonAcademicSetup />,
            },
            {
              path: "/lasmab-setup",
              element: <LasmabSetup />,
            },
            {
              path: "/users-setup",
              element: <UsersSetup />,
            },
            {
              element: <SharedLayouts />,
              children: [
                {
                  path: "/dashboard",
                  element: <Dashboard />,
                },
                {
                  path: "/assets",
                  element: <Assets />,
                },
                {
                  element: <Cbt />,
                  children: [
                    {
                      path: "/cbt",
                      element: <CBTFirst />,
                    },
                    {
                      path: "/cbt/submission",
                      element: <CBTSecond />,
                    },
                    {
                      path: "/cbt/addquestion",
                      element: <CBTThird />,
                    },
                  ],
                },
                {
                  element: <Assignments />,
                  children: [
                    { path: "/assignments", element: <Assignment_First /> },
                    {
                      path: "/assignments/submission/:id",
                      element: <Submission />,
                    },
                    {
                      path: "/assignments/add-assignment/:id",
                      element: <AddAssignment />,
                    },
                  ],
                },
                {
                  element: <Classwork />,
                  children: [
                    { path: "/classwork", element: <Classwork_First /> },
                    {
                      path: "/classwork/add-classwork/:data",
                      element: <Classwork_Second />,
                    },
                    {
                      path: "/classwork/submission/:data",
                      element: <Classwork_Third />,
                    },
                  ],
                },
                {
                  path: "/clinic",
                  element: <Clinic />,
                },
                {
                  path: "/lessonnote",
                  element: <LessonNote />,
                },
                {
                  path: "/controlpanel",
                  element: <ControlPanel />,
                },
                {
                  path: "/finance",
                  element: <Finance />,
                },
                // {
                //   path: "/fleet",
                //   element: <Fleet />,
                // },
                {
                  path: "/hostel",
                  element: <Hostel />,
                },
                {
                  path: "/humanresources",
                  element: <HumanResources />,
                  children: [
                    { path: "staff-mgt", element: <StaffManagement /> },
                    { path: "request", element: <Request /> },
                    { path: "payroll", element: <Payroll /> },
                    { path: "nominal-roll", element: <NominalRoll /> },
                    {
                      path: "approval",
                      element: <Approval />,
                    },
                  ],
                },
                // {
                //   path: "/library",
                //   element: <Library />,
                // },

                {
                  path: "/parentmgt",
                  element: <ParentsMgt />,

                  children: [
                    {
                      path: "/parentmgt/:slug",
                      element: <CreateNewParent />,
                    },
                  ],
                },
                {
                  path: "/profile",
                  name: "My Profile",
                  element: <Profile />,
                },
                {
                  path: "/qualityassurance",
                  element: <QualityAssurance />,
                },
                {
                  path: "/report",
                  element: <Report />,
                },
                {
                  path: "/studentmgt",
                  element: <StudentMgt />,
                },
                {
                  path: "/academicActivities",
                  element: <AcademicActivivities />,
                },
                {
                  element: <Fleet />,
                  children: [
                    {
                      path: "/fleet/vehicle",
                      element: <VehicleSetup />,
                    },
                    {
                      path: "/fleet/log-issue",
                      element: <LogIssue />,
                    },
                  ],
                },
                {
                  element: <Library />,
                  children: [
                    {
                      path: "/library/books",
                      element: <BookSetup />,
                    },
                    {
                      path: "/library/book-lending",
                      element: <BookLending />,
                    },
                    {
                      path: "/library/library-settings",
                      element: <LibrarySetup />,
                    },
                  ],
                },
                {
                  path: "/myschools",
                  element: <MySchool />,
                },
                {
                  element: <Tutorials />,
                  children: [
                    {
                      path: "/tutorials",
                      element: <Tutorial_First />,
                    },
                    {
                      path: "/add-tutorial/:id",
                      element: <Tutorial_Second />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "/staff-member",
          element: <SingleStaff />,
        },
        {
          path: "/student",
          element: <SingleStudent />,
        },
        {
          path: "/parent",
          element: <SingleParent />,
        },

        {
          element: <PDSharedLayout />,
          children: [
            {
              element: <Admission />,
              children: [
                { path: "/parentmanagement/admission", element: <PDA_First /> },
                {
                  path: "/parentmanagement/admission/dashboard",
                  element: <PDA_Second />,
                  children: [
                    {
                      path: "/parentmanagement/admission/dashboard/admissionform",
                      element: <Admission_Form />,
                    },
                    {
                      path: "/parentmanagement/admission/dashboard/examinationslip",
                      element: <Examination_Slip />,
                    },
                    {
                      path: "/parentmanagement/admission/dashboard/report",
                      element: <Report_PDA />,
                    },
                  ],
                },
              ],
            },
            {
              path: "/parentmanagement/invoices",
              element: <Invoices />,
            },
            {
              path: "/parentmanagement/onlinepayment",
              element: <OnlinePayment />,
            },
            {
              path: "/parentmanagement/profile",
              element: <PDProfile />,
            },
            {
              path: "/parentmanagement/reciepts",
              element: <Reciepts />,
            },
            {
              path: "/parentmanagement/student",
              element: <Student />,
            },
          ],
        },
      ],
    },

    {
      element: <PublicRoutes />,
      children: [
        {
          index: "/",
          element: <Login />,
        },

        {
          path: "/verify",
          element: <Verify />,
        },
        {
          path: "/resetpassword",
          element: <ResetPassword />,
        },
      ],
    },
  ]);

  return element;
}
