import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Outlet } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
  },
});

const Classwork = () => {
  return (
    <ThemeProvider theme={theme}>
      <Outlet />
    </ThemeProvider>
  );
};

export default Classwork;
