import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  AccountSummaryWrapper,
  BigHeader,
  Container,
  Icon,
  InlineFormDiv,
  Input,
  Search,
  TabContainer,
  TabLinkContainer,
} from "../../../pages/Dashboard/styles/dashboard.style";
import { AiFillCheckCircle } from "react-icons/ai";
import { FaTimesCircle } from "react-icons/fa";
import ErrorService from "../../../services/errorService";
import GlobalStyles from "../../../assets/globalStyle";
import colors from "../../../assets/colors.json";
import { BsSearch } from "react-icons/bs";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import { Box } from "@mui/system";
import { IconButton, MenuItem, MenuList } from "@mui/material";
import { AuthButton, InputWrapper } from "../../../pages/auth/Login.style";
import { useMediaQuery } from "react-responsive";
import Select from "../../select/select";
import Modal from "../../modal/Modal";
import AssignPriviledge from "./AssignPriviledge";
import Loader from "../../pageLoader/loader";
import api from "../../../services/ControlPanel";
import FormLoader from "../../FormLoader";
import moment from "moment";
import { toast } from "react-toastify";
import { checkAccess } from "../../../services/utils";
const StaffRoles = () => {
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [queryId, setQueryId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showPriviledge, setShowPriviledge] = useState(false);
  const isTab = useMediaQuery({ maxWidth: 992 });
  const [anchorEl, setAnchorEl] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [roleSetups, setRoleSetups] = useState([]);
  const [staffRoles, setStaffRoles] = useState([]);

  const [loading, setLoading] = useState(true);
  const [formValid, setFormValid] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [loadingDepartments, setLoadingDepartments] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // School Departments
  const getSchoolDepartments = useCallback(
    async (id) => {
      console.log("working");
      try {
        setLoadingDepartments(true);
        const res = await api.getSchoolDepartments(admToken, user.schoolId, id);
        console.log(res);
        if (res.status && res.statusCode === 2) {
          setDepartments(res.data);
        } else {
          setDepartments([]);
        }
        setLoadingDepartments(false);
      } catch (error) {
        setDepartments([]);
        setLoadingDepartments(false);
      }
    },
    [admToken, user.schoolId]
  );

  // Get Staff Categories
  const getStaffCategories = useCallback(async () => {
    try {
      const res = await api.getStaffAccountCategories(admToken);
      if (res.status && res.statusCode === 2) {
        setCategories(res.data);
        if (res.data.length > 0) {
          setQueryId(res.data[0].id);
        }
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
    }
  }, [admToken]);

  // Get All Staff Roles
  const getRoles = useCallback(
    async (id = queryId) => {
      setLoading(true);
      try {
        const res = await api.getRoles(admToken, id);
        setStaffRoles(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [queryId, admToken]
  );
  // get All STaff Roles Setup
  const getStaffRoles = useCallback(async () => {
    try {
      const res = await api.getUserRolesSetUps(admToken);
      setRoleSetups(res.data);
      setLoading(false);
    } catch (error) {
      setStaffRoles([]);
      setLoading(false);
    }
  }, [admToken]);
  let _obj = {
    categoryId: "",
    departmentId: "",
    roleName: "",
    roleDescription: "",
    categoryName: "",
  };

  const [roleObj, setRoleObj] = useState(_obj);
  // done
  const createRole = useCallback(async () => {
    try {
      setFormLoading(true);
      const res = (await roleObj.roleId)
        ? await api.postUpdateRole(admToken, roleObj)
        : await api.postCreateRole(admToken, roleObj);
      res.status &&
        // eslint-disable-next-line eqeqeq
        res.statusCode == 2 &&
        toast.success("Staff Roles Successfully Created", {
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_LEFT,
        });

      res.status === 500 && ErrorService.displayErrorAlert(res.data);
      res.status === 400 && ErrorService.displayErrorAlert(res.data);
      !res.status && ErrorService.displayErrorAlert(res);
      setShowModal(false);
      getRoles();

      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);
    }
  }, [admToken, getRoles, roleObj]);

  useEffect(() => {
    getStaffCategories();
    getStaffRoles();
  }, [getStaffCategories, getStaffRoles]);

  useEffect(() => {
    if (queryId !== "") {
      getRoles();
    }
  }, [getRoles, queryId]);

  useEffect(() => {
    setFormValid(
      roleObj.roleDescription !== "" &&
        roleObj.roleName !== "" &&
        // roleObj.departmentId !== "" &&
        roleObj.categoryId !== ""
    );
  }, [roleObj]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }}>
        {" "}
        {roleObj.roleId ? "Update" : "Add New "} Roles
      </BigHeader>

      <div>
        <InputWrapper>
          <label htmlFor="">Category</label>
          <Select
            title="Category"
            index="name"
            selected={roleObj.categoryId}
            options={categories}
            setSelected={(val) => {
              setRoleObj({
                ...roleObj,
                departmentId: "",
                categoryId: val.id,
                categoryName: val.name,
              });
              // setcategoryId(val.id);

              // setDepartmentId("");
              getSchoolDepartments(val.id);
            }}
          />
        </InputWrapper>
        {loadingDepartments ? (
          "Pleas wait while we get departments"
        ) : (
          <InputWrapper>
            <label htmlFor="">Department</label>
            <Select
              title={
                departments.length === 0
                  ? "Category Above to Get Departments"
                  : "Department"
              }
              index="department_name"
              selected={roleObj.departmentId}
              options={departments}
              setSelected={(val) => {
                setRoleObj({ ...roleObj, departmentId: val.id });
                // setDepartmentId(val.id);
              }}
            />
          </InputWrapper>
        )}
      </div>
      <InputWrapper>
        <label htmlFor="">Roles</label>

        <input
          type="text"
          placeholder="Enter Role Name"
          onChange={(e) => {
            setRoleObj({ ...roleObj, roleName: e.target.value });
            // setRoleName(e.target.value);
          }}
          value={roleObj.roleName}
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="">Description</label>

        <textarea
          name=""
          id=""
          cols="30"
          rows="4"
          style={{
            resize: "none",
          }}
          onChange={(e) => {
            setRoleObj({ ...roleObj, roleDescription: e.target.value });
            // setRoleDes(e.target.value);
          }}
          value={roleObj.roleDescription}
        ></textarea>
      </InputWrapper>

      <div>
        <AuthButton
          onClick={() => createRole()}
          width="100%"
          mt="0.6rem"
          disabled={!formValid || formLoading}
        >
          {formLoading ? (
            <FormLoader />
          ) : (
            <> {roleObj.roleId ? "Update" : "Add New "} Roles</>
          )}
        </AuthButton>
      </div>
    </>
  );
  if (pageLoading) {
    return <Loader />;
  }
  return (
    <Container>
      <GlobalStyles />
      {showModal && (
        <Modal content={content} size={"large"} onClose={setShowModal} />
      )}
      {showPriviledge && (
        <Modal
          // start={true}
          content={
            <AssignPriviledge
              createRole={true}
              role={roleObj}
              // role={staffRole}
              setups={roleSetups}
              schoolId={user.schoolId}
              reloadFunction={getStaffRoles}
              closeModal={setShowPriviledge}
              showForm={false}
            />
          }
          size={"large"}
          onClose={setShowPriviledge}
          pd={"0px"}
          iColor={"white"}
        />
      )}
      <div>
        <BigHeader
          style={{
            borderBottom: `4px solid ${colors.primary}`,
            paddingBottom: "4px",
          }}
        >
          Staff Roles
        </BigHeader>
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        {checkAccess(accessObj.rights, "Create") && (
          <AuthButton
            mt="0.6rem"
            pl="0px"
            width={isTab ? "50%" : "30%"}
            onClick={() => {
              setRoleObj(Object.assign({}, _obj));
              setShowModal(!showModal);
            }}
          >
            <div>
              <span>Add Staff Role</span>
            </div>
            {/* Send Code */}
          </AuthButton>
        )}
      </div>

      <InlineFormDiv width="100%">
        <div style={{ width: isTab ? "100%" : "70%" }}>
          <InputWrapper>
            <label htmlFor="">Category</label>
            <Select
              title="Category"
              index="name"
              selected={queryId}
              options={categories}
              setSelected={(val) => {
                setQueryId(val.id);
              }}
            />
          </InputWrapper>
        </div>
      </InlineFormDiv>

      {/* needed */}
      <TabContainer style={{ paddingBottom: "0px" }}>
        <TabLinkContainer bg="rgb(238, 224, 241)" style={{ display: "none" }}>
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search" />
          </Input>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          {loading ? (
            <Loader />
          ) : (
            <table
              style={{
                borderRadius: "20px",
                // borderBottomLeftRadius: "20px",
                // borderBottomRightRadius: "20px",
              }}
            >
              <tr
                className="table-head"
                style={{ color: "black", fontWeight: "bold" }}
              >
                <th>S/N</th>
                <th>Roles ({staffRoles.length})</th>

                <th>Category </th>
                <th>Created By</th>
                <th>Privileges Assigned</th>
                <th>Created Date</th>
                <th></th>
              </tr>

              {staffRoles.length > 0 ? (
                staffRoles.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.roleName}</td>

                    <td>{item.categoryName}</td>
                    <td>{item.createdBy}</td>
                    <td>
                      {item.assignPrivilege ? (
                        <AiFillCheckCircle color={"green"} />
                      ) : (
                        <FaTimesCircle color="red" />
                      )}
                    </td>
                    <td>{moment(item.createdDate).format("DD/MM/YYYY")}</td>
                    <td>
                      {(checkAccess(accessObj.rights, "Assign") ||
                        checkAccess(accessObj.rights, "Edit")) && (
                        <IconButton
                          onClick={(e) => {
                            handleClick(e);

                            getSchoolDepartments(item.categoryId);
                            setRoleObj(Object.assign({}, item));
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={6}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    No Staff Roles For This Category
                  </td>
                </tr>
              )}
            </table>
          )}
        </AccountSummaryWrapper>
      </TabContainer>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            {checkAccess(accessObj.rights, "Assign") && (
              <MenuItem
                onClick={() => {
                  setShowPriviledge(!showPriviledge);
                  handleClose();
                }}
              >
                Assign Privileges
              </MenuItem>
            )}

            {checkAccess(accessObj.rights, "Edit") && (
              <MenuItem
                onClick={() => {
                  setShowModal(!showModal);
                  handleClose();
                }}
              >
                Edit Role
              </MenuItem>
            )}
          </MenuList>
        </Box>
      </Popover>
    </Container>
  );
};

export default StaffRoles;
