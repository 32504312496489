import React, { useState, useCallback, useEffect } from "react";
import { getSessions, getTerms } from "../../../services/Subject";
import { useSelector } from "react-redux";
import {
  AccountSummaryWrapper,
  Container,
  SmallHeader,
} from "../../../pages/Dashboard/styles/dashboard.style";
import {
  InlineLabelGroup,
  InlineLabelWrapper,
} from "../../../pages/Dashboard/styles/dashboard.style";
import Modal from "../../modal/Modal";
import { BiSolidEdit } from "react-icons/bi";
import {
  AuthButton,
  InputWrapper,
  SmallText,
} from "../../../pages/auth/Login.style";
import DatePickerComponent from "../../datepicker/DatePicker";
import Select from "../../select/select";
import colors from "../../../assets/colors.json";
import Loader from "../../pageLoader/loader";

import ErrorService from "../../../services/errorService";
import api from "../../../services/ControlPanel";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import { checkAccess } from "../../../services/utils";
const ResultTermPeriod = () => {
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [loadTerm, setLoadTerm] = useState(true);
  const [terms, setTerms] = useState([]);
  const [session, setSession] = useState({
    id: "",
    name: "",
  });
  const [sessions, setSessions] = useState([]);
  const [term, setTerm] = useState({
    id: "",
    name: "",
  });
  const [loading, setLoading] = useState(false);
  const [objData, setObjData] = useState(null);
  const getSessionTermPeriod = useCallback(
    async (sessionId, termId) => {
      try {
        setLoading(true);
        const res = await api.getSessionTermPeriod(
          admToken,
          user.schoolId,
          sessionId,
          termId
        );
        if (res.status === true && res.statusCode === 2 && res?.data) {
          setObjData(res?.data);
        }

        if (res.status === false && res.statusCode === 6) {
          toast.error(res?.message);
        }

        (res.status === 500 || res.status === 400 || res.status === false) &&
          ErrorService.displayErrorAlert(res.data);
        setLoading(false);
      } catch (error) {
        console.log("errr here", error);
        setLoading(false);
        // toast.error("An Error Occurred!");
      }
    },
    [admToken, user.schoolId]
  );
  const schoolTerms = useCallback(async () => {
    try {
      const res = await getTerms(admToken);
      res.status && res.statusCode === 2 && setTerms(res.data);
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
      setLoadTerm(false);
    } catch (error) {
      setLoadTerm(false);

      console.log(error);
    }
  }, [admToken]);
  const schoolSessions = useCallback(async () => {
    try {
      const res = await getSessions(admToken);

      res.status && res.statusCode === 2 && setSessions(res.data);
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);
  useEffect(() => {
    schoolTerms();
    schoolSessions();
  }, [schoolSessions, schoolTerms]);
  const _obj = {
    close: "",
    reopen: "",
    schoolId: user.schoolId,
    checkin: "",
    duration: "",
    term: 0,
    sessionId: 0,
    id: 0,
  };
  const [formObj, setFormObj] = useState(_obj);
  const [showModal, setShowModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const performAction = useCallback(async () => {
    try {
      setFormLoading(true);
      const res = await api.addUpdateTermPeriod(
        admToken,
        formObj,
        formObj.id > 0 ? "Update" : "Add"
      );

      if (res.status === true && res.statusCode === 2) {
        toast.success(res.message);
        getSessionTermPeriod(formObj.sessionId, formObj.term);
      }
      setShowModal(false);
      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);

      console.log(error);
    }
  }, [admToken, formObj, getSessionTermPeriod]);

  const content = (
    <>
      <SmallHeader
        style={{
          textAlign: "center",
          fontWeight: "bold",
          backgroundColor: colors.primary,
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          padding: "1.4rem",
          color: "white",
        }}
      >
        {formObj.id > 0 ? "Update" : "Add"} Session Term Period
      </SmallHeader>
      <InputWrapper>
        <label htmlFor="">Session</label>

        <Select
          disabled={formObj.id > 0}
          title="Session"
          index="session" // no
          selected={formObj.sessionId} // false
          options={sessions}
          setSelected={(val) => {
            setFormObj({ ...formObj, sessionId: val.id });
            setSession({
              ...session,
              id: val.id,
              name: val.session,
            });
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="">Term</label>

        <Select
          disabled={formObj.id > 0}
          title={loadTerm ? "LOading..." : "Term"}
          index="term" // no
          selected={formObj.term} // false
          options={terms}
          setSelected={(val) => {
            setFormObj({ ...formObj, term: val.id });
            setTerm({
              ...term,
              id: val.id,
              name: val.term,
            });
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="">Date Of Reopen</label>
        <input
          type="text"
          value={formObj.reopen}
          placeholder="Reopening Date"
          onChange={(e) => setFormObj({ ...formObj, reopen: e.target.value })}
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="">Duration</label>
        <input
          type="text"
          value={formObj.duration}
          placeholder="Duration Period"
          onChange={(e) => setFormObj({ ...formObj, duration: e.target.value })}
        />
      </InputWrapper>{" "}
      <InputWrapper>
        <label htmlFor="">Closing Date</label>
        <input
          type="text"
          value={formObj.close}
          placeholder="Closing Date"
          onChange={(e) => setFormObj({ ...formObj, close: e.target.value })}
        />
      </InputWrapper>{" "}
      <InputWrapper>
        <label htmlFor="">Check In Date</label>
        <input
          type="text"
          value={formObj.checkin}
          placeholder="Check In Date"
          onChange={(e) => setFormObj({ ...formObj, checkin: e.target.value })}
        />
      </InputWrapper>
      <AuthButton
        onClick={performAction}
        disabled={formLoading}
        width="100%"
        mt="0.4rem"
      >
        {formLoading ? "Please wait..." : "Proceed"}
      </AuthButton>
    </>
  );
  return (
    <Container>
      {showModal && (
        <Modal
          content={content}
          show={showModal}
          size="large"
          onClose={setShowModal}
          iColor={"white"}
          pd={"10px"}
        />
      )}
      <InlineLabelGroup>
        <InlineLabelWrapper flex={2}>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              {" "}
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title="Session"
                  index="session" // no
                  selected={session.id} // false
                  options={sessions}
                  setSelected={(val) => {
                    setSession({
                      ...session,
                      id: val.id,
                      name: val.session,
                    });

                    // getClasses(val.id);
                    // setAllData([]);

                    setSession({
                      ...session,
                      id: val.id,
                      name: val.session,
                    });
                    if (term.name.length > 0) {
                      getSessionTermPeriod(val.id, term.id);
                    }
                  }}
                  // color="white"
                />
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title={loadTerm ? "LOading..." : "Term"}
                  index="term" // no
                  selected={term.id} // false
                  options={terms}
                  setSelected={(val) => {
                    setTerm({ ...term, id: val.id, name: val.term });

                    if (session.name.length > 0) {
                      getSessionTermPeriod(session.id, val.id);
                    }
                    // setAllData([]);
                  }}
                  // color="white"
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </InlineLabelWrapper>
      </InlineLabelGroup>

      <div style={{ display: "flex", justifyContent: "end" }}>
        {checkAccess(accessObj.rights, "Create") && (
          <AuthButton
            mt="0.3rem"
            onClick={() => {
              setFormObj(Object.assign({}, _obj));
              setShowModal(!showModal);
            }}
          >
            Add Session Period
          </AuthButton>
        )}
      </div>

      {loading ? (
        <AccountSummaryWrapper>
          <Loader />
        </AccountSummaryWrapper>
      ) : objData !== null ? (
        <Grid container>
          <Grid item lg={5} sm={8} xs={12}>
            <div
              style={{
                width: "100%",
                borderRadius: "7px",
                padding: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                position: "relative",
              }}
            >
              <SmallText color={colors.primary} style={{ fontSize: "18px" }}>
                Session Term Period for {session.name} {term.name}
              </SmallText>
              <SmallText style={{ fontSize: "17px", fontWeight: "bold" }}>
                Check In Date -{" "}
                <span style={{ fontSize: " 14px", color: colors.black }}>
                  {objData.checkin}
                </span>
              </SmallText>{" "}
              <SmallText style={{ fontSize: "17px", fontWeight: "bold" }}>
                Closing Date -{" "}
                <span style={{ fontSize: " 14px", color: colors.black }}>
                  {objData.close}
                </span>{" "}
              </SmallText>{" "}
              <SmallText style={{ fontSize: "17px", fontWeight: "bold" }}>
                Duration -{" "}
                <span style={{ fontSize: " 14px", color: colors.black }}>
                  {objData.duration}
                </span>{" "}
              </SmallText>
              <SmallText style={{ fontSize: "17px", fontWeight: "bold" }}>
                Reopening Date -{" "}
                <span style={{ fontSize: " 14px", color: colors.black }}>
                  {objData.reopen}
                </span>
              </SmallText>{" "}
              <BiSolidEdit
                onClick={() => {
                  setFormObj({
                    ...formObj,
                    duration: objData.duration,
                    checkin: objData.checkin,
                    close: objData.close,
                    id: objData.id,
                    reopen: objData.reopen,
                    schoolId: objData.schoolId,
                    sessionId: objData.sessionId,
                    term: objData.term,
                  });
                  setShowModal(true);
                }}
                color={colors.primary}
                style={{
                  position: "absolute",
                  right: "9px",
                  bottom: "7px",
                  cursor: "pointer",
                }}
              />
            </div>
          </Grid>
        </Grid>
      ) : (
        <SmallHeader style={{ textAlign: "center" }}>
          {" "}
          {session.name === "" || term.name === ""
            ? "Please select Term and Session to Get Started"
            : "No Data"}
        </SmallHeader>
      )}
    </Container>
  );
};

export default ResultTermPeriod;
