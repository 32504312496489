import React, { useState } from "react";
import { Box } from "@mui/system";
import { Outlet } from "react-router-dom";

import {PDNavbar, PDHeader }from "../components/PDLayouts";


const PDSharedLayout = () => {
  const [open, setOpen] = useState(false);
  return (
    <Box sx={{ display: "flex" }}>
      <PDNavbar
        controlNav={() => {
          if (open === true) {
            setOpen(false);
          }
        }}
        isOpen={open}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { lg: "90%", md: "90%", sm: "100%", xs: "100%" },
        }}
      >
        <PDHeader />
        <Box mt={14} sx={{ mx: "auto", width: "85%", overflowY:'scroll', height:'83vh' }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default PDSharedLayout;