import React from "react";
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import CPNavbar from "../../components/CPLayouts/CPNavBar";
import { NonAcademicData } from "../../assets/data/ControlPanelData";
import { DashboardHeader } from "../../components/Dashboard";
import { checkAccess } from "../../services/utils";
import { useSelector } from "react-redux";
import HostelManagement from "../../components/ControlPanel/NonAcademic/HostelManagement";

const NonAcademicSetup = () => {
  const location = useLocation();
  const { accessObj } = useSelector((state) => state.auth);
  const [open, setOpen] = React.useState(false);
  const page_id = Number(new URLSearchParams(location.search).get("page")) || 0;
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CPNavbar
        controlNav={() => {
          if (open === true) {
            setOpen(false);
          }
        }}
        isOpen={open}
        title="Non Academic Setup"
        navData={NonAcademicData}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { lg: "90%", md: "90%", sm: "100%", xs: "100%" },
        }}
      >
        <DashboardHeader
          // setView={setView}
          // view={view}
          title="Non Academic Setup"
          navData={NonAcademicData}
        />
        {/* <Box mt={13} sx={{ mx: "auto", width: isMobile ? "90%" : "85%" }}>
          {page_id === 0 && <ChooseNav />}
        </Box> */}
        <Box mt={13} sx={{ mx: "auto", width: isMobile ? "90%" : "85%" }}>
          {checkAccess(accessObj.menuItems, "Non-Academic Setup") &&
            checkAccess(accessObj.menuItems, "Hostel") &&
            page_id === 1 && <HostelManagement />}
        </Box>
      </Box>
    </Box>
  );
};

export default NonAcademicSetup;
