import React from "react";
import { Box, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AdSideBar from "./AdSideBar";
import AdDrawer from "./AdDrawer";
import { GoBackDiv } from "../../pages/student_mgt/stu_mgt.style";
import { BsArrowLeftRight, BsArrowLeftShort } from "react-icons/bs";
import { useLocation } from "react-router-dom";
// import AdmissionForm from "./AdmissionForm";
import { AdmissionForm, AdmissionReport, AdmissionSlip } from "./index";

const SingleAdmission = () => {
  const location = useLocation();
  const tab_id = Number(new URLSearchParams(location.search).get("tab")) || 1;

  return (
    <>
      <Box>
        <Box mt={2} sx={{ display: "flex", justifyContent: "space-between" }}>
          <GoBackDiv style={{ cursor: "pointer" }}>
            <div>
              <BsArrowLeftShort />
            </div>
            <span>Back To List</span>
          </GoBackDiv>
        </Box>
        <Box sx={{ display: { lg: "none", sm: "block" } }}>
          <AdDrawer />
        </Box>

        <Box
          sx={{
            minHeight: "68vh",
            overflow: "scroll",
            display: "flex",
            justifyContent: "space-between",
            mt: 3,
          }}
        >
          <Box
            sx={{
              width: "25%",
              display: { lg: "block", md: "none", sm: "none", xs: "none" },
              height: "68vh",
            }}
          >
            <AdSideBar />
          </Box>
          <Box
            sx={{
              width: { lg: "72%", sm: "100%", xs: "100%" },
              // border: "0.5px solid #5e9c09",
              // borderRadius: "15px",
              p: 2,
            }}
          >
            {tab_id === 1 && <AdmissionForm />}
            {tab_id === 2 && <AdmissionSlip />}
            {tab_id === 3 && <AdmissionReport />}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SingleAdmission;
