import styled from "styled-components";
import colors from "../../assets/colors.json";

export const TabRouteContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  display: -webkit-inline-box;
  flex-wrap: nowrap !important;
  border-bottom: 1px solid ${colors.lightGray};
  margin: 0.3rem 0rem;
  padding: 0 !important;

  &::-webkit-scrollbar {
    width: 3px;
    height: 7px;
    visibility: hidden;
    display: none;
  }
`;
export const SchoolTray = styled(TabRouteContainer)`
  padding-bottom: 14px !important;
  border: none !important;
  /* &::-webkit-scrollbar {
    width: 3px;
    height: 7px;
    visibility: hidden; 
     display: none;
  } */

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    /* background-color: #c3c2c2; */
    width: 3px;
    height: 7px;
    background: ${colors.primary};
  }

  ::-webkit-scrollbar {
    border-radius: 5px;
    background: ${colors.secondary};
    width: 3px;
    height: 10px;
    display: block;
    visibility: visible;
  }
`;

export const TabRoute = styled.div`
  width: fit-content !important;
  padding: 0.4rem 0.6rem;
  cursor: pointer;
  text-align: center;
  border-bottom: ${(props) =>
    !props.isActive ? `` : `2px solid ${colors.primary}`};
  a,
  span {
    width: 100% !important;
    color: ${(props) => (!props.isActive ? colors.gray : colors.primary)};
    font-weight: ${(props) => (!props.isActive ? "bold" : "bold")};
    font-size: ${(props) => (!props.isActive ? "15px" : "19px")};
  }
`;

export const ImgTextDiv = styled.div`
  display: inline-flex !important;
  align-items: center;

  & > img {
    height: 39px;
    width: 39px;
    border-radius: 50%;
  }
  & > img:hover {
    transform: scale(1.4);
  }
  & > img.clicked {
    transform: scale(1.4);
    transition: transform 0.3s ease;
  }
  & > p {
    margin-left: 0.5rem;
    font-weight: 600;
    width: max-content;
  }
  & > span {
    margin-left: 0.5rem;
    font-weight: 600;
  }
  & > div {
    margin-left: 0.5rem;
    font-weight: 600;
  }
`;

export const StatusBar = styled.p`
  padding: 10px;
  border: 1px solid;
  border-radius: 10px;
  background-color: ${(props) =>
    props.status === "8"
      ? colors.secondary
      : props.status === "3.0" || props.status === "3"
      ? colors.secondary
      : props.status === "2"
      ? "rgba(94, 158, 8,0.1)"
      : "rgba(255, 0,0,0.1)"};

  font-weight: bold;
  color: ${(props) =>
    props.status === "8"
      ? colors.primary
      : props.status === "3.0" || props.status === "3"
      ? colors.primary
      : props.status === "2"
      ? "#539C08"
      : "red"};
  width: fit-content;
  text-align: center;
`;

export const GoBackDiv = styled.div`
  display: inline-flex !important;
  align-items: center;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    background-color: white;
    border-radius: 50%;
    & > svg {
      font-size: 19px;
      background: ${colors.secondary};
      font-weight: bold;
      border-radius: 50%;
    }
  }

  & > span {
    margin-left: 2px;
    color: ${colors.black};
    font-size: 15px;
    font-weight: bold;
  }
`;
