import React, { useState, createRef, useRef } from "react";
import PropTypes from "prop-types";

import {
  PageWrapper,
  InlineFormDiv,
  SlidingImg,
  SmallHeader,
} from "../../../pages/Dashboard/styles/dashboard.style";
import Loader from "../../pageLoader/loader";

import { useMediaQuery } from "react-responsive";

import api from "../../../services/ControlPanel";
import { InputWrapper, AuthButton } from "../../../pages/auth/Login.style";
import Avatar from "../../../assets/Images/avatar.jpg";
import colors from "../../../assets/colors.json";
import FormLoader from "../../FormLoader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ErrorService from "../../../services/errorService";
import { checkAccess } from "../../../services/utils";
const Values = ({ arr, loading, getData }) => {
  const { admToken, accessObj } = useSelector((state) => state.auth);
  const myRef = useRef();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [formLoading, setFormLoading] = useState(false);
  const isPhone = useMediaQuery({ maxWidth: 768 });
  const _obj = {
    valueContent: "",
    valueContent2: "",
    valueContent3: "",
    valueheading: "",
    // imageurl: "",
  };
  const [valueObj, setValueObj] = useState(_obj);
  const pickFile = (ref) => {
    ref.current.click();
  };
  const onPickFile = (event) => {
    const files = event.target.files;
    let filename = files[0].name;
    if (
      filename.includes(".jpg") ||
      filename.includes(".png") ||
      filename.includes(".jpeg")
    ) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        setValueObj({
          ...valueObj,
          imageurl: fileReader.result,
          name: filename,
        });
      });
      fileReader.readAsDataURL(files[0]);
    } else {
      alert("Please Upload A Photo");
    }

    console.log("We are working");
  };

  const submitValue = async () => {
    setFormLoading(true);

    try {
      const res = await api.postControlPanelLasmabSetup(
        valueObj,
        admToken,
        valueObj.id ? "Update-lasmab-values" : "Add-Value"
      );
      console.log(res);
      if (res.status && res.statusCode === 2) {
        toast.success(res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        getData();
        setValueObj(Object.assign({}, _obj));
      }
      if (res.status === 400 || res.status === 500 || res.status === false) {
        ErrorService.displayErrorAlert(res.data);
      }

      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);

      console.log(error);
    }
  };
  const onValueFileRef = createRef();
  if (loading) {
    return <Loader />;
  }
  return (
    <PageWrapper width="95%" ref={myRef}>
      {/* <InlineFormDiv width="90%" jc="space-between">
        <div
          style={{
            width: isMobile ? "100%" : "70%",
          }}
        >
          <input
            type="file"
            style={{ display: "none" }}
            onChange={(e) => onPickFile(e)}
            ref={onValueFileRef}
          />
          <InputWrapper>
            <label htmlFor="filename">Upload Image</label>
            <input
              type="text"
              value={valueObj?.name}
              disabled={valueObj?.name && valueObj?.name.length > 0}
              placeholder="File Image"
              onClick={() => pickFile(onValueFileRef)}
            />
          </InputWrapper>
        </div>
        <AuthButton
          width={isMobile ? "100%" : "20%"}
          onClick={() => pickFile(onValueFileRef)}
        >
          Upload
        </AuthButton>
      </InlineFormDiv> */}
      <InputWrapper>
        <label htmlFor="">Heading</label>
        <input
          type="text"
          placeholder="Value's Heading"
          value={valueObj.valueheading}
          onChange={(e) =>
            setValueObj({
              ...valueObj,
              valueheading: e.target.value,
            })
          }
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="">Paragraph 1</label>
        <textarea
          name=""
          id=""
          placeholder="Value's Description"
          cols="30"
          rows="3"
          value={valueObj.valueContent}
          onChange={(e) =>
            setValueObj({
              ...valueObj,
              valueContent: e.target.value,
            })
          }
        ></textarea>
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="">Paragraph 2</label>
        <textarea
          name=""
          id=""
          placeholder="Value's Description"
          cols="30"
          rows="3"
          value={valueObj.valueContent2}
          onChange={(e) =>
            setValueObj({
              ...valueObj,
              valueContent2: e.target.value,
            })
          }
        ></textarea>
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="">Paragraph 3</label>
        <textarea
          name=""
          id=""
          placeholder="Value's Description"
          cols="30"
          rows="2"
          value={valueObj.valueContent3}
          onChange={(e) =>
            setValueObj({
              ...valueObj,
              valueContent3: e.target.value,
            })
          }
        ></textarea>
      </InputWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          borderBottom: `2px solid ${colors.primary}`,
          paddingBottom: "10px",
        }}
      >
        {((valueObj.id && checkAccess(accessObj.rights, "Edit")) ||
          checkAccess(accessObj.rights, "Save")) && (
          <AuthButton
            mt="0.3rem"
            disabled={
              formLoading ||
              valueObj.valueheading === "" ||
              valueObj.valueContent === "" ||
              valueObj.imageurl === ""
            }
            onClick={() => submitValue()}
          >
            {formLoading ? (
              <FormLoader />
            ) : valueObj.id ? (
              "Update Info"
            ) : (
              "Add Info"
            )}
          </AuthButton>
        )}

        {valueObj.id && (
          <AuthButton
            style={{ marginLeft: "0.3rem" }}
            mt="0.3rem"
            bg="red"
            onClick={() => setValueObj(Object.assign({}, _obj))}
          >
            Clear Fields
          </AuthButton>
        )}
      </div>
      {arr.length > 0 ? (
        arr.map((item, index) => (
          <SlidingImg key={index}>
            <div>
              {/* <img
                src={
                  item.imageurl === "" || item.imageurl === null
                    ? Avatar
                    : item.imageurl
                }
                alt=""
              /> */}
              <div
                className=""
                style={{
                  // display: isPhone ? "none" : "block",
                  padding: "0.4rem",
                }}
              >
                <h5 style={{ fontWeight: "bold" }}>{item.valueheading}</h5>
                <div className="desc">
                  <p>{item.valueContent}</p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: isPhone ? "column" : "row",
              }}
            >
              {checkAccess(accessObj.rights, "Edit") && (
                <button
                  style={{
                    backgroundColor: colors.primary,
                    color: "white",
                    fontSize: "14px",
                  }}
                  onClick={() => {
                    const element = myRef.current;
                    const extraOffset = 50; // Adjust this value to your preference

                    window.scrollTo({
                      top: element.offsetTop - extraOffset,
                      behavior: "smooth",
                    });
                    setValueObj(Object.assign({}, item));
                  }}
                >
                  Edit
                </button>
              )}

              {checkAccess(accessObj.rights, "Delete") && (
                <button
                  style={{
                    marginTop: isPhone ? "0.3rem" : "0rem",
                    color: "white",
                    backgroundColor: "red",
                    borderColor: "red",
                    fontSize: "14px",
                  }}
                >
                  Delete
                </button>
              )}
            </div>
          </SlidingImg>
        ))
      ) : (
        <SmallHeader style={{ textAlign: "center" }}>
          No Values At The Moment
        </SmallHeader>
      )}
    </PageWrapper>
  );
};

Values.propTypes = {
  arr: PropTypes.array,
  loading: PropTypes.bool,
  getData: PropTypes.func,
};

Values.defaultProps = {
  arr: [],
  loading: true,
};

export default Values;
