import React, { useState, useEffect } from "react";
import { InputWrapper } from "../../pages/auth/Login.style";
import Select from "../select/select";
import colors from "../../assets/colors.json";
import { AuthButton } from "../../pages/auth/Login.style";
import { toast } from "react-toastify";
import ErrorService from "../../services/errorService";
import hostelApi from "../../services/hostel";

const AddFloorModal = ({
  hostel,
  user,
  getFloors,
  setOpenAddModal,
  admToken,
  getHostels,
  edit = false,
  selectedFloor,
  setOpenEditModal,
}) => {
  const [floor, setFloor] = useState("");
  const [loading, setLoading] = useState(false);
  // console.log(selectedFloor);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      floor_id: 0,
      hostel_id: hostel.hostel_id,
      floor: floor,
      schoolid: user.schoolId,
    };

    const editData = {
      floor_id: selectedFloor?.floorId,
      hostel_id: hostel.hostel_id,
      floor: floor,
      schoolid: user.schoolId,
      createdBy: user.staffId,
      createdDate: new Date(),
    };
    try {
      setLoading(true);
      const res = await (edit
        ? hostelApi.editHostelFloor(admToken, editData)
        : hostelApi.createHostelFloor(admToken, data));
      if (res?.status && res?.statusCode === 2) {
        toast.success(res.message);
        getFloors();
        getHostels();
        edit && setOpenEditModal(false);
        !edit && setOpenAddModal(false);
        setLoading(false);
      } else if (res?.status === 500 || res?.status === 400 || !res?.status) {
        ErrorService.displayErrorAlert(res);
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (edit && selectedFloor) {
      setFloor(selectedFloor.floorName);
    }
  }, [edit, selectedFloor]);
  return (
    <form onSubmit={handleSubmit} className="mt-8">
      <h1 className="text-2xl font-semibold">
        {edit
          ? `Edit ${selectedFloor.floorName} of  ${hostel?.hostel_name}`
          : `Create Floor for ${hostel?.hostel_name}`}
      </h1>

      <div className="">
        <InputWrapper>
          <label>Floor Name</label>
          <input
            type="text"
            name=""
            id=""
            value={floor}
            onChange={(e) => setFloor(e.target.value)}
          />
        </InputWrapper>
      </div>

      <AuthButton
        bg={colors.primary}
        disabled={loading || !floor}
        className="!rounded-md w-full"
        type="submit"
      >
        {loading ? "Loading..." : `${edit ? "Edit" : "Create"}  floor`}
      </AuthButton>
    </form>
  );
};

export default AddFloorModal;
