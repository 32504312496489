import React, { useEffect, useState, Suspense, lazy } from "react";
import {
  AuthWrapper,
  AuthLeftWrapper,
  LeftWrapperContent,
  LogoWrapper,
} from "./auth.style";
import GlobalStyles from "../../assets/globalStyle";
import logo from "../../assets/school-logo.png";
import "../../assets/style.css";
import "react-phone-input-2/lib/style.css";
import RightSide from "../../components/RightSide";
import Loader from "../../components/pageLoader/loader";
const SendCodeReset = lazy(() => import("../../components/auth/SendCodeReset"));
const ChangeCodeReset = lazy(() =>
  import("../../components/auth/ChangeCodeReset")
);
const ResetPassword = () => {
  const [sendCodeObj, setSendCodeObj] = useState(null);
  let view;
  if (sendCodeObj === null) {
    view = (
      <Suspense fallback={<Loader />}>
        <SendCodeReset changeCode={setSendCodeObj} />
      </Suspense>
    );
  }

  if (sendCodeObj && sendCodeObj.showComp === "resetPin") {
    view = (
      <Suspense fallback={<Loader />}>
        <ChangeCodeReset details={sendCodeObj} changeCode={setSendCodeObj} />
      </Suspense>
    );
  }
  return (
    <AuthWrapper>
      <GlobalStyles />
      <AuthLeftWrapper>
        <LeftWrapperContent>
          <LogoWrapper>
            <img src={logo} alt="lasmab-logo" />
            <div>
              <p>Lagos Anglican Schools</p>
              <p>Management Board</p>
            </div>
          </LogoWrapper>
          {view}
        </LeftWrapperContent>
      </AuthLeftWrapper>
      <RightSide />
    </AuthWrapper>
  );
};

export default ResetPassword;
