import React, { useState, useEffect, useCallback } from "react";
import {
  TabContainer,
  TabLink,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import LinkedUnLinkedStudent from "../../components/StudentMgt/LinkedUnLinkedStudent";
import Loader from "../../components/pageLoader/loader";
import { useSelector } from "react-redux";
import ErrorService from "../../services/errorService";
import { getLinkedUnLinkStudents } from "../../services/ParentStudent";
const StudentList = () => {
  const { admToken, user } = useSelector((state) => state.auth);
  const [tab, setTab] = useState(1);
  // const [search, setSearch] = useState("");
  const [obj, setObj] = useState(null);
  const [loading, setLoading] = useState(false);
  const getLinkedUnLinked = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getLinkedUnLinkStudents(
        admToken,
        user.schoolId,
        user.classId,
        ""
      );
      console.log(res);
      res.status && res.statusCode === 2 && setObj(res.data);
      res.status === 400 && ErrorService.displayErrorAlert(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [admToken, user]);

  useEffect(() => {
    getLinkedUnLinked();
  }, [getLinkedUnLinked]);

  return (
    <>
      {loading && <Loader />}
      {!loading && obj !== null && (
        <TabContainer style={{ paddingBottom: "0px", border: "none" }}>
          <TabLinkContainer style={{ border: "1px solid" }}>
            <TabLink
              isActive={tab === 1}
              onClick={() => setTab(1)}
              minWidth={"50%"}
            >
              Linked Student ({obj.linkedStudent.length})
            </TabLink>
            <TabLink
              isActive={tab === 2}
              onClick={() => setTab(2)}
              minWidth={"50%"}
            >
              Unlinked Student ({obj.unLinkedStudent.length})
            </TabLink>
          </TabLinkContainer>

          {tab === 1 && (
            <LinkedUnLinkedStudent
              allData={obj.linkedStudent}
              type="Linked"
              schoolId={user.schoolId}
            />
          )}

          {tab === 2 && (
            <LinkedUnLinkedStudent
              allData={obj.unLinkedStudent}
              type="Unlinked"
              schoolId={user.schoolId}
              action={getLinkedUnLinked}
            />
          )}
        </TabContainer>
      )}
    </>
  );
};

export default StudentList;
