/* eslint-disable eqeqeq */
import { useState, useEffect, useCallback } from "react";
import Modal from "../../modal/Modal";
import ErrorService from "../../../services/errorService";
import FormLoader from "../../FormLoader";
import { Grid, createTheme, ThemeProvider, Box } from "@mui/material";

import colors from "../../../assets/colors.json";
import Pagination from "../../../components/pagination_one/pagination";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Loader from "../../pageLoader/loader";
import api from "../../../services/ControlPanel";
import { checkAccess, paginateData } from "../../../services/utils";
import { useSelector } from "react-redux";
import { AuthButton, InputWrapper } from "../../../pages/auth/Login.style";
import {
  TabContainer,
  AccountSummaryWrapper,
  BigHeader,
  Search,
  Icon,
  MenuDiv,
  Input,
  SmallHeader,
} from "../../../pages/Dashboard/styles/dashboard.style";
import Select from "../../select/select";
import NewSelect from "@mui/material/Select";

import { BsSearch } from "react-icons/bs";
import { toast } from "react-toastify";
const theme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
  },
});
const Topics = () => {
  const [id, setId] = useState("");
  const [loadSub, setLoadSub] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffSet, setitemOffSet] = useState(0);
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [formLoading, setFormLoading] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [list, setList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [sQuery, setSQuery] = useState("");
  let _obj = {
    id: 0,
    accountType: 0,
    name: "",
  };

  const [topicObj, setTopicObj] = useState(_obj);
  const performAction = async () => {
    try {
      setFormLoading(true);
      const res = await api.addTopicQuestion(admToken, topicObj, "AddTopic");
      setFormLoading(false);
      res.status &&
        res.statusCode === 2 &&
        toast.success(res.message) &&
        setShowModal(false);
      res.status && res.statusCode === 2 && getDataFunc();
      !res.status && toast.error(res.message);
      res.status === 400 && ErrorService.displayErrorAlert(res);
    } catch (error) {
      setFormLoading(false);
    }
  };
  const accTypes = [
    { id: 0, name: "General" },
    { id: 1, name: "Students" },
    { id: 2, name: "Parent" },
    { id: 3, name: "Admin" },
  ];
  const [showModal, setShowModal] = useState(false);

  const filterSearch = async (e) => {
    let arr = [];
    console.log(arr);
    const queryRegex = new RegExp(e.target.value, "i");
    allData.forEach((item) => {
      Object.values(item).forEach((val) =>
        queryRegex.test(val) ? arr.push(item) : ""
      );
    });
    console.log(arr);
    setList(arr);
    setPageCount(0);
    // }
  };
  const getDataFunc = useCallback(async () => {
    try {
      const res = await api.getTopicsQuestions(admToken, "GetTopics");
      console.log(res);
      res.status && res.statusCode === 2 && setAllData(res.data);
      (res.status === 500 || res.status === 400) &&
        ErrorService.displayErrorAlert(res.data);
      setLoadSub(false);
    } catch (error) {
      console.log(error);
      setLoadSub(false);
    }
  }, [admToken]);
  const resetData = useCallback(() => {
    setCurrentPage(1);
    console.log("reset data working");
    paginateData(allData, setList, 1, itemOffSet, setitemOffSet, setPageCount);
  }, [allData, itemOffSet]);

  const delAction = useCallback(
    async (id) => {
      try {
        setFormLoading(true);
        const res = await api.deleteTopicStepQuestion(
          admToken,
          "DeleteTopic",
          id
        );

        setFormLoading(false);
        if (res.status && res.statusCode === 2) {
          toast.success(res.message);
          resetData();
          getDataFunc();
        }

        if (res.status === 500 || res.status === 400 || res.status === false) {
          ErrorService.displayErrorAlert(res);
        }
      } catch (error) {
        setFormLoading(false);
        toast.error("An Error Occur!");
      }
    },
    [admToken, getDataFunc, resetData]
  );

  // getALl subject
  useEffect(() => {
    getDataFunc();
  }, [getDataFunc]);

  useEffect(() => {
    paginateData(
      allData,
      setList,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allData, currentPage]);

  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }}>
        {" "}
        {topicObj.id > 0 ? "Update" : "Add"} Topic
      </BigHeader>
      <InputWrapper>
        <label htmlFor="">Topic Name</label>
        <input
          type="text"
          value={topicObj.name}
          placeholder="Topic's Name"
          onChange={(e) => setTopicObj({ ...topicObj, name: e.target.value })}
        />
      </InputWrapper>
      <FormControl fullWidth style={{ margin: "0.3rem 0rem" }}>
        <label htmlFor="">Select Account Type</label>

        <NewSelect
          sx={{ fontFamily: "Gilroy-Regular", marginTop: "0.4rem" }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          variant="outlined"
          size="small"
          value={topicObj.accountType}
          onChange={(e) => {
            setTopicObj({ ...topicObj, accountType: e.target.value });
            // setSchool(e.target.value);
          }}
        >
          {accTypes.length > 0 &&
            accTypes.map((item, index) => (
              <MenuItem
                key={index}
                sx={{ fontFamily: "Gilroy-Regular" }}
                value={item.id}
              >
                {item.name}
              </MenuItem>
            ))}
        </NewSelect>
      </FormControl>
      {/* <InputWrapper>
        <label htmlFor="">Account Type</label>
        <Select
          title={"Account Type"}
          index="name"
          options={accTypes}
          selected={topicObj.accountType}
          setSelected={(val) =>
            setTopicObj({ ...topicObj, accountType: val.id })
          }
        />
      </InputWrapper> */}
      <div>
        <AuthButton
          onClick={performAction}
          width="100%"
          mt="0.6rem"
          disabled={formLoading || topicObj.name === ""}
        >
          {formLoading ? (
            <FormLoader />
          ) : topicObj.id > 0 ? (
            "Update Topic"
          ) : (
            "Create Topic"
          )}
        </AuthButton>
      </div>
    </>
  );
  if (loadSub) {
    return <Loader />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <div style={{ display: "flex", justifyContent: "end" }}>
          {checkAccess(accessObj.rights, "Create") && (
            <AuthButton
              onClick={() => {
                setTopicObj(Object.assign({}, _obj));
                setShowModal(!showModal);
              }}
            >
              Add Topic
            </AuthButton>
          )}
        </div>

        {showModal && (
          <Modal
            content={content}
            show={showModal}
            size={"large"}
            onClose={setShowModal}
          />
        )}

        <TabContainer style={{ borderRadius: "10px", paddingBottom: "0px" }}>
          <AccountSummaryWrapper pt="0px">
            <table>
              <tr
                className="table-head"
                style={{ backgroundColor: colors.primary, color: "white" }}
              >
                <th></th>
                <th>Topic Name ({allData.length > 0 && allData.length}) </th>
                <th>Category</th>

                <th>
                  <Input style={{ width: "100%" }}>
                    <Icon>
                      <BsSearch color={colors.primary} />
                    </Icon>
                    <Search
                      style={{ background: "white" }}
                      value={sQuery}
                      type="type"
                      placeholder="Search For Topic"
                      onChange={(e) => {
                        console.log(e.target.value);
                        setSQuery(e.target.value);
                        if (e.target.value.length > 3) {
                          console.log("inside");
                          filterSearch(e);
                        }

                        if (e.target.value === "") {
                          resetData();
                        }
                      }}
                    />
                  </Input>
                </th>
              </tr>
              {list.length > 0 ? (
                list.map((item, index) => (
                  <tr key={index}>
                    <td> {index + 1}.</td>
                    <td>{item.name === null ? "No Name" : item.name}</td>
                    <td>
                      {item.accountType == 3
                        ? "Admin"
                        : item.accountType == 2
                        ? "Parent"
                        : item.accountType == 1
                        ? "Student"
                        : "General"}
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        {checkAccess(accessObj.rights, "Edit") && (
                          <button
                            onClick={() => {
                              setTopicObj({
                                ...topicObj,
                                name: item.name,
                                accountType: item.accountType,
                                id: item.id,
                              });

                              setShowModal(true);
                            }}
                            style={{
                              backgroundColor: colors.primary,
                              color: "white",
                              padding: "8px 12px",
                              border: `1px solid white`,
                              borderRadius: "12px",
                              marginRight: "0.2rem",
                            }}
                          >
                            Edit
                          </button>
                        )}
                        {checkAccess(accessObj.rights, "Delete") && (
                          <button
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              padding: "8px 12px",
                              border: `1px solid white`,
                              borderRadius: "12px",
                              marginRight: "0.2rem",
                            }}
                            onClick={() => {
                              setId(item.id);
                              delAction(item.id);
                            }}
                          >
                            {formLoading && id === item.id ? (
                              <FormLoader />
                            ) : (
                              " Delete"
                            )}
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} style={{ textAlign: "center" }}>
                    There Are No Subjects Available
                  </td>
                </tr>
              )}
            </table>
          </AccountSummaryWrapper>
        </TabContainer>

        {pageCount > 1 && (
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Pagination
              pageCount={pageCount > 1 && pageCount}
              range={3}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default Topics;
