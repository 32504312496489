import {
    Box,
    Button,
    Grid,
    InputLabel,
  
    TextField,
    FormControl,
    Select,
    MenuItem,
  
  } from "@mui/material";
  import React from "react";
  
  const secondFilterData = [
    {
      id: 1,
      title: "Admin",
    },
    {
      id: 2,
      title: "Finance",
    },
    {
      id: 3,
      title: "Human Resources",
    },
    {
      id: 4,
      title: "Academics",
    },
    {
      id: 5,
      title: "Library",
    },
    {
      id: 6,
      title: "Assets",
    },
    {
      id: 7,
      title: "Fleet",
    },
    {
      id: 8,
      title: "Hostel",
    },
  ];
  
  const Data = [
    {
      id: 1,
      file: "Introduction to Chemistry",
      date: "12/02/2022",
    },
    {
      id: 2,
      file: "Introduction to Physics",
      date: "20/10/2022",
    },
    {
      id: 3,
      file: "Introduction to Mathematics",
      date: "1/05/2022",
    },
  ];
  const Edit = () => {
    const [secondFilter, setSecondFilter] = React.useState("Admin");
    return (
      <>
        <Box>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <Box>
                <InputLabel
                  sx={{
                    mb: 1.5,
                    mt: 1,
                    fontSize: "12px",
                    fontWeight: "bolder",
                    color: "#000",
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                Choose Subject
                </InputLabel>
                <FormControl fullWidth>
                  <Select
                    sx={{ fontFamily: "Gilroy-Regular" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant="outlined"
                    size="small"
                    value={secondFilter}
                    onChange={(e) => setSecondFilter(e.target.value)}
                  >
                    {secondFilterData.map((item, index) => (
                      <MenuItem
                        sx={{ fontFamily: "Gilroy-Regular" }}
                        value={item.title}
                        key={index}
                      >
                        {item.title}
                      </MenuItem>
                    ))}
  
                    {/* ...................................................... */}
                    {/* ...................................................... */}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Box>
                <InputLabel
                  sx={{
                    mb: 1.5,
                    mt: 1,
                    fontSize: "12px",
                    fontWeight: "bolder",
                    color: "#000",
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Quiz Type
                </InputLabel>
                <FormControl fullWidth>
                  <Select
                    sx={{ fontFamily: "Gilroy-Regular",   }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant="outlined"
                    size="small"
  
                    value={secondFilter}
                    onChange={(e) => setSecondFilter(e.target.value)}
                  >
                    {secondFilterData.map((item, index) => (
                      <MenuItem
                        sx={{ fontFamily: "Gilroy-Regular" }}
                        value={item.title}
                        key={index}
                      >
                        {item.title}
                      </MenuItem>
                    ))}
  
                    {/* ...................................................... */}
                    {/* ...................................................... */}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item lg={6}>
            <Box>
                <InputLabel
                  sx={{
                    mb: 1.5,
                    mt: 1,
                    fontSize: "12px",
                    fontWeight: "bolder",
                    color: "#000",
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Start Date and Time
                </InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  type="date"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid #671E78",
                      },
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item lg={6}>
            <Box>
                <InputLabel
                  sx={{
                    mb: 1.5,
                    mt: 1,
                    fontSize: "12px",
                    fontWeight: "bolder",
                    color: "#000",
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Closing Date and Time
                </InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  type="date"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid #671E78",
                      },
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item lg={6}>
            <Box>
                <InputLabel
                  sx={{
                    mb: 1.5,
                    mt: 1,
                    fontSize: "12px",
                    fontWeight: "bolder",
                    color: "#000",
                    fontFamily: "Gilroy-Medium",
                  }}
                >
               Quiz / Practice Test Title
                </InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid #671E78",
                      },
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item lg={6}>
            <Box>
                <InputLabel
                  sx={{
                    mb: 1.5,
                    mt: 1,
                    fontSize: "12px",
                    fontWeight: "bolder",
                    color: "#000",
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                 Duration / (Duration should be in Minutes e.g 30)
                </InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid #671E78",
                      },
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
  
          <Box mt={5}>
            <Button
              variant="contained"
              fullWidth
              sx={{ textTransform: "initial" }}
            >
              Add Quiz
            </Button>
          </Box>
        </Box>
      </>
    );
  };
  
  export default Edit;
  