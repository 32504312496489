import React from "react";
import {
  DateIconWrapper,
  DateInputWrapper,
  DatePickerButton,
  DatePickerSelect,
} from "../../pages/Dashboard/styles/dashboard.style";
import Calendar from "../../assets/icons/Calendar";
import ChevronLeft from "../../assets/icons/chevron-left";
import ChevronRight from "../../assets/icons/chevron-right";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import DatePicker from "react-datepicker";
import { AiFillCalendar } from "react-icons/ai";
import PropTypes from "prop-types";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
registerLocale("es", es);
const DatePickerComponent = ({
  selected,
  setSelected,
  color,
  bg,
  useBorder,
  disabled,
  placeholder,
  showTimeSelect,
}) => {
  const range = (start, end) => {
    return new Array(end - start).fill().map((d, i) => i + start);
  };
  const years = range(2002, getYear(new Date()) + 10);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <>
      <DateInputWrapper color={color} bg={bg}>
        <DatePicker
          showTimeSelect={showTimeSelect}
          dateFormat="dd/MM/yyyy"
          placeholderText={placeholder}
          disabled={disabled}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <DatePickerButton
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <ChevronLeft />
              </DatePickerButton>

              <DatePickerSelect
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)}
                style={{
                  height: "30px",
                  marginTop: 0,
                  marginRight: "2px",
                  padding: "0px",
                }}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </DatePickerSelect>

              <DatePickerSelect
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
                style={{
                  height: "30px",
                  marginTop: 0,
                  marginRight: "2px",
                  padding: "0px",
                }}
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </DatePickerSelect>

              <DatePickerButton
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                {" "}
                <ChevronRight />
              </DatePickerButton>
            </div>
          )}
          onChangeRaw={(e) => e.preventDefault()}
          selected={selected}
          onChange={(date) => {
            console.log(date);
            setSelected(date);
          }}
        />
        <DateIconWrapper useBorder={useBorder}>
          {/* <Calendar /> */}
          <AiFillCalendar />
        </DateIconWrapper>
      </DateInputWrapper>
    </>
  );
};

DatePickerComponent.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  showTimeSelect: PropTypes.bool,
};

DatePickerComponent.defaultProps = {
  disabled: false,
  placeholder: "Enter Date",
  showTimeSelect: false,
};

export default DatePickerComponent;
