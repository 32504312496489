import { useState, useEffect, useCallback } from "react";
import { SiAdobeacrobatreader } from "react-icons/si";
import { BsSearch, BsFillPrinterFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { IconButton, MenuItem, MenuList } from "@mui/material";
import Pagination from "../pagination_one/pagination";
import colors from "../../assets/colors.json";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "../../assets/Images/avatar.jpg";
import { AuthButton } from "../../pages/auth/Login.style";
import { Box } from "@mui/system";
import Popover from "@mui/material/Popover";
import { AiFillFileExcel } from "react-icons/ai";
import { ImgTextDiv, StatusBar } from "../../pages/student_mgt/stu_mgt.style";
import { storePrev } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import {
  Icon,
  Input,
  TabContainer,
  TabLinkContainer,
  Search,
  AccountSummaryWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
import {
  approveStudent,
  createStudentList,
  declineStudent,
} from "../../services/ParentStudent";
import Loader from "../pageLoader/loader";
import FormLoader from "../FormLoader";
import ErrorService from "../../services/errorService";
import { toast } from "react-toastify";
import { checkAccess, paginateData } from "../../services/utils";
const CreateStudentList = ({ setID, showFormFunc }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [arr, setArr] = useState([]);
  const [marked, setMarked] = useState([]);
  const [list, setList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffSet, setitemOffSet] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [paramsId, setParamsId] = useState("");
  const [singleObj, setSingleObj] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const getStudents = useCallback(async () => {
    console.log("getting students");
    // setLoading(true);
    try {
      const res = await createStudentList(admToken, user.schoolId, "");
      if (res.status && res.statusCode === 2) {
        setArr(res.data);
      }
      console.log(res);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [admToken, user.schoolId]);

  useEffect(() => {
    paginateData(
      arr,
      setList,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [arr, currentPage, itemOffSet]);

  useEffect(() => {
    getStudents();
  }, [getStudents]);

  // const markAll = () => {
  //   if (arr.length === marked.length) {
  //     setMarked([]);
  //   } else {
  //     const newList = arr.map((item) => {
  //       return { studentId: item.studentId, schoolId: user.schoolId };
  //     });

  //     console.log(newList);
  //     setMarked(newList);
  //   }
  // };
  const toggleMarked = (id) => {
    // console.log(markedItem);
    let item = marked.find((i) => i.studentId === id);

    if (item) {
      setMarked(marked.filter((i) => i.studentId !== id));
    } else {
      let obj = {
        schoolId: user.schoolId,
        studentId: id,
      };
      // console.log(obj);
      setMarked([...marked, obj]);
    }
    // console.log(item);
  };
  const [declineLoading, setDeclineLoading] = useState(false);
  const declineFunc = async () => {
    console.log(marked);
    let data = {
      selectedStudents: marked,
    };
    console.log(data);
    try {
      setDeclineLoading(true);
      const res = await declineStudent(admToken, data);
      console.log(res);
      res.status &&
        res.statusCode === 2 &&
        toast.success(res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        }) &&
        setMarked([]);
      res.status && res.statusCode === 2 && getStudents();
      (res.status === 500 || res.status === 400) &&
        ErrorService.displayErrorAlert(res.data);
      setDeclineLoading(false);
    } catch (error) {
      setDeclineLoading(false);
      toast.error("An Error Occurred, Please Try Again Later");
    }
  };
  const approveStudentFunc = async () => {
    console.log(marked);
    let data = {
      selectedStudents: marked,
    };
    console.log(data);
    try {
      setFormLoading(true);
      const res = await approveStudent(admToken, data);
      console.log(res);
      res.status &&
        res.statusCode === 2 &&
        toast.success(res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        }) &&
        setMarked([]);
      res.status && res.statusCode === 2 && getStudents();
      (res.status === 500 || res.status === 400) &&
        ErrorService.displayErrorAlert(res.data);
      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);
      toast.error("An Error Occurred, Please Try Again Later");
    }
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <>
        <TabContainer style={{ marginTop: "1rem", paddingBottom: "0px" }}>
          <TabLinkContainer bg="#F0E8F1" style={{ position: "relative" }}>
            <Input>
              <Icon>
                <BsSearch />
              </Icon>
              <Search type="text" placeholder="Search " />
            </Input>
            <div style={{ display: "inline-flex" }} className="div">
              <AiFillFileExcel
                style={{ fontSize: "25px", marginLeft: "0.3rem" }}
              />
              <SiAdobeacrobatreader
                style={{ fontSize: "25px", marginLeft: "0.3rem" }}
              />
              <BsFillPrinterFill
                style={{ fontSize: "25px", marginLeft: "0.3rem" }}
              />
            </div>
          </TabLinkContainer>
          <AccountSummaryWrapper pt="0px">
            <table
              style={{
                borderRadius: "0px",
                borderBottomLeftRadius: "20px",
                borderBottomRightRadius: "20px",
              }}
            >
              <tr
                className="table-head"
                style={{
                  backgroundColor: "#F0E8F1",
                  color: "black",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                <th></th>

                <th style={{ fontWeight: "bold" }}>
                  Student Name ({arr.length}){" "}
                </th>
                <th>Phone</th>
                <th>Email Address</th>
                <th>Status</th>
                <th>Created By</th>
                <th></th>
              </tr>

              {list.length > 0 ? (
                list.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.accountStatus === "3" && (
                        <input
                          type="checkbox"
                          checked={marked.some(
                            (i) => i.studentId === item.studentId
                          )}
                          onChange={() => toggleMarked(item.studentId)}
                        />
                      )}
                    </td>

                    <td>
                      <ImgTextDiv>
                        <img
                          src={
                            item.profileImagePath
                              ? item.profileImagePath
                              : Avatar
                          }
                          alt=""
                        />
                        <span>{`${item.firstName} ${" "} ${
                          item.lastName
                        }`}</span>
                      </ImgTextDiv>
                    </td>
                    <td>{item.phoneNumber}</td>
                    <td>{item.email}</td>
                    <td>
                      <StatusBar status={item.accountStatus}>
                        {item.accountStatus === "8"
                          ? "Admitted"
                          : item.accountStatus === "7"
                          ? "Shortlisted"
                          : item.accountStatus === "6"
                          ? "Declined"
                          : item.accountStatus === "5"
                          ? "In Process"
                          : item.accountStatus === "4"
                          ? "Cancelled"
                          : item.accountStatus === "3"
                          ? "Submitted"
                          : item.accountStatus === "2"
                          ? "Saved"
                          : "Not Confirmed"}
                      </StatusBar>
                    </td>
                    <td>{item.createdBy}</td>
                    <td>
                      {item.phoneNumber !== null && (
                        <IconButton
                          onClick={(e) => {
                            setSingleObj(item);
                            setParamsId(item.phoneNumber);
                            // setSchoolId(item.schoolId);
                            handleClick(e);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} style={{ textAlign: "center" }}>
                    No Students List
                  </td>
                </tr>
              )}
            </table>
          </AccountSummaryWrapper>
        </TabContainer>
        {list.length > 0 && (
          <div
            style={{
              display: "flex",
              marginTop: "0.1rem",
              justifyContent: "end",
              padding: "5px 20px",
            }}
          >
            {checkAccess(accessObj.rights, "Approve") && (
              <AuthButton
                disabled={marked.length === 0 || formLoading}
                onClick={approveStudentFunc}
                width="150px"
              >
                {formLoading ? <FormLoader /> : "Approve"}
              </AuthButton>
            )}

            {checkAccess(accessObj.rights, "Decline") && (
              <AuthButton
                style={{ marginLeft: "0.5rem" }}
                color={colors.primary}
                bg="white"
                width="150px"
                onClick={declineFunc}
                disabled={marked.length === 0 || declineLoading}
              >
                {declineLoading ? "Please wait..." : "Decline"}
              </AuthButton>
            )}
          </div>
        )}
        {pageCount > 1 && (
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Pagination
              pageCount={pageCount > 1 && pageCount}
              range={3}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box
            sx={{
              bgcolor: "#671E78",
              color: "#fff",
              fontFamily: "Gilroy-Regular",
              px: 3,
            }}
          >
            <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
              {/* <MenuItem>Activate</MenuItem> */}
              <MenuItem
                onClick={() => {
                  if (
                    singleObj.accountStatus === "3" ||
                    singleObj.accountStatus === "3.0"
                  ) {
                    dispatch(storePrev("/studentmgt?tab=1"));
                    navigate(
                      `/student?school_id=${singleObj.schoolId}&student_id=${singleObj.phoneNumber}`
                    );
                  } else {
                    console.log(paramsId);
                    setID(paramsId);
                    showFormFunc(true);
                  }
                }}
              >
                View Details
              </MenuItem>
              {/* <MenuItem>Delete</MenuItem> */}
            </MenuList>
          </Box>
        </Popover>
      </>
    </div>
  );
};

export default CreateStudentList;
