import { createGlobalStyle } from "styled-components";
import colors from "../assets/colors.json";

const GlobalStyles = createGlobalStyle`
*{
   font-family: "Gilroy-Regular";
}

button:disabled {
  background-color:${colors.lightGray} !important;
  border-color: ${colors.lightGray} !important;
}

input, textarea {
  border-radius: 4px;
  border: 1px solid ${colors.primary};

  box-shadow: none;
  box-sizing: border-box;
  color: #444;
  margin-top: 5px;
  display: block;
  font-size: 0.875rem;
  line-height: 2.125rem;
  padding: 0.4rem 0.9375rem;
  width: 100%;
  transition: background-color 0.15s, box-shadow 0.15s, border-color 0.15s,
    color 0.15s;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${colors.lightGray} !important;
  }
}

textarea {
  overflow-y: hidden;
}

a {
  text-decoration: none !important;
  text-align: center;s
}

textarea{
  resize:none;
}

input[type=checkbox]:checked {
  background-color: ${colors.primary} ;
  accent-color: ${colors.primary};
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



.phone-input{
  width: 100% !important;
  height: 50px !important;
  border: 1px solid ${colors.primary} !important;
}
.phone-btn{
  background: transparent !important;
  border: 1px solid ${colors.primary} !important;
  
}

.code-input{
  display: flex !important;
  margin-left:-4px;
  margin-top: 5px;
  & > input{
  font-family: monospace;
  -moz-appearance: textfield;
  border-radius: 4px;
  margin: 4px;
  padding-left: 8px;
  height: 34px;
  font-size: 24px;
  box-sizing: border-box;
  color: black;
  text-align:center;
  background-color: white;
  }

  
}

button.chooseFileButton {
  margin: 0 !important;
  width: fit-content !important;
  color: black !important;
}

.fileContainer > p{
  display: none;
}


.table-wrapper{
    overflow:hidden;
    overflow-y: scroll;
    height:500px;
}
table {
  width: 100%;
  box-shadow: 0px 6px 12px #1E1C1B12;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
}
.fixed-table{
  table-layout: auto;
  @media (max-width: 992px){
    table-layout: fixed;
  }
}
td, th {
  text-align: left;
  padding:13px 0px 13px 13px;
  font-size: 15px;
  font-weight: bold;
  @media (max-width:992px){
  font-size: 12px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  }
}

td{
  font-weight: 600;
}
th {
    top: 0;
    z-index: 2;
    position: sticky;
 }
.table-head{
  background: ${colors.primary};
  color: white;
 
  @media(max-width: 992px){
    & > th{
      padding: 15px 0px 15px 15px;
    }
  }
}
.table-bottom{
  color: ${colors.primary};
  background: white;
}
.coop-file{
  width: 10px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
tr{
  cursor: pointer;
  position: relative !important;
  z-index: 0;
}
tr:nth-child(odd) {
  background-color: ${colors.secondary};
  background-opacity: 0.2 ;
}

.hidden-td{
  visibility: hidden;
}

.center{
  padding: 50px;
  text-align: center;
}

.center img {
  margin: 0 auto;
}
`;

export default GlobalStyles;
