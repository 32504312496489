import React from "react";
// import ReactPaginate from "react-paginate";
import ReactPaginate from "react-paginate";
import RightArrow from "./rightArrow";
import LeftArrow from "./leftArrow";
import { PaginationWrapper } from "./pagination.style";
import PropTypes from "prop-types";

function Pagination({
  call,
  pageCount,
  range,
  setCurrentPage,
  currentPage,
  getDates,
  type,
  session,
  term,
  classObj,
  currentMonth,
}) {
  // console.log("pgCp", currentPage, "pgcm", currentMonth);
  return (
    <PaginationWrapper>
      <div>
        <ReactPaginate
          initialPage={currentPage}
          breakLabel="..."
          className="pagination"
          nextLabel={<RightArrow />}
          nextLinkClassName="next-page"
          previousLinkClassName="previous-page"
          //   onClick={(e) => {
          //     console.log("Inside cp", currentPage);
          //     console.log(e);
          //     // console.log(pageCount);
          //     console.log("nsp", e.nextSelectedPage);

          //     if (
          //       (!e.isActive && e?.nextSelectedPage) ||
          //       (!e.isActive && e?.nextSelectedPage === 0)
          //     ) {
          //       // console.log("inside");
          //       setCurrentPage(e.nextSelectedPage + 1);
          //       if (type === "teacherAtt") {
          //  getDates(session.id, term.id, classObj.id, currentPage);
          //       }
          //     }
          //   }}
          onPageChange={(e) => {
            const selectedPage = e.selected + 1; // Adjusting for 0-based index
            setCurrentPage(selectedPage);
            if (type === "teacherAtt") {
              // getDates(session.id, term.id, classObj.id, selectedPage);
            }
          }}
          pageClassName="page-link"
          activeClassName="active-page-link"
          pageRangeDisplayed={range}
          pageCount={pageCount}
          previousLabel={<LeftArrow />}
          renderOnZeroPageCount={null}
          marginPagesDisplayed={2}
        />
      </div>
    </PaginationWrapper>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
};

Pagination.defaultProps = {
  currentPage: 0,
};
export default Pagination;
// onPageChange={(e) => {
//   if (call) {
//     call(e.selected - 1);
//   }
//   console.log(e.selected + 1);
//   setCurrentPage(e.selected + 1);
// }}
