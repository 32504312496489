/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import {
  FormFlex,
  FormFlexDiv,
  ImageUploadContainer,
  ImageWrapper,
  SmallHeader,
  TabContentWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
import { useSelector } from "react-redux";
import colors from "../../assets/colors.json";
import { InputWrapper } from "../../pages/auth/Login.style";
import DatePickerComponent from "../datepicker/DatePicker";
import Upload from "../../assets/icons/upload";
import ImageUploader from "react-images-upload";
import Select from "../select/select";
import { AuthButton } from "../../pages/auth/Login.style";
import { useMediaQuery } from "react-responsive";
import avatar from "../../assets/Images/avatar.jpg";
import PropTypes from "prop-types";
import { isNumber } from "../../services/utils";
import FormLoader from "../../components/FormLoader";
import moment from "moment";
import { getReadOnly } from "../../services/ParentStudent";
const containerStyle = {
  background: "transparent",
  boxShadow: "none",
  padding: "0px",
  justifyContent: "flex-start",
  margin: "0",
};
const style = {
  background: "white",
  color: colors.primary,
};
const StudentBioData = ({
  bioData,
  readOnly,
  setBioData,
  action,
  loading,
  showInfoButton,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const optReligion = [
    {
      id: 1,
      name: "Christianity",
    },
    { id: 2, name: "Islam" },
    { id: 3, name: "islam" },
    { id: 4, name: "Others" },
  ];

  const onDrop = (picture, url) => {
    let filename = picture[0].name;
    if (
      filename.includes(".jpg") ||
      filename.includes(".png") ||
      filename.includes(".jpeg")
    ) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        setBioData({ ...bioData, profileImagePath: fileReader.result });
      });
      fileReader.readAsDataURL(picture[0]);
    } else {
      alert("Please Upload A Photo");
    }
  };
  const calculateAge = useCallback(
    (date = bioData.dateOfBirth) => {
      console.log("here");
      if (isNaN(new Date(date).getTime())) {
        setBioData({
          ...bioData,
          studentAge: 0,
          dateOfBirth: "",
        });
      } else {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        let age = currentYear - new Date(Date.parse(date)).getFullYear();
        const month = currentMonth - new Date(Date.parse(date)).getMonth();
        if (
          month < 0 ||
          (month === 0 &&
            new Date().getDate() < new Date(Date.parse(date)).getDate())
        ) {
          age--;
        }
        console.log(age);
        setBioData({
          ...bioData,
          studentAge: age,
          dateOfBirth: moment(date).format("YYYY-MM-DD"),
        });
        // setAge(age);
      }
    },
    [bioData, setBioData]
  );
  useEffect(() => {
    if (bioData !== null) {
      calculateAge();
    }
  }, []);

  let dateRegex =
    /^(?:(?:19|20)\d\d)-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12][0-9]|3[01])$/;

  return (
    <TabContentWrapper>
      {bioData === null ? (
        <SmallHeader style={{ textAlign: "center" }}>
          No Student Info At The Moment
        </SmallHeader>
      ) : (
        <>
          <FormFlex reverse={true}>
            {" "}
            <FormFlexDiv>
              {readOnly && (
                <InputWrapper>
                  <label htmlFor="Admission Number">Admission Number</label>
                  <input
                    type="text"
                    placeholder="Admission Number"
                    value={bioData.admission_no}
                    onChange={(e) =>
                      setBioData({ ...bioData, admission_no: e.target.value })
                    }
                    disabled={readOnly}
                  />
                </InputWrapper>
              )}

              <InputWrapper>
                <label htmlFor="First Name">
                  First Name{" "}
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      marginLeft: "0.2rem",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="First Name"
                  value={bioData.firstName}
                  disabled={readOnly}
                  onChange={(e) =>
                    setBioData({ ...bioData, firstName: e.target.value })
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Last Name">
                  Last Name{" "}
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      marginLeft: "0.2rem",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Last Name"
                  disabled={readOnly}
                  value={bioData.lastName}
                  onChange={(e) =>
                    setBioData({ ...bioData, lastName: e.target.value })
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Other Name">Other Name</label>
                <input
                  type="text"
                  placeholder="Other Name"
                  value={bioData.otherName}
                  disabled={readOnly}
                  onChange={(e) =>
                    setBioData({ ...bioData, otherName: e.target.value })
                  }
                />
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              {" "}
              <ImageUploadContainer>
                <ImageWrapper>
                  <img
                    src={
                      bioData.profileImagePath === null ||
                      bioData.profileImagePath === ""
                        ? avatar
                        : bioData.profileImagePath
                    }
                    alt="avatar"
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      background: "white",
                      height: "fit-content",
                      justifyContent: "center",
                    }}
                  >
                    <Upload />
                    <ImageUploader
                      disabled={readOnly}
                      withIcon={false}
                      onChange={onDrop}
                      buttonText="Upload"
                      label=""
                      input
                      imgExtension={[".jpg", ".png", ".jpeg"]}
                      maxFileSize={5242880}
                      singleImage={true}
                      buttonStyles={style}
                      fileContainerStyle={{
                        ...containerStyle,
                      }}
                    />
                  </div>
                </ImageWrapper>
              </ImageUploadContainer>{" "}
            </FormFlexDiv>
          </FormFlex>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="Nationality">
                  Email
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      marginLeft: "0.2rem",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Email"
                  disabled={readOnly}
                  value={bioData.email}
                  onChange={(e) =>
                    setBioData({ ...bioData, email: e.target.value })
                  }
                />
              </InputWrapper>
              {/* Date Picker */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "70%",
                  }}
                >
                  <InputWrapper>
                    <label htmlFor="">
                      Date of Birth{" "}
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          marginLeft: "0.2rem",
                        }}
                      >
                        *
                      </span>
                    </label>

                    <DatePickerComponent
                      disabled={readOnly}
                      selected={
                        bioData.dateOfBirth === "" ||
                        bioData.dateOfBirth === null ||
                        !dateRegex.test(bioData.dateOfBirth)
                          ? ""
                          : new Date(Date.parse(bioData.dateOfBirth))
                      }
                      setSelected={(val) => {
                        calculateAge(val);
                      }}
                    />
                  </InputWrapper>
                </div>
                <div
                  style={{
                    width: "20%",
                  }}
                >
                  <InputWrapper>
                    <label
                      htmlFor="Age"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Age{" "}
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          marginLeft: "0.2rem",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      value={bioData.studentAge}
                      style={{
                        color: "white",
                        background: "#671e78",
                      }}
                      disabled={readOnly}
                      onChange={(e) => console.log(e.target.value)}
                    />
                  </InputWrapper>
                </div>
              </div>
              <InputWrapper>
                <label htmlFor="Gender">
                  Gender{" "}
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      marginLeft: "0.2rem",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Gender"
                  disabled={readOnly}
                  value={bioData.gender}
                  onChange={(e) =>
                    setBioData({ ...bioData, gender: e.target.value })
                  }
                />
              </InputWrapper>

              <InputWrapper>
                <label htmlFor="State of Origin">
                  State Of Origin{" "}
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      marginLeft: "0.2rem",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="State of Origin"
                  value={bioData.origin}
                  disabled={readOnly}
                  onChange={(e) =>
                    setBioData({ ...bioData, origin: e.target.value })
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Residential Address">
                  Residential Address{" "}
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      marginLeft: "0.2rem",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Residential Address"
                  value={bioData.residentialeAddress}
                  disabled={readOnly}
                  onChange={(e) =>
                    setBioData({
                      ...bioData,
                      residentialeAddress: e.target.value,
                    })
                  }
                />
              </InputWrapper>

              <InputWrapper>
                <label htmlFor="Eye Color">Eye Color</label>
                <input
                  type="text"
                  placeholder="Brown"
                  disabled={readOnly}
                  value={bioData.eyeColor}
                  onChange={(e) =>
                    setBioData({ ...bioData, eyeColor: e.target.value })
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Home Town">Home Town</label>
                <input
                  type="text"
                  placeholder="Ojuelegba"
                  disabled={readOnly}
                  value={bioData.hometown}
                  onChange={(e) =>
                    setBioData({ ...bioData, hometown: e.target.value })
                  }
                />
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="Nationality">
                  Phone Number{" "}
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      marginLeft: "0.2rem",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  onKeyDown={(e) => isNumber(e)}
                  placeholder="Phone Number"
                  disabled={readOnly}
                  value={bioData.phoneNumber}
                  onChange={(e) =>
                    setBioData({
                      ...bioData,
                      phoneNumber: e.target.value,
                    })
                  }
                />
              </InputWrapper>

              <InputWrapper>
                <label htmlFor="Nationality">
                  Nationality{" "}
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      marginLeft: "0.2rem",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Your Country"
                  disabled={readOnly}
                  value={bioData.nationality}
                  onChange={(e) =>
                    setBioData({ ...bioData, nationality: e.target.value })
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Local Goevrnment">
                  Local Government{" "}
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      marginLeft: "0.2rem",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Lagos Island Local Government"
                  disabled={readOnly}
                  value={bioData.localGovernment}
                  onChange={(e) =>
                    setBioData({ ...bioData, localGovernment: e.target.value })
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Home Town">
                  Nearest Bus-stop to your house
                </label>
                <input
                  type="text"
                  disabled={readOnly}
                  placeholder="Enter Busstop"
                  value={bioData.nearestBustop}
                  onChange={(e) =>
                    setBioData({ ...bioData, nearestBustop: e.target.value })
                  }
                />
              </InputWrapper>

              <InputWrapper>
                <label htmlFor="Religion">
                  Religion{" "}
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      marginLeft: "0.2rem",
                    }}
                  >
                    *
                  </span>
                </label>
                <Select
                  title="Religion"
                  disabled={readOnly}
                  index="name"
                  selected={bioData.religion}
                  options={optReligion}
                  setSelected={(val) => {
                    console.log(val);
                    // setOpt(val.name);
                    setBioData({
                      ...bioData,
                      religion: val.name,
                    });
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Denomination">Denomination</label>
                <input
                  type="text"
                  placeholder="Winners"
                  value={bioData.denomination}
                  disabled={readOnly}
                  onChange={(e) =>
                    setBioData({ ...bioData, denomination: e.target.value })
                  }
                />
              </InputWrapper>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "45%" }}>
                  <InputWrapper>
                    <label htmlFor="weight">Weight</label>
                    <input
                      style={{
                        marginTop: "5px",
                      }}
                      type="text"
                      disabled={readOnly}
                      placeholder="20kg"
                      value={`${bioData.weight}`}
                      onChange={(e) =>
                        setBioData({ ...bioData, weight: e.target.value })
                      }
                    />
                  </InputWrapper>
                </div>
                <div style={{ width: "45%" }}>
                  <InputWrapper>
                    <label htmlFor="height">Height</label>
                    <input
                      type="text"
                      disabled={readOnly}
                      placeholder="100m"
                      value={`${bioData.height}`}
                      onChange={(e) =>
                        setBioData({ ...bioData, height: e.target.value })
                      }
                    />
                  </InputWrapper>
                </div>
              </div>
            </FormFlexDiv>
          </FormFlex>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="Postal Address">Address of Denomination</label>
                <input
                  type="text"
                  disabled={readOnly}
                  placeholder="20 Edmund Street"
                  value={bioData.denominationAddress}
                  onChange={(e) =>
                    setBioData({
                      ...bioData,
                      denominationAddress: e.target.value,
                    })
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Home Town">
                  Role of child/ward in place of worship
                </label>
                <input
                  type="text"
                  disabled={readOnly}
                  placeholder="Boy's Scout"
                  value={bioData.childOrWardWorshipRole}
                  onChange={(e) =>
                    setBioData({
                      ...bioData,
                      childOrWardWorshipRole: e.target.value,
                    })
                  }
                />
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="phone number">Name of the Vicar</label>
                <input
                  type="text"
                  placeholder="Abraham Doe"
                  value={bioData.vicorOrPastorName}
                  disabled={readOnly}
                  onChange={(e) =>
                    setBioData({
                      ...bioData,
                      vicorOrPastorName: e.target.value,
                    })
                  }
                />
              </InputWrapper>

              <InputWrapper>
                <label htmlFor="phone number">
                  Phone Number of the Vicar/Pastor
                </label>
                <input
                  type="text"
                  onKeyDown={(e) => isNumber(e)}
                  placeholder="08090494939"
                  value={bioData.vicorOrPastorPhoneNo}
                  disabled={readOnly}
                  onChange={(e) =>
                    setBioData({
                      ...bioData,
                      vicorOrPastorPhoneNo: e.target.value,
                    })
                  }
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          {showInfoButton && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <AuthButton
                width={isMobile ? "100%" : "30%"}
                onClick={() => action(bioData.phoneNumber)}
                disabled={loading}
              >
                {loading ? (
                  <FormLoader />
                ) : (
                  <div>
                    <span>
                      {bioData.studentId === 0
                        ? "Create Student"
                        : "Update Details"}
                    </span>
                  </div>
                )}

                {/* Send Code */}
              </AuthButton>
            </div>
          )}
        </>
      )}
    </TabContentWrapper>
  );
};

StudentBioData.propTypes = {
  readOnly: PropTypes.bool,
  bioData: PropTypes.object,
  showInfoButton: PropTypes.bool,
};

StudentBioData.defaultProps = {
  readOnly: true,
  bioData: null,
  showInfoButton: true,
};

export default StudentBioData;
