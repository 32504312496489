import React, { useState } from "react";
import colors from "../../assets/colors.json";
import { TabLink, TabLinkContainer } from "../Dashboard/styles/dashboard.style";
import { useMediaQuery } from "react-responsive";
import ClassAttendance from "../../components/StudentMgt/ClassAttendance";
import SubjectAttendance from "../../components/StudentMgt/SubjectAttendance";
import ReportAttendance from "../../components/StudentMgt/ReportAttendance";
import { checkAccess } from "../../services/utils";
import { useSelector } from "react-redux";
const Attendance = () => {
  const [tab, setTab] = useState(0);
  const isTab = useMediaQuery({ maxWidth: 992 });
  const { accessObj } = useSelector((state) => state.auth);

  return (
    <div>
      {(checkAccess(accessObj.menuItems, "Class Attendance") ||
        checkAccess(accessObj.menuItems, "Attendance Report") ||
        checkAccess(accessObj.menuItems, "Subject Attendance")) && (
        <TabLinkContainer
          style={{ marginTop: "10px", justifyContent: "start" }}
          wd={isTab ? "90%" : "60%"}
          bg={colors.secondary}
          brtl={"5px"}
          brtr={"5px"}
          brbl={"5px"}
          brbr={"5px"}
        >
          {checkAccess(accessObj.menuItems, "Class Attendance") && (
            <TabLink isActive={tab === 1} onClick={() => setTab(1)}>
              Class Attendance
            </TabLink>
          )}
          {checkAccess(accessObj.menuItems, "Attendance Report") && (
            <TabLink isActive={tab === 3} onClick={() => setTab(3)}>
              Attendance Report
            </TabLink>
          )}

          {/* {checkAccess(accessObj.menuItems, "Subject Attendance") && (
            <TabLink isActive={tab === 2} onClick={() => setTab(2)}>
              Subject Attendance
            </TabLink>
          )} */}
        </TabLinkContainer>
      )}
      {checkAccess(accessObj.menuItems, "Class Attendance") && tab === 1 && (
        <ClassAttendance />
      )}
      {/* {checkAccess(accessObj.menuItems, "Subject Attendance") && tab === 2 && (
        <SubjectAttendance />
      )} */}
      {checkAccess(accessObj.menuItems, "Attendance Report") && tab === 3 && (
        <ReportAttendance />
      )}
    </div>
  );
};

export default Attendance;
