/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  FormFlex,
  FormFlexDiv,
  TabContentWrapper,
  ImageWrapper,
  ImageUploadContainer,
  SmallHeader,
} from "../../pages/Dashboard/styles/dashboard.style";
import { InputWrapper } from "../../pages/auth/Login.style";
import { useSelector, useDispatch } from "react-redux";
import avatar from "../../assets/Images/avatar.jpg";
import Upload from "../../assets/icons/upload";
import ImageUploader from "react-images-upload";
import colors from "../../assets/colors.json";
import { useMediaQuery } from "react-responsive";
import Select from "../select/select";
import { AuthButton } from "../../pages/auth/Login.style";
import DatePickerComponent from "../datepicker/DatePicker";
import PropTypes from "prop-types";
const containerStyle = {
  background: "transparent",
  boxShadow: "none",
  padding: "0px",
  justifyContent: "flex-start",
  margin: "0",
};
const style = {
  background: "white",
  color: colors.primary,
};

const Personal = ({ data, staffId }) => {
  const [dob, setDob] = useState(new Date(2014, 0, 3));
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [age, setAge] = useState("");
  const [details, setDetails] = useState(null);
  let disabled = true;
  const [staffNumber, setStaffNumber] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user, admToken, } = useSelector(
    (state) => state.auth
  );
  const [access, setAccess] = useState(null);


  useEffect(() => {
    if (data !== null) {
      setDetails(Object.assign({}, data.accountDetails));
      setAccess(Object.assign({}, data.accountAccess));
    }
  }, [data]);

  const fetchStaffNumber = useCallback(async (admToken, staffId) => {
    try {
      const endpoint = `https://lasmabadmindev.azurewebsites.net/api/v1/UserAccount/Staff-Profile`;
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${admToken}`,
        },
      });

      if (response.data.status === true && response.data.statusCode === 2) {
        return response.data.data;
      } else {
        throw new Error('Failed to fetch staff number');
      }
    } catch (error) {
      console.error('Error fetching staff number:', error);
      throw error;
    }
  }, []);

  useEffect(() => {
    const getStaffNumber = async () => {
      setLoading(true);
      try {
        const data = await fetchStaffNumber(admToken, user.staffId);
        if (data && data.userInformation && data.userInformation.accountDetails) {
          setStaffNumber(data.userInformation.accountDetails.staffNumber);
        } else {
          setError('Invalid response structure');
        }
      } catch (error) {
        setError('Error fetching staff number');
      } finally {
        setLoading(false);
      }
    };

    getStaffNumber();
  }, [admToken, user.staffId, fetchStaffNumber]);




  const optGender = [
    {
      id: 1,
      name: "MALE",
    },
    {
      id: 2,
      name: "FEMALE",
    },
    {
      id: 3,
      name: "Male",
    },
    {
      id: 4,
      name: "Female",
    },
    {
      id: 5,
      name: "male",
    },
    {
      id: 6,
      name: "female",
    },
  ];
  const onDrop = (picture, url) => {
    console.log(picture);
    console.log(url);
  };

  const calculateAge = (date = dob) => {
    const today = new Date();
    console.log(date);
    let age = today.getFullYear() - date.getFullYear();
    const month = today.getMonth() - date.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < date.getDate())) {
      age--;
    }
    console.log(age);
    setAge(age);
  };

  useEffect(() => {
    if (data !== null) {
      setDetails(Object.assign({}, data));
      calculateAge(new Date(data.dob));
    }
  }, [data]);

  return (
    <TabContentWrapper>
      {details === null ? (
        <SmallHeader
          style={{
            textAlign: "center",
            color: colors.primary,
            fontWeight: "bold",
          }}
        >
          An error Ocurred Try Again Later
        </SmallHeader>
      ) : (
        <>
          <FormFlex reverse={true}>
            <FormFlexDiv>
              {" "}
              <InputWrapper>
                <label htmlFor="Surname">Surname</label>
                <input
                  type="text"
                  placeholder="Surname"
                  value={details.surName}
                  onChange={(e) => console.log(e.target.value)}
                  disabled={true}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="First Name">First Name</label>
                <input
                  type="text"
                  placeholder="First Name"
                  value={details.firstName}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Other Name">Other Name</label>
                <input
                  type="text"
                  placeholder="Other Name"
                  value={details.otherName}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              {" "}
              <ImageUploadContainer>
                <ImageWrapper>
                  <img
                    src={
                      details.profileImageurl === ""
                        ? avatar
                        : details.profileImageurl
                    }
                    alt="avatar"
                  />
                  
                  {/* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      background: "white",
                      height: "fit-content",
                      justifyContent: "center",
                    }}
                  >
                    <Upload />
                    <ImageUploader
                      disabled={true}
                      withIcon={false}
                      onChange={onDrop}
                      buttonText="Upload"
                      label=""
                      input
                      imgExtension={[".jpg", ".png"]}
                      maxFileSize={5242880}
                      singleImage={true}
                      buttonStyles={{ ...style, pointerEvents: "none" }}
                      fileContainerStyle={{
                        ...containerStyle,
                      }}
                    />
                  </div> */}
                </ImageWrapper>
              </ImageUploadContainer>{" "}
            </FormFlexDiv>
          </FormFlex>
          <FormFlex>
            <FormFlexDiv>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "70%",
                  }}
                >
                  <InputWrapper>
                    <label htmlFor="">Date of Birth</label>

                    <DatePickerComponent
                      disabled={true}
                      selected={new Date(details.dob)}
                      setSelected={(val) => {
                        console.log(val);
                        setDob(val);
                        calculateAge(val);
                      }}
                    />
                  </InputWrapper>
                </div>
                <div
                  style={{
                    width: "20%",
                  }}
                >
                  <InputWrapper>
                    <label
                      htmlFor="Age"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Age
                    </label>
                    <input
                      type="text"
                      value={details.age}
                      style={{
                        color: "black",
                        background: colors.lightGray,
                        borderColor: colors.lightGray,
                      }}
                      disabled={true}
                      onChange={(e) => console.log(e.target.value)}
                    />
                  </InputWrapper>
                </div>
              </div>

              <InputWrapper>
                <label htmlFor="Religion">Marital Status</label>
                <input
                  type="text"
                  disabled={true}
                  placeholder="Enter Marital Status"
                  value={details.marital}
                  onChange={(e) => console.log(e.target.value)}
                />
                {/* <Select
              title="Marital Status"
              index="name"
              selected={details.marital}
              options={optionsArr}
              setSelected={(val) => {
                console.log(val);
                setOpt(val.name);
              }}
            /> */}
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Phone Number">Phone Number</label>
                <input
                  type="text"
                  disabled={true}
                  placeholder="Enter Phone Number"
                  value={details.phone}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Language Spoken">Language Spoken</label>
                <input
                  type="text"
                  disabled={true}
                  placeholder="Enter Language"
                  value={details.language}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Blood Group">Blood Group</label>
                <input
                  type="text"
                  placeholder="Enter Blod Group"
                  value={details.bgroup}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
                {/* <Select
              title="Blood group"
              index="name"
              selected={"Value 1"}
              options={optionsArr}
              setSelected={(val) => {
                console.log(val);
                setOpt(val.name);
              }}
            /> */}
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Blood Genotype">Blood Genotype</label>
                <input
                  type="text"
                  disabled={true}
                  placeholder="Enter Blood Genotype"
                  value={details.genotype}
                  onChange={(e) => console.log(e.target.value)}
                />
                {/* <Select
              title="Blood Genotype"
              index="name"
              selected={"Value 1"}
              options={optionsArr}
              setSelected={(val) => {
                console.log(val);
                setOpt(val.name);
              }}
            /> */}
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>

            <InputWrapper>
                <label htmlFor="Other Name">Staff Number</label>
                <input
                  type="text"
                  placeholder="Staff Number"
                  value={staffNumber}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Gender">Gender</label>
                <Select
                  title="Gender"
                  index="name"
                  disabled={true}
                  selected={details.gender}
                  options={optGender}
                  setSelected={(val) => {
                    console.log(val);
                    // setOpt(val.name);
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Religion">Religion</label>
                <input
                  type="text"
                  disabled={true}
                  placeholder="Enter Religion"
                  value={details.religion}
                  onChange={(e) => console.log(e.target.value)}
                />
                {/* <Select
              title="Religion"
              index="name"
              selected={"Value 1"}
              options={optionsArr}
              setSelected={(val) => {
                console.log(val);
                setOpt(val.name);
              }}
            /> */}
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="origin">State Of Origin</label>
                <input
                  type="text"
                  disabled={true}
                  placeholder="Enter State Of Origin"
                  value={details.origin}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  placeholder="Enter Email"
                  value={details.email}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  placeholder="Enter Address"
                  disabled={true}
                  value={details.address}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="medical history">Medical History</label>
                <textarea
                  cols="30"
                  rows="3"
                  placeholder={details.medicalHistory}
                  value={details.medicalHistory}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                ></textarea>
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="">Alergy</label>
                <textarea
                  cols="30"
                  rows="3"
                  disabled={true}
                  value={details.alergy}
                  onChange={(e) => console.log(e.target.value)}
                ></textarea>
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          <div
            style={{
              display: "none",
              justifyContent: "end",
            }}
          >
            <AuthButton width={isMobile ? "100%" : "30%"} onSubmit={null}>
              <div>
                <span>Update Details</span>
              </div>
              {/* Send Code */}
            </AuthButton>
          </div>
        </>
      )}
    </TabContentWrapper>
  );
};

Personal.propTypes = {
  data: PropTypes.object,
};

Personal.defaultProps = {
  data: null,
};

export default Personal;
