import React, { useCallback, useEffect, useState } from "react";
import { SiAdobeacrobatreader } from "react-icons/si";
import { BsSearch, BsFillPrinterFill } from "react-icons/bs";
import { IconButton, MenuItem, MenuList } from "@mui/material";
import Pagination from "../pagination_one/pagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "../../assets/Images/avatar.jpg";
import { Box } from "@mui/system";
import Popover from "@mui/material/Popover";
import { AiFillFileExcel } from "react-icons/ai";
import { ImgTextDiv } from "../../pages/student_mgt/stu_mgt.style";
import {
  Icon,
  Input,
  TabContainer,
  TabLinkContainer,
  Search,
  AccountSummaryWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
import { useSelector } from "react-redux";
import { linkedParents } from "../../services/ParentStudent";
import PropTypes from "prop-types";
import Loader from "../pageLoader/loader";
import { paginateData } from "../../services/utils";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
const LinkedUnLinkedParent = ({ type }) => {
  const navigate = useNavigate();
  const { admToken } = useSelector((state) => state.auth);
  const [list, setList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffSet, setitemOffSet] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [param, setParam] = useState("");
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setParam(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getData = useCallback(async () => {
    try {
      const res = await linkedParents(admToken, query, type);
      console.log(res);
      res.status && res.statusCode === 2 && setAllData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [admToken, type, query]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    paginateData(
      allData,
      setList,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allData, currentPage]);

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <TabContainer
        style={{
          marginTop: "0px",
          borderRadius: "0px",
          border: "none",
          paddingBottom: "0px",
        }}
      >
        <TabLinkContainer
          bg="#F0E8F1"
          style={{ position: "relative", borderRadius: "0px" }}
        >
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search " />
          </Input>
          <div style={{ display: "none" }} className="div">
            <AiFillFileExcel
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
            <SiAdobeacrobatreader
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
            <BsFillPrinterFill
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
          </div>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table style={{ borderRadius: "0px" }}>
            <tr
              className="table-head"
              style={{ backgroundColor: "#F0E8F1", color: "black" }}
            >
              <th>S/N</th>
              <th>Parent Name </th>
              <th>Phone</th>
              <th>Email Address</th>

              <th>Date Created</th>
              <th></th>
            </tr>
            {list.length > 0 ? (
              list.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <ImgTextDiv>
                      <img
                        src={
                          item.profileImagePath ? item.profileImagePath : Avatar
                        }
                        alt=""
                      />
                      <span>{item.fullName}</span>
                    </ImgTextDiv>
                  </td>

                  <td>{item.phoneNumber}</td>
                  <td>{item.email}</td>

                  <td>{moment(item.createdDate).format("DD-MM-YYYY")}</td>
                  <td>
                    <IconButton
                      onClick={(e) => {
                        handleClick(e, item.phoneNumber);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td style={{ textAlign: "center" }} colSpan={7}>
                  No List At The Moment
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      {pageCount > 1 && (
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            range={1}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem
              onClick={() => {
                navigate(`/parent?parent_id=${param}`);
              }}
            >
              View Details
            </MenuItem>
            {/* <MenuItem>Edit</MenuItem>
            <MenuItem>Delete</MenuItem> */}
          </MenuList>
        </Box>
      </Popover>
    </div>
  );
};

LinkedUnLinkedParent.propTypes = {
  allData: PropTypes.array,
  type: PropTypes.string,
};

LinkedUnLinkedParent.defaultProps = {
  allData: [],
  type: "AllLinkedParent",
};
export default LinkedUnLinkedParent;
