import { ThemeProvider, Typography, createTheme } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import CreateNewParent from '../../components/Parent/CreateNewParent'

const theme = createTheme({
  palette:{
    primary:{
      main:'#671e78'
    }
  }
})
const PDProfile = () => {
  return (
    <>
    <ThemeProvider theme={theme}>
      <Box>
          <Typography
            variant="body"
            sx={{ fontFamily: "Gilroy-Medium", fontSize: "26px" }}
          >
            <span style={{ opacity: 0.3, fontFamily: "Gilroy-Regular" }}>
              Parent Management &#62;&#62;
            </span>{" "}
            Parent Dashboard - Mr James Olu(2) &#62;&#62; Profile
          </Typography>
          </Box>
          <CreateNewParent/>
          </ThemeProvider>
    </>
  )
}

export default PDProfile