/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
const endpoint = process.env.REACT_APP_API_ENDPOINT_ONE;
const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
const api_version = process.env.REACT_APP_API_VERSION;
// const api_version = "hello";
export default {
  postChangePin: (token, data, browserToken) =>
    axios
      .post(`${endpoint}/api/v${api_version}/Auth/Change-PIN`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          browserToken: `${browserToken}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response),
  postResetPin: (data) =>
    axios
      .post(`${endpoint}/api/v${api_version}/Auth/Reset-PIN`, data)
      .then((res) => res.data)
      .catch((err) => err.response),
  postForgetPin: (phoneNumber) =>
    axios
      .post(`${endpoint}/api/v${api_version}/Auth/Forget-PIN`, {
        phoneNumber,
        accountType: 3,
      })
      .then((res) => res.data)
      .catch((err) => err.response),
  postSendCode: ({ phoneNumber, email, dialCode, accountType }) => {
    let data = {
      phoneNumber,
      email,
      countryCode: dialCode,
      accountType,
    };
    console.log(data);

    return axios
      .post(`${endpoint}/api/v${api_version}/Auth/Send-Code`, data)
      .then((res) => {
        // console.log(res.data);
        return res.data;
      })
      .catch((err) => err.response);
  },

  postVerifyCode: ({ phoneNumber, code, accountType }) => {
    let data = {
      phoneNumber,
      code,
      accountType,
    };
    return axios
      .post(`${endpoint}/api/v${api_version}/Auth/Verify-code`, data)
      .then((res) => res.data)
      .catch((err) => err.response);
  },

  postCreatePin: ({ phoneNumber, pin, browserToken, accountType }) => {
    let data = {
      phoneNumber,
      pin,
      browserToken,
      accountType,
    };

    return axios
      .post(`${endpoint}/api/v${api_version}/Auth/Create-Pin`, data, {
        headers: {
          Authorization: `Bearer ${browserToken}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);
  },
  authLogin: ({ phoneNumber, pin, browserToken, accountType }) => {
    let data = {
      phoneNumber,
      pin,
      browserToken,
      accountType,
    };
    // console.log(data);
    return axios
      .post(`${endpoint_two}/api/v${api_version}/UserAccount/Adm-Auth`, data, {
        headers: {
          // Authorization: `Bearer ${browserToken}`,
          browserToken: `${browserToken}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);
  },
  // admin account details
  admAccountDetails: (token) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/UserAccount/Adm-AccountDetails`,
        {
          headers: {
            authToken: `${token}`,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  userStaffProfile: (token) =>
    axios
      .get(`${endpoint_two}/api/v${api_version}/UserAccount/Staff-Profile`, {
        headers: {
          authToken: `${token}`,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response),
  addUserInformation: (token, data, type) =>
    axios
      .post(`${endpoint_two}/api/v${api_version}/UserAccount/${type}`, data, {
        headers: {
          authToken: `${token}`,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response),
};

export const addUserInformation = (token, data, type) =>
  axios
    .post(`${endpoint_two}/api/v${api_version}/UserAccount/${type}`, data, {
      headers: {
        authToken: `${token}`,
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response);

export const userStaffProfile = (token) =>
  axios
    .get(`${endpoint_two}/api/v${api_version}/UserAccount/Staff-Profile`, {
      headers: {
        authToken: `${token}`,
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response);
