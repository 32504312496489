import {
  Button,
  IconButton,
  MenuItem,
  MenuList,
  Modal,
  Typography,
  Divider,
  List,
  ListItem,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box } from "@mui/system";
import React from "react";
import { BsSearch } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputBase from "@mui/material/InputBase";
import Pagination from "@mui/material/Pagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Title from "../Dashboard/Title";
import { Link } from "react-router-dom";
import AddQuiz from "./Modals/AddQuiz";
import Edit from "./Modals/Edit";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 800, xs: 400 },
  height: "600px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "1px solid #671E78",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};
const theme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
  },
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gilroy-Regular",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function createData(
  session,
  createdBy,
  phone,
  studentClass,
  name,
  status,
  email
) {
  return { session, createdBy, phone, studentClass, name, status, email };
}

const rows = [
  createData(
    "12/2022",
    "Olaoluwa Thomson",
    "Agricultural Science",
    "Jss 2 Grace",
    "Adeola Grace (2)",
    "30 mins",
    "abc@gmail.com"
  ),
  createData(
    "12/2022",
    "Olaoluwa Thomson",
    "Agricultural Science",
    "Jss 1 Purple",
    "Lawal Tolulope(2)",
    "30 mins",
    "abc@gmail.com"
  ),
  createData(
    "12/2022",
    "Olaoluwa Thomson",
    "Agricultural Science",
    "Jss 2 Grace",
    "Mrs Adeniyi",
    "40 mins",
    "abc@gmail.com"
  ),
  createData(
    "12/2022",
    "Olaoluwa Thomson",
    "Agricultural Science",
    "Jss 2 Grace",
    "Mrs Adeniyi",
    "30 mins",
    "abc@gmail.com"
  ),
  createData(
    "2021/2022",
    "Olaoluwa Thomson",
    "Agricultural Science",
    "Jss 2 Grace",
    "Mrs Adeniyi",
    "30 mins",
    "abc@gmail.com"
  ),
];

const CBTFirst = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const [openAddClass, setOpenAddClass] = React.useState(false);

  const handleOpenAddClass = () => setOpenAddClass(true);

  const handleCloseAddClass = () => setOpenAddClass(false);

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleOpenEdit = () => setOpenEdit(true);

  const handleCloseEdit = () => setOpenEdit(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Box align="right" sx={{ my: 2 }}>
          <Button
            onClick={handleOpenModal}
            variant="contained"
            sx={{
              textTransform: "initial",
              fontFamily: "Gilroy-Bold",
              height: "50px",
              px: 7,
              py: 0.5,
            }}
          >
            Add Quiz
          </Button>
        </Box>
        <Box
          mt={5}
          sx={{
            border: "1px solid #671E78",
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              borderRadius: "20px 20px 0px 0px",
              pt: 4,
              background: "rgba(103, 30, 120, 0.1)",
              height: "100px",
              pl: { xs: 2.5 },
            }}
          >
            <Search
              sx={{
                width: {
                  lg: "345px",
                  md: "330px",
                  sm: "auto",
                  xs: "auto",
                  pl: { xs: 6 },
                },
              }}
            >
              <SearchIconWrapper>
                <BsSearch />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Box>
          <TableContainer>
            <Table
              sx={{ minWidth: 700, fontFamily: "Gilroy-Regular" }}
              aria-label="customized table"
            >
              <TableHead
                sx={{
                  backgroundColor: "rgba(103, 30, 120, 0.1)",
                  width: "100%",
                }}
              >
                <TableRow>
                  <StyledTableCell>Posted Date</StyledTableCell>
                  <StyledTableCell>Subject</StyledTableCell>
                  <StyledTableCell>Posted By</StyledTableCell>
                  <StyledTableCell>Title</StyledTableCell>
                  <StyledTableCell>Duration</StyledTableCell>
                  <StyledTableCell>Type</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
                {rows.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {row.session}
                    </StyledTableCell>
                    <StyledTableCell>{row.phone}</StyledTableCell>
                    <StyledTableCell>{row.createdBy}</StyledTableCell>
                    <StyledTableCell>{row.email}</StyledTableCell>
                    <StyledTableCell>{row.status}</StyledTableCell>
                    <StyledTableCell>{row.createdBy}</StyledTableCell>

                    <StyledTableCell>
                      <IconButton onClick={handleClick}>
                        <MoreVertIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box
            sx={{
              bgcolor: "#671E78",
              color: "#fff",
              fontFamily: "Gilroy-Regular",
              px: 3,
            }}
          >
            <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
              <MenuItem
                onClick={handleOpenAddClass}
                sx={{ fontFamily: "Gilroy-Regular" }}
              >
                Add Class
              </MenuItem>
              <MenuItem sx={{ fontFamily: "Gilroy-Regular" }}>
                Activation
              </MenuItem>
              <Link to="/cbt/addquestion">
                <MenuItem sx={{ fontFamily: "Gilroy-Regular" }}>
                  Add/View Questions
                </MenuItem>
              </Link>
              <Link to="/cbt/submission">
                <MenuItem sx={{ fontFamily: "Gilroy-Regular" }}>
                  View Submission
                </MenuItem>
              </Link>
              <MenuItem sx={{ fontFamily: "Gilroy-Regular" }}>
                View Test
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "Gilroy-Regular" }}
                onClick={handleOpenEdit}
              >
                Edit
              </MenuItem>
              <MenuItem sx={{ fontFamily: "Gilroy-Regular" }}>Delete</MenuItem>
            </MenuList>
          </Box>
        </Popover>
      </Box>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box align="right" sx={{}}>
            <IconButton onClick={handleCloseModal}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", mb: 2, fontFamily: "Gilroy-Medium" }}
            >
              Add Quiz
            </Typography>
            <Divider />
          </Box>
          <Box p={3} sx={{ overflowY: "scroll", height: "450px" }}>
            <AddQuiz />
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box align="right" sx={{}}>
            <IconButton onClick={handleCloseEdit}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", mb: 2, fontFamily: "Gilroy-Medium" }}
            >
              Add Quiz
            </Typography>
            <Divider />
          </Box>
          <Box p={3} sx={{ overflowY: "scroll", height: "450px" }}>
            <Edit />
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openAddClass}
        onClose={handleCloseAddClass}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box align="right" sx={{}}>
            <IconButton onClick={handleCloseAddClass}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", mb: 1, fontFamily: "Gilroy-Medium" }}
            >
              Assign Class
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: "center", mb: 2, fontFamily: "Gilroy-Regular" }}
            >
              English Language (Oral English)
            </Typography>
            <Divider />
          </Box>
          <Box p={3} sx={{ overflowY: "scroll", height: "450px" }}>
            <List>
              <ListItem>
                <Checkbox />
                <Typography
                  variant="caption"
                  sx={{ fontFamily: "Gilroy-Medium" }}
                >
                  JSS 1
                </Typography>
              </ListItem>
              <ListItem>
                <Checkbox />
                <Typography
                  variant="caption"
                  sx={{ fontFamily: "Gilroy-Medium" }}
                >
                  JSS 1
                </Typography>
              </ListItem>
              <ListItem>
                <Checkbox />
                <Typography
                  variant="caption"
                  sx={{ fontFamily: "Gilroy-Medium" }}
                >
                  JSS 1
                </Typography>
              </ListItem>
            </List>
            <Box mt={5}>
              <Button
                variant="contained"
                fullWidth
                sx={{ textTransform: "initial" }}
              >
                Assign Class
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default CBTFirst;
