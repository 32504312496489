import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { checkAccess, paginateData } from "../../../services/utils";
import GlobalStyles from "../../../assets/globalStyle";
import colors from "../../../assets/colors.json";
import { BsSearch } from "react-icons/bs";
import { InputWrapper } from "../../../pages/auth/Login.style";
import { useMediaQuery } from "react-responsive";
import api from "../../../services/ControlPanel";
import { toast } from "react-toastify";
import {
  TabLinkContainer,
  AccountSummaryWrapper,
  BigHeader,
  Container,
  Icon,
  Input,
  MenuDiv,
  Search,
  TabContainer,
} from "../../../pages/Dashboard/styles/dashboard.style";
import Pagination from "../../pagination_one/pagination";
import Modal from "../../modal/Modal";
import Loader from "../../pageLoader/loader";

const VicePrincipalSetup = () => {
  const isTab = useMediaQuery({ maxWidth: 992 });
  const [sQuery, setSQuery] = useState("");
  const [itemOffSet, setitemOffSet] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(true);
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [allData, setAllData] = useState([]);
  const [arr, setArr] = useState([]);
  const [openAssign, setOpenAssign] = useState(false);
  const [openUnAssign, setOpenUnAssign] = useState(false);
  const [openViewVps, setOpenViewVps] = useState(false);
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [staffQuery, setStaffQuery] = useState("");
  const [staffId, setStaffId] = useState("");
  const [rowId, setRowId] = useState("");
  const [assignLoading, setAssignLoading] = useState(false);
  const [selectedVpIds, setSelectedVpIds] = useState([]);
  const [schoolVps, setSchoolVps] = useState([]);
  const [vpRowId, setVpRowId] = useState();
  const [schoolObj, setSchoolObj] = useState({
    id: "",
    name: "",
  });
  const filterSearch = useCallback(
    async (e) => {
      let arr = [];
      const queryRegex = new RegExp(e.target.value, "i");
      allData.forEach((item) => {
        Object.values(item).some((val) =>
          queryRegex.test(val) ? arr.push(item) : ""
        );
      });

      setArr(arr);
      setPageCount(0);
    },
    [allData]
  );
  const resetData = () => {
    setCurrentPage(1);
    paginateData(allData, setArr, 1, itemOffSet, setitemOffSet, setPageCount);
  };

  const filterStaff = useCallback(
    async (e) => {
      let arr = [];
      const queryRegex = new RegExp(e.target.value, "i");
      data.forEach((item) => {
        Object.values(item).some((val) =>
          queryRegex.test(val) ? arr.push(item) : ""
        );
      });
      setList(arr);
    },
    [data]
  );

  const getAllStaff = useCallback(async () => {
    try {
      const res = await api.getUsersCredential(admToken, user.schoolId, 3);
      if (res.status === true && res.statusCode === 2) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken, user.schoolId]);

  const getAllSchools = useCallback(async () => {
    try {
      const res = await api.getSchoolsForPrincipalOrVice(
        admToken,
        user.schoolId
      );
      setAllData(res.data);
      setPageLoading(false);
    } catch (error) {
      console.log(error);
      setPageLoading(false);
    }
  }, [admToken, user.schoolId]);
  const assignVicePrincipal = useCallback(
    async (schoolId) => {
      const data = {
        schoolId: schoolId,
        vicePrincpalIds: selectedVpIds,
      };
      try {
        setAssignLoading(true);
        const res = await api.assignVicePrincipal(admToken, data);
        if (res.status && res.statusCode === 2) {
          toast.success(res.message, {
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setOpenAssign(false);
          setCurrentPage(1);
          getAllSchools();
          setSelectedVpIds([]);
        }
        setAssignLoading(false);
        setRowId("");
      } catch (error) {
        toast.error("Error Assigning Principal");
        setRowId("");
      }
    },
    [admToken, getAllSchools, selectedVpIds]
  );

  const unAssignVicePrincipal = useCallback(
    async (staffId) => {
      try {
        setAssignLoading(true);
        const res = await api.unAssignPrincipalOrVice(admToken, staffId, true);
        if (res.status && res.statusCode === 2) {
          toast.success(res.message, {
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setOpenUnAssign(false);
          setCurrentPage(1);
          getAllSchools();
        }
        setAssignLoading(false);
      } catch (error) {
        toast.error("Error Un-assigning Vice Principal");
      }
    },
    [admToken, getAllSchools]
  );

  useEffect(() => {
    paginateData(
      allData,
      setArr,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allData, currentPage, itemOffSet]);

  useEffect(() => {
    getAllSchools();
    getAllStaff();
  }, [getAllStaff, getAllSchools]);
  const selectStaff = (credentialId) => {
    if (!selectedVpIds.includes(credentialId)) {
      setSelectedVpIds((prevIds) => [...prevIds, credentialId]);
    }
  };

  const selectedStaffNames = selectedVpIds?.map((id) => {
    const selectedStaff = data.find((staff) => staff.credentialId === id);
    return selectedStaff ? selectedStaff.fullName : "";
  });
  const removeSelectedStaff = (staffIdToRemove) => {
    setSelectedVpIds((prevIds) =>
      prevIds.filter((id) => id !== staffIdToRemove)
    );
  };
  const assignContent = (
    <>
      <BigHeader style={{ textAlign: "center" }} fs="13px">
        Assign Vice principal To {schoolObj.name}
      </BigHeader>
      <InputWrapper>
        <input
          type="text"
          placeholder="Search For Staff"
          onChange={(e) => {
            filterStaff(e);
            setStaffQuery(e.target.value);
          }}
          value={staffQuery}
        />
      </InputWrapper>
      <div className="flex gap-2 mt-4 flex-wrap">
        {selectedStaffNames.map((name, index) => (
          <div
            key={index}
            className={`bg-[${colors.primary}] text-white rounded flex gap-1 items-center p-1 relative`}
          >
            <span key={index}>{name}</span>
            <button
              className="text-white bg-red-500 absolute -top-3 -right-1  rounded-full w-5 h-5 "
              onClick={() => removeSelectedStaff(selectedVpIds[index])}
            >
              x
            </button>
          </div>
        ))}
      </div>
      <button
        onClick={() => assignVicePrincipal(schoolObj.id)}
        disabled={selectedVpIds.length === 0 || assignLoading}
        className={`w-full h-[48px] p-2 rounded-md bg-[${colors.primary}] text-white my-3`}
      >
        {assignLoading ? "loading..." : "Assign selected Staff(s)"}
      </button>
      <MenuDiv style={{ padding: isTab ? "0.6rem" : "0.8rem" }}>
        {list.length > 0 &&
          list.map((item, index) => (
            <div
              key={index}
              style={{
                marginTop: "0.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>{item.fullName}</div>
              <button
                disabled={staffId === item.credentialId && assignLoading}
                style={{
                  backgroundColor: colors.primary,
                  color: "white",
                  borderRadius: "20px",
                  padding: "0.3rem 0.6rem",
                }}
                onClick={() => {
                  setStaffId(item.credentialId);
                  selectStaff(item.credentialId);
                }}
              >
                Select
              </button>
            </div>
          ))}
      </MenuDiv>
    </>
  );

  const unAssignContent = (
    <>
      <BigHeader style={{ textAlign: "center" }} fs="13px">
        Un Assign Vice principal From {schoolObj.name}
      </BigHeader>
      <div className="flex flex-col gap-2 mt-4">
        {schoolVps?.map((each, index) => (
          <div
            key={index}
            className={`bg-[${colors.primary}] text-white rounded flex gap-1 items-center p-1 relative`}
          >
            <span key={index}>{each.vicePrincipalName}</span>
            <button
              disabled={assignLoading}
              className="text-white bg-red-500 absolute -top-3 -right-1  rounded-full w-5 h-5 "
              onClick={() => {
                setVpRowId(each.vicePrincipalId);
                unAssignVicePrincipal(each.vicePrincipalId);
              }}
            >
              {assignLoading && each.id === vpRowId ? "...." : "x"}
            </button>
          </div>
        ))}
      </div>
    </>
  );

  const viewVpsContent = (
    <>
      <BigHeader style={{ textAlign: "center" }} fs="13px">
        List Vice principals in {schoolObj.name}
      </BigHeader>
      <div className="flex flex-col gap-2 mt-4">
        {schoolVps?.map((each, index) => (
          <div
            key={index}
            className={`bg-[${colors.primary}] text-white rounded flex gap-1 items-center p-1 relative`}
          >
            <span key={index}>{each.vicePrincipalName}</span>
          </div>
        ))}
      </div>
    </>
  );

  if (pageLoading) {
    return <Loader />;
  }
  return (
    <Container>
      <GlobalStyles />
      {openAssign && (
        <Modal content={assignContent} size="large" onClose={setOpenAssign} />
      )}
      {openUnAssign && (
        <Modal
          content={unAssignContent}
          size="large"
          onClose={setOpenUnAssign}
        />
      )}
      {openViewVps && (
        <Modal content={viewVpsContent} size="large" onClose={setOpenViewVps} />
      )}
      <div>
        <BigHeader
          style={{
            borderBottom: `4px solid ${colors.primary}`,
            paddingBottom: "4px",
          }}
        >
          Vice Principal Setup
        </BigHeader>
      </div>
      <TabContainer
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          paddingBottom: "0px",
        }}
      >
        <TabLinkContainer bg="rgb(238, 224,241)">
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search
              type="text"
              placeholder="Search For Staff"
              value={sQuery}
              onChange={(e) => {
                setSQuery(e.target.value);
                if (e.target.value.length > 2) {
                  filterSearch(e);
                }

                if (e.target.value === "") {
                  resetData();
                }
              }}
            />
          </Input>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table
            style={{
              borderRadius: "0px",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <tr
              className="tabel-head"
              style={{ color: "black", fontWeight: "bold" }}
            >
              <th>S/N</th>
              <th>School ({allData.length > 0 ? allData.length : 0}) </th>
              <th>Vice Principal</th>
              <th></th>
            </tr>
            {arr.length > 0 ? (
              arr.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.schoolName}</td>
                  <td>
                    {item.vicePrincipals.length > 0 ? (
                      <button
                        onClick={() => {
                          setSchoolVps(item.vicePrincipals);
                          setSchoolObj({
                            ...schoolObj,
                            id: item.schoolId,
                            name: item.schoolName,
                          });
                          setOpenViewVps(true);
                        }}
                      >
                        Click to view
                      </button>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    {checkAccess(accessObj.rights, "Assign") && (
                      <div className="flex gap-1">
                        <button
                          disabled={
                            item.vicePrincipals.length === 0 || assignLoading
                          }
                          style={{
                            backgroundColor:
                              item.vicePrincipals.length > 0
                                ? colors.primary
                                : "white",
                            color:
                              item.vicePrincipals.length > 0
                                ? "white"
                                : colors.primary,
                            fontWeight: "bold",
                            border: `1px solid ${colors.primary}`,
                            borderRadius: "08px",
                            padding: "0.3rem 0.6rem",
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            setRowId(item.schoolId);
                            setSchoolVps(item.vicePrincipals);
                            setSchoolObj({
                              ...schoolObj,
                              id: item.schoolId,
                              name: item.schoolName,
                            });
                            setOpenUnAssign(true);
                          }}
                        >
                          Unassign
                        </button>
                        <button
                          disabled={rowId === item.schoolId && assignLoading}
                          style={{
                            backgroundColor:
                              item.vicePrincipals.length > 0
                                ? colors.primary
                                : "white",
                            color:
                              item.vicePrincipals.length > 0
                                ? "white"
                                : colors.primary,
                            fontWeight: "bold",
                            border: `1px solid ${colors.primary}`,
                            borderRadius: "08px",
                            padding: "0.3rem 0.6rem",
                            fontSize: "11px",
                          }}
                          onClick={() => {
                            setRowId(item.schoolId);
                            setSchoolObj({
                              ...schoolObj,
                              id: item.schoolId,
                              name: item.schoolName,
                            });
                            setOpenAssign(true);
                          }}
                        >
                          Assign
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} style={{ textAlign: "center" }}>
                  No School Available
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      {pageCount > 1 && (
        <div
          style={{
            marginTop: "2px",
            marginBottom: "2px",
          }}
        >
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            currentPage={currentPage - 1}
            range={3}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </Container>
  );
};

export default VicePrincipalSetup;
