import React, { useState, useEffect, useCallback } from "react";
import {
  AuthRightOverlay,
  AuthRightWrapper,
  CarouselDots,
  CarouselWrapper,
  RightLogoWrapper,
  RigthBigText,
} from "../pages/auth/auth.style";
import logo from "../assets/school-logo.png";
import img1 from "../assets/Images/slide1.jpeg";
import img2 from "../assets/Images/slide2.jpeg";
import img3 from "../assets/Images/slide3.jpeg";
import img4 from "../assets/Images/slide4.jpeg";
import img5 from "../assets/Images/slide5.jpeg";
import img6 from "../assets/Images/slide6.jpeg";
import img7 from "../assets/Images/slide7.jpeg";
import ArrowRight from "../assets/icons/arrow-right-circle";
let data = [img1, img2, img3, img4, img5, img6, img7];
const RightSide = () => {
  const [selected, setSelected] = useState(1);
  const nextSlide = useCallback(() => {
    let num = selected;
    num++;
    if (data.length - 1 === selected) {
      setSelected(0);
    } else {
      setSelected(num);
    }
  }, [selected]);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 60 * 1 * 1000); // 1 minute in milliseconds

    return () => clearInterval(interval);
  }, [nextSlide, selected]);

  return (
    <AuthRightWrapper bgUrl={data[selected]}>
      <AuthRightOverlay />
      <RightLogoWrapper>
        <img src={logo} alt="logo" />
      </RightLogoWrapper>
      <RigthBigText>
        Welcome Back, Educators! Log in to Inspire, Educate, and Shape
        Tomorrow's Leaders
      </RigthBigText>

      <CarouselWrapper>
        <div
          style={{
            display: "flex",
          }}
        >
          {data.map((item, index) => {
            return (
              <CarouselDots
                key={index}
                indexSelected={index === selected}
                onClick={() => setSelected(index)}
              />
            );
          })}
        </div>

        <div
          onClick={nextSlide}
          style={{
            cursor: "pointer",
          }}
        >
          <ArrowRight />
        </div>
      </CarouselWrapper>
    </AuthRightWrapper>
  );
};

export default RightSide;
