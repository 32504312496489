import { useState, useCallback, useEffect } from "react";
import GlobalStyles from "../../assets/globalStyle";
import {
  SmallHeader,
  TabContainer,
  TabContent,
  TabLink,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import { StudentBioData, SocialData, Health, Attachment } from "../StudentMgt";
import { Box } from "@mui/material";
import Loader from "../pageLoader/loader";
import colors from "../../assets/colors.json";
import { viewStudentDetails } from "../../services/MySchool";
import { checkAccess } from "../../services/utils";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateStudent } from "../../services/ParentStudent";
import ErrorService from "../../services/errorService";
import { getReadOnly } from "../../services/ParentStudent";
import StudentProfileBio from "../StudentMgt/StudentProfileBio";

const Profile = ({ schoolId, studentId, token }) => {
  const { accessObj, admToken } = useSelector((state) => state.auth);
  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const [dataObj, setDataObj] = useState(null);
  const [bioObj, setBioObj] = useState(null);
  const [healthObj, setHealthObj] = useState(null);
  const [socialObj, setSocialObj] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [formLoading, setFormLoading] = useState(false);
  const [name, setName] = useState("");
  const [readOnlyObj, setReadOnlyObj] = useState(null);

  const fetchReadOnly = async () => {
    try {
      const res = await getReadOnly(admToken, 1);
      setReadOnlyObj(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getStudentProfile = useCallback(async () => {
    try {
      setLoading(true);
      let res = await viewStudentDetails(token, schoolId, studentId);
      console.log(res);
      console.log(res.data);

      if (res.status && res.statusCode === 2) {
        res.data.bioData !== null &&
          setBioObj(Object.assign({}, res.data.bioData));

        res.data.healthData !== null &&
          setHealthObj(Object.assign({}, res.data.healthData));

        res.data.socialData !== null &&
          setSocialObj(Object.assign({}, res.data.socialData));

        res.data.attachmentData !== null &&
          setAttachments(res.data.attachmentData);

        setDataObj(res.data);
      }

      // console.log(res.data.persoalData.firtName);
      setName(
        `${res.data.bioData.firstName} ${res.data.bioData.otherName} ${res.data.bioData.lastName}`
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [schoolId, studentId, token]);

  useEffect(() => {
    getStudentProfile();
  }, [getStudentProfile]);

  useEffect(() => {
    fetchReadOnly();
  }, []);

  console.log("gggggg", readOnlyObj);

  const updateBioData = async () => {
    console.log(bioObj);
    setFormLoading(true);
    try {
      const res = await updateStudent(admToken, bioObj);
      console.log(res);
      setFormLoading(false);
      if (res.status === true && res.statusCode === 2) {
        toast.success(res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setBioObj({ ...bioObj, studentId: res.data.studentId });
        // setTab(2);
      }
      if (res.status === 400 || res.status === 500 || res.status === false) {
        ErrorService.displayErrorAlert(res);
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred");
      setFormLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <GlobalStyles />
      <Box
        borderBottom={`2px solid ${colors.primary}`}
        paddingBottom={"3px"}
        marginBottom={"24px"}
      >
        <SmallHeader
          style={{
            fontWeight: "bold",
            color: colors.lightGray,
            marginBottom: "1.9rem",
          }}
        >
          {/* <span style={{ marginRight: "0.9rem" }}>MySchool {">>"}</span> */}
          {/* <span style={{ marginRight: "0.9rem" }}>School</span> */}
          {name.length > 0 && (
            <>
              <span style={{ marginRight: "0.9rem" }}>
                Student Info- {name} {">>"}
              </span>
              <span style={{ color: "black" }}>Profile</span>
            </>
          )}
        </SmallHeader>
      </Box>
      <TabContainer>
        <TabLinkContainer>
          <TabLink
            isActive={tab === 1}
            onClick={() => setTab(1)}
            minWidth="25%"
          >
            <p>Personal Data</p>
          </TabLink>{" "}
          <TabLink
            isActive={tab === 2}
            onClick={() => setTab(2)}
            minWidth="25%"
          >
            <p>Social Data</p>
          </TabLink>{" "}
          <TabLink
            isActive={tab === 3}
            onClick={() => setTab(3)}
            minWidth="25%"
          >
            <p>health Data</p>
          </TabLink>{" "}
          <TabLink
            isActive={tab === 4}
            onClick={() => setTab(4)}
            minWidth="25%"
          >
            <p>Attachments</p>
          </TabLink>{" "}
        </TabLinkContainer>

        {dataObj !== null && (
          <TabContent>
            {tab === 1 && (
              <StudentProfileBio
                bioData={bioObj}
                setBioData={setBioObj}
                showInfoButton={checkAccess(accessObj.rights, "Update")}
                action={updateBioData}
                loading={formLoading}
                readOnlyObj={readOnlyObj}
              />
            )}
            {tab === 2 && (
              <SocialData bioData={socialObj} showInfoButton={false} />
            )}
            {tab === 3 && <Health bioData={healthObj} showInfoButton={false} />}
            {tab === 4 && (
              <Attachment data={attachments} showSubmitButton={false} />
            )}
          </TabContent>
        )}

        {dataObj === null && (
          <SmallHeader
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: colors.primary,
              marginTop: "0.4rem",
            }}
          >
            No Student Information, Please Try Again Later!!!
          </SmallHeader>
        )}
      </TabContainer>
    </>
  );
};

export default Profile;
