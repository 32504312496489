import React, { useState } from "react";
import {
  TabContainer,
  // TabContent,
  TabLink,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import LinkedUnLinkedParent from "./LinkedUnLinkedParent";
import GlobalStyles from "../../assets/globalStyle";
// import LinkedUnLinkedStudent from "../../components/StudentMgt/LinkedUnLinkedStudent";
const ParentList = () => {
  const [tab, setTab] = useState(2);
  return (
    <TabContainer style={{ paddingBottom: "0px" }}>
      <GlobalStyles />
      <TabLinkContainer>
        <TabLink
          isActive={tab === 2}
          onClick={() => setTab(2)}
          minWidth={"50%"}
        >
          Unlinked Parent
        </TabLink>
        <TabLink
          isActive={tab === 1}
          onClick={() => setTab(1)}
          minWidth={"50%"}
        >
          Linked Parent
        </TabLink>
      </TabLinkContainer>
      {tab === 1 && <LinkedUnLinkedParent type="AllLinkedParents" />}
      {tab === 2 && <LinkedUnLinkedParent type="AllUnlinkedParents" />}
    </TabContainer>
  );
};

export default ParentList;
