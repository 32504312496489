import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import colors from "../../assets/colors.json";
import { TabLink } from "../Dashboard/styles/dashboard.style";
import RequestLeave from "../../components/HR/RequestLeave";
import RedeploymentTransfer from "../../components/HR/RedeploymentTransfer";
import Training from "../../components/HR/Training";
const Request = () => {
  const isTab = useMediaQuery({ maxWidth: 992 });
  const [tab, setTab] = useState(1);
  return (
    <>
      <div
        style={{
          width: isTab ? "100%" : "70%",
          display: "flex",
          alignItems: "center",
          padding: "8px 16px",
          backgroundColor: colors.secondary,
          borderRadius: "5px",
          flexWrap: "wrap",
        }}
      >
        <TabLink isActive={tab === 1} onClick={() => setTab(1)}>
          Request Leave
        </TabLink>
        <TabLink isActive={tab === 2} onClick={() => setTab(2)}>
          Redeployment/Transfer
        </TabLink>
        <TabLink isActive={tab === 3} onClick={() => setTab(3)}>
          Training
        </TabLink>
      </div>

      {tab === 1 && <RequestLeave />}
      {tab === 2 && <RedeploymentTransfer />}
      {tab === 3 && <Training />}
    </>
  );
};

export default Request;
