import { List } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { SmallText } from "../../pages/auth/Login.style";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import colors from "../../assets/colors.json";
import {
  ControlPanelLink,
  ControlPanelNavTab,
  InlineFormDiv,
} from "../../pages/Dashboard/styles/dashboard.style";
import { InputWrapper } from "../../pages/auth/Login.style";
import { CiSearch } from "react-icons/ci";
import GlobalStyles from "../../assets/globalStyle";
import { BsArrowLeftCircle } from "react-icons/bs";
import { checkAccess } from "../../services/utils";

let Body = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
    secondary: {
      main: "#edf2ff",
    },
  },
  typography: {
    fontFamily: "Gilroy-Regular",
    h1: {
      fontFamily: "Gilroy-Bold",
      fontSize: "52px",
    },
  },
});

const CPNavbar = ({ title, navData }) => {
  const { accessObj, user } = useSelector((state) => state.auth);

  const theme = useTheme();
  const location = useLocation();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const page_id = Number(new URLSearchParams(location.search).get("page")) || 0;
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <GlobalStyles />
      {isMatch ? (
        <Box></Box>
      ) : (
        <ThemeProvider theme={Body}>
          <Box
            sx={{
              width: "301px",
              bgcolor: colors.lightGray,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "6.5rem",
              minHeight: "100vh",
            }}
          >
            {/* <Avatar
              src={logo}
              sx={{ bgcolor: "white", width: "85px", height: "85px", mb: 5 }}
            /> */}

            <ControlPanelNavTab>
              <SmallText style={{ textAlign: "center" }}>{user.name}</SmallText>
              <SmallText
                style={{
                  textAlign: "center",
                  textOverflow: "ellipsis",
                  marginBottom: "0.2rem",
                }}
              >
                {user.schoolName}
              </SmallText>
              <Link to="/controlpanel">
                <BsArrowLeftCircle />
                <span style={{ fontWeight: "bold", fontSize: "22px" }}>
                  Control Panel
                </span>
              </Link>
            </ControlPanelNavTab>

            <InlineFormDiv style={{ width: "85%" }}>
              <div style={{ width: "100%" }}>
                <InputWrapper style={{ position: "relative" }}>
                  <CiSearch
                    style={{
                      position: "absolute",
                      left: "0.5rem",
                      top: "64%",
                      fontSize: "27px",
                      color: colors.gray,
                      transform: "translateY(-50%)",
                    }}
                  />
                  <input
                    type="text"
                    placeholder=""
                    style={{ paddingLeft: "2.3rem" }}
                  />
                </InputWrapper>
              </div>
            </InlineFormDiv>

            <Box sx={{ width: "85%", mt: 4, mb: 3 }}>
              <SmallText
                color="black"
                style={{
                  fontSize: "18px",
                  fontWeight: "semibold",
                  marginLeft: "0.5rem",
                }}
              >
                {title}
              </SmallText>

              <List>
                {navData.map((nav, id) => (
                  <div
                    key={id}
                    className={`${
                      nav.link === "/principal-setup" ||
                      nav.link === "/vp-setup" ||
                      nav.link === "/hod-setup" ||
                      nav.link === "/year-tutor-setup" ||
                      nav.link === "/house-manager-setup"
                        ? "ml-5"
                        : ""
                    }`}
                  >
                    {checkAccess(accessObj.menuItems, nav.label) && (
                      <ControlPanelLink
                        key={id}
                        onClick={() => {
                          // setView(nav.id);
                          setSearchParams({ ...searchParams, page: nav.id });
                        }}
                        isActive={page_id === nav.id}
                      >
                        {nav.icon}
                        <span>{nav.label}</span>
                      </ControlPanelLink>
                    )}
                  </div>
                ))}
              </List>
            </Box>
          </Box>
        </ThemeProvider>
      )}
    </>
  );
};

export default CPNavbar;

export const getMatchPath = (pathname = "", link = "") => {
  let links = pathname.split("/");

  return link.length === 2 ? links[1] === link : links[2] === link;
};
