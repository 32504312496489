import React, { useState } from "react";
import {
  TabContainer,
  TabContent,
  TabLink,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import LinkedParent from "./LinkedParent";
import UnlinkedParent from "./UnlinkedParent";

const ParentStudent = () => {
  const [tab, setTab] = useState(1);
  return (
    <TabContainer>
      <TabLinkContainer>
        <TabLink isActive={tab === 1} onClick={() => setTab(1)}>
          Linked Parent
        </TabLink>
        <TabLink isActive={tab === 2} onClick={() => setTab(2)}>
         Unlinked Parent
        </TabLink>
      </TabLinkContainer>
      {tab === 1 && (
        <TabContent>
          <LinkedParent name="Classwork" />
        </TabContent>
      )}
      {tab === 2 && (
        <TabContent>
          <UnlinkedParent name="Classwork" />
        </TabContent>
      )}

    </TabContainer>
  );
};

export default ParentStudent;