import React, { useState, useEffect } from "react";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";

import {
  PageWrapper,
  ProfileWrapper,
  WrapperCenter,
  WrapperLeft,
  WrapperRight,
  InlineLabelGroup,
  InlineLabelWrapper,
  AccordionText,
  FormFlex,
  FormFlexDiv,
  TabContentWrapper,
  SmallHeader,
} from "../../pages/Dashboard/styles/dashboard.style";
import PropTypes from "prop-types";
import { InputWrapper, AuthButton } from "../../pages/auth/Login.style";
import { useMediaQuery } from "react-responsive";
// import colors from "../../assets/colors.json";

import DatePickerComponent from "../datepicker/DatePicker";
import Select from "../select/select";
import FormLoader from "../FormLoader";

const Health = ({
  bioData,
  setBioData,
  action,
  loading,
  readOnly,
  showInfoButton,
}) => {
  // const [dob, setDob] = useState(new Date(2014, 0, 3));
  const isMobile = useMediaQuery({ maxWidth: 992 });
  // const isTab = useMediaQuery({ maxWidth: 768 });
  // const [opt, setOpt] = useState("");
  // const [bioData, setBioData] = useState(null);
  const [dataShow, setDataShow] = useState({
    accId: 1,
    accName: "accOne",
  });

  const optionsArr = [
    {
      id: 1,
      name: "Yes",
      value: "true",
    },
    {
      id: 2,
      name: "No",
      value: "false",
    },
  ];
  // useEffect(() => {
  //   if (data !== null) {
  //     setBioData(Object.assign({}, data));
  //     // calculateAge(new Date(data.dob));
  //   }
  // }, [data]);
  return (
    <TabContentWrapper>
      {bioData === null ? (
        <SmallHeader style={{ textAlign: "center" }}>
          No Student Info At The Moment
        </SmallHeader>
      ) : (
        <>
          {/* Accordion one */}
          <div style={{ marginTop: "5px" }}>
            <ProfileWrapper
              onClick={() => {
                if (dataShow.accId === 1 && dataShow.accName === "accOne") {
                  setDataShow({
                    accId: "",
                    accName: "",
                  });
                } else {
                  setDataShow({
                    accId: 1,
                    accName: "accOne",
                  });
                }
              }}
            >
              <WrapperLeft>Immunization history</WrapperLeft>
              <WrapperCenter></WrapperCenter>
              <WrapperRight>
                {dataShow.accId === 1 ? (
                  <BiSolidUpArrow color="white" />
                ) : (
                  <BiSolidDownArrow color="white" />
                )}
              </WrapperRight>
            </ProfileWrapper>
            {dataShow.accName === "accOne" && (
              <PageWrapper>
                <InlineLabelGroup>
                  <InlineLabelWrapper flex={1}>
                    <InputWrapper>
                      <label htmlFor="">BCG</label>
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={2}>
                    {" "}
                    <InputWrapper>
                      <label htmlFor="">Status</label>
                      <input
                        type="text"
                        placeholder="BCG Status"
                        value={bioData.bcgStatus}
                        onChange={(e) =>
                          setBioData({ ...bioData, bcgStatus: e.target.value })
                        }
                        disabled={readOnly}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={2}>
                    <InputWrapper>
                      <label htmlFor="">Date</label>
                      <DatePickerComponent
                        selected={new Date(bioData.bcgDate)}
                        setSelected={(val) => {
                          setBioData({ ...bioData, bcgDate: val });
                          // setDob(val);
                        }}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                </InlineLabelGroup>
                <InlineLabelGroup>
                  <InlineLabelWrapper flex={1}>
                    <InputWrapper>
                      <label htmlFor="">DPT</label>
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={2}>
                    {" "}
                    <InputWrapper>
                      {/* <label htmlFor="">Status</label> */}
                      <input
                        type="text"
                        placeholder="DPT Status"
                        value={bioData.dptStatus}
                        onChange={(e) =>
                          setBioData({ ...bioData, dptStatus: e.target.value })
                        }
                        disabled={readOnly}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={2}>
                    <InputWrapper>
                      {/* <label htmlFor="">Date</label> */}
                      <DatePickerComponent
                        selected={new Date(bioData.dptDate)}
                        setSelected={(val) => {
                          setBioData({ ...bioData, dptDate: val });
                        }}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                </InlineLabelGroup>
                <InlineLabelGroup>
                  <InlineLabelWrapper flex={1}>
                    <InputWrapper>
                      <label htmlFor="">Oral Polio</label>
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={2}>
                    {" "}
                    <InputWrapper>
                      {/* <label htmlFor="">Status</label> */}
                      <input
                        type="text"
                        placeholder="Oral Polio Status"
                        value={bioData.oralPolioStatus}
                        onChange={(e) =>
                          setBioData({
                            ...bioData,
                            oralPolioStatus: e.target.value,
                          })
                        }
                        disabled={readOnly}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={2}>
                    <InputWrapper>
                      {/* <label htmlFor="">Date</label> */}
                      <DatePickerComponent
                        selected={new Date(bioData.oralPolioDate)}
                        setSelected={(val) => {
                          // setDob(val);
                          setBioData({
                            ...bioData,
                            oralPolioDate: val,
                          });

                          console.log(val);
                        }}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                </InlineLabelGroup>
                <InlineLabelGroup>
                  <InlineLabelWrapper flex={1}>
                    <InputWrapper>
                      <label htmlFor="">Yellow Fever</label>
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={2}>
                    {" "}
                    <InputWrapper>
                      <label htmlFor="">Status</label>
                      <input
                        type="text"
                        placeholder="Yellow Fever Status"
                        value={bioData.yellowFeverStatus}
                        onChange={(e) =>
                          setBioData({
                            ...bioData,
                            yellowFeverStatus: e.target.value,
                          })
                        }
                        disabled={readOnly}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={2}>
                    <InputWrapper>
                      <label htmlFor="">Date</label>
                      <DatePickerComponent
                        selected={new Date(bioData.yellowFeverDate)}
                        setSelected={(val) => {
                          setBioData({
                            ...bioData,
                            yellowFeverDate: val,
                          });
                        }}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                </InlineLabelGroup>
              </PageWrapper>
            )}
          </div>

          {/* Accordion Two */}
          <div style={{ marginTop: "5px" }}>
            <ProfileWrapper
              onClick={() => {
                if (dataShow.accId === 2 && dataShow.accName === "accTwo") {
                  setDataShow({
                    accId: "",
                    accName: "",
                  });
                } else {
                  setDataShow({
                    accId: 2,
                    accName: "accTwo",
                  });
                }
              }}
            >
              <WrapperLeft>Personal Medical history</WrapperLeft>
              <WrapperCenter></WrapperCenter>
              <WrapperRight>
                {dataShow.accId === 2 ? (
                  <BiSolidUpArrow color="white" />
                ) : (
                  <BiSolidDownArrow color="white" />
                )}
              </WrapperRight>
            </ProfileWrapper>
            {dataShow.accName === "accTwo" && (
              <PageWrapper>
                <AccordionText>
                  Does the child suffer from any of the following?
                </AccordionText>
                {/* 1 */}
                <FormFlex>
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Sickle Cell Disease</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          index="name"
                          selected={bioData.isSickleCell ? "Yes" : "No"}
                          options={optionsArr}
                          disabled={readOnly}
                          setSelected={(val) => {
                            setBioData({
                              ...bioData,
                              isSickleCell: val.name === "Yes",
                            });
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>{" "}
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Disease Of The Eye</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          index="name"
                          selected={bioData.hasEyeDisease ? "Yes" : "No"}
                          options={optionsArr}
                          disabled={readOnly}
                          setSelected={(val) => {
                            setBioData({
                              ...bioData,
                              hasEyeDisease: val.name === "Yes",
                            });
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>
                </FormFlex>
                {/* 2 */}
                <FormFlex>
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Epilepsy</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          index="name"
                          selected={bioData.hasEpilesy ? "Yes" : "No"}
                          options={optionsArr}
                          disabled={readOnly}
                          setSelected={(val) => {
                            setBioData({
                              ...bioData,
                              hasEpilesy: val.name === "Yes",
                            });
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>{" "}
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Disease Of the Ear</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          index="name"
                          selected={bioData.hasEarDisease ? "Yes" : "No"}
                          options={optionsArr}
                          disabled={readOnly}
                          setSelected={(val) => {
                            setBioData({
                              ...bioData,
                              hasEarDisease: val.name === "Yes",
                            });
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>
                </FormFlex>
                {/* 3 */}
                <FormFlex>
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Diabetics Meitus</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          index="name"
                          selected={bioData.hasDiabetics ? "Yes" : "No"}
                          options={optionsArr}
                          disabled={readOnly}
                          setSelected={(val) => {
                            setBioData({
                              ...bioData,
                              hasDiabetics: val.name === "Yes",
                            });
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>{" "}
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">
                            Disease of the Nose and throat
                          </label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          index="name"
                          selected={bioData.hasNoseDisease ? "Yes" : "No"}
                          options={optionsArr}
                          disabled={readOnly}
                          setSelected={(val) => {
                            setBioData({
                              ...bioData,
                              hasNoseDisease: val.name === "Yes",
                            });
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>
                </FormFlex>
                {/* 4 */}
                <FormFlex>
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Tuberculosis</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          index="name"
                          selected={bioData.hasTuberculosis ? "Yes" : "No"}
                          options={optionsArr}
                          disabled={readOnly}
                          setSelected={(val) => {
                            setBioData({
                              ...bioData,
                              hasTuberculosis: val.name === "Yes",
                            });
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>{" "}
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Respiratory Disease</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          index="name"
                          selected={
                            bioData.hasRespiratoryDisease ? "Yes" : "No"
                          }
                          options={optionsArr}
                          disabled={readOnly}
                          setSelected={(val) => {
                            setBioData({
                              ...bioData,
                              hasRespiratoryDisease: val.name === "Yes",
                            });
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>
                </FormFlex>{" "}
                {/* 5 */}
                <FormFlex>
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Heart Disease</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          index="name"
                          selected={bioData.hasHeartDisease ? "Yes" : "No"}
                          options={optionsArr}
                          disabled={readOnly}
                          setSelected={(val) => {
                            setBioData({
                              ...bioData,
                              hasHeartDisease: val.name === "Yes",
                            });
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>{" "}
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">
                            Alergies e.g Asthmaor Skin Dissease
                          </label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          index="name"
                          selected={bioData.hasAlergies ? "Yes" : "No"}
                          options={optionsArr}
                          disabled={readOnly}
                          setSelected={(val) => {
                            setBioData({
                              ...bioData,
                              hasAlergies: val.name === "Yes",
                            });
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>
                </FormFlex>{" "}
                {/* 6 */}
                <FormFlex>
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Kidney Disease</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          index="name"
                          selected={bioData.hasKidneyDisease ? "Yes" : "No"}
                          options={optionsArr}
                          disabled={readOnly}
                          setSelected={(val) => {
                            setBioData({
                              ...bioData,
                              hasKidneyDisease: val.name === "Yes",
                            });
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>{" "}
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Mental or Nervous Disease</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          index="name"
                          selected={
                            bioData.hasMentalOrNervousDisease ? "Yes" : "No"
                          }
                          options={optionsArr}
                          disabled={readOnly}
                          setSelected={(val) => {
                            setBioData({
                              ...bioData,
                              hasMentalOrNervousDisease: val.name === "Yes",
                            });
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>
                </FormFlex>{" "}
                {/* 7 */}
                <FormFlex>
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">
                            Disease of the Digestive System
                          </label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          index="name"
                          selected={bioData.hasDigestiveDisease ? "Yes" : "No"}
                          options={optionsArr}
                          disabled={readOnly}
                          setSelected={(val) => {
                            setBioData({
                              ...bioData,
                              hasDigestiveDisease: val.name === "Yes",
                            });
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>
                </FormFlex>
                <InlineLabelGroup>
                  <InlineLabelWrapper flex={2}>
                    <InputWrapper>
                      <label htmlFor="">
                        Does your child has any type of Handicap?
                      </label>
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={1}>
                    <InputWrapper>
                      <Select
                        title="Status"
                        index="name"
                        selected={bioData.isChild_HandCap ? "Yes" : "No"}
                        options={optionsArr}
                        disabled={readOnly}
                        setSelected={(val) => {
                          setBioData({
                            ...bioData,
                            isChild_HandCap: val.name === "Yes",
                          });
                        }}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                </InlineLabelGroup>
                <InlineLabelGroup>
                  <InlineLabelWrapper flex={2}>
                    <InputWrapper>
                      <label htmlFor="">
                        Has your child been admitted into a hospital before?
                      </label>
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={1}>
                    <InputWrapper>
                      <Select
                        title="Status"
                        index="name"
                        selected={
                          bioData.isChildAdmitted_To_Hospital ? "Yes" : "No"
                        }
                        options={optionsArr}
                        disabled={readOnly}
                        setSelected={(val) => {
                          setBioData({
                            ...bioData,
                            isChildAdmitted_To_Hospital: val.name === "Yes",
                          });
                        }}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                </InlineLabelGroup>
                <InlineLabelGroup>
                  <InlineLabelWrapper flex={2}>
                    <InputWrapper>
                      <label htmlFor="">
                        Has your child been operated on before?
                      </label>
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={1}>
                    <InputWrapper>
                      <Select
                        title="Status"
                        index="name"
                        selected={bioData.isChildOperatedBefore ? "Yes" : "No"}
                        options={optionsArr}
                        disabled={readOnly}
                        setSelected={(val) => {
                          setBioData({
                            ...bioData,
                            isChildOperatedBefore: val.name === "Yes",
                          });
                        }}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                </InlineLabelGroup>
                {/* If Yes */}
                {/*  */}
                <FormFlex>
                  <FormFlexDiv width="100%">
                    <InputWrapper>
                      <label htmlFor="">If Yes give details</label>
                      <textarea
                        cols="30"
                        rows="3"
                        value={bioData.operationDetails}
                        onChange={(e) =>
                          setBioData({
                            ...bioData,
                            operationDetails: e.target.value,
                          })
                        }
                        disabled={readOnly}
                      ></textarea>
                    </InputWrapper>
                  </FormFlexDiv>
                </FormFlex>
                <InlineLabelGroup>
                  <InlineLabelWrapper flex={2}>
                    <InputWrapper>
                      <label htmlFor="">
                        Is your child on any special medication?
                      </label>
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={1}>
                    <InputWrapper>
                      <Select
                        title="Status"
                        index="name"
                        selected={bioData.isChildOnMedication ? "Yes" : "No"}
                        options={optionsArr}
                        disabled={readOnly}
                        setSelected={(val) => {
                          setBioData({
                            ...bioData,
                            isChildOnMedication: val.name === "Yes",
                          });
                        }}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                </InlineLabelGroup>
              </PageWrapper>
            )}
          </div>
          {/* Accordion Three */}
          <div style={{ marginTop: "5px" }}>
            <ProfileWrapper
              onClick={() => {
                if (dataShow.accId === 3 && dataShow.accName === "accThree") {
                  setDataShow({
                    accId: "",
                    accName: "",
                  });
                } else {
                  setDataShow({
                    accId: 3,
                    accName: "accThree",
                  });
                }
              }}
            >
              <WrapperLeft>Others</WrapperLeft>
              <WrapperCenter></WrapperCenter>
              <WrapperRight>
                {dataShow.accId === 3 ? (
                  <BiSolidUpArrow color="white" />
                ) : (
                  <BiSolidDownArrow color="white" />
                )}
              </WrapperRight>
            </ProfileWrapper>
            {dataShow.accName === "accThree" && (
              <PageWrapper>
                <FormFlex>
                  <FormFlexDiv width="100%">
                    <InputWrapper>
                      <label htmlFor="Common Family Ailment(s)">
                        Common Family Ailment(s)
                      </label>
                      <input
                        type="text"
                        value={bioData.familyAilment}
                        onChange={(e) =>
                          setBioData({
                            ...bioData,
                            familyAilment: e.target.value,
                          })
                        }
                        disabled={readOnly}
                        placeholder="Common Family Ailment(s)"
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <label htmlFor="Current status of ailment">
                        Current status of ailment
                      </label>
                      <input
                        type="text"
                        value={bioData.ailmentStatus}
                        placeholder="Common Family Ailment(s)"
                        onChange={(e) =>
                          setBioData({
                            ...bioData,
                            ailmentStatus: e.target.value,
                          })
                        }
                        disabled={readOnly}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <label htmlFor="Blood Group">Blood Group</label>
                      <input
                        type="text"
                        value={bioData.bloodGroup}
                        onChange={(e) =>
                          setBioData({
                            ...bioData,
                            bloodGroup: e.target.value,
                          })
                        }
                        disabled={readOnly}
                        placeholder="Child Known ailment"
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <label htmlFor="">
                        Medical advice on child's ailment
                      </label>
                      <textarea
                        cols="30"
                        rows="3"
                        value={bioData.medicalAdvise}
                        onChange={(e) =>
                          setBioData({
                            ...bioData,
                            medicalAdvise: e.target.value,
                          })
                        }
                        disabled={readOnly}
                      ></textarea>
                    </InputWrapper>
                    <InputWrapper>
                      <label htmlFor="Child Known ailment">
                        Child Known ailment
                      </label>
                      <input
                        type="text"
                        value={bioData.childAilment}
                        onChange={(e) =>
                          setBioData({
                            ...bioData,
                            childAilment: e.target.value,
                          })
                        }
                        disabled={readOnly}
                        placeholder="Child Known ailment"
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <label htmlFor="Child's Allergic reaction(if any)">
                        Child's Allergic reaction(if any)
                      </label>
                      <input
                        type="text"
                        value={bioData.childAllergicReaction}
                        onChange={(e) =>
                          setBioData({
                            ...bioData,
                            childAllergicReaction: e.target.value,
                          })
                        }
                        disabled={readOnly}
                        placeholder="Child's Allergic reaction(if any)"
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <label htmlFor="Genotype">Genotype</label>
                      <input
                        type="text"
                        value={bioData.genotype}
                        onChange={(e) =>
                          setBioData({
                            ...bioData,
                            genotype: e.target.value,
                          })
                        }
                        disabled={readOnly}
                        placeholder="Child's Genotype"
                      />
                    </InputWrapper>
                  </FormFlexDiv>
                </FormFlex>
              </PageWrapper>
            )}
          </div>

          {showInfoButton && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <AuthButton
                disabled={loading}
                width={isMobile ? "100%" : "30%"}
                onClick={action}
              >
                {loading ? (
                  <FormLoader />
                ) : (
                  <div>
                    <span>Update Details</span>
                  </div>
                )}

                {/* Send Code */}
              </AuthButton>
            </div>
          )}
        </>
      )}
    </TabContentWrapper>
  );
};

Health.propTypes = {
  bioData: PropTypes.object,
  readOnly: PropTypes.bool,
  showInfoButton: PropTypes.bool,
};

Health.defaultProps = {
  bioData: null,
  readOnly: true,
  showInfoButton: true,
};

export default Health;
