import React from 'react'
import Title from '../../components/Dashboard/Title'

const Clinic = () => {
  return (
    <>
    <Title title="Clinic"/>
    </>
  )
}

export default Clinic