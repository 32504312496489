import { AccountCircle } from "@mui/icons-material";
import { Button, Grid, InputAdornment, InputLabel, TextField } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import { Box } from "@mui/system";
import React from "react";

const ReligionData = () => {
  return (
    <>
      <Box sx={{ width: "87%", margin: "0 auto" }}>
        <Grid container spacing={3} pt={5}>
          <Grid item xs={12} md={6}>
            <InputLabel
              sx={{
                mb: 1.5,
                fontWeight: "bolder",
                color: "#000",
                fontFamily: "Gilroy-Medium",
              }}
            >
              Religion
            </InputLabel>
            <TextField
              fullWidth
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "2px solid #671E78",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel
              sx={{
                mb: 1.5,
                fontWeight: "bolder",
                color: "#000",
                fontFamily: "Gilroy-Medium",
              }}
            >
              Place of Worship
            </InputLabel>
            <TextField
              fullWidth
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "2px solid #671E78",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel
              sx={{
                mb: 1.5,
                fontWeight: "bolder",
                color: "#000",
                fontFamily: "Gilroy-Medium",
              }}
            >
              Denomination
            </InputLabel>
            <TextField
           
              fullWidth
              size="small"
             
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "2px solid #671E78",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel
              sx={{
                mb: 1.5,
                fontWeight: "bolder",
                color: "#000",
                fontFamily: "Gilroy-Medium",
              }}
            >
           Status in place of worship
            </InputLabel>
            <TextField
              fullWidth
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "2px solid #671E78",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel
              sx={{
                mb: 1.5,
                fontWeight: "bolder",
                color: "#000",
                fontFamily: "Gilroy-Medium",
              }}
            >
              Place of Birth
            </InputLabel>
            <TextField
              fullWidth
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "2px solid #671E78",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel
              sx={{
                mb: 1.5,
                fontWeight: "bolder",
                color: "#000",
                fontFamily: "Gilroy-Medium",
              }}
            >
              Phone Number
            </InputLabel>
            <TextField
              fullWidth
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <p>+234</p>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "2px solid #671E78",
                  },
                },
              }}
            />
          </Grid>

         
        
        </Grid>
        <Box sx={{textAlign:'right', mt:5}}>
            <Button variant="contained">Submit For Approval</Button>
        </Box>
      </Box>
    </>
  );
};

export default ReligionData;
