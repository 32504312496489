import React, { useState, useEffect } from "react";
import colors from "../../assets/colors.json";
import { InputWrapper, AuthButton } from "../../pages/auth/Login.style";
import Select from "../select/select";
import hostelApi from "../../services/hostel";
import { toast } from "react-toastify";
import ErrorService from "../../services/errorService";

const AddBedspaceModal = ({
  rooms,
  hostel,
  user,
  admToken,
  setOpenAddModal,
  selectedRoom,
  setSelectedRoom,
  getBeds,
  getHostels,
  edit = false,
  selectedBed,
  setOpenEditModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [bedName, setBedName] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      hostelId: hostel.hostel_id,
      floorId: selectedRoom?.floorId,
      roomId: selectedRoom?.roomid,
      bedbunk: bedName,
      bedspace: 1,
      staffId: user.staffId,
      schoolId: user.schoolId,
    };

    const editData = {
      bedId: selectedBed?.bedId,
      hostelId: selectedBed?.hostelId,
      roomId: selectedBed?.roomId,
      floorName: selectedBed?.floorName,
      roomName: selectedBed?.roomName,
      gender: selectedBed?.gender,
      bedtype: bedName,
      bedspace: 1,
      createdBy: user.name,
      createdDate: new Date(),
      schoolId: user.schoolId,
    };

    try {
      setLoading(true);
      const res = await (edit
        ? hostelApi.editBedSpace(admToken, editData)
        : hostelApi.addBedSpace(admToken, data));
      if (res?.status && res?.statusCode === 2) {
        toast.success(res.message);
        getBeds();
        getHostels();
        !edit && setOpenAddModal(false);
        edit && setOpenEditModal(false);
        setLoading(false);
      } else if (res?.status === 500 || res?.status === 400 || !res?.status) {
        ErrorService.displayErrorAlert(res);
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (edit && selectedBed) {
      setBedName(selectedBed.bedtype);
    }
  }, [edit, selectedBed]);
  return (
    <form onSubmit={handleSubmit} className="mt-8">
      <header className="flex justify-center items-center flex-col pb-2 border-b-[0.5px] border-gray-400 px-4 w-[calc(100%+18px)] lg:w-[calc(100%+79px)] -ml-[9px] lg:-ml-[39px]">
        <h1 className="text-2xl">{edit ? "Edit" : "Add"} Bedspace </h1>
        <span
          style={{ background: colors.primary }}
          className="py-2 px-3 flex justify-center items-center text-white rounded-[20px]"
        >
          {hostel?.hostel_name}
        </span>
      </header>
      <InputWrapper>
        <label>Bed Name</label>
        <input
          type="text"
          name="bedname"
          id=""
          value={bedName}
          onChange={(e) => setBedName(e.target.value)}
        />
      </InputWrapper>
      <InputWrapper>
        <label>Room Name</label>
        <Select
          title="Room Name"
          index="roomname"
          thirdOption={"roomid"}
          selected={selectedRoom?.roomid}
          options={rooms}
          setSelected={(val) => {
            setSelectedRoom(val);
          }}
          // color="white"
        />
      </InputWrapper>
      {/* <InputWrapper>
        <h3 className="font-bold text-[#2e4138]">Bed Spaces</h3>
        <div className="flex gap-5">
          <div className="flex items-center gap-1.5">
            <label>Single Bunk</label>
            <input
              value={singleBunk}
              onChange={(e) => setSingleBunk(parseInt(e.target.value))}
              type="number"
              className="w-[60px] h-10 text-center font-bold"
            />
          </div>
          <div className="flex items-center gap-1.5">
            <label>Double Bunk</label>
            <input
              value={doubleBunk}
              onChange={(e) => setDoubleBunk(parseInt(e.target.value))}
              type="number"
              className="w-[60px] h-10 text-center font-bold"
            />
          </div>
          <input
            value={totalBedspaces}
            type="number"
            disabled
            className="w-[60px] h-10 ml-5 text-center font-bold"
          />
        </div>
      </InputWrapper> */}
      <AuthButton
        bg={colors.primary}
        disabled={loading}
        className="!rounded-md w-full"
        type="submit"
      >
        {loading ? "Loading..." : `${edit ? "Edit" : "Add"} Bedspace`}
      </AuthButton>
    </form>
  );
};

export default AddBedspaceModal;
