import React, {
  useState,
  useCallback,
  useEffect,
  // useRef,
  // createRef,
  // forwardRef,
} from "react";
// import styled from "styled-components";
// import ReactToPrint, { useReactToPrint } from "react-to-print";
import { BsSearch } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Typography, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputBase from "@mui/material/InputBase";
import Modal from "@mui/material/Modal";
import colors from "../../assets/colors.json";
import { AiFillLock } from "react-icons/ai";

import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
// import "./Result.css";
import { getResults, viewResult } from "../../services/ParentStudent";
import Loader from "../pageLoader/loader";
import { toast } from "react-toastify";
import ResultCard from "./ResultCard";
import { SmallHeader } from "../../pages/Dashboard/styles/dashboard.style";
import { getSchoolStampAndSignature } from "../../services/MySchool";
import { GetFixedGrades } from "../../services/Subject";

let theme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
    secondary: {
      main: "#edf2ff",
    },
  },
  typography: {
    h1: {
      fontFamily: "Gilroy-Bold",
      fontSize: "52px",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -38%)",
  width: {
    lg: 1100,
    xs: "98%",
    md: 880,
    sm: "90%",
  },
  height: 700,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: {
    lg: 4,
    md: 4,
    sm: 3,
    xs: 2,
  },
  overflow: "scroll",
  // scrollbars: "hidden",
  // scrollbars: "hidden",
};
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    // backgroundColor: " rgba(103, 30, 120, 0.1)",
    backgroundColor: "white",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Result = ({ studentPhone, name }) => {
  const isTab = useMediaQuery({ maxWidth: 768 });
  const { admToken, user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([]);
  const [result, setResult] = useState(null);
  const [stampSign, setStampSign] = useState(null);
  const [caMark, setCaMark] = useState(0);
  const [examMark, setExamMark] = useState(0);
  const viewData = useCallback(
    async (data) => {
      // toast.warning("Please wait...", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 1000,
      // });
      console.log("inside");
      console.log(data);
      setLoading(true);
      try {
        let res = await viewResult(admToken, data);
        console.log(res);
        if (res.status && res.statusCode === 2) {
          setLoading(false);
          setResult(res.data);
          handleOpen();
          return;
        }
        if (!res.status && res.statusCode === 6) {
          setLoading(false);
          toast.error(res.message);
          return;
        }
      } catch (error) {
        setLoading(false);
        toast.error("An error Occurred");
        console.log(error);
      }
    },
    [admToken]
  );
  const getData = useCallback(async () => {
    try {
      let res = await getResults(admToken, {
        schoolId: user.schoolId,
        phoneNumber: studentPhone,
      });
      // console.log(res);
      if (res.status && res.statusCode === 2) {
        setData(res.data);
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      // toast.error("Error!, Try Again Later");
      setLoading(false);
      console.log("something is wrong");
    }
  }, [admToken, studentPhone, user.schoolId]);
  const getStampSign = useCallback(async () => {
    try {
      let res = await getSchoolStampAndSignature(admToken, user.schoolId);

      if (res.status && res.statusCode === 2) {
        setStampSign(res.data);
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      // toast.error("Error!, Try Again Later");
      setLoading(false);
      console.log("something is wrong");
    }
  }, [admToken, user.schoolId]);
  const getFixedMarks = useCallback(async () => {
    try {
      const res = await GetFixedGrades({
        token: admToken,
        schoolId: user.schoolId,
      });
      if (res.status && res.statusCode === 2 && Array.isArray(res.data)) {
        let _obj = res.data[0];

        if (!isNaN(_obj.ca)) {
          // console.log(_obj.ca);
          setCaMark(parseFloat(_obj.ca));
        }

        if (!isNaN(_obj.exam)) {
          setExamMark(parseFloat(_obj.exam));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken, user.schoolId]);

  useEffect(() => {
    getData();
    getStampSign();
    getFixedMarks();
  }, [getData, getFixedMarks, getStampSign]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{ fontFamily: "gilroy-regular", margin: "0 auto", width: "90%" }}
        >
          <Box
            borderBottom={`2px solid ${colors.primary}`}
            paddingBottom={"3px"}
          >
            <SmallHeader
              style={{
                fontWeight: "bold",
                color: colors.lightGray,
                marginBottom: "1.9rem",
              }}
            >
              {/* <span style={{ marginRight: "0.9rem" }}>School</span> */}
              {name.length > 0 && (
                <>
                  <span style={{ marginRight: "0.9rem" }}>
                    Student Info- {name} {">>"}
                  </span>
                  <span style={{ color: "black" }}>Result Summary</span>
                </>
              )}
            </SmallHeader>
          </Box>
          {/* .......,,,,,,,,,,,,,,.............Tables..............,,,,,,,,,,,,,,,,............... */}
          <Box
            mt={5}
            sx={{
              border: "1px solid #671E78",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                display: "none",
                borderRadius: "20px 20px 0px 0px",
                pt: 4,
                background: "rgba(103, 30, 120, 0.1)",
                height: "100px",
                pl: { xs: 2.5 },
              }}
            >
              <Search
                sx={{
                  width: {
                    lg: "345px",
                    md: "330px",
                    sm: "auto",
                    xs: "auto",
                    pl: { xs: 6 },
                  },
                }}
              >
                <SearchIconWrapper>
                  <BsSearch />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Box>
            <TableContainer sx={{ borderRadius: "20px" }}>
              <Table
                sx={{ minWidth: 700, borderRadius: "20px" }}
                aria-label="customized table"
              >
                <TableHead
                  sx={{
                    backgroundColor: "rgba(103, 30, 120, 0.1)",
                    width: "100%",
                  }}
                >
                  <TableRow>
                    <StyledTableCell>S/N</StyledTableCell>
                    <StyledTableCell>Session</StyledTableCell>
                    <StyledTableCell>Term</StyledTableCell>
                    <StyledTableCell>Class</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
                  {data.length > 0 ? (
                    data.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell>{row.session}</StyledTableCell>
                        <StyledTableCell>{row.term}</StyledTableCell>
                        <StyledTableCell>{row.className}</StyledTableCell>
                        <StyledTableCell>
                          {row.status === "0" ? (
                            <Box
                              sx={{
                                background: "rgba(103, 30, 120, 0.1) ",
                                py: 1,
                                textAlign: "center",
                                color: "#671E78",
                                borderRadius: "4px",
                              }}
                            >
                              Available
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                background: "rgba(170, 16, 16, 0.1) ",
                                py: 1,
                                textAlign: "center",
                                justifyContent: "center",
                                color: "#AA1010",
                                display: "flex",
                                borderRadius: "4px",
                              }}
                            >
                              <span>Unavailable</span>
                              <AiFillLock style={{ marginLeft: "5px" }} />
                            </Box>
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              let data = {
                                sessionId: row.sessionId,
                                currentTermId: row.termId,
                                classId: row.classId,
                                studentPhoneNumber: studentPhone,
                                schoolId: user.schoolId,
                              };
                              viewData(data);
                            }}
                            sx={{
                              borderRadius: "100px",
                              fontFamily: "gilroy-regular",
                              px: 4,
                            }}
                            disabled={row.status === "0"}
                          >
                            View
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell
                        colSpan={6}
                        style={{ textAlign: "center" }}
                      >
                        No Results Available For This Student
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                  {/* {} */}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        {/* ............,,,,,,,,///////////////////................MODAL.............//////////////// */}
        <Modal
          sx={{
            minWidth: isTab ? "98%" : "80&",
          }}
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="result">
            <ResultCard
              caMark={caMark}
              examMark={examMark}
              resultData={result}
              stampSign={stampSign}
              close={() => {
                handleClose();
                setResult(null);
              }}
            />
          </Box>
        </Modal>
      </ThemeProvider>
    </>
  );
};

export default Result;
