import React from "react";
import Spinner from "./spinner.svg";
const loader = () => {
  return (
    <div className="center">
      <img src={Spinner} alt="activity-loader" />
    </div>
  );
};

export default loader;
