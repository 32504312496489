import GlobalStyles from "../../assets/globalStyle";
import { SmallHeader } from "../../pages/Dashboard/styles/dashboard.style";
import colors from "../../assets/colors.json";
import CreateParentForm from "../parent_mgt/CreateParentForm";
import Loader from "../pageLoader/loader";
import PropTypes from "prop-types";
const Profile = ({ loading, obj }) => {
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <GlobalStyles />
      <SmallHeader
        style={{
          fontWeight: "bold",
          color: colors.lightGray,
          marginBottom: "1.9rem",
        }}
      >
        <span style={{ marginRight: "0.9rem" }}>Parent Managment</span>
        <span style={{ marginRight: "0.9rem" }}>Parent Dashboard-</span>
        {obj && (
          <span style={{ marginRight: "0.9rem" }}>
            {`${obj.title} ${obj.fullName}`} {">>"}
          </span>
        )}
        <span style={{ color: "black" }}>Profile</span>
      </SmallHeader>
      <CreateParentForm obj={obj} />
    </>
  );
};

Profile.propTypes = {
  obj: PropTypes.object,
  loading: PropTypes.bool,
};

Profile.defaultProps = {
  obj: null,
  loading: true,
};
export default Profile;
