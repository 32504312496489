import React, { useState } from "react";
import { BigHeader } from "../../../pages/Dashboard/styles/dashboard.style";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import colors from "../../../assets/colors.json";
import { LuSearch, LuRefreshCcw } from "react-icons/lu";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import api from "../../../services/ControlPanel";
import { toast } from "react-toastify";
import moment from "moment";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const AuditTrail = () => {
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Input search params for data");
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    operation: "",
    from: null,
    to: null,
  });
  const [report, setReport] = useState();

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };
  const handleReset = () => {
    setFilters({
      name: "",
      email: "",
      phoneNumber: "",
      operation: "",
      from: null,
      to: null,
    });
    setReport(null);
    setMessage("Input search params for data");
  };
  const handleDateChange = (name, date) => {
    // Convert dayjs object to ISO string if date is not null
    const formattedDate = date ? date.toISOString() : null;
    setFilters({ ...filters, [name]: formattedDate });
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const res = await api.getAuditReport(admToken, filters);
      if (res.status) {
        setReport(res);
        setShowFilters(false);
        if (res.data.length === 0) {
          setMessage("No result for the searched parameters");
        }
      }
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const getAuditActionText = (auditAction) => {
    switch (auditAction) {
      case 1:
        return "New Record Created";
      case 2:
        return "Record Updated";
      case 3:
        return "Record Deleted";
      case 4:
        return "Activate";
      case 5:
        return "Deactivate";
      case 6:
        return "Assign";
      case 7:
        return "Unassign";
      default:
        return "";
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <section>
        <div>
          <BigHeader
            style={{
              borderBottom: `4px solid ${colors.primary}`,
              paddingBottom: "4px",
            }}
          >
            Audit Trail
          </BigHeader>
        </div>
        <div className="flex md:hidden">
          <button
            className="w-full h-12 rounded-lg flex justify-between items-center"
            onClick={() => setShowFilters(!showFilters)}
          >
            <span className="font-semibold">Show filters</span>
            {showFilters ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </button>
        </div>
        <div
          className={`${
            showFilters ? "flex" : "hidden md:flex"
          } flex flex-col gap-3 mb-6 justify-center py-4 border-b-4 border-primary overflow-x-scroll`}
        >
          <div className="flex flex-col md:flex-row justify-center items-center gap-4">
            <input
              type="text"
              name="name"
              value={filters.name}
              onChange={handleFilterChange}
              placeholder="Name"
              className="border border-gray-300 rounded-[48px] px-2 py-1 min-w-[100px] "
            />
            <input
              type="text"
              name="phoneNumber"
              value={filters.phoneNumber}
              onChange={handleFilterChange}
              placeholder="Phone Number"
              className="border border-gray-300 rounded-[48px] px-2 py-1 min-w-[100px] h-12"
            />
            <input
              type="email"
              name="email"
              value={filters.email}
              onChange={handleFilterChange}
              placeholder="Email"
              className="border border-gray-300 rounded-[48px] px-2 py-1 min-w-[100px] h-12"
            />
          </div>
          <div className="flex flex-col md:flex-row gap-4 items-center justify-center">
            <input
              type="text"
              name="operation"
              value={filters.operation}
              onChange={handleFilterChange}
              placeholder="Operation"
              className="border border-gray-300 rounded-[48px] px-2 py-1 min-w-[100px] h-12"
            />

            <DatePicker
              label="From"
              value={filters.from ? dayjs(filters.from) : null}
              onChange={(date) => handleDateChange("from", date)}
              renderInput={(params) => (
                <input {...params} className="lg:max-w-[100px] w-full" />
              )}
              sx={{
                width: "100%",

                "& .MuiInputBase-root": {
                  borderRadius: "48px",
                  fontSize: "12.8px",
                  width: "100%",
                  background: "#FAFAFF",
                },
                "& .MuiInputBase-input": {
                  fontSize: "12.8px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "12.8px",
                  fontWeight: 600,
                },
                "& .MuiInputLabel-formControl": {},
              }}
            />
            <DatePicker
              label="To"
              value={filters.to ? dayjs(filters.to) : null}
              onChange={(date) => handleDateChange("to", date)}
              renderInput={(params) => (
                <input {...params} className="lg:max-w-[100px] w-full" />
              )}
              sx={{
                width: "100%",

                "& .MuiInputBase-root": {
                  borderRadius: "48px",
                  fontSize: "12.8px",
                  width: "100%",
                  background: "#FAFAFF",
                },
                "& .MuiInputBase-input": {
                  fontSize: "12.8px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "12.8px",
                  fontWeight: 600,
                },
                "& .MuiInputLabel-formControl": {},
              }}
            />
          </div>
          <div className="flex  gap-6">
            <button
              disabled={loading}
              onClick={handleSearch}
              className="bg-primary text-white px-4 py-1 rounded  flex justify-center items-center w-1/2 h-12"
            >
              {loading ? "..." : <LuSearch />}
            </button>
            <button
              onClick={handleReset}
              className="bg-primary text-white px-4 py-1 rounded flex justify-center items-center w-1/2 h-12 "
            >
              <LuRefreshCcw />
            </button>
          </div>
        </div>
        <TableContainer>
          <Table
            sx={{ minWidth: 700, fontFamily: "Gilroy-Regular" }}
            aria-label="customized table"
          >
            <TableHead
              sx={{
                backgroundColor: "rgba(103, 30, 120, 0.1)",
                width: "100%",
              }}
            >
              <TableRow>
                <TableCell>Staff Name</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Audit Action</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
              {report && report.data.length > 0 ? (
                report?.data?.map((each) => (
                  <TableRow>
                    <TableCell>{each.staffName}</TableCell>
                    <TableCell>{each.actionDescription}</TableCell>
                    <TableCell>{each.assignRole}</TableCell>
                    <TableCell>
                      {getAuditActionText(each.auditAction)}
                    </TableCell>
                    <TableCell>
                      {moment(each.actionDateTime).format("DD/MM/YYYY")}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <p className="mt-12 lg:text-xl font-semibold ">{message}</p>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
    </LocalizationProvider>
  );
};

export default AuditTrail;
