import React, { useState, createRef, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import {
  PageWrapper,
  InlineFormDiv,
  SlidingImg,
  SmallHeader,
} from "../../../pages/Dashboard/styles/dashboard.style";

import { useMediaQuery } from "react-responsive";
import api from "../../../services/ControlPanel";
import { InputWrapper, AuthButton } from "../../../pages/auth/Login.style";
import Avatar from "../../../assets/Images/avatar.jpg";
import colors from "../../../assets/colors.json";
import FormLoader from "../../FormLoader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ErrorService from "../../../services/errorService";
import { checkAccess } from "../../../services/utils";
const News = ({ arr, getData }) => {
  const myRef = useRef();
  const [formLoading, setFormLoading] = useState(false);
  const { admToken, accessObj } = useSelector((state) => state.auth);
  const [actLoading, setActLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);

  // const [newsImg, setNewsImg] = useState("");
  const isPhone = useMediaQuery({ maxWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 992 });
  let _obj = { title: "", content: "", image: "", imageName: "" };
  const [newsObj, setNewsObj] = useState(_obj);
  const onNewsFileRef = createRef();
  const deleteItem = useCallback(
    async (id) => {
      setDeleteLoading(true);
      try {
        const res = await api.deleteControlPanelLasmabSetup(
          id,
          admToken,
          "Delete-News"
        );
        if (res.status && res.statusCode === 2) {
          toast.success(res.message, {
            autoClose: 4000,
            position: toast.POSITION.BOTTOM_LEFT,
          });
          // setFile({ ...file, name: "", base64: "" });
          getData();
        }
        res.status === 500 && ErrorService.displayErrorAlert(res.data);
        res.status === 400 && ErrorService.displayErrorAlert(res.data);
        setDeleteLoading(false);
      } catch (error) {
        setDeleteLoading(false);
        console.log(error);
      }
    },
    [getData, admToken]
  );
  const activateItem = useCallback(
    async (id, bool) => {
      setActLoading(true);
      try {
        const res = await api.activateControlPanelLasmabSetup(
          id,
          bool,
          admToken,
          "Activate-DeactivateNews"
        );
        if (res.status && res.statusCode === 2) {
          toast.success(res.message, {
            autoClose: 4000,
            position: toast.POSITION.BOTTOM_LEFT,
          });
          // setFile({ ...file, name: "", base64: "" });
          getData();
        }
        res.status === 500 && ErrorService.displayErrorAlert(res.data);
        res.status === 400 && ErrorService.displayErrorAlert(res.data);
        setActLoading(false);
      } catch (error) {
        setActLoading(false);
        console.log(error);
      }
    },
    [getData, admToken]
  );
  const submitNews = async () => {
    setFormLoading(true);

    try {
      const res = await api.postControlPanelLasmabSetup(
        newsObj,
        admToken,
        newsObj.newsid ? "Update-News" : "Add-News"
      );
      console.log(res);
      if (res.status && res.statusCode === 2) {
        toast.success(res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setNewsObj(Object.assign({}, _obj));

        getData();
      }

      // res.status && res.statusCode === 2 && resetBoardObj();
      if (res.status === 400 || res.status === 500) {
        ErrorService.displayErrorAlert(res.data);
      }
      if (!res.status) {
        ErrorService.displayErrorAlert(res);
      }
      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);

      console.log(error);
    }
  };
  const pickFile = (ref) => {
    ref.current.click();
  };
  const onPickFile = (event) => {
    const files = event.target.files;
    // setFileName(files[0].name);
    let filename = files[0].name;
    // setName !== null && setName(filename);
    if (
      filename.includes(".jpg") ||
      filename.includes(".png") ||
      filename.includes(".jpeg")
    ) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        // setFunc(fileReader.result);
        setNewsObj({
          ...newsObj,
          image: fileReader.result,
          imageName: filename,
        });
      });
      fileReader.readAsDataURL(files[0]);
    } else {
      alert("Please Upload A Photo");
    }

    console.log("We are working");
  };
  return (
    <PageWrapper width="95%" ref={myRef}>
      <InlineFormDiv width="90%" jc="space-between">
        <div
          style={{
            width: isMobile ? "100%" : "70%",
          }}
        >
          <input
            type="file"
            style={{
              display: "none",
            }}
            onChange={(e) => onPickFile(e)}
            ref={onNewsFileRef}
          />
          <InputWrapper>
            <label htmlFor="filename">Upload Image</label>
            <input
              type="text"
              value={newsObj.imageName}
              disabled={newsObj.imageName.length > 0}
              placeholder="Upload Image"
              onClick={() => pickFile(onNewsFileRef)}
            />
          </InputWrapper>
        </div>
        <AuthButton
          width={isMobile ? "100%" : "20%"}
          onClick={() => pickFile(onNewsFileRef)}
        >
          Upload
        </AuthButton>
      </InlineFormDiv>
      <InputWrapper>
        <label htmlFor="">Title</label>
        <input
          type="text"
          value={newsObj.title}
          onChange={(e) =>
            setNewsObj({
              ...newsObj,
              title: e.target.value,
            })
          }
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="">Content</label>
        <textarea
          name=""
          id=""
          cols="30"
          rows="4"
          value={newsObj.content}
          onChange={(e) =>
            setNewsObj({
              ...newsObj,
              content: e.target.value,
            })
          }
        ></textarea>
      </InputWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          borderBottom: `2px solid ${colors.primary}`,
          paddingBottom: "10px",
        }}
      >
        {((newsObj.newsid && checkAccess(accessObj.rights, "Edit")) ||
          checkAccess(accessObj.rights, "Save")) && (
          <AuthButton
            mt="0.3rem"
            disabled={
              formLoading ||
              newsObj.title === "" ||
              newsObj.content === "" ||
              newsObj.image === ""
            }
            onClick={() => submitNews()}
          >
            {formLoading ? (
              <FormLoader />
            ) : newsObj.newsid ? (
              "Update News"
            ) : (
              "Add News"
            )}
          </AuthButton>
        )}

        {newsObj.newsid && (
          <AuthButton
            style={{ marginLeft: "0.3rem" }}
            mt="0.3rem"
            bg="red"
            onClick={() => setNewsObj(Object.assign({}, _obj))}
          >
            Clear Fields
          </AuthButton>
        )}
      </div>
      {arr.length > 0 ? (
        arr.map((item, index) => (
          <SlidingImg key={index}>
            <div>
              <img
                src={
                  item.image === null || item.image === "" ? Avatar : item.image
                }
                alt=""
              />
              <div
                className=""
                style={{
                  // display: isPhone ? "none" : "block",
                  padding: "0.4rem",
                }}
              >
                <h5 style={{ fontWeight: "bold" }}>{item.newstitle}</h5>
                <div className="desc">
                  <p>{item.content}</p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: isPhone ? "column" : "row",
              }}
            >
              {checkAccess(accessObj.rights, "Activate") && (
                <button
                  disabled={query === item.newsid && actLoading}
                  style={{
                    borderColor:
                      item.status === 1 ? colors.primary : colors.gray,
                    backgroundColor:
                      item.status === 1 ? colors.primary : colors.gray,
                    color: "white",
                  }}
                  onClick={() => {
                    console.log("here");
                    setQuery(item.newsid);
                    activateItem(item.newsid, item.status === 1 ? false : true);
                  }}
                >
                  {query === item.newsid && actLoading
                    ? "Please wait..."
                    : item.status === 1
                    ? "Deactivate"
                    : "Activate"}
                </button>
              )}

              {/* <button
                style={{
                  color: "white",
                  backgroundColor: colors.primary,
                }}
              >
                Activate
              </button> */}
              {checkAccess(accessObj.rights, "Edit") && (
                <button
                  style={{
                    marginTop: isPhone ? "0.3rem" : "0rem",

                    backgroundColor: colors.primary,
                    color: "white",
                    fontSize: "14px",
                  }}
                  onClick={() => {
                    const element = myRef.current;
                    const extraOffset = 50; // Adjust this value to your preference

                    window.scrollTo({
                      top: element.offsetTop - extraOffset,
                      behavior: "smooth",
                    });
                    let obj = {
                      newsid: item.newsid,
                      title: item.newstitle,
                      content: item.content,
                      image: item.image,
                      imageName: "Image",
                    };
                    setNewsObj(Object.assign({}, obj));
                  }}
                >
                  Edit
                </button>
              )}

              {checkAccess(accessObj.rights, "Delete") && (
                <button
                  disabled={query === item.newsid && deleteLoading}
                  onClick={() => {
                    console.log("here");
                    setQuery(item.newsid);
                    deleteItem(item.newsid);
                  }}
                  style={{
                    marginTop: isPhone ? "0.3rem" : "0rem",
                    backgroundColor: "red",
                    border: `1px solid red`,
                    borderRadius: "10px",
                    marginLeft: isPhone ? "0rem" : "0.2rem",
                    color: "white",
                  }}
                >
                  {query === item.newsid && deleteLoading
                    ? "Please wait..."
                    : "Delete"}
                </button>
              )}
            </div>
          </SlidingImg>
        ))
      ) : (
        <SmallHeader style={{ textAlign: "center" }}>
          No New Information At The Moment
        </SmallHeader>
      )}
    </PageWrapper>
  );
};

News.propTypes = {
  arr: PropTypes.array,
  loading: PropTypes.bool,
  getData: PropTypes.func,
};

News.defaultProps = {
  arr: [],
  loading: true,
};

export default News;
