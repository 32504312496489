import { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet, useLocation, useSearchParams } from "react-router-dom";
import { TabRoute, TabRouteContainer } from "./stu_mgt.style";
import { Container } from "../Dashboard/styles/dashboard.style";
import GlobalStyles from "../../assets/globalStyle";
import { getLinkedUnLinkStudents } from "../../services/ParentStudent";
import Title from "../../components/Dashboard/Title";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import { ThemeProvider, createTheme } from "@mui/material";
import { checkAccess } from "../../services/utils";
import CreateStudent from "./CreateStudent";
import StudentList from "./StudentList";
import Subject from "./Subject";
import Result from "./Result";
import Attendance from "./Attendance";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
  },
});

const StudentMgt = () => {
  const location = useLocation();
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [studentCount, setStudentCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const tab_id = Number(new URLSearchParams(location.search).get("tab"));
  const handleChange = (event, newValue) => {
    console.log(newValue);
    setSearchParams({ ...searchParams, tab: newValue });
  };
  const getStudentListCount = useCallback(async () => {
    try {
      const res = await getLinkedUnLinkStudents(
        admToken,
        user.schoolId,
        user.classId,
        ""
      );
      console.log(res);
      if (res.status && res.statusCode === 2) {
        let num1 = res.data.linkedStudent.length || 0;
        let num2 = res.data.unLinkedStudent.length || 0;
        setStudentCount(num1 + num2);
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken, user]);
  useEffect(() => {
    getStudentListCount();
  }, [getStudentListCount]);

  return (
    <Container>
      <GlobalStyles />
      <div>
        <Title title="Student Management" />
      </div>

      <TabRouteContainer>
        {checkAccess(accessObj.menuItems, "Create Student") && (
          <TabRoute isActive={tab_id === 1} onClick={(e) => handleChange(e, 1)}>
            <span>Create Student</span>
          </TabRoute>
        )}

        {checkAccess(accessObj.menuItems, "Student Records") && (
          <TabRoute isActive={tab_id === 2} onClick={(e) => handleChange(e, 2)}>
            <span> Student List ({studentCount})</span>
          </TabRoute>
        )}
        {checkAccess(accessObj.menuItems, "Subject Mgt.") && (
          <TabRoute isActive={tab_id === 3} onClick={(e) => handleChange(e, 3)}>
            <span> Subject</span>
          </TabRoute>
        )}
        {checkAccess(accessObj.menuItems, "Result Mgt.") && (
          <TabRoute isActive={tab_id === 4} onClick={(e) => handleChange(e, 4)}>
            <span> Result</span>
          </TabRoute>
        )}
        {checkAccess(accessObj.menuItems, "Attendance Mgt.") && (
          <TabRoute isActive={tab_id === 5} onClick={(e) => handleChange(e, 5)}>
            <span> Attendance</span>
          </TabRoute>
        )}
      </TabRouteContainer>
      {checkAccess(accessObj.menuItems, "Create Student") && tab_id === 1 && (
        <CreateStudent />
      )}
      {checkAccess(accessObj.menuItems, "Student Records") && tab_id === 2 && (
        <StudentList />
      )}
      {checkAccess(accessObj.menuItems, "Subject Mgt.") && tab_id === 3 && (
        <Subject />
      )}
      {checkAccess(accessObj.menuItems, "Result Mgt.") && tab_id === 4 && (
        <Result />
      )}
      {checkAccess(accessObj.menuItems, "Attendance Mgt.") && tab_id === 5 && (
        <Attendance />
      )}
      {/* <Outlet /> */}
    </Container>
  );
};

export default StudentMgt;
