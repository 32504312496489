import React, { useState } from "react";
import colors from "../../assets/colors.json";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/system";
import {
  AccountSummaryWrapper,
  Icon,
  Input,
  Search,
  SmallHeader,
  TabContainer,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import Popover from "@mui/material/Popover";
import PropTypes from "prop-types";
import { ImgTextDiv, StatusBar } from "../../pages/student_mgt/stu_mgt.style";
import Avatar from "../../assets/Images/avatar.jpg";

import { SmallText } from "../../pages/auth/Login.style";
import { Grid, IconButton, MenuItem, MenuList } from "@mui/material";
import Card from "../Dashboard/Card";
import { FaBabyCarriage, FaRegHandshake } from "react-icons/fa";
import { BsFillJournalBookmarkFill, BsSearch } from "react-icons/bs";
import { MdOutlineMedicalServices } from "react-icons/md";
const ApprovalTraining = ({ showOnlyList }) => {
  const [tab, setTab] = useState(showOnlyList ? 2 : 1);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <div style={{ marginTop: "0.7rem" }}>
        <TabContainer style={{ paddingBottom: "0px" }}>
          <TabLinkContainer bg="#EEE0F1">
            <Input>
              <Icon>
                <BsSearch />
              </Icon>
              <Search type="text" placeholder="Search" />
            </Input>
          </TabLinkContainer>
          <AccountSummaryWrapper pt="0px">
            <table style={{ borderRadius: "0px" }}>
              <tr
                className="table-head"
                style={{ backgroundColor: "#F0E8F1", color: "black" }}
              >
                <th>
                  <input type="checkbox" />
                </th>
                <th>Staff ID</th>
                <th>Staff Name</th>
                <th>Paid By</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th></th>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>CMS/2022/001</td>
                <td>
                  <ImgTextDiv>
                    <img src={Avatar} alt="" />
                    <span>Adekitan Sandra</span>
                  </ImgTextDiv>
                </td>

                <td>CMS Grammar School</td>
                <td>April 10th, 2023</td>
                <td>April 10th, 2023</td>
                <td>
                  <IconButton onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>
                </td>
              </tr>
            </table>
          </AccountSummaryWrapper>
        </TabContainer>

        <div
          style={{
            display: "flex",
            marginTop: "1rem",
            justifyContent: "end",
            padding: "10px 20px",
          }}
        >
          <button
            style={{
              borderRadius: "8px",
              // padding: "8px",
              minWidth: "121px",
              color: "white",
              backgroundColor: colors.primary,
              cursor: "pointer",
              lineHeight: "2.125rem",
              border: `1px solid ${colors.primary}`,
              outline: "none",
              fontWeight: "bold",
            }}
          >
            Approve
          </button>
          <button
            style={{
              marginLeft: "0.5rem",
              borderRadius: "8px",
              fontSize: "16px",
              // padding: "8px",
              minWidth: "121px",
              backgroundColor: "white",
              color: colors.primary,
              cursor: "pointer",
              lineHeight: "2.125rem",
              border: `1px solid ${colors.primary}`,
              outline: "none",
              fontWeight: "bold",
            }}
          >
            Delete
          </button>
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem>View</MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </>
  );
};

ApprovalTraining.propTypes = {
  showOnlyList: PropTypes.bool,
};

ApprovalTraining.defaultProps = {
  showOnlyList: false,
};

export default ApprovalTraining;
