import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  SmallHeader,
  SubMenuDiv,
  TabContentWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
import { InputWrapper } from "../../pages/auth/Login.style";
import colors from "../../assets/colors.json";
import { useMediaQuery } from "react-responsive";
import {
  AiFillPlusCircle,
  AiFillMinusCircle,
  // AiFillCheckCircle,
  AiFillCloseCircle,
} from "react-icons/ai";
import PropTypes from "prop-types";
import { checkAccess } from "../../services/utils";
import { useSelector, useDispatch } from "react-redux";
import { NavData } from "../../assets/data/NavData";
import { Grid } from "@mui/material";
import Card from "../Dashboard/Card";
import Loader from "../../components/pageLoader/loader";
import { ImStatsBars } from "react-icons/im";
import api from "../../services/ControlPanel";
import dashboardApi from "../../services/dashboard";
import { toast } from "react-toastify";
import { TeacherClasses } from "../../services/Subject";
import FormControl from "@mui/material/FormControl";
import ErrorService from "../../services/errorService";
import Select from "@mui/material/Select";
import { getAccountProfile } from "../../features/auth/authSlice";
import MenuItem from "@mui/material/MenuItem";
import { Token } from "@mui/icons-material";
const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;

const api_version = process.env.REACT_APP_API_VERSION;

const UserInformation = ({ data, myClasses }) => {
  const dispatch = useDispatch();
  const isTab = useMediaQuery({ maxWidth: 992 });
  const [showFirst, setShowFirst] = useState(false);
  const [showSecond, setShowSecond] = useState(false);
  const [showThird, setShowThird] = useState(false);
  const [showFourth, setShowFourth] = useState(false);
  const [details, setDetails] = useState(null);
  const [access, setAccess] = useState(null);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [loading, setLoading] = useState(false);
  const { accessObj } = useSelector((state) => state.auth);
  const { user, admToken, token, isLoading } = useSelector(
    (state) => state.auth
  );
  const [stats, setStats] = useState([]);
  const [dashboardItems, setDashboardItems] = useState([]);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    if (data !== null) {
      setDetails(Object.assign({}, data.accountDetails));
      setAccess(Object.assign({}, data.accountAccess));
    }
  }, [data]);

  // Fetch Menu items
  const fetchMenuItems = useCallback(async (admToken, staffId) => {
    try {
      const endpoint = `https://lasmabadmindev.azurewebsites.net/api/v1/ControlPaneluserSetup/MenuItems/${staffId}`;
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${admToken}`,
        },
      });

      if (response.data.status === true && response.data.statusCode === 2) {
        console.log("Fetched menu items:", response.data.data.mainMenus); // Debug log
        return response.data.data.mainMenus;
      } else {
        throw new Error("Failed to fetch menu items");
      }
    } catch (error) {
      console.error("Error fetching menu items:", error);
      throw error;
    }
  }, []);

  useEffect(
    () => {
      setLoading(true);
      fetchMenuItems(admToken, user.staffId)
        .then((items) => {
          console.log("Setting menu items:", items); // Debug log
          setMenuItems(items);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching menu items:", error);
          setLoading(false);
        });
    },
    [admToken, user.staffId, fetchMenuItems],
    []
  );

  const renderMenu = (items) => {
    return (
      <ul className="list-disc ml-4">
        {items.map((item) => (
          <li key={item.id}>
            <span className={`font-[800] ${item.subMenus ? "ml-0" : ""}`}>
              {item.name}
            </span>
            {item.subMenus && (
              <ul className="list-disc ml-4">
                {item.subMenus.map((subItem) => (
                  <li key={subItem.id}>
                    <span className="ml-4 font-[700]">{subItem.name}</span>
                    {subItem.items && (
                      <ul className="list-circle ml-4">
                        {subItem.items.map((nestedSubItem) => (
                          <li
                            key={nestedSubItem.id}
                            className="ml-4 font-[600]"
                          >
                            {nestedSubItem.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    );
  };

  const getSelectedSchoolFunc = useCallback(async () => {
    try {
      const res = await api.getSelectedSchool(
        admToken,
        user.phoneNumber,
        accessObj?.privileges?.some((obj) => obj.id === 1) ? 1 : 2
      );
      if (res.status === true && res.statusCode === 2) {
        setSelectedSchools(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken, user.phoneNumber, accessObj?.privileges]);

  const assignDefaultClass = useCallback(
    async (id) => {
      toast.info("Please wait...");
      try {
        const res = await api.assignDefaultClassToStaff(
          admToken,
          user.schoolId,
          user.staffId,
          id
        );
        if (res.status === true && res.statusCode === 2) {
          toast.success(res.message);
          dispatch(getAccountProfile(admToken));
        }

        if (res.status === 400 || res.status === 500 || res.status === false) {
          ErrorService.displayErrorAlert(res);
        }
      } catch (error) {
        toast.error("An Error Occurred");
      }
    },
    [admToken, dispatch, user.schoolId, user.staffId]
  );

  // Function to fetch selected department data
  const getSelectedDepartFunc = useCallback(async () => {
    try {
      const res = await api.getSelectedDepartment(
        admToken,
        user.phoneNumber,
        accessObj?.privileges?.some((obj) => obj.id === 1) ? 1 : 3,
        user.schoolId
      );
      if (res.status === true && res.statusCode === 2) {
        setSelectedDepartments(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken, user.phoneNumber, user.schoolId, accessObj?.privileges]);

  // Function to fetch department dashboard count
  const getDepartmentDashboardCount = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const res = await api.dashboardCount(admToken, user.phoneNumber, id);
        if (res.status && res.statusCode === 2) {
          setStats(res.data);
        }
        if (!res.status && res.statusCode === 6) {
          toast.error(res.message);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
    [user, admToken]
  );

  // Function to fetch all dashboard counts
  // const getDashboardCount = useCallback(
  //   async (id) => {
  //     try {
  //       setLoading(true);
  //       const res = await dashboardApi.getDashboardCount(
  //         admToken,
  //         id,
  //         user.schoolId
  //       );
  //       if (res.status && res.statusCode === 2) {
  //         setAllDashboardCounts(res.data);
  //       }
  //       if (!res.status && res.statusCode === 6) {
  //         toast.error(res.message);
  //       }
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //       console.log(error);
  //     }
  //   },
  //   [admToken, user.schoolId]
  // );

  useEffect(() => {
    getSelectedSchoolFunc();
    getSelectedDepartFunc();
    if (selectedDepartments.length > 0) {
      const initialDepartId = selectedDepartments[0].departmentId;
      getDepartmentDashboardCount(initialDepartId);
      // getDashboardCount(initialDepartId);
    }
  }, []);

  return (
    <TabContentWrapper>
      {details === null ? (
        <SmallHeader
          style={{
            textAlign: "center",
            color: colors.primary,
            fontWeight: "bold",
          }}
        >
          An error Ocurred Try Again Later
        </SmallHeader>
      ) : (
        <>
          <InputWrapper>
            <label htmlFor="" style={{ color: colors.gray, fontSize: "14px" }}>
              School
            </label>
            <SmallHeader style={{ fontWeight: "bold" }}>
              {details.schoolName}
            </SmallHeader>
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="" style={{ color: colors.gray, fontSize: "14px" }}>
              Department
            </label>
            {details.department.length > 0 && (
              <SmallHeader style={{ fontWeight: "bold" }}>
                {details.department.map((dept) => (
                  <div key={dept.id}>
                    {" "}
                    {/* Ensure each mapped element has a unique key */}
                    {dept.name}
                  </div>
                ))}
              </SmallHeader>
            )}
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="" style={{ color: colors.gray, fontSize: "14px" }}>
              Role
            </label>
            <SmallHeader style={{ fontWeight: "bold" }}>
              {details.roleName}
            </SmallHeader>
          </InputWrapper>

          <InputWrapper>
            <label htmlFor="" style={{ color: colors.gray, fontSize: "14px" }}>
              Class
            </label>
            <SmallHeader style={{ fontWeight: "bold" }}>
              {/* <div className="class-grid">
                {classes.length > 0 &&
                  classes.map((item, index) => (
                    <div key={index} className="class-card">
                      <h3>{item.className} <span>{item.classArm}</span></h3>

                    </div>
                  ))}
              </div> */}
              <div style={{ fontFamily: "Gilroy-Regular" }}>
                {myClasses.length > 0 &&
                  myClasses.map((item, index) => (
                    <div key={index} style={{ marginBottom: "10px" }}>
                      <button
                        style={{
                          border: "1px solid #ccc",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          cursor: "pointer",
                          backgroundColor: "#f0f0f0",
                          fontFamily: "Gilroy-Regular",
                          fontWeight: "bold",
                        }}
                        onClick={() => assignDefaultClass(item.classId)}
                      >
                        {item.className} {item.classArm}
                      </button>
                    </div>
                  ))}
              </div>
            </SmallHeader>
          </InputWrapper>
          {/* <InputWrapper>
            <label htmlFor="" style={{ color: colors.gray, fontSize: "14px" }}>
              Other Roles
            </label>
            <SmallHeader style={{ fontWeight: "bold" }}>
              No Role Stated In The API
            </SmallHeader>
          </InputWrapper> */}
          {access !== null && (
            <>
              <InputWrapper>
                <label
                  htmlFor=""
                  style={{
                    color: colors.gray,
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "0.4rem",
                  }}
                >
                  {" "}
                  Priviledges
                </label>
              </InputWrapper>
              <div
                style={{
                  width: isTab ? "100%" : "75%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: colors.secondary,
                  borderRadius: "5px",
                  padding: "0.7rem",
                  margin: "0.4rem 0rem",
                }}
                onClick={() => {
                  setShowFirst(!showFirst);
                }}
              >
                <SmallHeader
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Dashboard Items
                </SmallHeader>
                <div>
                  {showFirst ? (
                    <AiFillMinusCircle
                      style={{ color: colors.primary, fontSize: "20px" }}
                    />
                  ) : (
                    <AiFillPlusCircle
                      style={{ color: colors.primary, fontSize: "20px" }}
                    />
                  )}
                </div>
              </div>
              {showFirst && (
                <div>
                  <div>
                    <div>
                      {dashboardItems && dashboardItems.length > 0 ? (
                        dashboardItems.map((category) => (
                          <div key={category.categoryId}>
                            <div>
                              {category.categoryItems.map((department) => (
                                <div key={department.departmentId}>
                                  <h3 className="font-[700] text-[20px] mt-[10px]">
                                    {department.departmentName}
                                  </h3>
                                  <ul
                                    style={{
                                      listStyleType: "none",
                                      padding: 0,
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {department.items.map((item) => (
                                      <li
                                        key={item.id}
                                        style={{
                                          backgroundColor: "#800080",
                                          color: "#fff",
                                          borderRadius: "8px",
                                          marginBottom: "8px",
                                          padding: "12px",
                                          minWidth: "200px",
                                          marginRight: "12px",
                                        }}
                                      >
                                        <div>
                                          <strong>{item.name}</strong>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>No dashboard items found.</p>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div
                style={{
                  width: isTab ? "100%" : "75%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: colors.secondary,
                  borderRadius: "5px",
                  padding: "0.7rem",
                  margin: "0.4rem 0rem",
                }}
                onClick={() => {
                  setShowSecond(!showSecond);
                }}
              >
                <SmallHeader
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Menu Items
                </SmallHeader>
                <div>
                  {showSecond ? (
                    <AiFillMinusCircle
                      style={{ color: colors.primary, fontSize: "20px" }}
                    />
                  ) : (
                    <AiFillPlusCircle
                      style={{ color: colors.primary, fontSize: "20px" }}
                    />
                  )}
                </div>
              </div>
              {showSecond && (
                // <div
                //   style={{
                //     width: isTab ? "100%" : "60%",
                //     padding: "0.4rem",
                //   }}
                // >
                //   {access !== null && (
                //     <>
                //       {access.menuItems.length > 0 && (
                //         <>
                //           {access.menuItems
                //             // Filter unique menu items based on name
                //             .reduce((uniqueMenuItems, menuItem) => {
                //               const existingItem = uniqueMenuItems.find(item => item.name === menuItem.name);
                //               if (!existingItem) {
                //                 uniqueMenuItems.push(menuItem);
                //               }
                //               return uniqueMenuItems;
                //             }, [])
                //             .map((menuItem, index) => (
                //               <div key={index}>
                //                 <div
                //                   style={{
                //                     width: isTab ? "100%" : "75%",
                //                     display: "flex",
                //                     alignItems: "center",
                //                     borderRadius: "5px",
                //                     padding: "0.4rem 0.7rem",
                //                     margin: "0.2rem 0rem",
                //                   }}
                //                 >
                //                   <span style={{ fontSize: "20px", fontWeight: "normal" }}>
                //                     &bull;
                //                   </span>
                //                   <SmallHeader
                //                     style={{ fontWeight: "bold", fontSize: "18px", marginLeft: "0.5rem" }}
                //                   >
                //                     {menuItem.name}
                //                   </SmallHeader>
                //                 </div>
                //                 <div style={{ padding: "0.2rem 1rem", marginLeft: "1rem" }}>
                //                   {/* Render submenu items directly */}
                //                   {access.menuItems
                //                     .filter(submenu => submenu.topMenuId === menuItem.id)
                //                     .map((submenu, subIndex) => (
                //                       <SmallHeader
                //                         key={subIndex}
                //                         style={{
                //                           fontWeight: "bold",
                //                           fontSize: "15px",
                //                           marginLeft: "0.5rem",
                //                         }}
                //                       >
                //                         &bull; {submenu.name}
                //                       </SmallHeader>
                //                     ))}
                //                 </div>
                //               </div>
                //             ))}
                //         </>
                //       )}
                //     </>
                //   )}

                // </div>

                <div>
                  <ul>{renderMenu(menuItems)}</ul>
                </div>
              )}

              <div
                style={{
                  width: isTab ? "100%" : "75%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: colors.secondary,
                  borderRadius: "5px",
                  padding: "0.7rem",
                  margin: "0.4rem 0rem",
                }}
                onClick={() => {
                  setShowFourth(!showFourth);
                }}
              >
                <SmallHeader
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Activities
                </SmallHeader>
                <div>
                  {showFourth ? (
                    <AiFillMinusCircle
                      style={{ color: colors.primary, fontSize: "20px" }}
                    />
                  ) : (
                    <AiFillPlusCircle
                      style={{ color: colors.primary, fontSize: "20px" }}
                    />
                  )}
                </div>
              </div>
              {showFourth && (
                <div
                  style={{
                    width: isTab ? "100%" : "60%",
                    padding: "0.4rem",
                  }}
                >
                  {access.privileges.length === 2 ||
                  access.privileges.length === 3 ? (
                    <SmallHeader></SmallHeader>
                  ) : (
                    access.privileges.map((item, index) => (
                      <SubMenuDiv style={{ width: "100%" }} key={index}>
                        <SmallHeader
                          style={{ fontSize: "13px", fontWeight: "bold" }}
                        >
                          {item.name}
                        </SmallHeader>
                      </SubMenuDiv>
                    ))
                  )}

                  <div style={{ marginTop: "1rem" }}>
                    <SmallHeader
                      style={{
                        fontSize: "13px",
                        fontWeight: "bold",
                        paddingLeft: "13px",
                      }}
                    >
                      SELECTED SCHOOL
                    </SmallHeader>
                    {selectedSchools.length > 0 ? (
                      selectedSchools.map((school, index) => (
                        <MenuItem
                          key={index}
                          sx={{
                            fontFamily: "Gilroy-Regular",
                            fontWeight: "bold",
                            fontSize: "18px",
                          }}
                          value={school.schoolid}
                        >
                          <li>{school.schoolname}</li>
                        </MenuItem>
                      ))
                    ) : (
                      <div>No selected schools</div>
                    )}
                  </div>

                  <div style={{ marginTop: "1rem" }}>
                    <SmallHeader
                      style={{
                        fontSize: "13px",
                        fontWeight: "bold",
                        paddingLeft: "13px",
                      }}
                    >
                      SELECTED DEPARTMENTS
                    </SmallHeader>
                    {selectedDepartments.length > 0 ? (
                      selectedDepartments.map((department, index) => (
                        <MenuItem
                          key={index}
                          sx={{
                            fontFamily: "Gilroy-Regular",
                            fontWeight: "bold",
                            fontSize: "18px",
                          }}
                          value={department.departmentId}
                        >
                          <li>{department.departmentName}</li>
                        </MenuItem>
                      ))
                    ) : (
                      <div>No selected departments</div>
                    )}
                  </div>
                </div>
              )}

              <div
                style={{
                  width: isTab ? "100%" : "75%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: colors.secondary,
                  borderRadius: "5px",
                  padding: "0.7rem",
                  margin: "0.4rem 0rem",
                }}
                onClick={() => {
                  setShowThird(!showThird);
                }}
              >
                <SmallHeader
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Rights
                </SmallHeader>
                <div>
                  {showThird ? (
                    <AiFillMinusCircle
                      style={{ color: colors.primary, fontSize: "20px" }}
                    />
                  ) : (
                    <AiFillPlusCircle
                      style={{ color: colors.primary, fontSize: "20px" }}
                    />
                  )}
                </div>
              </div>
              {showThird && (
                <div
                  style={{
                    width: isTab ? "100%" : "60%",
                    padding: "0.4rem",
                  }}
                >
                  {/* <InputWrapper>
                    <label
                      htmlFor=""
                      style={{
                        color: colors.gray,
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginTop: "0.4rem",
                      }}
                    >
                      {" "}
                      Activities
                    </label>
                  </InputWrapper> */}
                  {/* {access?.rights && access.rights.length > 0 ? (
                    access.rights.map((item, index) => (
                      <SubMenuDiv style={{ width: "100%" }} key={index}>
                        <div></div>
                        <SmallHeader
                          style={{ fontSize: "13px", fontWeight: "bold" }}
                        >
                          Input rights here
                          {item.name}
                        </SmallHeader>
                        <AiFillCloseCircle
                          style={{ color: "green", fontSize: "19px" }}
                        />
                      </SubMenuDiv>
                    ))
                  ) : (
                    <SmallHeader>
                      No Activities For This Staff</SmallHeader>
                  )} */}

                  {access?.rights?.length > 0 ? (
                    access.rights
                      .filter((item) => item !== null)
                      .map((item, index) => (
                        <SubMenuDiv style={{ width: "100%" }} key={index}>
                          <div></div>
                          <SmallHeader
                            style={{ fontSize: "13px", fontWeight: "bold" }}
                          >
                            {item.name}
                          </SmallHeader>
                        </SubMenuDiv>
                      ))
                  ) : (
                    <SmallHeader>
                      No dashboard Items For Account Access
                    </SmallHeader>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </TabContentWrapper>
  );
};

UserInformation.propTypes = {
  data: PropTypes.object,
};

UserInformation.defaultProps = {
  data: null,
};

export default UserInformation;
