import React from "react";
import Title from "../../components/Dashboard/Title";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import ParentStudentLink from "../../components/Parent/ParentStudentLink";
import { Button, Avatar } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CreateLesson from "../../components/LessonNote/CreateLesson";

export const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
  },
});

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LessonNote = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <ThemeProvider theme={darkTheme}>
      <Box>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label=" Create Lesson Note"
                {...a11yProps(0)}
                sx={{
                  textTransform: "initial",
                  fontFamily: "Gilroy-Medium",
                  fontSize: "18px",
                }}
              />
              <Tab
                label="Comment & Endorsement"
                {...a11yProps(1)}
                sx={{
                  textTransform: "initial",
                  fontFamily: "Gilroy-Medium",
                  fontSize: "18px",
                }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <CreateLesson />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ParentStudentLink />
          </TabPanel>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default LessonNote;
