/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
const endpoint = process.env.REACT_APP_API_ENDPOINT_ONE;
const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
const api_version = process.env.REACT_APP_API_VERSION;

export const getAllStudent = (token, schoolId, currentPage, pageSize) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/StudentManagement/all-student/${schoolId}?currentPage=${currentPage}&pageSize=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const getReadOnly = (token, schoolId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/StudentManagement/GetStudentReadOnlyField?SchoolId=${schoolId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const declineStudent = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/StudentManagement/CreateStudnt/Decline`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const approveStudent = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/StudentManagement/Approve-Student`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const createStudent = (token, data, type) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/StudentManagement/${type}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          authToken: `${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const updateStudent = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/StudentManagement/Update-Student-BioData`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          authToken: `${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const getLinkedUnLinkStudents = (token, schoolId, classId, filter) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/StudentManagement/StudentList?schoolId=${schoolId}&classId=${classId}&filter=${filter}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export default {
  studentFilterSearch: (token, schoolId, currentPage, pageSize, filter) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/StudentManagement/all-student-filter?currentPage=${currentPage}&schoolId=${schoolId}&pageSize=${pageSize}&fliter=${filter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
};

export const allParents = (token) =>
  axios
    .get(`${endpoint_two}/api/v${api_version}/ParentManagement/AllParents`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response);
export const createParent = (token, data) =>
  axios
    .post(`${endpoint_two}/api/v${api_version}/ParentManagement/Create`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response);

export const getSubjectStudentList = (token, schoolId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/StudentManagement/Subject-StudentList?schoolId=${schoolId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const AssignSubject = (token, arr) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/StudentManagement/Assign-Subject`,
      {
        selectedStudents: arr,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const linkedParents = (token, filter, type) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/ParentManagement/${type}?filters=${filter}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const parentAllStudent = (token) =>
  axios
    .get(`${endpoint_two}/api/v${api_version}/ParentManagement/AllStudents`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response);

export const approveParent = (token, id) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/ParentManagement/Approve?PhoneNumber=${id}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const deleteParent = (token, id) =>
  axios
    .delete(
      `${endpoint_two}/api/v${api_version}/ParentManagement/Delete?PhoneNumber=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const getParentProfile = (token, id) =>
  axios
    .get(`${endpoint}/api/v${api_version}/ParentProfile?PhoneNumber=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response);

export const linkUnlinkStudent = (
  token,
  schoolId,
  parentPhone,
  studentPhone,
  type
) =>
  axios
    .patch(
      `${endpoint_two}/api/v${api_version}/ParentManagement/${type}?schoolId=${schoolId}&StudentPhoneNumber=${studentPhone}&ParentPhoneNumber=${parentPhone}`,
      null,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const parentStudent = (token, id) =>
  axios
    .get(
      `${endpoint}/api/v${api_version}/ParentProfile/student-list?PhoneNumber=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const studentParents = (token, id) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/ParentManagement/Student-Parent?StudentPhoneNumber=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const studentLinkedParents = (token, id) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/ParentManagement/Student/LinkedParents?StudentPhoneNumber=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const parentLinkedStudents = (token, id) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/ParentManagement/Parent/LinkedStudents?ParentPhoneNumber=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const GetStudentMedicalInfo = (token, studentId, schoolId, infoType) =>
  axios
    .get(
      `${endpoint}/api/v${api_version}/StudentMedical/${infoType}?studentId=${studentId}&schoolId=${schoolId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const createStudentList = (token, schoolId, query) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/StudentManagement/CreateStudnt/List?schoolId=${schoolId}&filter=${query}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const submitApplication = (token, studentId, schoolId) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/StudentManagement/SubmitAdmission?studentId=${studentId}&schoolId=${schoolId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const getResults = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/Results/CheckResult/Result-View?schoolId=${data.schoolId}&StudentPhoneNumber=${data.phoneNumber}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
export const viewResult = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/Results/CheckResult/Term`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const getSpreadSheet = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/Results/Report/SpreadSheet`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const getCummSpreadSheet = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/Results/Report/CumulativeSpreadSheet`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

export const getSubjectReport = (token, classId, sessionId, schoolId) =>
  axios
    .get(
      `${endpoint_two}/api/v${api_version}/StudentManagement/GetAssignedTutorSubjects?classId=${classId}&sessionId=${sessionId}&schoolId=${schoolId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);

//update below
export const getAttendanceReport = (token, data) =>
  axios
    .post(
      `${endpoint_two}/api/v${api_version}/StudentManagement/GetClassAttendanceRecords`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
