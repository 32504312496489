import { RxPerson } from "react-icons/rx";
import { AiOutlineFile } from "react-icons/ai";
import { BsPeople } from "react-icons/bs";

import { MdMedicalServices, MdOutlineSchool } from "react-icons/md";

export const singleStudentData = [
  {
    id: 1,
    label: "Profile",
    icon: <RxPerson />,
  },
  {
    id: 2,
    label: "Result",
    icon: <MdOutlineSchool />,
    admitted: true,
  },

  {
    id: 3,
    label: "Medical",
    icon: <MdMedicalServices />,
    admitted: true,
  },
  {
    id: 5,
    label: "Parents",
    icon: <BsPeople />,
    admitted: true,
  },
  {
    id: 4,
    label: "Ledger",
    icon: <AiOutlineFile />,
    admitted: true,
  },
];

export const nonAdmittedData = [
  {
    id: 1,
    label: "Profile",
    icon: <RxPerson />,
  },
  {
    id: 5,
    label: "Parents",
    icon: <BsPeople />,
    admitted: true,
  },
];
