import React, { useEffect, createRef } from "react";
import { useCallback, useState } from "react";
import colors from "../../../assets/colors.json";
import Modal from "../../modal/Modal";
import { useSelector } from "react-redux";
import Pagination from "../../../components/pagination_one/pagination";
import { checkAccess, isNumber, paginateData } from "../../../services/utils";
import { BsSearch } from "react-icons/bs";
import NewSelect from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";

import {
  TabContainer,
  AccountSummaryWrapper,
  BigHeader,
  Search,
  Icon,
  MenuDiv,
  Input,
  SmallHeader,
  InlineFormDiv,
} from "../../../pages/Dashboard/styles/dashboard.style";

import {
  InputWrapper,
  SmallText,
  AuthButton,
} from "../../../pages/auth/Login.style";
import api from "../../../services/ControlPanel";
import Loader from "../../pageLoader/loader";
import FormLoader from "../../FormLoader";
import ErrorService from "../../../services/errorService";
import { useMediaQuery } from "react-responsive";
const Questions = () => {
  const [id, setId] = useState("");

  const [formLoading, setFormLoading] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const [loadSub, setLoadSub] = useState(true);
  const [sQuery, setSQuery] = useState("");
  const [topics, setTopics] = useState([]);
  const { admToken, accessObj } = useSelector((state) => state.auth);
  const [list, setList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  let __obj = { id: "", data: [] };
  const [obj, setObj] = useState(__obj);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffSet, setitemOffSet] = useState(0);
  const onPickFileRef = createRef();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const pickFile = (ref) => {
    ref.current.click();
  };
  const [fileName, setFileName] = useState("");
  const onPickFile = (event) => {
    if (event !== null) {
      const files = event.target.files;
      let filename = files[0].name;
      if (
        filename.includes(".jpg") ||
        filename.includes(".png") ||
        filename.includes(".jpeg")
      ) {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          setStepObj({
            ...stepObj,
            stepPictureBase64: fileReader.result,
          });
          setFileName(filename);
        });
        fileReader.readAsDataURL(files[0]);
      } else {
        alert("Please Upload A Photo");
      }
    }
  };
  const getDataFunc = useCallback(async () => {
    try {
      const res = await api.getTopicsQuestions(admToken, "GetTopics");
      console.log(res);
      res.status && res.statusCode === 2 && setTopics(res.data);
      (res.status === 500 || res.status === 400) &&
        ErrorService.displayErrorAlert(res.data);
      //  setLoadSub(false);
    } catch (error) {
      console.log(error);
      //  setLoadSub(false);
    }
  }, [admToken]);
  const getUserGuides = useCallback(async () => {
    try {
      setLoadSub(true);
      const res = await api.getTopicsQuestions(admToken, "allquestions");
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setAllData(res.data);
        // if (res.data.length > 0) {
        //   setShowModal(true);
        // }
      }
      setLoadSub(false);
    } catch (error) {
      console.log(error.message);
      setLoadSub(false);
    }
  }, [admToken]);
  const filterSearch = async (e) => {
    let arr = [];
    console.log(arr);
    const queryRegex = new RegExp(e.target.value, "i");
    allData.forEach((item) => {
      Object.values(item).forEach((val) =>
        queryRegex.test(val) ? arr.push(item) : ""
      );
    });
    console.log(arr);
    setList(arr);
    setPageCount(0);
    // }
  };
  useEffect(() => {
    paginateData(
      allData,
      setList,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allData, currentPage, itemOffSet]);
  const resetData = useCallback(() => {
    setCurrentPage(1);
    console.log("reset data working");
    paginateData(allData, setList, 1, itemOffSet, setitemOffSet, setPageCount);
  }, [allData, itemOffSet]);
  const _obj = {
    id: 0,
    topicId: "",
    question: "",
    accType: "",
  };
  const [formObj, setFormObj] = useState(_obj);

  useEffect(() => {
    getUserGuides();
    getDataFunc();
  }, [getUserGuides, getDataFunc]);
  const performAction = async () => {
    try {
      setFormLoading(true);
      const res = await api.addTopicQuestion(admToken, formObj, "AddQuestion");
      setFormLoading(false);
      if (res.status && res.statusCode === 2) {
        toast.success(res.message) && setShowModal(false);
        getUserGuides();
      }

      !res.status && toast.error(res.message);
      (res.status === 400 || res.status === 500) &&
        ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      setFormLoading(false);
    }
  };
  const getLink = useCallback(
    async (id, accType) => {
      setViewLoading(true);
      try {
        const res = await api.getUserGuideLink(admToken, accType, id);
        console.log(res);
        if (res.status && res.statusCode === 2) {
          if (Array.isArray(res.data) && res.data.length > 0) {
            setObj({ ...obj, id, data: res.data });
          }
          setViewModal(true);
        }
        setViewLoading(false);
      } catch (error) {
        setViewLoading(false);

        console.log(error);
      }
    },
    [admToken, obj]
  );

  const delQuestion = useCallback(
    async (id) => {
      try {
        setFormLoading(true);
        const res = await api.deleteTopicStepQuestion(
          admToken,
          "DeleteQuestion",
          id
        );

        setFormLoading(false);
        if (res.status && res.statusCode === 2) {
          toast.success(res.message);
          resetData();
          getUserGuides();
        }

        if (res.status === 500 || res.status === 400 || res.status === false) {
          ErrorService.displayErrorAlert(res);
        }
      } catch (error) {
        setFormLoading(false);
        toast.error("An Error Occur!");
      }
    },
    [admToken, getUserGuides, resetData]
  );

  const performStep = async () => {
    try {
      setStepLoading(true);
      const res = await api.addTopicQuestion(admToken, stepObj, "AddStep");
      setStepLoading(false);
      res.status &&
        res.statusCode === 2 &&
        toast.success(res.message) &&
        setShowForm(false);
      res.status &&
        res.statusCode === 2 &&
        getLink(stepObj.questionId, formObj.accType);
      !res.status && toast.error(res.message);
      res.status === 400 && ErrorService.displayErrorAlert(res);
      res.status === 500 && ErrorService.displayErrorAlert(res);
    } catch (error) {
      setStepLoading(false);
    }
  };

  const [showForm, setShowForm] = useState(false);
  const [stepLoading, setStepLoading] = useState(false);
  const _objStep = {
    id: 0,
    questionId: 0,
    stepPictureBase64: "",
    step: "",
  };
  const [stepObj, setStepObj] = useState(_objStep);

  const delStep = useCallback(
    async (id) => {
      try {
        setFormLoading(true);
        const res = await api.deleteTopicStepQuestion(
          admToken,
          "DeleteStep",
          id
        );

        setFormLoading(false);
        if (res.status && res.statusCode === 2) {
          toast.success(res.message);
          getLink(formObj.id, formObj.accType);
        }

        if (res.status === 500 || res.status === 400 || res.status === false) {
          ErrorService.displayErrorAlert(res);
        }
      } catch (error) {
        setFormLoading(false);
        toast.error("An Error Occur!");
      }
    },
    [admToken, formObj.accType, formObj.id, getLink]
  );

  // formObj is controlling it
  // formObj.id question id
  // formObj.question is the topic
  const stepContent = (
    <>
      <SmallHeader
        style={{
          textAlign: "center",
          fontWeight: "bold",
          background: colors.primary,
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          padding: "1.4rem",
          color: "white",
        }}
      >
        {stepObj.id > 0 ? "Update" : "Add"} Step For {formObj.question}
      </SmallHeader>{" "}
      <div style={{ display: "flex", justifyContent: "end" }}>
        <span
          style={{
            color: colors.primary,
            cursor: "pointer",
            fontWeight: "bold",
            padding: "0.4rem 0.8rem",
          }}
          onClick={() => setShowForm(false)}
        >
          Go Back
        </span>
      </div>
      <InputWrapper>
        <label htmlFor="">Step</label>
        <input
          type="text"
          disabled={stepObj.id > 0}
          onKeyDown={(e) => isNumber(e)}
          value={stepObj.step}
          placeholder="Step"
          onChange={(e) =>
            setStepObj({ ...stepObj, step: Number(e.target.value) })
          }
        />
      </InputWrapper>
      <InlineFormDiv width="90%" jc="space-between">
        <div
          style={{
            width: isMobile ? "100%" : "70%",
          }}
        >
          <input
            type="file"
            style={{
              display: "none",
            }}
            onChange={(e) => onPickFile(e)}
            ref={onPickFileRef}
          />
          <InputWrapper>
            <label htmlFor="filename">Upload Logo</label>
            <input
              type="text"
              value={fileName}
              disabled={true}
              placeholder="File Name"
            />
          </InputWrapper>
        </div>
        <AuthButton
          bg={colors.gray}
          width={isMobile ? "100%" : "20%"}
          onClick={() => pickFile(onPickFileRef)}
        >
          Upload
        </AuthButton>
      </InlineFormDiv>
      <div
        style={{
          marginTop: "10px",
          width: "100%",
          display: "flex",
          position: "relative",
          justifyContent: "center",
        }}
      >
        {stepObj.stepPictureBase64.length > 0 && (
          <>
            <span
              style={{
                backgroundColor: "red",
                cursor: "pointer",
                color: "white",
                padding: "0.4rem 0.4rem",
                position: "absolute",
                right: "5px",
                top: "10px",
                borderRadius: "20px",
              }}
            >
              X
            </span>
            <img
              src={stepObj.stepPictureBase64}
              alt=""
              style={{ maxHeight: "250px", width: "90%" }}
            />
          </>
        )}
      </div>
      <AuthButton
        onClick={performStep}
        width="100%"
        mt="0.6rem"
        disabled={
          stepLoading || stepObj.step === "" || stepObj.stepPictureBase64 === ""
        }
      >
        {stepLoading ? (
          <FormLoader />
        ) : stepObj.id > 0 ? (
          "Update Step"
        ) : (
          "Add Step"
        )}
      </AuthButton>
    </>
  );

  const viewContent = (
    <>
      <SmallHeader
        style={{
          textAlign: "center",
          fontWeight: "bold",
          background: colors.primary,
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          padding: "1.4rem",
          color: "white",
        }}
      >
        View Steps For {formObj.question}
      </SmallHeader>{" "}
      <MenuDiv style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
        {obj &&
          obj.data.length > 0 &&
          obj.data.map((i, ind) => (
            <div
              key={ind}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "0.3rem",
                padding: "0rem 0.9rem",
              }}
            >
              <SmallText color={colors.primary} style={{ fontWeight: "bold" }}>
                Step {i.steps}
              </SmallText>
              {/* <div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "0.3rem",
                  padding: "0rem 0.9rem",
                }}
              >
                {checkAccess(accessObj.rights, "Edit") && (
                  <button
                    style={{
                      backgroundColor: colors.primary,
                      color: "white",
                      padding: "8px 12px",
                      border: `1px solid white`,
                      borderRadius: "12px",
                      marginRight: "0.2rem",
                    }}
                    onClick={() => {
                      setFileName("");
                      setStepObj({
                        ...stepObj,
                        step: i.steps,
                        id: i.stepId,
                        questionId: formObj.id,
                        stepPictureBase64: "",
                      });
                      setShowForm(true);
                    }}
                  >
                    Edit
                  </button>
                )}
                <a
                  href={i.link}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    borderRadius: "10px",
                    color: colors.primary,
                    border: `1px solid ${colors.primary}`,
                    backgroundColor: "transparent",
                    padding: "0.3rem 0.9rem",
                    fontWeight: "bold",
                    marginRight: "0.2rem",
                  }}
                >
                  Doc Link
                </a>

                {checkAccess(accessObj.rights, "Delete") && (
                  <button
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      padding: "8px 12px",
                      border: `1px solid white`,
                      borderRadius: "12px",
                      marginRight: "0.2rem",
                    }}
                    onClick={() => {
                      setId(i.stepId);
                      delStep(i.stepId);
                    }}
                  >
                    {formLoading && id === i.stepId ? (
                      <FormLoader />
                    ) : (
                      " Delete"
                    )}
                  </button>
                )}
              </div>

              {/* </div> */}
            </div>
          ))}
      </MenuDiv>
      {checkAccess(accessObj.rights, "Create") && (
        <AuthButton
          width="100%"
          onClick={() => {
            setFileName("");
            setStepObj({
              ...stepObj,
              step: "",
              questionId: formObj.id,
              stepPictureBase64: "",
            });

            setShowForm(true);
          }}
        >
          Add Step
        </AuthButton>
      )}
    </>
  );

  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }}>
        {" "}
        {formObj.id > 0 ? "Update" : "Add"} Question
      </BigHeader>

      <InputWrapper>
        <label htmlFor="">Question</label>
        <input
          type="text"
          value={formObj.question}
          placeholder="Question"
          onChange={(e) => setFormObj({ ...formObj, question: e.target.value })}
        />
      </InputWrapper>
      <FormControl fullWidth sx={{ marginTop: "0.9rem" }}>
        <label htmlFor="">Select Topic</label>

        <NewSelect
          sx={{ fontFamily: "Gilroy-Regular", marginTop: "0.4rem" }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          variant="outlined"
          size="small"
          value={formObj.topicId}
          onChange={(e) => {
            setFormObj({ ...formObj, topicId: e.target.value });
            // setSchool(e.target.value);
          }}
        >
          <MenuItem
            sx={{ fontFamily: "Gilroy-Regular" }}
            value={""}
            selected
            disabled
          >
            Please Select Topic
          </MenuItem>
          {topics.length > 0 &&
            topics.map((item, index) => (
              <MenuItem
                key={index}
                sx={{ fontFamily: "Gilroy-Regular" }}
                value={item.id}
              >
                {item.name}
              </MenuItem>
            ))}
        </NewSelect>
      </FormControl>
      <div>
        <AuthButton
          onClick={performAction}
          width="100%"
          mt="0.6rem"
          disabled={formLoading || formObj.question === ""}
        >
          {formLoading ? (
            <FormLoader />
          ) : formObj.id > 0 ? (
            "Update Question"
          ) : (
            "Create Question"
          )}
        </AuthButton>
      </div>
    </>
  );

  if (loadSub) {
    return <Loader />;
  }
  return (
    <>
      <div style={{ display: "flex", justifyContent: "end" }}>
        {checkAccess(accessObj.rights, "Create") && (
          <AuthButton
            onClick={() => {
              setFormObj(Object.assign({}, _obj));
              setShowModal(!showModal);
            }}
          >
            Add Question
          </AuthButton>
        )}
      </div>

      {viewModal && (
        <Modal
          content={!showForm ? viewContent : stepContent}
          show={viewModal}
          size={"large"}
          onClose={setViewModal}
          iColor={"white"}
          pd={"10px"}
        />
      )}
      {showModal && (
        <Modal
          content={content}
          show={showModal}
          // iColor={"white"}
          size={"large"}
          onClose={setShowModal}
        />
      )}

      <TabContainer style={{ borderRadius: "10px", paddingBottom: "0px" }}>
        <AccountSummaryWrapper pt="0px">
          <table>
            <tr
              className="table-head"
              style={{ backgroundColor: colors.primary, color: "white" }}
            >
              <th></th>
              <th>Question ({allData.length > 0 && allData.length}) </th>
              {/* <th></th> */}
              <th>Topic</th>

              <th>
                <Input style={{ width: "100%" }}>
                  <Icon>
                    <BsSearch color={colors.primary} />
                  </Icon>
                  <Search
                    style={{ background: "white" }}
                    value={sQuery}
                    type="type"
                    placeholder="Search For Question"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setSQuery(e.target.value);
                      if (e.target.value.length > 3) {
                        console.log("inside");
                        filterSearch(e);
                      }

                      if (e.target.value === "") {
                        resetData();
                      }
                    }}
                  />
                </Input>
              </th>
            </tr>
            {list.length > 0 ? (
              list.map((item, index) => (
                <tr key={index}>
                  <td> {index + 1}.</td>
                  <td>
                    {item.question === null ? "No Question" : item.question}
                  </td>
                  <td>{item.topic}</td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      {checkAccess(accessObj.rights, "Edit") && (
                        <button
                          onClick={() => {
                            setFormObj({
                              ...formObj,
                              id: item.id,
                              question: item.question,
                              topicId: item.topicId,
                            });
                            setShowModal(true);
                          }}
                          style={{
                            backgroundColor: colors.primary,
                            color: "white",
                            padding: "8px 12px",
                            border: `1px solid white`,
                            borderRadius: "12px",
                            marginRight: "0.2rem",
                          }}
                        >
                          Edit
                        </button>
                      )}
                      <button
                        disabled={viewLoading && item.id === formObj.id}
                        onClick={() => {
                          setFormObj({
                            ...formObj,
                            id: item.id,
                            question: item.question,
                            topicId: item.topicId,
                            accType: item.accountType,
                          });
                          setShowForm(false);
                          setObj(Object.assign({}, __obj));
                          getLink(item.id, item.accountType);
                        }}
                        style={{
                          backgroundColor: colors.primary,
                          color: "white",
                          padding: "8px 12px",
                          border: `1px solid white`,
                          borderRadius: "12px",
                          marginRight: "0.2rem",
                        }}
                      >
                        View Steps
                      </button>
                      {checkAccess(accessObj.rights, "Delete") && (
                        <button
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            padding: "8px 12px",
                            border: `1px solid white`,
                            borderRadius: "12px",
                            marginRight: "0.2rem",
                          }}
                          onClick={() => {
                            setId(item.id);
                            delQuestion(item.id);
                          }}
                        >
                          {formLoading && id === item.id ? (
                            <FormLoader />
                          ) : (
                            " Delete"
                          )}
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} style={{ textAlign: "center" }}>
                  There Are No Subjects Available
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>

      {pageCount > 1 && (
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            range={3}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </>
  );
};

export default Questions;
