import { useState, createRef, useEffect, useCallback, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import Loader from "../../components/pageLoader/loader";
import colors from "../../assets/colors.json";
import { MuiColorInput } from "mui-color-input";
import {
  InlineFormDiv,
  LogoImgSetup,
  PageWrapper,
  SlidingImg,
  FormFlex,
  FormFlexDiv,
  SmallHeader,
} from "../Dashboard/styles/dashboard.style";
import { AuthButton } from "../auth/Login.style";
import { InputWrapper } from "../auth/Login.style";
import Avatarr from "../../assets/Images/avatar.jpg";
import { toast } from "react-toastify";
import { Divider } from "@mui/material";
import ErrorService from "../../services/errorService";
import { useMediaQuery } from "react-responsive";
import {
  postSchoolDetails,
  getSchoolWebsiteInfo,
  deleteSchoolDetails,
  postSchoolStampAndSign,
} from "../../services/MySchool";
import { useSelector } from "react-redux";
import FormLoader from "../../components/FormLoader";
import { checkAccess } from "../../services/utils";
import { getSchoolStampAndSignature } from "../../services/MySchool";

const WebsiteInfo = () => {
  const onSchoolLogoRef = createRef();
  const onSchoolStampRef = createRef();
  const onSignatureRef = createRef();
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [logoFileName, setLogoFileName] = useState("");
  const [logoBase64, setLogoBase64] = useState("");
  const [stampFileName, setStampFileName] = useState("");
  const [stampBase64, setStampBase64] = useState("");
  const [signatureFileName, setSignatureFileName] = useState("");
  const [signatureBase64, setSignatureBase64] = useState("");
  const [expanded, setExpanded] = useState(false);
  const isPhone = useMediaQuery({ maxWidth: 768 });
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [boardImg, setBoardImg] = useState("");
  const onPickBoardFileRef = createRef();
  const [infoData, setInfoData] = useState(null);
  const [stampSignData, setStampSignData] = useState(null);
  const myRef = useRef();
  const myReff = useRef();

  let _obj = {
    fullName: "",
    schoolId: user.schoolId,
    position: "",
    profileImage: "",
    address: "",
  };
  const [boardObj, setBoardObj] = useState(_obj);

  let _newObj = {
    question: "",
    answer: "",
    schoolId: user.schoolId,
  };
  const [newsObj, setNewsObj] = useState(_newObj);

  const isNumber = (evt) => {
    // console.log(evt);
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const pickFile = (ref) => {
    ref.current.click();
  };

  const onPickImage = (event, type) => {
    if (event !== null) {
      const files = event.target.files;
      let filename = files[0]?.name;
      type === "logo" && setLogoFileName(filename);
      type === "stamp" && setStampFileName(filename);
      type === "signature" && setSignatureFileName(filename);
      if (
        filename.includes(".jpg") ||
        filename.includes(".png") ||
        filename.includes(".jpeg")
      ) {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          type === "logo" && setLogoBase64(fileReader.result);
          type === "stamp" && setStampBase64(fileReader.result);
          type === "signature" && setSignatureBase64(fileReader.result);
        });
        fileReader.readAsDataURL(files[0]);
      } else {
        alert("Please Upload A Photo");
      }
    }
  };
  const onPickFile = (event, setFunc, setName = null, type) => {
    const files = event.target.files;
    type === "logo" && setLogoFileName(files[0]?.name);
    type === "stamp" && setStampFileName(files[0]?.name);
    type === "signature" && setSignatureFileName(files[0]?.name);
    let filename = files[0]?.name;
    setName !== null && setName(filename);
    if (
      filename.includes(".jpg") ||
      filename.includes(".png") ||
      filename.includes(".jpeg")
    ) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        if (setFunc === "board") {
          setBoardObj({ ...boardObj, profileImage: fileReader.result });
        } else {
          setFunc(fileReader.result);
        }

        // setFunc(fileReader.result);
      });
      fileReader.readAsDataURL(files[0]);
    } else {
      alert("Please Upload A Photo");
    }

    console.log("We are working");
  };
  const getWebsiteInfo = useCallback(async () => {
    console.log();
    setLoading(true);
    try {
      const res = await getSchoolWebsiteInfo(admToken, user.schoolId);
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setInfoData(res.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [admToken, user.schoolId]);

  const getStampSign = useCallback(async () => {
    try {
      let res = await getSchoolStampAndSignature(admToken, user.schoolId);
      if (res.status && res.statusCode === 2) {
        setStampSignData(res.data);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      // toast.error("Error!, Try Again Later");
      setLoading(false);
      console.log("something is wrong");
    }
  }, [admToken, user.schoolId]);

  const isObjEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === "") {
        return false;
      }
    }
    return true;
  };
  const deleteItem = useCallback(
    async (id, schoolId) => {
      setDeleteLoading(true);
      try {
        const res = await deleteSchoolDetails(
          id,
          admToken,
          schoolId,
          "Delete-Team"
        );
        if (res.status && res.statusCode === 2) {
          toast.success(res.message, {
            autoClose: 4000,
            position: toast.POSITION.BOTTOM_LEFT,
          });
          // setFile({ ...file, name: "", base64: "" });
          getWebsiteInfo();
        }
        res.status === 500 && ErrorService.displayErrorAlert(res.data);
        res.status === 400 && ErrorService.displayErrorAlert(res.data);
        setDeleteLoading(false);
      } catch (error) {
        setDeleteLoading(false);
        console.log(error);
      }
    },
    [admToken, getWebsiteInfo]
  );
  const updateSchoolDetails = async (obj, type) => {
    let formIsValid = isObjEmpty(obj);
    console.log(obj);
    if (!formIsValid) {
      return toast.error("Please Make Sure You Fill All The Form");
    }
    try {
      setFormLoading(true);
      const res = await postSchoolDetails(admToken, obj, type);
      res.status &&
        res.statusCode === 2 &&
        toast.success(res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        });
      if (res.status && res.statusCode === 2) {
        setLogoFileName("");
        setLogoBase64("");
        resetBoardObj();
        getWebsiteInfo();
      }

      (res.status === 400 || res.status === 500) &&
        ErrorService.displayErrorAlert(res.data);
      setFormLoading(false);
    } catch (error) {
      console.log(error);
      setFormLoading(false);
      toast.error("An Error Ocurred Try Again Later");
    }
  };
  const postColor = async () => {
    let data = {
      colorCode: infoData?.school?.colorValue,
      schoolId: user.schoolId,
    };
    updateSchoolDetails(data, "Save-School-Color");
  };
  const postSchoolLogo = async () => {
    let data = {
      logoBase64Content: logoBase64,
      schoolId: user.schoolId,
    };
    updateSchoolDetails(data, "Change-School-Logo");
  };
  const addSchoolTeam = async () => {
    updateSchoolDetails(boardObj, "Add-School-Team");
  };
  const submitFAQs = async () => {
    // let data = {
    //   ...newsObj,
    //   schoolId: Number(schoolId),
    // };
    updateSchoolDetails(newsObj, "Add-School-FAQ");
  };
  const submitContact = async () => {
    updateSchoolDetails(infoData?.contactUs, "Add-School-ContactUs");
  };
  const postSchoolAbout = async () => {
    updateSchoolDetails(infoData?.aboutUs, "Add-School-About");
  };

  const postSchoolStampSign = async (type) => {
    const data = {
      stamp: type === "stamp" ? stampBase64 : "",
      signature: type === "signature" ? signatureBase64 : "",
      schoolId: user.schoolId,
    };
    try {
      setFormLoading(true);
      const res = await postSchoolStampAndSign(admToken, data);
      res.status &&
        res.statusCode === 2 &&
        toast.success(res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        });
      if (res.status && res.statusCode === 2) {
        setStampFileName("");
        setStampBase64("");
        setSignatureFileName("");
        setSignatureBase64("");
        resetBoardObj();
        getWebsiteInfo();
      }

      (res.status === 400 || res.status === 500) &&
        ErrorService.displayErrorAlert(res.data);
      setFormLoading(false);
    } catch (error) {
      console.log(error);
      setFormLoading(false);
      toast.error("An Error Ocurred Try Again Later");
    }
  };

  useEffect(() => {
    if (user.schoolId > 0) {
      getWebsiteInfo();
      getStampSign();
    }
  }, [getStampSign, getWebsiteInfo, user.schoolId]);

  const resetBoardObj = () => {
    setBoardImg("");
    setBoardObj(_obj);
    setNewsObj(_newObj);
  };

  useEffect(() => {
    if (
      boardObj.fullName !== "" &&
      boardObj.profileImage !== "" &&
      boardObj.position !== "" &&
      boardObj.address !== ""
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }

    // if (
    //   contactObj.address !== "" &&
    //   contactObj.email !== "" &&
    //   contactObj.phoneNumber !== "" &&
    //   contactObj.schoolAdminContact !== "" &&
    //   contactObj.helpLine !== "" &&
    //   contactObj.socialMedial !== ""
    // ) {
    //   // setContactForm(true);
    //   setFormIsValid(true);
    // } else {
    //   setFormIsValid(false);
    // }
  }, [boardObj, boardImg]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box mt={2}>
      {infoData !== null && (
        <div>
          {/* logo */}
          <Accordion
            sx={{ mb: 2, boxShadow: "none" }}
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            style={{
              pointerEvents: formLoading && "none",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    border: "1px solid #fff",
                    borderRadius: "50%",
                    color: "white",
                  }}
                />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ bgcolor: "#671E78", borderRadius: "20px", px: 3 }}
            >
              <Typography
                sx={{
                  width: "33%",
                  flexShrink: 0,
                  color: "#fff",
                  fontFamily: "Gilroy-Bold",
                }}
              >
                Logo
              </Typography>
              <Box
                sx={{
                  width: "60%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Divider sx={{ bgcolor: "#fff" }} />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <InlineFormDiv width="90%" jc="space-between">
                <div
                  style={{
                    width: isPhone ? "100%" : "70%",
                  }}
                >
                  <input
                    type="file"
                    style={{
                      display: "none",
                    }}
                    onChange={(e) => onPickImage(e, "logo")}
                    ref={onSchoolLogoRef}
                  />
                  <InputWrapper>
                    <label htmlFor="filename">Upload Logo</label>
                    <input
                      type="text"
                      onClick={() => pickFile(onSchoolLogoRef)}
                      value={logoFileName}
                      disabled={logoFileName.length > 0}
                      placeholder="File Name"
                    />
                  </InputWrapper>
                </div>
                <AuthButton
                  width={isPhone ? "100%" : "20%"}
                  onClick={() => pickFile(onSchoolLogoRef)}
                >
                  Upload
                </AuthButton>
              </InlineFormDiv>
              <LogoImgSetup>
                <img
                  src={
                    logoBase64.length > 0
                      ? logoBase64
                      : infoData?.school?.logo
                      ? infoData?.school?.logo
                      : Avatarr
                  }
                  alt=""
                />
                {logoBase64.length > 0 && (
                  <span
                    style={{
                      padding: "0.2rem 0.4rem",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setLogoFileName("");
                      setLogoBase64("");
                    }}
                  >
                    X
                  </span>
                )}
              </LogoImgSetup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: `2px solid ${colors.primary}`,
                  paddingBottom: "10px",
                }}
              >
                {checkAccess(accessObj.rights, "Save") && (
                  <AuthButton
                    disabled={logoBase64 === "" || formLoading}
                    onClick={postSchoolLogo}
                  >
                    {formLoading ? <FormLoader /> : "Post School Logo"}
                  </AuthButton>
                )}
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Color */}
          <Accordion
            sx={{ mb: 2, boxShadow: "none" }}
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            style={{
              pointerEvents: formLoading && "none",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    border: "1px solid #fff",
                    borderRadius: "50%",
                    color: "white",
                  }}
                />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ bgcolor: "#671E78", borderRadius: "20px", px: 3 }}
            >
              <Typography
                sx={{
                  width: "33%",
                  flexShrink: 0,
                  color: "#fff",
                  fontFamily: "Gilroy-Bold",
                }}
              >
                Colour
              </Typography>

              <Box
                sx={{
                  width: "60%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Divider sx={{ bgcolor: "#fff" }} />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" sx={{ color: "#000" }}>
                Choose Colour{" "}
              </Typography>
              <MuiColorInput
                fullWidth
                format="rgb"
                value={infoData?.school?.colorValue}
                onChange={(value) => {
                  console.log(value);
                  setInfoData({
                    ...infoData,
                    school: {
                      ...infoData?.school,
                      colorValue: value,
                    },
                  });
                }}
              />
              {/* <TextField
                fullWidth
                margin="normal"
                type="color"
                value={infoData?.school?.colorValue}
                onChange={(e) => {
                  setInfoData({
                    ...infoData,
                    school: {
                      ...infoData?.school,
                      colorValue: e.target.value,
                    },
                  });
                }}
              /> */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: `2px solid ${colors.primary}`,
                  paddingBottom: "10px",
                }}
              >
                {checkAccess(accessObj.rights, "Save") && (
                  <AuthButton
                    disabled={!infoData?.school?.colorValue || formLoading}
                    onClick={postColor}
                  >
                    {formLoading ? <FormLoader /> : "Post School Colour"}
                  </AuthButton>
                )}
              </div>
            </AccordionDetails>
          </Accordion>

          {/* About School */}
          <Accordion
            sx={{ mb: 2, boxShadow: "none" }}
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    border: "1px solid #fff",
                    borderRadius: "50%",
                    color: "white",
                  }}
                />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ bgcolor: "#671E78", borderRadius: "20px", px: 3 }}
            >
              <Typography
                sx={{
                  width: "33%",
                  flexShrink: 0,
                  color: "#fff",
                  fontFamily: "Gilroy-Bold",
                }}
              >
                About School
              </Typography>

              <Box
                sx={{
                  width: "60%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Divider sx={{ bgcolor: "#fff" }} />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <InputWrapper>
                <label htmlFor="">About School</label>
                <textarea
                  name="about_school"
                  id=""
                  cols="30"
                  style={{ resize: "none" }}
                  rows="4"
                  value={infoData?.aboutUs?.about_Us}
                  onChange={(e) => {
                    if (infoData?.aboutUs?.id) {
                      setInfoData({
                        ...infoData,
                        aboutUs: {
                          ...infoData?.aboutUs,
                          about_Us: e.target.value,
                          schoolId: user.schoolId,
                        },
                      });
                    } else {
                      setInfoData({
                        ...infoData,
                        aboutUs: {
                          ...infoData?.aboutUs,
                          id: 0,
                          about_Us: e.target.value,
                          schoolId: user.schoolId,
                        },
                      });
                    }
                  }}
                ></textarea>
              </InputWrapper>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: `2px solid ${colors.primary}`,
                  paddingBottom: "10px",
                }}
              >
                {checkAccess(accessObj.rights, "Save") && (
                  <AuthButton
                    disabled={
                      formLoading ||
                      infoData?.aboutUs === null ||
                      infoData?.aboutUs?.about_Us === ""
                    }
                    onClick={postSchoolAbout}
                  >
                    {formLoading ? <FormLoader /> : "Post About School"}
                  </AuthButton>
                )}
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Board Team */}
          <Accordion
            sx={{ mb: 2, boxShadow: "none" }}
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    border: "1px solid #fff",
                    borderRadius: "50%",
                    color: "white",
                  }}
                />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ bgcolor: "#671E78", borderRadius: "20px", px: 3 }}
            >
              <Typography
                sx={{
                  width: "33%",
                  flexShrink: 0,
                  color: "#fff",
                  fontFamily: "Gilroy-Bold",
                }}
              >
                Team
              </Typography>

              <Box
                sx={{
                  width: "60%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Divider sx={{ bgcolor: "#fff" }} />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <PageWrapper width="98%" ref={myRef}>
                <FormFlex reverse={true} style={{}}>
                  <FormFlexDiv width="75%">
                    <InputWrapper>
                      <label htmlFor="">Name</label>
                      <input
                        type="text"
                        value={boardObj.fullName}
                        onChange={(e) =>
                          setBoardObj({ ...boardObj, fullName: e.target.value })
                        }
                        placeholder="Board Member's name"
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <label htmlFor="">Postion</label>
                      <input
                        type="text"
                        value={boardObj.position}
                        onChange={(e) =>
                          setBoardObj({
                            ...boardObj,
                            position: e.target.value,
                          })
                        }
                        placeholder="Board Member's Role"
                      />
                    </InputWrapper>
                  </FormFlexDiv>
                  <FormFlexDiv
                    width="23%"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      flexDirection: "column",
                      paddingTop: "15px",
                    }}
                  >
                    <img
                      src={
                        boardObj.profileImage !== ""
                          ? boardObj.profileImage
                          : Avatarr
                      }
                      alt=""
                      style={{
                        width: "150px",
                        height: "160px",
                        borderRadius: "10px",
                      }}
                    />

                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => onPickFile(e, "board")}
                      ref={onPickBoardFileRef}
                    />

                    <button
                      style={{
                        color: "white",
                        marginTop: "4px",
                        backgroundColor: colors.primary,
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                      onClick={() => pickFile(onPickBoardFileRef)}
                    >
                      Change Photo
                    </button>
                  </FormFlexDiv>
                </FormFlex>

                <InputWrapper>
                  <label htmlFor="about-us">Address</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    placeholder="Address"
                    rows="4"
                    style={{ resize: "none" }}
                    value={boardObj.address}
                    onChange={(e) =>
                      setBoardObj({ ...boardObj, address: e.target.value })
                    }
                  ></textarea>
                </InputWrapper>

                <div style={{ marginTop: "10px" }}>
                  {/* <InputWrapper>
                  <label
                    htmlFor="facebook"
                    style={{ display: "block", fontWeight: "bold" }}
                  >
                    Facebook
                  </label>
                  <input
                    type="text"
                    value={boardObj.socialMediaLink}
                    placeholder="Social Media Link"
                    onChange={(e) =>
                      setBoardObj({
                        ...boardObj,
                        socialMediaLink: e.target.value,
                      })
                    }
                  />
                </InputWrapper> */}
                  <div style={{ display: "none", marginTop: "10px" }}>
                    {boardObj.fullName.length > 0 && (
                      <div
                        style={{
                          padding: "0.4rem 1.1rem",
                          border: `1px solid ${colors.primary}`,
                          borderRadius: "5px",
                          marginRight: "5px",
                          background: "transparent",
                          fontWeight: "bold",
                        }}
                      >
                        <span>@{boardObj.fullName.replace(" ", "")}</span>
                      </div>
                    )}

                    {/* <div
                    style={{
                      padding: "0.4rem 1.1rem",
                      border: `1px solid ${colors.primary}`,
                      borderRadius: "5px",
                      marginRight: "5px",
                      background: "transparent",
                      fontWeight: "bold",
                    }}
                  >
                    <span>@PhillpTolu</span>
                  </div> */}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    borderBottom: `2px solid ${colors.primary}`,
                    paddingBottom: "10px",
                  }}
                >
                  {checkAccess(accessObj.rights, "Save") && (
                    <AuthButton
                      mt="0.3rem"
                      onClick={() => addSchoolTeam()}
                      disabled={!formIsValid || formLoading}
                    >
                      {formLoading ? (
                        <FormLoader />
                      ) : (
                        <>{boardObj.id ? "Update Info" : "Add Info"}</>
                      )}
                    </AuthButton>
                  )}

                  {boardObj.id && (
                    <AuthButton
                      style={{ marginLeft: "0.3rem" }}
                      mt="0.3rem"
                      bg="red"
                      onClick={() => setBoardObj(Object.assign({}, _obj))}
                    >
                      Clear Fields
                    </AuthButton>
                  )}
                </div>
                {Array.isArray(infoData?.teams) &&
                  infoData?.teams.length > 0 &&
                  infoData.teams.map((item, index) => (
                    <SlidingImg key={index}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={item.profileImage} alt="" />
                        <div>
                          <span>{item.fullName}</span>
                          <span style={{ display: "block", fontSize: "12px" }}>
                            {item.position}
                          </span>
                        </div>
                      </div>
                      <div>
                        <button
                          style={{
                            backgroundColor: colors.primary,
                            color: "white",
                          }}
                          onClick={() => {
                            const element = myRef.current;
                            const extraOffset = 50;
                            window.scrollTo({
                              top: element.offsetTop - extraOffset,
                              behavior: "smooth",
                            });
                            setBoardObj(Object.assign({}, item));
                          }}
                        >
                          Edit
                        </button>
                        <button
                          disabled={query === item.id && deleteLoading}
                          onClick={() => {
                            console.log("here");
                            setQuery(item.id);
                            deleteItem(item.id, item.schoolId);
                          }}
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            borderColor: "red",
                          }}
                        >
                          {query === item.id && deleteLoading
                            ? "Please wait..."
                            : "Delete"}
                        </button>
                      </div>
                    </SlidingImg>
                  ))}
              </PageWrapper>
            </AccordionDetails>
          </Accordion>

          {/* contact */}
          <Accordion
            sx={{ mb: 2, boxShadow: "none" }}
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    border: "1px solid #fff",
                    borderRadius: "50%",
                    color: "white",
                  }}
                />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ bgcolor: "#671E78", borderRadius: "20px", px: 3 }}
            >
              <Typography
                sx={{
                  width: "33%",
                  flexShrink: 0,
                  color: "#fff",
                  fontFamily: "Gilroy-Bold",
                }}
              >
                Contact
              </Typography>

              <Box
                sx={{
                  width: "60%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Divider sx={{ bgcolor: "#fff" }} />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <PageWrapper width="95%">
                <InputWrapper>
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    placeholder="54 Road Block A Indiana"
                    value={infoData?.contactUs?.address}
                    onChange={(e) => {
                      if (infoData?.contactUs?.id) {
                        setInfoData({
                          ...infoData,
                          contactUs: {
                            ...infoData?.contactUs,
                            address: e.target.value,
                            schoolId: user.schoolId,
                          },
                        });
                      } else {
                        setInfoData({
                          ...infoData,
                          contactUs: {
                            ...infoData?.contactUs,
                            address: e.target.value,
                            schoolId: user.schoolId,
                            id: 0,
                          },
                        });
                      }
                    }}
                  />
                </InputWrapper>

                <FormFlex reverse={true} style={{ alignItems: "center" }}>
                  <FormFlexDiv width="55%">
                    <InputWrapper>
                      <label htmlFor="">Name</label>
                      <input
                        type="text"
                        value={infoData?.contactUs?.schoolAdminContact}
                        onChange={(e) => {
                          if (infoData?.contactUs?.id) {
                            setInfoData({
                              ...infoData,
                              contactUs: {
                                ...infoData?.contactUs,
                                schoolAdminContact: e.target.value,
                                schoolId: user.schoolId,
                              },
                            });
                          } else {
                            setInfoData({
                              ...infoData,
                              contactUs: {
                                ...infoData?.contactUs,
                                schoolAdminContact: e.target.value,
                                schoolId: user.schoolId,
                                id: 0,
                              },
                            });
                          }
                        }}
                        placeholder="Admin Contact's Name"
                      />
                    </InputWrapper>
                  </FormFlexDiv>
                  <FormFlexDiv width="43%">
                    <InputWrapper>
                      <label htmlFor="">Phone Number</label>
                      <input
                        onKeyDown={(e) => isNumber(e)}
                        type="text"
                        value={infoData?.contactUs?.phoneNumber}
                        onChange={(e) => {
                          if (infoData?.contactUs?.id) {
                            setInfoData({
                              ...infoData,
                              contactUs: {
                                ...infoData?.contactUs,
                                phoneNumber: e.target.value,
                                schoolId: user.schoolId,
                              },
                            });
                          } else {
                            setInfoData({
                              ...infoData,
                              contactUs: {
                                ...infoData?.contactUs,
                                phoneNumber: e.target.value,
                                schoolId: user.schoolId,
                                id: 0,
                              },
                            });
                          }
                        }}
                        placeholder="08012345678"
                      />
                    </InputWrapper>
                  </FormFlexDiv>
                </FormFlex>
                <InputWrapper>
                  <label htmlFor="">Help Line</label>
                  <input
                    onKeyDown={(e) => isNumber(e)}
                    type="text"
                    value={infoData?.contactUs?.helpLine}
                    onChange={(e) => {
                      if (infoData?.contactUs?.id) {
                        setInfoData({
                          ...infoData,
                          contactUs: {
                            ...infoData?.contactUs,
                            helpLine: e.target.value,
                            schoolId: user.schoolId,
                          },
                        });
                      } else {
                        setInfoData({
                          ...infoData,
                          contactUs: {
                            ...infoData?.contactUs,
                            helpLine: e.target.value,
                            schoolId: user.schoolId,
                            id: 0,
                          },
                        });
                      }
                    }}
                    placeholder="08012345678"
                  />
                </InputWrapper>
                <InputWrapper>
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    placeholder="xyzEnterPrise@setup.com"
                    value={infoData?.contactUs?.email}
                    onChange={(e) => {
                      if (infoData?.contactUs?.id) {
                        setInfoData({
                          ...infoData,
                          contactUs: {
                            ...infoData?.contactUs,
                            email: e.target.value,
                            schoolId: user.schoolId,
                          },
                        });
                      } else {
                        setInfoData({
                          ...infoData,
                          contactUs: {
                            ...infoData?.contactUs,
                            email: e.target.value,
                            schoolId: user.schoolId,
                            id: 0,
                          },
                        });
                      }
                    }}
                  />
                </InputWrapper>
                <SmallHeader style={{ fontWeight: "bold", marginTop: "10px" }}>
                  Social Media Link
                </SmallHeader>
                <div style={{ marginTop: "10px" }}>
                  <InputWrapper>
                    <label
                      htmlFor="facebook"
                      style={{ display: "block", fontWeight: "bold" }}
                    >
                      Facebook
                    </label>
                    <input
                      type="text"
                      placeholder="Social Media Link"
                      value={infoData?.contactUs?.faceookUrl}
                      onChange={(e) => {
                        if (infoData?.contactUs?.id) {
                          setInfoData({
                            ...infoData,
                            contactUs: {
                              ...infoData?.contactUs,
                              faceookUrl: e.target.value,
                              schoolId: user.schoolId,
                            },
                          });
                        } else {
                          setInfoData({
                            ...infoData,
                            contactUs: {
                              ...infoData?.contactUs,
                              faceookUrl: e.target.value,
                              schoolId: user.schoolId,
                              id: 0,
                            },
                          });
                        }
                      }}
                    />
                  </InputWrapper>
                  {/* <div style={{ display: "inline-flex", marginTop: "10px" }}>
                  {infoData?.contactUs?.schoolAdminContact.length > 0 && (
                    <div
                      style={{
                        padding: "0.4rem 1.1rem",
                        border: `1px solid ${colors.primary}`,
                        borderRadius: "5px",
                        marginRight: "5px",
                        background: "transparent",
                        fontWeight: "bold",
                      }}
                    >
                      <span>
                        @
                        {infoData?.contactUs?.schoolAdminContact.replace(
                          " ",
                          ""
                        )}
                      </span>
                    </div>
                  )}
                </div> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    borderBottom: `2px solid ${colors.primary}`,
                    paddingBottom: "10px",
                  }}
                >
                  {checkAccess(accessObj.rights, "Save") && (
                    <AuthButton
                      onClick={() => submitContact()}
                      disabled={formLoading}
                    >
                      {formLoading ? <FormLoader /> : <>Save Info</>}
                    </AuthButton>
                  )}
                </div>
              </PageWrapper>
            </AccordionDetails>
          </Accordion>
          {/* faqs */}
          <Accordion
            sx={{ mb: 2, boxShadow: "none" }}
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
            style={{
              pointerEvents: formLoading && "none",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    border: "1px solid #fff",
                    borderRadius: "50%",
                    color: "white",
                  }}
                />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ bgcolor: "#671E78", borderRadius: "20px", px: 3 }}
            >
              <Typography
                sx={{
                  width: "33%",
                  flexShrink: 0,
                  color: "#fff",
                  fontFamily: "Gilroy-Bold",
                }}
              >
                FAQs
              </Typography>

              <Box
                sx={{
                  width: "60%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Divider sx={{ bgcolor: "#fff" }} />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <PageWrapper width="100%" ref={myReff}>
                <InputWrapper>
                  <label htmlFor="">Question</label>
                  <input
                    type="text"
                    value={newsObj.question}
                    onChange={(e) =>
                      setNewsObj({
                        ...newsObj,
                        question: e.target.value,
                      })
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <label htmlFor="">Answer</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="4"
                    value={newsObj.answer}
                    onChange={(e) =>
                      setNewsObj({
                        ...newsObj,
                        answer: e.target.value,
                      })
                    }
                  ></textarea>
                </InputWrapper>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    borderBottom: `2px solid ${colors.primary}`,
                    paddingBottom: "10px",
                  }}
                >
                  {checkAccess(accessObj.rights, "Save") && (
                    <AuthButton
                      mt="0.3rem"
                      disabled={
                        formLoading ||
                        newsObj.question === "" ||
                        newsObj.answer === ""
                      }
                      onClick={() => submitFAQs()}
                    >
                      {formLoading ? (
                        <FormLoader />
                      ) : newsObj.id ? (
                        "Update FAQ"
                      ) : (
                        "Add FAQ"
                      )}
                    </AuthButton>
                  )}

                  {newsObj.id && (
                    <AuthButton
                      style={{ marginLeft: "0.3rem" }}
                      mt="0.3rem"
                      bg="red"
                      onClick={() => setNewsObj(Object.assign({}, _newObj))}
                    >
                      Clear Fields
                    </AuthButton>
                  )}
                </div>

                {Array.isArray(infoData.faQs) &&
                  infoData.faQs.map((item, index) => (
                    <SlidingImg key={index}>
                      <div>
                        {/* <img src={Avatarr} alt="" /> */}
                        <div
                          style={{
                            // display: isPhone ? "none" : "block",
                            padding: "0.4rem",
                          }}
                        >
                          <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                            {item.question}
                          </p>
                          <p style={{ fontSize: "11px" }}>{item.answer}</p>
                        </div>
                      </div>
                      <div>
                        <button
                          style={{
                            backgroundColor: colors.primary,
                            color: "white",
                          }}
                          onClick={() => {
                            const element = myReff.current;
                            const extraOffset = 50;
                            window.scrollTo({
                              top: element.offsetTop - extraOffset,
                              behavior: "smooth",
                            });
                            setNewsObj(Object.assign({}, item));
                          }}
                        >
                          Edit
                        </button>
                        <button
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            borderColor: "red",
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </SlidingImg>
                  ))}
              </PageWrapper>
            </AccordionDetails>
          </Accordion>

          {/* Stamp */}
          <Accordion
            sx={{ mb: 2, boxShadow: "none" }}
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
            style={{
              pointerEvents: formLoading && "none",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    border: "1px solid #fff",
                    borderRadius: "50%",
                    color: "white",
                  }}
                />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ bgcolor: "#671E78", borderRadius: "20px", px: 3 }}
            >
              <Typography
                sx={{
                  width: "33%",
                  flexShrink: 0,
                  color: "#fff",
                  fontFamily: "Gilroy-Bold",
                }}
              >
                Stamp
              </Typography>
              <Box
                sx={{
                  width: "60%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Divider sx={{ bgcolor: "#fff" }} />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <InlineFormDiv width="90%" jc="space-between">
                <div
                  style={{
                    width: isPhone ? "100%" : "70%",
                  }}
                >
                  <input
                    type="file"
                    style={{
                      display: "none",
                    }}
                    onChange={(e) => onPickImage(e, "stamp")}
                    ref={onSchoolStampRef}
                  />
                  <InputWrapper>
                    <label htmlFor="filename">Upload Stamp</label>
                    <input
                      type="text"
                      onClick={() => pickFile(onSchoolStampRef)}
                      value={stampFileName}
                      disabled={stampFileName.length > 0}
                      placeholder="File Name"
                    />
                  </InputWrapper>
                </div>
                <AuthButton
                  width={isPhone ? "100%" : "20%"}
                  onClick={() => pickFile(onSchoolStampRef)}
                >
                  Upload
                </AuthButton>
              </InlineFormDiv>
              <LogoImgSetup>
                <img
                  src={
                    stampBase64.length > 0
                      ? stampBase64
                      : stampSignData?.stamp
                      ? stampSignData?.stamp
                      : Avatarr
                  }
                  alt=""
                />
                {stampBase64.length > 0 && (
                  <span
                    style={{
                      padding: "0.2rem 0.4rem",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setStampFileName("");
                      setStampBase64("");
                    }}
                  >
                    X
                  </span>
                )}
              </LogoImgSetup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: `2px solid ${colors.primary}`,
                  paddingBottom: "10px",
                }}
              >
                {checkAccess(accessObj.rights, "Save") && (
                  <AuthButton
                    disabled={stampBase64 === "" || formLoading}
                    onClick={() => postSchoolStampSign("stamp")}
                  >
                    {formLoading ? <FormLoader /> : "Post School Stamp"}
                  </AuthButton>
                )}
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Signature */}
          <Accordion
            sx={{ mb: 2, boxShadow: "none" }}
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
            style={{
              pointerEvents: formLoading && "none",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    border: "1px solid #fff",
                    borderRadius: "50%",
                    color: "white",
                  }}
                />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ bgcolor: "#671E78", borderRadius: "20px", px: 3 }}
            >
              <Typography
                sx={{
                  width: "33%",
                  flexShrink: 0,
                  color: "#fff",
                  fontFamily: "Gilroy-Bold",
                }}
              >
                Signature
              </Typography>
              <Box
                sx={{
                  width: "60%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Divider sx={{ bgcolor: "#fff" }} />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <InlineFormDiv width="90%" jc="space-between">
                <div
                  style={{
                    width: isPhone ? "100%" : "70%",
                  }}
                >
                  <input
                    type="file"
                    style={{
                      display: "none",
                    }}
                    onChange={(e) => onPickImage(e, "signature")}
                    ref={onSignatureRef}
                  />
                  <InputWrapper>
                    <label htmlFor="filename">Upload Signature</label>
                    <input
                      type="text"
                      onClick={() => pickFile(onSignatureRef)}
                      value={signatureFileName}
                      disabled={signatureFileName.length > 0}
                      placeholder="File Name"
                    />
                  </InputWrapper>
                </div>
                <AuthButton
                  width={isPhone ? "100%" : "20%"}
                  onClick={() => pickFile(onSignatureRef)}
                >
                  Upload
                </AuthButton>
              </InlineFormDiv>
              <LogoImgSetup>
                <img
                  src={
                    signatureBase64.length > 0
                      ? signatureBase64
                      : stampSignData?.signature
                      ? stampSignData?.signature
                      : Avatarr
                  }
                  alt=""
                />
                {signatureBase64.length > 0 && (
                  <span
                    style={{
                      padding: "0.2rem 0.4rem",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSignatureFileName("");
                      setSignatureBase64("");
                    }}
                  >
                    X
                  </span>
                )}
              </LogoImgSetup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: `2px solid ${colors.primary}`,
                  paddingBottom: "10px",
                }}
              >
                {checkAccess(accessObj.rights, "Save") && (
                  <AuthButton
                    disabled={signatureBase64 === "" || formLoading}
                    onClick={() => postSchoolStampSign("signature")}
                  >
                    {formLoading ? <FormLoader /> : "Post School Signature"}
                  </AuthButton>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </Box>
  );
};

export default WebsiteInfo;
