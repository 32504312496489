import React from "react";
import EditCredentialComponent from "./EditCredentialComponent";

const ParentCredentialSetup = () => {
  return (
    <EditCredentialComponent accountType={2} title="Manage Parent Account" />
  );
};

export default ParentCredentialSetup;
