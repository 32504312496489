import React, { useState, useEffect } from "react";
import colors from "../../assets/colors.json";
import {
  FormFlex,
  FormFlexDiv,
  SmallHeader,
  TabContentWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
import { InputWrapper, AuthButton } from "../../pages/auth/Login.style";
import Select from "../select/select";
import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";
import FormLoader from "../FormLoader";
const SocialData = ({
  bioData,
  setBioData,
  readOnly,
  action,
  loading,
  showInfoButton,
}) => {
  const [opt, setOpt] = useState("");
  // const [bioData, setBioData] = useState(null);

  const isMobile = useMediaQuery({ maxWidth: 992 });

  const optionsArr = [
    {
      id: 1,
      name: "Yes",
      value: "true",
    },
    {
      id: 2,
      name: "No",
      value: "false",
    },
  ];

  return (
    <TabContentWrapper>
      {bioData === null ? (
        <SmallHeader style={{ textAlign: "center" }}>
          No Student Info At The Moment
        </SmallHeader>
      ) : (
        <>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="position of child">
                  Position of Child In the Family
                </label>
                <input
                  type="text"
                  placeholder="Position of Child in the family"
                  value={bioData.childPosition_In_Family}
                  disabled={readOnly}
                  onChange={(e) =>
                    setBioData({
                      ...bioData,
                      childPosition_In_Family: e.target.value,
                    })
                  }
                />
              </InputWrapper>{" "}
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="position of mother">
                  Position of child's mother
                </label>
                <input
                  type="text"
                  placeholder="Position of child's Mother"
                  value={bioData.childPosition_On_Mother}
                  disabled={readOnly}
                  onChange={(e) =>
                    setBioData({
                      ...bioData,
                      childPosition_On_Mother: e.target.value,
                    })
                  }
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>

          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="career">
                  Career ambition of your child(list in order of priority)
                </label>
                <textarea
                  cols="30"
                  rows="3"
                  placeholder="Child Career Ambition"
                  value={bioData.childAmbition}
                  disabled={readOnly}
                  onChange={(e) =>
                    setBioData({
                      ...bioData,
                      childAmbition: e.target.value,
                    })
                  }
                ></textarea>
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="">What does the child find easy to do</label>
                <textarea
                  cols="30"
                  rows="3"
                  value={bioData.childEasyThings}
                  disabled={readOnly}
                  onChange={(e) =>
                    setBioData({
                      ...bioData,
                      childEasyThings: e.target.value,
                    })
                  }
                ></textarea>
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="bed wet">Does the child bed wet ?</label>
                <Select
                  title="Bed Wet Status"
                  index="name" // no
                  selected={bioData.isChildBedWet ? "Yes" : "No"} // false
                  options={optionsArr}
                  thirdOption="value" // false
                  disabled={readOnly}
                  setSelected={(val) => {
                    console.log(val);
                    setBioData({
                      ...bioData,
                      isChildBedWet: val.name === "Yes",
                    });
                    // console.log(val);
                    // setOpt(val.value);
                  }}
                />
              </InputWrapper>{" "}
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="period">
                  Has the child been away from home for long period Before ?
                </label>
                <Select
                  title="Has Child Being Far Away From Home"
                  index="name" // no
                  selected={bioData.isChild_Away_FromHome_Before ? "Yes" : "No"} // false
                  options={optionsArr}
                  thirdOption="value" // false
                  disabled={readOnly}
                  setSelected={(val) => {
                    setBioData({
                      ...bioData,
                      isChild_Away_FromHome_Before: val.name === "Yes",
                    });
                  }}
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="">
                  Special comment(s) worthy of Note on Child.
                </label>
                <textarea
                  cols="30"
                  rows="3"
                  value={bioData.note_On_Child}
                  disabled={readOnly}
                  onChange={(e) =>
                    setBioData({
                      ...bioData,
                      note_On_Child: e.target.value,
                    })
                  }
                ></textarea>
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          {showInfoButton && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <AuthButton
                disabled={loading}
                width={isMobile ? "100%" : "30%"}
                onClick={action}
              >
                {loading ? (
                  <FormLoader />
                ) : (
                  <div>
                    <span>Update Details</span>
                  </div>
                )}
              </AuthButton>
            </div>
          )}
        </>
      )}
    </TabContentWrapper>
  );
};

SocialData.propTypes = {
  bioData: PropTypes.object,
  readOnly: PropTypes.bool,
  showInfoButton: PropTypes.bool,
};

SocialData.defaultProps = {
  bioData: null,
  showInfoButton: true,
  readOnly: true,
};
export default SocialData;
