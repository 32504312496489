import React, { useCallback, useEffect, useState } from "react";
import colors from "../../../assets/colors.json";
import { toast } from "react-toastify";
import { GiCancel } from "react-icons/gi";

import {
  TabContainer,
  AccountSummaryWrapper,
  BigHeader,
  Search,
  Icon,
  MenuDiv,
  Input,
  SmallHeader,
  // SubMenuDiv,
} from "../../../pages/Dashboard/styles/dashboard.style";
import { useMediaQuery } from "react-responsive";
import { getAllClasses } from "../../../services/Subject";
import { BsSearch } from "react-icons/bs";
// import api from "../../../services/ControlPanel";
import Pagination from "../../../components/pagination_one/pagination";
import { checkAccess, paginateData } from "../../../services/utils";
import { InputWrapper } from "../../../pages/auth/Login.style";
import { useSelector } from "react-redux";
import {
  AssignSubjectClasses,
  // AssignSsubjectTeacher,
  // createSubject,
  // deleteSubject,
  // getAllSubjects,
  // GetAssignSubjectTeacher,
  GetSubjectTeacherClasses,
  GetSubjectTeachers,
} from "../../../services/MySchool";
// import FormLoader from "../../../components/FormLoader";
import ErrorService from "../../../services/errorService";
import Loader from "../../../components/pageLoader/loader";
import Modal from "../../modal/Modal";
const AssignClassToSubjectTeacher = () => {
  const isTab = useMediaQuery({ maxWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 768 });
  // const [showModal, setShowModal] = useState(false);
  const [classQuery, setClassQuery] = useState("");
  const [modalObj, setModalObj] = useState({
    staffId: "",
    staffName: "",
    subjectName: "",
    subjectId: "",
    schoolId: "",
  });
  // const [obj, setObj] = useState({
  //   subname: "Work Of Art",
  //   subshort: "WOA",
  //   sort_order: 2,
  //   unit: "string",
  //   grouped: 0,
  //   main_sub: 0,
  // });
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffSet, setitemOffSet] = useState(0);
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  // const [formLoading, setFormLoading] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [list, setList] = useState([]);
  const [allData, setAllData] = useState([]);
  // const [id, setId] = useState("");
  const [loadSub, setLoadSub] = useState(true);
  const [sQuery, setSQuery] = useState("");
  const [data, setData] = useState([]);
  const [listData, setListData] = useState([]);
  // const [teachers, setTeachers] = useState([]);
  // const [tLoading, setTLoading] = useState([]);
  const [assignLoading, setAssignLoading] = useState(false);
  const [queryId, setQueryId] = useState("");
  const [classes, setClasses] = useState([]);
  const [cLoading, setCLoading] = useState(false);
  const subjectTeacherClasses = useCallback(
    async (staffId, subjectId, schoolId) => {
      try {
        setCLoading(true);
        const res = await GetSubjectTeacherClasses(
          admToken,
          staffId,
          subjectId,
          schoolId
        );
        console.log(res);
        if (res.status === true && res.statusCode === 2) {
          setClasses(res.data);
          setShowAssignModal(true);
        }
        setCLoading(false);
      } catch (error) {
        console.log(error);
        setCLoading(false);
      }
    },
    [admToken]
  );
  const allClasses = useCallback(async () => {
    try {
      const res = await getAllClasses(admToken, user.schoolId);
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken, user.schoolId]);
  const filterClass = useCallback(
    async (e) => {
      let arr = [];
      const queryRegex = new RegExp(e.target.value, "i");
      data.forEach((item) => {
        Object.values(item).some((val) =>
          queryRegex.test(val) ? arr.push(item) : ""
        );
      });
      setListData(arr);
    },
    [data]
  );

  const getSubjectTeachers = useCallback(async () => {
    try {
      const res = await GetSubjectTeachers(admToken, user.schoolId);
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setAllData(res.data);
      }
      setLoadSub(false);
    } catch (error) {
      setLoadSub(false);

      console.log(error.response);
    }
  }, [admToken, user.schoolId]);
  useEffect(() => {
    paginateData(
      allData,
      setList,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allData, currentPage, itemOffSet]);
  useEffect(() => {
    getSubjectTeachers();
    allClasses();
  }, [getSubjectTeachers, allClasses]);

  const resetData = () => {
    setCurrentPage(1);
    console.log("reset data working");
    paginateData(allData, setList, 1, itemOffSet, setitemOffSet, setPageCount);
  };
  const filterSearch = async (e) => {
    let arr = [];
    console.log(arr);
    const queryRegex = new RegExp(e.target.value, "i");
    allData.forEach((item) => {
      Object.values(item).forEach((val) =>
        queryRegex.test(val) ? arr.push(item) : ""
      );
    });
    console.log(arr);
    setList(arr);
    setPageCount(0);
    // }
  };

  const assignFunc = useCallback(
    async (id) => {
      try {
        setAssignLoading(true);
        const res = await AssignSubjectClasses(
          admToken,
          id,
          modalObj.subjectId,
          modalObj.staffId
        );
        if (res.status && res.statusCode === 2) {
          toast.success(res.message, {
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_LEFT,
          });

          subjectTeacherClasses(
            modalObj.staffId,
            modalObj.subjectId,
            modalObj.schoolId
          );
          // setCurrentPage(1);
        }
        res.status === 500 && ErrorService.displayErrorAlert(res.data);
        res.status === 400 && ErrorService.displayErrorAlert(res.data);
        !res.status && ErrorService.displayErrorAlert(res);
        setAssignLoading(false);
      } catch (error) {
        setAssignLoading(false);
      }
    },
    [
      admToken,
      modalObj.schoolId,
      modalObj.staffId,
      modalObj.subjectId,
      subjectTeacherClasses,
    ]
  );
  const assignContent = (
    <>
      <BigHeader style={{ textAlign: "center" }} fs="17px">
        Assign {modalObj.subjectName} class for {modalObj.staffName}
      </BigHeader>
      <InputWrapper>
        <input
          type="text"
          placeholder="Search For Class"
          onChange={(e) => {
            filterClass(e);
            setClassQuery(e.target.value);
          }}
          value={classQuery}
        />
      </InputWrapper>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: isMobile ? "column" : "row",
          padding: "0.2rem 0.3rem",
          justifyContent: "start",
        }}
      >
        {classes.length > 0 &&
          classes.map((item, index) => (
            <div
              key={index}
              style={{
                position: "relative",
                marginTop: "0.4rem",
                backgroundColor: colors.primary,
                display: "flex",
                alignItems: "center",
                padding: "0.3rem 0.6rem",
                borderRadius: "10px",
                marginRight: "0.2rem",
                flexWrap: "wrap",
                border: `1px solid ${colors.primary}`,
                cursor: "pointer",
              }}
              onClick={() => {
                setQueryId(item.classid);
                assignFunc(item.classid);
              }}
            >
              <SmallHeader
                style={{
                  color: "white",
                  marginRight: "0.2rem",
                  fontSize: isTab ? "12px" : "13px",
                }}
              >
                {item.className}
              </SmallHeader>

              <GiCancel
                color="red"
                style={{
                  marginLeft: "0.2rem",
                  cursor: "pointer",
                }}
              />
            </div>
          ))}
      </div>

      <MenuDiv style={{ padding: isTab ? "0.6rem" : "0.8rem" }}>
        {listData.length > 0 &&
          listData.map((item, index) => (
            <div
              key={index}
              style={{
                marginTop: "0.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>{item.className}</div>
              <button
                disabled={queryId === item.classId && assignLoading}
                style={{
                  backgroundColor: colors.primary,
                  color: "white",
                  borderRadius: "20px",
                  padding: "0.3rem 0.6rem",
                }}
                onClick={() => {
                  setQueryId(item.classId);
                  assignFunc(item.classId);
                }}
              >
                {queryId === item.classId && assignLoading
                  ? "Please wait..."
                  : checkAccess(classes, item.classId)
                  ? "Unassign"
                  : "Assign"}
              </button>
            </div>
          ))}
      </MenuDiv>
    </>
  );
  if (loadSub) {
    return <Loader />;
  }
  return (
    <div>
      {showAssignModal && (
        <Modal
          show={showAssignModal}
          content={assignContent}
          size="large"
          onClose={setShowAssignModal}
        />
      )}
      <TabContainer style={{ borderRadius: "10px", paddingBottom: "0px" }}>
        <AccountSummaryWrapper pt="0px">
          <table>
            <tr
              className="table-head"
              style={{ backgroundColor: colors.primary, color: "white" }}
            >
              <th>Subject Teacher ({allData.length > 0 && allData.length}) </th>
              {/* <th>Phone Number</th> */}
              <th>
                <Input style={{ width: "100%" }}>
                  <Icon>
                    <BsSearch color={colors.primary} />
                  </Icon>
                  <Search
                    style={{ background: "white" }}
                    value={sQuery}
                    type="type"
                    placeholder="Search For Staff"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setSQuery(e.target.value);
                      if (e.target.value.length > 3) {
                        console.log("inside");
                        filterSearch(e);
                      }

                      if (e.target.value === "") {
                        resetData();
                      }
                    }}
                  />
                </Input>
              </th>{" "}
            </tr>
            {list.length > 0 ? (
              list.map((item, index) => (
                <tr key={index}>
                  <td>
                    {" "}
                    {index + 1}.{item.fullName}
                  </td>
                  {/* <td>{item.phoneNumber}</td> */}
                  <td>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {item.subjects.length > 0 &&
                        item.subjects.map((i, id) => (
                          <button
                            disabled={
                              (modalObj.staffId === item.credentialId &&
                                modalObj.subjectId === i.subjectId &&
                                cLoading) ||
                              !checkAccess(accessObj.rights, "Assign")
                            }
                            style={{
                              marginRight: "0.5rem",
                              backgroundColor: colors.primary,
                              borderRadius: "0.4rem",
                              color: "white",
                              padding: "0.3rem 0.5rem",
                              marginTop: "0.3rem",
                              position: "relative",
                            }}
                            key={id}
                            onClick={() => {
                              setModalObj({
                                ...modalObj,
                                staffId: item.credentialId,
                                staffName: item.fullName,
                                subjectId: i.subjectId,
                                subjectName: i.subjectName,
                                schoolId: item.schoolId,
                              });
                              subjectTeacherClasses(
                                item.credentialId,
                                i.subjectId,
                                item.schoolId
                              );
                              // assignTeachers();
                            }}
                          >
                            <span
                              style={{
                                position: "absolute",
                                top: "-5px",
                                padding: "0.1rem 0.37rem",
                                borderRadius: "6px",
                                right: "-5px",
                                backgroundColor: "red",
                                color: "white",
                                fontSize: "9px",
                              }}
                            >
                              {i.classesCount}
                            </span>
                            {modalObj.staffId === item.credentialId &&
                            modalObj.subjectId === i.subjectId &&
                            cLoading
                              ? "Please wait"
                              : `Assign ${i.subjectName}`}
                          </button>
                        ))}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  There Are No Subject Teachers
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      {pageCount > 1 && (
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            range={3}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default AssignClassToSubjectTeacher;
