import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Drawer, IconButton, List, Button, ListItemButton, ListItemText, ListItemIcon, Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/system";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { BsSearch } from "react-icons/bs";
import logo from "../../assets/school-logo.png";
import { PDNavData } from "../../assets/data/PDNavData";

import LogoutIcon from "@mui/icons-material/Logout";


let Body = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
    secondary: {
      main: "#edf2ff",
    },
  },
  typography: {
    fontFamily: "Gilroy-Regular",
    h1: {
      fontFamily: "Gilroy-Bold",
      fontSize: "52px",
    },
  },
});


const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Drawers = () => {
  const router = useLocation()
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
    <ThemeProvider theme={Body}>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List
          sx={{
            background: "#691e78",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Link to="/">
          <Avatar
          src={logo}
          sx={{ bgcolor: "white", width: "85px", height: "85px", mb: 5 }}
        />
          </Link> */}
          <Box
        sx={{
       
          width: "301px",
          bgcolor: "#671E78",
          display: "flex",
          height:'100vh',
          flexDirection: "column",
          alignItems: "center",
          pt: 5,
        }}
      >
        <Avatar
          src={logo}
          sx={{ bgcolor: "white", width: "85px", height: "85px", mb: 5 }}
        />

        <Box sx={{ width: "100%", mt: 4 }}>
          <Typography sx={{ ml: 6, color: "#fff" }} variant="caption">
            MENU
          </Typography>

          <List >
            {PDNavData.map((nav, id)=>(
   <Link to={nav.link} key={id}   onClick={() => setOpenDrawer(false)} >
   <ListItemButton selected={router.pathname === nav.link ? true : false}  sx={{ color: "white", pl: 5,
'&:hover, 	&.Mui-selected':{
   bgcolor:'rgba(217, 217, 217, 0.1)',
   borderLeft:'4px solid #fff'
} }}>

     <ListItemIcon sx={{color:'#fff'}}>
     {nav.icon}
     </ListItemIcon>
     <ListItemText>{nav.label}</ListItemText>
   </ListItemButton>
   </Link>

            ))}
         
          </List>
        </Box>
        {/* <Button variant="contained">Parent Dashboard</Button> */}
        <Link to="/parentmgt">
          <Button
            variant="contained"
            sx={{
              bgcolor: "#fff",
              color: "#671e78",
              fontFamily: "gilroy-semibold",
              textTransform: "inherit",
              width: "200px",
              mt:6,
              "&:hover": {
                color: "#fff",
              },
            }}
          >
           Back to previous
          </Button>
          </Link>
      </Box>
    
             
        
        </List>
      </Drawer>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pt: 5,
          pb: 3,
          px: 4,
        }}
      >
        <Avatar>S</Avatar>

        <Search
          sx={{
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
            ml: 2,
          }}
        >
          <SearchIconWrapper>
            <BsSearch />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
          />
        </Search>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "15%",
          }}
        >
          <Badge badgeContent={2} color="error">
            <NotificationsNoneIcon />
          </Badge>
          <IconButton
            sx={{ color: "#000" }}
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            <MenuIcon />
          </IconButton>

          {/* <MenuIcon style={{ fontSize: "25px", cursor:'pointer' }} /> */}
        </Box>
      </Box>
      </ThemeProvider>
    </>
  );
};

export default Drawers;
