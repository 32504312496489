import {
    Avatar,
    Button,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
  } from "@mui/material";
  import { useMediaQuery, useTheme } from "@mui/material";
  import Badge from '@mui/material/Badge';
  import { Box } from "@mui/system";
  
  import logo from "../../assets/school-logo.png";
  import { createTheme, ThemeProvider } from "@mui/material/styles";
  import { Link, useLocation } from "react-router-dom";
  
  import  {PDNavData} from '../../assets/data/PDNavData'
  
  
  let Body = createTheme({
    palette: {
      primary: {
        main: "#671E78",
      },
      secondary: {
        main: "#edf2ff",
      },
    },
    typography: {
      fontFamily: "Gilroy-Regular",
      h1: {
        fontFamily: "Gilroy-Bold",
        fontSize: "52px",
      },
    },
  });
  
  const PDNavbar = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
      const router = useLocation()
  
    return (
      <>
            {isMatch ? (
          <Box></Box>
        ) : (
      <ThemeProvider theme={Body}>
        <Box
          sx={{
            width: "301px",
            bgcolor: "#671E78",
            display: "flex",
            height:'100vh',
            flexDirection: "column",
            alignItems: "center",
            pt: 5,
       
          }}
        >
          <Avatar
            src={logo}
            sx={{ bgcolor: "white", width: "85px", height: "85px", mb: 5 }}
          />
     
          <Box sx={{ width: "100%", mt: 4, mb:3 }}>
            <Typography sx={{ ml: 6, color: "#fff" }} variant="caption">
              MENU
            </Typography>
  
            <List >
              {PDNavData.map((nav, id)=>(
     <Link to={nav.link} key={id}>
     <ListItemButton selected={router.pathname === nav.link ? true : false}  sx={{ color: "white", mr:2, pl: 5,
  ' 	&.Mui-selected':{
     bgcolor:'rgba(217, 217, 217, 0.1)',
     borderLeft:'4px solid #fff'
  } }}>
  
       <ListItemIcon sx={{color:'#fff', fontSize:'20px'}}>
       {nav.icon}
       </ListItemIcon>
       <Box display="flex">
       <ListItemText>{nav.label}</ListItemText>
       
       <Badge badgeContent={nav.badge} color="error" sx={{top:10, right:-7, padding:'0 2px'}}/>
       </Box>
  
     </ListItemButton>
     </Link>
  
              ))}
           
            </List>
          </Box>
          <Box>
            <Link to="/parentmgt">
          <Button
            variant="contained"
            sx={{
              bgcolor: "#fff",
              color: "#671e78",
              fontFamily: "gilroy-semibold",
              textTransform: "inherit",
              width: "200px",
              mt:6,
              "&:hover": {
                color: "#fff",
              },
            }}
          >
           Back to previous
          </Button>
          </Link>
        </Box>
        </Box>
      </ThemeProvider>
        )}
      </>
    );
  };
  
  export default PDNavbar;
  
  
  export const getMatchPath = (pathname = "", link = "") =>{
      let links = pathname.split("/");
  
      return link.length === 2 ? links[1] === link : links[2] === link;
  }