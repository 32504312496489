import React from "react";
import { Box, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, Outlet } from "react-router-dom";
import SideBar from "./Layouts/SideBar";
import PDA_Drawer from "./Layouts/PDA_Drawer";

const PDA_Second = () => {
  return (
    <>
      <Box>
        <Box mt={2} sx={{display:'flex', justifyContent:'space-between'}}>
          <Box >
            <Link to="/parentmanagement/admission">
              <IconButton>
                <ArrowBackIcon />
              </IconButton>{" "}
            </Link>{" "}
            Back to list
          </Box>
          <Box sx={{ display:{lg:'none', sm:'block'}}}>
            <PDA_Drawer />
          </Box>
        </Box>
        <Box
          sx={{
            height: "68vh",
            overflow: "scroll",
            display: "flex",
            justifyContent: "space-between",
            mt: 3,
          }}
        >
          <Box sx={{ width: "25%",display:{lg:'block', md:'none', sm:'none', xs:'none'}, height: "100%" }}>
            <SideBar />
          </Box>

          <Box
            sx={{
              width: { lg: "72%", sm: "100%", xs: "100%" },
              border: "0.5px solid #5e9c09",
              borderRadius: "15px",
              p: 2,
           
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PDA_Second;
