import { Box } from "@mui/material";
import React from "react";
import {
  IconButton,
  Typography,
  Grid,
  // List,
  ListItem,
  Avatar,
  Divider,
  TextField,
} from "@mui/material";
import { GiCancel } from "react-icons/gi";
import PdfDownloader2 from "../PdfDownloader2";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LanguageIcon from "@mui/icons-material/Language";
import { LuFileSpreadsheet } from "react-icons/lu";

const ExcelJS = require("exceljs");

const AttendanceReportSheet = ({ close, note, resultData, dates }) => {
  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Attendance report sheet");
    sheet.properties.defaultRowHeight = 80;
    sheet.properties.header = "";

    sheet.columns = [
      { header: "S/N", key: "serialNumber", width: 5 },
      {
        header: "Name",
        key: "studentName",
        width: "35",
      },
      {
        header: "Admission No",
        key: "admissionNo",
        width: "15",
      },
      ...dates.flatMap((date) => [
        {
          header: `${date.attendanceDay} - ${date.stringAttendanceDate} (Morning)`,
          key: `${date.stringAttendanceDate}_morning`,
          width: 25,
        },
        {
          header: `${date.attendanceDay} - ${date.stringAttendanceDate} (Afternoon)`,
          key: `${date.stringAttendanceDate}_afternoon`,
          width: 25,
        },
      ]),
      {
        header: "Total School days",
        key: "totalSchoolDays",
        width: "20",
      },
    ];

    resultData?.studentAttendances.forEach((each, index) => {
      let totalPresentDays = 0;

      const startDate = new Date(dates[0]?.attendanceDate);

      dates?.forEach((date) => {
        each.records.forEach((record) => {
          if (record.stringAttendanceDate === date.stringAttendanceDate) {
            if (new Date(record.attendanceDate) >= startDate) {
              if (record.morningMarkValue === 1) {
                totalPresentDays += 1;
              }
              if (record.afternoonMarkValue === 1) {
                totalPresentDays += 1;
              }
            }
          }
        });
      });
      const row = {
        serialNumber: index + 1,
        studentName: each.studentName,
        admissionNo:
          each.admissionNo === "No Admission Number Yet"
            ? "N/A"
            : each.admissionNo,
        totalSchoolDays: totalPresentDays,
      };

      dates?.forEach((d) => {
        const record = each.records.find(
          (i) => i.stringAttendanceDate === d.stringAttendanceDate
        );
        row[`${d.stringAttendanceDate}_morning`] = record
          ? record.morningMarkValue === 1
            ? "✔"
            : "✗"
          : "";
        row[`${d.stringAttendanceDate}_afternoon`] = record
          ? record.afternoonMarkValue === 1
            ? "✔"
            : "✗"
          : "";
      });

      const addedRow = sheet.addRow(row);
      // Center align the values for each cell in the row
      addedRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Attendance-report.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <Box
      sx={{
        position: "fixed",
        backgroundColor: "white",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        zIndex: 100000,
        padding: "10px 14px",
        marginBottom: "0.7rem",
      }}
    >
      <Box
        sx={{
          bgcolor: "#671E78",
          p: 1,
          borderRadius: "20px",
          pb: 2,
          mt: 2,
          // mb: 3,
        }}
      >
        <Typography
          variant="body"
          sx={{
            color: "#fff",
            fontFamily: "Gilroy-Medium",
            fontSize: {
              xs: "10px",
              md: "16px",
              sm: "14px",
              lg: "16px",
            },
          }}
        >
          Attendance
        </Typography>
      </Box>
      <Box
        sx={{
          bgcolor: "#671E78",
          width: "40%",
          margin: "0 auto",
          p: 1,
          borderRadius: "20px",
          position: "relative",
          top: "-8px",
          pt: 0,
        }}
      >
        <Typography
          variant="body"
          sx={{
            color: "#fff",
            fontSize: {
              lg: "16px",
              md: "16px",
              sm: "14px",
              xs: "10px",
            },
            fontFamily: "Gilroy-Regular",
          }}
        >
          {/* {resultData.studentDetails.term} */}
          {note}
        </Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "white",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          zIndex: 100001,
          padding: "10px 14px",
          marginBottom: "0.7rem",
          overflowY: "scroll",
        }}
      >
        <GiCancel
          style={{
            position: "absolute",
            left: "10px",
            top: "10px",
            cursor: "pointer",
            zIndex: 494994849,
          }}
          onClick={close}
        />
        <Box mb={10} sx={{ position: "relative" }}>
          <div className="flex items-center gap-2 justify-end">
            <button
              className="flex gap-1 items-center border p-1"
              onClick={exportExcelFile}
            >
              <LuFileSpreadsheet className="w-6 h-6" />
              Export to excel
            </button>
          </div>
          <Box id="spreadsheet">
            <Box
              sx={{
                textAlign: "center",
                margin: "0 auto",
                width: { lg: "97%", md: "97%", sm: "98%", xs: "99%" },
              }}
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar src={resultData.schoolDetail.logo} />
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "Gilroy-Heavy",
                    fontSize: { xs: "14px", lg: "20px" },
                  }}
                >
                  {resultData.schoolDetail.schoolname}
                </Typography>
              </Box>
              <Box mb={2} sx={{ margin: "0 auto", width: "90%" }}>
                <Grid
                  container
                  sx={{
                    borderRadius: "20px",
                    fontFamily: "Gilroy-Regular",
                  }}
                >
                  <Grid item xs={12} md={6} sm={6} lg={6}>
                    <Box
                      sx={{
                        my: { lg: 2, xs: 1 },
                        pr: { lg: 3.5, xs: 0 },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        borderRight: {
                          lg: "1px solid #671e78",
                          xs: "none",
                          md: "1px solid #671e781a",
                          sm: "1px solid #671e781a",
                        },
                        textAlign: { lg: "right", xs: "center" },
                      }}
                    >
                      <span style={{ fontSize: "14px", marginBottom: "10px" }}>
                        {resultData.schoolDetail.schooladdress}

                        <AllInboxIcon
                          sx={{ ml: 1, mb: -0.6, fontSize: "18px" }}
                        />
                      </span>{" "}
                      <span style={{ fontSize: "14px" }}>
                        {resultData.schoolDetail.schoolemail}
                        <EmailIcon sx={{ ml: 1, mb: -0.6, fontSize: "18px" }} />
                      </span>{" "}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sm={6}
                    sx={{ textAlign: "left", my: { lg: 2, xs: -0.6 } }}
                  >
                    <Box
                      sx={{
                        textAlign: { lg: "left", xs: "center" },
                        ml: { lg: 2, xs: 0 },
                      }}
                    >
                      <span style={{ fontSize: "14px", marginBottom: "12px" }}>
                        <LocalPhoneIcon
                          sx={{ mr: 1, mb: -0.6, fontSize: "18px" }}
                        />
                        {resultData.schoolDetail.schoolphone}
                      </span>{" "}
                      <br />
                      <span style={{ fontSize: "14px" }}>
                        <LanguageIcon
                          sx={{ mr: 1, mb: -0.6, fontSize: "18px" }}
                        />
                        {resultData.schoolDetail.portal_url}
                      </span>{" "}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  bgcolor: "#671E78",
                  p: 1,
                  borderRadius: "20px",
                  pb: 2,
                  mt: 2,
                  // mb: 3,
                }}
              >
                <Typography
                  variant="body"
                  sx={{
                    color: "#fff",
                    fontFamily: "Gilroy-Medium",
                    fontSize: {
                      xs: "10px",
                      md: "16px",
                      sm: "14px",
                      lg: "16px",
                    },
                  }}
                >
                  Atendance
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: "#671E78",
                  width: "40%",
                  margin: "0 auto",
                  p: 1,
                  borderRadius: "20px",
                  position: "relative",
                  top: "-8px",
                  pt: 0,
                }}
              >
                <Typography
                  variant="body"
                  sx={{
                    color: "#fff",
                    fontSize: {
                      lg: "16px",
                      md: "16px",
                      sm: "14px",
                      xs: "10px",
                    },
                    fontFamily: "Gilroy-Regular",
                  }}
                >
                  {/* {resultData.studentDetails.term} */}
                  {note}
                </Typography>
              </Box>
            </Box>
            {/* table*/}
            <Box
              mt={3}
              className="sheeet_table card_class"
              sx={{ overflowX: "scroll" }}
            >
              <table
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                  overflowX: "scroll",
                }}
              >
                <tr className="att">
                  <th>S/N</th>
                  <th className="name-head">Name</th>
                  <th className="admNo">Admission no</th>
                  {dates?.map((date) => (
                    <th key={date.id}>
                      <div>
                        <p>{date.attendanceDay}</p>
                        <p>{date.stringAttendanceDate}</p>
                        <div
                          className={`flex gap-1 items-center mt-4 border-t-2 border-[#671E78] p-2`}
                        >
                          <span className="">Morning</span>
                          <span className="h-4 w-[2px] bg-black" />
                          <span>Noon</span>
                        </div>
                      </div>
                    </th>
                  ))}
                  <th>Total Attendance ({dates.length * 2})</th>
                </tr>
                {resultData?.studentAttendances.map((student, index) => {
                  let totalPresentDays = 0;

                  const startDate = new Date(dates[0]?.attendanceDate);

                  dates?.forEach((date) => {
                    student.records.forEach((record) => {
                      if (
                        record.stringAttendanceDate ===
                        date.stringAttendanceDate
                      ) {
                        if (new Date(record.attendanceDate) >= startDate) {
                          if (record.morningMarkValue === 1) {
                            totalPresentDays += 1;
                          }
                          if (record.afternoonMarkValue === 1) {
                            totalPresentDays += 1;
                          }
                        }
                      }
                    });
                  });
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{student.studentName}</td>
                      <td>{student.admissionNo}</td>
                      {dates?.map((date, dateIndex) => {
                        return (
                          <td key={dateIndex}>
                            {student.records.map(
                              (record) =>
                                record.stringAttendanceDate ===
                                  date.stringAttendanceDate && (
                                  <div
                                    className={`flex gap-1 items-center   justify-center  pr-2`}
                                  >
                                    {record.morningMarkValue === 1 ? (
                                      <span className="">{"✔"}</span>
                                    ) : record.morningMarkValue === -1 ? (
                                      <p></p>
                                    ) : (
                                      <span className="text-xl  text-red-500">
                                        ✗
                                      </span>
                                    )}
                                    <span className="bg-black h-[3.5rem] w-[1px] mx-4" />
                                    {record.afternoonMarkValue === 1 ? (
                                      <span className="">{"✔"}</span>
                                    ) : record.afternoonMarkValue === -1 ? (
                                      <p></p>
                                    ) : (
                                      <span className="text-xl  text-red-500">
                                        ✗
                                      </span>
                                    )}
                                  </div>
                                )
                            )}
                          </td>
                        );
                      })}
                      <td>{totalPresentDays}</td>
                    </tr>
                  );
                })}
              </table>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AttendanceReportSheet;
