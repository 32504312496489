import { BsFileSpreadsheet, BsPeople, BsGlobe2 } from "react-icons/bs";
import { SiGoogleclassroom } from "react-icons/si";
import { BiUser } from "react-icons/bi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { RiUserSettingsLine, RiParentFill } from "react-icons/ri";
import { PiStudentFill } from "react-icons/pi";
import { MdSmartphone } from "react-icons/md";
import { FaCalendarCheck } from "react-icons/fa";
import { TbDatabaseSearch } from "react-icons/tb";
import { GiBed } from "react-icons/gi";
export const UserGuideData = [
  {
    id: 1,
    label: "User Guide Setup",
    icon: <MdSmartphone />,
  },
];
export const LasmabData = [
  {
    id: 1,
    label: "Web Info",
    link: "/lasmab-setup-webinfo",
    icon: <BsGlobe2 />,
  },
];

export const AcademicData = [
  {
    id: 1,
    label: "Result",
    icon: <BsFileSpreadsheet />,
    link: "/result-summary",
  },
  {
    id: 2,
    label: "Class",
    icon: <SiGoogleclassroom />,
    link: "/class-summary",
  },
  {
    id: 3,
    label: "Student",
    icon: <BsPeople />,
    link: "/student-setup",
  },
  {
    id: 4,
    label: "Subjects",
    icon: <SiGoogleclassroom />,
    link: "/class-summary",
  },
  {
    id: 5,
    label: "Attendance",
    icon: <FaCalendarCheck />,
  },
];

export const NonAcademicData = [
  {
    id: 1,
    label: "Hostel",
    icon: <GiBed />,
    link: "/hostel-management",
  },
];

export const UsersData = [
  {
    id: 1,
    label: "Staff Roles",
    link: "/staff-setup",
    icon: <BiUser />,
  },
  {
    id: 2,
    label: "Staff Account Setup",
    link: "/staff-account-setup",
    icon: <RiUserSettingsLine />,
  },
  {
    id: 7,
    label: "Principal Setup",
    link: "/principal-setup",
    icon: <BsFillPersonLinesFill />,
  },
  {
    id: 6,
    label: "Vice-Principal Setup",
    link: "/vp-setup",
    icon: <BsFillPersonLinesFill />,
  },
  {
    id: 8,
    label: "HOD Setup",
    link: "/hod-setup",
    icon: <BsFillPersonLinesFill />,
  },
  {
    id: 9,
    label: "Year-Tutor Setup",
    link: "/year-tutor-setup",
    icon: <BsFillPersonLinesFill />,
  },
  {
    id: 5,
    label: "House-Manager Setup",
    link: "/house-manager-setup",
    icon: <BsFillPersonLinesFill />,
  },
  {
    id: 3,
    label: "Student Setup",
    icon: <PiStudentFill />,
  },
  {
    id: 4,
    label: "Parent Setup",
    icon: <RiParentFill />,
  },
  {
    id: 10,
    label: "Audit Trail",
    icon: <TbDatabaseSearch />,
  },
];
