import React, { useState } from "react";
import {
  BigHeader,
  InlineLabelGroup,
  InlineLabelWrapper,
  PaySlipDiv,
  PaySlipHeader,
  PaySlipImgText,
  SmallHeader,
  TabContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import {
  AuthButton,
  InputWrapper,
  ButtonFlex,
} from "../../pages/auth/auth.style";
import GlobalStyles from "../../assets/globalStyle";
import DatePickerComponent from "../datepicker/DatePicker";
import colors from "../../assets/colors.json";
import { useMediaQuery } from "react-responsive";
import Modal from "../modal/Modal";
import { AiFillFileExcel } from "react-icons/ai";
import { SiAdobeacrobatreader, SiAdobeaftereffects } from "react-icons/si";
import { BsFillPrinterFill } from "react-icons/bs";
import Avatar from "../../assets/school-logo.png";
import { SmallText } from "../../pages/auth/Login.style";
const PaySlip = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [dob, setDob] = useState(new Date(2014, 0, 3));
  const [showFilter, setShowFilter] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const content = (
    <>
      <PaySlipHeader>
        <PaySlipDiv>
          <div
            style={{
              width: isMobile ? "100%" : "50%",
              display: "inline-flex",
              justifyContent: "center",
              margin: "0.5rem 0rem",
            }}
          >
            <AiFillFileExcel
              style={{ fontSize: "23px", color: "white", marginRight: "5px" }}
            />
            <SiAdobeacrobatreader
              style={{ fontSize: "23px", color: "white", marginRight: "5px" }}
            />
            <BsFillPrinterFill
              style={{ fontSize: "23px", color: "white", marginRight: "5px" }}
            />
          </div>

          <PaySlipImgText>
            <div>
              <img src={Avatar} alt="" />
            </div>
            <span>
              LAGOS ANGLICAN SCHOOLS <br />
              MANAGEMENT
            </span>
          </PaySlipImgText>
        </PaySlipDiv>
        <PaySlipDiv>
          <BigHeader
            style={{ color: "white", fontWeight: "bold", textAlign: "center" }}
          >
            PaySlip
          </BigHeader>
        </PaySlipDiv>
        <PaySlipDiv>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div style={{ width: isMobile ? "50%" : "40%" }}>
              <SmallText color="white">Employee Name:</SmallText>
            </div>
            <div style={{ width: isMobile ? "50%" : "40%" }}>
              <SmallText color="white" style={{ fontWeight: "400" }}>
                Joe James
              </SmallText>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div style={{ width: isMobile ? "50%" : "40%" }}>
              <SmallText color="white">Staff ID:</SmallText>
            </div>
            <div style={{ width: isMobile ? "50%" : "40%" }}>
              <SmallText color="white" style={{ fontWeight: "400" }}>
                CMS/2022/001
              </SmallText>
            </div>
          </div>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div style={{ width: isMobile ? "50%" : "40%" }}>
              <SmallText color="white">Unit</SmallText>
            </div>
            <div style={{ width: isMobile ? "50%" : "40%" }}>
              <SmallText color="white" style={{ fontWeight: "400" }}>
                Staff
              </SmallText>
            </div>
          </div>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: isMobile ? "center" : "end",
            }}
          >
            <div style={{ width: isMobile ? "50%" : "40%" }}>
              <SmallText color="white">Period:</SmallText>
            </div>
            <div style={{ width: isMobile ? "50%" : "40%" }}>
              <SmallText color="white" style={{ fontWeight: "400" }}>
                Sept, 2022
              </SmallText>
            </div>
          </div>
        </PaySlipDiv>
      </PaySlipHeader>

      <div style={{ padding: isMobile ? "0.3rem 0.8rem" : "1.1rem 1.9rem" }}>
        <table style={{ borderRadius: "0px", marginTop: "0.8rem" }}>
          <tr
            className="table-head"
            style={{ backgroundColor: colors.primary, color: "white" }}
          >
            <th colSpan={1} style={{ paddingLeft: "2.5rem" }}>
              Basic
            </th>
            <th colSpan={1}></th>
          </tr>
          <tr>
            <td style={{ paddingLeft: "2.5rem" }}>Basic</td>
            <td style={{ textAlign: "center" }}>
              <span
                style={{
                  background: colors.primary,
                  color: "white",

                  padding: "0.4rem 0.6rem",
                }}
              >
                NGN20,000
              </span>
            </td>
          </tr>
        </table>
        <table style={{ borderRadius: "0px", marginTop: "0.8rem" }}>
          <tr
            className="table-head"
            style={{ backgroundColor: colors.primary, color: "white" }}
          >
            <th colSpan={1} style={{ paddingLeft: "2.5rem" }}>
              Allowance
            </th>
            <th colSpan={1}></th>
          </tr>
          <tr>
            <td style={{ paddingLeft: "2.5rem", fontWeight: "500" }}>
              Wardrope
            </td>
            <td
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              NGN20,000
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: "2.5rem", fontWeight: "500" }}>
              Furniture
            </td>
            <td
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              NGN20,000
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: "2.5rem", fontWeight: "500" }}>
              Transportation
            </td>
            <td
              style={{
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "500",
              }}
            >
              NGN20,000
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: "2.5rem", fontWeight: "bold" }}>
              Total Allowance
            </td>
            <td style={{ textAlign: "center", fontWeight: "bold" }}>
              NGN20,000
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: "2.5rem", fontWeight: "bold" }}>Gross</td>
            <td style={{ textAlign: "center" }}>
              <span
                style={{
                  background: colors.primary,
                  color: "white",

                  padding: "0.4rem 0.6rem",
                }}
              >
                NGN20,000
              </span>
            </td>
          </tr>
        </table>
        <table style={{ borderRadius: "0px", marginTop: "0.8rem" }}>
          <tr
            className="table-head"
            style={{ backgroundColor: colors.primary, color: "white" }}
          >
            <th colSpan={1} style={{ paddingLeft: "2.5rem" }}>
              Deductions
            </th>
            <th colSpan={1}></th>
          </tr>
          <tr>
            <td style={{ paddingLeft: "2.5rem", fontWeight: "500" }}>NHIS</td>
            <td
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              NGN20,000
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: "2.5rem", fontWeight: "500" }}>Tax</td>
            <td
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              NGN20,000
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: "2.5rem", fontWeight: "500" }}>
              Cooperative
            </td>
            <td
              style={{
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "500",
              }}
            >
              NGN20,000
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: "2.5rem", fontWeight: "bold" }}>
              Total Deduction
            </td>
            <td style={{ textAlign: "center", fontWeight: "bold" }}>
              NGN20,000
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: "2.5rem", fontWeight: "bold" }}>
              Total Net Pay
            </td>
            <td style={{ textAlign: "center" }}>
              <span
                style={{
                  background: colors.primary,
                  color: "white",

                  padding: "0.4rem 0.6rem",
                }}
              >
                NGN20,000
              </span>
            </td>
          </tr>
        </table>
      </div>
    </>
  );
  return (
    <>
      {showModal && (
        <Modal
          start={true}
          content={content}
          size={"large"}
          onClose={setShowModal}
          pd={"0px"}
          iColor={"white"}
        />
      )}
      <GlobalStyles />
      <SmallHeader
        style={{
          fontWeight: "bold",
          color: colors.lightGray,
          marginBottom: "1.9rem",
        }}
      >
        <span style={{ marginRight: "0.9rem" }}>MySchool {">>"}</span>
        <span style={{ marginRight: "0.9rem" }}>School - Staff info</span>
        <span style={{ marginRight: "0.9rem" }}>
          Staff Info- James John {">>"}
        </span>
        <span style={{ color: "black" }}>Payslip</span>
      </SmallHeader>

      <TabContainer style={{ marginTop: "1rem", background: colors.primary }}>
        <InlineLabelGroup>
          <InlineLabelWrapper flex={3}>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper>
                  <label
                    htmlFor=""
                    style={{
                      color: "white",
                    }}
                  >
                    Year
                  </label>
                  <DatePickerComponent
                    bg={colors.primary}
                    useBorder={true}
                    color="white"
                    selected={dob}
                    setSelected={(val) => {
                      setDob(val);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={1}>
                <InputWrapper>
                  <label
                    htmlFor=""
                    style={{
                      color: "white",
                    }}
                  >
                    Month
                  </label>
                  <DatePickerComponent
                    bg={colors.primary}
                    useBorder={true}
                    color="white"
                    selected={dob}
                    setSelected={(val) => {
                      setDob(val);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
          </InlineLabelWrapper>
          <InlineLabelWrapper flex={1}>
            <ButtonFlex>
              <AuthButton
                disabled={false}
                width={isMobile ? "100%" : "90%"}
                onClick={() => setShowModal(true)}
                color={colors.primary}
                bg="white"
              >
                {" "}
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  Generate Payslip
                </div>
              </AuthButton>
            </ButtonFlex>
          </InlineLabelWrapper>
        </InlineLabelGroup>
      </TabContainer>
    </>
  );
};

export default PaySlip;
