import React, { useEffect, useState } from "react";
import colors from "../../assets/colors.json";
import { InputWrapper, AuthButton } from "../../pages/auth/Login.style";
import Select from "../select/select";
import { genderOptions } from "../../assets/data/miscData";
import hostelApi from "../../services/hostel";
import { toast } from "react-toastify";
import ErrorService from "../../services/errorService";

const AddRoomModal = ({
  floors,
  hostel,
  getRooms,
  user,
  admToken,
  setOpenAddModal,
  setOpenEditModal,
  getHostels,
  edit = false,
  selectedRoom,
}) => {
  const [loading, setLoading] = useState(false);
  const [roomObj, setRoomObj] = useState({
    floor: "",
    roomName: "",
    maxOccupant: 0,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      room_id: 0,
      room: roomObj.roomName,
      max_occupant: parseInt(roomObj.maxOccupant),
      hostel_id: hostel.hostel_id,
      schoolid: user.schoolId,
      floor_id: roomObj.floor,
    };

    const editData = {
      roomid: selectedRoom && selectedRoom?.roomid,
      roomname: roomObj.roomName,
      maxOccupant: parseInt(roomObj.maxOccupant),
      hostelId: hostel.hostel_id,
      schoolId: user.schoolId,
      floorId: roomObj.floor,
      createdBy: user.name,
      createdDate: new Date(),
      gender: hostel.gender,
      floorName: selectedRoom?.floorName,
    };

    try {
      setLoading(true);
      const res = await (edit
        ? hostelApi.editHostelRoom(admToken, editData)
        : hostelApi.createHostelRoom(admToken, data));
      if (res?.status && res?.statusCode === 2) {
        toast.success(res.message);
        getRooms();
        getHostels();
        !edit && setOpenAddModal(false);
        edit && setOpenEditModal(false);
        setLoading(false);
      } else if (res?.status === 500 || res?.status === 400 || !res?.status) {
        ErrorService.displayErrorAlert(res);
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (edit && selectedRoom) {
      setRoomObj({
        floor: selectedRoom.floorId,
        roomName: selectedRoom.roomname,
        maxOccupant: selectedRoom.maxOccupant,
      });
    }
  }, [edit, selectedRoom]);

  return (
    <form onSubmit={handleSubmit} className="mt-8">
      <header className="flex justify-center items-center flex-col pb-2 border-b-[0.5px] border-gray-400 px-4 w-[calc(100%+18px)] lg:w-[calc(100%+79px)] -ml-[9px] lg:-ml-[39px]">
        <h1 className="text-2xl">
          {" "}
          {edit ? `Edit Room (${selectedRoom.roomname}) ` : `Add room`}
        </h1>
        <span
          style={{ background: colors.primary }}
          className="py-2 px-3 flex justify-center items-center text-white rounded-[20px]"
        >
          {hostel?.hostel_name}
        </span>
      </header>
      <div className="grid grid-cols-1 gap-2 md:gap-x-7 xl:gap-x-24 md:grid-cols-2">
        <div className="col-span-2">
          <InputWrapper>
            <label>Floor</label>
            <Select
              title="Floor Name"
              index="floorName"
              thirdOption={"floorId"}
              selected={roomObj.floor}
              options={floors}
              setSelected={(val) => {
                setRoomObj({
                  ...roomObj,
                  floor: val.floorId,
                });
              }}
              // color="white"
            />
          </InputWrapper>
        </div>
        <InputWrapper>
          <label>Room Name</label>
          <input
            type="text"
            name=""
            id=""
            value={roomObj.roomName}
            onChange={(e) =>
              setRoomObj({
                ...roomObj,
                roomName: e.target.value,
              })
            }
          />
        </InputWrapper>
        {/* <InputWrapper>
          <label>Gender</label>
          <Select
            title="Gender"
            index="gender"
            selected={roomObj.gender}
            options={genderOptions}
            setSelected={(val) => {
              setRoomObj({
                ...roomObj,
                gender: val.gender,
              });
            }}
            // color="white"
          />
        </InputWrapper> */}
        <InputWrapper>
          <label>Max Occupants</label>
          <input
            type="number"
            name="occupants"
            id="occupants"
            value={roomObj.maxOccupant}
            onChange={(e) =>
              setRoomObj({
                ...roomObj,
                maxOccupant: e.target.value,
              })
            }
          />
        </InputWrapper>
      </div>
      <AuthButton
        bg={colors.primary}
        disabled={
          loading ||
          !roomObj.floor ||
          !roomObj.roomName ||
          // !roomObj.gender ||
          !roomObj.maxOccupant
        }
        className="!rounded-md w-full"
        type="submit"
      >
        {loading ? "Loading..." : `${edit ? "Edit" : "Add"} Room`}
      </AuthButton>
    </form>
  );
};

export default AddRoomModal;
