import React, { useState } from "react";
import {
  TabContainer,
  TabContent,
  TabLink,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import {
  Certifications,
  Education,
  Experience,
  NOK,
  Personal,
} from "../profile";

const StaffForm = () => {
  const [tab, setTab] = useState(1);

  return (
    <TabContainer>
      <TabLinkContainer>
        <TabLink isActive={tab === 1} onClick={() => setTab(1)} minWidth="18%">
          <p>Personal Data</p>
        </TabLink>
        <TabLink isActive={tab === 2} onClick={() => setTab(2)} minWidth="18%">
          <p>Education Qualification</p>
        </TabLink>
        <TabLink isActive={tab === 3} onClick={() => setTab(3)} minWidth="18%">
          <p>Experience History</p>
        </TabLink>
        <TabLink isActive={tab === 4} onClick={() => setTab(4)} minWidth="18%">
          <p>Certifications</p>
        </TabLink>
        <TabLink isActive={tab === 5} onClick={() => setTab(5)} minWidth="18%">
          <p>Next of Kin</p>
        </TabLink>
      </TabLinkContainer>
      <TabContent>
        {tab === 1 && <Personal />}
        {tab === 2 && <Education />}
        {tab === 3 && <Experience />}
        {tab === 4 && <Certifications />}
        {tab === 5 && <NOK />}
      </TabContent>
    </TabContainer>
  );
};

export default StaffForm;
