import React, { useState } from "react";
import { BsSearch } from "react-icons/bs";
import { IconButton, MenuItem, MenuList } from "@mui/material";
import Pagination from "../pagination_one/pagination";
import colors from "../../assets/colors.json";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "../../assets/Images/avatar.jpg";
import { useMediaQuery } from "react-responsive";
import { AuthButton } from "../../pages/auth/Login.style";
import { Box } from "@mui/system";
import Popover from "@mui/material/Popover";
import Modal from "../modal/Modal";
import { ImgTextDiv, StatusBar } from "../../pages/student_mgt/stu_mgt.style";
import {
  Icon,
  Input,
  TabContainer,
  TabLinkContainer,
  Search,
  AccountSummaryWrapper,
  BigHeader,
  InlineFormDiv,
  FormFlex,
  FormFlexDiv,
  TabContentWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
import { InputWrapper } from "../../pages/auth/auth.style";
import Select from "../select/select";

// const initialObj = {
//   studentId: "",
//   bookTitle: "string",
//   librarySection: "string",
//   rowNo: "string",
//   author: "string",
//   publisher: "string",
//   isbn: "string",
//   placeOfPublication: "string",
//   editor: "string",
//   edition: "string",
//   copywrightDate: "2023-07-01T13:42:27.913Z",
//   category: "string",
//   coverType: "string",
//   vendor: "string",
//   student: "string",
//   name: "string",
//   admissionID: "string",
//   class: "string",
//   arm: "string",
//   noOfDay: 0,
//   remark: "string",
//   status: "string",
// };
const ApplicationTable = ({ showModal, setShowModal }) => {
  // const [obj, setObj] = useState(initialObj);
  const [pageCount, setPageCount] = useState(50);
  const [currentPage, setCurrentPage] = useState(2);
  // const [showForm, setShowForm] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isTab = useMediaQuery({ maxWidth: 992 });
  // const isMobile = useMediaQuery({ maxWidth: 768 });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [opt, setOpt] = useState("");

  // const [showModal, setShowModal] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const optionsArr = [
    {
      id: 1,
      name: "Value 1",
    },
    {
      id: 2,
      name: "Value 2",
    },
  ];
  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }}>Search Book</BigHeader>
      <TabContentWrapper>
        <InlineFormDiv width="100%" jc="space-between" ai="center">
          <div style={{ width: isTab ? "100%" : "75%" }}>
            <InputWrapper>
              <label htmlFor="">Book Title</label>
              <input
                type="text"
                value={"Hello World"}
                placeholder="Book Title"
              />
              <div style={{ width: "100%", textAlign: "end" }}>
                <span
                  style={{
                    fontSize: "12px",
                    color: "green",
                    fontWeight: "bold",
                  }}
                >
                  2 Copies Found
                </span>
              </div>
            </InputWrapper>
          </div>
          <AuthButton
            mt="1rem"
            width={isTab ? "100%" : "20%"}
            onClick={null}
            bg={colors.primary}
          >
            Choose
          </AuthButton>
        </InlineFormDiv>
        <InputWrapper>
          <label htmlFor="">Library Section</label>
          <input
            type="text"
            placeholder="Library Section"
            value={"Hello World"}
            onChange={(e) => console.log(e.target.value)}
          />
        </InputWrapper>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Library Section</label>
              <Select
                title="School"
                index="name"
                selected={"Value 1"}
                options={optionsArr}
                setSelected={(val) => {
                  console.log(val);
                  setOpt(val.name);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Row No</label>
              <Select
                title="School"
                index="name"
                selected={opt}
                options={optionsArr}
                setSelected={(val) => {
                  console.log(val);
                  setOpt(val.name);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
      </TabContentWrapper>
      <div
        style={{
          padding: "0.9rem 1.4rem",
          background: colors.primary,
          color: "white",
        }}
      >
        Book Information
      </div>
      <TabContentWrapper>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Author</label>
              <input
                type="text"
                placeholder="Book Title"
                value={"Hello World"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Publisher</label>
              <input
                type="text"
                placeholder="Book Publisher"
                value={"Hello World"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">ISBN</label>
              <input
                type="text"
                placeholder="ISBN"
                value={"Hello World"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Place Of Publication</label>
              <input
                type="text"
                placeholder="Book Title"
                value={"Hello World"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Editor</label>
              <input
                type="text"
                placeholder="Editor"
                value={"Hello World"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Edition</label>
              <input
                type="text"
                placeholder="Book Publisher"
                value={"Hello World"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Copywrite Date</label>
              <input
                type="text"
                placeholder="Copywrite Date"
                value={"Hello World"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Category</label>
              <Select
                title="Category"
                index="name"
                selected={opt}
                options={optionsArr}
                setSelected={(val) => {
                  console.log(val);
                  setOpt(val.name);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Cover Type</label>
              <Select
                title="Cover Type"
                index="name"
                selected={opt}
                options={optionsArr}
                setSelected={(val) => {
                  console.log(val);
                  setOpt(val.name);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Vendor</label>
              <Select
                title="Vendor"
                index="name"
                selected={opt}
                options={optionsArr}
                setSelected={(val) => {
                  console.log(val);
                  setOpt(val.name);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
      </TabContentWrapper>
      <div
        style={{
          padding: "0.9rem 1.4rem",
          background: colors.primary,
          color: "white",
        }}
      >
        Student Information
      </div>
      <TabContentWrapper>
        <InlineFormDiv width="100%" jc="space-between" ai="center">
          <div style={{ width: isTab ? "100%" : "75%" }}>
            <InputWrapper>
              <label htmlFor="">Student</label>
              <input
                type="text"
                value={"Hello World"}
                placeholder="Search by name or admission ID"
              />
              <div style={{ width: "100%", textAlign: "end" }}>
                <span
                  style={{
                    fontSize: "12px",
                    color: "green",
                    fontWeight: "bold",
                  }}
                >
                  2 Students Found
                </span>
              </div>
            </InputWrapper>
          </div>
          <AuthButton
            mt="1rem"
            width={isTab ? "100%" : "20%"}
            onClick={null}
            bg={colors.primary}
          >
            Search
          </AuthButton>
        </InlineFormDiv>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Name</label>
              <input
                type="text"
                placeholder="Student's Name"
                value={"Hello World"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Admission ID</label>
              <input
                type="text"
                placeholder="Book Publisher"
                value={"Hello World"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Class</label>
              <input
                type="text"
                placeholder="Student's Class"
                value={"Hello World"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Arm</label>
              <input
                type="text"
                placeholder="Arm"
                value={"Hello World"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">No of Days</label>
              <input
                type="text"
                placeholder="Editor"
                value={"Hello World"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>{" "}
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Remark</label>
              <input
                type="text"
                placeholder="Book Publisher"
                value={"Hello World"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <AuthButton width={isTab ? "100%" : "80%"} onSubmit={null}>
            <div>
              <span style={{ fontWeight: "bold" }}>Apply</span>
            </div>
            {/* Send Code */}
          </AuthButton>
        </div>
      </TabContentWrapper>
    </>
  );
  return (
    <div>
      {showModal && (
        <Modal
          start={true}
          show={showModal}
          content={content}
          size={"large"}
          onClose={setShowModal}
          pd={"0px"}
        />
      )}
      <TabContainer
        style={{ marginTop: "0px", borderRadius: "0px", border: "none" }}
      >
        <TabLinkContainer
          bg="#F0E8F1"
          style={{ position: "relative", borderRadius: "0px" }}
        >
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search " />
          </Input>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table style={{ borderRadius: "0px" }}>
            <tr
              className="table-head"
              style={{ backgroundColor: "#F0E8F1", color: "black" }}
            >
              <th>
                <input type="checkbox" />
              </th>
              <th>Admission No</th>
              <th>Student Name </th>
              <th>Booking Name</th>
              <th>Lending Days</th>
              <th>Return Date</th>
              <th>Returned</th>
              <th>Condition</th>
              <th></th>
            </tr>
            <tr>
              <td>
                <input type="checkbox" />
              </td>
              <td>CMS/2022/001</td>
              <td>
                <ImgTextDiv>
                  <img src={Avatar} alt="" />
                  <span>Adekitan Sandra</span>
                </ImgTextDiv>
              </td>
              <td>Organic Chemistry</td>
              <td>12</td>
              <td>12/04/2022</td>
              <td>
                <StatusBar status="saved">Yes</StatusBar>
              </td>
              <td>
                <StatusBar>Damage</StatusBar>
              </td>
              <td>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              </td>
            </tr>
          </table>

          <div
            style={{
              display: "flex",
              marginTop: "1rem",
              justifyContent: "end",
              padding: "10px 20px",
            }}
          >
            <button
              style={{
                borderRadius: "8px",
                // padding: "8px",
                minWidth: "121px",
                color: "white",
                backgroundColor: colors.primary,
                cursor: "pointer",
                lineHeight: "2.125rem",
                border: `1px solid ${colors.primary}`,
                outline: "none",
                fontWeight: "bold",
              }}
            >
              Approve
            </button>
            <button
              style={{
                marginLeft: "0.5rem",
                borderRadius: "8px",
                fontSize: "16px",
                // padding: "8px",
                minWidth: "121px",
                backgroundColor: "white",
                color: colors.primary,
                cursor: "pointer",
                lineHeight: "2.125rem",
                border: `1px solid ${colors.primary}`,
                outline: "none",
                fontWeight: "bold",
              }}
            >
              Decline
            </button>
          </div>
        </AccountSummaryWrapper>
      </TabContainer>
      <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Pagination
          pageCount={pageCount > 1 && pageCount}
          range={1}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem>Activate</MenuItem>
            <MenuItem>Edit</MenuItem>
            <MenuItem>Delete</MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </div>
  );
};

export default ApplicationTable;
