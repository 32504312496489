import { Box, Grid, InputLabel, TextField } from '@mui/material'
import React from 'react'

const SocialData = () => {
  return (
<Box sx={{pb:4}}>
  <Grid container>
    <Grid item lg={7} sm={12} xs={12}>
    <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontWeight: "bolder",
                  color: "#000",
                  fontSize:'12px',
                  fontFamily: "Gilroy-Medium",
                }}
              >
               Position of child in the family
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
    </Grid>
    <Grid item lg={7} sm={12} xs={12}>
    <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontWeight: "bolder",
                  color: "#000",
                  fontSize:'12px',
                  fontFamily: "Gilroy-Medium",
                }}
              >
             Career ambition of your child (list in order of priority)
              </InputLabel>
              <TextField
                fullWidth
                multiline
                rows={5}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
    </Grid>
    <Grid item lg={7} sm={12} xs={12}>
    <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontWeight: "bolder",
                  color: "#000",
                  fontSize:'12px',
                  fontFamily: "Gilroy-Medium",
                }}
              >
               Does the child bed wet?
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
    </Grid>
    <Grid item lg={7} sm={12} xs={12}>
    <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontWeight: "bolder",
                  color: "#000",
                  fontSize:'12px',
                  fontFamily: "Gilroy-Medium",
                }}
              >
               Special comment(s) worthy of Note on child.
              </InputLabel>
              <TextField
                fullWidth
                multiline
                rows={3}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
    </Grid>
    <Grid item lg={7} sm={12} xs={12}>
    <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontWeight: "bolder",
                  color: "#000",
                  fontSize:'12px',
                  fontFamily: "Gilroy-Medium",
                }}
              >
               Child known ailment
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
    </Grid>
    <Grid item lg={7} sm={12} xs={12}>
    <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontWeight: "bolder",
                  color: "#000",
                  fontSize:'12px',
                  fontFamily: "Gilroy-Medium",
                }}
              >
               Child's Allergic reaction (if any)
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
    </Grid>
    <Grid item lg={7} sm={12} xs={12}>
    <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontWeight: "bolder",
                  color: "#000",
                  fontSize:'12px',
                  fontFamily: "Gilroy-Medium",
                }}
              >
              Genotype
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
    </Grid>
    <Grid item lg={7} sm={12} xs={12}>
    <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontWeight: "bolder",
                  color: "#000",
                  fontSize:'12px',
                  fontFamily: "Gilroy-Medium",
                }}
              >
               Genotype
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
    </Grid>
  </Grid>
</Box>
  )
}

export default SocialData