import React from "react";
import GlobalStyles from "../../assets/globalStyle";
import {
  BigHeader,
  Container,
  SmallHeader,
} from "../Dashboard/styles/dashboard.style";
import { TabRoute, TabRouteContainer } from "../student_mgt/stu_mgt.style";
import { Link, Outlet, useLocation } from "react-router-dom";
import Title from "../../components/Dashboard/Title";
const Fleet = () => {
  const location = useLocation();
  return (
    <Container>
      <GlobalStyles />
      <div>
    <Title title="Fleet Management"/>
      </div>

      <TabRouteContainer>
        <TabRoute isActive={location.pathname.includes("vehicle")}>
          <Link to={"/fleet/vehicle"}>Vehicle Setup</Link>
        </TabRoute>{" "}
        <TabRoute isActive={location.pathname.includes("log-issue")}>
          <Link to={"/fleet/log-issue"}>Log Issue/Request</Link>
        </TabRoute>
      </TabRouteContainer>
      <Outlet />
    </Container>
  );
};

export default Fleet;
