/* eslint-disable array-callback-return */
import { useState, useCallback, useEffect } from "react";
import {
  TabContainer,
  InlineLabelGroup,
  InlineLabelWrapper,
  AccountSummaryWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
import moment from "moment";
import Avatar from "../../assets/Images/avatar.jpg";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { BiCheck } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import Loader from "../pageLoader/loader";
import {
  getSessions,
  getTerms,
  TeacherClasses,
  getAttendanceDates,
  StudentsAttendance,
  postMultipleAttendance,
  attendanceStudent,
  postSingleAttendance,
  getReportAttendanceDates,
} from "../../services/Subject";
import colors from "../../assets/colors.json";
import { useSelector } from "react-redux";
import ErrorService from "../../services/errorService";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import {
  InputWrapper,
  SmallText,
  AuthButton,
} from "../../pages/auth/auth.style";
import Select from "../select/select";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import FormLoader from "../FormLoader";
import { ImgTextDiv } from "../../pages/student_mgt/stu_mgt.style";
import Modal from "../modal/Modal";
import AttCalendar from "../AttendanceCalendar/AttCalendar";
import { checkAccess } from "../../services/utils";
import { Pagination } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CreateIcon from "@mui/icons-material/Create";
import CloseIcon from "@mui/icons-material/Close";
import { BsEye } from "react-icons/bs";
import TeacherAttCalendar from "../AttendanceCalendar/TeacherAttCalendar";

const ClassAttendance = () => {
  const [showSelectDateModal, setShowSelectDateModal] = useState(false);
  const [prevAtt, setPrevAtt] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [loading, setloading] = useState(false);
  const [period, setPeriod] = useState({ id: "", name: "" });
  const [showForm, setShowForm] = useState(true);
  const [loadClass, setLoadClass] = useState(false);
  const [datesLoading, setDatesLoading] = useState(false);
  const [terms, setTerms] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [classes, setClasses] = useState([]);
  const [classObj, setClassObj] = useState({
    id: "",
    name: "",
  });
  const [session, setSession] = useState({
    id: "",
    name: "",
  });
  const [term, setTerm] = useState({
    id: "",
    name: "",
  });
  const periods = [
    { id: 1, name: "Morning" },
    {
      id: 2,
      name: "Afternoon",
    },
  ];
  const [dates, setDates] = useState([]);
  const [date, setDate] = useState({
    id: "",
    name: "",
    date: "",
  });
  const [singleSaveLoading, setSingleSaveLoading] = useState(false);
  const [singleAttendanceValue, setSingleAttendanceValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRecords, setPageRecords] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const currentMonth = new Date().getMonth() + 1;
  const handleChange = (eventt, value) => {
    setCurrentPage(value);
    if (value !== currentPage) {
      getStudents(value);
    }
  };

  const schoolTerms = useCallback(async () => {
    try {
      const res = await getTerms(admToken);
      res.status &&
        res.statusCode === 2 &&
        setTerms(res.data?.filter((term) => term.isActive));
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);
  const schoolSessions = useCallback(async () => {
    try {
      const res = await getSessions(admToken);

      res.status && res.statusCode === 2 && setSessions(res.data);
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);
  const getClasses = useCallback(async () => {
    try {
      setLoadClass(true);
      const res = await TeacherClasses(admToken, user.schoolId);
      res.status && res.statusCode === 2 && setClasses(res.data);
      (res.status === 500 || res.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res);
      setLoadClass(false);
    } catch (error) {
      console.log(error);
      setLoadClass(false);
    }
  }, [admToken, user]);

  useEffect(() => {
    schoolTerms();
    schoolSessions();
    getClasses();
  }, [schoolSessions, schoolTerms, getClasses]);

  const getDates = useCallback(
    async (sessionId, termId, classId, activeMonth) => {
      setDatesLoading(true);
      try {
        const res = await getReportAttendanceDates(
          admToken,
          user.schoolId,
          sessionId,
          termId,
          classId,
          0
        );

        if (res.status && res.statusCode === 2) {
          setDates(res.data);
          if (res.data.length === 0) {
            toast.error(
              "There are no dates available to take attendance of the students"
            );
          } else {
            // Get the current date
            const today = new Date();

            // Format today's date to match the format in the array
            const formattedToday = today.toISOString().split("T")[0];

            // Filter the array based on the attendanceDate matching today's date
            const filteredArr = res.data.filter(
              (item) => item.attendanceDate.split("T")[0] === formattedToday
            );
            console.log(filteredArr);

            if (filteredArr.length > 0) {
              setDate({
                ...date,
                id: filteredArr[0].id,
                name: filteredArr[0].name,
                date: filteredArr[0].attendanceDate,
              });
            }

            setShowForm(false);
          }
        }
        (res.status === 500 || res.status === 400 || !res.status) &&
          ErrorService.displayErrorAlert(res);
      } catch (error) {
        console.log(error);
      } finally {
        setDatesLoading(false);
      }
    },
    [admToken, date, user.schoolId]
  );
  const [students, setStudents] = useState([]);

  const getStudents = useCallback(
    async (value) => {
      let data = {
        staffId: user.staffId,
        schoolId: user.schoolId,
        sessionId: session.id,
        classId: classObj.id,
        termId: term.id,
        attendanceDateId: date.id,
        periodId: period.id,
        pageSize: 10,
        pageNumber: value ?? currentPage,
      };
      try {
        setloading(true);
        const res = await StudentsAttendance(admToken, data);
        console.log(res);
        if (res.status && res.statusCode === 2) {
          setStudents(res?.data?.students);
          setPageRecords(res.totalRecord);
          if (res?.data?.students?.length === 0) {
            toast.error("No Students Available");
          }
        }
        if (!res.status && res.statusCode === 6) {
          // if (res.data.students.length === 0) {
          toast.error(res.message);
          // }
        }
        setloading(false);
      } catch (error) {
        setloading(false);
        console.log(error);
      }
    },
    [
      admToken,
      classObj.id,
      currentPage,
      date.id,
      period.id,
      session.id,
      term.id,
      user.schoolId,
      user.staffId,
    ]
  );

  const markAttendance = (id, value) => {
    // if(value === -1)
    setSingleAttendanceValue(value);
    setStudents((preArr) => {
      return preArr.map((item) => {
        if (item.studentId === id) {
          return { ...item, markedValue: value };
        }
        return item;
      });
    });
  };

  const updateMarkValue = (newValue, isChecked) => {
    // If the checkbox is checked, mark all students with the new value
    // If the checkbox is unchecked, unmark all students
    const updatedArr = students.map((item) => ({
      ...item,
      markedValue: isChecked ? newValue : -1, // Assuming -1 is the value for unmarked
    }));
    setStudents(updatedArr);
  };

  const getArr = useCallback(() => {
    const newArray = students
      .filter((item) => item.markedValue === 0 || item.markedValue === 1)
      .map((item) => ({
        studentId: item.studentId,
        isPresent: item.markedValue === 1,
      }));

    return newArray;
  }, [students]);
  const markFunction = useCallback(async () => {
    const arr = getArr();
    console.log(arr);

    try {
      let data = {
        staffId: 0,
        schoolId: user.schoolId,
        classId: classObj.id,
        attendanceDateId: date.id,
        periodId: period.id,
        attendanceValues: arr,
      };
      console.log(data);
      setFormLoading(true);
      const res = await postMultipleAttendance(admToken, data);
      console.log(res);
      setFormLoading(false);

      if (res.status && res.statusCode === 2) {
        toast.success(res.message);
        getStudents();
        // getDates(session.id, term.id, classObj.id);
      }
      if (!res.status && res.statusCode === 6) {
        toast.error(res.message);
      }

      if (
        res.status === 500 ||
        res.status === 400 ||
        res?.data?.status === 400 ||
        !res.status
      ) {
        ErrorService.displayErrorAlert(res.data);
      }
    } catch (error) {
      console.log(error);
      setFormLoading(false);
    }
  }, [
    admToken,
    classObj.id,
    date.id,
    getArr,
    getStudents,
    period.id,
    user.schoolId,
  ]);
  const studentAttFunc = useCallback(
    async (id) => {
      toast.info("Please wait...");
      console.log(id);
      let data = {
        staffId: user.staffId,
        schoolId: user.schoolId,
        sessionId: session.id,
        classId: classObj.id,
        termId: term.id,
        attendanceDateId: date.id,
        periodId: period.id,
      };
      try {
        const res = await attendanceStudent(admToken, id, data);
        console.log(res);
        if (res.status === true && res.statusCode === 2) {
          setPrevAtt(res.data);
          if (res?.data?.records.length > 0) {
            setShowModal(true);
          } else {
            toast.error("Student Has No Attendance Record ");
          }
        }
        if (
          res.status === 500 ||
          res.status === 400 ||
          res?.data?.status === 400 ||
          !res.status
        ) {
          ErrorService.displayErrorAlert(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [
      admToken,
      classObj.id,
      date.id,
      period.id,
      session.id,
      term.id,
      user.schoolId,
      user.staffId,
    ]
  );

  const updateSingleAttendance = async (item) => {
    let data = {
      staffId: user.staffId,
      schoolId: user.schoolId,
      classId: classObj.id,
      attendanceDateId: date.id,
      periodId: period.id,
      studentId: item.studentId,
      isPresent: !!+singleAttendanceValue ?? !!+item.markedValue,
    };
    setSingleSaveLoading(true);
    try {
      const res = await postSingleAttendance(admToken, data);
      if (res.status && res.statusCode === 2) {
        getStudents(currentPage);
        setEditId(item.studentId);
        setShowEdit(false);
      } else if (
        res.status === 500 ||
        res.status === 400 ||
        res.data.status === 400 ||
        !res.status
      ) {
        ErrorService.displayErrorAlert(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSingleSaveLoading(false);
    }
  };

  return (
    <>
      {showSelectDateModal && (
        <Modal
          content={
            <TeacherAttCalendar
              dates={dates}
              session={session}
              classObj={classObj}
              setDate={setDate}
              date={date}
              setShowSelectDateModal={setShowSelectDateModal}
              setDates={setDates}
              getDates={getDates}
              term={term}
              datesLoading={datesLoading}
            />
          }
          size="large"
          onClose={setShowSelectDateModal}
          pd={"0px"}
          iColor={"white"}
        />
      )}
      {showModal && prevAtt !== null && (
        <Modal
          content={
            <AttCalendar
              dataObj={prevAtt}
              session={session}
              classObj={classObj}
            />
          }
          size="large"
          onClose={setShowModal}
          pd={"0px"}
          iColor={"white"}
        />
      )}
      <div
        onClick={() => setShowForm(!showForm)}
        style={{
          backgroundColor: colors.primary,
          borderRadius: "10px",
          marginTop: "0.3rem",
          padding: "0.4rem",
          display: "flex",
          justifyContent: "space-between",
          color: "white",
          fontWeight: "bold",
        }}
      >
        <div>
          <span>
            {session.name} {session.name.length > 0 ? "-" : ""} {term.name}{" "}
            {term.name.length > 0 && classObj.name.length > 0 ? "-" : ""}
            {classObj.name}
          </span>
        </div>
        {showForm ? (
          <BiSolidDownArrow color="white" />
        ) : (
          <BiSolidUpArrow color="white" />
        )}
      </div>
      {showForm && (
        <>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              {" "}
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title="Session"
                  index="session" // no
                  selected={session.id} // false
                  options={sessions.filter((i) => i.isActive)}
                  setSelected={(val) => {
                    setDate({ ...date, id: "", name: "" });
                    setPeriod({ ...period, id: "", name: "" });
                    setClassObj({ ...classObj, id: "", name: "" });
                    setDates([]);
                    setSession({
                      ...session,
                      id: val.id,
                      name: val.session,
                    });
                    setStudents([]);
                  }}
                  // color="white"
                />
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title="Term"
                  index="term" // no
                  selected={term.id} // false
                  options={terms}
                  setSelected={(val) => {
                    setDate({ ...date, id: "", name: "" });
                    setPeriod({ ...period, id: "", name: "" });
                    setClassObj({ ...classObj, id: "", name: "" });
                    setDates([]);
                    setStudents([]);

                    setTerm({ ...term, id: val.id, name: val.term });
                    // if (session.name.length > 0) {
                    //   getDates(session.id, val.id);
                    // }
                  }}
                  // color="white"
                />
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title={loadClass ? "Loading" : "Class"}
                  index="className" // no
                  selected={classObj.id} // false
                  options={classes}
                  thirdOption="classId"
                  extraTitle="classArm"
                  setSelected={(val) => {
                    console.log(val);
                    // setAllData([]);
                    setClassObj({
                      ...classObj,
                      id: val.classId,
                      name: `${val.className} ${val.classArm}`,
                    });
                    // getStudents(val.classId);

                    if (term.name.length > 0 && session.name.length > 0) {
                      getDates(session.id, term.id, val.classId, currentMonth);
                    }
                  }}
                  // color="white"
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </>
      )}
      {datesLoading && <Loader />}
      {dates.length > 0 && (
        <TabContainer
          style={{
            marginTop: "0.5rem",
            background: colors.primary,
            paddingBottom: "10px",
            paddingTop: "10px",
          }}
        >
          <InlineLabelGroup>
            <InlineLabelWrapper flex={3}>
              <InlineLabelGroup>
                <InlineLabelWrapper flex={1}>
                  <InlineLabelGroup>
                    <InlineLabelWrapper flex={4}>
                      {/* <Select
                        color={"white"}
                        title="Date"
                        index="name"
                        options={dates}
                        selected={date.id}
                        setSelected={(val) => {
                          setDate({
                            ...date,
                            id: val.id,
                            name: val.name,
                            date: val.attendanceDate,
                          });

                          setStudents([]);
                          setPageRecords(0);
                        }}
                      /> */}
                      <div className="flex gap-2  justify-between">
                        <button
                          onClick={() => setShowSelectDateModal(true)}
                          className="flex items-center justify-center gap-2 p-2 text-white border border-white rounded w-64 text-sm"
                        >
                          {date ? date.name : "Select date"}
                          <BsFillCalendarDateFill
                            color={"white"}
                            fontSize="17px"
                          />
                        </button>
                        {isMobile && (
                          <div className="flex justify-end">
                            <SmallText color="white">
                              {moment().format("LT")}
                            </SmallText>
                          </div>
                        )}
                      </div>
                    </InlineLabelWrapper>
                    {!isMobile && (
                      <InlineLabelWrapper flex={1}>
                        <SmallText color="white">
                          {moment().format("LT")}
                        </SmallText>
                      </InlineLabelWrapper>
                    )}
                  </InlineLabelGroup>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                    <Select
                      color={"white"}
                      title="Period"
                      index="name" // no
                      selected={period.id} // false
                      options={periods}
                      setSelected={(val) => {
                        setPeriod({ ...period, id: val.id, name: val.name });
                        setStudents([]);
                        setPageRecords(0);
                        console.log("pp", val);
                      }}
                      // color="white"
                    />
                  </InputWrapper>
                </InlineLabelWrapper>
                {/* <InlineLabelWrapper flex={1}>
                  <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                    <Select
                      color={"white"}
                      title={loadClass ? "Loading" : "Class"}
                      index="className" // no
                      selected={classObj.id} // false
                      options={classes}
                      thirdOption="classId"
                      extraTitle="classArm"
                      setSelected={(val) => {
                        console.log(val);
                        // setAllData([]);
                        setClassObj({
                          ...classObj,
                          id: val.classId,
                          name: `${val.className} ${val.classArm}`,
                        });
                        setStudents([]);
                        setPageRecords(0);
                      }}
                    />
                  </InputWrapper>
                </InlineLabelWrapper> */}
              </InlineLabelGroup>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={1}>
              <AuthButton
                style={{ marginTop: "0.3rem" }}
                bg="white"
                color={colors.primary}
                onClick={() => getStudents()}
                disabled={
                  date.id === "" ||
                  period.id === "" ||
                  classObj.id === "" ||
                  loading
                }
                width={isMobile ? "100%" : "90%"}
              >
                {loading ? <FormLoader color={colors.primary} /> : "Fetch"}
              </AuthButton>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </TabContainer>
      )}

      {loading && <Loader />}

      {!loading && students.length > 0 && (
        <TabContainer style={{ borderRadius: "20px", paddingBottom: "0px" }}>
          <AccountSummaryWrapper pt="0px">
            <table>
              <tr
                className="table-head"
                style={{ backgroundColor: colors.primary, color: "white" }}
              >
                <th>Students ({pageRecords})</th>
                <th>
                  <div style={{ display: "inline-flex", alignItems: "center" }}>
                    <div>
                      <input
                        type="checkbox"
                        style={{
                          borderColor: "white",
                          marginTop: "0px",
                          backgroundColor: colors.primary,
                        }}
                        onChange={(e) => updateMarkValue(1, e.target.checked)}
                        checked={
                          students.length > 0 &&
                          students.every((i) => i.markedValue === 1)
                        }
                      />
                    </div>
                    <div style={{ marginLeft: "0.3rem" }}>
                      <p>Mark All Present</p>
                    </div>
                  </div>
                </th>
                <th>
                  <div style={{ display: "inline-flex", alignItems: "center" }}>
                    <div>
                      <input
                        type="checkbox"
                        style={{
                          borderColor: "white",
                          marginTop: "0px",
                          backgroundColor: colors.primary,
                        }}
                        onChange={(e) => updateMarkValue(0, e.target.checked)}
                        checked={
                          students.length > 0 &&
                          students.every((i) => i.markedValue === 0)
                        }
                      />
                    </div>
                    <div style={{ marginLeft: "0.3rem" }}>
                      <p>Mark All Absent</p>
                    </div>
                  </div>
                </th>
                <th className="pl-4 pr-4">
                  {" "}
                  <BsEye className="text-lg" />
                </th>
                <th>Actions</th>
              </tr>
              {/*Table Body  */}
              {students.length > 0 ? (
                students.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <ImgTextDiv>
                        <span
                          style={{ marginRight: "0.7rem", fontSize: "1.3rem" }}
                        >
                          {index + 1}
                        </span>
                        <img src={Avatar} alt="Child's Name" />
                        <span>{item.name}</span>
                      </ImgTextDiv>
                    </td>

                    <td>
                      <button
                        // className={`disabled:bg-[red]`}
                        disabled={!showEdit && item.isMarked}
                        onClick={() =>
                          markAttendance(
                            item.studentId,
                            item.markedValue === 1 ? -1 : 1
                          )
                        }
                        style={{
                          display: "inline-flex",
                          padding: "0.3rem 0.6rem",
                          borderRadius: "8px",
                          color: item.markedValue === 1 ? "white" : undefined,
                          backgroundColor:
                            item.markedValue === 1 ? colors.primary : undefined,
                          fontWeight: "bold",
                          border: `2px solid ${colors.primary}`,
                        }}
                      >
                        <BiCheck size="18px" fontWeight="bold" />
                        <p style={{ marginLeft: "0.3rem", fontSize: "14px" }}>
                          Present
                        </p>{" "}
                      </button>
                    </td>
                    <td>
                      <button
                        disabled={!showEdit && item.isMarked}
                        onClick={() =>
                          markAttendance(
                            item.studentId,
                            item.markedValue === 0 ? -1 : 0
                          )
                        }
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          padding: "0.3rem 0.6rem",
                          borderRadius: "8px",
                          backgroundColor:
                            item.markedValue === 0 ? colors.primary : undefined,
                          // color: colors.primary,
                          fontWeight: "bold",
                          border: `2px solid ${colors.primary}`,
                        }}
                      >
                        {/* <MdCancel size="18px" fontWeight="bold" /> */}

                        <MdCancel
                          size="18px"
                          fontWeight="bold"
                          color={item.markedValue === 0 ? "white" : undefined}
                        />

                        <p
                          style={{
                            marginLeft: "0.3rem",
                            fontSize: "14px",
                            color: item.markedValue === 0 ? "white" : undefined,
                          }}
                        >
                          Absent
                        </p>
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => studentAttFunc(item.studentId)}
                        style={{
                          padding: "0.6rem",
                          borderRadius: "8px",
                          backgroundColor:
                            index % 2 === 0 ? `${colors.secondary}` : "white",
                          color:
                            index % 2 === 0 ? "white" : `${colors.secondary}`,
                        }}
                      >
                        <BsFillCalendarDateFill
                          color={
                            index % 2 === 0
                              ? colors.primary
                              : `${colors.primary}`
                          }
                          fontSize="17px"
                        />
                      </button>
                    </td>
                    <td>
                      {!item.isMarked ? (
                        <button
                          disabled={
                            singleSaveLoading || item.markedValue === -1
                          }
                          onClick={() => {
                            updateSingleAttendance(item);
                          }}
                        >
                          {singleSaveLoading ? (
                            <FormLoader color={colors.primary} />
                          ) : (
                            <SaveIcon className="text-[#671E78]" />
                          )}
                        </button>
                      ) : (
                        <>
                          {showEdit && editId === item.studentId ? (
                            <div className="flex justify-center">
                              <div className="flex gap-5 items-center">
                                <button
                                  className="border-[#671E78] border px-[10px] py-[5px] rounded-sm"
                                  onClick={() => {
                                    updateSingleAttendance(item);
                                  }}
                                >
                                  {singleSaveLoading ? (
                                    <FormLoader color={colors.primary} />
                                  ) : (
                                    <SaveIcon className="text-[#671E78]" />
                                  )}
                                </button>
                                <button
                                  className="border-[#671E78] border px-[10px] py-[5px] rounded-sm"
                                  onClick={() => {
                                    setShowEdit(false);
                                    setEditId("");
                                  }}
                                >
                                  <CloseIcon className="text-[#671E78]" />
                                </button>
                              </div>
                            </div>
                          ) : (
                            <button
                              onClick={() => {
                                setShowEdit(true);
                                setEditId(item.studentId);
                              }}
                            >
                              <CreateIcon className="text-[#671E78]" />
                            </button>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} style={{ textAlign: "center" }}>
                    No Students Available
                  </td>
                </tr>
              )}
            </table>
          </AccountSummaryWrapper>
          <Pagination
            count={Math.ceil(pageRecords / 10)}
            page={currentPage}
            onChange={handleChange}
          />
        </TabContainer>
      )}

      {students.length > 0 &&
        !loading &&
        checkAccess(accessObj.rights, "Update") && (
          <div
            style={{
              display: "flex",
              // marginTop: "1rem",
              justifyContent: "end",
              padding: "8px 16px",
            }}
          >
            <AuthButton
              style={{ marginTop: "0.5rem" }}
              disabled={
                formLoading || students.some((item) => item.markedValue === -1)
              }
              onClick={markFunction}
              width="150px"
            >
              {formLoading ? <FormLoader /> : "Mark Attendance"}
            </AuthButton>
          </div>
        )}
    </>
  );
};

export default ClassAttendance;
