import React, { useState } from "react";
import { BsSearch } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Box, Container } from "@mui/system";
import InputBase from "@mui/material/InputBase";
import Popover from "@mui/material/Popover";
import { checkAccess } from "../../services/utils";
import {
  Grid,
  useMediaQuery,
  useTheme,
  AppBar,
  IconButton,
  MenuItem,
  MenuList,
} from "@mui/material";
import Drawers from "./Drawer";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate, useLocation } from "react-router-dom";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CPDrawers from "./CPDrawer";
import { useDispatch, useSelector } from "react-redux";

import { logout } from "../../features/auth/authSlice";
import UserGuideButton from "../ControlPanel/UserGuide/UserGuideButton";
import ChangePin from "./ChangePin";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const DashboardHeader = ({ title, navData }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  let location = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const userInfo = useSelector((state) => state.auth.user);

  return (
    <>
      <AppBar
        sx={{
          background: "#ffffff",
          color: "#000",
          boxShadow: "none",
          width: { lg: "78%", md: "70%" },
          display: "flex",
          justifyContent: "center",
          // alignContent: "center",
          height: { lg: "100px", md: "70px", sm: "70px", xs: "70px" },
        }}
      >
        {isMatch ? (
          <>
            <Box></Box>
            {location.pathname.includes("setup") ? (
              <CPDrawers
                title={title}
                navData={navData}
                // setView={setView}
                // view={view}
              />
            ) : (
              <Drawers />
            )}
          </>
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center", width: "95%" }}>
              <Container>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    lg={6}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <UserGuideButton />
                  </Grid>

                  <Grid
                    item
                    lg={2}
                    md={3}
                    sm={3}
                    xs={3}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Badge badgeContent={0} color="error">
                      <NotificationsNoneIcon />
                    </Badge>
                    {userInfo?.profileImage === "" ? (
                      <Avatar
                        src="https://res.cloudinary.com/dgkcytiv6/image/upload/v1693005681/qkgkhvblmekt5uxj4w30_eucaxm.jpg"
                        sx={{
                          "&:hover": { transform: "scale(2)" },
                          transition: "0.2s all linear",
                        }}
                      />
                    ) : (
                      <Avatar
                        sx={{
                          "&:hover": { transform: "scale(2)" },
                          transition: "0.2s all linear",
                        }}
                        src={userInfo.profileImage}
                      />
                    )}
                    <IconButton onClick={handleClick}>
                      <MenuIcon
                        style={{ fontSize: "25px", cursor: "pointer" }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Container>
            </Box>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuList>
                <ChangePin />
                <MenuItem onClick={handleLogout} sx={{ fontSize: "15px" }}>
                  <LogoutIcon sx={{ mr: 1 }} /> Logout
                </MenuItem>
              </MenuList>
            </Popover>
          </>
        )}
      </AppBar>
    </>
  );
};

export default DashboardHeader;
