import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
const statusStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 400, xs: 400 },
  height: "400px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "1px solid #671E78",
  boxShadow: 24,
  p: 4,
};
const StatusModal = ({ open, onClose, assignmentValues }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={statusStyle}>
          <Box align="right" sx={{}}>
            <IconButton onClick={onClose}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", mb: 2, fontFamily: "Gilroy-Medium" }}
            >
              Status
            </Typography>
            <Divider />
          </Box>
          <Box>
            <Box className="flex justify-between items-center mb-4">
              <Box>
                <Typography sx={{ fontFamily: "Gilroy-Medium" }}>
                  Question(s) Added
                </Typography>
              </Box>
              <Box>
                <Checkbox checked={assignmentValues?.isQuestionadded} />
              </Box>
            </Box>
            <Box className="flex justify-between items-center mb-4">
              <Box>
                <Typography sx={{ fontFamily: "Gilroy-Medium" }}>
                  Activated
                </Typography>
              </Box>
              <Box>
                <Checkbox checked={assignmentValues?.isActivate} />
              </Box>
            </Box>
            <Box className="flex justify-between items-center mb-4">
              <Box>
                <Typography sx={{ fontFamily: "Gilroy-Medium" }}>
                  Submitted
                </Typography>
              </Box>
              <Box>
                <Checkbox checked={assignmentValues?.isSubmitted} />
              </Box>
            </Box>
            <Box className="flex justify-between items-center mb-4">
              <Box>
                <Typography sx={{ fontFamily: "Gilroy-Medium" }}>
                  Closed
                </Typography>
              </Box>
              <Box>
                <Checkbox checked={assignmentValues?.isClosed} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default StatusModal;
