import { useEffect, useState, useCallback } from "react";
// import Modal from "../modal/Modal";
import {
  // BigHeader,
  Container,
  TabContainer,
  AccountSummaryWrapper,
  TabLinkContainer,
  Icon,
  Input,
  Search,
} from "../../pages/Dashboard/styles/dashboard.style";
import { BsSearch } from "react-icons/bs";
import Loader from "../pageLoader/loader";
import PropTypes from "prop-types";
// import Select from "../select/select";
// import colors from "../../assets/colors.json";
// import { AuthButton, InputWrapper } from "../../pages/auth/Login.style";
import {
  // AddBookSetupType,
  // DelBookSetupByID,
  GetBookSetup,
  // PutBookSetupType,
} from "../../services/Asset";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
// import FormLoader from "../FormLoader";
// import ErrorService from "../../services/errorService";

import { paginateData } from "../../services/utils";
import Pagination from "../pagination_one/pagination";
// const initialObj = {
//   sectionName: "SouthEast Corner",
//   description: "Hello World",
//   schoolId: "",
// };
const Subject = ({ arr, loading }) => {
  const [pageLoading, setPageLoading] = useState(true);
  // const [id, setId] = useState("");
  const [items, setItems] = useState([]);
  const [list, setList] = useState([]);
  const { admToken } = useSelector((state) => state.auth);
  // const [showModal, setShowModal] = useState(false);
  // const [formLoading, setFormLoading] = useState(false);
  // const [obj, setObj] = useState(initialObj);
  // const [schoolId, setSchoolId] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffSet, setitemOffSet] = useState(0);

  useEffect(() => {
    paginateData(
      items,
      setList,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [items, currentPage, itemOffSet]);

  const getSections = useCallback(async () => {
    try {
      const res = await GetBookSetup(admToken, "ListSubjects");
      console.log(res);
      res.status && res.statusCode === 2 && setItems(res.data);
      (!res.status || res.status === 400 || res.status === 500) &&
        toast.error("An Error  Occurred, Please try again later");
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.log(error);
    }
  }, [admToken]);

  useEffect(() => {
    getSections();
    /* The line `import { useEffect, useState, useCallback } from "react";` is importing the `useEffect`,
  `useState`, and `useCallback` hooks from the React library. These hooks are used to manage state and
  side effects in functional components. */
  }, [getSections]);

  if (pageLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <TabContainer style={{ padding: "0px" }}>
        <TabLinkContainer bg="#F0E8F1" style={{ position: "relative" }}>
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search " />
          </Input>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table
            style={{
              borderRadius: "0px",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            <tr
              className="table-head"
              style={{ backgroundColor: "#F0E8F1", color: "black" }}
            >
              <th>S/N </th>
              <th>
                <p style={{ width: "max-content" }}>Name</p>
              </th>
            </tr>

            {list.length > 0 ? (
              list.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.subname}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} style={{ textAlign: "center" }}>
                  No Library Subjects Available At The Moment
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      {pageCount > 1 && (
        <div
          style={{
            marginTop: "2px",
            marginBottom: "2px",
          }}
        >
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            range={3}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </Container>
  );
};

Subject.propTypes = {
  arr: PropTypes.array,
  loading: PropTypes.bool,
};

Subject.defaultProps = {
  arr: [],
  loading: true,
};

export default Subject;
