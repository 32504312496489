import React, { useState, useCallback, useEffect } from "react";
import {
  InlineLabelGroup,
  InlineLabelWrapper,
  TabContainer,
  TabLinkContainer,
  TabLink,
} from "../Dashboard/styles/dashboard.style";
import Select from "../../components/select/select";
import colors from "../../assets/colors.json";
import { ButtonFlex, AuthButton, InputWrapper } from "../auth/auth.style";
import { useMediaQuery } from "react-responsive";
import { BsSearch } from "react-icons/bs";
import ApplicationTable from "../../components/Library/ApplicationTable";
import { GetBookSetup } from "../../services/Asset";
import { useSelector } from "react-redux";

const BookLending = () => {
  const { admToken } = useSelector((state) => state.auth);
  const [opt, setOpt] = useState("");
  const optionsArr = [
    {
      id: 1,
      name: "Value 1",
    },
    {
      id: 2,
      name: "Value 2",
    },
  ];
  const isTab = useMediaQuery({ maxWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [tab, setTab] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const getLendingHistory = useCallback(async () => {
    try {
      const res = await GetBookSetup(admToken, "GetLendingHistory");
      console.log(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [admToken]);
  useEffect(() => {
    getLendingHistory();
  }, [getLendingHistory]);
  return (
    <>
      <TabContainer style={{ background: colors.primary }}>
        <InlineLabelGroup>
          <InlineLabelWrapper flex={5}>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={2}>
                <InputWrapper>
                  <Select
                    color={"white"}
                    title="School"
                    index="name"
                    selected={"Value 1"}
                    options={optionsArr}
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={1}>
                <InputWrapper>
                  <Select
                    color={"white"}
                    title="School"
                    index="name"
                    selected={"Value 1"}
                    options={optionsArr}
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={1}>
                <InputWrapper>
                  <Select
                    color={"white"}
                    title="School"
                    index="name"
                    selected={"Value 1"}
                    options={optionsArr}
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={1}>
                <InputWrapper>
                  <Select
                    color={"white"}
                    title="School"
                    index="name"
                    selected={"Value 1"}
                    options={optionsArr}
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
          </InlineLabelWrapper>
          <InlineLabelWrapper flex={1}>
            <ButtonFlex>
              <AuthButton
                style={{ marginTop: "1rem" }}
                disabled={false}
                width={isMobile ? "100%" : "90%"}
                onClick={null}
                color={colors.primary}
                bg="white"
              >
                <div style={{ textAlign: "center" }}>Load</div>
              </AuthButton>
            </ButtonFlex>
          </InlineLabelWrapper>
        </InlineLabelGroup>
      </TabContainer>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <AuthButton
          mt="0.6rem"
          pl="0px"
          width={isTab ? "50%" : "30%"}
          onClick={() => setShowModal(!showModal)}
        >
          <div>
            <BsSearch />
            <span>Book Search</span>
          </div>
          {/* Send Code */}
        </AuthButton>
      </div>

      <TabContainer>
        <TabLinkContainer>
          <TabLink
            isActive={tab === 1}
            onClick={() => setTab(1)}
            minWidth={"50%"}
          >
            Online Application
          </TabLink>
          <TabLink
            isActive={tab === 2}
            onClick={() => setTab(2)}
            minWidth={"50%"}
          >
            Offline Application
          </TabLink>
        </TabLinkContainer>

        {tab === 1 && (
          <ApplicationTable showModal={showModal} setShowModal={setShowModal} />
        )}
        {tab === 2 && (
          <ApplicationTable showModal={showModal} setShowModal={setShowModal} />
        )}
      </TabContainer>
    </>
  );
};

export default BookLending;
