import React, { useState, useCallback, useEffect, useRef } from "react";
import Select from "../select/select";
import colors from "../../assets/colors.json";
import { InputWrapper, AuthButton } from "../../pages/auth/Login.style";
import {
  AccountSummaryWrapper,
  InlineLabelGroup,
  InlineLabelWrapper,
  Container,
  Icon,
  Input,
  Search,
  TabContainer,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import { useMediaQuery } from "react-responsive";
import hostelApi from "../../services/hostel";
import { useSelector } from "react-redux";
import ErrorService from "../../services/errorService";
import GlobalStyles from "../../assets/globalStyle";
import { BsSearch } from "react-icons/bs";
import { LiaEllipsisVSolid } from "react-icons/lia";
import Loader from "../pageLoader/loader";
import AddRoomModal from "./AddRoomModal";
import Modal from "../modal/Modal";
import moment from "moment";
import { toast } from "react-toastify";
import { MenuItem, MenuList } from "@mui/material";
import Popover from "@mui/material/Popover";
import { Box } from "@mui/system";

const Rooms = ({ hostel, getHostels }) => {
  const { admToken, user } = useSelector((state) => state.auth);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [rooms, setRooms] = useState([]);
  const [floors, setFloors] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [sQuery, setSQuery] = useState("");
  const [list, setList] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleAnchorClick = (event, room) => {
    setAnchorEl(event.currentTarget);
    setSelectedRoom(room);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const filterSearch = async (e) => {
    const queryRegex = new RegExp(e.target.value, "i");
    const arr = rooms.filter((item) => {
      return Object.values(item).some((val) => queryRegex.test(val));
    });
    setList(arr);
  };

  const resetData = () => {
    setList(rooms);
  };

  const getRooms = useCallback(async () => {
    setLoading(true);
    if (!hostel) return;
    try {
      const res = await hostelApi.getRooms(
        admToken,
        user.schoolId,
        hostel.hostel_id
      );
      if (res.status && res.statusCode === 2) {
        setRooms(res.data);
        setList(res.data);
      }
      res.status === 500 ||
        res.status === 400 ||
        (!res.status && ErrorService.displayErrorAlert(res));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [admToken, hostel, user.schoolId]);
  const getFloors = useCallback(async () => {
    setLoading(true);
    if (!hostel) return;
    try {
      const res = await hostelApi.getHostelFloors(
        admToken,
        user.schoolId,
        hostel.hostel_id
      );
      if (res.status && res.statusCode === 2) {
        setFloors(res.data);
      }
      res.status === 500 ||
        res.status === 400 ||
        (!res.status && ErrorService.displayErrorAlert(res));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [admToken, hostel, user.schoolId]);

  useEffect(() => {
    getRooms();
    getFloors();
  }, [getFloors, getRooms]);

  return (
    <>
      {openAddModal && (
        <Modal
          content={
            <AddRoomModal
              hostel={hostel}
              floors={floors}
              setOpenAddModal={setOpenAddModal}
              admToken={admToken}
              getRooms={getRooms}
              getHostels={getHostels}
              user={user}
            />
          }
          size="large"
          onClose={setOpenAddModal}
        />
      )}
      {openEditModal && (
        <Modal
          content={
            <AddRoomModal
              hostel={hostel}
              floors={floors}
              setOpenEditModal={setOpenEditModal}
              admToken={admToken}
              getRooms={getRooms}
              getHostels={getHostels}
              user={user}
              edit
              selectedRoom={selectedRoom}
            />
          }
          size="large"
          onClose={setOpenEditModal}
        />
      )}
      {showDelete && (
        <Modal
          content={
            <DeleteModal
              selectedRoom={selectedRoom}
              setShowDelete={setShowDelete}
              admToken={admToken}
              getRooms={getRooms}
              getHostels={getHostels}
            />
          }
          size="small"
          onClose={setShowDelete}
        />
      )}
      <section className="h-[500px] overflow-scroll">
        <div className="  w-[calc(100%+18px)] lg:w-[calc(100%+79px)] -ml-[9px] lg:-ml-[39px]">
          <div className="flex flex-col lg:flex-row gap-4 items-start justify-between lg:items-center pb-2 border-b-[0.5px] border-gray-400 px-4 lg:px-12">
            <div>
              <h1 className="text-[1.5rem] ">Add Room</h1>
              <span className="py-2 px-7 rounded-3xl bg-[#671e78] text-white text-sm">
                {hostel.hostel_name}
              </span>
            </div>
            <AuthButton
              style={{ fontSize: "14px", fontFamily: "Helvetica" }}
              color="white"
              mt="0rem"
              bg={colors.primary}
              onClick={() => setOpenAddModal(true)}
              width={isMobile ? "100%" : "200px"}
            >
              Add Room
            </AuthButton>
          </div>
        </div>
        <TabContainer
          style={{
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
            paddingBottom: "0px",
            marginTop: "50px",
          }}
        >
          <TabLinkContainer bg="rgb(238, 224,241)">
            <Input>
              <Icon>
                <BsSearch />
              </Icon>
              <Search
                type="text"
                placeholder="Search For Room"
                value={sQuery}
                onChange={(e) => {
                  setSQuery(e.target.value);
                  if (e.target.value.length > 2) {
                    filterSearch(e);
                  }

                  if (e.target.value === "") {
                    resetData();
                  }
                }}
              />
            </Input>
          </TabLinkContainer>
          <AccountSummaryWrapper pt="0px">
            {loading ? (
              <Loader />
            ) : (
              <Box>
                <table
                  style={{
                    borderRadius: "0px",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                    // position: "relative",
                  }}
                >
                  <tr
                    className="tabel-head"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    <th className="text-[0.75rem] xl:text-[1.1rem]">S/N</th>
                    <th className="text-[0.75rem] xl:text-[1.1rem]">
                      Room Name
                    </th>
                    <th className="text-[0.75rem] xl:text-[1.1rem]">
                      Floor Name
                    </th>
                    <th className="text-[0.75rem] xl:text-[1.1rem]">
                      Capacity
                    </th>
                    <th className="text-[0.75rem] xl:text-[1.1rem]">
                      Created By
                    </th>
                    <th className="text-[0.75rem] xl:text-[1.1rem]">
                      Created Date
                    </th>
                    <th className="text-[0.75rem] xl:text-[1.1rem]"></th>
                  </tr>
                  {list.map((each, index) => (
                    <React.Fragment key={index}>
                      <tr className="relative">
                        <td className="text-[0.75rem] xl:text-[1rem]">
                          {index + 1}
                        </td>
                        <td className="text-[0.75rem] xl:text-[1rem]">
                          {each.roomname}
                        </td>
                        <td className="text-[0.75rem] xl:text-[1rem]">
                          {each.floorName || ""}
                        </td>
                        <td className="text-[0.75rem] xl:text-[1rem]">
                          {each.maxOccupant}
                        </td>
                        <td className="text-[0.75rem] xl:text-[1rem]">
                          {each.createdBy || ""}
                        </td>
                        <td className="text-[0.75rem] xl:text-[1rem]">
                          {moment(each.createdDate).isValid()
                            ? moment(each.createdDate).format("MMM Do, YY")
                            : ""}
                        </td>
                        <td className="text-[0.75rem] xl:text-[1rem]">
                          <button onClick={(e) => handleAnchorClick(e, each)}>
                            <LiaEllipsisVSolid className="w-6 h-6" />
                          </button>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </table>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: "#671E78",
                      color: "#fff",
                      fontFamily: "Gilroy-Regular",
                      px: 3,
                    }}
                  >
                    <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
                      <MenuItem
                        onClick={() => {
                          setOpenEditModal(true);
                          handleClose();
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setShowDelete(true);
                          handleClose();
                        }}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Box>
                </Popover>
              </Box>
            )}
          </AccountSummaryWrapper>
        </TabContainer>
      </section>
    </>
  );
};

export default Rooms;

const DeleteModal = ({
  selectedRoom,
  setShowDelete,
  getRooms,
  admToken,
  getHostels,
}) => {
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    try {
      setLoading(true);
      const res = await hostelApi.deleteRoom(admToken, selectedRoom.roomid);
      if (res?.status && res?.statusCode === 2) {
        toast.success(res.message);
        setShowDelete(false);
        getRooms();
        getHostels();
      } else if (res?.status === 500 || res?.status === 400 || !res?.status) {
        ErrorService.displayErrorAlert(res);
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <section>
      <h3 className="text-lg text-gray-700 text-center">
        Are you sure you want to Delete {selectedRoom.roomname} ?
      </h3>
      <div className="flex gap-3 mt-7 justify-center">
        <button
          type="button"
          onClick={() => setShowDelete(false)}
          className="w-[142px]  h-11 px-[18px] py-3 bg-white rounded-lg shadow border border-gray-300 justify-center items-center gap-1.5 inline-flex text-slate-700  font-semibold leading-normal"
        >
          Cancel
        </button>
        <button
          disabled={loading}
          onClick={() => handleDelete()}
          className="w-[142px] whitespace-nowrap  h-11 px-[18px] py-3 bg-rose-500 rounded-lg shadow border border-rose-500 justify-center items-center gap-1.5 inline-flex text-white  font-semibold leading-normal"
        >
          {loading ? "Please wait..." : "Delete"}
        </button>
      </div>
    </section>
  );
};
