import React, { useState } from "react";
import {
  TabContainer,
  TabContent,
  TabLink,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import PropTypes from "prop-types";
import colors from "../../assets/colors.json";
import "react-datepicker/dist/react-datepicker.css";
import PersonalData from "./PersonalData";
import ReligionData from "./ReligionData";
import { useMediaQuery } from "react-responsive";
import { AuthButton } from "../../pages/auth/Login.style";
import FormLoader from "../FormLoader";
const CreateParentForm = ({ obj, setObj, action, formLoading, readOnly }) => {
  const isTab = useMediaQuery({ maxWidth: 992 });
  const [tab, setTab] = useState(1);
  return (
    <TabContainer>
      <TabLinkContainer>
        <TabLink minWidth="40%" isActive={tab === 1} onClick={() => setTab(1)}>
          <p>Personal Data</p>
        </TabLink>
        <TabLink minWidth="40%" isActive={tab === 2} onClick={() => setTab(2)}>
          <p>Religion Data</p>
        </TabLink>
      </TabLinkContainer>
      <TabContent>
        {obj === null ? (
          <div
            style={{
              textAlign: "center",
              color: colors.primary,
              fontWeight: "bold",
            }}
          >
            An Error Occurred, Try Again Later
          </div>
        ) : (
          <>
            {tab === 1 && (
              <PersonalData data={obj} setData={setObj} readOnly={readOnly} />
            )}
            {tab === 2 && (
              <ReligionData data={obj} setData={setObj} readOnly={readOnly} />
            )}

            {!readOnly && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <AuthButton
                  width={isTab ? "90%" : "60%"}
                  onClick={action}
                  disabled={formLoading}
                >
                  {formLoading ? <FormLoader /> : "Create Parent"}
                </AuthButton>
              </div>
            )}
          </>
        )}
      </TabContent>
    </TabContainer>
  );
};

CreateParentForm.propTypes = {
  readOnly: PropTypes.bool,
  obj: PropTypes.object.isRequired,
  action: PropTypes.func,
  formLoading: PropTypes.bool,
};

CreateParentForm.defaultProps = {
  readOnly: true,
  obj: null,
  formLoading: false,
};
export default CreateParentForm;
