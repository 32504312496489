import React, { useState, useEffect, useCallback, createRef } from "react";
import { toast } from "react-toastify";

import {
  AccountSummaryWrapper,
  BigHeader,
  FormFlex,
  FormFlexDiv,
  Icon,
  InlineLabelGroup,
  InlineLabelWrapper,
  Input,
  Search,
  TabContainer,
  TabContentWrapper,
  TabLinkContainer,
  SubMenuDiv,
  SmallHeader,
  AttachmentUploadContainer,
} from "../Dashboard/styles/dashboard.style";
import { AiFillCar } from "react-icons/ai";
import { useLocation, useSearchParams } from "react-router-dom";
import { TbUserCheck } from "react-icons/tb";
import { TiArrowSync } from "react-icons/ti";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/system";
import colors from "../../assets/colors.json";
import { InputWrapper, AuthButton, ButtonFlex } from "../auth/auth.style";
import Select from "../../components/select/select";
import { useMediaQuery } from "react-responsive";
import { BsSearch } from "react-icons/bs";
import { IconButton, MenuItem, MenuList } from "@mui/material";
import Popover from "@mui/material/Popover";
import Pagination from "../../components/pagination_one/pagination";
import Modal from "../../components/modal/Modal";
import DatePickerComponent from "../../components/datepicker/DatePicker";
import { useSelector } from "react-redux";
import { getSchools, getSchoolList } from "../../services/MySchool";
import ErrorService from "../../services/errorService";
import { isNumber, isObjEmpty, paginateData } from "../../services/utils";
import FormLoader from "../../components/FormLoader";
import {
  AddVehicle,
  GetAllVehicles,
  GetSchoolVehicles,
} from "../../services/Fleet";
const cateArr = [
  {
    id: 1,
    name: "SUV",
  },
  {
    id: 2,
    name: "CAR",
  },
  {
    id: 3,
    name: "BUS",
  },
  {
    id: 4,
    name: "Others",
  },
];
const transArr = [
  {
    id: 1,
    name: "Manual",
  },
  {
    id: 2,
    name: "Automatic",
  },
  {
    id: 3,
    name: "CVT Transmission",
  },
];
const initialObj = {
  motorId: 0,
  schoolId: "",
  staffId: "",
  category: "SUV",
  carMaker: "string",
  productionYear: "2009",
  mileage: "string",
  transmissionType: "Manual",
  colour: "Brown",
  chasisNumber: "SHYEU478JEDH73HEJ",
  engineNumber: "YUE78EJDHDHDJ",
  numberPlate: "MUS-389-HJ",
  datePurchased: "",
  costPrice: "2000000",
  file: "",
  serviceDate: "",
  serviceDueDate: "",
};
const VehicleSetup = () => {
  const [obj, setObj] = useState(initialObj);
  let defaultArr = [
    {
      schoolId: "AS",
      schoolName: "All Schools",
    },
  ];
  const [formLoading, setFormLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [arr, setArr] = useState([]);
  const location = useLocation();
  const [opt, setOpt] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTab = useMediaQuery({ maxWidth: 922 });
  const [showModal, setShowModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [controlModal, setControlModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { admToken } = useSelector((state) => state.auth);
  const [searchParams, setSearchParams] = useSearchParams();
  // const schoolId = new URLSearchParams(location.search).get("school_id") || "";
  const [sLoading, setSLoading] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [schoolId, setSchoolId] = useState("AS");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [list, setList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffSet, setitemOffSet] = useState(0);

  const getVehicles = useCallback(async () => {
    try {
      const res = await GetAllVehicles(admToken);
      console.log(res);
      res.status && res.statusCode === 2 && setItems(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  }, [admToken]);
  const getSchoolVehicles = useCallback(
    async (id) => {
      try {
        const res = await GetSchoolVehicles(admToken, id);
        console.log(res);
        res.status && res.statusCode === 2 && setItems(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.log(error);
      }
    },
    [admToken]
  );

  useEffect(() => {
    paginateData(
      items,
      setList,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [items, currentPage]);
  useEffect(() => {
    getVehicles();
  }, [getVehicles]);

  // get schools
  const getAllSchools = useCallback(async () => {
    try {
      setPageLoading(true);
      const res = await getSchools(admToken);
      if (res.status && res.statusCode === 2) {
        setArr(res.data);
      }
      (res.status === 500 || res.status === 400) &&
        ErrorService.displayErrorAlert(res.data);
      console.log(res);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.log(error);
    }
  }, [admToken]);
  // get school staff
  const getSchoolStaff = useCallback(
    async (id) => {
      try {
        setSLoading(true);
        const res = await getSchoolList(admToken, "StaffList", id);
        console.log(res);
        res.status && res.statusCode === 2 && setStaffList(res.data);
        setSLoading(false);
      } catch (error) {
        console.log(error);
        setSLoading(false);
      }
    },
    [admToken]
  );

  const onPickFileRef = createRef();

  const pickFile = () => {
    onPickFileRef.current.click();
  };
  const onPickFile = (event) => {
    const files = event.target.files;
    //  setObj({ ...obj, fileName });
    //  setFileName(files[0].name);
    let filename = files[0].name;
    if (
      filename.includes(".jpg") ||
      filename.includes(".jpeg") ||
      filename.includes(".png")
    ) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        // setBase64(fileReader.result);

        setObj({
          ...obj,
          file: fileReader.result,
        });
      });
      fileReader.readAsDataURL(files[0]);
    } else {
      alert("Please upload a picture");
    }
  };

  const addCar = async () => {
    console.log(obj);
    let formIsValid = isObjEmpty(obj);
    if (!formIsValid) {
      return toast.error("Please Make Sure You Fill All The Form");
    }
    try {
      setFormLoading(true);
      console.log(obj);
      const res = await AddVehicle(admToken, obj);
      setFormLoading(false);
      console.log(res);
      if (res.status && res.statusCode === 2) {
        toast.success("Assets Created Successfully");
        setObj(initialObj);
        setShowModal(!showModal);
        // schoolId === "AS" ? getAssets() : getSchoolAssets(schoolId);
      }
      !res.data.status && ErrorService.displayErrorAlert(res.data);
      res.status === 400 && ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      setFormLoading(false);
      console.log(error);
    }
  };
  const editCar = () => {};
  useEffect(() => {
    getAllSchools();
  }, []);
  const optionsArr = [
    {
      id: 1,
      name: "Value 1",
    },
    {
      id: 2,
      name: "Value 2",
    },
  ];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }}> Add Vehicle</BigHeader>
      <TabContentWrapper>
        {/* school and staff */}
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Select School</label>
              <Select
                title="School"
                thirdOption="schoolId"
                index="schoolName"
                selected={obj.schoolId}
                options={arr}
                setSelected={(val) => {
                  setObj({
                    ...obj,
                    schoolId: val.schoolId.toString(),
                    staffId: "",
                  });
                  getSchoolStaff(val.schoolId);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Staff</label>
              {obj.schoolId === "" && (
                <span
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    color: colors.primary,
                  }}
                >
                  Select School To Get List Of Staff
                </span>
              )}
              {sLoading && (
                <span
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    color: colors.primary,
                  }}
                >
                  Please wait...
                </span>
              )}
              {obj.schoolId !== "" && !sLoading && staffList.length > 0 && (
                <Select
                  title="Staff"
                  index="name"
                  thirdOption="staffId"
                  selected={obj.staffId}
                  options={staffList}
                  setSelected={(val) => {
                    setObj({ ...obj, staffId: val.staffId.toString() });
                  }}
                />
              )}
              {obj.schoolId !== "" && !sLoading && staffList.length === 0 && (
                <span
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    color: colors.primary,
                  }}
                >
                  Please Add A Staff To This School Selected
                </span>
              )}
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        {/* category and car model */}
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Motor Category</label>
              <Select
                title="Category"
                index="name"
                selected={obj.category}
                options={cateArr}
                setSelected={(val) => {
                  console.log(val);
                  setObj({ ...obj, category: val.name });
                  // setOpt(val.name);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Car Make and Model</label>
              <input
                type="text"
                onChange={(e) => setObj({ ...obj, carMaker: e.target.value })}
                value={obj.carMaker}
                placeholder="Car Maker"
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>{" "}
        {/* number plate and year */}
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Number Plate</label>
              <input
                type="text"
                placeholder="Number Plate"
                onChange={(e) =>
                  setObj({ ...obj, numberPlate: e.target.value })
                }
                value={obj.numberPlate}
              />
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Production Year</label>
              <input
                type="text"
                placeholder="Production Year"
                onKeyDown={(e) => isNumber(e)}
                onChange={(e) =>
                  setObj({ ...obj, productionYear: e.target.value })
                }
                value={obj.productionYear}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>{" "}
        {/* mileage and tranmission type */}
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Mileage</label>
              <input
                type="text"
                placeholder="Mileage"
                onChange={(e) => setObj({ ...obj, mileage: e.target.value })}
                value={obj.mileage}
              />
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Tranmission Type</label>
              <Select
                title="Tranmission Type"
                index="name"
                selected={obj.transmissionType}
                options={transArr}
                setSelected={(val) => {
                  console.log(val);
                  setOpt(val.name);

                  setObj({ ...obj, transmissionType: val.name });
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>{" "}
        {/* color and chasis */}
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Color</label>
              <input
                type="text"
                placeholder="Car Color"
                value={obj.colour}
                onChange={(e) => setObj({ ...obj, colour: e.target.value })}
              />
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Chassis Number</label>
              <input
                type="text"
                placeholder="Chassis Number"
                value={obj.chasisNumber}
                onChange={(e) =>
                  setObj({ ...obj, chasisNumber: e.target.value })
                }
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>{" "}
        {/* engine number purchase date */}
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Engine Number</label>
              <input
                type="text"
                placeholder="Production Year"
                value={obj.engineNumber}
                onChange={(e) =>
                  setObj({ ...obj, engineNumber: e.target.value })
                }
              />
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Date Purchased</label>
              <DatePickerComponent
                selected={obj.datePurchased}
                setSelected={(val) => {
                  setObj({ ...obj, datePurchased: val });
                  console.log(val);
                  // calculateAge(val);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>{" "}
        {/* service and service due date */}
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Service Date</label>
              <DatePickerComponent
                selected={obj.serviceDate}
                setSelected={(val) => {
                  setObj({ ...obj, serviceDate: val });
                  console.log(val);
                  // calculateAge(val);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Due Service Date</label>
              <DatePickerComponent
                selected={obj.serviceDueDate}
                setSelected={(val) => {
                  setObj({ ...obj, serviceDueDate: val });
                  console.log(val);
                  // calculateAge(val);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Cost Price</label>
              <input
                type="text"
                placeholder="Cost Price"
                onKeyDown={(e) => isNumber(e)}
                value={obj.costPrice}
                onChange={(e) => setObj({ ...obj, costPrice: e.target.value })}
              />
            </InputWrapper>
          </FormFlexDiv>

          <FormFlexDiv width="45%">
            <AttachmentUploadContainer
              onClick={pickFile}
              width="100%"
              zin={"0"}
              style={{ padding: "10px 5px", background: "transparent" }}
            >
              {obj.file.length === 0 && (
                <>
                  <AiFillCar
                    style={{ color: colors.primary, fontSize: "40px" }}
                  />
                </>
              )}
              {obj.file.length > 0 && <img src={obj.file} alt="" />}
              <input
                type="file"
                style={{
                  display: "none",
                }}
                onChange={onPickFile}
                ref={onPickFileRef}
              />
            </AttachmentUploadContainer>
            {obj.file.length === 0 ? (
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                Please Upload A Car
              </p>
            ) : (
              <p
                style={{
                  textAlign: "center",
                  color: colors.primary,
                  fontWeight: "bold",
                }}
              >
                Please Click To Update Photo
              </p>
            )}
          </FormFlexDiv>
        </FormFlex>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <AuthButton
            width={isTab ? "100%" : "80%"}
            onClick={() => (obj.motorId ? editCar() : addCar())}
            disabled={formLoading}
          >
            {formLoading ? (
              <FormLoader />
            ) : (
              <div>
                <span> {obj.motorId ? "Edit" : "Add"} Car</span>
              </div>
            )}

            {/* Send Code */}
          </AuthButton>
        </div>
      </TabContentWrapper>
    </>
  );
  const assignContent = (
    <>
      <BigHeader style={{ textAlign: "center" }}>Assign Driver</BigHeader>
      <TabContentWrapper>
        <TabContainer
          style={{
            background: colors.primary,
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          <InlineLabelGroup>
            <InlineLabelWrapper flex={3}>
              <InputWrapper>
                <Select
                  color={"white"}
                  title="Driver"
                  index="name"
                  selected={"Value 1"}
                  options={optionsArr}
                  setSelected={(val) => {
                    console.log(val);
                    setOpt(val.name);
                  }}
                />
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={2}>
              <ButtonFlex>
                <AuthButton
                  style={{ marginTop: "1rem" }}
                  disabled={false}
                  width={isMobile ? "100%" : "90%"}
                  onClick={null}
                  color={colors.primary}
                  bg="white"
                >
                  <div style={{ textAlign: "center" }}>
                    <TbUserCheck />
                    <span style={{ fontWeight: "bold" }}>Assign</span>
                  </div>
                </AuthButton>
              </ButtonFlex>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </TabContainer>

        <div style={{ padding: isMobile ? "0.6rem" : "0.9rem" }}>
          <SubMenuDiv
            jc="start"
            style={{
              width: isMobile ? "100%" : "100%",
              borderBottom: `1px solid ${colors.secondary}`,
            }}
          >
            <span style={{ marginRight: "0.8rem" }}>
              <input type="checkbox" style={{ marginTop: "0px" }} />
            </span>
            <SmallHeader
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginRight: "0.4rem",
              }}
            >
              Mr James Phillips
            </SmallHeader>
            <SmallHeader
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginRight: "0.4rem",
                fontFamily: "italic",
              }}
            >
              i
            </SmallHeader>
          </SubMenuDiv>
          <SubMenuDiv
            jc="start"
            style={{
              width: isMobile ? "100%" : "100%",
              borderBottom: `1px solid ${colors.secondary}`,
            }}
          >
            <span style={{ marginRight: "0.8rem" }}>
              <input type="checkbox" style={{ marginTop: "0px" }} />
            </span>
            <SmallHeader
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginRight: "0.4rem",
              }}
            >
              Mr James Phillips
            </SmallHeader>
            <SmallHeader
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginRight: "0.4rem",
                fontFamily: "italic",
              }}
            >
              i
            </SmallHeader>
          </SubMenuDiv>
        </div>
      </TabContentWrapper>
    </>
  );
  const controlContent = (
    <>
      <BigHeader style={{ textAlign: "center", fontSize: "38px" }}>
        Assign Control Account
      </BigHeader>
      <TabContentWrapper>
        <TabContainer
          style={{
            background: colors.primary,
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          <InlineLabelGroup>
            <InlineLabelWrapper flex={3}>
              <InputWrapper>
                <Select
                  color={"white"}
                  title="Control Account"
                  index="name"
                  selected={"Value 1"}
                  options={optionsArr}
                  setSelected={(val) => {
                    console.log(val);
                    setOpt(val.name);
                  }}
                />
              </InputWrapper>
              <span
                style={{
                  fontSize: "13px",
                  color: "white",
                  margin: "0.6rem 0rem 0rem 0.7rem",
                  fontWeight: "bold",
                }}
              >
                Account Code: 1256374844
              </span>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={2}>
              <ButtonFlex>
                <AuthButton
                  style={{ marginTop: "1rem" }}
                  disabled={false}
                  width={isMobile ? "100%" : "90%"}
                  onClick={null}
                  color={colors.primary}
                  bg="white"
                >
                  <div style={{ textAlign: "center" }}>
                    <TiArrowSync />
                    <span style={{ fontWeight: "bold" }}>Update</span>
                  </div>
                </AuthButton>
              </ButtonFlex>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </TabContainer>
      </TabContentWrapper>
    </>
  );
  return (
    <>
      {controlModal && (
        <Modal
          show={controlModal}
          content={controlContent}
          size={"large"}
          pd="0px"
          onClose={setControlModal}
        />
      )}
      {assignModal && (
        <Modal
          show={assignModal}
          content={assignContent}
          size="large"
          pd="0px"
          onClose={setAssignModal}
        />
      )}
      {showModal && (
        <Modal
          start={true}
          show={showModal}
          content={content}
          size={"large"}
          onClose={setShowModal}
          pd={"0px"}
        />
      )}
      <TabContainer
        style={{
          background: colors.primary,
          paddingLeft: "0.4rem",
          paddingRight: "0.4rem",
        }}
      >
        <InlineLabelGroup>
          <InlineLabelWrapper flex={5}>
            <InputWrapper>
              <Select
                color={"white"}
                title="School"
                thirdOption="schoolId"
                index="schoolName"
                selected={schoolId}
                options={[...defaultArr, ...arr]}
                setSelected={(val) => {
                  console.log(val);
                  setSchoolId(val.schoolId);
                  val.schoolId === "AS"
                    ? getVehicles()
                    : getSchoolVehicles(val.schoolId);
                }}
              />
            </InputWrapper>
          </InlineLabelWrapper>
          <InlineLabelWrapper flex={1}>
            <ButtonFlex>
              <AuthButton
                style={{ marginTop: "1rem" }}
                disabled={false}
                width={isMobile ? "100%" : "90%"}
                onClick={null}
                color={colors.primary}
                bg="white"
              >
                <div style={{ textAlign: "center" }}>Load</div>
              </AuthButton>
            </ButtonFlex>
          </InlineLabelWrapper>
        </InlineLabelGroup>
      </TabContainer>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <AuthButton
          mt="0.6rem"
          pl="0px"
          width={isTab ? "50%" : "30%"}
          onClick={() => setShowModal(!showModal)}
        >
          <div>
            <span>Add Fleet</span>
          </div>
        </AuthButton>
      </div>

      <TabContainer style={{ paddingBottom: "0px" }}>
        <TabLinkContainer bg="#F0E8F1">
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search" />
          </Input>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table style={{ borderRadius: "0px" }}>
            <tr
              className="table-head"
              style={{ background: "#F0E8F1", color: "black" }}
            >
              <th>S/N</th>
              <th>Car Model/Make</th>
              <th>Category</th>
              <th>Plate Number</th>
              <th>Created By</th>
              <th>Created Date</th>
              <th></th>
            </tr>
            <tr>
              <td>1</td>
              <td>Mercedes</td>
              <td>Car</td>
              <td>ABG-87-HG</td>
              <td>Mr John Doe</td>
              <td>12/03/2021</td>
              <td>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              </td>
            </tr>{" "}
            <tr>
              <td>2</td>
              <td>Mercedes</td>
              <td>Car</td>
              <td>ABG-87-HG</td>
              <td>Mr John Doe</td>
              <td>12/03/2021</td>
              <td>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              </td>
            </tr>
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Pagination
          pageCount={pageCount > 1 && pageCount}
          range={1}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem onClick={() => setShowModal(!showModal)}>Edit</MenuItem>
            <MenuItem onClick={() => setAssignModal(!assignModal)}>
              Assign Driver
            </MenuItem>
            <MenuItem onClick={() => setControlModal(!controlModal)}>
              Assign Control Account
            </MenuItem>
            <MenuItem>Renewal, Refuelling, Servicing, Maintenance</MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </>
  );
};

export default VehicleSetup;
