import React from "react";
import { Navbar, Header } from "../../components/Single";
import { Box } from "@mui/system";
import { singleStaffData } from "../../assets/data/SingleStaffData";
import { Profile, PaySlip } from "../../components/SingleStaff";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
const SingleStaff = () => {
  const isPhone = useMediaQuery({ maxWidth: 768 });
  const { token, prev } = useSelector((state) => state.auth);
  const location = useLocation();
  const schoolId = new URLSearchParams(location.search).get("school_id") || "";
  const staffId = new URLSearchParams(location.search).get("staff_id") || "";
  const page_id = Number(new URLSearchParams(location.search).get("page")) || 1;
  return (
    <Box sx={{ display: "flex" }}>
      <Navbar navData={singleStaffData} pathname={prev} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { lg: "90%", md: "90%", sm: "100%", xs: "1005" },
        }}
      >
        <Header navData={singleStaffData} pathname="/myschool/school-staff" />
        <Box mt={14} sx={{ mx: "auto", width: isPhone ? "95%" : "85%" }}>
          {page_id === 1 && (
            <Profile schoolId={schoolId} staffId={staffId} token={token} />
          )}
          {page_id === 2 && <PaySlip />}
        </Box>
      </Box>
    </Box>
  );
};

export default SingleStaff;
