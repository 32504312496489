import { RxPerson } from "react-icons/rx";
import { AiOutlineFile } from "react-icons/ai";
export const singleStaffData = [
  {
    id: 1,
    label: "Profile",
    icon: <RxPerson />,
  },

  {
    id: 2,
    label: "Pay Slip",
    icon: <AiOutlineFile />,
  },
];
