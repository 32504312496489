import React, { useState } from "react";
import { Box } from "@mui/system";
import { UsersData } from "../../assets/data/ControlPanelData";
import CPNavbar from "../../components/CPLayouts/CPNavBar";
import { useMediaQuery } from "react-responsive";
import { DashboardHeader } from "../../components/Dashboard";
import StaffRoles from "../../components/ControlPanel/Users/StaffRoles";
import StaffAccountSetup from "../../components/ControlPanel/Users/StaffAccountSetup";
import StudentCredentialSetup from "../../components/ControlPanel/Users/StudentCredentialSetup";
import ParentCredentialSetup from "../../components/ControlPanel/Users/ParentCredentialSetup";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import HouseManagerSetup from "../../components/ControlPanel/Users/HouseManagerSetup";
import { checkAccess } from "../../services/utils";
import VicePrincipalSetup from "../../components/ControlPanel/Users/VicePrincipalSetup";
import PrincipalSetup from "../../components/ControlPanel/Users/PrincipalSetup";
import HODSetup from "../../components/ControlPanel/Users/HODSetup";
import YearTutorSetup from "../../components/ControlPanel/Users/YearTutorSetup";
import AuditTrail from "../../components/ControlPanel/Users/AuditTrail";
const UsersSetup = () => {
  const location = useLocation();
  const { accessObj } = useSelector((state) => state.auth);
  const isGlobalUser = accessObj?.privileges?.some((obj) => obj.id === 1);
  const page_id = Number(new URLSearchParams(location.search).get("page")) || 0;
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CPNavbar
        controlNav={() => {
          if (open === true) {
            setOpen(false);
          }
        }}
        isOpen={open}
        // setView={setView}
        title="Users Setup"
        navData={UsersData}
        // view={view}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { lg: "90%", md: "90%", sm: "100%", xs: "100%" },
        }}
      >
        <DashboardHeader title="Users Setup" navData={UsersData} />
        <Box
          mt={13}
          sx={{
            mx: "auto",
            width: isMobile ? "90%" : "85%",
            overflow: "scroll",
          }}
        >
          {checkAccess(accessObj.menuItems, "Users Setup") &&
            checkAccess(accessObj.menuItems, "Staff Roles") &&
            page_id === 1 && <StaffRoles />}
          {checkAccess(accessObj.menuItems, "Users Setup") &&
            checkAccess(accessObj.menuItems, "Staff Account Setup") &&
            page_id === 2 && <StaffAccountSetup />}
          {checkAccess(accessObj.menuItems, "Users Setup") &&
            checkAccess(accessObj.menuItems, "Student Setup") &&
            page_id === 3 && <StudentCredentialSetup />}
          {checkAccess(accessObj.menuItems, "Users Setup") &&
            checkAccess(accessObj.menuItems, "Parent Setup") &&
            page_id === 4 && <ParentCredentialSetup />}
          {checkAccess(accessObj.menuItems, "Users Setup") &&
            checkAccess(accessObj.menuItems, "House-Manager Setup") &&
            page_id === 5 && <HouseManagerSetup />}
          {checkAccess(accessObj.menuItems, "Users Setup") &&
            checkAccess(accessObj.menuItems, "Vice-Principal Setup") &&
            page_id === 6 &&
            isGlobalUser && <VicePrincipalSetup />}
          {checkAccess(accessObj.menuItems, "Users Setup") &&
            checkAccess(accessObj.menuItems, "Principal Setup") &&
            page_id === 7 &&
            isGlobalUser && <PrincipalSetup />}
          {checkAccess(accessObj.menuItems, "Users Setup") &&
            checkAccess(accessObj.menuItems, "HOD Setup") &&
            page_id === 8 && <HODSetup />}
          {checkAccess(accessObj.menuItems, "Users Setup") &&
            checkAccess(accessObj.menuItems, "Year-Tutor Setup") &&
            page_id === 9 && <YearTutorSetup />}
          {checkAccess(accessObj.menuItems, "Users Setup") &&
            checkAccess(accessObj.menuItems, "Audit Trail") &&
            page_id === 10 && <AuditTrail />}
        </Box>
      </Box>
    </Box>
  );
};

export default UsersSetup;
