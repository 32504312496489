import {
  FormFlex,
  FormFlexDiv,
  TabContentWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
import { InputWrapper } from "../../pages/auth/Login.style";
import Select from "../select/select";
import GlobalStyles from "../../assets/globalStyle";
import PropTypes from "prop-types";
const ReligionData = ({ data, setData, readOnly }) => {
  const optReligion = [
    {
      id: 1,
      name: "Christianity",
    },

    {
      id: 6,
      name: "CHRISTIANITY",
    },
    { id: 2, name: "Islam" },
    { id: 5, name: "islam" },
    { id: 3, name: "Christen " },
    { id: 4, name: "Others" },
  ];
  return (
    <TabContentWrapper>
      <GlobalStyles />
      <FormFlex>
        <FormFlexDiv>
          <InputWrapper>
            <label htmlFor="">Religion</label>
            <Select
              title="Religion"
              disabled={readOnly}
              index="name"
              selected={data.religion}
              options={optReligion}
              setSelected={(val) => {
                console.log(val);
                setData({ ...data, religion: val.name });
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="">Denomination</label>
            <input
              type="text"
              value={data.denomination}
              placeholder="Religion"
              disabled={readOnly}
              onChange={(e) =>
                setData({ ...data, denomination: e.target.value })
              }
            />
          </InputWrapper>
        </FormFlexDiv>
        <FormFlexDiv>
          <InputWrapper>
            <label htmlFor="">Place Of Worship Address</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="3"
              onChange={(e) =>
                setData({ ...data, placeOfWorship: e.target.value })
              }
              value={data.placeOfWorship}
              disabled={readOnly}
            ></textarea>
          </InputWrapper>

          <InputWrapper>
            <label htmlFor="">Status In Place Of Worship</label>
            <input
              type="text"
              disabled={readOnly}
              value={data.statusInPlaceOfWorship}
              onChange={(e) =>
                setData({ ...data, statusInPlaceOfWorship: e.target.value })
              }
            />
          </InputWrapper>
        </FormFlexDiv>
      </FormFlex>
    </TabContentWrapper>
  );
};

ReligionData.propTypes = {
  readOnly: PropTypes.bool,
};
ReligionData.defaultProps = {
  readOnly: true,
};
export default ReligionData;
