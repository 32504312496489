import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/user";

const userInstance = JSON.parse(localStorage.getItem("user"));
const accessInstance = JSON.parse(localStorage.getItem("accessInstance"));
const admLoginToken = localStorage.getItem("admLoginToken");
const authStatus = Boolean(localStorage.getItem("admAuthStatus"));
const admToken = localStorage.getItem("admToken");
const admPhone = localStorage.getItem("admPhone");
const admAccountType = localStorage.getItem("admAccountType");
const prevRoute = localStorage.getItem("prev_route");
const initialState = {
  user: userInstance ? userInstance : "",
  accessObj: accessInstance ? accessInstance : "",
  loginToken: admLoginToken ? admLoginToken : "",
  admAccountType: admAccountType ? admAccountType : "",
  admToken: admToken ? admToken : "",
  admPhone: admPhone ? admPhone : "",
  authStatus: authStatus,
  isLoading: false,
  isSuccess: false,
  message: "",
  prev: prevRoute ? prevRoute : "",
  // jwtToken: "",
};

// Reset Pin Action
export const resetPin = createAsyncThunk(
  "auth/resetPin",
  async (data, thunkAPI) => {
    try {
      return await api.postResetPin(data);
    } catch (error) {
      return error;
    }
  }
);
//forget pin action
export const forgetPin = createAsyncThunk(
  "auth/forgetPin",
  async (data, thunkAPI) => {
    try {
      return await api.postForgetPin(data);
    } catch (error) {
      return error;
    }
  }
);
// Send Code Action
export const sendCode = createAsyncThunk(
  "auth/sendCode",
  async (data, thunkAPI) => {
    try {
      return await api.postSendCode(data);
    } catch (error) {
      return error;
    }
  }
);
// verify code action
export const verifyCode = createAsyncThunk(
  "auth/verifyCode",
  async (data, thunkAPI) => {
    try {
      return await api.postVerifyCode(data);
    } catch (error) {
      return error;
    }
  }
);
// create pin action
export const createPin = createAsyncThunk(
  "auth/createPin",
  async (data, thunkAPI) => {
    try {
      return await api.postCreatePin(data);
    } catch (error) {
      return error;
    }
  }
);
// admin login
export const adminLogin = createAsyncThunk(
  "auth/adminLogin",
  async (data, thunkAPI) => {
    try {
      return await api.authLogin(data);
    } catch (error) {
      return error;
    }
  }
);

// get User Account
export const getAccountProfile = createAsyncThunk(
  "auth/getAccountProfile",
  async (data, thunkAPI) => {
    try {
      return await api.admAccountDetails(data);
    } catch (error) {
      return error;
    }
  }
);

// auth Slice
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("admToken");
      localStorage.removeItem("user");
      localStorage.removeItem("accessInstance");
      localStorage.removeItem("admAuthStatus");
      state.admToken = "";
      state.authStatus = false;
      state.user = "";
      state.accessObj = "";
    },
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
    },
    resetAuthProcess: (state) => {
      state.loginToken = "";
      state.admPhone = "";
      state.admAccountType = "";
      localStorage.removeItem("admLoginToken");
      localStorage.removeItem("admPhone");
      localStorage.removeItem("admAccountType");
    },
    storePrev: (state, par) => {
      console.log(par);
      let { payload } = par;
      state.prev = payload;
      // JSON.stringify()
      localStorage.setItem("prev_route", payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPin.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(resetPin.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(forgetPin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgetPin.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(forgetPin.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(sendCode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendCode.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(sendCode.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(verifyCode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyCode.fulfilled, (state, action) => {
        let { payload } = action;

        state.loginToken = payload?.data?.browserToken;
        state.admAccountType = payload?.data?.accountType;
        state.authStatus = false;
        state.admPhone = payload?.data?.phoneNumber;
        state.isLoading = false;
      })
      .addCase(verifyCode.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createPin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPin.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createPin.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(adminLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(adminLogin.fulfilled, (state, action) => {
        let { payload } = action;
        state.user = payload?.data?.accountDetails;
        state.accessObj = payload?.data?.accountAccess;
        state.admToken = payload?.data?.authToken;
        state.authStatus = true;
        state.isLoading = false;
      })
      .addCase(adminLogin.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getAccountProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAccountProfile.fulfilled, (state, action) => {
        let { payload } = action;
        console.log(payload);
        if (
          (payload && payload.status === 401) ||
          (payload && !payload.status)
        ) {
          // logout();
          localStorage.removeItem("admToken");
          localStorage.removeItem("user");
          localStorage.removeItem("accessInstance");
          localStorage.removeItem("admAuthStatus");
          state.admToken = "";
          state.authStatus = false;
          state.user = "";
          state.accessObj = "";
          state.isLoading = false;
        }

        if (payload && payload.status && payload.statusCode === 2) {
          localStorage.setItem(
            "user",
            JSON.stringify(payload.data.accountDetails)
          );
          localStorage.setItem(
            "accessInstance",
            JSON.stringify(payload.data.accountAccess)
          );
          state.user = payload.data.accountDetails;
          state.accessObj = payload.data.accountAccess;
          state.isLoading = false;
        }
      })
      .addCase(getAccountProfile.rejected, (state, action) => {
        setTimeout(() => {
          state.isLoading = false;
        }, 5000);
      });
  },
});

export const { reset, resetAuthProcess, logout, storePrev } = authSlice.actions;
export default authSlice.reducer;
