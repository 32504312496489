import { Button, IconButton, MenuItem, MenuList } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { BsSearch } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputBase from '@mui/material/InputBase';
import Pagination from '@mui/material/Pagination';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popover from '@mui/material/Popover';
import Checkbox from '@mui/material/Checkbox';



  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: '#FFF',
  
    border:'1px solid #691e78',
    borderRadius:"100px",
    '&:hover': {
      // backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width:'345px',
  
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
     
    },
  
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: "rgba(103, 30, 120, 0.1)",
      fontFamily:'Gilroy-Bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontFamily:'Gilroy-Regular'
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: ' rgba(103, 30, 120, 0.1)'
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  function createData( name, phone, session,  email, address) {
    return {  session,  phone,  name,  email, address };
  }
  
  const rows = [
    createData(  'Olaoluwa Thomson','08132182983', 'Adeola Grace (2)', 'abc@gmail.com', '12, Ased street, Lagos'),
    createData( 'Olaoluwa Thomson','08132182983',  'Lawal Tolulope(2)', 'abc@gmail.com', '12, Ased street, Lagos'),

  ];
  

const CreateSchools = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

  return (
   <Box>
  
    <Box
       
        
          >
                        <Box sx={{ pt:4, background: 'rgba(103, 30, 120, 0.1)', height:'100px', pl:{xs:2.5}}}>

                        <Search sx={{width:{lg:'345px', md:'330px', sm:'auto', xs:'auto', pl:{xs:6}}}}>
            <SearchIconWrapper>
              <BsSearch />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
                        </Box>
            <TableContainer
           
            >
              <Table sx={{ minWidth: 700, fontFamily:'Gilroy-Regular' }} aria-label="customized table">
                <TableHead sx={{  backgroundColor: "rgba(103, 30, 120, 0.1)", width:'100%',}}>
      
                  <TableRow>
                
                    <StyledTableCell>S/N</StyledTableCell>
                    <StyledTableCell >Name</StyledTableCell>
                    <StyledTableCell >
                    Phone Number
                    </StyledTableCell>
                    <StyledTableCell >
                    Email
                    </StyledTableCell>
                    <StyledTableCell>
                    Address
                    </StyledTableCell>
                  
                    <StyledTableCell >
                   
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{fontFamily:'Gilroy-Regular'}}>
                  {rows.map((row, index) => (
                    <StyledTableRow key={index}>
                 
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.session}
                      </StyledTableCell>
                      <StyledTableCell >{row.phone}</StyledTableCell>
                      <StyledTableCell >
                        {row.email}
                      </StyledTableCell>
                      <StyledTableCell >
                        {row.address}
                      </StyledTableCell>
                    
                      <StyledTableCell  >
                      <IconButton onClick={handleClick}>
                        <MoreVertIcon/>
                      </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
         

            <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
      >
       <Box sx={{bgcolor:'#671E78', color:'#fff', fontFamily:'Gilroy-Regular', px:3}}>
        <MenuList sx={{fontFamily:'Gilroy-Regular'}}>
            <MenuItem>View Details</MenuItem>
       
        </MenuList>
       </Box>
      </Popover>
       
   </Box>
  )
}

export default CreateSchools