import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  MenuList,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { BsSearch } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Typography,
  Modal,
  FormControl,
  Table,
  TableBody,
  TableContainer,
  Select,
  Popover,
  InputBase,
  TableHead,
  TableRow,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius:2,
  border:'0.5px solid #891e78',
  p: 4,
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gilroy-Regular",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function createData(
  session,
  createdBy,
  phone,
  studentClass,
  name,
  status,
  email
) {
  return { session, createdBy, phone, studentClass, name, status, email };
}

const rows = [
  createData(
    "2021/2022",
    "Jss 1",
    "1",
    "Jss 2 Grace",
    "Adeola Grace (2)",
    "Pending",
    "Mathematics"
  ),
  createData(
    "2021/2022",
    "Jss 1",
    "2",
    "Jss 1 Purple",
    "Lawal Tolulope(2)",
    "Declined",
    "English"
  ),
  createData(
    "2021/2022",
    "Jss 2",
    "1",
    "Jss 1 Purple",
    "Lawal Tolulope(2)",
    "Pending",
    "Mathematics"
  ),
  createData(
    "2021/2022",
    "Jss 1",
    "1",
    "Jss 1 Purple",
    "Lawal Tolulope(2)",
    "Endorsed",
    "Mathematics"
  ),
];

const CreateLesson = (index) => {
  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const [school, setSchool] = React.useState("Global Statistics");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate("/parentmanagement/student");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          bgcolor: "#691e78",
          borderRadius: "10px",
          justifyContent: "center",
          alignItems: "center",
          height: "130px",
        }}
      >
        <Grid item lg={5}>
          <Box>
            <InputLabel
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontFamily: "gilroy-regular",
              }}
            >
              School Year
            </InputLabel>
            <FormControl fullWidth>
              <Select
                sx={{
                  fontFamily: "Gilroy-Regular",
                  border: "1px solid white",
                  color: "#fff",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                size="small"
                value={school}
                onChange={(e) => setSchool(e.target.value)}
              >
                {/* ...................................................... */}
                {/* ...................................................... */}
                <MenuItem
                  sx={{ fontFamily: "Gilroy-Regular" }}
                  value="Global Statistics"
                >
                  Global Statistics
                </MenuItem>
                <MenuItem value="School 1">School 1</MenuItem>
                <MenuItem
                  sx={{ fontFamily: "Gilroy-Regular" }}
                  value="School 2"
                >
                  School 2
                </MenuItem>

                {/* ...................................................... */}
                {/* ...................................................... */}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item lg={3.5}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontFamily: "gilroy-regular",
              }}
            >
              Week
            </InputLabel>
            <FormControl fullWidth>
              <Select
                sx={{
                  fontFamily: "Gilroy-Regular",
                  border: "1px solid white",
                  color: "#fff",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                size="small"
                value={school}
                onChange={(e) => setSchool(e.target.value)}
              >
                {/* ...................................................... */}
                {/* ...................................................... */}
                <MenuItem
                  sx={{ fontFamily: "Gilroy-Regular" }}
                  value="Global Statistics"
                >
                  Global Statistics
                </MenuItem>
                <MenuItem value="School 1">School 1</MenuItem>
                <MenuItem
                  sx={{ fontFamily: "Gilroy-Regular" }}
                  value="School 2"
                >
                  School 2
                </MenuItem>

                {/* ...................................................... */}
                {/* ...................................................... */}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item lg={3}>
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Button
              fullWidth
              variant="contained"
              sx={{
                bgcolor: "#fff",
                color: "#691e78",
                "&:hover": { color: "#fff" },
              }}
            >
              <RefreshIcon />
              Generate
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Box mt={5} align="right">
        <Button
          variant="contained"
          onClick={handleOpenModal}
          sx={{ textTransform: "initial", fontFamily: "gilroy-regular" }}
        >
          Create New Lesson Note Setup
        </Button>
      </Box>
      <Box
        mt={2}
        sx={{
          border: "1px solid #671E78",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            borderRadius: "20px 20px 0px 0px",
            pt: 4,
            background: "rgba(103, 30, 120, 0.1)",
            height: "100px",
            pl: { xs: 2.5 },
          }}
        >
          <Search
            sx={{
              width: {
                lg: "345px",
                md: "330px",
                sm: "auto",
                xs: "auto",
                pl: { xs: 6 },
              },
            }}
          >
            <SearchIconWrapper>
              <BsSearch />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 700, fontFamily: "Gilroy-Regular" }}
            aria-label="customized table"
          >
            <TableHead
              sx={{ backgroundColor: "rgba(103, 30, 120, 0.1)", width: "100%" }}
            >
              <TableRow>
                <StyledTableCell>S/N</StyledTableCell>
                <StyledTableCell>School Year</StyledTableCell>
                <StyledTableCell>Week</StyledTableCell>
                <StyledTableCell>Subject</StyledTableCell>
                <StyledTableCell>Class</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Created By</StyledTableCell>
                <StyledTableCell>Created Date</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
              {rows.map((row, index) => (
                <StyledTableRow key={index} hover>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell>{row.session}</StyledTableCell>
                  <StyledTableCell>{row.phone}</StyledTableCell>
                  <StyledTableCell>{row.email}</StyledTableCell>
                  <StyledTableCell>{row.createdBy}</StyledTableCell>
                  <StyledTableCell>
                    {row.status === "Endorsed" && (
                      <Box
                        sx={{
                          color: "#67a116",
                          bgcolor: "#67a1161a",
                          textAlign: "center",
                          borderRadius: "3px",
                          px: 1,
                        }}
                      >
                        {row.status}
                      </Box>
                    )}
                    {row.status === "Pending" && (
                      <Box
                        sx={{
                          color: "#ffc710",
                          bgcolor: "#ffc7101a",
                          textAlign: "center",
                          borderRadius: "3px",
                          px: 1,
                        }}
                      >
                        {row.status}
                      </Box>
                    )}
                    {row.status === "Declined" && (
                      <Box
                        sx={{
                          color: "#b22929",
                          bgcolor: "#b229291a",
                          textAlign: "center",
                          borderRadius: "3px",
                          px: 1,
                        }}
                      >
                        {row.status}
                      </Box>
                    )}
                  </StyledTableCell>

                  <StyledTableCell>{row.session}</StyledTableCell>
                  <StyledTableCell>{row.session}</StyledTableCell>
                  <StyledTableCell>
                    <IconButton onClick={handleClick}>
                      <MoreVertIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "right", mt: 3 }}>
        <Pagination count={10} color="primary" />
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem sx={{ fontFamily: "Gilroy-Regular" }}>Edit</MenuItem>
            <MenuItem sx={{ fontFamily: "Gilroy-Regular" }}>Update</MenuItem>
            <MenuItem sx={{ fontFamily: "Gilroy-Regular" }}>
              Preview Lesson Note
            </MenuItem>
            <MenuItem sx={{ fontFamily: "Gilroy-Regular" }}>Delete</MenuItem>
            <MenuItem sx={{ fontFamily: "Gilroy-Regular" }}>
              Deactivate
            </MenuItem>
          </MenuList>
        </Box>
      </Popover>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
       <Box align="right">
<IconButton onClick={handleCloseModal}>
<CancelIcon/>
</IconButton>
       </Box>
       <Box align="center">
        <Typography varriant="h4" sx={{fontFamily:'gilroy-medium', fontSize:'30px'}}>Add Lesson Note Setup</Typography>
       </Box>
       <Divider sx={{mt:3}}/>
       <Box>
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <Box>
            <InputLabel
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontFamily: "gilroy-regular",
              }}
            >
              Wee
            </InputLabel>
            <FormControl fullWidth>
              <Select
                sx={{
                  fontFamily: "Gilroy-Regular",
                  border: "1px solid  #671E78",
                  color: "#671E78",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
              
                value={school}
                onChange={(e) => setSchool(e.target.value)}
              >
                {/* ...................................................... */}
                {/* ...................................................... */}
                <MenuItem
                  sx={{ fontFamily: "Gilroy-Regular" }}
                  value="Global Statistics"
                >
                  Global Statistics
                </MenuItem>
                <MenuItem value="School 1">School 1</MenuItem>
                <MenuItem
                  sx={{ fontFamily: "Gilroy-Regular" }}
                  value="School 2"
                >
                  School 2
                </MenuItem>

                {/* ...................................................... */}
                {/* ...................................................... */}
              </Select>
            </FormControl>
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box>
          <TextField
              type="date"
              fullWidth
              placeholder="Date"
              defaultValue={false}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1px solid #671E78",
                  },
                },
              }}
            />
            </Box>
          </Grid>
          <Grid item lg={6}>
          <Box>
            <InputLabel
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontFamily: "gilroy-regular",
              }}
            >
              Week
            </InputLabel>
            <FormControl fullWidth>
              <Select
                sx={{
                  fontFamily: "Gilroy-Regular",
                  border: "1px solid white",
                  color: "#fff",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
              
                value={school}
                onChange={(e) => setSchool(e.target.value)}
              >
                {/* ...................................................... */}
                {/* ...................................................... */}
                <MenuItem
                  sx={{ fontFamily: "Gilroy-Regular" }}
                  value="Global Statistics"
                >
                  Global Statistics
                </MenuItem>
                <MenuItem value="School 1">School 1</MenuItem>
                <MenuItem
                  sx={{ fontFamily: "Gilroy-Regular" }}
                  value="School 2"
                >
                  School 2
                </MenuItem>

                {/* ...................................................... */}
                {/* ...................................................... */}
              </Select>
            </FormControl>
            </Box>
          </Grid>
          <Grid item lg={6}>
          <Box>
            <InputLabel
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontFamily: "gilroy-regular",
              }}
            >
              Week
            </InputLabel>
            <FormControl fullWidth>
              <Select
                sx={{
                  fontFamily: "Gilroy-Regular",
                  border: "1px solid white",
                  color: "#fff",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
            
                value={school}
                onChange={(e) => setSchool(e.target.value)}
              >
                {/* ...................................................... */}
                {/* ...................................................... */}
                <MenuItem
                  sx={{ fontFamily: "Gilroy-Regular" }}
                  value="Global Statistics"
                >
                  Global Statistics
                </MenuItem>
                <MenuItem value="School 1">School 1</MenuItem>
                <MenuItem
                  sx={{ fontFamily: "Gilroy-Regular" }}
                  value="School 2"
                >
                  School 2
                </MenuItem>

                {/* ...................................................... */}
                {/* ...................................................... */}
              </Select>
            </FormControl>
            </Box>
          </Grid>
          <Grid item lg={6}>
          <Box>
            <TextField
              type="date"
              fullWidth
              defaultValue="Date from"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1px solid #671E78",
                  },
                },
              }}
            />
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box>
            <TextField
              type="date"
              fullWidth
              // placeholder="Date"
              // defaultValue={false}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1px solid #671E78",
                  },
                },
              }}
            />
            </Box>
          </Grid>
          <Grid item lg={12}>
            <Box>
              <Button fullWidth variant="contained" sx={{textTransform:'initial', fontFamily:'gilroy-Regular'}}>Add Lesson Note Setup</Button>
            </Box>
          </Grid>
        </Grid>
       </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CreateLesson;
