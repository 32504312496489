import React from "react";
import {
  BigHeader,
  Container,
  SmallHeader,
} from "../Dashboard/styles/dashboard.style";
import GlobalStyles from "../../assets/globalStyle";
import { Outlet, useLocation, Link } from "react-router-dom";
import { TabRoute, TabRouteContainer } from "../student_mgt/stu_mgt.style";

const HumanResources = () => {
  const location = useLocation();
  return (
    <Container>
      <GlobalStyles />
      <div>
        <SmallHeader>Hi there! Welcome</SmallHeader>
        <BigHeader>Human Resources</BigHeader>
      </div>
      {/* <TabRouteContainer>
        <TabRoute isActive={location.pathname.includes("staff-mgt")}>
          <Link to="/hr/staff-mgt">Staff Management</Link>
        </TabRoute>
        <TabRoute isActive={location.pathname.includes("request")}>
          <Link to="/hr/request">Request</Link>
        </TabRoute>
        <TabRoute isActive={location.pathname.includes("Payroll")}>
          <Link to="/hr/Payroll">Payroll</Link>
        </TabRoute>
        <TabRoute isActive={location.pathname.includes("nominal-roll")}>
          <Link to="/hr/nominal-roll">Nominal roll</Link>
        </TabRoute>
        <TabRoute isActive={location.pathname.includes("approval")}>
          <Link to="/hr/approval">Approval</Link>
        </TabRoute>
      </TabRouteContainer> */}

      <Outlet />
    </Container>
  );
};

export default HumanResources;
