import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const Title = (props) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <Box>
      {props.showCaption && (
        <Typography
          variant="caption"
          sx={{
            fontFamily: "Gilroy-Regular",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          Hi {user.name}! Welcome{" "}
          <span style={{ fontSize: "12px", color: "black" }}>
            {user.schoolName !== null && <>({user.schoolName})</>}
          </span>
        </Typography>
      )}

      <Typography
        variant="h1"
        sx={{
          fontFamily: "Gilroy-Bold",
          fontSize: { lg: "47px", xs: "40px" },
          lineHeight: { lg: "80px", xs: "60px" },
          borderBottom: "2px solid  #671E78",
        }}
      >
        {`${user.staffCategoryName ? user.staffCategoryName : ""} Dashboard ${
          props.title ? `- ${props.title}` : ""
        }`}
      </Typography>
    </Box>
  );
};
Title.propTypes = {
  showCaption: PropTypes.bool,
};

Title.defaultProps = {
  showCaption: true,
};

export default Title;
