import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { AuthButton } from "../../pages/auth/Login.style";
import { BsArrowLeftShort } from "react-icons/bs";
import { GoBackDiv } from "../../pages/student_mgt/stu_mgt.style";
import CreateParentForm from "./CreateParentForm";
import CreateParentList from "./CreateParentList";
import { createParent } from "../../services/ParentStudent";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ErrorService from "../../services/errorService";
import { isObjEmpty } from "../../services/utils";
const CreateParent = () => {
  const [showForm, setShowForm] = useState(false);
  const isTab = useMediaQuery({ maxWidth: 992 });
  const { admToken } = useSelector((state) => state.auth);
  const [formLoading, setFormLoading] = useState(false);
  const [obj, setObj] = useState({
    fullName: "Kosofe Kosofe",
    title: "Mr",
    email: "string",
    officeAddress: "string",
    placeOfWork: "string",
    dateOfBirth: "2023-05-11T12:31:50.557Z",
    nameOfChildren: "Bello Bello",
    phoneNumber: "",
    residentialAddress: "string",
    studentId: 0,
    schoolId: 0,
    relationShip: "string",
    postalAddress: "string",
    permanentAddress: "string",
    profession: "string",
    statusRankDesignation: "string",
    profileImagePath: "",
    accountStatus: "0",
    religion: "Christianity",
    placeOfWorship: "string",
    denomination: "string",
    statusInPlaceOfWorship: "string",
    placeOfBirth: "string",
    permanentHomeAddress: "string",
    nearestBusstop: "string",
    educationalQualification: "string",
    occupation: "string",
    nationality: "string",
    stateOfOrigin: "string",
    lg: "string",
    homeTown: "string",
    createdBy: "N",
  });

  const createParentFunc = async () => {
    console.log(obj);
    let formIsValid = isObjEmpty(obj);
    if (!formIsValid) {
      return toast.error("Please Make Sure You Fill All The Form");
    }
    try {
      setFormLoading(true);
      const res = await createParent(admToken, obj);
      console.log(res);
      setFormLoading(false);
      res.status &&
        res.statusCode === 2 &&
        toast.success(res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        }) &&
        setShowForm(false);
      (!res.status || res.status === 400) &&
        ErrorService.displayErrorAlert(res);
    } catch (error) {
      setFormLoading(false);
      console.log(error);
      toast.error("An Error Ocurred, Try Again Later");
    }
  };
  return (
    <div style={{ marginTop: "0.6rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: !showForm ? "end" : "start",
        }}
      >
        {!showForm ? (
          <AuthButton
            mt="0.6rem"
            pl="0px"
            width={isTab ? "50%" : "30%"}
            onClick={() => setShowForm(!showForm)}
          >
            <div>
              <span>Create New Parent</span>
            </div>
            {/* Send Code */}
          </AuthButton>
        ) : (
          <GoBackDiv
            onClick={() => setShowForm(!showForm)}
            style={{
              cursor: "pointer",
            }}
          >
            <div>
              <BsArrowLeftShort />
            </div>
            <span>Back To Parent List</span>
          </GoBackDiv>
        )}
      </div>
      <>{!showForm && <CreateParentList />}</>
      <>
        {showForm && (
          <CreateParentForm
            showFormFunc={setShowForm}
            formLoading={formLoading}
            obj={obj}
            setObj={setObj}
            action={createParentFunc}
            readOnly={false}
          />
        )}
      </>
    </div>
  );
};

export default CreateParent;
