import React, { useState } from "react";
import {
  FormFlex,
  FormFlexDiv,
  ImageUploadContainer,
  ImageWrapper,
  TabContentWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
import { InputWrapper, AuthButton } from "../../pages/auth/Login.style";
import Select from "../select/select";
import { useMediaQuery } from "react-responsive";
import GlobalStyles from "../../assets/globalStyle";
import DatePickerComponent from "../datepicker/DatePicker";
import avatar from "../../assets/Images/avatar.jpg";
import Upload from "../../assets/icons/upload";
import colors from "../../assets/colors.json";
import PhoneInputComp from "../PhoneInputComp";
import ImageUploader from "react-images-upload";
import PropTypes from "prop-types";

const containerStyle = {
  background: "transparent",
  boxShadow: "none",
  padding: "0px",
  justifyContent: "flex-start",
  margin: "0",
};
const style = {
  background: "white",
  color: colors.primary,
};
const PersonalData = ({ data, setData, readOnly }) => {
  const [dob, setDob] = useState(new Date(2014, 0, 3));
  // const [phoneVal, setPhoneVal] = useState("");
  // const [phoneValTwo, setPhoneValTwo] = useState("");
  // const [inputPhone, setInputPhone] = useState("");
  // const [inputPhoneTwo, setInputPhoneTwo] = useState("");
  const [opt, setOpt] = useState("");
  const titleArr = [
    {
      id: 1,
      name: "Mr",
    },
    {
      id: 22,
      name: "mr",
    },
    {
      id: 29,
      name: "mrs",
    },
    {
      id: 29,
      name: "MRS",
    },
    {
      id: 2,
      name: "Mrs",
    },
    {
      id: 3,
      name: "Miss",
    },
    {
      id: 3,
      name: "Dr",
    },
    {
      id: 3,
      name: "Prof",
    },
  ];

  const optionsArr = [
    {
      id: 1,
      name: "Value 1",
    },
    {
      id: 2,
      name: "Value 2",
    },
  ];
  const onDrop = (picture, url) => {
    console.log(picture);
    console.log(url);

    setData({ ...data, profileImagePath: url[0] });
  };
  return (
    <TabContentWrapper>
      <GlobalStyles />

      <FormFlex reverse={true}>
        <FormFlexDiv>
          {/* full name */}
          <InputWrapper>
            <label htmlFor="full_name">Full Name</label>
            <input
              disabled={readOnly}
              type="text"
              value={data.fullName}
              onChange={(e) => setData({ ...data, fullName: e.target.value })}
              placeholder="Parent's Full Name"
            />
          </InputWrapper>
          {/* title */}
          <InputWrapper>
            <label htmlFor="Degree">Title</label>
            <Select
              disabled={readOnly}
              title="Title"
              index="name"
              selected={data.title}
              options={titleArr}
              setSelected={(val) => {
                setData({ ...data, title: val.name });
                // console.log(val);

                // setOpt(val.name);
              }}
            />
          </InputWrapper>
          {/* email */}
          <InputWrapper>
            <label htmlFor="full_name">Email Address</label>
            <input
              type="text"
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              placeholder="Parent's Email"
              disabled={readOnly}
            />
          </InputWrapper>
        </FormFlexDiv>
        <FormFlexDiv>
          <ImageUploadContainer>
            <ImageWrapper>
              <img
                src={
                  data.profileImagePath !== "" ? data.profileImagePath : avatar
                }
                alt=""
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "white",
                  height: "fit-content",
                  justifyContent: "center",
                  pointerEvents: readOnly ? "none" : "",
                  cursor: readOnly ? "not-allowed" : "",
                }}
              >
                <Upload />
                <ImageUploader
                  withIcon={false}
                  onChange={onDrop}
                  buttonText="Upload"
                  label=""
                  input
                  imgExtension={[".jpg", ".png", ".jpeg"]}
                  maxFileSize={5242880}
                  singleImage={true}
                  buttonStyles={style}
                  fileContainerStyle={{
                    ...containerStyle,
                  }}
                />
              </div>
            </ImageWrapper>
          </ImageUploadContainer>
        </FormFlexDiv>
      </FormFlex>
      <FormFlex>
        <FormFlexDiv>
          {/* residential address */}
          <InputWrapper>
            <label htmlFor="full_name">Residential Address</label>
            <textarea
              name=""
              id=""
              cols="30"
              disabled={readOnly}
              rows="2"
              value={data.residentialAddress}
              onChange={(e) =>
                setData({ ...data, residentialAddress: e.target.value })
              }
              placeholder="Parent's Residential Address"
            ></textarea>
          </InputWrapper>
          {/* date of birth */}
          <InputWrapper>
            <label htmlFor="d_o_B">Date Of Birth</label>
            {readOnly && (
              <DatePickerComponent
                disabled={readOnly}
                selected={data.dob !== "" ? new Date(data.dob) : dob}
                setSelected={(val) => setData({ ...data, dateOfBirth: val })}
              />
            )}

            {!readOnly && (
              <DatePickerComponent
                disabled={readOnly}
                selected={
                  data.dateOfBirth !== "" ? new Date(data.dateOfBirth) : dob
                }
                setSelected={(val) => setData({ ...data, dateOfBirth: val })}
              />
            )}
          </InputWrapper>
          {/* place of birth */}
          <InputWrapper>
            <label htmlFor="full_name">Place Of Birth</label>
            <input
              type="text"
              disabled={readOnly}
              value={data.placeOfBirth}
              onChange={(e) =>
                setData({ ...data, placeOfBirth: e.target.value })
              }
              placeholder="Parent's Place Of Birth"
            />
          </InputWrapper>
          {/* phone number  */}
          <InputWrapper>
            <label htmlFor="full_name">Phone Number</label>

            <PhoneInputComp
              disabled={readOnly}
              // setPhoneInput={(val) => setData({ ...data, inputVal: val })}
              setPhoneVal={(val) => setData({ ...data, phoneNumber: val })}
              phoneInput={
                data.phoneNumber.length > 0 && `234${data.phoneNumber.slice(1)}`
              }
            />
          </InputWrapper>
          {/* name of childrens */}
          <InputWrapper>
            <label htmlFor="full_name">
              Name Of Child(ren) On School Application
            </label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="5"
              disabled={readOnly}
              value={data.nameOfChildren}
              onChange={(e) =>
                setData({ ...data, nameOfChildren: e.target.value })
              }
            ></textarea>
          </InputWrapper>
          {/* relationship with children */}
          <InputWrapper>
            <label htmlFor="Nationality">Relationship With Child</label>

            <input
              type="text"
              value={data.relationShip}
              onChange={(e) =>
                setData({ ...data, relationShip: e.target.value })
              }
              disabled={readOnly}
            />
          </InputWrapper>
          {/* education qualification */}
          <InputWrapper>
            <label htmlFor="Nationality">Education Qualification</label>
            <input
              type="text"
              value={data.educationalQualification}
              onChange={(e) =>
                setData({ ...data, educationalQualification: e.target.value })
              }
              disabled={readOnly}
            />
          </InputWrapper>
          {/* nationality */}
          <InputWrapper>
            <label htmlFor="full_name">Nationality</label>
            <input
              type="text"
              value={data.nationality}
              onChange={(e) =>
                setData({ ...data, nationality: e.target.value })
              }
              placeholder="Parent's Natiionality"
              disabled={readOnly}
            />
          </InputWrapper>{" "}
        </FormFlexDiv>
        <FormFlexDiv>
          {/* state of origin */}
          <InputWrapper>
            <label htmlFor="full_name">State of Origin</label>
            <input
              type="text"
              value={data.stateOfOrigin}
              disabled={readOnly}
              onChange={(e) =>
                setData({ ...data, stateOfOrigin: e.target.value })
              }
              placeholder="Parent's Full Name"
            />
          </InputWrapper>
          {/* home town */}
          <InputWrapper>
            <label htmlFor="full_name">Home Town</label>
            <input
              type="text"
              value={data.homeTown}
              disabled={readOnly}
              onChange={(e) => setData({ ...data, homeTown: e.target.value })}
              placeholder="Parent's Full Name"
            />
          </InputWrapper>
          {/* local government */}
          <InputWrapper>
            <label htmlFor="full_name">Local Government</label>
            <input
              type="text"
              value={data.lg}
              onChange={(e) => setData({ ...data, lg: e.target.value })}
              placeholder="Parent's Local Government"
              disabled={readOnly}
            />
          </InputWrapper>
          {/* Profession */}
          <InputWrapper>
            <label htmlFor="full_name">Profession/Occupation</label>
            <input
              type="text"
              value={data.occupation}
              onChange={(e) => setData({ ...data, occupation: e.target.value })}
              placeholder="Parent's Profession"
              disabled={readOnly}
            />
          </InputWrapper>
          {/* Profession Rank */}
          <InputWrapper>
            <label htmlFor="full_name">Status/Rank/Designation</label>
            <input
              type="text"
              value={data.statusRankDesignation}
              onChange={(e) =>
                setData({ ...data, statusRankDesignation: e.target.value })
              }
              placeholder="Parent's Full Name"
            />
          </InputWrapper>
          {/* Place of work/office */}
          <InputWrapper>
            <label htmlFor="full_name">Place Of Work</label>
            <input
              type="text"
              value={data.placeOfWork}
              onChange={(e) =>
                setData({ ...data, placeOfWork: e.target.value })
              }
              placeholder="Parent's Place Of Work"
              disabled={readOnly}
            />
          </InputWrapper>
          {/* place of work address */}
          <InputWrapper>
            <label htmlFor="full_name">Office Address</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="2"
              value={data.officeAddress}
              onChange={(e) =>
                setData({ ...data, officeAddress: e.target.value })
              }
              disabled={readOnly}
              placeholder="Parent's Office Address"
            ></textarea>
          </InputWrapper>
          {/* postal address */}
          <InputWrapper>
            <label htmlFor="postal">Postal Address</label>
            <input
              type="text"
              value={data.postalAddress}
              onChange={(e) =>
                setData({ ...data, postalAddress: e.target.value })
              }
              placeholder="Parent's Postal Address"
              disabled={readOnly}
            />
          </InputWrapper>{" "}
          {/* Permanent home address */}
          <InputWrapper>
            <label htmlFor="full_name">Permanent Home Address</label>
            <textarea
              name=""
              disabled={readOnly}
              id=""
              cols="30"
              rows="3"
              value={data.permanentHomeAddress}
              onChange={(e) =>
                setData({ ...data, permanentHomeAddress: e.target.value })
              }
              placeholder="Parent's Permanent Home Address"
            ></textarea>
          </InputWrapper>{" "}
          {/* nearest busstop */}
          <InputWrapper>
            <label htmlFor="full_name">Nearest Bus stop to your house</label>
            <input
              type="text"
              value={data.nearestBusstop}
              onChange={(e) =>
                setData({ ...data, nearestBusstop: e.target.value })
              }
              placeholder="Nearest Busstop"
              disabled={readOnly}
            />
          </InputWrapper>
        </FormFlexDiv>
      </FormFlex>
    </TabContentWrapper>
  );
};

PersonalData.propTypes = {
  readOnly: PropTypes.bool,
};

PersonalData.defaultProps = {
  readOnly: true,
};
export default PersonalData;
