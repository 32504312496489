import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";



export default function SuccessAlert( {openState, message}) {
  const [open, setOpen] = React.useState(openState);


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (


      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%", fontFamily:'Gilroy-regular' }}>
        {message}
        </Alert>
      </Snackbar>

  );
}
