import { useCallback, useState, useEffect } from "react";
import { AuthButton } from "../../../pages/auth/Login.style";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import api from "../../../services/ControlPanel";
import Loader from "../../pageLoader/loader";
import {
  AccountSummaryWrapper,
  Input,
  Search,
  TabContainer,
  TabLinkContainer,
  Icon,
} from "../../../pages/Dashboard/styles/dashboard.style";
import GlobalStyles from "../../../assets/globalStyle";
import { toast } from "react-toastify";
import colors from "../../../assets/colors.json";
import { BsSearch } from "react-icons/bs";
import Pagination from "../../pagination_one/pagination";
import { checkAccess, paginateData } from "../../../services/utils";
import Modal from "../../modal/Modal";
import AssignPriviledge from "./AssignPriviledge";
const StaffSetups = () => {
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [showForm, setShowForm] = useState(false);
  const isTab = useMediaQuery({ maxWidth: 992 });
  const [id, setId] = useState("");
  const [itemOffSet, setitemOffSet] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [arr, setArr] = useState([]);
  const [phone, setPhone] = useState("");
  const [staffRole, setStaffRole] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [staffSetup, setStaffSetup] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [paramsLoading, setParamsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const getStaffSetups = useCallback(async () => {
    try {
      setLoading(true);
      const res = await api.getStaffWithRoleSetUp(admToken, user.schoolId);
      if (res.status && res.statusCode === 2) {
        setAllData(res.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [admToken, user.schoolId]);

  const activateStaff = useCallback(
    async (id, bool) => {
      try {
        setParamsLoading(true);
        const res = await api.postActivateStaffAccount(admToken, id, bool);
        console.log(res);
        if (res.status && res.statusCode === 2) {
          getStaffSetups();
          toast.success("Role Setups Was Updated Successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000,
          });
        }

        res.status === 500 && toast.error(res.data.message);
        setParamsLoading(false);
      } catch (error) {
        setParamsLoading(false);

        console.log(error);
      }
    },
    [admToken, getStaffSetups]
  );

  const getStaffRoleSetups = useCallback(
    async (id) => {
      setUpdateLoading(true);
      try {
        const res = await api.getStaffRoleSetups(admToken, id);
        if (res.status && res.statusCode === 2) {
          setUpdateLoading(false);
          setStaffSetup(res.data);
          setShowModal(true);
        } else {
          setUpdateLoading(false);
          toast.error("An Error Occurred Try Again Later");
        }
        console.log(res);
      } catch (error) {
        setUpdateLoading(false);
        console.log(error.response);
      }
    },
    [admToken]
  );

  const deleteFunc = useCallback(
    async (id) => {
      try {
        setDeleteLoading(true);
        const res = await api.deleteStaffRole(admToken, id);
        console.log(res);
        if (res.status && res.statusCode === 2) {
          getStaffSetups();
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000,
          });
        }

        res.status === 500 && toast.error(res.data.message);
        setDeleteLoading(false);
      } catch (error) {
        setDeleteLoading(false);

        console.log(error);
      }
    },
    [admToken, getStaffSetups]
  );
  useEffect(() => {
    getStaffSetups();
  }, [getStaffSetups]);
  const filterSearch = useCallback(
    async (e) => {
      console.log(e.target.value);
      console.log("working");

      let arr = [];
      const queryRegex = new RegExp(e.target.value, "i");
      allData.forEach((item) => {
        Object.values(item).some((val) =>
          queryRegex.test(val) ? arr.push(item) : ""
        );
      });

      setArr(arr);
      setPageCount(0);
    },
    [allData]
  );
  const resetData = () => {
    setCurrentPage(1);
    console.log("reset data working");
    paginateData(allData, setArr, 1, itemOffSet, setitemOffSet, setPageCount);
  };

  useEffect(() => {
    paginateData(
      allData,
      setArr,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allData, currentPage, itemOffSet]);
  const [sQuery, setSQuery] = useState("");

  if (loading) {
    return <Loader />;
  }

  return (
    <div style={{ marginTop: "0.5rem" }}>
      <GlobalStyles />
      {showModal && staffRole !== null && staffSetup !== null && (
        <Modal
          start={true}
          content={
            <AssignPriviledge
              createRole={false}
              role={staffRole}
              staffSetup={staffSetup}
              schoolId={user.schoolId}
              reloadFunction={getStaffSetups}
              closeModal={setShowModal}
              showForm={true}
              update={true}
            />
          }
          size={"large"}
          onClose={setShowModal}
          pd={"0px"}
          iColor={"white"}
        />
      )}
      <div style={{ display: "none", justifyContent: "end" }}>
        <AuthButton
          mt="0.4rem"
          pl="0px"
          width={isTab ? "50%" : "30%"}
          onClick={() => setShowForm(!showForm)}
        >
          <div>
            <span>Create New Staff</span>
          </div>
        </AuthButton>
      </div>

      <TabContainer style={{ paddingBottom: "0px" }}>
        <TabLinkContainer bg="rgb(238, 224,241)">
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search
              type="text"
              placeholder="Search"
              value={sQuery}
              onChange={(e) => {
                setSQuery(e.target.value);
                if (e.target.value.length > 2) {
                  filterSearch(e);
                }

                if (e.target.value === "") {
                  resetData();
                  console.log("hellow world inside jsx");
                }
              }}
            />
          </Input>
        </TabLinkContainer>

        <AccountSummaryWrapper pt="0px">
          <table
            style={{
              borderRadius: "0px",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <tr
              className="tabel-head"
              style={{ color: "black", fontWeight: "bold" }}
            >
              <th>S/N</th>
              <th>Name ({allData.length})</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Role Name</th>
              <th></th>
            </tr>

            {arr.length > 0 ? (
              arr.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.roleName}</td>
                  <td style={{ paddingRight: "0.5rem" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {checkAccess(accessObj.rights, "Update") && (
                        <button
                          style={{
                            borderRadius: "9px",
                            border: `1px solid ${colors.primary}`,
                            padding: "0.3rem 0.6rem",
                          }}
                          onClick={() => {
                            // setName()
                            setStaffRole(item);
                            setPhone(item.phoneNumber);
                            getStaffRoleSetups(item.phoneNumber);
                          }}
                        >
                          {updateLoading && phone === item.phoneNumber
                            ? "Please wait"
                            : "Update Setup"}
                        </button>
                      )}
                      {checkAccess(accessObj.rights, "Activate") && (
                        <button
                          onClick={() => {
                            setId(item.staffId);
                            activateStaff(
                              item.staffId,
                              item.accountStatus === 1 ? false : true
                            );
                          }}
                          style={{
                            backgroundColor: colors.primary,
                            color: "white",
                            marginLeft: "5px",
                            borderRadius: "9px",
                            border: `1px solid ${colors.primary}`,
                            padding: "0.3rem 0.6rem",
                          }}
                        >
                          {paramsLoading && id === item.staffId ? (
                            <span>Please wait...</span>
                          ) : (
                            <span>
                              {item.accountStatus === 1
                                ? "Deactivate Staff "
                                : "Activate Staff"}
                            </span>
                          )}
                        </button>
                      )}
                      {checkAccess(accessObj.rights, "Delete") && (
                        <button
                          disabled={deleteLoading && id === item.staffId}
                          onClick={() => {
                            setId(item.staffId);
                            deleteFunc(item.staffId);
                          }}
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            marginLeft: "5px",
                            borderRadius: "9px",
                            border: `1px solid red`,
                            padding: "0.3rem 0.6rem",
                          }}
                        >
                          {deleteLoading && id === item.staffId
                            ? "Please wait..."
                            : "Delete Role"}
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} style={{ textAlign: "center" }}>
                  No New Staff Available
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      {pageCount > 1 && (
        <Pagination
          pageCount={pageCount > 1 && pageCount}
          range={3}
          currentPage={currentPage - 1}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};

export default StaffSetups;
