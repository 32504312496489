/* eslint-disable eqeqeq */
import {
  Avatar,
  Button,
  Chip,
  createTheme,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import Loader from "../pageLoader/loader";
import { useSelector } from "react-redux";
import Pagination from "../pagination_one/pagination";
import React, { useCallback, useState, useEffect } from "react";
import stu1 from "../../assets/Images/avatar.jpg";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ClassIcon from "@mui/icons-material/Class";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import {
  getLinkedUnLinkStudents,
  parentStudent,
  linkUnlinkStudent,
} from "../../services/ParentStudent";
import Modal from "../modal/Modal";
import GlobalStyles from "../../assets/globalStyle";
import {
  BigHeader,
  Icon,
  Input,
  Search,
  SmallHeader,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import { AuthButton } from "../../pages/auth/Login.style";
import FormLoader from "../FormLoader";

import ErrorService from "../../services/errorService";
import { BsSearch } from "react-icons/bs";
import { SchoolTray } from "../../pages/student_mgt/stu_mgt.style";
import { AiOutlineLink } from "react-icons/ai";
import { paginateData } from "../../services/utils";

const theme = createTheme({
  palette: {
    primary: {
      main: "#671e78",
    },
  },
});

const Students = ({ param, token, name }) => {
  const { user } = useSelector((state) => state.auth);
  const [pageLoading, setPageLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  // const [arr, setArr] = useState([]); //  all schools
  const [allList, setAllList] = useState([]);
  const [unlinked, setUnlinked] = useState([]);
  // modal
  const [uLoading, setULoading] = useState(false);
  // modal
  const [filter, setFilter] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffSet, setitemOffSet] = useState(0);
  const [studentPhone, setStudentPhone] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const getUnLinked = useCallback(async () => {
    try {
      setFilter("");

      setULoading(true);
      const res = await getLinkedUnLinkStudents(token, user.schoolId, 0, "");
      console.log(res.data.unLinkedStudent.length);
      res.status &&
        res.statusCode === 2 &&
        setAllList(res.data.unLinkedStudent);
      res.status === 400 && ErrorService.displayErrorAlert(res.data);
      setULoading(false);
    } catch (error) {
      setULoading(false);
      console.log(error);
    }
  }, [token, user.schoolId]);

  useEffect(() => {
    paginateData(
      allList,
      setUnlinked,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allList, currentPage, itemOffSet]);

  useEffect(() => {
    getUnLinked();
  }, [getUnLinked]);

  const calculateAge = (date) => {
    if (isNaN(new Date(date).getTime())) {
      return 0;
    } else {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();

      let age = currentYear - new Date(Date.parse(date)).getFullYear();
      const month = currentMonth - new Date(Date.parse(date)).getMonth();
      if (
        month < 0 ||
        (month === 0 &&
          new Date().getDate() < new Date(Date.parse(date)).getDate())
      ) {
        age--;
      }
      return age;
    }
  };

  const getStudents = useCallback(async () => {
    try {
      setPageLoading(true);
      const res = await parentStudent(token, param);
      res.status && res.statusCode === 2 && setStudents(res.data);
      setPageLoading(false);
      console.log(res);
    } catch (error) {
      setPageLoading(false);
      console.log(error);
    }
  }, [token, param]);
  useEffect(() => {
    getStudents();
  }, [getStudents]);

  const linkUnlinkAction = async (type, schoolId, studentId) => {
    setStudentPhone(studentId);
    try {
      setFormLoading(true);
      const res = await linkUnlinkStudent(
        token,
        schoolId,
        param,
        studentId,
        type
      );

      res.status &&
        res.statusCode === 2 &&
        toast.success(res.data, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        }) &&
        setShowModal(false);
      console.log(res);
      (!res.status || res.status === 400) &&
        ErrorService.displayErrorAlert(res);
      getStudents();
      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);

      console.log(error);
    }
  };
  const filterName = async (e) => {
    if (e.target.value === "") {
      setCurrentPage(1);
    }
    if (e.target.value.length > 0) {
      setFilter(e.target.value);
      let [firstName, lastName] = e.target.value.split(/\s+/);

      if (firstName && lastName) {
        let searchFirstName = firstName.toLowerCase();
        let searchLastName = lastName.toLowerCase();
        let result = allList.filter((person) => {
          let itemFirstName = person.firstName.toLowerCase();
          let itemLastName = person.lastName.toLowerCase();
          return (
            (itemFirstName.includes(searchFirstName) &&
              itemLastName.includes(searchLastName)) ||
            (itemFirstName.includes(searchLastName) &&
              itemLastName.includes(searchFirstName))
          );
        });
        console.log(result);
        setPageCount(1);
        setUnlinked(result);
        return;
      }
      if (firstName) {
        let searchFirstName = firstName.toLowerCase();
        let result = allList.filter((person) => {
          let itemFirstName = person.firstName.toLowerCase();
          let itemLastName = person.lastName.toLowerCase();
          return (
            itemFirstName.includes(searchFirstName) ||
            itemLastName.includes(searchFirstName)
          );
        });
        console.log(result);
        setPageCount(1);
        setUnlinked(result);
        return;
      }
    }
  };

  const content = (
    <>
      <BigHeader fs="20px" style={{ textAlign: "center" }}>
        Add More Student
      </BigHeader>
      <SmallHeader style={{ textAlign: "center" }}>
        {user.schoolId > 0
          ? user.schoolName
          : "Select A School By Default To Search For A Student "}
      </SmallHeader>
      <div>
        <TabLinkContainer bg="transparent">
          <Input width="100%">
            <Icon>
              <BsSearch />
            </Icon>
            <Search
              value={filter}
              onChange={(e) => {
                filterName(e);
              }}
              type="text"
              placeholder="Search By First Name or Last Name"
            />
          </Input>
        </TabLinkContainer>
      </div>

      {uLoading ? (
        <Loader />
      ) : (
        <SchoolTray>
          {unlinked.map((item, index) => (
            <Grid item lg={2} xs={6} key={index} sx={{ marginRight: "4px" }}>
              <Box
                sx={{
                  border: "1px solid #671e78 ",
                  borderRadius: "20px",
                  p: 0.7,
                  pb: 2,
                  minHeight: "350px",
                  minWidth: "fit-content",
                }}
              >
                <Avatar
                  src={item.profileImagePath ? item.profileImagePath : stu1}
                  variant="rounded"
                  sx={{
                    width: "100%",
                    height: "130px",
                    borderRadius: "15px",
                  }}
                />
                <Box mt={1}>
                  <Typography
                    variant="h6"
                    sx={{ fontFamily: "Gilroy-Bold", fontSize: "15px" }}
                  >
                    {`${item.firstName} ${item.lastName}`}
                  </Typography>
                  <Chip
                    label={`Aged: ${calculateAge(item.datOfBirth)}`}
                    sx={{
                      fontSize: "9px",
                      height: "16px",
                      bgcolor: "#5e9c091a",
                      color: "#5e9c09",
                    }}
                  />
                  <List>
                    <ListItem sx={{ px: 0, py: 0.5 }}>
                      <ListItemIcon
                        sx={{ color: "black", minWidth: 0, mr: 0.5 }}
                      >
                        <LocationCityIcon sx={{ fontSize: "15px" }} />
                      </ListItemIcon>
                      <Typography
                        variant="caption"
                        sx={{ lineHeight: "12px", fontSize: "10px" }}
                      >
                        {user.schoolId > 0 ? user.schoolName : ""}
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ px: 0, py: 0.5 }}>
                      <ListItemIcon
                        sx={{ color: "black", minWidth: 0, mr: 0.5 }}
                      >
                        <ClassIcon sx={{ fontSize: "15px" }} />
                      </ListItemIcon>
                      <Typography
                        variant="caption"
                        sx={{ lineHeight: "12px", fontSize: "10px" }}
                      >
                        {"Class Not Available"}
                      </Typography>
                    </ListItem>
                  </List>
                  {item.phoneNumber && (
                    <AuthButton
                      style={{ fontSize: "12px" }}
                      padding="2px"
                      onClick={() => {
                        linkUnlinkAction(
                          "LinkStudent",
                          user.schoolId,
                          item.phoneNumber
                        );
                      }}
                      width="100%"
                      disabled={formLoading && item.phoneNumber == studentPhone}
                    >
                      {formLoading && item.phoneNumber == studentPhone ? (
                        <FormLoader />
                      ) : (
                        <div>
                          <AiOutlineLink />
                          <span>Link</span>
                        </div>
                      )}
                    </AuthButton>
                  )}
                </Box>
              </Box>
            </Grid>
          ))}
        </SchoolTray>
      )}
      {pageCount > 1 && (
        <div
          style={{
            marginTop: "2px",
            marginBottom: "2px",
          }}
        >
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            range={3}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </>
  );
  if (pageLoading) {
    return <Loader />;
  }
  return (
    <>
      <GlobalStyles />
      {showModal && (
        <Modal content={content} size={"large"} onClose={setShowModal} />
      )}
      <ThemeProvider theme={theme}>
        <Box>
          <Typography
            variant="body"
            sx={{ fontFamily: "Gilroy-Medium", fontSize: "26px" }}
          >
            <span style={{ opacity: 0.3, fontFamily: "Gilroy-Regular" }}>
              Parent Management &#62;&#62;
            </span>{" "}
            Parent Dashboard -{" "}
            <span style={{ marginRight: "0.9rem", textTransform: "uppercase" }}>
              {name} {">>"}
            </span>
            ({students.length}) &#62;&#62; Student
          </Typography>
        </Box>
        <Box mt={5} px={1}>
          <Grid
            container
            spacing={2}
            sx={{
              minHeight: "300px",
              justifyContent: "center",
            }}
          >
            {students.map((item, index) => (
              <Grid item lg={3} xs={6} key={index}>
                <Box
                  sx={{
                    border: "1px solid #671e78 ",
                    borderRadius: "20px",
                    p: 0.7,
                    pb: 2,
                    minHeight: "350px",
                    minWidth: "fit-content",
                  }}
                >
                  <Avatar
                    src={item.studentImagePath ? item.studentImagePath : stu1}
                    variant="rounded"
                    sx={{
                      width: "100%",
                      height: "130px",
                      borderRadius: "15px",
                    }}
                  />
                  <Box mt={1}>
                    <Typography
                      variant="h6"
                      sx={{ fontFamily: "Gilroy-Bold", fontSize: "15px" }}
                    >
                      {item.studentName}
                      {/* {`${item.firstName} ${item.lastName}`} */}
                    </Typography>
                    <Chip
                      label={`Aged: ${calculateAge(item.age)}`}
                      sx={{
                        fontSize: "9px",
                        height: "16px",
                        bgcolor: "#5e9c091a",
                        color: "#5e9c09",
                      }}
                    />
                    <List>
                      <ListItem sx={{ px: 0, py: 0.5 }}>
                        <ListItemIcon
                          sx={{ color: "black", minWidth: 0, mr: 0.5 }}
                        >
                          <LocationCityIcon sx={{ fontSize: "15px" }} />
                        </ListItemIcon>
                        <Typography
                          variant="caption"
                          sx={{ lineHeight: "12px", fontSize: "10px" }}
                        >
                          {item.schoolName}
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ px: 0, py: 0.5 }}>
                        <ListItemIcon
                          sx={{ color: "black", minWidth: 0, mr: 0.5 }}
                        >
                          <ClassIcon sx={{ fontSize: "15px" }} />
                        </ListItemIcon>
                        <Typography
                          variant="caption"
                          sx={{ lineHeight: "12px", fontSize: "10px" }}
                        >
                          {"Class Not Available"}
                        </Typography>
                      </ListItem>
                    </List>
                    {item.phoneNumber && (
                      <AuthButton
                        style={{ fontSize: "12px" }}
                        padding="2px"
                        onClick={() => {
                          linkUnlinkAction(
                            "UnlinkStudent",
                            item.schoolId,
                            item.phoneNumber
                          );
                        }}
                        width="100%"
                        disabled={
                          formLoading && item.phoneNumber == studentPhone
                        }
                      >
                        {formLoading && item.phoneNumber == studentPhone ? (
                          <FormLoader />
                        ) : (
                          <div>
                            <AiOutlineLink />
                            <span>Link</span>
                          </div>
                        )}
                      </AuthButton>
                    )}

                    {/* <Button
                  mt={1}
                  variant="contained"
                  fullWidth
                  sx={{
                    background: colors.primary,
                    fontSize: "13px",
                    fontFamily: "Gilroy-Medium",
                    textTransform: "initial",
                  }}
                >
                  <LinkOffIcon sx={{ mr: 1, fontSize: "16px" }} /> Unlink
                </Button> */}
                  </Box>
                </Box>
              </Grid>
            ))}

            <Grid item lg={3}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Button
                  sx={{ textTransform: "initial" }}
                  onClick={() => setShowModal(!showModal)}
                >
                  <ControlPointIcon />
                  Add more Student
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Students;
