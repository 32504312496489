/* eslint-disable eqeqeq */
import React, { useState, useCallback, useEffect } from "react";
import GlobalStyles from "../../assets/globalStyle";
import {
  Avatar,
  Button,
  // Chip,
  createTheme,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { AiOutlineLink } from "react-icons/ai";

import Loader from "../pageLoader/loader";
import FormLoader from "../FormLoader";
import { toast } from "react-toastify";
import Modal from "../modal/Modal";
import stu1 from "../../assets/Images/student.jpg";
import {
  BsSearch,
  BsFillTelephoneFill,
  BsPersonWorkspace,
} from "react-icons/bs";
import ErrorService from "../../services/errorService";
import { AiOutlineMail } from "react-icons/ai";
import {
  BigHeader,
  Icon,
  Input,
  Search,
  SmallHeader,
  // TabContainer,
  // TabContent,
  // TabLink,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import { Box } from "@mui/system";
import colors from "../../assets/colors.json";
import {
  // allParents,
  linkedParents,
  studentParents,
  linkUnlinkStudent,
  studentLinkedParents,
} from "../../services/ParentStudent";
import { useSelector } from "react-redux";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { SchoolTray } from "../../pages/student_mgt/stu_mgt.style";
import { AuthButton } from "../../pages/auth/Login.style";
const theme = createTheme({
  palette: {
    primary: {
      main: "#671e78",
    },
  },
});
const Parents = ({ name, studentId, schoolId }) => {
  const [queryPhone, setQueryPhone] = useState(null);
  const { admToken } = useSelector((state) => state.auth);

  // const [loading, setLoading] = useState(false);
  const [arr, setArr] = useState([]);
  const [list, setList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [parents, setParents] = useState(null);

  const getParents = useCallback(async () => {
    try {
      setParents(null);
      setPageLoading(true);
      // const res = await studentParents(admToken, studentId);
      const res = await studentLinkedParents(admToken, studentId);
      console.log(res);
      if (
        res.status &&
        res.statusCode === 2 &&
        (Array.isArray(res.data) || typeof res.data === "object")
      ) {
        setParents(res.data);
      }
      setPageLoading(false);
      console.log(res);
    } catch (error) {
      setPageLoading(false);
      console.log(error);
    }
  }, [admToken, studentId]);
  useEffect(() => {
    getParents();
  }, [getParents]);
  const getData = useCallback(async () => {
    try {
      const res = await linkedParents(admToken, "", "AllUnlinkedParents");
      console.log(res);

      if (res.status === true && res.statusCode === 2) {
        setArr(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);
  useEffect(() => {
    getData();
  }, [getData]);
  const [sQuery, setSQuery] = useState("");
  const filterSearch = async (e) => {
    const queryRegex = new RegExp(e.target.value, "i");
    const arrr = arr.filter((item) => {
      return Object.values(item).some((val) => queryRegex.test(val));
    });
    console.log(arrr);
    setList(arrr);
    // }
  };

  const [formLoading, setFormLoading] = useState(false);
  const linkUnlinkAction = async (type, parentPhone) => {
    setQueryPhone(parentPhone);
    try {
      setFormLoading(true);
      const res = await linkUnlinkStudent(
        admToken,
        schoolId,
        parentPhone,
        studentId,
        type
      );
      if (res.status && res.statusCode === 2) {
        toast.success(res.data, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        getParents();
        getData();
        setShowModal(false);
      }
      setFormLoading(false);
      console.log(res);
      (res.status === 400 || res.status === 500) &&
        ErrorService.displayErrorAlert(res.data);

      !res.status && ErrorService.displayErrorAlert(res.data);
      // getStudents();
    } catch (error) {
      setFormLoading(false);

      console.log(error);
    }
  };

  const content = (
    <>
      <BigHeader fs="16px" style={{ textAlign: "center" }}>
        Add A Parent
      </BigHeader>
      <div>
        <TabLinkContainer bg="transparent">
          <Input width="100%" mWidth="100%">
            <Icon>
              <BsSearch />
            </Icon>
            <Search
              style={{ background: "white" }}
              value={sQuery}
              type="text"
              placeholder="Search For A Parent"
              onChange={(e) => {
                console.log(e.target.value);
                setSQuery(e.target.value);

                filterSearch(e);
              }}
            />
          </Input>
        </TabLinkContainer>
      </div>
      <SchoolTray
      // style={{ display: "flex", flexWrap: "nowrap" }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            // minHeight: "300px",
            justifyContent: "start",
            flexWrap: "nowrap",
          }}
        >
          {list.map((i, index) => (
            <Grid
              item
              lg={6}
              md={5}
              xs={5}
              key={index}
              sx={{ marginRight: "3px" }}
            >
              <Box
                sx={{
                  border: "1px solid #671e78 ",
                  borderRadius: "20px",
                  p: 0.7,
                  pb: 2,
                  minHeight: "300px",
                  // minWidth: "fit-content",
                  width: "100%",
                }}
              >
                <Avatar
                  // src={i.profileImagePath ? i.profileImagePath : stu1}
                  variant="rounded"
                  sx={{
                    width: "100%",
                    height: "130px",
                    objectFit: "contain",
                    borderRadius: "15px",
                  }}
                >
                  <img
                    src={i.profileImagePath ? i.profileImagePath : stu1}
                    alt="logo"
                    style={{
                      width: "100%",
                      height: "130px",
                      objectFit: "fill",
                      borderRadius: "15px",
                    }}
                  />
                </Avatar>
                <Box mt={1}>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      fontFamily: "Gilroy-Bold",
                      fontSize: "15px",
                      color: colors.primary,
                    }}
                  >
                    {`${i.fullName}`}
                  </Typography>
                  <List>
                    <ListItem sx={{ px: 0, py: 0.5 }}>
                      <ListItemIcon
                        sx={{ color: colors.primary, minWidth: 0, mr: 0.5 }}
                      >
                        <AiOutlineMail sx={{ fontSize: "15px" }} />
                      </ListItemIcon>
                      <Typography
                        variant="caption"
                        sx={{ lineHeight: "12px", fontSize: "13px" }}
                      >
                        {i.email}
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ px: 0, py: 0.5 }}>
                      <ListItemIcon
                        sx={{ color: colors.primary, minWidth: 0, mr: 0.5 }}
                      >
                        <BsFillTelephoneFill
                          sx={{ fontSize: "15px", color: colors.primary }}
                        />
                      </ListItemIcon>
                      <Typography
                        variant="caption"
                        sx={{ lineHeight: "12px", fontSize: "13px" }}
                      >
                        {i.phoneNumber}
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ px: 0, py: 0.5 }}>
                      <ListItemIcon
                        sx={{
                          color: colors.primary,
                          minWidth: 0,
                          mr: 0.5,
                          marginRight: "0.3rem",
                        }}
                      >
                        <BsPersonWorkspace
                          sx={{ fontSize: "15px", color: colors.primary }}
                        />
                      </ListItemIcon>
                      <Typography
                        variant="caption"
                        sx={{
                          lineHeight: "12px",
                          fontSize: "13px",
                          textTransform: "capitalize",
                        }}
                      >
                        {i.occupation || i.profession}
                      </Typography>
                    </ListItem>
                  </List>

                  {i.phoneNumber && (
                    <AuthButton
                      style={{ fontSize: "12px" }}
                      padding="2px"
                      onClick={() => {
                        linkUnlinkAction("LinkStudent", i.phoneNumber);
                        setQueryPhone(i.phoneNumber);
                      }}
                      width="100%"
                      disabled={formLoading && i.phoneNumber == queryPhone}
                    >
                      {formLoading && i.phoneNumber == queryPhone ? (
                        <FormLoader />
                      ) : (
                        <div>
                          <AiOutlineLink />
                          <span>Link</span>
                        </div>
                      )}
                    </AuthButton>
                  )}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </SchoolTray>
    </>
  );
  if (pageLoading) {
    return <Loader />;
  }
  return (
    <>
      <GlobalStyles />
      {showModal && (
        <Modal content={content} size={"large"} onClose={setShowModal} />
      )}
      <ThemeProvider theme={theme}>
        <Box
          borderBottom={`2px solid ${colors.primary}`}
          paddingBottom={"3px"}
          marginBottom={"24px"}
        >
          <SmallHeader
            style={{
              fontWeight: "bold",
              color: colors.lightGray,
              marginBottom: "1.9rem",
            }}
          >
            {/* <span style={{ marginRight: "0.9rem" }}>MySchool {">>"}</span> */}
            {/* <span style={{ marginRight: "0.9rem" }}>School</span> */}
            {name.length > 0 && (
              <>
                <span style={{ marginRight: "0.9rem" }}>
                  Student Info- {name} {">>"}
                </span>
                <span style={{ color: "black" }}>Parents</span>
              </>
            )}
          </SmallHeader>
        </Box>
        <Box mt={5} px={1}>
          <Grid
            container
            spacing={2}
            sx={{ minHeight: "300px", justifyContent: "center" }}
          >
            {parents !== null &&
              parents.length > 0 &&
              parents.map((i, index) => (
                <Grid
                  item
                  lg={3}
                  md={4}
                  xs={4}
                  key={index}
                  sx={{ marginRight: "3px" }}
                >
                  <Box
                    sx={{
                      border: "1px solid #671e78 ",
                      borderRadius: "20px",
                      p: 0.7,
                      pb: 2,
                      minHeight: "300px",
                      minWidth: "fit-content",
                    }}
                  >
                    <Avatar
                      // src={i.profileImagePath ? i.profileImagePath : stu1}
                      variant="rounded"
                      sx={{
                        width: "100%",
                        height: "130px",
                        objectFit: "contain",
                        borderRadius: "15px",
                      }}
                    >
                      <img
                        src={i.profileImagePath ? i.profileImagePath : stu1}
                        alt="logo"
                        style={{
                          width: "100%",
                          height: "130px",
                          objectFit: "fill",
                          borderRadius: "15px",
                        }}
                      />
                    </Avatar>
                    <Box mt={1}>
                      <Typography
                        variant="h6"
                        sx={{
                          textAlign: "center",
                          fontFamily: "Gilroy-Bold",
                          fontSize: "15px",
                          color: colors.primary,
                        }}
                      >
                        {`${i.title} ${" "} ${i.fullName}`}
                      </Typography>

                      <List>
                        <ListItem sx={{ px: 0, py: 0.5 }}>
                          <ListItemIcon
                            sx={{ color: colors.primary, minWidth: 0, mr: 0.5 }}
                          >
                            <AiOutlineMail sx={{ fontSize: "15px" }} />
                          </ListItemIcon>
                          <Typography
                            variant="caption"
                            sx={{ lineHeight: "12px", fontSize: "13px" }}
                          >
                            {i.email}
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ px: 0, py: 0.5 }}>
                          <ListItemIcon
                            sx={{ color: colors.primary, minWidth: 0, mr: 0.5 }}
                          >
                            <BsFillTelephoneFill
                              sx={{ fontSize: "15px", color: colors.primary }}
                            />
                          </ListItemIcon>
                          <Typography
                            variant="caption"
                            sx={{ lineHeight: "12px", fontSize: "13px" }}
                          >
                            {i.phoneNumber}
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ px: 0, py: 0.5 }}>
                          <ListItemIcon
                            sx={{
                              color: colors.primary,
                              minWidth: 0,
                              mr: 0.5,
                              marginRight: "0.3rem",
                            }}
                          >
                            <BsPersonWorkspace
                              sx={{ fontSize: "15px", color: colors.primary }}
                            />
                          </ListItemIcon>
                          <Typography
                            variant="caption"
                            sx={{
                              lineHeight: "12px",
                              fontSize: "13px",
                              textTransform: "capitalize",
                            }}
                          >
                            {i.occupation || i.profession}
                          </Typography>
                        </ListItem>
                      </List>

                      {i.phoneNumber && (
                        <AuthButton
                          style={{ fontSize: "12px" }}
                          padding="2px"
                          onClick={() => {
                            linkUnlinkAction("UnlinkStudent", i.phoneNumber);
                            setQueryPhone(i.phoneNumber);
                          }}
                          width="100%"
                          disabled={formLoading && i.phoneNumber == queryPhone}
                        >
                          {formLoading && i.phoneNumber == queryPhone ? (
                            <FormLoader />
                          ) : (
                            <div>
                              <AiOutlineLink />
                              <span>Unlink</span>
                            </div>
                          )}
                        </AuthButton>
                      )}
                    </Box>
                  </Box>
                </Grid>
              ))}
            <Grid item lg={3} sx={{ marginLeft: "0.3rem" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Button
                  sx={{ textTransform: "initial", color: colors.primary }}
                  onClick={() => {
                    setList([]);
                    setShowModal(!showModal);
                  }}
                >
                  <AiOutlinePlusCircle />
                  Add A Parent
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Parents;
