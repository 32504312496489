import React, { useCallback, useEffect, useState } from "react";
import { BsFillCalendar2WeekFill } from "react-icons/bs";
import colors from "../../assets/colors.json";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Calendar,
  WeekDays,
  WeekLabel,
  Days,
  EmptyDay,
  Day,
} from "./attcalendar.style";
import { SmallText } from "../../pages/auth/auth.style";
import Pagination from "../pagination_one/pagination";
import { useSelector } from "react-redux";
const AttCalendar = ({ dataObj, session, classObj }) => {
  const { user } = useSelector((state) => state.auth);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [reArr, setReArr] = useState([]);
  const [reObj, setReObj] = useState(null);
  const [days, setDays] = useState([]);

  const generateDays = useCallback(
    (i) => {
      let obj = reArr[i - 1];
      setReObj(Object.assign({}, obj));
      const month = obj.month - 1;
      const year = obj.year;
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      const firstDayOfMonth = new Date(year, month, 1).getDay();
      const days = [];

      for (let i = 0; i < firstDayOfMonth; i++) {
        days.push(<EmptyDay key={`empty=${i}`} />);
      }
      for (let i = 1; i <= daysInMonth; i++) {
        const marked = obj.data.find(
          (item) => i === Number(moment(item.attendanceDate).format("D"))
        );
        days.push(
          <Day key={i} style={{ position: "relative", textAlign: "left" }}>
            <div
              style={{
                position: "absolute",
                width: "50%",
                top: 0,
                bottom: 0,
                backgroundColor:
                  marked && marked.morningMarkValue === 0
                    ? "red"
                    : marked && marked.morningMarkValue === 1
                    ? "green"
                    : "",

                left: 0,
                zIndex: 0,
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                width: "50%",
                top: 0,
                bottom: 0,
                backgroundColor:
                  marked && marked.afternoonMarkValue === 0
                    ? "red"
                    : marked && marked.afternoonMarkValue === 1
                    ? "green"
                    : "",
                right: 0,
                zIndex: 0,
              }}
            ></div>

            <div
              style={{
                color:
                  marked &&
                  (marked.morningMarkValue === 0 ||
                    marked.morningMarkValue === 1)
                    ? "white"
                    : "black",
                padding: "10px",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              {i}
            </div>
          </Day>
        );
      }
      setDays(days);
    },
    [reArr]
  );

  const func = useCallback((arr) => {
    let groupedMap = arr.reduce((acc, obj) => {
      let key = `${obj.attendanceYear}-${obj.attendanceMonth}`;
      acc[key] = acc[key] || {
        year: obj.attendanceYear,
        month: obj.attendanceMonth,
        data: [],
      };
      acc[key].data.push(obj);
      return acc;
    }, {});

    let result = Object.values(groupedMap);
    console.log(result);
    if (result.length > 0) {
      setPageCount(result.length);
      setReArr([...result]);
      // generateDays(0);
    }
  }, []);

  useEffect(() => {
    if (reArr.length > 0) {
      console.log("Working");
      console.log(currentPage);
      generateDays(currentPage);
    }
  }, [reArr, currentPage, generateDays]);

  useEffect(() => {
    if (dataObj?.records.length > 0) {
      func(dataObj.records);
    }
  }, [dataObj.records, func]);

  return (
    <>
      {reObj !== null && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: colors.primary,
              padding: "0.5rem",
              paddingTop: "1.9rem",
              borderTopLeftRadius: "inherit",
              borderTopRightRadius: "inherit",
            }}
          >
            <div>
              <SmallText color="white" style={{ fontSize: "18px" }}>
                Class Attendance
              </SmallText>
              <SmallText color="white">{session.name} Session</SmallText>
              {/* <SmallText color="white">
                15th, September 2022- 15th, October 2023
              </SmallText> */}
            </div>
            <div style={{ textAlign: "right" }}>
              <SmallText color="white">Class: {classObj.name}</SmallText>
              <SmallText color="white">
                Student's Name: {dataObj.studentName}
              </SmallText>{" "}
              <SmallText color="white">Teacher's Name:{user.name}</SmallText>
            </div>
          </div>
          <div style={{ padding: "0.8rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0.5rem 0.9rem",
              }}
            >
              <SmallText style={{ fontSize: "16px" }}>
                {moment()
                  .month(Number(reObj.month) - 1)
                  .format("MMMM")}
              </SmallText>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  backgroundColor: colors.lightGray,
                  color: colors.gray,
                  fontSize: "10px",
                  padding: "0.3rem",
                  borderRadius: "10px",
                }}
              >
                <span style={{ marginRight: "0.4rem" }}>
                  {" "}
                  {moment()
                    .month(Number(reObj.month) - 1)
                    .format("MMM")}
                  , {reObj.year}
                </span>
                <BsFillCalendar2WeekFill />
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Calendar>
                <WeekDays>
                  <WeekLabel> Sun</WeekLabel>
                  <WeekLabel> Mon</WeekLabel>
                  <WeekLabel> Tue</WeekLabel>
                  <WeekLabel> Wed</WeekLabel>
                  <WeekLabel> Thu</WeekLabel>
                  <WeekLabel> Fri</WeekLabel>
                  <WeekLabel> Sat</WeekLabel>
                </WeekDays>
                <Days>{days}</Days>
              </Calendar>
            </div>
          </div>
          {pageCount > 1 && (
            <div
              style={{
                marginTop: "2px",
                marginBottom: "2px",
              }}
            >
              <Pagination
                pageCount={pageCount > 1 && pageCount}
                currentPage={currentPage - 1}
                range={3}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

AttCalendar.propTypes = {
  dataObj: PropTypes.object.isRequired,
};
export default AttCalendar;
