import React, { useEffect, useRef } from "react";
import {
  Container,
  InlineFormDiv,
  ControlPanelDiv,
  ControlPanelTab,
} from "./styles/dashboard.style";
import { checkAccess } from "../../services/utils";
import { useSelector } from "react-redux";
import GlobalStyles from "../../assets/globalStyle";
import LogoImg from "../../assets/school-logo.png";
import colors from "../../assets/colors.json";
import { useMediaQuery } from "react-responsive";
import { InputWrapper } from "../auth/Login.style";
import { CiSearch } from "react-icons/ci";
import { Link } from "react-router-dom";
import { TbSchoolOff } from "react-icons/tb";
// import { FaUserCog, FaSchool } from "react-icons/fa";
// import { GrUserSettings } from "react-icons/gr";
import { AiOutlineUser } from "react-icons/ai";
import { SlGraduation } from "react-icons/sl";
// import { BsChatDots } from "react-icons/bs";
import { MdSmartphone } from "react-icons/md";
import Title from "../../components/Dashboard/Title";
const ControlPanel = () => {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const myRef = useRef();
  useEffect(() => {
    const element = myRef.current;
    const extraOffset = 0; // Adjust this value to your preference
    window.scrollTo({
      top: element.offsetTop - extraOffset,
      behavior: "smooth",
    });
  }, []);
  const { accessObj } = useSelector((state) => state.auth);
  return (
    <Container ref={myRef}>
      <GlobalStyles />
      <div>
        <Title title="Control Panel" />
      </div>

      <InlineFormDiv>
        <div style={{ width: isMobile ? "100%" : "70%" }}>
          <InputWrapper style={{ position: "relative", padding: "0px" }}>
            <CiSearch
              style={{
                position: "absolute",
                left: "0.5rem",
                top: "64%",
                fontSize: "27px",
                color: colors.gray,
                transform: "translateY(-50%)",
              }}
            />
            <input type="text" placeholder="" />
          </InputWrapper>
        </div>
      </InlineFormDiv>

      <ControlPanelDiv>
        {checkAccess(accessObj.menuItems, "Webpage Setup") && (
          <ControlPanelTab>
            <Link to="/lasmab-setup">
              <img src={LogoImg} alt="" />
              <span>Lasmab Setup</span>
            </Link>
          </ControlPanelTab>
        )}
        {checkAccess(accessObj.menuItems, "Academic Setup") && (
          <ControlPanelTab>
            <Link to="/academic-setup">
              <SlGraduation color={colors.primary} />
              <span>Academic Setup</span>
            </Link>
          </ControlPanelTab>
        )}
        {checkAccess(accessObj.menuItems, "Non-Academic Setup") && (
          <ControlPanelTab>
            <Link to="/non-academic-setup">
              <TbSchoolOff />
              <span>Non-Academic Setup</span>
            </Link>
          </ControlPanelTab>
        )}
        {/* >{" "}
        <ControlPanelTab>
          <Link>
            <SlGraduation />
            <span>Non-Academic Setup</span>
          </Link>
        </ControlPanelTab>{" "}
        <ControlPanelTab>
          <Link>
            <GrUserSettings />
            <span>HR Setup</span>
          </Link>
        </ControlPanelTab>{" "} */}
        {checkAccess(accessObj.menuItems, "Users Setup") && (
          <ControlPanelTab>
            <Link to="/users-setup">
              <AiOutlineUser color={colors.primary} />
              <span>Users Setup</span>
            </Link>
          </ControlPanelTab>
        )}
        {checkAccess(accessObj.menuItems, "User Guide Setup") && (
          <ControlPanelTab>
            <Link to="/user-guide-setup">
              <MdSmartphone color={colors.primary} />
              <span>User Guide Setup</span>
            </Link>
          </ControlPanelTab>
        )}

        {/* {" "}
        <ControlPanelTab>
          <Link>
            <GrUserSettings />
            <span>Admin Setup</span>
          </Link>
        </ControlPanelTab>{" "}
        <ControlPanelTab>
          <Link>
            <BsChatDots />
            <span>Chat Setup</span>
          </Link>
        </ControlPanelTab>{" "} */}
      </ControlPanelDiv>
    </Container>
  );
};

export default ControlPanel;
