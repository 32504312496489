import React, { useState, useEffect, useCallback } from "react";
import {
  PageWrapper,
  InlineLabelGroup,
  InlineLabelWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
import { InputWrapper } from "../../pages/auth/auth.style";
import { useLocation } from "react-router-dom";
import colors from "../../assets/colors.json";
import Loader from "../pageLoader/loader";
import DatePickerComponent from "../datepicker/DatePicker";
import { toast } from "react-toastify";
import { connect, useSelector } from "react-redux";
// import { getStudentMedicalInfo } from "../../actions/user";
import { GetStudentMedicalInfo } from "../../services/ParentStudent";
const HealthRecordImmuneHistory = ({ studentId }) => {
  const [bioData, setBioData] = useState(null);
  const location = useLocation();
  const schoolId = new URLSearchParams(location.search).get("school_id") || "";
  const { admToken } = useSelector((state) => state.auth);
  const [dob, setDob] = useState(new Date(2014, 0, 3));
  // const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  // const token = localStorage.lasmabAuthToken || "";
  const [loading, setLoading] = useState(true);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      let res = await GetStudentMedicalInfo(
        admToken,
        studentId,
        schoolId,
        "immunization-history"
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setBioData(res.data);
        setLoading(false);
      } else {
        toast.error(res.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error("An Error Occured! Try Again Later");
      setLoading(false);
      console.log(error);
    }
  }, [admToken, schoolId, studentId]);

  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : bioData ? (
        <PageWrapper>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              <InputWrapper>
                <label htmlFor="">BCG</label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={2}>
              {" "}
              <InputWrapper>
                <label htmlFor="">Status</label>
                <input
                  type="text"
                  value={bioData.bcgStatus}
                  placeholder="Status"
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={2}>
              <InputWrapper>
                <label htmlFor="">Date</label>
                <DatePickerComponent
                  selected={new Date(bioData.bcgDate)}
                  setSelected={(val) => {
                    setDob(val);
                  }}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              <InputWrapper>
                <label htmlFor="">DPT</label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={2}>
              {" "}
              <InputWrapper>
                {/* <label htmlFor="">Status</label> */}
                <input
                  type="text"
                  value={bioData.dptStatus}
                  placeholder="Status"
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={2}>
              <InputWrapper>
                {/* <label htmlFor="">Date</label> */}
                <DatePickerComponent
                  selected={new Date(bioData.dptDate)}
                  setSelected={(val) => {
                    setDob(val);
                  }}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              <InputWrapper>
                <label htmlFor="">Oral Polio</label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={2}>
              {" "}
              <InputWrapper>
                {/* <label htmlFor="">Status</label> */}
                <input
                  type="text"
                  value={bioData.oralPolioStatus}
                  placeholder="Status"
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={2}>
              <InputWrapper>
                {/* <label htmlFor="">Date</label> */}
                <DatePickerComponent
                  selected={new Date(bioData.oralPolioDate)}
                  setSelected={(val) => {
                    setDob(val);
                  }}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              <InputWrapper>
                <label htmlFor="">Yellow Fever</label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={2}>
              {" "}
              <InputWrapper>
                <label htmlFor="">Status</label>
                <input
                  type="text"
                  value={bioData.yellowFeverStatus}
                  placeholder="Status"
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={2}>
              <InputWrapper>
                <label htmlFor="">Date</label>
                <DatePickerComponent
                  selected={new Date(bioData.yellowFeverDate)}
                  setSelected={(val) => {
                    setDob(val);
                  }}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </PageWrapper>
      ) : (
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            color: colors.primary,
          }}
        >
          No Immunization Medical Data For Student FOund
        </div>
      )}
    </>
  );
};

export default HealthRecordImmuneHistory;
