import React, { useState } from "react";
import img from "../../assets/Images/avatar.jpg";
import {
  Box,
  Avatar,
  Typography,
  Chip,
  Drawer,
  ListItemIcon,
  List,
  IconButton,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useSearchParams } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const NavData = [
  {
    label: "Admission Form",
    id: 1,
    // link: "/parentmanagement/admission/dashboard/admissionform",
    // selected: true,
  },
  {
    label: "Examination Slip",
    id: 2,
    // link: "/parentmanagement/admission/dashboard/examinationslip",
    // selected: false,
  },
  {
    label: "Report",
    id: 3,
    // link: "/parentmanagement/admission/dashboard/report",
    // selected: false,
  },
];

let Body = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
    secondary: {
      main: "#edf2ff",
    },
  },
  typography: {
    fontFamily: "Gilroy-Regular",
    h1: {
      fontFamily: "Gilroy-Bold",
      fontSize: "52px",
    },
  },
});

const AdDrawer = () => {
  const theme = useTheme();
  const location = useLocation();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const tab_id = Number(new URLSearchParams(location.search).get("tab")) || 1;
  const page_id = Number(new URLSearchParams(location.search).get("page")) || 1;
  const adm_id = new URLSearchParams(location.search).get("admission_id");

  const [searchParams, setSearchParams] = useSearchParams();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <ThemeProvider theme={Body}>
        <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
          <List
            sx={{
              background: "#5e9c09",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Link to="/">
          <Avatar
          src={logo}
          sx={{ bgcolor: "white", width: "85px", height: "85px", mb: 5 }}
        />
          </Link> */}
            <Box
              sx={{
                width: "301px",
                bgcolor: "#5e9c09",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                pt: 5,
                height: "100vh",
              }}
            >
              <Box
                mt={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar src={img} sx={{ width: "90px", height: "90px" }} />
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ fontFamily: "Gilroy-Medium", fontWeight: "bolder" }}
                >
                  Adeolu James
                </Typography>
                <Chip
                  label="Aged:12"
                  sx={{ color: "#fff", fontSize: "10px" }}
                />
              </Box>

              <Box sx={{ width: "100%", mt: 4 }}>
                <List sx={{ pl: 3 }}>
                  {NavData.map((nav, index) => (
                    // <Link
                    //   to={nav.link}
                    //   key={id}
                    //   onClick={() => setOpenDrawer(false)}
                    // >
                    <ListItemButton
                      key={index}
                      onClick={() => {
                        setSearchParams({
                          ...searchParams,
                          page: page_id,
                          tab: nav.id,
                          admission_id: adm_id,
                          // school_id: schoolId,
                        });
                        setOpenDrawer(false);
                      }}
                      selected={tab_id === nav.id ? true : false}
                      sx={{
                        pl: 5,
                        " 	&.Mui-selected": {
                          bgcolor: "#fff",
                          borderRadius: "20px 0px 0px 20px",
                        },
                      }}
                    >
                      <ListItemIcon sx={{ color: "#fff" }}>
                        {nav.icon}
                      </ListItemIcon>
                      <ListItemText>{nav.label}</ListItemText>
                    </ListItemButton>
                    // </Link>
                  ))}
                </List>
              </Box>
              {/* <Button variant="contained">Parent Dashboard</Button> */}
              <Box sx={{ pl: 3, mt: 4 }}></Box>
            </Box>
          </List>
        </Drawer>

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            pt: 5,
            pb: 3,
            px: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "",
              alignItems: "right",
              width: "15%",
            }}
          >
            <IconButton
              sx={{ color: "#000" }}
              onClick={() => setOpenDrawer(!openDrawer)}
            >
              <MenuIcon />
            </IconButton>

            {/* <MenuIcon style={{ fontSize: "25px", cursor:'pointer' }} /> */}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default AdDrawer;
