/* eslint-disable eqeqeq */
import { Box } from "@mui/material";
import React from "react";
import { Typography, Grid, Avatar } from "@mui/material";
import { GiCancel } from "react-icons/gi";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LanguageIcon from "@mui/icons-material/Language";
import { LuFileSpreadsheet } from "react-icons/lu";
import PdfDownloader2 from "../PdfDownloader2";
import { getOrdinalSuffix } from "../../utils";

const ExcelJS = require("exceljs");

const SpreadSheetModal = ({ close, resultData, note }) => {
  function rankStudents(students) {
    // Create a deep copy of the students array to avoid modifying the original array
    let studentsCopy = JSON.parse(JSON.stringify(students));

    // Sort the copied array in descending order based on the average score
    studentsCopy.sort((a, b) => b.averageScore - a.averageScore);

    // Initialize the rank and the previous score
    let rank = 1;
    let previousScore = studentsCopy[0].averageScore;

    // Iterate through the sorted array and assign ranks
    for (let i = 0; i < studentsCopy.length; i++) {
      // If the current student's score is different from the previous one, update the rank
      if (studentsCopy[i].averageScore !== previousScore) {
        rank = i + 1; // Update the rank to the current index plus 1
        previousScore = studentsCopy[i].averageScore;
      }

      // Assign the rank to the current student
      studentsCopy[i].rank = rank;
    }

    return studentsCopy;
  }

  const rankedStudents = rankStudents(resultData?.studentSheetScores);

  const getPosition = (name) => {
    const position = rankedStudents.find(
      (student) => student.studentName === name
    );
    return getOrdinalSuffix(position.rank);
  };

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Result sheet");
    sheet.properties.defaultRowHeight = 80;
    sheet.properties.header = "";

    sheet.columns = [
      {
        header: "Name",
        key: "studentName",
        width: "35",
      },
      {
        header: "Admission No",
        key: "admissionNo",
        width: "15",
      },
      ...resultData.subjects.map((subject) => ({
        header: subject.subjectShort,
        key: subject.subjectId,
        width: "8",
      })),
      {
        header: "Total",
        key: "totalScore",
        width: "8",
      },
      {
        header: "Avg %",
        key: "averageScore",
        width: "8",
      },
      {
        header: "Position",
        key: "position",
        width: "8",
      },
    ];

    // Add rows to the sheet
    resultData.studentSheetScores.forEach((each) => {
      // Create a row object with initial data
      const row = {
        studentName: each.studentName,
        admissionNo:
          each.admissionNo === "No Admission Number Yet"
            ? "N/A"
            : each.admissionNo,
        totalScore: each.totalScore,
        averageScore: each.averageScore,
        position: getPosition(each.studentName),
      };

      // Add subject scores to the row object
      resultData.subjects.forEach((s) => {
        let indexNumber = each.subjectScores.findIndex(
          (i) => i.subjectId == s.subjectId
        );
        row[s.subjectId] =
          indexNumber != -1 ? each.subjectScores[indexNumber].examScore : "";
      });
      // Add the row to the sheet
      const addedRow = sheet.addRow(row);
      // Center align the values for each cell in the row
      addedRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Results-spread-sheet.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <Box
      sx={{
        position: "fixed",
        backgroundColor: "white",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        zIndex: 100000,
        padding: "10px 14px",
        marginBottom: "0.7rem",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "white",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          zIndex: 100001,
          padding: "10px 14px",
          marginBottom: "0.7rem",
          overflowY: "scroll",
        }}
      >
        <GiCancel
          style={{
            position: "absolute",
            left: "10px",
            top: "10px",
            cursor: "pointer",
            zIndex: 494994849,
          }}
          onClick={close}
        />
        <Box mb={10} sx={{ position: "relative" }}>
          <div className="flex items-center gap-2 justify-end">
            <button
              className="flex gap-1 items-center border p-1"
              onClick={exportExcelFile}
            >
              <LuFileSpreadsheet className="w-6 h-6" />
              Export to excel
            </button>
            <PdfDownloader2 elementId={"spreadsheet"} orientation="landscape" />
          </div>

          {/* another box */}
          <Box id="spreadsheet">
            <Box
              sx={{
                textAlign: "center",
                margin: "0 auto",
                width: { lg: "97%", md: "97%", sm: "98%", xs: "99%" },
              }}
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar src={resultData.schoolDetails.logo} />
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "Gilroy-Heavy",
                    fontSize: { xs: "14px", lg: "20px" },
                  }}
                >
                  {resultData.schoolDetails.schoolname}
                </Typography>
              </Box>
              <Box mb={2} sx={{ margin: "0 auto", width: "90%" }}>
                <Grid
                  container
                  sx={{
                    borderRadius: "20px",
                    fontFamily: "Gilroy-Regular",
                  }}
                >
                  <Grid item xs={12} md={6} sm={6} lg={6}>
                    <Box
                      sx={{
                        my: { lg: 2, xs: 1 },
                        pr: { lg: 3.5, xs: 0 },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        borderRight: {
                          lg: "1px solid #671e78",
                          xs: "none",
                          md: "1px solid #671e781a",
                          sm: "1px solid #671e781a",
                        },
                        textAlign: { lg: "right", xs: "center" },
                      }}
                    >
                      <span style={{ fontSize: "14px", marginBottom: "10px" }}>
                        {resultData.schoolDetails.schooladdress}

                        <AllInboxIcon
                          sx={{ ml: 1, mb: -0.6, fontSize: "18px" }}
                        />
                      </span>{" "}
                      <span style={{ fontSize: "14px" }}>
                        {resultData.schoolDetails.schoolemail}
                        <EmailIcon sx={{ ml: 1, mb: -0.6, fontSize: "18px" }} />
                      </span>{" "}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sm={6}
                    sx={{ textAlign: "left", my: { lg: 2, xs: -0.6 } }}
                  >
                    <Box
                      sx={{
                        textAlign: { lg: "left", xs: "center" },
                        ml: { lg: 2, xs: 0 },
                      }}
                    >
                      <span style={{ fontSize: "14px", marginBottom: "12px" }}>
                        <LocalPhoneIcon
                          sx={{ mr: 1, mb: -0.6, fontSize: "18px" }}
                        />
                        {resultData.schoolDetails.schoolphone}
                      </span>{" "}
                      <br />
                      <span style={{ fontSize: "14px" }}>
                        <LanguageIcon
                          sx={{ mr: 1, mb: -0.6, fontSize: "18px" }}
                        />
                        {resultData.schoolDetails.portal_url}
                      </span>{" "}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  bgcolor: "#671E78",
                  p: 1,
                  borderRadius: "20px",
                  pb: 2,
                  mt: 2,
                  // mb: 3,
                }}
              >
                <Typography
                  variant="body"
                  sx={{
                    color: "#fff",
                    fontFamily: "Gilroy-Medium",
                    fontSize: {
                      xs: "10px",
                      md: "16px",
                      sm: "14px",
                      lg: "16px",
                    },
                  }}
                >
                  Spread Sheet
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: "#671E78",
                  width: "40%",
                  margin: "0 auto",
                  p: 1,
                  borderRadius: "20px",
                  position: "relative",
                  top: "-8px",
                  pt: 0,
                }}
              >
                <Typography
                  variant="body"
                  sx={{
                    color: "#fff",
                    fontSize: {
                      lg: "16px",
                      md: "16px",
                      sm: "14px",
                      xs: "10px",
                    },
                    fontFamily: "Gilroy-Regular",
                  }}
                >
                  {/* {resultData.studentDetails.term} */}
                  {note}
                </Typography>
              </Box>
            </Box>

            {/* table box */}
            <Box
              mt={3}
              className="sheeet_table card_class"
              sx={{ overflowX: "scroll" }}
            >
              <table
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                  overflowX: "scroll",
                }}
              >
                <tr>
                  <th>Name</th>
                  <th>Admission No</th>

                  {resultData?.subjects.length > 0 &&
                    resultData.subjects.map((item, i) => (
                      <th
                        key={i}
                        style={{
                          textAlign: "center",
                          textTransform: "uppercase",
                          width: "78px",
                        }}
                      >
                        {item.subjectShort}
                      </th>
                    ))}
                  <th
                    style={{
                      textAlign: "center",
                      textTransform: "uppercase",
                      width: "78px",
                    }}
                  >
                    Total
                  </th>

                  <th
                    style={{
                      textAlign: "center",
                      textTransform: "uppercase",
                      width: "78px",
                    }}
                  >
                    Avg %
                  </th>

                  <th
                    style={{
                      textAlign: "center",
                      textTransform: "uppercase",
                      width: "78px",
                    }}
                  >
                    Position
                  </th>
                </tr>

                {resultData?.studentSheetScores.length > 0 &&
                  resultData?.studentSheetScores.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td
                          style={{
                            textAlign: "left",
                            width: "230px",
                            padding: "10px",
                          }}
                        >
                          {i + 1}. {item.studentName}{" "}
                        </td>
                        <td style={{ textAlign: "center", width: "150px" }}>
                          {item.admissionNo === "No Admission Number Yet"
                            ? "N/A"
                            : item.admissionNo}
                        </td>
                        {resultData?.subjects.length > 0 &&
                          resultData.subjects.map((s, index) => {
                            let indexNumber = item.subjectScores.findIndex(
                              (i) => i.subjectId == s.subjectId
                            );
                            return (
                              <td key={index} style={{ textAlign: "center" }}>
                                {indexNumber != -1
                                  ? item.subjectScores[indexNumber].examScore
                                  : ""}{" "}
                              </td>
                            );
                          })}
                        <td style={{ textAlign: "center" }}>
                          {item.totalScore}
                        </td>{" "}
                        {/* <td> {"   "}</td> */}
                        <td style={{ textAlign: "center" }}>
                          {item.averageScore}
                        </td>
                        <td>{getPosition(item.studentName)}</td>
                      </tr>
                    );
                  })}

                {/* <tr>
                <td style={{ textAlign: "center", fontWeight: "bold" }}>
                  Total
                </td>
                <td></td>

                {resultData?.subjects.length > 0 &&
                  resultData.subjects.map((item, i) => (
                    <td
                      key={i}
                      style={{
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      {item.grandTotal}
                    </td>
                  ))}
              </tr> */}
              </table>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* SpreadSheetModal */}
    </Box>
  );
};

export default SpreadSheetModal;
