import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Drawer,
  IconButton,
  List,
  Button,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/system";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { BsSearch } from "react-icons/bs";
import logo from "../../assets/school-logo.png";
import { NavData } from "../../assets/data/NavData";
import ChangePin from "./ChangePin";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch } from "react-redux";
import { logout } from "../../features/auth/authSlice";
import { checkAccess } from "../../services/utils";
import UserGuideButton from "../ControlPanel/UserGuide/UserGuideButton";
let Body = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
    secondary: {
      main: "#edf2ff",
    },
  },
  typography: {
    fontFamily: "Gilroy-Regular",
    h1: {
      fontFamily: "Gilroy-Bold",
      fontSize: "52px",
    },
  },
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));
// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("md")]: {
//       width: "20ch",
//     },
//   },
// }));
const Drawers = () => {
  const dispatch = useDispatch();
  const router = useLocation();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { accessObj } = useSelector((state) => state.auth);

  return (
    <>
      <ThemeProvider theme={Body}>
        <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
          <List
            className="scrollbar"
            sx={{
              background: "#691e78",
              // background: "red",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
              justifyContent: "start",
            }}
          >
            {/* <Link to="/">
          <Avatar
          src={logo}
          sx={{ bgcolor: "white", width: "85px", height: "85px", mb: 5 }}
        />
          </Link> */}
            <Box
              sx={{
                width: "301px",
                bgcolor: "#671E78",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                pt: 5,
              }}
            >
              <Avatar
                src={logo}
                sx={{ bgcolor: "white", width: "85px", height: "85px", mb: 5 }}
              />
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#fff",
                    color: "#671e78",
                    fontFamily: "gilroy-semibold",
                    textTransform: "inherit",
                    width: "200px",
                    display: "none",
                    "&:hover": {
                      color: "#fff",
                    },
                  }}
                >
                  Switch to User
                </Button>
              </Box>
              <Box sx={{ width: "100%", mt: 4 }}>
                <Typography sx={{ ml: 6, color: "#fff" }} variant="caption">
                  MENU
                </Typography>

                <List>
                  {NavData.map(
                    (nav, id) =>
                      (checkAccess(accessObj.menuItems, nav.label) ||
                        checkAccess(accessObj.menuItems, nav.name)) && (
                        <Link
                          to={nav.link}
                          key={id}
                          onClick={() => setOpenDrawer(false)}
                        >
                          <ListItemButton
                            selected={
                              router.pathname === nav.link ||
                              router.pathname.includes(nav.altPath)
                            }
                            sx={{
                              color: "white",
                              pl: 5,
                              "&:hover, 	&.Mui-selected": {
                                bgcolor: "rgba(217, 217, 217, 0.1)",
                                borderLeft: "4px solid #fff",
                              },
                            }}
                          >
                            <ListItemIcon sx={{ color: "#fff" }}>
                              {nav.icon}
                            </ListItemIcon>
                            <ListItemText>{nav.label}</ListItemText>
                          </ListItemButton>
                        </Link>
                      )
                  )}
                </List>
              </Box>
              {/* <Button variant="contained">Parent Dashboard</Button> */}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  paddingLeft: "25px",
                }}
              >
                <ChangePin color="white" />
              </Box>
              <Box sx={{ pl: 3, mt: 4 }}>
                <Button
                  variant=""
                  sx={{ color: "#fff", fontFamily: "Gilroy-regular" }}
                  onClick={() => {
                    dispatch(logout());
                    navigate("/");
                  }}
                >
                  <LogoutIcon sx={{ mr: 2 }} /> Logout
                </Button>
              </Box>
            </Box>
          </List>
        </Drawer>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pt: 5,
            pb: 3,
            px: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              columnGap: 3,
            }}
          >
            <Avatar
              sx={{
                "&:hover": { transform: "scale(2)" },
                transition: "0.2s all linear",
              }}
              src="https://res.cloudinary.com/dgkcytiv6/image/upload/v1693005681/qkgkhvblmekt5uxj4w30_eucaxm.jpg"
            />
            <UserGuideButton />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // width: "15%",
              columnGap: 3,
            }}
          >
            <Badge badgeContent={0} color="error">
              <NotificationsNoneIcon />
            </Badge>
            <IconButton
              sx={{ color: "#000" }}
              onClick={() => setOpenDrawer(!openDrawer)}
            >
              <MenuIcon />
            </IconButton>

            {/* <MenuIcon style={{ fontSize: "25px", cursor:'pointer' }} /> */}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Drawers;
