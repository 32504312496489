import styled from "styled-components";
import colors from "../../assets/colors.json";

export const Calendar = styled.div`
  width: 85%;

  @media (max-width: 768px) {
    width: 95%;
  }
`;

export const WeekDays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

export const WeekLabel = styled.div`
  font-weight: 700;
  text-align: center;
`;

export const Days = styled.div`
  background-color: rgba(238, 224, 241, 0.6);
  /* background-color: ${colors.primary}; */
  overflow: hidden;
  border-radius: 20px;
  margin-top: 0.9rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

export const EmptyDay = styled.div`
  min-height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-color: white;
  font-weight: 700;
  font-size: 17px;
`;

export const Day = styled(EmptyDay)`
  color: ${(props) => props.color || "transparent"} !important;
`;
