import React, { useState } from "react";
import { AuthButton, BigText, SmallText } from "../auth/Login.style";
import { GoBackDiv } from "../student_mgt/stu_mgt.style";
import { BsArrowLeftShort } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import { BigHeader } from "../Dashboard/styles/dashboard.style";
import StaffList from "../../components/HR/StaffList";
import StaffForm from "../../components/HR/StaffForm";

const StaffManagement = () => {
  const [showForm, setShowForm] = useState(true);
  const isTab = useMediaQuery({ maxWidth: 992 });

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: !showForm ? "end" : "space-between",
        }}
      >
        {!showForm ? (
          <AuthButton
            mt="0.6rem"
            pl="0px"
            width={isTab ? "50%" : "30%"}
            onClick={() => setShowForm(!showForm)}
          >
            <div>
              <span>Add new Staff</span>
            </div>
          </AuthButton>
        ) : (
          <>
            <BigHeader>Create Staff Profile</BigHeader>
            <GoBackDiv
              onClick={() => setShowForm(!showForm)}
              style={{
                cursor: "pointer",
              }}
            >
              <div>
                <BsArrowLeftShort />
              </div>
              Back to Staff List
            </GoBackDiv>
          </>
        )}
      </div>
      {!showForm ? <StaffList /> : <StaffForm />}
    </div>
  );
};

export default StaffManagement;
