import { useCallback, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import colors from "../../../assets/colors.json";
import GlobalStyles from "../../../assets/globalStyle";
import Modal from "../../modal/Modal";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  // BigHeader,
  MenuDiv,
  SmallHeader,
} from "../../../pages/Dashboard/styles/dashboard.style";
import { InputWrapper, SmallText } from "../../../pages/auth/Login.style";
import api from "../../../services/ControlPanel";
const UserGuideButton = ({ showIcon }) => {
  const [query, setQuery] = useState("");
  const { admToken } = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [list, setList] = useState([]);
  const [arr, setArr] = useState([]);
  let _obj = { id: "", data: [] };
  const [obj, setObj] = useState(_obj);
  const getUserGuides = useCallback(async () => {
    try {
      const res = await api.getUserGuides(admToken, 3);
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setArr(res.data);
        setList(res.data);
        // if (res.data.length > 0) {
        //   setShowModal(true);
        // }
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [admToken]);

  const getLink = useCallback(
    async (id) => {
      try {
        const res = await api.getUserGuideLink(admToken, 3, id);
        console.log(res);
        if (res.status && res.statusCode === 2) {
          if (Array.isArray(res.data) && res.data.length > 0) {
            setObj({ ...obj, id, data: res.data });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [admToken, obj]
  );
  const searchFilter = (e) => {
    let filterArr = [];
    const queryRegex = new RegExp(e.target.value, "i");
    arr.forEach((item) => {
      Object.values(item).some((val) =>
        queryRegex.test(val) ? filterArr.push(item) : ""
      );
    });

    setList(filterArr);
  };

  useEffect(() => {
    console.log("once");
    getUserGuides();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const content = (
    <>
      <SmallHeader
        style={{
          textAlign: "center",
          fontWeight: "bold",
          background: colors.primary,
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          padding: "1.4rem",
          color: "white",
        }}
      >
        User Guides
      </SmallHeader>
      <div style={{ padding: "0.8rem" }}>
        <InputWrapper>
          <input
            type="text"
            value={query}
            placeholder="Search Topic"
            onChange={(e) => {
              setQuery(e.target.value);
              if (e.target.value.length > 2) {
                searchFilter(e);
              }

              if (e.target.value === "") {
                setList(arr);
                console.log("hellow world inside jsx");
              }
            }}
          />
        </InputWrapper>
        <MenuDiv style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
          {list.map((item, index) => (
            <div key={index}>
              <div
                style={{
                  backgroundColor: colors.secondary,
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: "10px",
                  margin: "0.4rem 0rem",
                  alignItems: "center",
                  padding: "0.5rem 0.8rem",
                }}
              >
                <SmallText style={{ fontWeight: "bold" }}>
                  {item.topic}
                </SmallText>
                {obj && obj.id === item.qid && obj.data.length > 0 ? (
                  <button
                    onClick={() => setObj(Object.assign({}, _obj))}
                    style={{
                      borderRadius: "15px",
                      color: colors.primary,
                      border: `1px solid ${colors.primary}`,
                      backgroundColor: "transparent",
                      padding: "0.3rem 0.9rem",
                      fontWeight: "bold",
                    }}
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    onClick={() => getLink(item.qid)}
                    style={{
                      borderRadius: "15px",
                      color: colors.primary,
                      border: `1px solid ${colors.primary}`,
                      backgroundColor: "transparent",
                      padding: "0.3rem 0.9rem",
                      fontWeight: "bold",
                    }}
                  >
                    View
                  </button>
                )}
              </div>
              {obj &&
                obj.id === item.qid &&
                obj.data.length > 0 &&
                obj.data.map((i, ind) => (
                  <div
                    key={ind}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "0.3rem",
                      padding: "0rem 0.9rem",
                    }}
                  >
                    <SmallText>Step {i.steps}</SmallText>
                    {/* <div> */}
                    <a
                      href={i.link}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        borderRadius: "10px",
                        color: colors.primary,
                        border: `1px solid ${colors.primary}`,
                        backgroundColor: "transparent",
                        padding: "0.3rem 0.9rem",
                        fontWeight: "bold",
                      }}
                    >
                      Doc Link
                    </a>
                    {/* </div> */}
                  </div>
                ))}
            </div>
          ))}
        </MenuDiv>
      </div>
    </>
  );

  return (
    <>
      <GlobalStyles />
      {showModal && (
        <Modal
          content={content}
          size={"large"}
          onClose={setShowModal}
          pd={"0px"}
          iColor={"white"}
        />
      )}

      <IconButton
        // onClick={getUserGuides}
        onClick={() => setShowModal(true)}
      >
        <div
          style={{
            padding: "8px",
            background: "#671E781a",
            borderRadius: "50%",
          }}
        >
          <div
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#671E78",
            }}
          >
            <h1
              style={{
                color: "#ffffff",
                fontSize: "18px",
              }}
            >
              !
            </h1>
          </div>
        </div>
      </IconButton>
    </>
  );
};

UserGuideButton.propTypes = {
  showIcon: PropTypes.bool,
};

UserGuideButton.defaultProps = {
  showIcon: true,
};

export default UserGuideButton;
