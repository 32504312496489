import { Box } from "@mui/system";
import React, { useState } from "react";
import CPNavbar from "../../components/CPLayouts/CPNavBar";
import { DashboardHeader } from "../../components/Dashboard";
import { UserGuideData } from "../../assets/data/ControlPanelData";

import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkAccess } from "../../services/utils";
import UserGuidePage from "../../components/ControlPanel/UserGuide/UserGuidePage";
const UserGuideSetup = () => {
  const { accessObj } = useSelector((state) => state.auth);
  const location = useLocation();
  const page_id = Number(new URLSearchParams(location.search).get("page")) || 0;
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CPNavbar
        controlNav={() => {
          if (open === true) {
            setOpen(false);
          }
        }}
        isOpen={open}
        title=""
        navData={UserGuideData}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { lg: "90%", md: "90%", sm: "100%", xs: "100%" },
        }}
      >
        <DashboardHeader title="" navData={UserGuideData} />
        <Box mt={14} sx={{ mx: "auto", width: isMobile ? "95%" : "85%" }}>
          {checkAccess(accessObj.menuItems, "User Guide Setup") &&
            page_id === 1 && <UserGuidePage />}
        </Box>
      </Box>
    </Box>
  );
};

export default UserGuideSetup;
