export default function Upload({ fill, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{
        fill: fill ? fill : "none",
        stroke: color ? color : "currentcolor",
        strokeWidth: "2px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      className="feather feather-upload-cloud"
    >
      <polyline points="16 16 12 12 8 16"></polyline>
      <line x1="12" y1="12" x2="12" y2="21"></line>
      <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
      <polyline points="16 16 12 12 8 16"></polyline>
    </svg>
  );
}
