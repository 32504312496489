import React, { useState } from "react";
import {
  TabContainer,
  TabContent,
  TabLink,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import StudentBioData from "../StudentMgt/StudentBioData";
import SocialData from "../StudentMgt/SocialData";
import Health from "../StudentMgt/Health";
import Attachment from "../StudentMgt/Attachment";
const AdmissionForm = () => {
  const { admToken } = useSelector((state) => state.auth);
  const [formLoading, setFormLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const location = useLocation();
  const schoolId = new URLSearchParams(location.search).get("school_id") || "1";
  const [tab, setTab] = useState(1);

  const [attachObj, setAttachObj] = useState({
    // studentId: 0,
    admissionId: 0,
    schoolId: Number(schoolId),
    fileName: "string",
    fileContentPath: "",
    fileContent: "",
  });
  const [socialObj, setSocialObj] = useState({
    // studentId: 0,
    schoolId: schoolId,
    childPosition_In_Family: "string",
    childPosition_On_Mother: "string",
    childAmbition: "string",
    childEasyThings: "string",
    isChildBedWet: true,
    note_On_Child: "string",
    isChild_Away_FromHome_Before: true,
    parentPhoneNumber: "string",
  });
  const [bioObj, setBioObj] = useState({
    studentId: 0,
    schoolName: "string",
    admissionId: 0,
    phoneNumber: "07053781229",
    email: "strgone@gmail.com",
    profileImagePath: "",
    schoolId: schoolId,
    firstName: "Kane",
    lastName: "Harry",
    otherName: "Rooney",
    admission_no: "string",
    residentialeAddress: "string",
    origin: "Lagos",
    studentAge: 0,
    classId: 0,
    gender: "MALE",
    dateOfBirth: "",
    height: "6'4ft",
    weight: "80kg",
    hometown: "Tokyo",
    nationality: "Nigeria",
    localGovernment: "Nagaki",
    nearestBustop: "Osaka",
    religion: "Islam",
    parentPhoneNumber: "08133456789",
    parentEmail: "str3@gmail.com",
    childOrWardWorshipRole: "Role One",
    vicorOrPastorName: "Mr Vicar Vicar",
    vicorOrPastorPhoneNo: "",
    eyeColor: "Yellow",
    denomination: "Anglican",
    denominationAddress: "RCCG Camp Ota",
    // accountStatus: "string",
    // createdBy: "string",
  });

  const [healthObj, setHealthObj] = useState({
    schoolId: Number(schoolId),
    // studentId: 0,
    isSickleCell: false,
    hasEyeDisease: false,
    hasEpilespy: false,
    hasEarDisease: false,
    hasDiabetics: false,
    hasNoseDisease: false,
    hasTuberculosis: false,
    hasRespiratoryDisease: false,
    hasHeartDisease: false,
    hasKidneyDisease: false,
    hasMentalOrNervousDisease: false,
    hasAlergies: false,
    hasDigestiveDisease: false,
    isChild_HandCap: false,
    isChildAdmitted_To_Hospital: false,
    isChildOperatedBefore: false,
    operationDetails: "string",
    isChildOnMedication: false,
    familyAilment: "string",
    ailmentStatus: "string",
    bloodGroup: "string",
    medicalAdvise: "string",
    genotype: "string",
    childAilment: "string",
    childAllergicReaction: "string",
    bcgStatus: "string",
    bcgDate: "2023-05-15T13:48:50.100Z",
    dptStatus: "string",
    dptDate: "2023-05-15T13:48:50.100Z",
    oralPolioStatus: "string",
    oralPolioDate: "2023-05-15T13:48:50.100Z",
    yellowFeverStatus: "string",
    yellowFeverDate: "2023-05-15T13:48:50.100Z",
    // parentPhoneNumber: "string",
    // parentSignatureDate: "string",
  });
  return (
    <>
      {" "}
      <>
        <TabContainer>
          <TabLinkContainer>
            <TabLink isActive={tab === 1} onClick={() => setTab(1)}>
              <p>Student Bio-data</p>
            </TabLink>
            <TabLink isActive={tab === 2} onClick={() => setTab(2)}>
              <p>Social Data</p>
            </TabLink>
            <TabLink isActive={tab === 3} onClick={() => setTab(3)}>
              <p>Health Data</p>
            </TabLink>
            <TabLink isActive={tab === 4} onClick={() => setTab(4)}>
              <p>Attachments</p>
            </TabLink>
          </TabLinkContainer>

          <TabContent>
            {tab === 1 && (
              <StudentBioData
                bioData={bioObj}
                setBioData={setBioObj}
                readOnly={false}
                loading={formLoading}
                // action={createBioData}
              />
            )}
            {tab === 2 && (
              <SocialData
                bioData={socialObj}
                setBioData={setSocialObj}
                readOnly={false}
                loading={formLoading}
                // action={createSocialData}
              />
            )}
            {tab === 3 && (
              <Health
                bioData={healthObj}
                setBioData={setHealthObj}
                // action={createHealthData}
                loading={formLoading}
                readOnly={false}
              />
            )}
            {tab === 4 && (
              <Attachment
                showForm={true}
                data={attachments}
                // action={addAttachment}
                loading={formLoading}
                obj={attachObj}
                setObj={setAttachObj}
              />
            )}
          </TabContent>
        </TabContainer>
      </>
    </>
  );
};

export default AdmissionForm;
