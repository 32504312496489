import { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAccountProfile } from "../features/auth/authSlice";
import { toast } from "react-toastify";

const ProtectedRoutes = () => {
  const { admToken, authStatus, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (authStatus) {
      dispatch(getAccountProfile(admToken));
    } else {
      toast.error("Token has expired, Please Revalidate your user account");
      navigate("/");
      return;
    }
  }, [location, authStatus, dispatch, admToken, navigate]);

  return admToken && authStatus && user ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
