import {
  Button,
  Box,
  Typography,
  IconButton,
  Divider,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LoaderLine from "../../LoaderLine";
import SuccessAlert from "../../SuccessAlert";
import ErrorAlert from "../../ErrorAlert";
import { useUpdateClassworkQuestionMutation } from "../../../api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    sx: 200,
    lg: 650,
  },
  bgcolor: "background.paper",
  border: "1px solid #671E78",
  boxShadow: 24,
  p: 2,
  borderRadius: 3,
  height: 600,
  overflow: "scroll",
};

const EditQuestion = ({
  open,
  onClose,
  id,
  assignmentId,
  myQuestion,
  classworkData,
}) => {
  const [onEditQuestion] = useUpdateClassworkQuestionMutation();
  const [selectedFile, setSelectedFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState(false);
  const [question, setQuestion] = useState();
  useEffect(() => {
    setQuestion(myQuestion);
  }, [myQuestion]);
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file from the input
    setSelectedFile(file); // Save the file in the state
  };
  const handleQuestionEdit = async () => {
    setLoading(true);
    setResponseMessage(false);
    setErrorMessage(false);
    const editData = {
      classWorkId: assignmentId,
      questionid: id,
      Question: question,
      questionFile: selectedFile ? selectedFile?.name : "",
      marks: 0,
    };
    try {
      const res = onEditQuestion(editData);
      if (res) {
        setResponseMessage(true);
        setLoading(false);
        onClose();
        setQuestion("");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setErrorMessage(true);
      onClose();
    }
  };
  return (
    <>
      {loading && <LoaderLine />}
      {responseMessage && (
        <SuccessAlert
          openState={responseMessage}
          message={"Question updated successfully"}
        />
      )}
      {errorMessage && (
        <ErrorAlert
          openState={errorMessage}
          message={"Error, please try again"}
        />
      )}
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box align="right">
            <IconButton onClick={onClose}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box align="center" sx={{ mb: 2 }}>
            <Typography sx={{ fontFamily: "gilroy-bold", fontSize: "30px" }}>
              Edit Question
            </Typography>
          </Box>
          <Box className="flex items-center justify-center" sx={{ mb: 2 }}>
            <Typography sx={{ fontFamily: "gilroy-regular" }}>
              {classworkData?.subject} - {classworkData?.classWorkTitle}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ mt: 3, px: 3 }}>
            <InputLabel
              sx={{
                fontFamily: "gilroy-regular",
                color: "#333",
                fontSize: "12px",
                mb: 1,
              }}
            >
              Enter Question in the textbox below
            </InputLabel>
            <TextField
              size="large"
              fullWidth
              multiline
              rows={6}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              InputProps={{
                style: {
                  fontFamily: "Gilroy-regular",
                  fontSize: "13px",
                  borderRadius: "5px",
                  offset: " 0.5px solid #671E78",
                  border: " 0.5px solid #671E78",
                },
              }}
            />
            <InputLabel
              sx={{
                fontFamily: "gilroy-regular",
                color: "#333",
                fontSize: "12px",
                mb: 1,
                mt: 3,
              }}
            >
              Upload Picture(optional)
            </InputLabel>
            <TextField
              type="file"
              fullWidth
              onChange={handleFileChange}
              InputProps={{
                style: {
                  fontFamily: "Gilroy-regular",
                  fontSize: "13px",
                  borderRadius: "5px",
                  offset: " 0.5px solid #671E78",
                  border: " 0.5px solid #671E78",
                },
              }}
            />
          </Box>
          <Button
            disabled={!question}
            variant="contained"
            fullWidth
            sx={{
              mt: 4,
              textTransform: "initial",
              fontFamily: "gilroy-regular",
            }}
            onClick={handleQuestionEdit}
          >
            Update Question
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default EditQuestion;
