import React, { useState, createRef } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/system";
import colors from "../../assets/colors.json";

import {
  BigHeader,
  TabContainer,
  TabLinkContainer,
  AccountSummaryWrapper,
  Icon,
  Input,
  Search,
  SmallHeader,
  TabContentWrapper,
  FormFlex,
  FormFlexDiv,
  InlineFormDiv,
} from "../../pages/Dashboard/styles/dashboard.style";
import { useMediaQuery } from "react-responsive";

import { BsFillJournalBookmarkFill, BsSearch } from "react-icons/bs";
import Popover from "@mui/material/Popover";
import { StatusBar } from "../../pages/student_mgt/stu_mgt.style";
import Modal from "../../components/modal/Modal";
import { InputWrapper, SmallText } from "../../pages/auth/Login.style";
import { Grid, IconButton, MenuItem, MenuList } from "@mui/material";
import { AuthButton } from "../../pages/auth/Login.style";
import Select from "../select/select";
import DatePickerComponent from "../datepicker/DatePicker";

const Training = () => {
  const [showModal, setShowModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [opt, setOpt] = useState("");
  const onPickFileRef = createRef();
  const [fileName, setFileName] = useState("");
  const [base64, setBase64] = useState("");
  const isTab = useMediaQuery({ maxWidth: 992 });
  const [dob, setDob] = useState(new Date(2014, 0, 3));

  const pickFile = () => {
    onPickFileRef.current.click();
  };
  const onPickFile = (event) => {
    const files = event.target.files;
    setFileName(files[0].name);
    let filename = files[0].name;
    if (
      filename.includes(".pdf") ||
      filename.includes(".doc") ||
      filename.includes(".docx")
    ) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        setBase64(fileReader.result);
      });
      fileReader.readAsDataURL(files[0]);
    } else {
      alert("Please upload a document");
    }

    console.log("We are working");
  };

  const optionsArr = [
    {
      id: 1,
      name: "Value 1",
    },
    {
      id: 2,
      name: "Value 2",
    },
  ];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const content = (
    <>
      {/* Reference to Add book */}
      <BigHeader style={{ textAlign: "center" }}>Apply for Training</BigHeader>
      <TabContentWrapper>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Training Name</label>
              <input
                type="text"
                placeholder="Training's Name"
                value={"Training One"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Description</label>
              <input
                type="text"
                placeholder="Description"
                value={"Training One"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Trainer's Name/Guide</label>
              <input
                type="text"
                placeholder="Trainer's Guide"
                value={"Training One"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Fee / Cost</label>
              <input
                type="text"
                placeholder="Training's Name"
                value={"Training One"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Paid by School</label>
              <div style={{ display: "block" }}>
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    marginRight: "0.9rem",
                  }}
                >
                  <input
                    type="checkbox"
                    style={{ marginTop: "0px", marginRight: "0.7rem" }}
                  />{" "}
                  <span>Yes</span>
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    marginRight: "0.9rem",
                  }}
                >
                  <input
                    type="checkbox"
                    style={{ marginTop: "0px", marginRight: "0.7rem" }}
                  />{" "}
                  <span>No</span>
                </div>
              </div>
              {/* <input
                type="text"
                placeholder="Trainer's Guide"
                value={"Training One"}
                onChange={(e) => console.log(e.target.value)}
              /> */}
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Affect Compensation</label>
              <div style={{ display: "block" }}>
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    marginRight: "0.9rem",
                  }}
                >
                  <input
                    type="checkbox"
                    style={{ marginTop: "0px", marginRight: "0.7rem" }}
                  />{" "}
                  <span>Yes</span>
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    marginRight: "0.9rem",
                  }}
                >
                  <input
                    type="checkbox"
                    style={{ marginTop: "0px", marginRight: "0.7rem" }}
                  />{" "}
                  <span>No</span>
                </div>
              </div>
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Reference URL</label>
              <input
                type="text"
                placeholder="Reference URL"
                value={"Training One"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Nature Of Training</label>
              <Select
                title="Nature Of Training"
                index="name"
                selected={opt}
                options={optionsArr}
                setSelected={(val) => {
                  console.log(val);
                  setOpt(val.name);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Eligibility</label>
              <input
                type="text"
                placeholder="Eligibility"
                value={"Training One"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="">Awards | Rewards</label>
              <input
                type="text"
                placeholder="Training's Name"
                value={"Training One"}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>

        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="start_date">Start Date</label>
              <DatePickerComponent
                selected={dob}
                setSelected={(val) => {
                  setDob(val);
                  // calculateAge(val);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="start_date">End Date</label>
              <DatePickerComponent
                selected={dob}
                setSelected={(val) => {
                  setDob(val);
                  // calculateAge(val);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        <FormFlex>
          <input
            type="file"
            style={{ display: "none" }}
            onChange={onPickFile}
            ref={onPickFileRef}
          />

          <InlineFormDiv width="48%" style={{ padding: "0px" }}>
            <div style={{ width: isTab ? "100%" : "60%" }}>
              <InputWrapper>
                <label htmlFor="">Cover Page</label>
                <input
                  type="text"
                  value={fileName + "" + base64}
                  disabled={true}
                  placeholder="File Name"
                />
              </InputWrapper>
            </div>
            <AuthButton
              width={isTab ? "100%" : "30%"}
              onClick={pickFile}
              bg={colors.secondary}
              color={"black"}
            >
              Choose
            </AuthButton>
          </InlineFormDiv>
        </FormFlex>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <AuthButton width={isTab ? "100%" : "80%"} onSubmit={null}>
            <div>
              <span>Apply For Training</span>
            </div>
            {/* Send Code */}
          </AuthButton>
        </div>
      </TabContentWrapper>
    </>
  );
  return (
    <>
      {showModal && (
        <Modal
          start={true}
          content={content}
          size={"large"}
          onClose={setShowModal}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SmallHeader style={{ fontWeight: "bold" }}>Training</SmallHeader>
        <AuthButton onClick={() => setShowModal(!showModal)}>
          Apply For Training
        </AuthButton>
      </div>
      <TabContainer style={{ paddingBottom: "0px" }}>
        <TabLinkContainer bg="#EEE0F1">
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search" />
          </Input>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table style={{ borderRadius: "0px" }}>
            <tr
              className="table-head"
              style={{ backgroundColor: "#F0E8F1", color: "black" }}
            >
              <th>Training Name</th>
              <th>Trainer/Guide</th>
              <th>Paid By School</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
              <th></th>
            </tr>
            <tr>
              <td>12/02/2022</td>
              <td>Mrs Adeola Johnson</td>
              <td>CMS Grammar School</td>
              <td>April 10th, 2023</td> <td>April 10th, 2023</td>
              <td>
                <StatusBar>Approve</StatusBar>
              </td>
              <td>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              </td>
            </tr>
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem>Edit</MenuItem>
            <MenuItem>Delete</MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </>
  );
};

export default Training;
