import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Grid, createTheme, ThemeProvider } from "@mui/material";
import { Box } from "@mui/system";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import SelectInput from "@mui/material/Select";
import {
  getAllClasses,
  assignStudentNewClass,
} from "../../../services/Subject";
import { checkAccess, paginateData } from "../../../services/utils";
import Avatar from "../../../assets/Images/avatar.jpg";
import Pagination from "../../pagination_one/pagination";
import {
  AccountSummaryWrapper,
  BigHeader,
  Container,
  Icon,
  Input,
  Search,
  TabContainer,
  TabLinkContainer,
} from "../../../pages/Dashboard/styles/dashboard.style";
import ErrorService from "../../../services/errorService";
import GlobalStyles from "../../../assets/globalStyle";
import colors from "../../../assets/colors.json";
import { BsSearch } from "react-icons/bs";
import { AuthButton, InputWrapper } from "../../../pages/auth/Login.style";
import Select from "../../select/select";
import Modal from "../../modal/Modal";
import Loader from "../../pageLoader/loader";
import api from "../../../services/ControlPanel";
import FormLoader from "../../FormLoader";
import { toast } from "react-toastify";
import { ImgTextDiv } from "../../../pages/student_mgt/stu_mgt.style";
import { LabelWrapper, SmallText } from "../../../pages/auth/auth.style";
const StudentSetup = () => {
  const [itemOffSet, setitemOffSet] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [arr, setArr] = useState([]);
  const [sQuery, setSQuery] = useState("");
  const [data, setData] = useState([]);
  const [classId, setClassId] = useState(0);
  const [student, setStudent] = useState({
    id: "",
    name: "",
  });
  const [showModal, setShowModal] = useState();
  const [admissionNumber, setAdmissionNumber] = useState("");
  const getClassesFunc = useCallback(async () => {
    try {
      const res = await getAllClasses(admToken, user.schoolId);
      if (res.status && res.statusCode === 2) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken, user.schoolId]);

  useEffect(() => {
    getClassesFunc();
  }, [getClassesFunc]);

  const getStudentFunc = useCallback(
    async (id) => {
      setLoading(true);
      setCurrentPage(1);
      try {
        const res = await api.assignAdmissionStudentList(
          admToken,
          user.schoolId,
          id
        );
        if (res.status && res.statusCode === 2) {
          setAllData(res.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [admToken, user.schoolId]
  );
  const resetData = () => {
    setCurrentPage(1);
    paginateData(allData, setArr, 1, itemOffSet, setitemOffSet, setPageCount);
  };
  const filterSearch = useCallback(
    async (e) => {
      let arr = [];
      const queryRegex = new RegExp(e.target.value, "i");
      allData.forEach((item) => {
        Object.values(item).some((val) =>
          queryRegex.test(val) ? arr.push(item) : ""
        );
      });
      setArr(arr);
      setPageCount(0);
    },
    [allData]
  );
  useEffect(() => {
    getStudentFunc(0);
  }, [getStudentFunc]);
  useEffect(() => {
    paginateData(
      allData,
      setArr,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allData, currentPage, itemOffSet]);
  const [formLoading, setFormLoading] = useState(false);

  const [classObj, setClassObj] = useState({
    id: "",
    name: "",
  });
  const [terms, setTerms] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);

  const assignClass = useCallback(
    async (class_id, term, studentId) => {
      setAssignLoading(true);
      try {
        const res = await assignStudentNewClass(
          admToken,
          studentId,
          class_id,
          term
        );
        if (res.status && res.statusCode === 2) {
          toast.success(res.message, {
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
        !res.status && toast.error(res.message);
        res.status === 400 && ErrorService.displayErrorAlert(res);
        setAssignLoading(false);
        setClassObj({ ...classObj, id: "", name: "" });
        setTerms(false);
        setShowModal(false);
        setCurrentPage(1);
        getStudentFunc(classId);
        setSQuery("");
      } catch (error) {
        setAssignLoading(false);
        toast.error("An Error Occurred Performing Action");
      }
    },
    [admToken, classId, classObj, getStudentFunc]
  );

  const assignAdmFun = useCallback(async () => {
    const data = {
      admissionNumber: admissionNumber,
      studentId: student.id,
      schoolId: user.schoolId,
    };
    setFormLoading(true);
    try {
      const res = await api.assignAdmission(admToken, data);
      if (res.status && res.statusCode === 2) {
        toast.success(res.message, {
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setAdmissionNumber("");
        getStudentFunc(classId);
      }
      setFormLoading(false);

      res.status === 500 && ErrorService.displayErrorAlert(res.data);
      res.status === 400 && ErrorService.displayErrorAlert(res.data);
      !res.status && ErrorService.displayErrorAlert(res);
    } catch (error) {
      toast.error("Error Performing This Action, Please Try Again Later");
      setFormLoading(false);
    }
  }, [
    admToken,
    admissionNumber,
    classId,
    getStudentFunc,
    student.id,
    user.schoolId,
  ]);

  const removeAdmFun = useCallback(
    async (stdId) => {
      setFormLoading(true);
      try {
        const res = await api.removeAdmission(admToken, user.schoolId, stdId);
        if (res.status && res.statusCode === 2) {
          toast.success(res.message, {
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setAdmissionNumber("");
          getStudentFunc(classId);
        }
        setFormLoading(false);

        res.status === 500 && ErrorService.displayErrorAlert(res.data);
        res.status === 400 && ErrorService.displayErrorAlert(res.data);
        !res.status && ErrorService.displayErrorAlert(res);
      } catch (error) {
        toast.error("Error Performing This Action, Please Try Again Later");
        setFormLoading(false);
      }
    },
    [admToken, classId, getStudentFunc, user.schoolId]
  );

  const [showAdmissionModal, setShowAdmissionModal] = useState(false);
  const content = (
    <>
      <BigHeader style={{ textAlign: "center", fontWeight: "light" }} fs="19px">
        Assign Class To {student.name}
      </BigHeader>
      <InputWrapper>
        <Select
          title={"Class"}
          index="className" // no
          selected={classObj.id} // false
          options={data}
          thirdOption="classId"
          // extraTitle="classArm"
          setSelected={(val) => {
            setClassObj({
              ...classObj,
              id: val.classId,
              name: `${val.className} ${val.classArm}`,
            });
          }}
        />
      </InputWrapper>
      <InputWrapper style={{ display: "flex", alignItems: "center" }}>
        <LabelWrapper>
          <input
            style={{
              width: "auto",
              borderRadius: "50%",
            }}
            type="checkbox"
            value={terms}
            onChange={() => setTerms(!terms)}
          />

          <span></span>
        </LabelWrapper>
        <div
          style={{
            marginLeft: "7px",
            fontWeight: "bold",
            fontStyle: "Gilroy-Bold",
          }}
        >
          <SmallText color={colors.gray}>
            I Agree To Assign Class to {student.name}
          </SmallText>
        </div>
      </InputWrapper>
      <div>
        <AuthButton
          onClick={() => {
            assignClass(classObj.id, terms, student.id);
          }}
          width="100%"
          mt="0.6rem"
          disabled={
            student.id === "" || classObj.id === "" || !terms || assignLoading
          }
        >
          {assignLoading ? <FormLoader /> : <>Assign Class</>}
        </AuthButton>
      </div>
    </>
  );

  const AdmissionNoForm = (
    <>
      <BigHeader style={{ textAlign: "center", fontWeight: "light" }} fs="19px">
        Assign Admission no to {student.name}
      </BigHeader>
      <InputWrapper
      // style={{
      //   display: "flex",
      //   // alignItems: "center",
      //   flexDirection: "column",
      // }}
      >
        <LabelWrapper>Admission No</LabelWrapper>
        <input
          type="text"
          value={admissionNumber}
          onChange={(e) => setAdmissionNumber(e.target.value)}
        />
      </InputWrapper>
      <div>
        <AuthButton
          onClick={() => assignAdmFun()}
          width="100%"
          mt="0.6rem"
          disabled={
            student.id === "" ||
            formLoading ||
            admissionNumber === "" ||
            admissionNumber === null
          }
        >
          {formLoading ? <FormLoader /> : <>Assign Number</>}
        </AuthButton>
      </div>
    </>
  );

  if (loading) {
    return <Loader />;
  }
  return (
    <Container>
      <GlobalStyles />
      {showModal && (
        <Modal content={content} size="large" onClose={setShowModal} />
      )}
      {showAdmissionModal && (
        <Modal
          content={AdmissionNoForm}
          size="large"
          onClose={setShowAdmissionModal}
        />
      )}
      <div>
        <BigHeader
          fs="27px"
          style={{
            borderBottom: `4px solid ${colors.primary}`,
            paddingBottom: "4px",
          }}
        >
          Manage Class And Admission Number
        </BigHeader>
      </div>

      <Box sx={{ display: "", marginTop: "0.3rem" }}>
        <Grid container spacing={2}>
          {data.length > 0 && (
            <Grid item lg={12} xs={12}>
              <FormControl fullWidth>
                <label htmlFor="">Choose Class</label>

                <SelectInput
                  sx={{ fontFamily: "Gilroy-Regular" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="outlined"
                  size="small"
                  value={classId.toString()}
                  onChange={(e) => {
                    setClassId(e.target.value);
                    getStudentFunc(e.target.value);
                  }}
                >
                  <MenuItem sx={{ fontFamily: "Gilroy-Regular" }} value={0}>
                    Other Students
                  </MenuItem>
                  {data.length > 0 &&
                    data.map((item, index) => (
                      <MenuItem
                        key={index}
                        sx={{ fontFamily: "Gilroy-Regular" }}
                        value={item.classId}
                      >
                        {item.className}
                      </MenuItem>
                    ))}
                </SelectInput>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Box>

      <TabContainer
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          paddingBottom: "0px",
        }}
      >
        <TabLinkContainer bg="rgb(238, 224,241)">
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search
              type="text"
              placeholder="Search For Class "
              value={sQuery}
              onChange={(e) => {
                setSQuery(e.target.value);
                if (e.target.value.length > 2) {
                  filterSearch(e);
                }
                if (e.target.value === "") {
                  resetData();
                }
              }}
            />
          </Input>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table
            style={{
              borderRadius: "0px",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <tr
              className="tabel-head"
              style={{ color: "black", fontWeight: "bold" }}
            >
              <th>S/N</th>
              <th>Student Name ({allData.length > 0 && allData.length}) </th>
              <th>Admission Number</th>
              <th>Class</th>
              <th></th>
            </tr>
            {arr.length > 0 ? (
              arr.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <ImgTextDiv>
                      <img
                        src={
                          item?.profileImagePath
                            ? item.profileImagePath
                            : Avatar
                        }
                        alt=""
                      />
                      <div>
                        <span>{item.fullName} </span>
                      </div>
                    </ImgTextDiv>
                  </td>{" "}
                  <td>{item.admissionNo}</td>
                  <td> {item.className}</td>
                  <td>
                    {checkAccess(accessObj.rights, "Assign") && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        {/* {item.classId === 0 && ( */}
                        <button
                          disabled={
                            assignLoading && item.studentId === student.id
                          }
                          style={{
                            backgroundColor:
                              item.classId === 0 ? colors.primary : "red",
                            padding: "0.3rem 0.6rem",
                            borderRadius: "10px",
                            color: "white",
                          }}
                          onClick={() => {
                            // setStudent({
                            //   ...student,
                            //   id: item.studentId,
                            //   name: item.fullName,
                            // });
                            setStudent((prevState) => ({
                              ...prevState,
                              id: item.studentId,
                              name: item.fullName,
                            }));
                            if (item.classId === 0) {
                              setShowModal(true);
                            } else {
                              assignClass(0, true, item.studentId);
                            }
                          }}
                        >
                          {assignLoading && item.studentId === student.id
                            ? "Please wait..."
                            : item.classId === 0
                            ? "Assign Class"
                            : "Unassign Class"}
                        </button>
                        {/* )} */}

                        <button
                          onClick={() => {
                            setStudent({
                              ...student,
                              id: item.studentId,
                              name: item.fullName,
                            });
                            // setStudentId(item.studentId);
                            // assignAdmFun(
                            //   item.studentId,
                            //   item.admissionNo === "" ||
                            //     item.admissionNo === null
                            //     ? "Assign-"
                            //     : "Remove"
                            // );
                            if (
                              item.admissionNo === "" ||
                              item.admissionNo === null
                            ) {
                              setShowAdmissionModal(true);
                            } else {
                              removeAdmFun(item.studentId);
                            }
                          }}
                          disabled={
                            student.id === item.studentId && formLoading
                          }
                          style={{
                            backgroundColor: colors.primary,
                            padding: "0.3rem 0.6rem",
                            borderRadius: "10px",
                            color: "white",
                            marginTop: "0.3rem",
                          }}
                        >
                          {student.id === item.studentId && formLoading
                            ? "Please wait..."
                            : item.admissionNo === "" ||
                              item.admissionNo === null
                            ? "Assign Admission No"
                            : "Remove Admission No"}
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} style={{ textAlign: "center" }}>
                  No Students At The Moment
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      {pageCount > 1 && (
        <div
          style={{
            marginTop: "2px",
            marginBottom: "2px",
          }}
        >
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            currentPage={currentPage - 1}
            range={3}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </Container>
  );
};

export default StudentSetup;
