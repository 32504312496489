import { Box } from "@mui/system";
import { useState, useEffect, useCallback } from "react";
import { addUserInformation, userStaffProfile } from "../../services/user";
import Title from "../../components/Dashboard/Title";
import colors from "../../assets/colors.json";
import {
  SmallHeader,
  TabContainer,
  TabContent,
  TabLink,
  TabLinkContainer,
} from "./styles/dashboard.style";
import {
  Personal,
  Experience,
  Education,
  Certifications,
  NOK,
  UserInformation,
} from "../../components/profile";
import ErrorService from "../../services/errorService";
import { toast } from "react-toastify";
import GlobalStyles from "../../assets/globalStyle";
import { useSelector } from "react-redux";
import Loader from "../../components/pageLoader/loader";
import axios from "axios";
import { TeacherClasses } from "../../services/Subject";

const Profile = () => {
  const { admToken, user, token } = useSelector((state) => state.auth);
  const [tab, setTab] = useState(6);
  const [bioData, setBioData] = useState(null);
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  let defaultEduObj = {
    school: "",
    course: "",
    degree: "",
    grade: "",
    startDate: "",
    endDate: "",
    comments: "",
    proofFileBase64: "",
  };
  const [eduObj, setEduObj] = useState(defaultEduObj);

  let defaultExpObj = {
    employer: "",
    position: "",
    empPhoneNumber: "",
    empEmail: "",
    startDate: "",
    empAddress: "",
    endDate: "",
    reasons: "",
    current: false,
  };

  const [expObj, setExpObj] = useState(defaultExpObj);

  let defaultCertObj = {
    institution: "",
    courseName: "",
    licenseName: "",
    issueingBody: "",
    startDate: "",
    endDate: "",
    proofFileBase64: "",
  };

  const [certObj, setCertObj] = useState(defaultCertObj);

  let defaultNokObj = {
    firstName: "",
    surName: "",
    title: "",
    nextKinType: "",
    profileImageurl: "",
    email: "",
    phoneNumber: "",
    address: "",
    relationShip: "",
  };
  const [nokObj, setNokObj] = useState(defaultNokObj);

  const isObjEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === "") {
        return false;
      }
    }
    return true;
  };

  const getProfile = useCallback(async () => {
    try {
      const res = await userStaffProfile(admToken);
      console.log(res);
      if (res.status && res.statusCode === 2) {
        setBioData(res.data);
      } else {
        setBioData(null);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);

  const getClasses = useCallback(async () => {
    try {
      const res = await TeacherClasses(admToken, user.schoolId);
      console.log(res);
      if (res.status && res.statusCode === 2) {
        setClasses(res.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);

  // const getProfile = useCallback(
  //   async (staffId, schoolId, admToken) => {
  //     try {
  //       const endpoint = `https://lasmabadmindev.azurewebsites.net/api/v1/MySchool/ViewStaff-Details?schoolId=${schoolId}&staffId=${staffId}`;

  //       const res = await axios.get(endpoint, {
  //         headers: {
  //           Authorization: `Bearer ${admToken, token}`,
  //         },
  //       });

  //       if (res.data.status === true && res.data.statusCode === 2) {
  //         setBioData(res.data.data);
  //       } else {
  //         setBioData(null);
  //       }
  //       setLoading(false);
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }, [admToken]
  // );

  // useEffect(() => {
  //   if (user?.staffId && user?.schoolId) {
  //     getProfile(user.staffId, user.schoolId, admToken);
  //   }
  // }, [admToken, user.staffId, user.schoolId, getProfile]);

  const addProfile = async (obj, type, setObj, defaultObj) => {
    let formIsValid = isObjEmpty(obj);
    if (!formIsValid) {
      return toast.error("Please Make Sure You Fill All The Form");
    }
    setFormLoading(true);

    // console.log(obj);
    try {
      const res = await addUserInformation(admToken, obj, type);
      console.log(res);
      res.status &&
        res.statusCode === 2 &&
        setObj(Object.assign({}, defaultObj));
      res.status &&
        res.statusCode === 2 &&
        toast.success(res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        }) &&
        getProfile();

      (res.status === 400 || res.status === 500) &&
        ErrorService.displayErrorAlert(res.data);
      setFormLoading(false);
    } catch (error) {
      console.log(error);
      setFormLoading(false);
    }
  };

  const clearEdits = (defaultObj, setObj) => {
    setObj(Object.assign({}, defaultObj));
  };

  const addProfileEducation = () =>
    addProfile(eduObj, "Add-Educational-Q", setEduObj, defaultEduObj);
  const addProfileExp = () =>
    addProfile(expObj, "Add-Experience", setExpObj, defaultExpObj);
  const addProfileCert = () =>
    addProfile(certObj, "Add-Certification", setCertObj, defaultCertObj);
  const addProfileNOK = () =>
    addProfile(nokObj, "Add-NextofKin", setNokObj, defaultNokObj);
  useEffect(() => {
    getProfile();
  }, [getProfile]);

  useEffect(() => {
    getClasses();
  }, []);

  if (loading) {
    return (
      <>
        <GlobalStyles />
        <Loader />
      </>
    );
  }

  return (
    <>
      <GlobalStyles />
      <Box>
        <Title title="My Profile" />
      </Box>
      <TabContainer>
        <TabLinkContainer>
          <TabLink
            isActive={tab === 6}
            onClick={() => setTab(6)}
            minWidth="15%"
          >
            <p>User Information</p>
          </TabLink>
          <TabLink
            isActive={tab === 1}
            onClick={() => setTab(1)}
            minWidth="15%"
          >
            <p>Personal Data</p>
          </TabLink>{" "}
          <TabLink
            isActive={tab === 2}
            onClick={() => setTab(2)}
            minWidth="15%"
          >
            <p>
              Educational <span className="block">Qualification</span>
            </p>
          </TabLink>{" "}
          <TabLink
            isActive={tab === 3}
            onClick={() => setTab(3)}
            minWidth="15%"
          >
            <p>
              Experience <span className="block">History</span>
            </p>
          </TabLink>{" "}
          <TabLink
            isActive={tab === 4}
            onClick={() => setTab(4)}
            minWidth="15%"
          >
            <p>Certifications</p>
          </TabLink>{" "}
          <TabLink
            isActive={tab === 5}
            onClick={() => setTab(5)}
            minWidth="15%"
          >
            <p>Next of Kin</p>
          </TabLink>{" "}
        </TabLinkContainer>
        {bioData !== null && (
          <TabContent>
            {tab === 6 && (
              <UserInformation
                data={bioData.userInformation}
                myClasses={classes}
              />
            )}
            {tab === 1 && <Personal data={bioData.personalData} />}
            {tab === 2 && (
              <Education
                data={bioData.educations}
                obj={eduObj}
                setObj={setEduObj}
                showForm={true}
                formLoading={formLoading}
                action={addProfileEducation}
                clearFields={() => clearEdits(defaultEduObj, setEduObj)}
              />
            )}
            {tab === 3 && (
              <Experience
                data={bioData.experienceHistory}
                obj={expObj}
                setObj={setExpObj}
                showForm={true}
                formLoading={formLoading}
                action={addProfileExp}
                clearFields={() => clearEdits(defaultExpObj, setExpObj)}
              />
            )}
            {tab === 4 && (
              <Certifications
                data={bioData.certifications}
                showForm={true}
                obj={certObj}
                setObj={setCertObj}
                formLoading={formLoading}
                action={addProfileCert}
                clearFields={() => clearEdits(defaultCertObj, setCertObj)}
              />
            )}
            {tab === 5 && (
              <NOK
                data={bioData.nextofKins}
                showForm={true}
                formLoading={formLoading}
                obj={nokObj}
                setObj={setNokObj}
                action={addProfileNOK}
                clearFields={() => clearEdits(defaultNokObj, setNokObj)}
              />
            )}
          </TabContent>
        )}

        {bioData === null && (
          <SmallHeader
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: colors.primary,
              marginTop: "0.4rem",
            }}
          >
            No Staff Information, Please Try Again Later!!!
          </SmallHeader>
        )}
      </TabContainer>
    </>
  );
};

export default Profile;
