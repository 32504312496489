import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { storePrev } from "../../features/auth/authSlice";
import Pagination from "../../components/pagination_one/pagination";
import Loader from "../../components/pageLoader/loader";
import { getSchoolList } from "../../services/MySchool";
import { paginateData } from "../../services/utils";
import GlobalStyles from "../../assets/globalStyle";
import colors from "../../assets/colors.json";
import Avatar from "../../assets/Images/avatar.jpg";
import { BsSearch, BsFillPrinterFill } from "react-icons/bs";
import { AiFillFileExcel } from "react-icons/ai";
import { SiAdobeacrobatreader } from "react-icons/si";
import {
  Container,
  TabContainer,
  AccountSummaryWrapper,
  TabLinkContainer,
  Icon,
  Input,
  Search,
} from "../Dashboard/styles/dashboard.style";
import { ImgTextDiv } from "../student_mgt/stu_mgt.style";

const SchoolStaffInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { admToken, token, user } = useSelector((state) => state.auth);
  const [itemOffSet, setItemOffSet] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [selectedStaffId, setSelectedStaffId] = useState(null);
  const [staffProfile, setStaffProfile] = useState(null);

  // Fetch the list of staff
  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getSchoolList(admToken, "StaffList", user.schoolId);
      setAllData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [admToken, user.schoolId]);

  // Paginate the data whenever allData or currentPage changes
  useEffect(() => {
    paginateData(allData, setList, currentPage, itemOffSet, setItemOffSet, setPageCount);
  }, [allData, currentPage, itemOffSet]);

  // Fetch the list when the component mounts
  useEffect(() => {
    getList();
  }, [getList], []);

  // Fetch staff profile data when selectedStaffId changes
  useEffect(() => {
    const fetchStaffProfile = async () => {
      if (selectedStaffId) {
        try {
          setStaffProfile(null); // Clear previous staff profile state
          const endpoint = `https://lasmabadmindev.azurewebsites.net/api/v1/MySchool/ViewStaff-Details?schoolId=${user.schoolId}&staffId=${selectedStaffId}`;
          const res = await axios.get(endpoint, {
            headers: {
              Authorization: `Bearer ${token}`, // Changed from admToken to token
            },
          });
          console.log("Response Data:", res.data.data); // Log the response data for debugging
          console.log("Selected Staff ID:", selectedStaffId); // Log the selected staff ID for debugging
  
          if (res.data.status === true && res.data.statusCode === 2) {
            setStaffProfile(res.data.data);
          } else {
            throw new Error('User information not found in response');
          }
        } catch (error) {
          console.error("Error fetching staff profile:", error);
          setStaffProfile(null);
        }
      }
    };
  
    fetchStaffProfile();
  }, [selectedStaffId, token, user.schoolId], []); // Changed from admToken to token
  

  // Navigate to the staff detail page when staffProfile is updated
  useEffect(() => {
    if (staffProfile) {
      const staffUrl = `/staff-member?school_id=${user.schoolId}&staff_id=${selectedStaffId}`;
      dispatch(storePrev("/myschools?tab=4"));
      navigate(staffUrl);
    }
  }, [staffProfile, navigate, selectedStaffId, user.schoolId, dispatch]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <GlobalStyles />
      {!loading && (
        <TabContainer style={{ marginTop: "1rem", paddingBottom: "0px" }}>
          <TabLinkContainer bg="#F0E8F1" style={{ position: "relative" }}>
            <Input>
              <Icon>
                <BsSearch />
              </Icon>
              <Search type="text" placeholder="Search " />
            </Input>
            <div style={{ display: "inline-flex" }} className="div">
              <AiFillFileExcel style={{ fontSize: "25px", marginLeft: "0.3rem" }} />
              <SiAdobeacrobatreader style={{ fontSize: "25px", marginLeft: "0.3rem" }} />
              <BsFillPrinterFill style={{ fontSize: "25px", marginLeft: "0.3rem" }} />
            </div>
          </TabLinkContainer>
          <AccountSummaryWrapper pt="0px">
            <table
              style={{
                borderRadius: "0px",
                borderBottomLeftRadius: "20px",
                borderBottomRightRadius: "20px",
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            >
              <thead>
                <tr className="table-head" style={{ backgroundColor: "#F0E8F1", color: "black" }}>
                  <th>S/N</th>
                  <th>Staff Name ({allData.length > 0 && allData.length})</th>
                  <th>Staff No</th>
                  <th>Role</th>
                  <th>Phone</th>
                  <th>Email Address</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {list.length > 0 ? (
                  list.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <ImgTextDiv>
                          <img src={Avatar} alt="" />
                          <span>{item.name}</span>
                        </ImgTextDiv>
                      </td>
                      <td>{item.staffNumber}</td>
                      <td>{item.majorRole}</td>
                      <td>{item.phoneNumber}</td>
                      <td>{item.email}</td>
                      <td style={{ paddingRight: "15px" }}>
                        <button
                          onClick={() => setSelectedStaffId(item.staffId)}
                          style={{
                            padding: "0.4rem 0.8rem",
                            border: `1px solid ${colors.primary}`,
                            borderRadius: "3rem",
                            fontWeight: "bold",
                            background: "transparent",
                            color: colors.primary,
                          }}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7} style={{ textAlign: "center" }}>
                      No School Staff Available At The Moment
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </AccountSummaryWrapper>
        </TabContainer>
      )}
      {pageCount > 1 && (
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Pagination pageCount={pageCount} range={1} setCurrentPage={setCurrentPage} />
        </div>
      )}
    </Container>
  );
};

export default SchoolStaffInfo;
