/* eslint-disable eqeqeq */
import React, { useEffect, useState, useCallback } from "react";
import { Navbar, Header } from "../../components/Single";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import {
  Profile,
  Result,
  Medical,
  Ledger,
  Parents,
} from "../../components/SingleStudent";
import { useSelector } from "react-redux";
import {
  singleStudentData,
  nonAdmittedData,
} from "../../assets/data/SingleStudent";
import { viewStudentDetails } from "../../services/MySchool";
const SingleStudent = () => {
  const location = useLocation();
  const { admToken, prev } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);

  const isPhone = useMediaQuery({ maxWidth: 768 });
  const schoolId = new URLSearchParams(location.search).get("school_id") || "";
  // student's phonenumber
  const studentId =
    new URLSearchParams(location.search).get("student_id") || "";
  const page_id = Number(new URLSearchParams(location.search).get("page")) || 1;
  const [name, setName] = useState("");

  // trying to get the student in order to get the student's medical
  const [id, setId] = useState(null);
  const [accountStatus, setAccountStatus] = useState("");
  const getStudentProfile = useCallback(async () => {
    try {
      let res = await viewStudentDetails(admToken, schoolId, studentId);
      console.log(res);
      console.log(res.data);
      // console.log(res.data.persoalData.firtName);

      if (res.status && res.statusCode === 2) {
        setName(
          `${res.data.bioData.firstName} ${res.data.bioData.otherName} ${res.data.bioData.lastName}`
        );
        setAccountStatus(res.data.bioData.accountStatus);
        setId(res.data.bioData.studentId);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  }, [schoolId, studentId, admToken]);

  useEffect(() => {
    getStudentProfile();
  }, [getStudentProfile]);

  // const hh = useNavigation();
  return (
    <Box sx={{ display: "flex" }}>
      <Navbar
        navData={accountStatus == "8" ? singleStudentData : nonAdmittedData}
        pathname={prev}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { lg: "90%", md: "90%", sm: "100%", xs: "1005" },
        }}
      >
        <Header
          navData={accountStatus == "8" ? singleStudentData : nonAdmittedData}
          pathname={prev}
        />
        <Box mt={14} sx={{ mx: "auto", width: isPhone ? "95%" : "85%" }}>
          {page_id === 1 && (
            <Profile
              schoolId={schoolId}
              studentId={studentId}
              token={admToken}
            />
          )}
          {page_id === 5 && (
            <Parents name={name} schoolId={schoolId} studentId={studentId} />
          )}
          {page_id === 2 && <Result studentPhone={studentId} name={name} />}
          {page_id === 3 && id && (
            <Medical
              name={name}
              loading={loading}
              schoolId={schoolId}
              // note I'm using the studentId from the view student details
              studentId={id}
              token={admToken}
            />
          )}
          {page_id === 4 && <Ledger name={name} />}
        </Box>
      </Box>
    </Box>
  );
};

export default SingleStudent;
