import { AccountCircle } from "@mui/icons-material";
import { Grid, InputAdornment, InputLabel, TextField } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import { Box } from "@mui/system";
import React from "react";

const PersonalData = () => {
  return (
    <>
      <Box sx={{ width: "87%", margin: "0 auto" }}>
        <Grid container spacing={3} pt={5}>
          <Grid item xs={12} md={6}>
            <InputLabel
              sx={{
                mb: 1.5,
                fontWeight: "bolder",
                color: "#000",
                fontFamily: "Gilroy-Medium",
              }}
            >
              Full Name
            </InputLabel>
            <TextField
              fullWidth
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "2px solid #671E78",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel
              sx={{
                mb: 1.5,
                fontWeight: "bolder",
                color: "#000",
                fontFamily: "Gilroy-Medium",
              }}
            >
              Place of work
            </InputLabel>
            <TextField
              fullWidth
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "2px solid #671E78",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel
              sx={{
                mb: 1.5,
                fontWeight: "bolder",
                color: "#000",
                fontFamily: "Gilroy-Medium",
              }}
            >
              Date of Birth
            </InputLabel>
            <TextField
              type="date"
              fullWidth
              size="small"
              defaultValue="2019-05-24"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "2px solid #671E78",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel
              sx={{
                mb: 1.5,
                fontWeight: "bolder",
                color: "#000",
                fontFamily: "Gilroy-Medium",
              }}
            >
              Office address
            </InputLabel>
            <TextField
              fullWidth
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "2px solid #671E78",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel
              sx={{
                mb: 1.5,
                fontWeight: "bolder",
                color: "#000",
                fontFamily: "Gilroy-Medium",
              }}
            >
              Place of Birth
            </InputLabel>
            <TextField
              fullWidth
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "2px solid #671E78",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel
              sx={{
                mb: 1.5,
                fontWeight: "bolder",
                color: "#000",
                fontFamily: "Gilroy-Medium",
              }}
            >
              Phone Number
            </InputLabel>
            <TextField
              fullWidth
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <p>+234</p>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "2px solid #671E78",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel
              sx={{
                mb: 1.5,
                fontWeight: "bolder",
                color: "#000",
                fontFamily: "Gilroy-Medium",
              }}
            >
              Name of child(ren) on school application
            </InputLabel>
            <TextField
              fullWidth
              multiline
              rows={5}
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "2px solid #671E78",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <InputLabel
                  sx={{
                    mb: 1.5,
                    fontWeight: "bolder",
                    color: "#000",
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Email address
                </InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "2px solid #671E78",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <InputLabel
                  sx={{
                    mb: 1.5,
                    fontWeight: "bolder",
                    color: "#000",
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Residential address
                </InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "2px solid #671E78",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PersonalData;
