// import PropTypes from "prop-types";
import Title from "../../Dashboard/Title";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
// import Typography from "@mui/material/Typography";
import NewStaffs from "./NewStaffs";
import StaffSetups from "./StaffSetups";
import StaffCredentialSetup from "./StaffCredentialSetup";

import { useLocation, useSearchParams } from "react-router-dom";
const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StaffAccountSetup = () => {
  const location = useLocation();
  const tab_id = Number(new URLSearchParams(location.search).get("tab")) || 0;
  const page_id = Number(new URLSearchParams(location.search).get("page")) || 1;
  const [searchParams, setSearchParams] = useSearchParams();
  const handleChange = (event, newValue) => {
    setSearchParams({ ...searchParams, tab: newValue, page: page_id });
  };
  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <Box>
          <Title title="Staff Account Setup" showCaption={false} />

          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tab_id}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{ overflowX: "scroll" }}
              >
                <Tab
                  label="Manage Staff Credentials"
                  {...a11yProps(0)}
                  sx={{
                    textTransform: "initial",
                    fontFamily: "Gilroy-Bold",
                    fontSize: "18px",
                  }}
                />
                <Tab
                  // New Staffs
                  label="Manage Staff Role"
                  {...a11yProps(1)}
                  sx={{
                    textTransform: "initial",
                    fontFamily: "Gilroy-Bold",
                    fontSize: "18px",
                  }}
                />
                <Tab
                  label="Manage Staff Privileges"
                  {...a11yProps(2)}
                  sx={{
                    textTransform: "initial",
                    fontFamily: "Gilroy-Bold",
                    fontSize: "18px",
                  }}
                />
              </Tabs>
            </Box>
            {tab_id === 0 && <StaffCredentialSetup />}

            {tab_id === 1 && <NewStaffs />}
            {tab_id === 2 && <StaffSetups />}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default StaffAccountSetup;
