import React, { useState, createRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  PageWrapper,
  InlineFormDiv,
  LogoImgSetup,
} from "../../../pages/Dashboard/styles/dashboard.style";
import colors from "../../../assets/colors.json";
import { useMediaQuery } from "react-responsive";
import { InputWrapper, AuthButton } from "../../../pages/auth/Login.style";
import Avatar from "../../../assets/Images/avatar.jpg";
import { useSelector } from "react-redux";
import { checkAccess } from "../../../services/utils";
const Logo = ({ obj, action, loading }) => {
  const { accessObj } = useSelector((state) => state.auth);

  let _obj = {
    name: "",
    logoPath: "",
    facebookUrl: "",
    twitterUrl: "",
    igUrl: "",
    imageName: "",
  };
  const [logoObj, setLogoObj] = useState(_obj);
  const onPickFileRef = createRef();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const pickFile = (ref) => {
    ref.current.click();
  };
  const onPickFile = (event) => {
    console.log(event);
    if (event !== null) {
      const files = event.target.files;
      let filename = files[0].name;
      if (
        filename.includes(".jpg") ||
        filename.includes(".png") ||
        filename.includes(".jpeg")
      ) {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          setLogoObj({
            ...logoObj,
            logoPath: fileReader.result,
            imageName: filename,
          });
        });
        fileReader.readAsDataURL(files[0]);
      } else {
        alert("Please Upload A Photo");
      }
    }
  };
  useEffect(() => {
    if (obj !== null) {
      setLogoObj({
        ...logoObj,
        name: obj.name ? obj.name : "",
        logoPath: obj.logoPath ? obj.logoPath : "",
        facebookUrl: obj.facebookUrl ? obj.facebookUrl : "",
        twitterUrl: obj.twitterUrl ? obj.twitterUrl : "",
        igUrl: obj.igUrl ? obj.igUrl : "",
        id: obj.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <InlineFormDiv width="90%" jc="space-between">
        <div
          style={{
            width: isMobile ? "100%" : "70%",
          }}
        >
          <input
            type="file"
            style={{
              display: "none",
            }}
            onChange={(e) => onPickFile(e)}
            ref={onPickFileRef}
          />
          <InputWrapper>
            <label>Upload Logo</label>
            <input
              type="text"
              value={logoObj.imageName}
              disabled={true}
              placeholder="File Name"
            />
          </InputWrapper>
        </div>
        <AuthButton
          width={isMobile ? "100%" : "20%"}
          onClick={() => pickFile(onPickFileRef)}
        >
          Upload
        </AuthButton>
      </InlineFormDiv>
      <LogoImgSetup>
        <img
          src={logoObj.logoPath.length > 0 ? logoObj.logoPath : Avatar}
          alt=""
        />
        {/* {file.base64.length > 0 && (
          <span
            onClick={() => {
              setFile({ ...file, base64: "", name: "" });
            }}
          >
            X
          </span>
        )} */}
      </LogoImgSetup>
      {/*  facebookUrl: "", twitterUrl: "", igUrl: "",
      imageName: "", */}
      <InputWrapper>
        <label>Name</label>
        <input
          type="text"
          placeholder="Name"
          value={logoObj.name}
          onChange={(e) =>
            setLogoObj({
              ...logoObj,
              name: e.target.value,
            })
          }
        />
      </InputWrapper>
      <InputWrapper>
        <label>Facebook URL</label>
        <input
          type="text"
          placeholder="Facebook Link"
          value={logoObj.facebookUrl}
          onChange={(e) =>
            setLogoObj({
              ...logoObj,
              facebookUrl: e.target.value,
            })
          }
        />
      </InputWrapper>
      <InputWrapper>
        <label>Twitter URL</label>
        <input
          type="text"
          placeholder="Twitter Profile"
          value={logoObj.twitterUrl}
          onChange={(e) =>
            setLogoObj({
              ...logoObj,
              twitterUrl: e.target.value,
            })
          }
        />
      </InputWrapper>
      <InputWrapper>
        <label>Instagram URL</label>
        <input
          type="text"
          placeholder="Instagram's Link"
          value={logoObj.igUrl}
          onChange={(e) =>
            setLogoObj({
              ...logoObj,
              igUrl: e.target.value,
            })
          }
        />
      </InputWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          borderBottom: `2px solid ${colors.primary}`,
          paddingBottom: "10px",
        }}
      >
        {checkAccess(accessObj.rights, "Save") && (
          <AuthButton onClick={() => action(logoObj)} disabled={loading}>
            {loading ? "Please wait..." : <>Save Info</>}
          </AuthButton>
        )}
      </div>
    </PageWrapper>
  );
};

Logo.propTypes = {
  obj: PropTypes.object,
  loading: PropTypes.bool,
  action: PropTypes.func,
};

Logo.defaultProps = {
  obj: null,
  loading: false,
};
export default Logo;
