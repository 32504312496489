// import { Container } from 'postcss'
import React, { useState } from "react";

import {
  Container,
  InlineLabelGroup,
  InlineLabelWrapper,
  SmallHeader,
  TabContainer,
  LedgerContainer,
  LedgerHeader,
  LedgerSchoolName,
  LedgerSchoolInfo,
  LedgerSchoolHead,
  LedgerStudentHeader,
  LedgerStudentDiv,
  LedgerInfoDetails,
  LedgerFlexInfo,
  LedgerTable,
  AccountSummaryWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
import GlobalStyles from "../../assets/globalStyle";
import colors from "../../assets/colors.json";

import {
  AuthButton,
  InputWrapper,
  ButtonFlex,
} from "../../pages/auth/auth.style";
import DatePickerComponent from "../../components/datepicker/DatePicker";
import { useMediaQuery } from "react-responsive";
import logo from "../../assets/school-logo.png";
import { GiPostOffice } from "react-icons/gi";
import { HiMail } from "react-icons/hi";
import { BsTelephoneFill, BsGlobe } from "react-icons/bs";
import avatar from "../../assets/Images/avatar.jpg";
import { Box } from "@mui/material";

const Ledger = ({ name }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  // const [showFilter, setShowFilter] = useState(true);
  const [dob, setDob] = useState(new Date(2014, 0, 3));
  return (
    <Container>
      <GlobalStyles />
      <Box
        borderBottom={`2px solid ${colors.primary}`}
        paddingBottom={"3px"}
        marginBottom={"24px"}
      >
        <SmallHeader
          style={{
            fontWeight: "bold",
            color: colors.lightGray,
            marginBottom: "1.9rem",
          }}
        >
          {/* <span style={{ marginRight: "0.9rem" }}>School</span> */}
          {name.length > 0 && (
            <>
              <span style={{ marginRight: "0.9rem", fontWeight: "bold" }}>
                Student Info- {name} {">>"}
              </span>
              <span style={{ color: "black", fontWeight: "bold" }}>Ledger</span>
            </>
          )}
        </SmallHeader>
      </Box>

      <TabContainer
        style={{
          marginTop: "1rem",
          background: colors.primary,
        }}
      >
        <InlineLabelGroup>
          <InlineLabelWrapper flex={2}>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper>
                  <label
                    htmlFor=""
                    style={{
                      color: "white",
                    }}
                  >
                    Start Date
                  </label>
                  <DatePickerComponent
                    bg={colors.primary}
                    useBorder={true}
                    color="white"
                    selected={dob}
                    setSelected={(val) => {
                      setDob(val);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={1}>
                <InputWrapper>
                  <label
                    htmlFor=""
                    style={{
                      color: "white",
                    }}
                  >
                    Stop Date
                  </label>
                  <DatePickerComponent
                    bg={colors.primary}
                    useBorder={true}
                    color="white"
                    selected={dob}
                    setSelected={(val) => {
                      setDob(val);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
          </InlineLabelWrapper>
          <InlineLabelWrapper flex={1}>
            <ButtonFlex>
              <AuthButton
                disabled={false}
                width={isMobile ? "100%" : "90%"}
                onClick={null}
                color={colors.primary}
                bg="white"
              >
                {" "}
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  Generate Ledger
                </div>
              </AuthButton>
            </ButtonFlex>
          </InlineLabelWrapper>
        </InlineLabelGroup>
      </TabContainer>
      <LedgerContainer>
        <LedgerHeader>
          <div>
            <LedgerSchoolName>
              <div>
                <img
                  src={logo}
                  alt="school-logo"
                  style={{ marginLeft: "4px" }}
                />
              </div>
              <span>C.M.S Grammar School, Bariga Lagos.</span>
            </LedgerSchoolName>
            <LedgerSchoolInfo>
              <div>
                <LedgerSchoolHead justifyContent="end">
                  <div>
                    <span>P.O Box 394, Yaba, Lagos</span>
                  </div>
                  <div>
                    <GiPostOffice />
                  </div>
                </LedgerSchoolHead>
                <LedgerSchoolHead justifyContent="end">
                  <div>
                    <span>cmsgrammarschool@gmail.com</span>
                  </div>
                  <div>
                    <HiMail />
                  </div>
                </LedgerSchoolHead>
              </div>
              <div>
                <LedgerSchoolHead>
                  <div>
                    <BsTelephoneFill />
                  </div>
                  <div>
                    <span>08012345678, 08090383883</span>
                  </div>
                </LedgerSchoolHead>
                <LedgerSchoolHead>
                  <div>
                    <BsGlobe />
                  </div>
                  <div>
                    <span>cmsgrammarschool@gmail.com</span>
                  </div>
                </LedgerSchoolHead>
              </div>
            </LedgerSchoolInfo>
          </div>
        </LedgerHeader>
        <LedgerStudentHeader>
          <div>
            <LedgerStudentDiv>
              <div
                style={{
                  width: "35%",
                  height: "160px",
                  borderRadius: "20px",
                }}
              >
                <img src={avatar} alt="" />
              </div>
              <div
                style={{
                  width: "60%",
                  padding: "0.4rem",
                }}
              >
                <LedgerInfoDetails>
                  <LedgerFlexInfo flex={1}>
                    <p>Name:</p>
                  </LedgerFlexInfo>
                  <LedgerFlexInfo flex={2}>
                    <span>Adeola James</span>{" "}
                  </LedgerFlexInfo>
                </LedgerInfoDetails>
                <LedgerInfoDetails>
                  <LedgerFlexInfo flex={1}>
                    <p>House</p>
                  </LedgerFlexInfo>
                  <LedgerFlexInfo flex={2}>
                    <span>Babington</span>
                  </LedgerFlexInfo>
                </LedgerInfoDetails>
                <LedgerInfoDetails>
                  <LedgerFlexInfo flex={1}>
                    <p>Term:</p>
                  </LedgerFlexInfo>
                  <LedgerFlexInfo flex={2}>
                    <span>Third Term</span>
                  </LedgerFlexInfo>
                </LedgerInfoDetails>
                <LedgerInfoDetails>
                  <LedgerFlexInfo flex={1}>
                    <p>Class</p>
                  </LedgerFlexInfo>
                  <LedgerFlexInfo flex={2}>
                    <span>JSS 1</span>
                  </LedgerFlexInfo>
                </LedgerInfoDetails>
              </div>
            </LedgerStudentDiv>
            <LedgerStudentDiv>
              <div
                style={{
                  width: "100%",
                  padding: "0.4rem",
                }}
              >
                <LedgerInfoDetails>
                  <LedgerFlexInfo flex={2}>
                    <p>Admission No:</p>
                  </LedgerFlexInfo>
                  <LedgerFlexInfo flex={1}>
                    <span>1555803</span>{" "}
                  </LedgerFlexInfo>
                </LedgerInfoDetails>
                <LedgerInfoDetails>
                  <LedgerFlexInfo flex={2}>
                    <p>Academic Session</p>
                  </LedgerFlexInfo>
                  <LedgerFlexInfo flex={1}>
                    <span>2021/2022</span>{" "}
                  </LedgerFlexInfo>
                </LedgerInfoDetails>
                <LedgerInfoDetails>
                  <LedgerFlexInfo flex={2}>
                    <p>Opening Balance:</p>
                  </LedgerFlexInfo>
                  <LedgerFlexInfo flex={1}>
                    <span>20,000</span>{" "}
                  </LedgerFlexInfo>
                </LedgerInfoDetails>
                <LedgerInfoDetails>
                  <LedgerFlexInfo flex={2}>
                    <p>Closing Balance:</p>
                  </LedgerFlexInfo>
                  <LedgerFlexInfo flex={1}>
                    <span>20,000</span>{" "}
                  </LedgerFlexInfo>
                </LedgerInfoDetails>
              </div>
            </LedgerStudentDiv>
          </div>
        </LedgerStudentHeader>
        <LedgerTable>
          <AccountSummaryWrapper>
            <table>
              <tr
                className="table-head"
                style={{
                  color: "black",
                }}
              >
                <th>Posting Date</th>
                <th>Transaction Date</th>
                <th>Reference No</th>
                {!isMobile && <th>Narration</th>}

                <th>Debit</th>
                <th>Credit</th>
                <th>Balance</th>
              </tr>
              <tr>
                <th>12/2/2022</th>
                <th>12/2/2022</th>
                <th>35E42</th>
                {!isMobile && <th>School fee</th>}
                <th
                  style={{
                    color: "rgba(255, 0,0, 0.2)",
                  }}
                >
                  20,000
                </th>
                <th
                  style={{
                    color: "rgba(0, 255, 0, 0.4)",
                  }}
                >
                  0.00
                </th>
                <th>20,000</th>
              </tr>
              <tr>
                <th>12/2/2022</th>
                <th>12/2/2022</th>
                <th>35E42</th>
                {!isMobile && <th>School fee</th>}
                <th
                  style={{
                    color: "rgba(255, 0,0, 0.2)",
                  }}
                >
                  20,000
                </th>
                <th
                  style={{
                    color: "rgba(0, 255, 0, 0.4)",
                  }}
                >
                  0.00
                </th>
                <th>20,000</th>
              </tr>
              <tr>
                <th>12/2/2022</th>
                <th>12/2/2022</th>
                <th>35E42</th>
                {!isMobile && <th>School fee</th>}
                <th
                  style={{
                    color: "rgba(255, 0,0, 0.2)",
                  }}
                >
                  20,000
                </th>
                <th
                  style={{
                    color: "rgba(0, 255, 0, 0.4)",
                  }}
                >
                  0.00
                </th>
                <th>20,000</th>
              </tr>
            </table>
          </AccountSummaryWrapper>
        </LedgerTable>
      </LedgerContainer>
    </Container>
  );
};

export default Ledger;
