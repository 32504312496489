import React from "react";
import {
  Box,
  Avatar,
  Typography,
  Chip,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import img from "../../../assets/Images/student.jpg";
import { Link, useLocation } from "react-router-dom";

const sideBar_Data = [
  {
    label: "Admission Form",
    link: "/parentmanagement/admission/dashboard/admissionform",
    selected: true,
  },
  {
    label: "Examination Slip",
    link: "/parentmanagement/admission/dashboard/examinationslip",
    selected: false,
  },
  {
    label: "Report",
    link: "/parentmanagement/admission/dashboard/report",
    selected: false,
  },
];
const SideBar = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const router = useLocation();
  return (
    <>
      {isMatch ? (
        <Box></Box>
      ) : (
        <Box
          sx={{
            bgcolor: "#5e9c09",
            height: "100%",
            borderRadius: "15px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            mt={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar src={img} sx={{ width: "90px", height: "90px" }} />
            <Typography
              variant="body1"
              color="initial"
              sx={{ fontFamily: "Gilroy-Medium", fontWeight: "bolder" }}
            >
              Adeolu James
            </Typography>
            <Chip label="Aged:12" sx={{ color: "#fff", fontSize: "10px" }} />
          </Box>

          <Box sx={{ width: "100%", pl: 3, mt: 5 }}>
            <List>
              {sideBar_Data.map((nav, index) => (
                <Link to={nav.link} key={index}>
                  <ListItemButton
                    selected={router.pathname === nav.link ? true : false}
                    sx={{
                      pl: 5,
                      " 	&.Mui-selected": {
                        bgcolor: "#fff",
                        borderRadius: "20px 0px 0px 20px",
                      },
                    }}
                  >
                    <ListItemText
                      sx={{ color: "#000", fontFamily: "Gilroy-Medium" }}
                    >
                      {nav.label}{" "}
                    </ListItemText>
                  </ListItemButton>
                </Link>
              ))}
            </List>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SideBar;
