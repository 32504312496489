import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Alert,
  Button,
  IconButton,
  MenuItem,
  MenuList,
  Snackbar,
  TableFooter,
  TablePagination,
  Typography,
  Tooltip,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import Modal from "@mui/material/Modal";
import Popover from "@mui/material/Popover";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Title from "../Dashboard/Title";
import LoaderLine from "../LoaderLine";
import Setup from "./Modals/Setup";

import CachedIcon from "@mui/icons-material/Cached";
import axios from "axios";
import { useSnackbar } from "notistack";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from "react-toastify";
import {
  useDeleteAssignmentsMutation,
  useGetAllAssignmentsQuery,
} from "../../api/assigmentAPi";
import AssignStaff from "./Modals/AssignStaff";
import Edit from "./Modals/Edit";
import StatusModal from "./Modals/statusModal";
import truncateString, { classList } from "../../utils";
import ActivateDeactivate from "./Modals/ActivateDeactivate";

const styleDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const DeleteModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 200, xs: 200 },
  height: "200px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "1px solid #671E78",
  boxShadow: 24,
  p: 4,
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gilroy-Regular",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(
  session,
  createdBy,
  phone,
  studentClass,
  name,
  status,
  email
) {
  return { session, createdBy, phone, studentClass, name, status, email };
}

const Assignment_First = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openAssign, setOpenAssign] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [activateDeactivate, setActivateDeactivate] = useState(false);
  const [tableRow, setTableRow] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [assignmentValues, setAssignmentValues] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [openAddClass, setOpenAddClass] = React.useState(false);
  const [loading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
  const api_version = process.env.REACT_APP_API_VERSION;
  const userInfo = useSelector((state) => state.auth.user);

  // COMEE AND RESUME HERE

  const handleCloseDelete = () => setOpenDelete(false);
  const handleActivateModal = () => {
    setActivateDeactivate(!activateDeactivate);
    setAnchorEl(null);
  };
  const adtoken = localStorage.getItem("admToken");
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };
  const {
    data: result,
    isLoading,
    refetch,
    isFetching,
  } = useGetAllAssignmentsQuery({
    schoolId: userInfo.schoolId,
  });

  const filteredAssignment = result?.data?.filter((item) => {
    return (
      item?.subject?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
      item?.description?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
      item?.className?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
      item?.title?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });

  const handleAssign = () => {
    setOpenAssign(false);
  };
  const handleEdit = () => {
    setOpenEdit(false);
  };

  const handleClickVariant = (variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar("Assignment Deleted Successfully", { variant });
  };
  const handleClickVariant2 = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };
  const handleClickVariant2Error = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };
  const handleOpenClass = () => {
    setOpenAddClass(true);
    setAnchorEl(null);
  };
  const handleCloseAddClass = () => setOpenAddClass(false);
  const handleClick = (event, subject, row) => {
    setAnchorEl(event.currentTarget);
    setSubject(subject);
    setEditData(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // fetch assignmeents

  const handleClickError = (variant) => {
    enqueueSnackbar("Error trying to delete, try again", { variant });
  };

  const [onDeleteAssignment] = useDeleteAssignmentsMutation();

  const deleteAssignment = async () => {
    setSuccess(false);
    try {
      const result = await onDeleteAssignment({
        Id: assignmentValues?.assignmentId,
      })?.unwrap();
      console.log("delete", result);
      if (result?.data?.status) {
        setOpenDelete(false);
        handleClickVariant("success");
        handleCloseDelete();
        setAnchorEl(null);
        refetch();
        toast.success("Assignment deleted successfully");
      }
    } catch (error) {
      toast.error("Error Deleting this assignmment", error);
    }
  };

  const handleOpenDelete = async () => {
    if (
      assignmentValues?.isSubmitted === true ||
      assignmentValues?.isActivate === true ||
      assignmentValues?.isQuestionadded === true
    ) {
      setOpenDelete(true);
      setAnchorEl(null);
    } else {
      deleteAssignment();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActivate = async () => {
    setIsLoading(true);

    const url = `${endpoint_two}/api/v${api_version}/Assignment/ActivateORDeactivateAssignment?AssignmentId=${assignmentValues?.assignmentId}&IsActivate=true`;

    // Define your headers here
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adtoken}`,
    };

    try {
      const result = await axios.post(url, { headers });

      if (result?.data?.status) {
        setOpenDelete(false);
        setIsLoading(false);
        handleClickVariant2("success", "Assignment activated successfully");
        refetch();
        handleActivateModal();
      }
    } catch (error) {
      setIsLoading(false);
      handleClickVariant2Error(
        "error",
        "Error activating assignment, try again"
      );
      console.error(error);
    }
  };
  const handleDeactivate = async () => {
    setIsLoading(true);

    const url = `${endpoint_two}/api/v${api_version}/Assignment/ActivateORDeactivateAssignment?AssignmentId=${assignmentValues?.assignmentId}&IsActivate=false`;

    // Define your headers here
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adtoken}`,
    };

    try {
      const result = await axios.post(url, { headers });

      if (result?.data?.status) {
        setOpenDelete(false);
        setIsLoading(false);
        handleClickVariant2("success", "Assignment deactivated successfully");
        refetch();
        handleActivateModal();
      }
    } catch (error) {
      setIsLoading(false);
      handleClickVariant2Error(
        "error",
        "Error deactivating assignment, try again"
      );
      console.error(error);
    }
  };

  return (
    <Box sx={{ pb: 5 }}>
      {(isLoading || isFetching) && <LoaderLine />}
      <Box align="right" sx={{ mt: 2 }}>
        <Setup refetch={refetch} />
      </Box>
      <Box
        mt={5}
        sx={{
          border: "1px solid #671E78",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            borderRadius: "20px 20px 0px 0px",
            pt: 4,
            background: "rgba(103, 30, 120, 0.1)",
            height: "100px",
            pl: { xs: 2.5 },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Search
            sx={{
              width: {
                lg: "345px",
                md: "330px",
                sm: "auto",
                xs: "auto",
                pl: { xs: 6 },
              },
            }}
          >
            <SearchIconWrapper>
              <BsSearch />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              value={searchInput}
              inputProps={{ "aria-label": "search" }}
              onChange={handleSearchInputChange}
            />
          </Search>
          <IconButton onClick={refetch} sx={{ mr: 3 }}>
            <CachedIcon />
          </IconButton>
        </Box>

        <TableContainer>
          <Table
            sx={{ minWidth: 700, fontFamily: "Gilroy-Regular" }}
            aria-label="customized table"
          >
            <TableHead
              sx={{
                backgroundColor: "rgba(103, 30, 120, 0.1)",
                width: "100%",
              }}
            >
              <TableRow>
                <StyledTableCell>S/N</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Subject</StyledTableCell>
                <StyledTableCell>Assignment Title/Description</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Mark</StyledTableCell>
                <StyledTableCell>Subject Teacher</StyledTableCell>
                <StyledTableCell>Class</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
              {filteredAssignment?.map((row, index) => (
                <StyledTableRow
                  key={index}
                  style={
                    index % 2
                      ? { background: "rgba(103, 30, 120, 0.1)" }
                      : { background: "white" }
                  }
                >
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell>
                    {typeof row.post_date === "string"
                      ? new Date(row.post_date).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "2-digit",
                        })
                      : ""}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.subject ? row.subject : "--"}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Tooltip title={row?.description}>
                      {truncateString(row?.title, 10)} /{" "}
                      {truncateString(row?.description, 20) ?? "--"}
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.status === 2 ? (
                      <span className="bg-[#ff00001a] text-[#ff0000] px-3 py-1 rounded-lg">
                        Closed
                      </span>
                    ) : (
                      <span className="bg-[#13f06673] text-[#035703fc] px-3 py-1 rounded-lg ">
                        Open
                      </span>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.score ? row.score : "--"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.subjectTeacher ? row.subjectTeacher : "--"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {" "}
                    {classList(row.assignClasses)}
                  </StyledTableCell>
                  <StyledTableCell>
                    <div onClick={() => setAssignmentValues(row)}>
                      <IconButton
                        onClick={(e) => handleClick(e, row.subject, row)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
            {/* {filteredAssignment && filteredAssignment?.length !== 0 && (
              <TableFooter sx={{ border: "1x solid red" }}>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={4}
                    count={filteredAssignment?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )} */}
          </Table>
        </TableContainer>
        {(filteredAssignment?.length === 0 ||
          filteredAssignment === undefined) && (
          <>
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                height: "300px",
                // border: "1px solid red",
              }}
            >
              <Typography sx={{ fontFamily: "Gilroy-Regular" }}>
                No Assignment Available
              </Typography>
            </Box>
          </>
        )}
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem
              sx={{ fontFamily: "Gilroy-Regular" }}
              onClick={handleOpenClass}
            >
              Status
            </MenuItem>
            <Link
              to={`/assignments/add-assignment/${assignmentValues?.assignmentId}?subject=${assignmentValues?.subject}&title=${assignmentValues?.title}&isActivate=${assignmentValues?.isActivate}`}
            >
              <MenuItem sx={{ fontFamily: "Gilroy-Regular" }}>
                Manage Assignment
              </MenuItem>
            </Link>
            <MenuItem
              sx={{ fontFamily: "Gilroy-Regular" }}
              onClick={() => {
                setOpenEdit(true);
                setAnchorEl(null);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem onClick={handleOpenDelete}>Delete</MenuItem>
            <MenuItem onClick={handleActivateModal}>
              Activate/Deactivate
            </MenuItem>
            <Link
              to={`/assignments/submission/${assignmentValues?.assignmentId}?score=${assignmentValues?.score}&isClosed=${assignmentValues?.isClosed}`}
            >
              <MenuItem sx={{ fontFamily: "Gilroy-Regular" }}>
                Submission
              </MenuItem>
            </Link>
          </MenuList>
        </Box>
      </Popover>

      {/* ......///////////////....................Modal..........///////////////......... */}

      <StatusModal
        open={openAddClass}
        onClose={handleCloseAddClass}
        assignmentValues={assignmentValues}
      />
      <Edit
        open={openEdit}
        onClose={handleEdit}
        id={assignmentValues?.assignmentId}
        subject={subject}
        data={editData}
        refetch={refetch}
      />
      <AssignStaff open={openAssign} onClose={handleAssign} />
      <ActivateDeactivate
        handleActivate={handleActivate}
        handleActivateModal={handleActivateModal}
        data={assignmentValues}
        handleDeactivate={handleDeactivate}
        activateDeactivate={activateDeactivate}
        loading={loading}
        styleDelete={styleDelete}
      />
      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Box sx={styleDelete}>
          <Box align="center">
            <RiDeleteBin6Line style={{ fontSize: "42px", color: "#d3302f" }} />
            <Box sx={{ mt: 3 }}>
              <Typography sx={{ fontFamily: "gilroy-bold" }}>
                You're about to delete Question {assignmentValues?.assignmentId}
              </Typography>
            </Box>
            <Typography
              sx={{
                fontFamily: "gilroy-regular",
                color: "#9c9c9c",
                fontSize: "12px",
              }}
            >
              This will delete the Question from the database
            </Typography>
            <Typography
              sx={{
                fontFamily: "gilroy-regular",
                color: "#9c9c9c",
                fontSize: "12px",
                mt: 2,
              }}
            >
              Are you sure?
            </Typography>
          </Box>
          <Box align="right" sx={{ mt: 2 }}>
            <Button
              sx={{
                color: "#333",
                textTransform: "initial",
                fontFamily: "gilroy-regular",
              }}
              onClick={handleCloseDelete}
            >
              Cancel
            </Button>
            <Button
              onClick={deleteAssignment}
              sx={{
                bgcolor: "#d3302f",
                color: "#fff",
                ml: 2,
                px: 3,
                textTransform: "initial",
                fontFamily: "gilroy-regular",
                "&:hover": {
                  bgcolor: "#d3302fb7",
                  color: "#fff",
                },
              }}
            >
              Delete
            </Button>
          </Box>
        </Box> */}
        <Box style={DeleteModalStyle} className="bg-white  p-[20px]">
          <Box align="right">
            <IconButton onClick={handleCloseDelete}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box className="items-center flex flex-col justify-center">
            <Typography
              sx={{ textAlign: "center", mb: 2, fontFamily: "Gilroy-Medium" }}
            >
              You can not delete this assignment because questions have been
              added, activated or submitted
            </Typography>
          </Box>
        </Box>
      </Modal>
      {success && (
        <>
          <Snackbar open={true} autoHideDuration={3000}>
            <Alert
              severity="success"
              sx={{ width: "100%", fontFamily: "gilroy-regular" }}
            >
              Deleted successfully
            </Alert>
          </Snackbar>
        </>
      )}
    </Box>
  );
};

export default Assignment_First;

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
