import React from "react";
import Title from "../../components/Dashboard/Title";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import CreateParent from "../../components/parent_mgt/CreateParent";
import ParentList from "../../components/parent_mgt/ParentList";
const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ParentsMgt = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <ThemeProvider theme={darkTheme}>
      <Box>
        <Title title="Parent Management" />

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label=" Create Parent"
                {...a11yProps(0)}
                sx={{
                  textTransform: "initial",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "18px",
                }}
              />
              <Tab
                label="Parent-Student link up"
                {...a11yProps(1)}
                sx={{
                  textTransform: "initial",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "18px",
                }}
              />
            </Tabs>
          </Box>

          {value === 0 && <CreateParent />}

          {value === 1 && <ParentList />}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ParentsMgt;
