import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { AuthButton } from "../auth/Login.style";
import { BsArrowLeftShort } from "react-icons/bs";
import { GoBackDiv } from "./stu_mgt.style";
import CreateStudentList from "../../components/StudentMgt/CreateStudentList";
import CreateStudentForm from "../../components/StudentMgt/CreateStudentForm";
import { useSelector } from "react-redux";
import { checkAccess } from "../../services/utils";
const CreateStudent = () => {
  const [showForm, setShowForm] = useState(false);
  const isTab = useMediaQuery({ maxWidth: 992 });
  const [queryId, setQueryId] = useState("");
  const { accessObj } = useSelector((state) => state.auth);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: !showForm ? "end" : "start",
        }}
      >
        {!showForm ? (
          checkAccess(accessObj.rights, "Create") && (
            <AuthButton
              mt="0.6rem"
              pl="0px"
              width={isTab ? "50%" : "30%"}
              onClick={() => {
                setShowForm(!showForm);
                // setSchoolId(user.schoolId);
              }}
            >
              <div>
                <span>Add Student</span>
              </div>
              {/* Send Code */}
            </AuthButton>
          )
        ) : (
          <GoBackDiv
            onClick={() => {
              setQueryId("");
              setShowForm(!showForm);
            }}
            style={{
              cursor: "pointer",
              marginTop: "0.3rem",
            }}
          >
            <div>
              <BsArrowLeftShort />
            </div>
            <span>Back To Student List</span>
          </GoBackDiv>
        )}
      </div>
      <>
        {!showForm && (
          <CreateStudentList setID={setQueryId} showFormFunc={setShowForm} />
        )}
      </>
      <>
        {showForm && (
          <CreateStudentForm
            setID={setQueryId}
            queryId={queryId}
            setShowForm={setShowForm}
            // showButton={}
          />
        )}
      </>
    </div>
  );
};

export default CreateStudent;
