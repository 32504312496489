import axios from "axios";
const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
const api_version = process.env.REACT_APP_API_VERSION;

const dashboardApi = {
  getDashboardCount: (token, departmentId, schoolId) => {
    axios.get(
      `${endpoint_two}/api/v${api_version}/Dashboard/GetDashboardCount?departmentId=${departmentId}&schoolId=${schoolId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
};

export default dashboardApi;
