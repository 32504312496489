import { Box } from "@mui/system";
import React, { useState } from "react";
import CPNavbar from "../../components/CPLayouts/CPNavBar";
import { DashboardHeader } from "../../components/Dashboard";
import { LasmabData } from "../../assets/data/ControlPanelData";
import WebInfo from "../../components/ControlPanel/Lasmab/WebInfo";
import Grid from "../../components/ControlPanel/Lasmab/Grid";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkAccess } from "../../services/utils";
const LasmabSetup = () => {
  const { accessObj } = useSelector((state) => state.auth);
  const location = useLocation();
  const page_id = Number(new URLSearchParams(location.search).get("page")) || 0;
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CPNavbar
        controlNav={() => {
          if (open === true) {
            setOpen(false);
          }
        }}
        isOpen={open}
        title="Lasmab SetUp"
        navData={LasmabData}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { lg: "90%", md: "90%", sm: "100%", xs: "100%" },
        }}
      >
        <DashboardHeader title="Lasmab Setup" navData={LasmabData} />
        <Box mt={14} sx={{ mx: "auto", width: isMobile ? "95%" : "85%" }}>
          {/* <Outlet /> */}
          {checkAccess(accessObj.menuItems, "Webpage Setup") &&
            checkAccess(accessObj.menuItems, "Web Info") &&
            page_id === 1 && <WebInfo />}
          {checkAccess(accessObj.menuItems, "Webpage Setup") &&
            checkAccess(accessObj.menuItems, "Grid") &&
            page_id === 2 && <Grid />}
        </Box>
      </Box>
    </Box>
  );
};

export default LasmabSetup;
