import React, { useState } from "react";
import {
  AccountSummaryWrapper,
  BigHeader,
  Icon,
  Input,
  Search,
  SmallHeader,
  TabContainer,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, MenuItem, MenuList } from "@mui/material";
import Popover from "@mui/material/Popover";
import { Box } from "@mui/system";
import { AuthButton, InputWrapper } from "../../pages/auth/Login.style";
import { BsSearch, BsFillPrinterFill } from "react-icons/bs";
import { SiAdobeacrobatreader } from "react-icons/si";
import { AiFillFileExcel } from "react-icons/ai";
import Modal from "../modal/Modal";
import Select from "../select/select";
const Deductions = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [opt, setOpt] = useState("");

  const optionsArr = [
    {
      id: 1,
      name: "Value 1",
    },
    {
      id: 2,
      name: "Value 2",
    },
  ];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }}>Add New Deduction</BigHeader>
      <div>
        <InputWrapper>
          <label htmlFor="allowance">Deduction</label>
          <input
            type="text"
            placeholder="Please Enter New Deduction"
            value={"Birthday Deduction"}
            onChange={(e) => console.log(e.target.value)}
          />
        </InputWrapper>

        <InputWrapper>
          <label htmlFor="">Deduction Type</label>
          <Select
            title="Type"
            index="name"
            selected={"Value 1"}
            options={optionsArr}
            setSelected={(val) => {
              console.log(val);
              setOpt(val.name);
            }}
          />
        </InputWrapper>
      </div>

      <div>
        <AuthButton width="100%" mt="0.6rem">
          Add New Deduction
        </AuthButton>
      </div>
    </>
  );

  return (
    <>
      {showModal && (
        <Modal content={content} size={"large"} onClose={setShowModal} />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SmallHeader style={{ color: "black", fontWeight: "bold" }}>
          Deduction
        </SmallHeader>
        <AuthButton
          onClick={() => setShowModal(!showModal)}
          style={{ marginTop: "0px" }}
        >
          <div>
            <span>Add New Deduction</span>
          </div>
        </AuthButton>
      </div>

      <TabContainer style={{ marginTop: "1rem" }}>
        <TabLinkContainer bg="#F0E8F1" style={{ position: "relative" }}>
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search" />
          </Input>
          <div style={{ display: "inline-flex" }} className="div">
            <AiFillFileExcel
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
            <SiAdobeacrobatreader
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
            <BsFillPrinterFill
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
          </div>
        </TabLinkContainer>

        <AccountSummaryWrapper pt="0px">
          <table style={{ borderRadius: "0px" }}>
            <tr
              className="table-head"
              style={{ backgroundColor: "#F0E8F1", color: "black" }}
            >
              <th>Deductions </th>

              <th>Deductions Type</th>
              <th>Date Created</th>
              <th>Created By</th>

              <th></th>
            </tr>
            <tr>
              <td>Tax</td>
              <td>Percentage</td>
              <td>21/02/2022</td>
              <td>John Doe</td>
              <td>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              </td>
            </tr>
            <tr>
              <td>NHIS</td>
              <td>Fixed</td>
              <td>21/02/2022</td>
              <td>John Doe</td>
              <td>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              </td>
            </tr>
          </table>
        </AccountSummaryWrapper>
      </TabContainer>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem>Edit Allowance</MenuItem>
            <MenuItem>Deactivate</MenuItem>
            <MenuItem>Delete</MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </>
  );
};

export default Deductions;
