import React, { useState, useCallback, useEffect } from "react";
import { Navbar, Header } from "../../components/Single";
import { Box } from "@mui/system";
import { PDNavData } from "../../assets/data/PDNavData";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import {
  Profile,
  Admission,
  Students,
  OnlinePayment,
  Invoices,
  Receipts,
} from "../../components/SingleParent";
import { useSelector } from "react-redux";
import { getParentProfile } from "../../services/ParentStudent";
const SingleParent = () => {
  const { admToken } = useSelector((state) => state.auth);
  const location = useLocation();
  const isPhone = useMediaQuery({ maxWidth: 768 });
  const page_id = Number(new URLSearchParams(location.search).get("page")) || 1;
  const parentId = new URLSearchParams(location.search).get("parent_id") || "";
  const [loading, setLoading] = useState(true);
  const [obj, setObj] = useState(null);
  const getData = useCallback(async () => {
    try {
      const res = await getParentProfile(admToken, parentId);
      res.status && res.statusCode === 2 && setObj(res.data);
      console.log(res);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [admToken, parentId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar navData={PDNavData} pathname="/parentmgt" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { lg: "90%", md: "90%", sm: "100%", xs: "1005" },
        }}
      >
        <Header navData={PDNavData} pathname="/parentmgt" />

        <Box mt={14} sx={{ mx: "auto", width: isPhone ? "95%" : "85%" }}>
          {page_id === 1 && <Profile obj={obj} loading={loading} />}
          {page_id === 2 && <Admission />}
          {page_id === 3 && (
            <Students
              param={parentId}
              token={admToken}
              name={obj ? `${obj.title} ${obj.fullName}` : ""}
            />
          )}
          {page_id === 4 && <OnlinePayment />}
          {page_id === 5 && <Invoices />}
          {page_id === 6 && <Receipts />}
        </Box>
      </Box>
    </Box>
  );
};

export default SingleParent;
