import { Grid, createTheme, ThemeProvider } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect, useCallback } from "react";
import "./styles.css";
import Title from "../../components/Dashboard/Title";
import Card from "../../components/Dashboard/Card";
import api from "../../services/ControlPanel";
import Loader from "../../components/pageLoader/loader";
import { ImStatsBars } from "react-icons/im";
import { TeacherClasses } from "../../services/Subject";
import TextField from "@mui/material/TextField";
import { getAccountProfile } from "../../features/auth/authSlice";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ErrorService from "../../services/errorService";
import LoaderLine from "../../components/LoaderLine";
import dashboardApi from "../../services/dashboard";

const theme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
  },
});

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const [departId, setDepartId] = useState("");
  const [cateName, setCateName] = useState("");
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([]);
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [allDashboardCounts, setAllDashboardCounts] = useState({});
  const { accessObj, user, admToken, isLoading } = useSelector(
    (state) => state.auth
  );
  const getClasses = useCallback(
    async (id) => {
      try {
        //  setLoadClass(true);
        const res = await TeacherClasses(admToken, id);
        if (res.status && res.statusCode === 2) {
          setClasses(res.data);
        }
        if (!res.status) {
          ErrorService.displayErrorAlert(res);
        }

        if (res.status === 400 || res.status === 500) {
          ErrorService.displayErrorAlert(res.data);
        }
        //  setLoadClass(false);
      } catch (error) {
        //  setLoadClass(false);
        setLoading(false);
        console.log(error);
      }
    },
    [admToken]
  );

  const assignDefaultSchool = useCallback(
    async (id) => {
      toast.info("Please wait...");
      try {
        const res = await api.assignDefaultSchoolToStaff(
          admToken,
          id,
          user.staffId
        );
        if (res.status === true && res.statusCode === 2) {
          toast.success(res.message);
          dispatch(getAccountProfile(admToken));
          getClasses(id);
        }

        if (res.status === 400 || res.status === 500 || res.status === false) {
          ErrorService.displayErrorAlert(res);
        }
      } catch (error) {
        toast.error("An Error Occurred");
      }
    },
    [admToken, dispatch, getClasses, user.staffId]
  );
  const assignDefaultClass = useCallback(
    async (id) => {
      toast.info("Please wait...");
      try {
        const res = await api.assignDefaultClassToStaff(
          admToken,
          user.schoolId,
          user.staffId,
          id
        );
        if (res.status === true && res.statusCode === 2) {
          toast.success(res.message);
          dispatch(getAccountProfile(admToken));
        }

        if (res.status === 400 || res.status === 500 || res.status === false) {
          ErrorService.displayErrorAlert(res);
        }
      } catch (error) {
        toast.error("An Error Occurred");
      }
    },
    [admToken, dispatch, user.schoolId, user.staffId]
  );
  const getSelectedSchoolFunc = useCallback(async () => {
    try {
      const res = await api.getSelectedSchool(
        admToken,
        user.phoneNumber,
        accessObj?.privileges?.some((obj) => obj.id === 1) ? 1 : 2
      );
      if (res.status === true && res.statusCode === 2) {
        setSelectedSchools(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken, user.phoneNumber, accessObj?.privileges]);

  const getSelectedDepartFunc = useCallback(async () => {
    try {
      const res = await api.getSelectedDepartment(
        admToken,
        user.phoneNumber,
        accessObj?.privileges?.some((obj) => obj.id === 1) ? 1 : 3,
        user.schoolId
      );
      if (res.status === true && res.statusCode === 2) {
        setSelectedDepartments(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken, user.phoneNumber, user.schoolId, accessObj?.privileges]);

  const getDepartmentDashboardCount = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const res = await api.dashboardCount(admToken, user.phoneNumber, id);
        if (res.status && res.statusCode === 2) {
          setStats(res.data);
          if (res.data.length === 0) {
            toast.error("No Dashboard Stats Assigned To You At The Moment");
            setDepartId("");
            setCateName("");
          }
        }
        if (!res.status && res.statusCode === 6) {
          toast.error(res.message);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.log(error);
      }
    },
    [user, admToken]
  );

  const getDashboardCount = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const res = await dashboardApi.getDashboardCount(
          admToken,
          id,
          user.schoolId
        );
        if (res.status && res.statusCode === 2) {
          setAllDashboardCounts(res.data);
        }
        if (!res.status && res.statusCode === 6) {
          toast.error(res.message);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
    [admToken, user.schoolId]
  );

  useEffect(() => {
    getSelectedSchoolFunc();
    getSelectedDepartFunc();
    getClasses(user.schoolId);
  }, [getSelectedSchoolFunc, getSelectedDepartFunc, getClasses, user.schoolId]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{}}>
          <Title />
          {isLoading && <LoaderLine />}
          {selectedSchools.length > 0 && (
            <Box sx={{ display: "", marginTop: "0.3rem" }}>
              <Grid container spacing={2}>
                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth>
                    <label htmlFor="">Choose School</label>

                    <Select
                      sx={{ fontFamily: "Gilroy-Regular" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      variant="outlined"
                      size="small"
                      value={user.schoolId.toString()}
                      onChange={(e) => {
                        assignDefaultSchool(e.target.value);
                        // setSchool(e.target.value);
                      }}
                    >
                      {selectedSchools.length > 0 &&
                        selectedSchools.map((item, index) => (
                          <MenuItem
                            key={index}
                            sx={{ fontFamily: "Gilroy-Regular" }}
                            value={item.schoolid}
                          >
                            {item.schoolname}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth>
                    <label htmlFor="">Choose Department</label>
                    <Select
                      sx={{ fontFamily: "Gilroy-Regular" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      variant="outlined"
                      size="small"
                      value={departId.toString()}
                      onChange={(e) => {
                        setDepartId(e.target.value);
                        if (e.target.value === "") {
                          setSelectedDepartments([]);
                        }
                        let name = selectedDepartments.find(
                          (item) => e.target.value === item.departmentId
                        ).categoryName;

                        setCateName(name);
                        getDepartmentDashboardCount(e.target.value);
                        getDashboardCount(e.target.value);
                      }}
                    >
                      <MenuItem
                        sx={{ fontFamily: "Gilroy-Regular" }}
                        value={""}
                      >
                        Please Select Department
                      </MenuItem>
                      {selectedDepartments.map((item, index) => (
                        <MenuItem
                          sx={{ fontFamily: "Gilroy-Regular" }}
                          value={item.departmentId}
                          key={index}
                        >
                          {item.departmentName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {cateName.length > 0 && (
                  <Grid item lg={3} xs={12}>
                    <FormControl fullWidth>
                      <label htmlFor="">Category</label>

                      <TextField
                        type="text"
                        value={cateName}
                        id="outlined-basic"
                        variant="outlined"
                        sx={{
                          fontFamily: "Gilroy-Regular",
                        }}
                        labelId="demo-simple-select-label"
                        size="small"
                        disabled
                      />
                    </FormControl>
                  </Grid>
                )}

                {classes.length > 0 && (
                  <Grid item lg={3} xs={12}>
                    <FormControl fullWidth>
                      <label htmlFor="">Choose Class</label>

                      <Select
                        sx={{ fontFamily: "Gilroy-Regular" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        size="small"
                        value={user.classId.toString()}
                        onChange={(e) => {
                          assignDefaultClass(e.target.value);
                          // setSchool(e.target.value);
                        }}
                      >
                        {classes.length > 0 &&
                          classes.map((item, index) => (
                            <MenuItem
                              key={index}
                              sx={{ fontFamily: "Gilroy-Regular",
                                fontWeight: 'bold'
                               }}
                              value={item.classId}
                            >
                              <div className="font-[600]">
                              {item.className} {item.classArm}
                              </div>
                              
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
          {loading ? (
            <Loader />
          ) : (
            <Grid container spacing={2} mt={3}>
              {selectedDepartments.length > 0 &&
                stats.map((item, index) => (
                  <Grid item lg={4} sm={6} xs={12} key={index}>
                    <Card
                      label={item.name}
                      value={item.count}
                      icon=<ImStatsBars />
                    />
                  </Grid>
                ))}
            </Grid>
          )}
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Dashboard;
