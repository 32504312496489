import { useState, useCallback, useEffect } from "react";
import { TabLink, TabLinkContainer } from "../Dashboard/styles/dashboard.style";
import { useMediaQuery } from "react-responsive";
import colors from "../../assets/colors.json";
import {
  Category,
  LibrarySection,
  Shelf,
  Subject,
  Vendor,
  Class,
} from "../../components/Library";
import ErrorService from "../../services/errorService";
import { useSelector } from "react-redux";
import { getSchools } from "../../services/MySchool";
const LibrarySetup = () => {
  const { admToken } = useSelector((state) => state.auth);
  const isTab = useMediaQuery({ maxWidth: 992 });
  const [tab, setTab] = useState(2);
  const [arr, setArr] = useState([]);
  const [listLoading, setListLoading] = useState(false);

  const getAllSchools = useCallback(async () => {
    try {
      const res = await getSchools(admToken);
      res.status && res.statusCode === 2 && setArr(res.data);
      (res.status === 500 || res.status === 400) &&
        ErrorService.displayErrorAlert(res.data);
      console.log(res);
      setListLoading(false);
    } catch (error) {
      console.log(error);
      setListLoading(false);
    }
  }, [admToken]);
  useEffect(() => {
    getAllSchools();
  }, [getAllSchools]);
  return (
    <div>
      <TabLinkContainer
        style={{
          justifyContent: isTab ? "space-around" : "start",
          marginTop: "0.3rem",
        }}
        wd={isTab ? "100%" : "fit-content"}
        bg={colors.secondary}
        brtl={"5px"}
        brtr={"5px"}
        brbl={"5px"}
        brbr={"5px"}
      >
        <TabLink
          isActive={tab === 1}
          onClick={() => setTab(1)}
          mwWidth="fit-content"
          minWidth="fit-content"
          style={{ marginRight: !isTab ? "0.4rem" : "" }}
        >
          Library Section
        </TabLink>
        <TabLink
          isActive={tab === 2}
          onClick={() => setTab(2)}
          mwWidth="fit-content"
          style={{ marginRight: !isTab ? "0.4rem" : "" }}
          minWidth="fit-content"
        >
          Shelf
        </TabLink>{" "}
        <TabLink
          isActive={tab === 3}
          onClick={() => setTab(3)}
          mwWidth="fit-content"
          minWidth="fit-content"
          style={{ marginRight: !isTab ? "0.4rem" : "" }}
        >
          Category
        </TabLink>
        <TabLink
          isActive={tab === 4}
          onClick={() => setTab(4)}
          mwWidth="fit-content"
          minWidth="fit-content"
          style={{ marginRight: !isTab ? "0.4rem" : "" }}
        >
          Vendor
        </TabLink>{" "}
        <TabLink
          isActive={tab === 5}
          onClick={() => setTab(5)}
          mwWidth="fit-content"
          minWidth="fit-content"
          style={{ marginRight: !isTab ? "0.4rem" : "" }}
        >
          Subject
        </TabLink>{" "}
        <TabLink
          isActive={tab === 6}
          onClick={() => setTab(6)}
          mwWidth="fit-content"
          minWidth="fit-content"
          style={{ marginRight: !isTab ? "0.4rem" : "" }}
        >
          Class
        </TabLink>
      </TabLinkContainer>
      {tab === 1 && <LibrarySection arr={arr} loading={listLoading} />}
      {tab === 2 && <Shelf arr={arr} loading={listLoading} />}
      {tab === 4 && <Vendor arr={arr} loading={listLoading} />}
      {tab === 5 && <Subject arr={arr} loading={listLoading} />}
      {tab === 3 && <Category arr={arr} loading={listLoading} />}
      {tab === 6 && <Class arr={arr} loading={listLoading} />}
    </div>
  );
};
export default LibrarySetup;
