/* eslint-disable eqeqeq */
import { toast } from "react-toastify";

export const paginateData = (
  data,
  setList,
  currentPage,
  offSet,
  setOffSet,
  setPageCount,
  itemsPerPage = 40
) => {
  if (data.length > 0) {
    // eslint-disable-next-line no-unused-vars
    const endOffSet = offSet + itemsPerPage;
    const newOffSet = (currentPage * itemsPerPage) % data.length;
    const trimStart = (currentPage - 1) * itemsPerPage;
    const trimEnd = trimStart + itemsPerPage;
    const arr = data.slice(trimStart, trimEnd);
    setList(arr);
    setOffSet(newOffSet);
  } else {
    setPageCount(0);
    setList([]);
  }
  setPageCount(Math.ceil(data.length / itemsPerPage));
};

export const isNumber = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    evt.preventDefault();
  } else {
    return true;
  }
};

export const validateEmail = (txt, name = "Email") => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  let value = regex.test(txt);
  if (txt === "") {
    toast.error(`${name} Address is empty`);
    return;
  }
  if (!value) {
    toast.error(`${txt.toUpperCase()} is not a valid Email Address`);
  }
  return value;
};

export const validateMobileNumber = (txt) => {
  const nigeriaMobileNumberRegex = /^(\+?234|0)?[789]\d{9}$/; // matches Nigerian phone numbers
  let value = nigeriaMobileNumberRegex.test(txt);

  if (txt === "") {
    toast.error(`You've not provided a Phone Number in one of the form above`);
    return;
  }
  if (!value) {
    toast.error(`${txt} is not a valid Nigerian Phone Number`);
  }
  return value;
};

export const isObjEmpty = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] === "") {
      return false;
    }
  }
  return true;
};

export const checkAccess = (arr, str) => {
  if (Array.isArray(arr)) {
    let filterArr = arr.filter((item) => item !== null);
    for (const obj of filterArr) {
      for (const value of Object.values(obj)) {
        // if (typeof value === "string" && value.includes(str)) {
        // console.log(typeof value);
        if (
          (typeof value === "string" || typeof value === "number") &&
          value == str
        ) {
          return true;
        }
      }
    }
    return false;
  }
  return false;
};
