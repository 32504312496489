import React, { useState } from "react";
import colors from "../../assets/colors.json";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/system";
import {
  AccountSummaryWrapper,
  Icon,
  Input,
  Search,
  SmallHeader,
  TabContainer,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import Popover from "@mui/material/Popover";
import PropTypes from "prop-types";

import { SmallText } from "../../pages/auth/Login.style";
import { Grid, IconButton, MenuItem, MenuList } from "@mui/material";
import Card from "../Dashboard/Card";
import { FaBabyCarriage, FaRegHandshake } from "react-icons/fa";
import { BsFillJournalBookmarkFill, BsSearch } from "react-icons/bs";
import { MdOutlineMedicalServices } from "react-icons/md";
import { StatusBar } from "../../pages/student_mgt/stu_mgt.style";
const RequestLeave = ({ showOnlyList }) => {
  const [tab, setTab] = useState(showOnlyList ? 2 : 1);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      {!showOnlyList && (
        <div
          style={{
            display: "inline-flex",
            marginTop: "1.1rem",
          }}
        >
          <div
            role="button"
            style={{
              marginRight: "0.95rem",
              fontWeight: tab === 1 ? "bold" : "500",
              color: tab === 1 ? colors.primary : colors.gray,
              borderBottom: tab === 1 ? `1px solid ${colors.primary}` : "",
              paddingBottom: tab === 1 ? "5px" : "",
            }}
            onClick={() => setTab(1)}
          >
            <p>Leave Type</p>
          </div>

          <div
            role="button"
            style={{
              marginRight: "0.95rem",
              fontWeight: tab === 2 ? "bold" : "500",
              color: tab === 2 ? colors.primary : colors.gray,
              borderBottom: tab === 2 ? `1px solid ${colors.primary}` : "",
              paddingBottom: tab === 2 ? "5px" : "",
            }}
            onClick={() => setTab(2)}
          >
            <p>Leave History</p>
          </div>
        </div>
      )}

      <div style={{ marginTop: "0.7rem" }}>
        {tab === 1 && (
          <>
            <SmallHeader
              style={{
                fontWeight: "bold",
                color: "black",
              }}
            >
              Available Leave Types
            </SmallHeader>

            <Grid container spacing={2} padding={3}>
              <Grid item lg={6} xs={12}>
                <Card
                  smallHeading="Maternity Leave"
                  value="5"
                  label="Working days"
                  icon=<FaBabyCarriage />
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Card
                  smallHeading="Annual Leave"
                  value="5"
                  label="Working days"
                  icon=<FaBabyCarriage />
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Card
                  smallHeading="Compassionate Leave"
                  value="5"
                  label="Working days"
                  icon=<BsFillJournalBookmarkFill />
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Card
                  smallHeading="Medical Leave"
                  value="5"
                  label="Working days"
                  icon=<MdOutlineMedicalServices />
                />
              </Grid>
            </Grid>
          </>
        )}

        {tab === 2 && (
          <>
            <TabContainer style={{ paddingBottom: "0px" }}>
              <TabLinkContainer bg="#EEE0F1">
                <Input>
                  <Icon>
                    <BsSearch />
                  </Icon>
                  <Search type="text" placeholder="Search" />
                </Input>
              </TabLinkContainer>
              <AccountSummaryWrapper pt="0px">
                <table style={{ borderRadius: "0px" }}>
                  <tr
                    className="table-head"
                    style={{ backgroundColor: "#F0E8F1", color: "black" }}
                  >
                    <th>Type</th>
                    <th>Duration</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Status</th>
                    <th>Applied on </th>
                    <th>Relief Officer</th>
                    <th></th>
                  </tr>
                  <tr>
                    <td>Annual Leave</td>
                    <td>2</td>
                    <td>April 20th, 2023</td>
                    <td>April 23th, 2023</td>
                    <td>
                      <StatusBar>Approve</StatusBar>
                    </td>
                    <td>April 10th, 2023</td>
                    <td>Mr John James</td>
                    <td>
                      <IconButton onClick={handleClick}>
                        <MoreVertIcon />
                      </IconButton>
                    </td>
                  </tr>
                </table>
              </AccountSummaryWrapper>
            </TabContainer>

            <div
              style={{
                display: "flex",
                marginTop: "1rem",
                justifyContent: "end",
                padding: "10px 20px",
              }}
            >
              <button
                style={{
                  borderRadius: "8px",
                  // padding: "8px",
                  minWidth: "121px",
                  color: "white",
                  backgroundColor: colors.primary,
                  cursor: "pointer",
                  lineHeight: "2.125rem",
                  border: `1px solid ${colors.primary}`,
                  outline: "none",
                  fontWeight: "bold",
                }}
              >
                Approve
              </button>
              <button
                style={{
                  marginLeft: "0.5rem",
                  borderRadius: "8px",
                  fontSize: "16px",
                  // padding: "8px",
                  minWidth: "121px",
                  backgroundColor: "white",
                  color: colors.primary,
                  cursor: "pointer",
                  lineHeight: "2.125rem",
                  border: `1px solid ${colors.primary}`,
                  outline: "none",
                  fontWeight: "bold",
                }}
              >
                Delete
              </button>
            </div>
          </>
        )}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem>View Certificate</MenuItem>
            <MenuItem>Download Handover note</MenuItem>
            <MenuItem>Download Proof</MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </>
  );
};

RequestLeave.propTypes = {
  showOnlyList: PropTypes.bool,
};

RequestLeave.defaultProps = {
  showOnlyList: false,
};

export default RequestLeave;
