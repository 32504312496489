import React from "react";
import {
  Modal,
  Box,
  LinearProgress,
  createTheme,
  ThemeProvider,
} from "@mui/material";

const style = {
  width: "100%",
  border: "none",
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#671e78",
    },
  },
});
const LoaderLine = () => {
  const [open, setOpen] = React.useState(true);

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <LinearProgress />
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default LoaderLine;
