import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { API } from "./constant";

export const baseQuery = fetchBaseQuery({
  baseUrl: API.BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.admToken;
    if (!headers.has("Authorization") && token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});