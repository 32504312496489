import React, { useState } from "react";
import colors from "../../assets/colors.json";
import {
  TabLink,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import { useMediaQuery } from "react-responsive";
import UpdateScore from "../../components/StudentMgt/UpdateScore";
import Remarks from "../../components/StudentMgt/Remarks";
import Promotion from "../../components/StudentMgt/Promotion";
import Psychomotor from "../../components/StudentMgt/Psychomotor";
// import Report from "../../components/StudentMgt/Report";
import { checkAccess } from "../../services/utils";
import { useSelector } from "react-redux";
import PastScore from "../../components/StudentMgt/PastScore";
import PastPsychomotor from "../../components/StudentMgt/PastPsychomotor";
import PastRemarks from "../../components/StudentMgt/PastRemarks";
import ResultReport from "../../components/StudentMgt/ResultReport";
const Result = () => {
  const { accessObj } = useSelector((state) => state.auth);
  const [tab, setTab] = useState(0);
  const isTab = useMediaQuery({ maxWidth: 992 });
  return (
    <div style={{}}>
      {(checkAccess(accessObj.menuItems, "Promotion") ||
        checkAccess(accessObj.menuItems, "Scores - Present") ||
        checkAccess(accessObj.menuItems, "Remarks - Present") ||
        checkAccess(accessObj.menuItems, "Psychomotor - Present") ||
        checkAccess(accessObj.menuItems, "Scores - Past") ||
        checkAccess(accessObj.menuItems, "Remarks - Past") ||
        checkAccess(accessObj.menuItems, "Psychomotor - Past") ||
        checkAccess(accessObj.menuItems, "Reports")) && (
        <TabLinkContainer
          style={{ marginTop: "10px", justifyContent: "start" }}
          wd={isTab ? "100%" : "100%"}
          bg={colors.secondary}
          brtl={"5px"}
          brtr={"5px"}
          brbl={"5px"}
          brbr={"5px"}
        >
          {checkAccess(accessObj.menuItems, "Scores - Present") && (
            <TabLink isActive={tab === 1} onClick={() => setTab(1)}>
              Update Score
            </TabLink>
          )}
          {checkAccess(accessObj.menuItems, "Psychomotor - Present") && (
            <TabLink isActive={tab === 5} onClick={() => setTab(5)}>
              Update Psychomotor
            </TabLink>
          )}

          {checkAccess(accessObj.menuItems, "Remarks - Present") && (
            <TabLink isActive={tab === 2} onClick={() => setTab(2)}>
              Update Remarks
            </TabLink>
          )}
          {checkAccess(accessObj.menuItems, "Scores - Past") && (
            <TabLink isActive={tab === 6} onClick={() => setTab(6)}>
              Past Score
            </TabLink>
          )}
          {checkAccess(accessObj.menuItems, "Psychomotor - Past") && (
            <TabLink isActive={tab === 7} onClick={() => setTab(7)}>
              Past Psychomotor
            </TabLink>
          )}
          {checkAccess(accessObj.menuItems, "Remarks - Past") && (
            <TabLink isActive={tab === 8} onClick={() => setTab(8)}>
              Past Remarks
            </TabLink>
          )}
          {checkAccess(accessObj.menuItems, "Promotion") && (
            <TabLink isActive={tab === 3} onClick={() => setTab(3)}>
              Promotion
            </TabLink>
          )}

          {checkAccess(accessObj.menuItems, "Reports") && (
            <TabLink isActive={tab === 4} onClick={() => setTab(4)}>
              Report
            </TabLink>
          )}
        </TabLinkContainer>
      )}

      {checkAccess(accessObj.menuItems, "Scores - Present") && tab === 1 && (
        <UpdateScore />
      )}
      {checkAccess(accessObj.menuItems, "Remarks - Present") && tab === 2 && (
        <Remarks />
      )}
      {checkAccess(accessObj.menuItems, "Promotion") && tab === 3 && (
        <Promotion />
      )}
      {checkAccess(accessObj.menuItems, "Reports") && tab === 4 && (
        <ResultReport />
      )}
      {checkAccess(accessObj.menuItems, "Psychomotor - Present") &&
        tab === 5 && <Psychomotor />}

      {checkAccess(accessObj.menuItems, "Scores - Past") && tab === 6 && (
        <PastScore />
      )}
      {checkAccess(accessObj.menuItems, "Psychomotor - Past") && tab === 7 && (
        <PastPsychomotor />
      )}
      {checkAccess(accessObj.menuItems, "Remarks - Past") && tab === 8 && (
        <PastRemarks />
      )}
    </div>
  );
};

export default Result;
