import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BsFillCalendar2WeekFill } from "react-icons/bs";

import {
  Calendar,
  WeekDays,
  WeekLabel,
  Days,
  EmptyDay,
  Day,
} from "./attcalendar.style";
import FormLoader from "../FormLoader";
import { SmallText } from "../../pages/auth/auth.style";
import { useSelector } from "react-redux";
import Pagination from "../pagination_one/pagination";

import moment from "moment";
import colors from "../../assets/colors.json";
import { AuthButton } from "../../pages/auth/Login.style";
const AttCalendarSetup = ({
  dates,
  session,
  term,
  pickDates,
  setPickDates,
  action,
  loading,
  allData,
}) => {
  const { user } = useSelector((state) => state.auth);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [reArr, setReArr] = useState([]);
  const [reObj, setReObj] = useState(null);
  const [days, setDays] = useState([]);

  const generateDays = useCallback(
    (i) => {
      let obj = reArr[i - 1];
      setReObj(Object.assign({}, obj));
      const month = obj.month - 1;
      const year = obj.year;
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      const firstDayOfMonth = new Date(year, month, 1).getDay();
      const days = [];

      for (let i = 0; i < firstDayOfMonth; i++) {
        days.push(<EmptyDay key={`empty=${i}`} />);
      }

      for (let i = 1; i <= daysInMonth; i++) {
        const dateInRange = dates.some(
          (item) =>
            Number(moment(new Date(item).toISOString()).format("D")) === i &&
            obj.month ===
              Number(moment(new Date(item).toISOString()).format("M")) &&
            obj.year ===
              Number(moment(new Date(item).toISOString()).format("Y"))
        );

        const isDatePicked = pickDates.some(
          (item) =>
            Number(
              moment(new Date(item.selectedDate).toISOString()).format("D")
            ) === i &&
            obj.month ===
              Number(
                moment(new Date(item.selectedDate).toISOString()).format("M")
              ) &&
            obj.year ===
              Number(
                moment(new Date(item.selectedDate).toISOString()).format("Y")
              )
        );

        const alreadySelected = allData.some(
          (item) =>
            Number(
              moment(new Date(item.attendanceDate).toISOString()).format("D")
            ) === i &&
            obj.month ===
              Number(
                moment(new Date(item.attendanceDate).toISOString()).format("M")
              ) &&
            obj.year ===
              Number(
                moment(new Date(item.attendanceDate).toISOString()).format("Y")
              )
        );

        days.push(
          <Day key={i} style={{ position: "relative", textAlign: "left" }}>
            <div
              style={{
                position: "absolute",
                width: "100%",
                top: 0,
                bottom: 0,
                backgroundColor: dateInRange ? "red" : "",
                left: 0,
                zIndex: 0,
                cursor: alreadySelected ? "not-allowed" : "",
              }}
            ></div>
            <div
              onClick={() => {
                const date = dates.find(
                  (item) =>
                    Number(moment(new Date(item).toISOString()).format("D")) ===
                      i &&
                    obj.month ===
                      Number(
                        moment(new Date(item).toISOString()).format("M")
                      ) &&
                    obj.year ===
                      Number(moment(new Date(item).toISOString()).format("Y"))
                );

                const index = pickDates.findIndex(
                  (item) => item.selectedDate === date
                );
                if (index !== -1) {
                  const updArr = [...pickDates];
                  updArr.splice(index, 1);
                  setPickDates(updArr);
                } else {
                  let obj = {
                    selectedDate: date,
                    attendanceName: moment(date).format("dddd, MMMM, DD YYYY"),
                  };
                  setPickDates([...pickDates, obj]);
                }
              }}
              style={{
                color: dateInRange ? "white" : "black",
                padding: "10px",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                bottom: 0,
                backgroundColor: alreadySelected
                  ? colors.primary
                  : isDatePicked
                  ? "green"
                  : "",
                cursor: alreadySelected
                  ? "not-allowed"
                  : dateInRange
                  ? "pointer"
                  : "",
                pointerEvents: alreadySelected ? "none" : "",
              }}
            >
              {i}
            </div>
          </Day>
        );
      }
      setDays(days);
    },
    [allData, dates, pickDates, reArr, setPickDates]
  );
  const func = useCallback((dateArray) => {
    const groupedDates = {};
    dateArray.forEach((date) => {
      const year = new Date(date).getFullYear();
      const month = new Date(date).getMonth() + 1; // Months are zero-indexed
      const key = `${year}-${month}`;

      if (!groupedDates[key]) {
        groupedDates[key] = {
          year: year,
          month: month,
          dates: [],
        };
      }

      groupedDates[key].dates.push(new Date(date).toISOString());
    });

    let result = Object.values(groupedDates);
    if (result.length > 0) {
      setPageCount(result.length);
      setReArr([...result]);
    }
  }, []);

  useEffect(() => {
    if (reArr.length > 0) {
      generateDays(currentPage);
    }
  }, [reArr, currentPage, generateDays, pickDates]);
  useEffect(() => {
    if (dates.length > 0) {
      func(dates);
    }
  }, [dates, func]);
  return (
    <>
      {dates.length > 0 && reObj !== null && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: colors.primary,
              padding: "0.5rem",
              paddingTop: "1.9rem",
              borderTopLeftRadius: "inherit",
              borderTopRightRadius: "inherit",
            }}
          >
            <div>
              <SmallText color="white" style={{ fontSize: "18px" }}>
                Attendance Setup
              </SmallText>
              <SmallText color="white">
                {term} {session}
              </SmallText>
            </div>
          </div>
          <div style={{ padding: "0.8rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0.5rem 0.9rem",
              }}
            >
              <SmallText style={{ fontSize: "16px" }}>
                {moment()
                  .month(Number(reObj.month) - 1)
                  .format("MMMM")}
              </SmallText>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  backgroundColor: colors.lightGray,
                  color: colors.gray,
                  fontSize: "10px",
                  padding: "0.3rem",
                  borderRadius: "10px",
                }}
              >
                <span style={{ marginRight: "0.4rem" }}>
                  {" "}
                  {moment()
                    .month(Number(reObj.month) - 1)
                    .format("MMM")}
                  , {reObj.year}
                </span>
                <BsFillCalendar2WeekFill />
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Calendar>
                <WeekDays>
                  <WeekLabel> Sun</WeekLabel>
                  <WeekLabel> Mon</WeekLabel>
                  <WeekLabel> Tue</WeekLabel>
                  <WeekLabel> Wed</WeekLabel>
                  <WeekLabel> Thu</WeekLabel>
                  <WeekLabel> Fri</WeekLabel>
                  <WeekLabel> Sat</WeekLabel>
                </WeekDays>
                <Days>{days}</Days>
              </Calendar>
            </div>
          </div>
          {pageCount > 1 && (
            <div
              style={{
                marginTop: "2px",
                marginBottom: "2px",
              }}
            >
              <Pagination
                pageCount={pageCount > 1 && pageCount}
                currentPage={currentPage - 1}
                range={3}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
          <div style={{ padding: "0.35rem 0.8rem" }}>
            <AuthButton
              width="100%"
              onClick={action}
              mt="0.3rem"
              disabled={pickDates.length === 0 || loading}
            >
              {loading ? <FormLoader /> : "Pick Dates"}
            </AuthButton>
          </div>
        </>
      )}
    </>
  );
};

AttCalendarSetup.propTypes = {
  dates: PropTypes.array.isRequired,
  session: PropTypes.string.isRequired,
  term: PropTypes.string.isRequired,
  pickDates: PropTypes.array.isRequired,
  allData: PropTypes.array.isRequired,
  setPickDates: PropTypes.func,
  action: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default AttCalendarSetup;
