import {
  Box,
  Button,
  Grid,
  InputLabel,
  Table,
  TableHead,
  TextField,
  TableCell,
  TableBody,
  TableRow,
  Select,
  MenuItem,
  Skeleton,
  Modal,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import React, { useState, useEffect } from "react";
import api from "../../../services/assignment";
import axios from "axios";
import LoaderLine from "../../LoaderLine";
import { useSelector } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSnackbar } from "notistack";
import colors from "../../../assets/colors.json";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 800, xs: 400 },
  height: { sm: 600, lg: "auto" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "1px solid #671E78",
  boxShadow: 24,
  p: 4,
};
const Data = [];

const Setup = ({ refetch }) => {
  // const adtoken =localStorage.getItem("admToken");
  const { enqueueSnackbar } = useSnackbar();
  const [subjects, setSubjects] = useState([]);
  const [subjectSelected, setSubjectSelected] = useState("");
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [score, setScore] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [subjectName, setSubjectName] = useState("");
  const [loading, setLoading] = useState(false);
  const [subjectID, setSubjectID] = useState(0);
  const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
  const api_version = process.env.REACT_APP_API_VERSION;
  const userInfo = useSelector((state) => state.auth.user);
  const adtoken = localStorage.getItem("admToken");
  const [subjectLoader, setSubjectLoader] = useState(false);
  const [classLoader, setClassLoader] = useState(false);
  const handleChange = (event) => {
    setSubjectSelected(event.target.value);
  };
  const handleClickVariant = (variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar("Assignment Created Successfully", { variant });
  };

  const handleClickError = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedClass(typeof value === "string" ? value.split(",") : value);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file from the input
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result; // This will contain the base64 data
        setSelectedFile(base64Data); // Set the base64 data using setSelectedFile
      };
      // Read the file as a data URL, which will give you the base64 representation
      reader.readAsDataURL(file);
    }
  };

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adtoken}`,
  };

  const fetchSubjects = async () => {
    setSubjectLoader(true);
    try {
      const response = await axios.get(
        `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/SubjectTeacher/MySubjects?schoolId=${userInfo.schoolId}`,
        { headers }
      );
      console.log("wawu", response);
      if (response.status !== 200) {
        setSubjectLoader(false);
        console.log("Error: Unable to fetch data", response);
        return;
      }

      const data = response.data;
      setSubjectLoader(false);
      if (!data?.status) {
        console.log("Error: Data is null or has no status property.");
        return;
      }
      setSubjects(data?.data); // Save the subjects data in the state
      console.log(subjects, "Walle");
    } catch (error) {
      setSubjectLoader(false);
      console.error("Error:", error.message);
    }
  };
  const fetchClasses = async () => {
    setClassLoader(true);
    try {
      const response = await axios.get(
        `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/SubjectTeacher/MySubjectClasses?subjectId=${subjectID}&schoolId=${userInfo.schoolId}`,
        { headers }
      );

      console.log("Error: Unable to fetch data", response);
      if (response.status !== 200) {
        setClassLoader(false);

        return;
      }
      setClassLoader(false);
      const data = response.data;
      if (!data?.status) {
        console.log("Error: Data is null or has no status property.");
        return;
      }
      setClassLoader(false);
      setClasses(data?.data); // Save the subjects data in the state
      // console.log(subjects, "Walle")
    } catch (error) {
      setClassLoader(false);
      console.error("Error:", error.message);
    }
  };
  useEffect(() => {
    fetchSubjects();
    if (subjectID) {
      fetchClasses(); // Call the fetchClasses function when subjectId changes
    }
  }, [subjectID]);

  const handleCreateAssignment = async () => {
    const assignClasses = selectedClass.map(
      ({ classId, className, classArm }) => ({
        classId,
        className,
        classArm,
      })
    );
    setLoading(true);
    const url = `${endpoint_two}/api/v${api_version}/Assignment/CreateAssignment`;

    const requestBody = {
      assignmentId: 0,
      schoolId: userInfo.schoolId,
      subject: subjectName,
      subjectTeacher: "0",
      title: title,
      score: score,
      description: description,
      subjectId: subjectSelected,
      classId: 0,
      assignmentUploadFile: selectedFile ?? "",
      post_date: new Date(),
      staffid: 0,
      postedBy: "",
      assignClasses: assignClasses,
    };
    console.log(requestBody, "Request");
    try {
      const response = await axios.post(url, requestBody, { headers });

      setLoading(false);
      handleClickVariant("success");
      setScore("");
      setDescription("");
      setOpenModal(false);
      setSubjectSelected("");
      setSelectedClass([]);
      refetch();
    } catch (error) {
      handleClickError("error", "Error setting up assignment, try again");
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {loading && <LoaderLine />}
      <Box className="grid">
        <Box className="grid lg:flex lg:justify-between">
          <Box></Box>
          <Box>
            <Button
              variant="contained"
              sx={{
                textTransform: "initial",
                fontFamily: "Gilroy-Bold",
                height: "50px",
              }}
              className="w-full md:w-[400px]"
              onClick={handleOpenModal}
            >
              Create Assignment
            </Button>
          </Box>
        </Box>
      </Box>
      {/* <Button
        variant="contained"
        sx={{
          textTransform: "initial",
          fontFamily: "Gilroy-Bold",
          height: "50px",
          backgroundColor: colors.primary,
        }}
        onClick={handleOpenModal}
      >
        Setup
      </Button> */}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box align="right" sx={{}}>
            <IconButton onClick={handleCloseModal}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", mb: 2, fontFamily: "Gilroy-Medium" }}
            >
              Setup Assignment
            </Typography>
            <Divider />
          </Box>
          <Box
            p={3}
            sx={{
              overflowY: {
                xs: "scroll",
                sm: "scroll",
                md: "scroll",
                lg: "scroll",
                xl: "auto",
              },
              height: {
                xs: "550px",
                sm: "550px",
                md: "550px",
                lg: "550px",
                xl: "auto",
              },
            }}
          >
            <Box>
              <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                  <Box>
                    {/* <Button onClick={handleClickVariant("success")}>CLick me</Button> */}
                    <InputLabel
                      sx={{
                        mb: 1.5,
                        mt: 1,
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#000",
                        fontFamily: "Gilroy-Medium",
                      }}
                      onClick={fetchSubjects}
                    >
                      Choose Subject
                    </InputLabel>

                    <Select
                      fullWidth
                      placeholder="dssss"
                      size="small"
                      value={subjectSelected}
                      onChange={handleChange}
                      sx={{
                        fontSize: "13px",
                        border: "0.5px solid #671E78",
                        fontFamily: "gilroy-regular",
                      }}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                        style: {
                          fontSize: "13px",
                          borderRadius: "10px",
                          fontFamily: "gilroy-regular",
                          // border: "1px solid #671E78",
                          // Replace with your desired font family
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em> Choose Subject</em>
                      </MenuItem>
                      {subjectLoader && (
                        <Box>
                          <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "2rem", mx: 2 }}
                          />
                          <Skeleton
                            variant="rounded"
                            height={60}
                            sx={{ mx: 2 }}
                          />
                          <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "1rem", mx: 2 }}
                          />
                        </Box>
                      )}
                      {subjects.length === 0 && !subjectLoader ? (
                        <Box>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                              color: "#333",
                              fontFamily: "Gilroy-Medium",
                            }}
                          >
                            No Subject Available
                          </Typography>
                        </Box>
                      ) : (
                        subjects.length > 0 &&
                        subjects.map((subject) => (
                          <MenuItem
                            value={subject.subjectId}
                            onClick={(e) => {
                              setSubjectName(subject.subjectName);
                              setSubjectID(subject.subjectId);
                              // No need to call fetchClasses() here, it's triggered by useEffect
                              setSelectedClass([]);
                            }}
                            sx={{
                              fontFamily: "gilroy-regular",
                              fontSize: "13px",
                            }}
                          >
                            {subject.subjectName}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </Box>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Box>
                    <InputLabel
                      sx={{
                        mb: 1.5,
                        mt: 1,
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#000",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      Choose Class
                    </InputLabel>

                    <Select
                      fullWidth
                      placeholder="Select Class"
                      size="small"
                      value={selectedClass}
                      multiple
                      onChange={handleChange2}
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected
                              .map(
                                (val) =>
                                  `${val.className} ${val.classArm}` ||
                                  val.classId
                              )
                              .join(", ")
                          : ""
                      }
                      sx={{
                        fontSize: "13px",
                        border: "0.5px solid #671E78",
                        fontFamily: "gilroy-regular",
                      }}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                        style: {
                          fontSize: "13px",
                          borderRadius: "10px",
                          fontFamily: "gilroy-regular",
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em> Select Class</em>
                      </MenuItem>
                      {classLoader && (
                        <Box>
                          <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "2rem", mx: 2 }}
                          />
                          <Skeleton
                            variant="rounded"
                            height={60}
                            sx={{ mx: 2 }}
                          />
                          <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "1rem", mx: 2 }}
                          />
                        </Box>
                      )}
                      {classes.length === 0 && !classLoader ? (
                        <Box>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontSize: "12px",
                              mt: 2,
                              mb: 1,
                              color: "#333",
                            }}
                          >
                            No Class Found.
                          </Typography>
                        </Box>
                      ) : (
                        classes.map((each, index) => (
                          <MenuItem
                            key={index}
                            value={each}
                            sx={{
                              fontFamily: "gilroy-regular",
                              fontSize: "13px",
                            }}
                          >
                            <Checkbox
                              checked={selectedClass.some(
                                (selected) => selected.classId === each.classId
                              )}
                            />
                            <ListItemText
                              primary={`${each?.className} ${each?.classArm}`}
                            />
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </Box>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Box>
                    <InputLabel
                      sx={{
                        mb: 1.5,
                        mt: 1,
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#000",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      Title
                    </InputLabel>
                    <TextField
                      fullWidth
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid #671E78",
                            fontFamily: "gilroy-regular",
                          },
                        },
                      }}
                      InputProps={{
                        style: {
                          fontFamily: "Gilroy-regular",
                          fontSize: "13px",
                        },
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box>
                        <InputLabel
                          sx={{
                            mb: 1.5,
                            mt: 1,
                            fontSize: "12px",
                            fontWeight: "bolder",
                            color: "#000",
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          Time
                        </InputLabel>
                        <TextField
                          disabled
                          fullWidth
                          value={new Date()}
                          size="small"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "1px solid #671E78",
                              },
                            },
                          }}
                          InputProps={{
                            style: {
                              fontFamily: "Gilroy-regular",
                              fontSize: "13px",
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box>
                        <InputLabel
                          sx={{
                            mb: 1.5,
                            mt: 1,
                            fontSize: "12px",
                            fontWeight: "bolder",
                            color: "#000",
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          Score
                        </InputLabel>
                        <TextField
                          fullWidth
                          type="number"
                          size="small"
                          onChange={(e) => setScore(e.target.value)}
                          value={score}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "1px solid #671E78",
                                fontFamily: "gilroy-regular",
                              },
                            },
                          }}
                          InputProps={{
                            style: {
                              fontFamily: "Gilroy-regular",
                              fontSize: "13px",
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <Box>
                    <InputLabel
                      sx={{
                        mb: 1.5,
                        mt: 1,
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#000",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      Description
                    </InputLabel>
                    <TextField
                      fullWidth
                      multiline
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      rows={3}
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid #671E78",
                          },
                        },
                      }}
                      InputProps={{
                        style: {
                          fontFamily: "Gilroy-regular",
                          fontSize: "13px",
                        },
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <InputLabel
                    sx={{
                      fontFamily: "gilroy-regular",
                      color: "#333",
                      fontSize: "12px",
                      mb: 1,
                      mt: 3,
                    }}
                  >
                    Upload Picture(optional)
                  </InputLabel>
                  <TextField
                    type="file"
                    fullWidth
                    onChange={handleFileChange}
                    InputProps={{
                      style: {
                        fontFamily: "Gilroy-regular",
                        fontSize: "13px",
                        borderRadius: "5px",
                        offset: " 0.5px solid #671E78",
                        border: " 0.5px solid #671E78",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Box mt={5}>
                <Button
                  disabled={!score || !description || !title}
                  variant="contained"
                  fullWidth
                  sx={{
                    textTransform: "initial",
                    backgroundColor: colors.primary,
                  }}
                  onClick={handleCreateAssignment}
                >
                  Create
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default Setup;
