import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  AccountSummaryWrapper,
  Container,
  Icon,
  Input,
  Search,
  TabContainer,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import GlobalStyles from "../../assets/globalStyle";
import { BsSearch } from "react-icons/bs";
import Loader from "../pageLoader/loader";
import { useSelector } from "react-redux";
import ErrorService from "../../services/errorService";
import hostelApi from "../../services/hostel";
import api from "../../services/ControlPanel";
import colors from "../../assets/colors.json";
import { AuthButton } from "../../pages/auth/Login.style";
import { LiaEllipsisVSolid } from "react-icons/lia";
import Modal from "../modal/Modal";
import Occupants from "./Occupants";
import Floors from "./Floors";
import Rooms from "./Rooms";
import moment from "moment";
import { paginateData } from "../../services/utils";
import Pagination from "../pagination_one/pagination";
import Bedspace from "./Bedspace";
import AddHostel from "./AddHostel";
import { MenuItem, MenuList } from "@mui/material";
import Popover from "@mui/material/Popover";
import { Box } from "@mui/system";

const HostelSetup = () => {
  const { admToken, user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [openCreateHostelModal, setOpenCreateHostelModal] = useState(false);
  const [openEditHostelModal, setOpenEditHostelModal] = useState(false);
  const [openAddFloor, setOpenAddFloor] = useState(false);
  const [openAddRoom, setOpenAddRoom] = useState(false);
  const [openAddBedspace, setopenAddBedspace] = useState(false);
  const [hostels, setHostels] = useState([]);
  const [staff, setStaff] = useState([]);
  const [selectedHostelObj, setSelectedHostelObj] = useState(null);
  const [openOccupants, setOpenOccupants] = useState(false);
  const [list, setList] = useState([]);
  const [itemOffSet, setitemOffSet] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sQuery, setSQuery] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleAnchorClick = (event, hostel) => {
    setAnchorEl(event.currentTarget);
    setSelectedHostelObj(hostel);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const filterSearch = async (e) => {
    const queryRegex = new RegExp(e.target.value, "i");
    const arr = hostels.filter((item) => {
      return Object.values(item).some((val) => queryRegex.test(val));
    });
    setList(arr);
    setPageCount(0);
    // }
  };

  const getHostels = useCallback(async () => {
    setLoading(true);
    try {
      const res = await hostelApi.getHostels(admToken, user.schoolId);
      if (res.status && res.statusCode === 2) {
        setHostels(res.data);
      }
      res.status === 500 ||
        res.status === 400 ||
        (!res.status && ErrorService.displayErrorAlert(res));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [admToken, user.schoolId]);

  const getAllStaff = useCallback(async () => {
    try {
      const res = await api.getUsersCredential(admToken, user.schoolId, 3);
      if (res.status === true && res.statusCode === 2) {
        setStaff(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken, user.schoolId]);

  useEffect(() => {
    getHostels();
    getAllStaff();
  }, [getAllStaff, getHostels]);

  const resetData = () => {
    setCurrentPage(1);
    console.log("reset data working");
    paginateData(hostels, setList, 1, itemOffSet, setitemOffSet, setPageCount);
  };

  useEffect(() => {
    paginateData(
      hostels,
      setList,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount,
      10
    );
  }, [currentPage, hostels, itemOffSet]);

  return (
    <Container>
      <GlobalStyles />
      {openCreateHostelModal && (
        <Modal
          content={
            <AddHostel
              staff={staff}
              setOpenCreateHostelModal={setOpenCreateHostelModal}
              getHostels={getHostels}
            />
          }
          size="large"
          onClose={setOpenCreateHostelModal}
          setOpenCreateHostelModal={setOpenCreateHostelModal}
          getHostels={getHostels}
        />
      )}
      {openEditHostelModal && (
        <Modal
          content={
            <AddHostel
              staff={staff}
              edit
              selectedHostel={selectedHostelObj}
              setOpenEditHostelModal={setOpenEditHostelModal}
              getHostels={getHostels}
            />
          }
          size="large"
          onClose={setOpenEditHostelModal}
        />
      )}
      {openOccupants && (
        <Modal
          content={
            <Occupants hostel={selectedHostelObj} getHostels={getHostels} />
          }
          size="large"
          onClose={setOpenOccupants}
        />
      )}
      {openAddFloor && (
        <Modal
          content={
            <Floors hostel={selectedHostelObj} getHostels={getHostels} />
          }
          size="large"
          onClose={setOpenAddFloor}
        />
      )}
      {openAddRoom && (
        <Modal
          content={<Rooms hostel={selectedHostelObj} getHostels={getHostels} />}
          size="large"
          onClose={setOpenAddRoom}
        />
      )}
      {openAddBedspace && (
        <Modal
          content={
            <Bedspace hostel={selectedHostelObj} getHostels={getHostels} />
          }
          size="large"
          onClose={setopenAddBedspace}
        />
      )}
      <div className="flex justify-end mb-5">
        <AuthButton
          onClick={() => setOpenCreateHostelModal(true)}
          bg={colors.primary}
          className="!rounded-md"
        >
          Add Hostel
        </AuthButton>
      </div>
      <TabContainer
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          paddingBottom: "0px",
        }}
      >
        <TabLinkContainer bg="rgb(238, 224,241)">
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search
              type="text"
              placeholder="Search For Hostel"
              value={sQuery}
              onChange={(e) => {
                setSQuery(e.target.value);
                if (e.target.value.length > 2) {
                  filterSearch(e);
                }

                if (e.target.value === "") {
                  resetData();
                  console.log("hellow world inside jsx");
                }
              }}
            />
          </Input>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          {loading ? (
            <Loader />
          ) : (
            <Box>
              <table
                style={{
                  borderRadius: "0px",
                  borderBottomLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                  // position: "relative",
                }}
              >
                <tr
                  className="tabel-head"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <th className="text-[0.75rem] xl:text-[1.1rem]">S/N</th>
                  <th className="text-[0.75rem] xl:text-[1.1rem]">Hostel</th>
                  <th className="text-[0.75rem] xl:text-[1.1rem]">Gender</th>
                  <th className="text-[0.75rem] xl:text-[1.1rem]">
                    House Master
                  </th>
                  <th className="text-[0.75rem] xl:text-[1.1rem]">Capacity</th>
                  <th className="text-[0.75rem] xl:text-[1.1rem]">Occupants</th>
                  <th className="text-[0.75rem] xl:text-[1.1rem]">
                    Created By
                  </th>
                  <th className="text-[0.75rem] xl:text-[1.1rem]">
                    Created Date
                  </th>
                  <th className="text-[0.75rem] xl:text-[1.1rem]"></th>
                </tr>
                {list.map((hostel, index) => (
                  <React.Fragment key={index}>
                    <tr className="relative">
                      <td className="text-[0.75rem] xl:text-[1rem]">
                        {index + 1}
                      </td>
                      <td className="text-[0.75rem] xl:text-[1rem]">
                        {hostel.hostel_name}
                      </td>
                      <td className="text-[0.75rem] xl:text-[1rem]">
                        {hostel.gender}
                      </td>
                      <td className="text-[0.75rem] xl:text-[1rem]">
                        {hostel.managerName || ""}
                      </td>
                      <td className="text-[0.75rem] xl:text-[1rem]">
                        {hostel.capacity}
                      </td>
                      <td className="text-[0.75rem] xl:text-[1rem]">
                        {hostel.occupants}
                      </td>
                      <td className="text-[0.75rem] xl:text-[1rem]">
                        {hostel.createdBy}
                      </td>
                      <td className="text-[0.75rem] xl:text-[1rem]">
                        {moment(hostel.createdDate).isValid()
                          ? moment(hostel.createdDate).format("MMM Do, YY")
                          : ""}
                      </td>
                      <td className="text-[0.75rem] xl:text-[1rem]">
                        <button onClick={(e) => handleAnchorClick(e, hostel)}>
                          <LiaEllipsisVSolid className="w-6 h-6" />
                        </button>
                      </td>
                    </tr>
                    {/* {openOptions === index && (
                      <div
                        ref={optionsRef}
                        className={`absolute right-[7%] bg-[${colors.primary}] text-white rounded-lg z-50`}
                      >
                        <ul className="flex gap-4 flex-col py-6 px-4">
                          <li
                            className={`rounded-lg py-2 px-4 hover:bg-[#d8c5dc] `}
                          >
                            <button
                              onClick={() => {
                                setOpenEditHostelModal(true);
                                setOpenOptions(false);
                              }}
                            >
                              Edit
                            </button>
                          </li>
                          <li
                            className={`rounded-lg py-2 px-4 hover:bg-[#d8c5dc] `}
                          >
                            <button
                              onClick={() => {
                                setOpenAddFloor(true);
                                setOpenOptions(false);
                              }}
                            >
                              {`Add Floor (${hostel.floorCount})`}
                            </button>
                          </li>
                          <li
                            className={`rounded-lg py-2 px-4 hover:bg-[#d8c5dc] `}
                          >
                            <button
                              onClick={() => {
                                setOpenAddRoom(true);
                                setOpenOptions(false);
                              }}
                            >
                              {`Add Room (${hostel.roomCount})`}
                            </button>
                          </li>
                          <li
                            className={`rounded-lg py-2 px-4 hover:bg-[#d8c5dc] `}
                          >
                            <button
                              onClick={() => {
                                setopenAddBedspace(true);
                                setOpenOptions(false);
                              }}
                            >
                              {`Add Bedspaces(${hostel.bedspaceCount})`}
                            </button>
                          </li>
                          <li
                            className={`rounded-lg py-2 px-4 hover:bg-[#d8c5dc] `}
                          >
                            <button
                              onClick={() => {
                                setOpenOccupants(true);
                                setOpenOptions(false);
                              }}
                            >
                              View Occupants
                            </button>
                          </li>
                        </ul>
                      </div>
                    )} */}
                  </React.Fragment>
                ))}
              </table>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Box
                  sx={{
                    bgcolor: "#671E78",
                    color: "#fff",
                    fontFamily: "Gilroy-Regular",
                    px: 3,
                  }}
                >
                  <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
                    <MenuItem
                      onClick={() => {
                        setOpenEditHostelModal(true);
                        handleClose();
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setOpenAddFloor(true);
                        handleClose();
                      }}
                    >
                      {`Add Floor (${selectedHostelObj?.floorCount})`}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setOpenAddRoom(true);
                        handleClose();
                      }}
                    >
                      {`Add Room (${selectedHostelObj?.roomCount})`}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setopenAddBedspace(true);
                        handleClose();
                      }}
                    >
                      {`Add Bedspace(${selectedHostelObj?.bedspaceCount})`}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setOpenOccupants(true);
                        handleClose();
                      }}
                    >
                      View Occupants
                    </MenuItem>
                  </MenuList>
                </Box>
              </Popover>
            </Box>
          )}
        </AccountSummaryWrapper>
      </TabContainer>
      {pageCount > 1 && (
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            range={3}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </Container>
  );
};

export default HostelSetup;
