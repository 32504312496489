import React, { useState, createRef, useCallback } from "react";
import PropTypes from "prop-types";
import {
  PageWrapper,
  InlineFormDiv,
  SlidingImg,
  SmallHeader,
} from "../../../pages/Dashboard/styles/dashboard.style";
import colors from "../../../assets/colors.json";
import Loader from "../../pageLoader/loader";
import api from "../../../services/ControlPanel";
import { useMediaQuery } from "react-responsive";
import { InputWrapper, AuthButton } from "../../../pages/auth/Login.style";
import Avatar from "../../../assets/Images/avatar.jpg";
import ErrorService from "../../../services/errorService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { checkAccess } from "../../../services/utils";

const SlidingImages = ({ arr, loading, action }) => {
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [actLoading, setActLoading] = useState(false);
  const onPickFileRef = createRef();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [formLoading, setFormLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [file, setFile] = useState({ name: "", base64: "" });
  const [query, setQuery] = useState("");
  const activateItem = useCallback(
    async (id, bool) => {
      setActLoading(true);
      try {
        const res = await api.activateControlPanelLasmabSetup(
          id,
          bool,
          admToken,
          "Activate-SlideImage"
        );
        if (res.status && res.statusCode === 2) {
          toast.success(res.message, {
            autoClose: 4000,
            position: toast.POSITION.BOTTOM_LEFT,
          });
          // setFile({ ...file, name: "", base64: "" });
          action();
        }
        res.status === 500 && ErrorService.displayErrorAlert(res.data);
        res.status === 400 && ErrorService.displayErrorAlert(res.data);
        setActLoading(false);
      } catch (error) {
        setActLoading(false);
        console.log(error);
      }
    },
    [action, admToken]
  );
  const deleteItem = useCallback(
    async (id) => {
      setDeleteLoading(true);
      try {
        const res = await api.deleteControlPanelLasmabSetup(
          id,
          admToken,
          "SlideImage"
        );
        if (res.status && res.statusCode === 2) {
          toast.success(res.message, {
            autoClose: 4000,
            position: toast.POSITION.BOTTOM_LEFT,
          });
          // setFile({ ...file, name: "", base64: "" });
          action();
        }
        res.status === 500 && ErrorService.displayErrorAlert(res.data);
        res.status === 400 && ErrorService.displayErrorAlert(res.data);
        setDeleteLoading(false);
      } catch (error) {
        setDeleteLoading(false);
        console.log(error);
      }
    },
    [action, admToken]
  );
  const submitSlide = async (data) => {
    setFormLoading(true);
    try {
      const res = await api.postControlPanelLasmabSetup(
        data,
        admToken,
        "Update-SlideImage"
      );
      if (res.status && res.statusCode === 2) {
        toast.success(res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setFile({ ...file, name: "", base64: "" });
        action();
      }
      res.status === 500 && ErrorService.displayErrorAlert(res.data);
      res.status === 400 && ErrorService.displayErrorAlert(res.data);
      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);
      console.log(error);
    }
  };
  const pickFile = (ref) => {
    ref.current.click();
  };
  const onPickFile = (event) => {
    if (event !== null) {
      const files = event.target.files;
      console.log(files);
      let filename = files[0].name;
      if (
        filename.includes(".jpg") ||
        filename.includes(".png") ||
        filename.includes(".jpeg")
      ) {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          // setFunc(fileReader.result);
          setFile({
            ...file,
            name: filename,
            base64: fileReader.result,
          });
          let data = {
            id: 0,
            status: 1,
            imagePath: fileReader.result,
            createdDate: new Date().toISOString(),
          };

          submitSlide(data);
        });
        fileReader.readAsDataURL(files[0]);
      } else {
        alert("Please Upload A Photo");
      }
    }
  };
  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <PageWrapper>
      <InlineFormDiv width="90%" jc="space-between">
        <div
          style={{
            width: isMobile ? "100%" : "70%",
          }}
        >
          <input
            type="file"
            style={{
              display: "none",
            }}
            onChange={(e) => onPickFile(e)}
            // onClick={(e) => onPickFile(e)}
            ref={onPickFileRef}
          />
          <InputWrapper>
            <label htmlFor="filename">Upload Image</label>
            <input
              type="text"
              value={file.name}
              disabled={true}
              placeholder="Select Image"
            />
          </InputWrapper>
        </div>
        {checkAccess(accessObj.rights, "Save") && (
          <AuthButton
            disabled={formLoading}
            width={isMobile ? "100%" : "20%"}
            onClick={() => pickFile(onPickFileRef)}
          >
            {formLoading ? "Please wait..." : "Select Image"}
          </AuthButton>
        )}
      </InlineFormDiv>

      {arr.length > 0 ? (
        arr.map((item, index) => (
          <SlidingImg key={index}>
            <div>
              <img
                src={
                  item.imagePath === "" || item.imagePath === null
                    ? Avatar
                    : item.imagePath
                }
                alt=""
              />
            </div>
            <div>
              {checkAccess(accessObj.rights, "Activate") && (
                <button
                  disabled={query === item.id && actLoading}
                  style={{
                    borderColor:
                      item.status === 1 ? colors.primary : colors.gray,
                    backgroundColor:
                      item.status === 1 ? colors.primary : colors.gray,
                    color: "white",
                  }}
                  onClick={() => {
                    console.log("here");
                    setQuery(item.id);
                    activateItem(item.id, item.status === 1 ? false : true);
                  }}
                >
                  {query === item.id && actLoading
                    ? "Please wait..."
                    : item.status === 1
                    ? "Deactivate"
                    : "Activate"}
                </button>
              )}

              {checkAccess(accessObj.rights, "Delete") && (
                <button
                  disabled={query === item.id && deleteLoading}
                  onClick={() => {
                    console.log("here");
                    setQuery(item.id);
                    deleteItem(item.id);
                  }}
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    borderColor: "red",
                  }}
                >
                  {query === item.id && deleteLoading
                    ? "Please wait..."
                    : "Delete"}
                </button>
              )}
            </div>
          </SlidingImg>
        ))
      ) : (
        <SmallHeader style={{ fontWeight: "bold", textAlign: "center" }}>
          No Slide Images At The Moment
        </SmallHeader>
      )}
    </PageWrapper>
  );
};

SlidingImages.propTypes = {
  arr: PropTypes.array,
  loading: PropTypes.bool,
  action: PropTypes.func,
};

SlidingImages.defaultProps = {
  arr: [],
  loading: true,
};
export default SlidingImages;
