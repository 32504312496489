import { useCallback, useEffect, useState } from "react";
import colors from "../../assets/colors.json";
import Avatar from "../../assets/Images/avatar.jpg";
import { BsSearch } from "react-icons/bs";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { toast } from "react-toastify";
import {
  TabContainer,
  AccountSummaryWrapper,
  InlineLabelGroup,
  InlineLabelWrapper,
  Search,
  Icon,
  Input,
} from "../../pages/Dashboard/styles/dashboard.style";
import { ImgTextDiv } from "../../pages/student_mgt/stu_mgt.style";
import { AuthButton, InputWrapper } from "../../pages/auth/Login.style";
import Select from "../../components/select/select";
import { useSelector } from "react-redux";
import FormLoader from "../../components/FormLoader";
import ErrorService from "../../services/errorService";
import Loader from "../../components/pageLoader/loader";
import {
  SubjectStudents,
  getSessions,
  AssignSingle,
  TeacherClasses,
  TeacherClassSubject,
} from "../../services/Subject";
import { checkAccess } from "../../services/utils";
const AssignSingleSubject = () => {
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [list, setList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [marked, setMarked] = useState([]);
  const [loadSub, setLoadSub] = useState(true);
  // const [subjectClasses, setSubjectClasses] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [loadClass, setLoadClass] = useState(false);
  const [classObj, setClassObj] = useState({
    id: "",
    name: "",
  });
  const [session, setSession] = useState({
    id: "",
    name: "",
  });
  const [subject, setSubject] = useState({
    id: "",
    name: "",
  });
  const schoolSessions = useCallback(async () => {
    try {
      // setLoading(true);
      const res = await getSessions(admToken);
      console.log(res);
      res.status && res.statusCode === 2 && setSessions(res.data);
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
      console.log(res);

      setLoadSub(false);
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      setLoadSub(false);
      console.log(error);
    }
  }, [admToken]);
  const [subjectsLoading, setSubjectsLoading] = useState(false);

  // const getSubjects = useCallback(async () => {
  //   try {
  //     const res = await MySubject(admToken, user.schoolId);
  //     console.log(res);
  //     res.status && res.statusCode === 2 && setSubjects(res.data);
  //     (res.status === 500 || res.status === 400) &&
  //       ErrorService.displayErrorAlert(res.data);
  //     setLoadSub(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoadSub(false);
  //   }
  // }, [admToken, user]);
  const getClassSubjects = useCallback(
    async (par) => {
      try {
        setSubjectsLoading(true);
        const res = await TeacherClassSubject(admToken, user.schoolId, par);
        if (res.status && res.statusCode === 2) {
          setSubjects(res.data);
        } else {
          ErrorService.displayErrorAlert(res);
        }
        setSubjectsLoading(false);
        console.log(res);
      } catch (error) {
        setSubjectsLoading(false);
        console.log(error);
      }
    },
    [admToken, user]
  );

  const [classes, setClasses] = useState([]);
  const getClasses = useCallback(async () => {
    try {
      setLoadClass(true);
      const res = await TeacherClasses(admToken, user.schoolId);
      console.log(res);
      res.status && res.statusCode === 2 && setClasses(res.data);
      (res.status === 500 || res.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res);
      setLoadClass(false);
    } catch (error) {
      console.log(error);
      setLoadClass(false);
    }
  }, [admToken, user]);
  useEffect(() => {
    // getSubjects();
    schoolSessions();
    getClasses();
  }, [getClasses, schoolSessions]);

  // const getClasses = useCallback(
  //   async (id) => {
  //     try {
  //       setLoadClass(true);
  //       const res = await MySubjectClasses(admToken, user.schoolId, id);
  //       console.log(res);
  //       res.status && res.statusCode === 2 && setSubjectClasses(res.data);
  //       (res.status === 500 || res.status === 400 || !res.status) &&
  //         ErrorService.displayErrorAlert(res);
  //       setLoadClass(false);
  //     } catch (error) {
  //       console.log(error);
  //       setLoadClass(false);
  //     }
  //   },
  //   [admToken, user]
  // );
  const mapMarkedFromApi = useCallback(
    (arr) => {
      const newList = arr.map((item) => ({
        schoolId: user.schoolId,
        studentId: item.studentId,
        isTicked: item.isSubjectAssign,
      }));

      setMarked(newList);
    },
    [user.schoolId]
  );
  const getStudents = useCallback(async () => {
    try {
      setLoading(true);
      const res = await SubjectStudents(
        admToken,
        user.schoolId,
        classObj.id,
        subject.id,
        session.id
      );

      setMarked([]);
      if (res.status && res.statusCode === 2) {
        if (res.data) {
          setShowForm(false);
          setList(res.data);
          mapMarkedFromApi(res.data);
          setAllData(res.data);
        }
      }
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
      console.log(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [user.schoolId, classObj, subject, admToken, mapMarkedFromApi, session]);

  const markAll = () => {
    if (marked.every((item) => item.isTicked === true)) {
      const newList = list.map((item) => {
        return {
          studentId: item.studentId,
          schoolId: user.schoolId,
          isTicked: false,
        };
      });
      setMarked(newList);
    } else {
      const newList = list.map((item) => {
        return {
          studentId: item.studentId,
          schoolId: user.schoolId,
          isTicked: true,
        };
      });
      setMarked(newList);
    }
  };
  const toggleMarked = (id) => {
    setMarked((prevItems) => {
      const updatedItems = prevItems.map((item) => {
        if (item.studentId === id) {
          return { ...item, isTicked: !item.isTicked }; // Update the 'name' key
        }
        return item;
      });

      return updatedItems;
    });
  };
  const [sQuery, setSQuery] = useState("");
  const filterSearch = async (e) => {
    console.log(e.target.value);
    if (e.target.value.length < 3) {
      // setCurrentPage(1);
      setList(allData);
    } else {
      let arr = [];
      console.log(arr);
      const queryRegex = new RegExp(e.target.value, "i");
      allData.forEach((item) => {
        Object.values(item).forEach((val) =>
          queryRegex.test(val) ? arr.push(item) : ""
        );
      });
      console.log(arr);
      setList(arr);
      // setPageCount(0);
    }
  };

  const assignSubject = async () => {
    console.log("Hello");
    try {
      setFormLoading(true);
      console.log(marked);
      let data = {
        classId: Number(classObj.id),
        subjectId: Number(subject.id),
        sessionId: Number(session.id),
        selectedStudents: marked,
        // sessionId: Number(session.id),
      };

      const res = await AssignSingle(admToken, data);
      console.log(res);

      if (res.status && res.statusCode === 2) {
        toast.success(res.message);
        getStudents(classObj.id);
        setMarked([]);
      }
      (res.status === 500 || res.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res);
      setFormLoading(false);
    } catch (error) {
      console.log(error);
      setFormLoading(false);

      toast.error("Please Try Again Later");
    }
  };
  const [showForm, setShowForm] = useState(true);

  if (loadSub) {
    return <Loader />;
  }

  return (
    <>
      <div
        onClick={() => setShowForm(!showForm)}
        style={{
          backgroundColor: colors.primary,
          borderRadius: "10px",
          marginTop: "0.3rem",
          padding: "0.4rem",
          display: "flex",
          justifyContent: "space-between",
          color: "white",
          fontWeight: "bold",
        }}
      >
        <div>
          <span>
            {session.name} {session.name.length > 0 ? "-" : ""} {subject.name}{" "}
            {subject.name.length > 0 ? "-" : ""}
            {classObj.name}
          </span>
        </div>
        {showForm ? (
          <BiSolidDownArrow color="white" />
        ) : (
          <BiSolidUpArrow color="white" />
        )}
      </div>

      {showForm && (
        <>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title="Session"
                  index="session" // no
                  selected={session.id} // false
                  options={sessions.filter((i) => i.isActive)}
                  // thirdOption="subjectId" // false
                  setSelected={(val) => {
                    console.log(val);
                    setSession({
                      ...session,
                      id: val.id,
                      name: val.session,
                    });

                    // setSessionId(val.id);
                    // getClasses(val.subjectId);
                  }}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>

          <InlineLabelGroup>
            <InlineLabelWrapper flex={2}>
              <InlineLabelGroup>
                {/* <InlineLabelWrapper flex={1}>
                  <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                    <Select
                      title={loadClass ? "Loading" : "Class"}
                      index="className"
                      selected={classObj.id}
                      options={subjectClasses}
                      thirdOption="classId"
                      extraTitle="classArm"
                      setSelected={(val) => {
                        console.log(val);
                        setClassObj({
                          ...classObj,
                          id: val.classId,
                          name: `${val.className} ${val.classArm}`,
                        });
                      }}
                    />
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                    <Select
                      title="Subject"
                      index="subjectName"
                      selected={subject.id}
                      options={subjects}
                      thirdOption="subjectId"
                      setSelected={(val) => {
                        setClassObj({ ...classObj, id: "", name: "" });

                        setSubject({
                          ...subject,
                          id: val.subjectId,
                          name: val.subjectName,
                        });
                        getClasses(val.subjectId);
                        setLoading(false);
                      }}
                    />
                  </InputWrapper>
                </InlineLabelWrapper> */}
                <InlineLabelWrapper flex={1}>
                  <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                    <Select
                      title={loadClass ? "Loading" : "Class"}
                      index="className" // no
                      selected={classObj.id} // false
                      options={classes}
                      thirdOption="classId"
                      extraTitle="classArm"
                      setSelected={(val) => {
                        console.log(val);
                        setMarked([]);
                        // setSelectedSubjects([]);
                        // setAllData([]);
                        // setList([]);
                        setClassObj({
                          ...classObj,
                          id: val.classId,
                          name: `${val.className} ${val.classArm}`,
                          category: val.category,
                        });
                        getClassSubjects(val.category);
                        // getStudents(val.classId);
                      }}
                      // color="white"
                    />
                  </InputWrapper>
                </InlineLabelWrapper>

                <InlineLabelWrapper flex={1}>
                  <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                    <Select
                      title={subjectsLoading ? "loading" : "Subject"}
                      index="subjectName" // no
                      selected={subject.id} // false
                      options={subjects}
                      thirdOption="subjectId" // false
                      setSelected={(val) => {
                        console.log(val);

                        // setClassObj({ ...classObj, id: "", name: "" });
                        setSubject({
                          ...subject,
                          id: val.subjectId,
                          name: val.subjectName,
                        });

                        // mapSubjectObjects(val);
                        // getClasses(val.subjectId);
                        // setLoading(false);
                      }}
                      // color="white"
                    />
                  </InputWrapper>
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              {/* <ButtonFlex> */}
              <AuthButton
                width="100%"
                mt="0.4rem"
                onClick={() => getStudents()}
                disabled={
                  loading ||
                  classObj.id === "" ||
                  subject.id === "" ||
                  session.id === ""
                }
              >
                <div style={{ textAlign: "center" }}>
                  {loading ? (
                    <FormLoader color={colors.primary} />
                  ) : (
                    "Load Student"
                  )}
                </div>
              </AuthButton>
              {/* </ButtonFlex> */}
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </>
      )}

      {loading && <Loader />}

      {!loading && (
        <>
          <TabContainer style={{ borderRadius: "10px", paddingBottom: "0px" }}>
            <AccountSummaryWrapper pt="0px">
              <table>
                <tr
                  className="table-head"
                  style={{ backgroundColor: colors.primary, color: "white" }}
                >
                  <th>
                    <input
                      type="checkbox"
                      style={{
                        borderColor: "white",
                        background: colors.primary,
                      }}
                      onChange={markAll}
                      checked={
                        marked.length > 0 &&
                        marked.every((item) => item.isTicked === true)
                      }
                    />
                  </th>
                  <th>Student Name ({list.length}) </th>
                  <th colSpan={2}>
                    <Input>
                      <Icon>
                        <BsSearch color={colors.primary} />
                      </Icon>
                      <Search
                        style={{ background: "white" }}
                        value={sQuery}
                        type="type"
                        placeholder="Search For Student"
                        onChange={(e) => {
                          // console.log(e.target.value);
                          setSQuery(e.target.value);
                          filterSearch(e);
                        }}
                      />
                    </Input>
                  </th>
                  {/* <th></th> */}
                </tr>
                {list.length > 0 ? (
                  list.map((item, index) => (
                    // const mark  = marked.find(i => studentId === item.studentId)
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          checked={
                            marked.find((i) => i.studentId === item.studentId)
                              .isTicked
                          }
                          onChange={() => toggleMarked(item.studentId)}
                        />
                      </td>
                      <td>
                        <ImgTextDiv>
                          <span
                            style={{
                              marginRight: "0.6rem",
                              fontSize: "1.3rem",
                            }}
                          >
                            {index + 1}.
                          </span>
                          <img
                            src={
                              item.profileImagePath === "" ||
                              item.profileImagePath === null
                                ? Avatar
                                : item.profileImagePath
                            }
                            alt=""
                          />
                          <span>{item.fullName} </span>
                        </ImgTextDiv>
                      </td>
                      <td>
                        <span style={{ fontSize: "14px" }}>
                          {item.admissionNo}
                        </span>
                      </td>
                      <td></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: "center" }}>
                      There Are No Student Available
                    </td>
                  </tr>
                )}
              </table>
            </AccountSummaryWrapper>
          </TabContainer>

          {list.length > 0 && (
            <div
              style={{
                display: "flex",
                marginTop: "1rem",
                justifyContent: "end",
                padding: "10px 20px",
              }}
            >
              {checkAccess(accessObj.rights, "Assign") && (
                <AuthButton
                  disabled={marked.length === 0 || formLoading}
                  onClick={assignSubject}
                  width="150px"
                >
                  {" "}
                  {formLoading ? <FormLoader /> : "Assign"}
                </AuthButton>
              )}

              {/* 
              <AuthButton
                style={{ marginLeft: "0.5rem", borderColor: colors.primary }}
                color={colors.primary}
                bg="white"
                width="150px"
              >
                Remove
              </AuthButton> */}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AssignSingleSubject;
