import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  TableCell,
  Modal,
  Skeleton,
  TextField,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link, useParams, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputBase from "@mui/material/InputBase";
import { BsSearch } from "react-icons/bs";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LoaderLine from "../LoaderLine";
import Title from "../Dashboard/Title";
import {
  useGetSubmittedClassworkQuery,
  useViewSubmittedClassworkQuery,
  useGradeClassworkMutation,
  useActivateDeactivateMutation,
} from "../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ChatApp from "./comments";
import FormLoader from "../FormLoader";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gilroy-Regular",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #671E78",
  boxShadow: 24,
  p: {
    md: 2,
    xs: 1,
  },
  borderRadius: 3,
  height: {
    sm: 600,
    // xs:
  },
  width: {
    lg: 600,
    xs: 300,
    sm: 400,
    md: 500,
  },
  overflow: "scroll",
};
const Classwork_Third = ({ content }) => {
  const { data } = useParams();
  const classworkData = JSON.parse(decodeURIComponent(data));
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [studentId, setStudentId] = React.useState();
  const [loading, setLoading] = useState(false);
  const [grade, setGrade] = useState();
  const userInfo = useSelector((state) => state.auth.user);
  const [studentData, setStudentData] = useState();
  const navigate = useNavigate();
  const {
    data: classworkQuestions,
    isLoading,
    refetch,
  } = useGetSubmittedClassworkQuery({
    classWorkId: classworkData?.classWorkId,
    schoolId: userInfo?.schoolId,
  });

  const mark = Number(classworkData?.expectedScore);
  const {
    data: submitted,
    isLoading: reLoading,
    refetch: fetchAgain,
  } = useViewSubmittedClassworkQuery({
    classWorkId: classworkData?.classWorkId,
    studentId: studentId,
  });
  useEffect(() => {
    refetch();
    fetchAgain();
  }, []);
  const convertDate = (dateTimeString) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const convertedTime = new Date(dateTimeString).toLocaleTimeString(
      "en-US",
      options
    );
    return convertedTime;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [onGradeClasswork] = useGradeClassworkMutation();
  const handleGradeClasswork = async () => {
    const gradeData = {
      gradeValue: grade,
      classWorkId: classworkData?.classWorkId,
      studentId: studentId,
      // staffId: userInfo?.staffCategoryId,
      staffId: 0,
    };
    if (classworkData?.isClosed) {
      toast.warn("This classwork is closed");
      return;
    } else {
      setLoading(!loading);
      try {
        const res = await onGradeClasswork(gradeData);
        if (res) {
          setLoading(false);
          toast.success("Grade successfully added");
          handleClose();
        }
      } catch (err) {
        setLoading(false);
        toast.error("there was an error grading this student", err);
      }
    }
  };
  const [onCloseClasswork] = useActivateDeactivateMutation();
  const handleCloseClasswork = async () => {
    setLoading(true);
    try {
      const res = await onCloseClasswork({
        classWorkId: classworkData?.classWorkId,
        isClosed: true,
      });
      if (res) {
        toast.success("You have successfully closed this classwork");
        navigate("/academicActivities?tab=1");
        setLoading(false);
      }
    } catch (err) {
      toast.error("there was an error: ", err);
      setLoading(false);
    }
  };
  const handleInputChange = (e) => {
    const inputValue = parseFloat(e.target.value);
    if (mark < inputValue) {
      toast.warn(
        `grade score is higher than expected score which is ${classworkData?.expectedScore}`
      );
    } else {
      setGrade(inputValue);
    }
  };
  return (
    <>
      {reLoading || loading || (isLoading && <LoaderLine />)}
      <Box sx={{ pb: 2 }}>
        <Title title="Classwork - Submission" />
        {/* <Typography sx={{ fontFamily: "gilroy-medium", fontSize: "20px" }}>
          English language(Oral English)
        </Typography> */}
      </Box>

      <Box sx={{ mt: 3 }}>
        <Link to="/academicActivities?tab=1">
          <IconButton
            sx={{ bgcolor: "#671e781a", width: "30px", height: "30px", mr: 2 }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        </Link>
        <Typography variant="caption" sx={{ fontFamily: "gilroy-regular" }}>
          Back to Classwork
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 3,
          width: "100%",
          border: "1px solid #671E78",
          borderRadius: "20px",
        }}
      >
        <Tabs
          className="bg-[#E5E5E5] w-full rounded-t-[20px]"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
        >
          <Tab label="Submission" sx={{ width: "50%" }} {...a11yProps(0)} />
          <Tab label="Comments" sx={{ width: "50%" }} {...a11yProps(1)} />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <Box
            sx={{
              pt: 4,
              background: "rgba(103, 30, 120, 0.1)",
              height: "100px",
              pl: { xs: 2.5 },
              width: "100%",
            }}
          >
            <Search
              sx={{
                width: {
                  lg: "345px",
                  md: "330px",
                  sm: "auto",
                  xs: "auto",
                  pl: { xs: 6 },
                },
              }}
            >
              <SearchIconWrapper>
                <BsSearch />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Box>
        </CustomTabPanel>

        <TableContainer
          sx={{
            width: "100%",
          }}
        >
          <CustomTabPanel value={value} index={0}>
            <Table
              sx={{
                minWidth: 700,
                fontFamily: "Gilroy-Regular",
              }}
              aria-label="customized table"
              className="rounded-b-[20px]"
            >
              <TableHead
                sx={{
                  backgroundColor: "rgba(103, 30, 120, 0.1)",
                  width: "100%",
                }}
              >
                <TableRow>
                  <StyledTableCell>S/N</StyledTableCell>
                  <StyledTableCell>Admission No</StyledTableCell>
                  <StyledTableCell>Student Name</StyledTableCell>
                  <StyledTableCell>Time Submitted</StyledTableCell>
                  <StyledTableCell>Date Submitted</StyledTableCell>
                  <StyledTableCell>Score</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
                {classworkQuestions?.data?.length > 0 ? (
                  classworkQuestions?.data?.map((row, index) => (
                    <StyledTableRow
                      style={
                        index % 2
                          ? { background: "rgba(103, 30, 120, 0.1)" }
                          : { background: "white" }
                      }
                      key={index}
                    >
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>
                        {row?.admissionNo ? row?.admissionNo : "--"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.name ? row?.name : "--"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {convertDate(row?.submittedDateTime)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {new Date(row?.submittedDateTime)?.toLocaleDateString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.totalScore ? row?.totalScore : "--"}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <div onClick={() => setStudentId(row?.studentId)}>
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ textTransform: "initial" }}
                            onClick={() => {
                              handleOpen();
                              setStudentData({
                                name: row?.name,
                                admissionNo: row?.admissionNo,
                              });
                            }}
                          >
                            View Submission
                          </Button>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <>
                    {isLoading && (
                      <Box className="w-full h-full">
                        <Skeleton
                          variant="text"
                          animation="wave"
                          className="w-full h-full"
                        />
                      </Box>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <ChatApp classWorkId={classworkData?.classWorkId} />
          </CustomTabPanel>
        </TableContainer>
      </Box>
      <Box sx={{ marginTop: "20px", marginBottom: "50px" }}>
        {classworkData?.isClosed === false ? (
          <Box align="right">
            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: "initial" }}
              onClick={handleCloseClasswork}
              disabled={loading}
            >
              {loading ? <FormLoader /> : <span>Close classwork</span>}
            </Button>
          </Box>
        ) : (
          ""
        )}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className="flex justify-center mt-5 mb-2">
            <Typography
              sx={{ textTransform: "initial", fontFamily: "gilroy-regular" }}
            >
              View Submission
            </Typography>
          </Box>
          <Box className="mb-2 flex justify-center">
            <Box className="gap-3 md:flex items-center">
              <Box>
                <Typography>Name: {studentData?.name ?? "--"}</Typography>
              </Box>
              <Box>
                <Typography>
                  Admission No: {studentData?.admissionNo ?? "--"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            className={`bg-gray-300 w-full overflow-auto h-auto md:min-h-[300px] p-[10px]`}
          >
            {reLoading && (
              <Box className="w-full h-full">
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{
                    fontSize: "2rem",
                    mx: 2,
                    height: "600px",
                    width: "650px",
                  }}
                />
              </Box>
            )}
            <Box>
              {submitted ? (
                <Box>
                  {submitted?.data?.map((items, _index) => {
                    const index = _index + 1;
                    return (
                      <Box
                        sx={{ display: "flex" }}
                        className={`gap-5 ${index > 0 && "border-b-[2px]"} p-3`}
                        key={index}
                      >
                        <Box>
                          <Typography
                            sx={{
                              textTransform: "initial",
                              fontFamily: "gilroy-regular",
                            }}
                          >
                            {index}
                          </Typography>
                        </Box>
                        <Box>
                          <Box className="gap-5 w-full  items-center lg:flex">
                            <Typography className="md:text-[20px] text-[16px]">
                              Question:
                            </Typography>
                            <Typography
                              sx={{
                                textTransform: "initial",
                                fontFamily: "gilroy-regular",
                              }}
                              className="text-[14px]  lg:text-[16px]"
                            >
                              {items?.question}
                            </Typography>
                          </Box>
                          <Box className="gap-5 w-full  items-center lg:flex">
                            <Typography>Answer:</Typography>
                            <Typography
                              sx={{
                                textTransform: "initial",
                                fontFamily: "gilroy-regular",
                              }}
                              className="text-[14px]  lg:text-[16px]"
                            >
                              {items?.submittedAnswer}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <Box>
                  <Typography
                    sx={{
                      textTransform: "initial",
                      fontFamily: "gilroy-regular",
                    }}
                  >
                    This student is yet to submit any classwork
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                textTransform: "initial",
                fontFamily: "gilroy-regular",
              }}
              className="font-bold"
            >
              Grade
            </Typography>
            <TextField
              fullWidth
              type="number"
              onChange={handleInputChange}
              value={grade}
              // disabled={fixedGrade > 0}
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1px solid #671E78",
                    fontFamily: "gilroy-regular",
                  },
                },
              }}
              InputProps={{
                style: {
                  fontFamily: "Gilroy-regular",
                  fontSize: "13px",
                },
              }}
            />
          </Box>
          <Button
            variant="contained"
            fullWidth
            sx={{
              mt: 4,
              textTransform: "initial",
              fontFamily: "gilroy-regular",
            }}
            disabled={loading || !grade}
            onClick={handleGradeClasswork}
          >
            {loading ? <FormLoader /> : <span>Grade</span>}
          </Button>
          {/* <Box sx={{ marginBottom: "30px" }}>
            <FileDownloadButton url={submitted?.data[0]?.answerFile} />
          </Box> */}
        </Box>
      </Modal>
    </>
  );
};

export default Classwork_Third;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const FileDownloadButton = ({ url }) => {
  const handleDownload = async () => {
    try {
      const blob = await fetch(url).then((response) => response.blob());
      const objectURL = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = objectURL;
      link.target = "_blank";
      link.click();
      toast.success("File successfully downloaded");
    } catch (error) {
      toast.error("Error downloading the file: ", error);
    }
  };
  return (
    <>
      <Button
        variant="contained"
        fullWidth
        onClick={handleDownload}
        sx={{
          mt: 4,
          textTransform: "initial",
          fontFamily: "gilroy-regular",
        }}
      >
        Download
      </Button>
    </>
  );
};
