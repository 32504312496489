import React, { useState, createRef, useEffect, useCallback } from "react";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import {
  BigHeader,
  Container,
  TabContentWrapper,
  ProfileWrapper,
  WrapperLeft,
  WrapperCenter,
  WrapperRight,
  PageWrapper,
} from "../../../pages/Dashboard/styles/dashboard.style";
import GlobalStyles from "../../../assets/globalStyle";
import Loader from "../../pageLoader/loader";
import api from "../../../services/ControlPanel";
import { InputWrapper, AuthButton } from "../../../pages/auth/Login.style";
import colors from "../../../assets/colors.json";
import FormLoader from "../../FormLoader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ErrorService from "../../../services/errorService";
import Board from "./Board";
import SlidingImages from "./SlidingImages";
import ContactUs from "./ContactUs";
import Values from "./Values";
import Logo from "./Logo";
import News from "./News";
import { checkAccess } from "../../../services/utils";
const WebInfo = () => {
  const { admToken, accessObj } = useSelector((state) => state.auth);
  const [formLoading, setFormLoading] = useState(false);
  const [infoData, setInfoData] = useState(null);
  const [dataShow, setDataShow] = useState({
    accId: "",
    accName: "",
  });

  const [loading, setLoading] = useState(true);
  const getData = useCallback(async () => {
    try {
      console.log("working");
      // const res = await api.getWebInfoDetails(admToken, "Lasmab-website-info");
      const res = await api.getAdminWebInfo(admToken);
      console.log(res);
      if (res.status && res.statusCode === 2) {
        setInfoData(res.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      console.log("error");
    }
  }, [admToken]);
  useEffect(() => {
    getData();
  }, [getData]);

  const submitPrivacy = async () => {
    setFormLoading(true);
    // let data = {
    //   policyContent: privacyPolicy,
    //   // schoolId: user.schoolId,
    // };

    try {
      const res = await api.postControlPanelLasmabSetup(
        infoData?.policy,
        admToken,
        infoData.policy?.id ? "Update-Privacypolicy" : "Add-Privacy-Policy"
      );
      console.log(res);
      if (res.status && res.statusCode === 2) {
        toast.success(res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        getData();
      }

      if (res.status === 400 || res.status === 500 || res.status === false) {
        ErrorService.displayErrorAlert(res.data);
      }
      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);

      console.log(error);
    }
  };
  const submitTOS = async () => {
    setFormLoading(true);

    try {
      const res = await api.postControlPanelLasmabSetup(
        infoData?.terms,
        admToken,
        infoData?.terms?.id ? "Update-Terms" : "Add-Terms"
      );
      console.log(res);
      if (res.status && res.statusCode === 2) {
        toast.success(res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        getData();
      }

      // res.status && res.statusCode === 2 && resetBoardObj();

      res.status === 400 && ErrorService.displayErrorAlert(res.data);
      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);

      console.log(error);
    }
  };

  const submitAbout = async (data) => {
    setFormLoading(true);
    try {
      const res = await api.postControlPanelLasmabSetup(
        infoData?.aboutUs,

        admToken,
        infoData?.aboutUs?.id ? "Update-Lasmab-AboutUs" : "Add-lasmab-aboutUs"
      );
      if (res.status && res.statusCode === 2) {
        toast.success(res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        getData();
      }
      res.status === 400 && ErrorService.displayErrorAlert(res.data);
      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);
      console.log(error);
    }
  };

  const submitLogo = async (data) => {
    setFormLoading(true);
    try {
      const res = await api.postControlPanelLasmabSetup(
        data,
        admToken,
        "Update-LogoAndName"
      );
      if (res.status && res.statusCode === 2) {
        toast.success(res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        getData();
      }

      res.status === 400 && ErrorService.displayErrorAlert(res.data);
      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);

      console.log(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <GlobalStyles />
      <div>
        <BigHeader
          style={{
            borderBottom: `4px solid ${colors.primary}`,
            paddingBottom: "4px",
          }}
        >
          Web Info.
        </BigHeader>
      </div>
      <TabContentWrapper>
        <div style={{ marginTop: "10px" }}>
          <ProfileWrapper
            onClick={() => {
              if (dataShow.accId === 1 && dataShow.accName === "accOne") {
                setDataShow({
                  accId: "",
                  accName: "",
                });
              } else {
                setDataShow({
                  accId: 1,
                  accName: "accOne",
                });
              }
            }}
          >
            <WrapperLeft>Logo</WrapperLeft>
            <WrapperCenter></WrapperCenter>
            <WrapperRight>
              {dataShow.accId === 1 ? (
                <BiSolidUpArrow color="white" />
              ) : (
                <BiSolidDownArrow color="white" />
              )}
            </WrapperRight>
          </ProfileWrapper>
          {dataShow.accName === "accOne" && (
            <Logo
              obj={infoData?.websiteNameAndLogo}
              loading={formLoading}
              action={submitLogo}
            />
          )}
        </div>

        {/* Done Here */}
        {/* second Sliding Images */}
        {/* components */}
        <div style={{ marginTop: "5px" }}>
          <ProfileWrapper
            onClick={() => {
              if (dataShow.accId === 2 && dataShow.accName === "accTwo") {
                setDataShow({
                  accId: "",
                  accName: "",
                });
              } else {
                setDataShow({
                  accId: 2,
                  accName: "accTwo",
                });
              }
            }}
          >
            <WrapperLeft>Sliding Images</WrapperLeft>
            <WrapperCenter></WrapperCenter>
            <WrapperRight>
              {dataShow.accId === 2 ? (
                <BiSolidUpArrow color="white" />
              ) : (
                <BiSolidDownArrow color="white" />
              )}
            </WrapperRight>
          </ProfileWrapper>
          {dataShow.accName === "accTwo" && (
            <SlidingImages
              arr={infoData?.slideImages}
              loading={loading}
              formLoading={formLoading}
              action={getData}
            />
          )}
        </div>
        {/* Fourth Board */}
        {/* component */}
        <div style={{ marginTop: "5px" }}>
          <ProfileWrapper
            style={{ pointerEvents: formLoading && "none" }}
            onClick={() => {
              if (dataShow.accId === 4 && dataShow.accName === "accFour") {
                setDataShow({
                  accId: "",
                  accName: "",
                });
              } else {
                setDataShow({
                  accId: 4,
                  accName: "accFour",
                });
              }
            }}
          >
            <WrapperLeft>Board</WrapperLeft>
            <WrapperCenter></WrapperCenter>
            <WrapperRight>
              {dataShow.accId === 4 ? (
                <BiSolidUpArrow color="white" />
              ) : (
                <BiSolidDownArrow color="white" />
              )}
            </WrapperRight>
          </ProfileWrapper>
          {dataShow.accName === "accFour" && (
            <Board arr={infoData?.getBoardTeams} getData={getData} />
          )}
        </div>
        {/* third About Us */}
        <div style={{ marginTop: "5px", display: "none" }}>
          <ProfileWrapper
            onClick={() => {
              if (dataShow.accId === 3 && dataShow.accName === "accThree") {
                setDataShow({
                  accId: "",
                  accName: "",
                });
              } else {
                setDataShow({
                  accId: 3,
                  accName: "accThree",
                });
              }
            }}
          >
            <WrapperLeft>About Us</WrapperLeft>
            <WrapperCenter></WrapperCenter>
            <WrapperRight>
              {dataShow.accId === 3 ? (
                <BiSolidUpArrow color="white" />
              ) : (
                <BiSolidDownArrow color="white" />
              )}
            </WrapperRight>
          </ProfileWrapper>
          {dataShow.accName === "accThree" && (
            <PageWrapper>
              <InputWrapper>
                <label htmlFor="about-us">About Us</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  value={infoData?.aboutUs?.about_Us}
                  onChange={(e) => {
                    if (infoData?.aboutUs?.id) {
                      setInfoData({
                        ...infoData,
                        aboutUs: {
                          ...infoData?.aboutUs,
                          about_Us: e.target.value,
                          schoolId: 0,
                        },
                      });
                    } else {
                      setInfoData({
                        ...infoData,
                        aboutUs: {
                          ...infoData?.aboutUs,
                          id: 0,
                          about_Us: e.target.value,
                          schoolId: 0,
                        },
                      });
                    }
                  }}
                ></textarea>
              </InputWrapper>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <AuthButton
                  onClick={submitAbout}
                  disabled={
                    formLoading ||
                    infoData?.aboutUs === null ||
                    infoData?.aboutUs?.about_Us === ""
                  }
                >
                  {formLoading ? "Please Wait..." : "Update Info"}
                </AuthButton>
              </div>
            </PageWrapper>
          )}
        </div>

        {/* news */}
        <div style={{ marginTop: "5px" }}>
          <ProfileWrapper
            style={{ pointerEvents: formLoading && "none" }}
            onClick={() => {
              if (dataShow.accId === 8 && dataShow.accName === "accEight") {
                setDataShow({
                  accId: "",
                  accName: "",
                });
              } else {
                setDataShow({
                  accId: 8,
                  accName: "accEight",
                });
              }
            }}
          >
            <WrapperLeft>News</WrapperLeft>
            <WrapperCenter></WrapperCenter>
            <WrapperRight>
              {dataShow.accId === 8 ? (
                <BiSolidUpArrow color="white" />
              ) : (
                <BiSolidDownArrow color="white" />
              )}
            </WrapperRight>
          </ProfileWrapper>
          {dataShow.accName === "accEight" && (
            <News arr={infoData?.news} loading={loading} getData={getData} />
          )}
        </div>

        {/* contact us */}
        {/* Component */}
        <div style={{ marginTop: "5px" }}>
          <ProfileWrapper
            style={{ pointerEvents: formLoading && "none" }}
            onClick={() => {
              if (dataShow.accId === 5 && dataShow.accName === "accFive") {
                setDataShow({
                  accId: "",
                  accName: "",
                });
              } else {
                setDataShow({
                  accId: 5,
                  accName: "accFive",
                });
              }
            }}
          >
            <WrapperLeft>Contact Us</WrapperLeft>
            <WrapperCenter></WrapperCenter>
            <WrapperRight>
              {dataShow.accId === 5 ? (
                <BiSolidUpArrow color="white" />
              ) : (
                <BiSolidDownArrow color="white" />
              )}
            </WrapperRight>
          </ProfileWrapper>
          {dataShow.accName === "accFive" && (
            <ContactUs
              dataObj={infoData?.getBoardContactDetails}
              getData={getData}
            />
          )}
        </div>

        {/* values */}
        <div style={{ marginTop: "5px" }}>
          <ProfileWrapper
            style={{ pointerEvents: formLoading && "none" }}
            onClick={() => {
              if (dataShow.accId === 7 && dataShow.accName === "accSeven") {
                setDataShow({
                  accId: "",
                  accName: "",
                });
              } else {
                setDataShow({
                  accId: 7,
                  accName: "accSeven",
                });
              }
            }}
          >
            <WrapperLeft>Values</WrapperLeft>
            <WrapperCenter></WrapperCenter>
            <WrapperRight>
              {dataShow.accId === 7 ? (
                <BiSolidUpArrow color="white" />
              ) : (
                <BiSolidDownArrow color="white" />
              )}
            </WrapperRight>
          </ProfileWrapper>
          {dataShow.accName === "accSeven" && (
            <Values
              arr={infoData?.values}
              loading={loading}
              getData={getData}
            />
          )}
        </div>
        {/* privacy policy */}
        <div style={{ marginTop: "5px" }}>
          <ProfileWrapper
            style={{ pointerEvents: formLoading && "none" }}
            onClick={() => {
              if (dataShow.accId === 9 && dataShow.accName === "accNine") {
                setDataShow({
                  accId: "",
                  accName: "",
                });
              } else {
                setDataShow({
                  accId: 9,
                  accName: "accNine",
                });
              }
            }}
          >
            <WrapperLeft>Privacy, Policy</WrapperLeft>
            <WrapperCenter></WrapperCenter>
            <WrapperRight>
              {dataShow.accId === 9 ? (
                <BiSolidUpArrow color="white" />
              ) : (
                <BiSolidDownArrow color="white" />
              )}
            </WrapperRight>
          </ProfileWrapper>
          {dataShow.accName === "accNine" && (
            <PageWrapper>
              <InputWrapper>
                <label htmlFor="">Privacy Policy</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="3"
                  value={infoData?.policy?.policyContent}
                  onChange={(e) => {
                    if (infoData?.policy?.id) {
                      setInfoData({
                        ...infoData,
                        policy: {
                          ...infoData.policy,
                          policyContent: e.target.value,
                          schoolId: 0,
                        },
                      });
                    } else {
                      setInfoData({
                        ...infoData,
                        policy: {
                          ...infoData.policy,
                          policyContent: e.target.value,
                          schoolId: 0,
                        },
                      });
                    }
                  }}
                ></textarea>
              </InputWrapper>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: `2px solid ${colors.primary}`,
                  paddingBottom: "10px",
                }}
              >
                {checkAccess(accessObj.rights, "Save") && (
                  <AuthButton
                    disabled={formLoading}
                    onClick={() => submitPrivacy()}
                  >
                    {formLoading ? <FormLoader /> : "Update Info"}
                  </AuthButton>
                )}
              </div>
            </PageWrapper>
          )}
        </div>
        {/* terms of service */}
        <div style={{ marginTop: "5px" }}>
          <ProfileWrapper
            style={{ pointerEvents: formLoading && "none" }}
            onClick={() => {
              if (dataShow.accId === 10 && dataShow.accName === "accTen") {
                setDataShow({
                  accId: "",
                  accName: "",
                });
              } else {
                setDataShow({
                  accId: 10,
                  accName: "accTen",
                });
              }
            }}
          >
            <WrapperLeft>Terms of Service</WrapperLeft>
            <WrapperCenter></WrapperCenter>
            <WrapperRight>
              {dataShow.accId === 10 ? (
                <BiSolidUpArrow color="white" />
              ) : (
                <BiSolidDownArrow color="white" />
              )}
            </WrapperRight>
          </ProfileWrapper>
          {dataShow.accName === "accTen" && (
            <PageWrapper>
              <InputWrapper>
                <label htmlFor="">Terms Of Service</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="4"
                  value={infoData?.terms?.termContent}
                  onChange={(e) => {
                    // setInfoData
                    // setTOS(e.target.value);

                    if (infoData?.terms?.id) {
                      setInfoData({
                        ...infoData,
                        terms: {
                          ...infoData.terms,
                          termContent: e.target.value,
                          schoolId: 0,
                        },
                      });
                    } else {
                      setInfoData({
                        ...infoData,
                        terms: {
                          ...infoData.terms,
                          termContent: e.target.value,
                          schoolId: 0,
                        },
                      });
                    }
                  }}
                ></textarea>
              </InputWrapper>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: `2px solid ${colors.primary}`,
                  paddingBottom: "10px",
                }}
              >
                {checkAccess(accessObj.rights, "Save") && (
                  <AuthButton
                    disabled={formLoading}
                    onClick={() => submitTOS()}
                  >
                    {formLoading ? <FormLoader /> : "Update info"}
                  </AuthButton>
                )}
              </div>
            </PageWrapper>
          )}
        </div>
      </TabContentWrapper>
    </Container>
  );
};

export default WebInfo;
