import React, { useCallback, useState, useEffect } from "react";
import {
  AuthButton,
  InputWrapper,
  SmallText,
} from "../../../pages/auth/Login.style";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import api from "../../../services/ControlPanel";
import Loader from "../../pageLoader/loader";
import Select from "../../select/select";
import {
  AccountSummaryWrapper,
  Input,
  Search,
  TabContainer,
  Icon,
  TabLinkContainer,
  BigHeader,
} from "../../../pages/Dashboard/styles/dashboard.style";
import Modal from "../../modal/Modal";
import colors from "../../../assets/colors.json";
import { BsSearch } from "react-icons/bs";
import Pagination from "../../pagination_one/pagination";
import { checkAccess, paginateData } from "../../../services/utils";
import GlobalStyles from "../../../assets/globalStyle";
import FormLoader from "../../FormLoader";
import { toast } from "react-toastify";
import ErrorService from "../../../services/errorService";
const NewStaffs = () => {
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [formLoading, setFormLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const isTab = useMediaQuery({ maxWidth: 992 });
  const [showModal, setShowModal] = useState(false);
  const [itemOffSet, setitemOffSet] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [arr, setArr] = useState([]);
  const [obj, setObj] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoryId, setcategoryId] = useState("");
  const [roleId, setRoleId] = useState("");
  const [staffRoles, setStaffRoles] = useState([]);
  const [rolesLoading, setRolesLoading] = useState(false);
  const [sQuery, setSQuery] = useState("");

  const setupStaff = async () => {
    let data = {
      categoryId,
      // staffId: "",
      staffId: obj.staffId,
      schoolId: obj.schoolId,
      roleId,
    };
    try {
      setFormLoading(true);
      const res = await api.postSetupStaffAccount(admToken, data);
      if (res.status && res.statusCode === 2) {
        toast.success(res.message, {
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setShowModal(false);
        setObj(null);
        getNewStaff();
      }
      res.status === 500 && ErrorService.displayErrorAlert(res.data);
      res.status === 400 && ErrorService.displayErrorAlert(res.data);
      !res.status && ErrorService.displayErrorAlert(res);

      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);
      toast.error("An Occured, Please contact support");
    }
  };

  const getStaffCategories = useCallback(async () => {
    try {
      const res = await api.getStaffAccountCategories(admToken);
      if (res.status && res.statusCode === 2) {
        setCategories(res.data);
      } else {
        setCategories([]);
        toast.error("Error Getting Staff Account Categories");
      }
    } catch (error) {
      setCategories([]);
      toast.error(
        "Error Getting Staff Account Categories, Please Try Again Later"
      );
      // setPageLoading(false);
      console.log(error);
    }
  }, [admToken]);

  // Get All Staff Roles
  const getRoles = useCallback(
    async (id) => {
      setRolesLoading(true);
      try {
        const res = await api.getRoles(admToken, id);
        if (res.status && res.statusCode === 2) {
          setStaffRoles(res.data);
        } else {
          setCategories([]);
          toast.error("Please Create A Staff Role For This Category");
        }
        setRolesLoading(false);
      } catch (error) {
        toast.error("Error Getting Staff Roles, Please Try Again Later");
        setRolesLoading(false);
      }
    },
    [admToken]
  );

  const getNewStaff = useCallback(async () => {
    try {
      setLoading(true);
      const res = await api.contactList(admToken, user.schoolId);
      setAllData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [admToken, user.schoolId]);

  useEffect(() => {
    getNewStaff();
    getStaffCategories();
  }, [getNewStaff, getStaffCategories]);

  const filterSearch = useCallback(
    async (e) => {
      console.log(e.target.value);
      console.log("working");

      let arr = [];
      const queryRegex = new RegExp(e.target.value, "i");
      allData.forEach((item) => {
        Object.values(item).some((val) =>
          queryRegex.test(val) ? arr.push(item) : ""
        );
      });

      setArr(arr);
      setPageCount(0);
    },
    [allData]
  );
  const resetData = () => {
    setCurrentPage(1);
    console.log("reset data working");
    paginateData(allData, setArr, 1, itemOffSet, setitemOffSet, setPageCount);
  };
  useEffect(() => {
    paginateData(
      allData,
      setArr,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allData, currentPage, itemOffSet]);

  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }}>
        Setup Staff Role For {obj?.email} {obj?.phoneNumber}
      </BigHeader>
      <div>
        <InputWrapper>
          <label htmlFor="">Category</label>
          <Select
            title="Category"
            index="name"
            selected={categoryId}
            options={categories}
            setSelected={(val) => {
              setcategoryId(val.id);
              setRoleId("");
              getRoles(val.id);
            }}
          />
        </InputWrapper>
        {!rolesLoading ? (
          <InputWrapper>
            <label htmlFor="">
              Select{" "}
              {staffRoles.length === 0
                ? "A Category To Get Staff Roles"
                : "Staff Role"}
            </label>
            <Select
              title={
                staffRoles.length === 0
                  ? "A Category To Get Staff Roles"
                  : "Staff Role"
              }
              index="roleName"
              thirdOption="roleId"
              selected={roleId}
              options={staffRoles}
              setSelected={(val) => {
                setRoleId(val.roleId);
              }}
            />
          </InputWrapper>
        ) : (
          <SmallText>Loading Staff Roles</SmallText>
        )}
      </div>

      <div>
        <AuthButton
          onClick={setupStaff}
          width="100%"
          mt="0.6rem"
          disabled={formLoading || roleId === "" || categoryId === ""}
        >
          {formLoading ? <FormLoader /> : `Add Setup For ${obj?.email}`}
        </AuthButton>
      </div>
    </>
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <div style={{ marginTop: "0.5rem" }}>
      <GlobalStyles />

      {showModal && obj && (
        <Modal content={content} onClose={setShowModal} size={"large"} />
      )}
      <div style={{ display: "none", justifyContent: "end" }}>
        <AuthButton
          mt="0.4rem"
          pl="0px"
          width={isTab ? "50%" : "30%"}
          onClick={() => setShowForm(!showForm)}
        >
          <div>
            <span>Create New Staff</span>
          </div>
        </AuthButton>
      </div>

      <TabContainer style={{ paddingBottom: "0px" }}>
        <TabLinkContainer bg="rgb(238, 224,241)">
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search
              type="text"
              placeholder="Search For Staff "
              value={sQuery}
              onChange={(e) => {
                setSQuery(e.target.value);
                if (e.target.value.length > 2) {
                  filterSearch(e);
                }

                if (e.target.value === "") {
                  resetData();
                  console.log("hellow world inside jsx");
                }
              }}
            />
          </Input>
        </TabLinkContainer>

        <AccountSummaryWrapper pt="0px">
          <table
            style={{
              borderRadius: "0px",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <tr
              className="tabel-head"
              style={{ color: "black", fontWeight: "bold" }}
            >
              <th>S/N</th>
              <th>Name ({allData.length})</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th></th>
            </tr>

            {arr.length > 0 ? (
              arr.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.phoneNumber}</td>
                  <td style={{ paddingRight: "0.5rem" }}>
                    {checkAccess(accessObj.rights, "Create") && (
                      <button
                        style={{
                          borderRadius: "9px",
                          border: `1px solid ${colors.primary}`,
                          padding: "0.3rem 0.6rem",
                          background: colors.primary,
                          color: "white",
                        }}
                        onClick={() => {
                          setRoleId("");
                          setcategoryId("");
                          setObj(item);
                          setShowModal(true);
                        }}
                        disabled={
                          item.email === "" ||
                          item.email === null ||
                          item.phoneNumber === "" ||
                          item.phoneNumber === null
                        }
                      >
                        Create Staff Role
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} style={{ textAlign: "center" }}>
                  No New Staff Available
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      {pageCount > 1 && (
        <Pagination
          pageCount={pageCount > 1 && pageCount}
          currentPage={currentPage - 1}
          range={3}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};

export default NewStaffs;
