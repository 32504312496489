import React, { useState } from "react";
import {
  TabContainer,
  TabContent,
  TabLink,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import LinkedParent from "./LinkedParent";
import PersonalData from "./PersonalData";
import ReligionData from "./ReligionData";
import UnlinkedParent from "./UnlinkedParent";

const CreateNewParent = () => {
  const [tab, setTab] = useState(1);
  return (
    <TabContainer>
      <TabLinkContainer>
        <TabLink isActive={tab === 1} onClick={() => setTab(1)}>
          Personal Data
        </TabLink>
        <TabLink isActive={tab === 2} onClick={() => setTab(2)}>
         Religion Data
        </TabLink>
      </TabLinkContainer>
      {tab === 1 && (
        <TabContent>
          <PersonalData name="Classwork" />
        </TabContent>
      )}
      {tab === 2 && (
        <TabContent>
          <ReligionData name="Classwork" />
        </TabContent>
      )}

    </TabContainer>
  );
};

export default CreateNewParent;