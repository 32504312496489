import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import Title from "../Dashboard/Title";
import { a11yProps } from "../../pages/Dashboard/LessonNote";
import { TabPanel } from "../../pages/Dashboard/LessonNote";
import { darkTheme } from "../../pages/Dashboard/LessonNote";
import Academic from "./Academic";
import NonAcademic from "./NonAcademic";

const StaffReport = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <ThemeProvider theme={darkTheme}>
      <Box>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Staff report tabs"
            >
              <Tab
                label=" Academic"
                {...a11yProps(0)}
                sx={{
                  textTransform: "initial",
                  fontFamily: "Gilroy-Medium",
                  fontSize: "18px",
                }}
              />
              <Tab
                label="Non Academic"
                {...a11yProps(1)}
                sx={{
                  textTransform: "initial",
                  fontFamily: "Gilroy-Medium",
                  fontSize: "18px",
                }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Academic />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <NonAcademic />
          </TabPanel>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
export default StaffReport;
