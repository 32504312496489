import React, { useState, useEffect, useCallback } from "react";
import {
  TabContainer,
  // InlineFormDiv,
  AccountSummaryWrapper,
  InlineLabelGroup,
  InlineLabelWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
import FormLoader from "../../components/FormLoader";
import { toast } from "react-toastify";
// import Loader from "../../components/pageLoader/loader";
import {
  getSessions,
  getTerms,
  GetRemarkClasses,
  GetRemarks,
  RemarkGetStudent,
  PostRemarkStudent,
  UpdateStudentRemarkSingle,
} from "../../services/Subject";
import ErrorService from "../../services/errorService";
import { useSelector } from "react-redux";
import colors from "../../assets/colors.json";
// import { useMediaQuery } from "react-responsive";
import { InputWrapper, AuthButton } from "../../pages/auth/Login.style";
import Select from "../select/select";
import { ImgTextDiv } from "../../pages/student_mgt/stu_mgt.style";
import Avatar from "../../assets/Images/avatar.jpg";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { checkAccess } from "../../services/utils";
import { Pagination } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CreateIcon from "@mui/icons-material/Create";
import CloseIcon from "@mui/icons-material/Close";

const Remarks = () => {
  // const isMobile = useMediaQuery({ maxWidth: 992 });
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [showForm, setShowForm] = useState(true);
  const [terms, setTerms] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [allData, setAllData] = useState([]);
  const [remarks, setRemarks] = useState([]);
  const [marked, setMarked] = useState([]);
  const [classes, setClasses] = useState([]);
  const [loadClass, setLoadClass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [classObj, setClassObj] = useState({
    id: "",
    name: "",
    for: "",
  });
  const [session, setSession] = useState({
    id: "",
    name: "",
  });
  const [term, setTerm] = useState({
    id: "",
    name: "",
  });
  const [showEdit, setShowEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [singleSaveLoading, setSingleSaveLoading] = useState(false);
  const [singleRemarkId, setSingleRemarkId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRecords, setPageRecords] = useState(0);
  // const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false)

  const handleChange = (eventt, value) => {
    setCurrentPage(value);
    if (value !== currentPage) {
      getStudents(value);
    }
  };

  const isSelectDisabled = (i) => {
    if (i.selectedRemarkId === 0) {
      return false;
    } else {
      if (showEdit && editId === i.studentId) {
        return false;
      }
      return true;
    }
  };

  const schoolTerms = useCallback(async () => {
    try {
      const res = await getTerms(admToken);
      res.status &&
        res.statusCode === 2 &&
        setTerms(res.data?.filter((term) => term.isActive));
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);
  const schoolSessions = useCallback(async () => {
    try {
      const res = await getSessions(admToken);

      res.status && res.statusCode === 2 && setSessions(res.data);
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);
  const remarksGet = useCallback(async () => {
    try {
      const res = await GetRemarks(admToken);
      console.log(res);
      if (res.status && res.statusCode === 2) {
        setRemarks(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);

  const getClasses = useCallback(
    async (id) => {
      try {
        setLoadClass(true);
        const res = await GetRemarkClasses(admToken, user.schoolId, id);
        console.log(res);
        res.status && res.statusCode === 2 && setClasses(res.data);
        (res.status === 500 || res.status === 400 || !res.status) &&
          ErrorService.displayErrorAlert(res);
        setLoadClass(false);
      } catch (error) {
        console.log(error);
        setLoadClass(false);
      }
    },

    [admToken, user]
  );
  useEffect(() => {
    remarksGet();
    schoolTerms();
    schoolSessions();
    // getClasses();
  }, [schoolSessions, schoolTerms, remarksGet]);

  const mapMarkedFromApi = useCallback((arr) => {
    const newList = arr.map((item) => ({
      studentId: item.studentId,
      remarkId: item.selectedRemarkId,
      lock: item.activateUpdateButton === 1 ? true : false,
      status: "C",
    }));
    setMarked(newList);
  }, []);
  // const getStudents = () => {};
  const getStudents = useCallback(
    async (value) => {
      try {
        let data = {
          schoolId: user.schoolId,
          staffId: user.staffId,
          classId: classObj.id,
          sessionId: session.id,
          termId: term.id,
          recordType: 0,
          pagesSize: 10,
          pageNumber: value ?? currentPage,
        };

        setLoading(true);
        const res = await RemarkGetStudent(admToken, data);
        console.log(res);
        if (res.status && res.statusCode === 2) {
          setPageRecords(res?.totalRecord);
          setAllData(res.data);

          if (res.data.length > 0) {
            setShowForm(false);
            mapMarkedFromApi(res.data);
          }
        }
        (res.status === 500 || res.status === 400 || !res.status) &&
          ErrorService.displayErrorAlert(res);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
    [
      admToken,
      classObj.id,
      mapMarkedFromApi,
      session.id,
      term.id,
      user.schoolId,
      user.staffId,
    ]
  );

  const handleClick = (e, studentId) => {
    setSingleRemarkId(e.target.value);

    // if (e.target.value === "") {
    //   let filteredArr = marked.filter((i) => i.studentId !== studentId);
    //   setMarked([...filteredArr]);
    // } else {

    // stops here
    if (e.target.value === "") {
      let item = marked.find((i) => i.studentId === studentId);
      if (item) {
        let filteredArr = marked.filter((i) => i.studentId !== studentId);
        let obj = {
          remarkId: 0,
          status: item.status,
          studentId,
        };
        setMarked([...filteredArr, obj]);
      }
    } else {
      const selectId = parseInt(e.target.value);
      const selectedRemark = remarks.find((option) => option.id === selectId);
      if (selectedRemark) {
        let item = marked.find((i) => i.studentId === studentId);
        if (item) {
          let filteredArr = marked.filter((i) => i.studentId !== studentId);
          let obj = {
            remarkId: e.target.value === "" ? 0 : selectedRemark.id,
            status: selectedRemark.status,
            studentId,
          };
          setMarked([...filteredArr, obj]);
        } else {
          let obj = {
            remarkId: e.target.value === "" ? 0 : selectedRemark.id,
            status: selectedRemark.status,
            studentId,
          };
          setMarked([...marked, obj]);
        }
      }
    }

    // }
  };

  const findAnswerId = (id) => {
    if (marked.length > 0) {
      // const markedAnswers = marked.map
      const option = marked.find((i) => i.studentId === id);
      return option ? option.remarkId : "";
    } else {
      return "";
    }
  };

  const updateAction = useCallback(async () => {
    try {
      setFormLoading(true);
      let data = {
        schoolId: user.schoolId,
        termId: Number(term.id),
        classId: Number(classObj.id),
        sessionId: Number(session.id),
        updateRemarkList: marked,
      };

      const res = await PostRemarkStudent(admToken, data);
      console.log(res);

      if (res.status && res.statusCode === 2) {
        toast.success(res.message);
        // setMarked([]);
        getStudents(currentPage);
      }
      (res.status === 500 || res.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res);
      setFormLoading(false);
    } catch (error) {
      console.log(error);
      setFormLoading(false);

      toast.error("Please Try Again Later");
    }
  }, [admToken, classObj.id, marked, session.id, term.id, user.schoolId]);

  const updateSingleRemark = async (item) => {
    // console.log(item);
    let data = {
      schoolId: item.schoolId,
      classId: classObj.id,
      sessionId: session.id,
      termId: term.id,
      updateRemark: {
        remarkId: singleRemarkId,
        status: item.remarkType,
        studentId: item.studentId,
        isDeleted: item.remarkId === 0 ? true : false,
      },
    };
    setSingleSaveLoading(true);
    try {
      const res = await UpdateStudentRemarkSingle(admToken, data);
      if (res.status && res.statusCode === 2) {
        getStudents(currentPage);
        setEditId(item.studentId);
        setShowEdit(false);
      } else if (
        res.status === 500 ||
        res.status === 400 ||
        res.data.status === 400 ||
        !res.status
      ) {
        ErrorService.displayErrorAlert(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSingleSaveLoading(false);
    }
  };

  return (
    <div>
      <div
        onClick={() => setShowForm(!showForm)}
        style={{
          backgroundColor: colors.primary,
          borderRadius: "10px",
          marginTop: "0.3rem",
          padding: "0.4rem",
          display: "flex",
          justifyContent: "space-between",
          color: "white",
          fontWeight: "bold",
        }}
      >
        <div>
          <span>
            {session.name} {session.name.length > 0 ? "-" : ""}
            {term.name} {term.name.length > 0 ? "" : ""}
            {classObj.name.length > 0 ? "-" : ""}
            {classObj.name} {classObj.for.length > 0 ? " - " : ""}
            {classObj.for}
          </span>
        </div>
        {showForm ? (
          <BiSolidDownArrow color="white" />
        ) : (
          <BiSolidUpArrow color="white" />
        )}
      </div>
      {showForm && (
        <>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={2}>
              <InlineLabelGroup>
                <InlineLabelWrapper flex={1}>
                  {" "}
                  <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                    <Select
                      title="Session"
                      index="session" // no
                      selected={session.id} // false
                      options={sessions.filter((i) => i.isActive)}
                      setSelected={(val) => {
                        setSession({
                          ...session,
                          id: val.id,
                          name: val.session,
                        });
                        getClasses(val.id);
                        setAllData([]);
                      }}
                      // color="white"
                    />
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                    <Select
                      title="Term"
                      index="term" // no
                      selected={term.id} // false
                      options={terms}
                      setSelected={(val) => {
                        setTerm({ ...term, id: val.id, name: val.term });
                        setAllData([]);
                        setPageRecords(0);
                      }}
                      // color="white"
                    />
                  </InputWrapper>
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title={loadClass ? "Loading" : "Class"}
                  index="className" // no
                  selected={classObj.id} // false
                  options={classes}
                  thirdOption="classId"
                  extraTitle="classArm"
                  setSelected={(val) => {
                    // console.log("vbv", val);
                    setAllData([]);
                    setClassObj({
                      ...classObj,
                      id: val.classId,
                      name: `${val.className} ${val.classArm}`,
                      for: `${val.classFor}`,
                    });
                    setPageRecords(0);
                    // getStudents(val.classId);
                  }}
                  // color="white"
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>

          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              <AuthButton
                width="100%"
                mt="0.4rem"
                onClick={() => getStudents()}
                disabled={
                  loading ||
                  classObj.id === "" ||
                  term.id === "" ||
                  session.id === ""
                }
              >
                <div style={{ textAlign: "center" }}>
                  {loading ? (
                    <FormLoader color={colors.primary} />
                  ) : (
                    "Load Student"
                  )}
                </div>
              </AuthButton>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </>
      )}

      <TabContainer style={{ borderRadius: "20px", paddingBottom: "0px" }}>
        <AccountSummaryWrapper pt="0px">
          <table style={{ borderRadius: "20px" }}>
            <tbody>
              <tr className="table-head" style={{ color: "black" }}>
                <th>Students ({pageRecords})</th>

                {classObj.for === "Principal" && <th>Avg %</th>}

                {classObj.for === "Principal" && <th>Attendance</th>}

                <th>Remarks</th>
                <th>Actions</th>
              </tr>
              {allData.length > 0 ? (
                <>
                  {allData.map((i, index) => (
                    <tr key={index}>
                      <td>
                        <ImgTextDiv>
                          <span
                            style={{
                              marginRight: "0.6rem",
                              fontSize: "1.3rem",
                            }}
                          >
                            {index + 1}.
                          </span>
                          <img src={Avatar} alt="" />
                          <div>
                            <p>{i.fullName}</p>
                            <p style={{ fontSize: "0.8rem" }}>
                              {i.admissionNo}
                            </p>
                          </div>
                        </ImgTextDiv>
                      </td>

                      {classObj.for === "Principal" && (
                        <td>{i.presentScore}</td>
                      )}

                      {classObj.for === "Principal" && (
                        <td>{i.presentAttendance}</td>
                      )}

                      <td style={{ paddingRight: "0.2rem" }}>
                        <select
                          disabled={
                            (classObj.for === "Principal" &&
                              (i.presentScore === null ||
                                i.presentScore === 0 ||
                                i.presentScore === "")) ||
                            allData.some((i) => i.activateUpdateButton === 1) ||
                            isSelectDisabled(i)
                          }
                          style={{ maxWidth: "200px" }}
                          name=""
                          id=""
                          value={findAnswerId(i.studentId)}
                          onChange={(e) => handleClick(e, i.studentId)}
                        >
                          <option value="" selected>
                            Choose Remark
                          </option>
                          {remarks.length > 0 &&
                            remarks.map((item, index) => (
                              <option
                                value={item.id}
                                key={index}
                                name={item.remarks}
                              >
                                {item.remarks}
                              </option>
                            ))}
                        </select>
                      </td>
                      <td>
                        {i.selectedRemarkId === 0 ? (
                          <button
                            disabled={allData.some(
                              (i) => i.activateUpdateButton === 1
                            )}
                            onClick={() => updateSingleRemark(i)}
                          >
                            {singleSaveLoading ? (
                              <FormLoader color={colors.primary} />
                            ) : (
                              <SaveIcon className="text-[#671E78]" />
                            )}
                          </button>
                        ) : (
                          <>
                            {showEdit && editId === i.studentId ? (
                              <div className="flex justify-center">
                                <div className="flex gap-5 items-center">
                                  <button
                                    className="border-[#671E78] border px-[10px] py-[5px] rounded-sm"
                                    onClick={() => {
                                      updateSingleRemark(i);
                                    }}
                                  >
                                    {singleSaveLoading ? (
                                      <FormLoader color={colors.primary} />
                                    ) : (
                                      <SaveIcon className="text-[#671E78]" />
                                    )}
                                  </button>
                                  <button
                                    className="border-[#671E78] border px-[10px] py-[5px] rounded-sm"
                                    onClick={() => {
                                      setShowEdit(false);
                                      setEditId("");
                                    }}
                                  >
                                    <CloseIcon className="text-[#671E78]" />
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <button
                                disabled={allData.some(
                                  (i) => i.activateUpdateButton === 1
                                )}
                                onClick={() => {
                                  setShowEdit(true);
                                  setEditId(i.studentId);
                                }}
                              >
                                <CreateIcon className="text-[#671E78]" />
                              </button>
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={6} style={{ textAlign: "center" }}>
                    Students List Are Not Available
                  </td>
                </tr>
              )}

              {/* <tr>
              <td>
                <ImgTextDiv>
                  <img src={Avatar} alt="" />
                  <span>Adekitan Lagbaja</span>
                </ImgTextDiv>
              </td>
              <td>100%</td>
              <td>100%</td>
              <td>123</td>
              <td>123</td>
              <td>
                <select name="" id="">
                  <option value="">Choose Remark</option>
                  <option value="hardworking">HardWorking Students</option>
                  <option value="hardworking">HardWorking Parents</option>
                </select>
              </td>
            </tr> */}
            </tbody>
          </table>
        </AccountSummaryWrapper>
        <Pagination
          count={Math.ceil(pageRecords / 10)}
          page={currentPage}
          onChange={handleChange}
        />
      </TabContainer>
      {allData.length > 0 && checkAccess(accessObj.rights, "Update") && (
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}
        >
          {allData.some((i) => i.selectedRemarkId === 0) && (
            <AuthButton
              width="150px"
              onClick={updateAction}
              disabled={
                marked.length === 0 ||
                formLoading ||
                allData.some((i) => i.activateUpdateButton === 1)
              }
            >
              {" "}
              {formLoading ? (
                <FormLoader />
              ) : allData.some((i) => i.activateUpdateButton === 1) ? (
                "Locked"
              ) : (
                "Save All"
              )}
            </AuthButton>
          )}
        </div>
      )}
    </div>
  );
};

export default Remarks;
