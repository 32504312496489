import React, { useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  Chip,
  List,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import img from "../../assets/Images/avatar.jpg";
import { useLocation, useSearchParams } from "react-router-dom";

const sideBar_Data = [
  {
    label: "Admission Form",
    id: 1,
    // link: "/parentmanagement/admission/dashboard/admissionform",
    // selected: true,
  },
  {
    label: "Examination Slip",
    id: 2,
    // link: "/parentmanagement/admission/dashboard/examinationslip",
    // selected: false,
  },
  {
    label: "Report",
    id: 3,
    // link: "/parentmanagement/admission/dashboard/report",
    // selected: false,
  },
];
const AdSideBar = () => {
  const theme = useTheme();
  const location = useLocation();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const tab_id = Number(new URLSearchParams(location.search).get("tab")) || 1;
  const page_id = Number(new URLSearchParams(location.search).get("page")) || 1;
  const adm_id = new URLSearchParams(location.search).get("admission_id");

  const [searchParams, setSearchParams] = useSearchParams();
  // const router = useLocation();
  return (
    <>
      {isMatch ? (
        <Box></Box>
      ) : (
        <Box
          sx={{
            bgcolor: "#5e9c09",
            height: "100%",
            borderRadius: "15px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            mt={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar src={img} sx={{ width: "90px", height: "90px" }} />
            <Typography
              variant="body1"
              color="initial"
              sx={{ fontFamily: "Gilroy-Medium", fontWeight: "bolder" }}
            >
              Adeolu James
            </Typography>
            <Chip label="Aged:12" sx={{ color: "#fff", fontSize: "10px" }} />
          </Box>

          <Box sx={{ width: "100%", pl: 3, mt: 5 }}>
            <List>
              {sideBar_Data.map((nav, index) => (
                // <Link to={nav.link} key={index}>
                <ListItemButton
                  key={index}
                  onClick={() => {
                    console.log("Hello World");
                    setSearchParams({
                      ...searchParams,
                      page: page_id,
                      tab: nav.id,
                      admission_id: adm_id,
                    });
                  }}
                  selected={tab_id === nav.id ? true : false}
                  sx={{
                    pl: 5,
                    " 	&.Mui-selected": {
                      bgcolor: "#fff",
                      borderRadius: "20px 0px 0px 20px",
                    },
                  }}
                >
                  <ListItemText
                    sx={{ color: "#000", fontFamily: "Gilroy-Medium" }}
                  >
                    {nav.label}{" "}
                  </ListItemText>
                </ListItemButton>
                // </Link>
              ))}
            </List>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AdSideBar;
