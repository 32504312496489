import React, { useState, useCallback, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  InlineLabelGroup,
  InlineLabelWrapper,
  AccountSummaryWrapper,
  Container,
  Icon,
  Input,
  Search,
  TabContainer,
  TabLinkContainer,
} from "../../../pages/Dashboard/styles/dashboard.style";
import GlobalStyles from "../../../assets/globalStyle";
import Title from "../../Dashboard/Title";
import { getSessions } from "../../../services/Subject";
import { useSelector } from "react-redux";
import Select from "../../select/select";
import Loader from "../../pageLoader/loader";
import ErrorService from "../../../services/errorService";
import { getAllClasses } from "../../../services/Subject";
import { InputWrapper } from "../../../pages/auth/Login.style";
import colors from "../../../assets/colors.json";
import { BsSearch } from "react-icons/bs";
import api from "../../../services/ControlPanel";
import hostelApi from "../../../services/hostel";
// import CreateIcon from "@mui/icons-material/Create";
import SaveIcon from "@mui/icons-material/Save";
// import CloseIcon from "@mui/icons-material/Close";
import FormLoader from "../../FormLoader";
import { toast } from "react-toastify";
import { paginateData } from "../../../services/utils";
import Pagination from "../../pagination_one/pagination";

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
  },
});

const HostelManagement = () => {
  const { admToken, user } = useSelector((state) => state.auth);
  const [allData, setAllData] = useState([]);
  const [sQuery, setSQuery] = useState("");
  const [loadClass, setLoadClass] = useState(false);
  const [classes, setClasses] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [classObj, setClassObj] = useState({
    id: "",
    name: "",
  });
  const [session, setSession] = useState({
    id: "",
    name: "",
  });
  const [loading, setLoading] = useState(false);
  const [hostels, setHostels] = useState([]);
  const [floors, setFloors] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [beds, setBeds] = useState([]);
  const [assignLoading, setAssignLoading] = useState(false);
  const [clickedButtonId, setClickedButtonId] = useState("");
  const [itemOffSet, setitemOffSet] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [arr, setArr] = useState([]);
  const resetData = () => {
    setCurrentPage(1);
    console.log("reset data working");
    paginateData(allData, setArr, 1, itemOffSet, setitemOffSet, setPageCount);
  };
  const filterSearch = useCallback(
    async (e) => {
      let arr = [];
      const queryRegex = new RegExp(e.target.value, "i");
      allData.forEach((item) => {
        Object.values(item).some((val) =>
          queryRegex.test(val) ? arr.push(item) : ""
        );
      });
      setArr(arr);
      setPageCount(0);
    },
    [allData]
  );

  const schoolSessions = useCallback(async () => {
    try {
      const res = await getSessions(admToken);
      res.status && res.statusCode === 2 && setSessions(res.data);
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);
  const getClasses = useCallback(async () => {
    try {
      setLoadClass(true);
      const res = await getAllClasses(admToken, user.schoolId);
      res.status &&
        res.statusCode === 2 &&
        setClasses([
          { className: "Select Class", classId: 0, classArm: "" },
          ...res.data,
        ]);
      (res.status === 500 || res.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res);
      setLoadClass(false);
    } catch (error) {
      console.log(error);
      setLoadClass(false);
    }
  }, [admToken, user]);

  const getHostels = useCallback(async () => {
    try {
      const res = await api.getAllHostels(admToken, user.schoolId);
      if (res.status && res.statusCode === 2) {
        setHostels(res.data);
      }
      res.status === 500 ||
        res.status === 400 ||
        (!res.status && ErrorService.displayErrorAlert(res));
    } catch (error) {
      console.log(error);
    }
  }, [admToken, user]);

  const getHostelFloors = useCallback(
    async (hostelId, index) => {
      try {
        const res = await hostelApi.getHostelFloors(
          admToken,
          user.schoolId,
          hostelId
        );
        if (res.status && res.statusCode === 2) {
          setFloors(res.data);
          const updatedData = [...allData];
          updatedData[index].floors = res.data;
          setAllData(updatedData);
          setFloors(res.data);
        } else {
          ErrorService.displayErrorAlert(res);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [admToken, user.schoolId, allData]
  );

  const getRooms = useCallback(
    async (hostelId, floorId, index) => {
      try {
        const res = await hostelApi.getRooms(
          admToken,
          user.schoolId,
          hostelId,
          floorId
        );
        if (res.status && res.statusCode === 2) {
          setRooms(res.data);
          const updatedData = [...allData];
          updatedData[index].rooms = res.data;
          setAllData(updatedData);
        } else {
          ErrorService.displayErrorAlert(res);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [admToken, user.schoolId, allData]
  );

  const getBeds = useCallback(
    async (hostelId, roomId, index) => {
      try {
        const res = await hostelApi.getBeds(
          admToken,
          user.schoolId,
          hostelId,
          roomId
        );
        if (res.status && res.statusCode === 2) {
          setBeds(res.data);
          const updatedData = [...allData];
          updatedData[index].beds = res.data;
          setAllData(updatedData);
        } else {
          ErrorService.displayErrorAlert(res);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [admToken, user.schoolId, allData]
  );

  const getStudentsList = useCallback(
    async (classId) => {
      setLoading(true);
      try {
        const res = await api.hostelAccomodationStudentsList(
          admToken,
          user.schoolId,
          classId,
          session.id
        );
        if (res.status && res.statusCode === 2) {
          // Add the confirmedAssignment flag for each student
          const studentsWithFlag = res.data.map((student) => ({
            ...student,
            confirmedAssignment: Boolean(
              student.hostelId &&
                student.floorId &&
                student.roomId &&
                student.bedId
            ),
          }));
          setAllData(studentsWithFlag);
        }
        (res.status === 500 || res.status === 400 || !res.status) &&
          ErrorService.displayErrorAlert(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [admToken, session.id, user.schoolId]
  );

  const assignHostel = useCallback(
    async (student) => {
      const data = {
        studentName: student.studentName,
        studentId: student.studentId,
        admissionNumber: student.admissionNumber,
        hostelId: student.hostelId,
        floorId: student.floorId,
        roomId: student.roomId,
        bedId: student.bedId,
        schoolId: student.schoolId,
        sessionId: session.id,
        isUnAssign: false,
      };
      setAssignLoading(true);
      try {
        const res = await hostelApi.allocateHostel(admToken, data);
        if (res.status && res.statusCode === 2) {
          toast.success(res.message);
          const updatedData = allData.map((item) =>
            item.studentId === student.studentId
              ? { ...item, confirmedAssignment: true }
              : item
          );
          setAllData(updatedData);
        }
        if (!res.status && res.statusCode === 6) {
          toast.warn(res.message);
        }
        if (
          res.status === 500 ||
          res.status === 400 ||
          res.data.status === 400 ||
          !res.status
        ) {
          ErrorService.displayErrorAlert(res.data);
        }
        setAssignLoading(false);
      } catch (error) {
        setAssignLoading(false);
      }
    },
    [admToken, allData, session.id]
  );

  const unassignHostel = useCallback(
    async (student) => {
      const data = {
        studentName: student.studentName,
        studentId: student.studentId,
        admissionNumber: student.admissionNumber,
        hostelId: student.hostelId,
        floorId: student.floorId,
        roomId: student.roomId,
        bedId: student.bedId,
        schoolId: student.schoolId,
        sessionId: session.id,
        isUnAssign: true,
      };
      setAssignLoading(true);
      try {
        const res = await hostelApi.allocateHostel(admToken, data);
        if (res.status && res.statusCode === 2) {
          toast.success(res.message);
          const updatedData = allData.map((item) =>
            item.studentId === student.studentId
              ? { ...item, confirmedAssignment: false }
              : item
          );
          getStudentsList(classObj.id);
          setAllData(updatedData);
        } else {
          ErrorService.displayErrorAlert(res);
        }
        setAssignLoading(false);
        setClickedButtonId("");
      } catch (error) {
        console.log(error);
        setAssignLoading(false);
      }
    },
    [admToken, user.schoolId, allData]
  );

  useEffect(() => {
    schoolSessions();
    getClasses();
    getHostels();
    getHostelFloors();
    getRooms();
    getBeds();
  }, [
    schoolSessions,
    getClasses,
    getHostels,
    getHostelFloors,
    getRooms,
    getBeds,
  ]);

  useEffect(() => {
    if (classObj.id && session.id) {
      getStudentsList(classObj.id);
    }
  }, [classObj.id, session.id, getStudentsList]);
  useEffect(() => {
    paginateData(
      allData,
      setArr,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allData, currentPage, itemOffSet]);

  useEffect(() => {
    if (classObj.id) {
      getStudentsList(classObj.id);
    }
  }, [classObj, getStudentsList]);
  return (
    <Container>
      <GlobalStyles />
      <ThemeProvider theme={darkTheme}>
        <Title title="Hostel" showCaption={false} />
        <InlineLabelGroup>
          <InlineLabelWrapper flex={2}>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                {" "}
                <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                  <Select
                    title="Session"
                    index="session" // no
                    selected={session.id} // false
                    options={sessions}
                    setSelected={(val) => {
                      setSession({
                        ...session,
                        id: val.id,
                        name: val.session,
                      });
                      // getClasses(val.id);
                      setAllData([]);

                      setSession({
                        ...session,
                        id: val.id,
                        name: val.session,
                      });
                      //   if (classObj.name.length > 0) {
                      //     getResults(val.id, classObj.id);
                      //   }
                    }}
                    // color="white"
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                  <Select
                    title={loadClass ? "Loading" : "Class"}
                    index="className" // no
                    selected={classObj.id} // false
                    options={classes}
                    thirdOption="classId"
                    // extraTitle="classArm"
                    setSelected={(val) => {
                      console.log(val);
                      setAllData([]);
                      setClassObj({
                        ...classObj,
                        id: val.classId,
                        name: `${val.className} ${val.classArm}`,
                      });
                      if (session.name.length > 0) {
                        getStudentsList(val.classId);
                      }
                      // getStudents(val.classId);
                    }}
                    // color="white"
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
          </InlineLabelWrapper>
        </InlineLabelGroup>
        <TabContainer
          style={{
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
            paddingBottom: "0px",
          }}
        >
          <TabLinkContainer bg="rgb(238, 224,241)">
            <Input>
              <Icon>
                <BsSearch />
              </Icon>
              <Search
                type="text"
                placeholder="Search For Student"
                value={sQuery}
                onChange={(e) => {
                  setSQuery(e.target.value);
                  if (e.target.value.length > 2) {
                    filterSearch(e);
                  }

                  if (e.target.value === "") {
                    resetData();
                    console.log("hellow world inside jsx");
                  }
                }}
              />
            </Input>
          </TabLinkContainer>
          <AccountSummaryWrapper pt="0px">
            {loading ? (
              <Loader />
            ) : (
              <table
                style={{
                  borderRadius: "0px",
                  borderBottomLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                }}
              >
                <tr
                  className="tabel-head"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  <th>S/N</th>
                  <th>Student</th>
                  <th>Admission No</th>
                  <th>Hostel</th>
                  <th>Floor</th>
                  <th>Room</th>
                  <th>Bed</th>
                  <th>Action</th>
                </tr>
                {arr.length > 0 ? (
                  arr?.map((student, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{student.studentName}</td>
                      <td>{student.admissionNumber}</td>
                      <td>
                        <select
                          onChange={(e) => {
                            const selectedHostelId = parseInt(e.target.value);
                            const updatedData = [...allData];
                            updatedData[i].hostelId = selectedHostelId;
                            updatedData[i].hostelName =
                              e.target.selectedOptions[0].innerText;
                            updatedData[i].floorId = "";
                            updatedData[i].roomId = "";
                            updatedData[i].bedId = "";
                            setAllData(updatedData);
                            getHostelFloors(e.target.value, i);
                          }}
                          value={student.hostelId || ""}
                        >
                          <option value={0}>Select</option>
                          {hostels?.map((each, i) => (
                            <option key={i} value={each.hostel_id}>
                              {each.hostel_name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <select
                          disabled={!student.hostelId}
                          onChange={(e) => {
                            const selectedFloorId = parseInt(e.target.value);
                            const updatedData = [...allData];
                            updatedData[i].floorId = selectedFloorId;
                            updatedData[i].floorName =
                              e.target.selectedOptions[0].innerText;
                            updatedData[i].roomId = "";
                            updatedData[i].bedId = "";
                            setAllData(updatedData);
                            getRooms(student.hostelId, e.target.value, i);
                          }}
                          value={student.floorId || ""}
                          className="min-w-[120px]  text-sm capiitalize"
                        >
                          <option value={0}>Select</option>
                          {(student.floors || floors).map((each, i) => (
                            <option key={i} value={each.floorId}>
                              {each.floorName}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <select
                          disabled={!student.floorId}
                          onChange={(e) => {
                            const selectedRoomId = parseInt(e.target.value);
                            const updatedData = [...allData];
                            updatedData[i].roomId = selectedRoomId;
                            updatedData[i].roomName =
                              e.target.selectedOptions[0].innerText;
                            updatedData[i].bedId = "";
                            setAllData(updatedData);
                            getBeds(student.hostelId, e.target.value, i);
                          }}
                          value={student.roomId || ""}
                          className="min-w-[120px]  text-sm capiitalize"
                        >
                          <option value={0}>Select</option>
                          {(student.rooms || rooms).map((each, i) => (
                            <option key={i} value={each.roomid}>
                              {each.roomname}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <select
                          disabled={!student.roomId}
                          onChange={(e) => {
                            const selectedBedId = parseInt(e.target.value);
                            const updatedData = [...allData];
                            updatedData[i].bedId = selectedBedId;
                            updatedData[i].bedName =
                              e.target.selectedOptions[0].innerText;
                            setAllData(updatedData);
                          }}
                          value={student.bedId || ""}
                          className="min-w-[120px]  text-sm capiitalize"
                        >
                          <option value={0}>Select</option>
                          {(student.beds || beds).map((each, i) => (
                            <option key={i} value={each.bedId}>
                              {each.bedtype}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <button
                          disabled={
                            assignLoading &&
                            clickedButtonId === student.studentId
                          }
                          className={`${
                            student.confirmedAssignment
                              ? " border-[#671E78] bg-white"
                              : "bg-[#671E78] text-white "
                          }  border px-[10px] py-[5px] rounded-lg w-[83px]`}
                          onClick={() => {
                            setClickedButtonId(student.studentId);
                            if (student.confirmedAssignment) {
                              unassignHostel(student);
                            } else {
                              assignHostel(student);
                            }
                          }}
                        >
                          {assignLoading &&
                          student.studentId === clickedButtonId ? (
                            <FormLoader color={colors.primary} />
                          ) : student.confirmedAssignment ? (
                            "Unassign"
                          ) : (
                            "Assign"
                          )}
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: "center" }}>
                      No Students At The Moment
                    </td>
                  </tr>
                )}
              </table>
            )}
          </AccountSummaryWrapper>
        </TabContainer>
        {pageCount > 1 && (
          <div
            style={{
              marginTop: "2px",
              marginBottom: "2px",
            }}
          >
            <Pagination
              pageCount={pageCount > 1 && pageCount}
              currentPage={currentPage - 1}
              range={3}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
      </ThemeProvider>
    </Container>
  );
};

export default HostelManagement;
