import { useEffect, useState, useCallback } from "react";
import Modal from "../modal/Modal";
import {
  BigHeader,
  Container,
  TabContainer,
  AccountSummaryWrapper,
  TabLinkContainer,
  Icon,
  Input,
  Search,
} from "../../pages/Dashboard/styles/dashboard.style";
import { BsSearch } from "react-icons/bs";
import Loader from "../pageLoader/loader";
import PropTypes from "prop-types";
import Select from "../select/select";
import colors from "../../assets/colors.json";
import { AuthButton, InputWrapper } from "../../pages/auth/Login.style";
import {
  AddBookSetupType,
  DelBookSetupByID,
  GetBookSetup,
  PutBookSetupType,
} from "../../services/Asset";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import FormLoader from "../FormLoader";
import ErrorService from "../../services/errorService";

import { paginateData } from "../../services/utils";
import Pagination from "../pagination_one/pagination";

const Category = ({ arr, loading }) => {
  const initialObj = {
    categoryName: "",
    description: "",
    schoolId: "",
  };
  const [pageLoading, setPageLoading] = useState(true);
  const [id, setId] = useState("");
  const [items, setItems] = useState([]);
  const [list, setList] = useState([]);
  const { admToken } = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [obj, setObj] = useState(initialObj);
  const [schoolId, setSchoolId] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffSet, setitemOffSet] = useState(0);

  useEffect(() => {
    paginateData(
      items,
      setList,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [items, currentPage]);
  const content = (
    <>
      <BigHeader fs="20px" style={{ textAlign: "center" }}>
        {obj.id ? "Edit" : "Add"} Category
      </BigHeader>
      <div>
        {loading ? (
          <p
            style={{
              fontWeight: "bold",
              color: colors.primary,
              textAlign: "center",
            }}
          >
            Loading Schools...
          </p>
        ) : (
          <InputWrapper pt="0px">
            <label htmlFor="">Select School</label>
            <Select
              title="School"
              thirdOption="schoolId"
              index="schoolName"
              selected={obj.schoolId}
              options={arr}
              setSelected={(val) => {
                setObj({ ...obj, schoolId: val.schoolId.toString() });
              }}
            />
          </InputWrapper>
        )}
        <InputWrapper>
          <label htmlFor="">Category Name</label>
          <input
            type="text"
            value={obj.categoryName}
            placeholder="Category Name"
            onChange={(e) => setObj({ ...obj, categoryName: e.target.value })}
          />
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="">Description</label>
          <textarea
            name=""
            id=""
            cols="30"
            rows="4"
            style={{ resize: "none" }}
            value={obj.description}
            onChange={(e) => setObj({ ...obj, description: e.target.value })}
          ></textarea>
        </InputWrapper>
        <div>
          <AuthButton
            onClick={() => (obj.id ? editSection() : addSection())}
            width="100%"
            mt="0.6rem"
            disabled={
              formLoading ||
              obj.categoryName === "" ||
              obj.description === "" ||
              obj.schoolId === ""
            }
          >
            {formLoading ? (
              <FormLoader />
            ) : (
              <span>{obj.id ? "Edit" : "Add"} Category </span>
            )}
          </AuthButton>
        </div>
      </div>
    </>
  );
  const addSection = async () => {
    try {
      console.log(obj);
      setFormLoading(true);
      const res = await AddBookSetupType(admToken, obj, "AddCategory");
      setFormLoading(false);

      res.status &&
        res.statusCode === 2 &&
        toast.success(res.message) &&
        setShowModal(false);
      res.status && res.statusCode === 2 && getSections();
      !res.status && toast.error(res.message);

      res.status === 400 && ErrorService.displayErrorAlert(res);

      console.log(res);
    } catch (error) {
      setFormLoading(false);
    }
  };

  const editSection = async () => {
    try {
      console.log(obj);
      setFormLoading(true);
      const res = await PutBookSetupType(admToken, obj, "UpdateCategory");
      setFormLoading(false);

      res.status &&
        res.statusCode === 2 &&
        toast.success(res.message) &&
        setShowModal(false);

      res.status && res.statusCode === 2 && getSections();
      !res.status && toast.error(res.message);

      res.status === 400 && ErrorService.displayErrorAlert(res);

      console.log(res);
    } catch (error) {
      setFormLoading(false);
    }
  };
  const delSection = async (id) => {
    try {
      console.log(obj);
      setFormLoading(true);
      const res = await DelBookSetupByID(admToken, id, "DeleteCategory");
      setFormLoading(false);

      res.status &&
        res.statusCode === 2 &&
        toast.success(res.message) &&
        getSections();
      !res.status && toast.error(res.message);

      res.status === 400 && ErrorService.displayErrorAlert(res);

      console.log(res);
    } catch (error) {
      setFormLoading(false);
    }
  };

  const getSections = useCallback(async () => {
    try {
      const res = await GetBookSetup(admToken, "ListCategories");
      console.log(res);
      res.status && res.statusCode === 2 && setItems(res.data);
      (!res.status || res.status === 400 || res.status === 500) &&
        toast.error("An Error  Occurred, Please try again later");
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.log(error);
    }
  }, [admToken]);

  useEffect(() => {
    getSections();
  }, []);

  if (pageLoading) {
    return <Loader />;
  }

  return (
    <Container>
      {showModal && (
        <Modal
          content={content}
          show={showModal}
          size={"large"}
          onClose={setShowModal}
        />
      )}
      <div style={{ display: "flex", justifyContent: "end" }}>
        <AuthButton
          onClick={() => {
            setObj(initialObj);
            setShowModal(!showModal);
          }}
        >
          Add Category
        </AuthButton>
      </div>

      <div style={{ width: "100%", padding: "0.2rem 0.4rem" }}>
        <InputWrapper>
          <label htmlFor="">Select School</label>
          <Select
            title="School"
            thirdOption="schoolId"
            index="schoolName"
            selected={schoolId}
            options={arr}
            setSelected={(val) => {
              // console.log(val);
              setSchoolId(val.schoolId);
            }}
          />
        </InputWrapper>
      </div>

      <TabContainer style={{ padding: "0px" }}>
        <TabLinkContainer bg="#F0E8F1" style={{ position: "relative" }}>
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search " />
          </Input>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table
            style={{
              borderRadius: "0px",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            <tr
              className="table-head"
              style={{ backgroundColor: "#F0E8F1", color: "black" }}
            >
              <th>S/N </th>
              <th>
                <p style={{ width: "max-content" }}>Name</p>
              </th>
              <th>
                <p style={{ width: "max-content" }}>Description</p>
              </th>

              <th></th>
            </tr>

            {list.length > 0 ? (
              list.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td>
                    <button
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        padding: "8px 12px",
                        border: `1px solid white`,
                        borderRadius: "12px",
                        marginRight: "0.2rem",
                      }}
                      onClick={() => {
                        setId(item.id);
                        delSection(item.id);
                      }}
                    >
                      {formLoading && id === item.id ? (
                        <FormLoader />
                      ) : (
                        " Delete"
                      )}
                    </button>
                    <button
                      style={{
                        padding: "8px 12px",
                        color: colors.primary,
                        border: `1px solid ${colors.primary}`,
                        borderRadius: "12px",
                      }}
                      onClick={() => {
                        let _obj = {
                          id: item.id,
                          categoryName: item.name,
                          schoolId: item.schoolId,
                          description: item.description,
                        };
                        let newObj = Object.assign(obj, _obj);
                        setObj(newObj);
                        setShowModal(true);
                      }}
                    >
                      Edit
                    </button>
                  </td>{" "}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} style={{ textAlign: "center" }}>
                  No Library Section Available At The Moment
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      {pageCount > 1 && (
        <div
          style={{
            marginTop: "2px",
            marginBottom: "2px",
          }}
        >
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            range={3}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </Container>
  );
};

Category.propTypes = {
  arr: PropTypes.array,
  loading: PropTypes.bool,
};

Category.defaultProps = {
  arr: [],
  loading: true,
};

export default Category;
