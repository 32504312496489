import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/system";
import colors from "../../assets/colors.json";

import {
  BigHeader,
  TabContainer,
  TabLinkContainer,
  AccountSummaryWrapper,
  Icon,
  Input,
  Search,
  SmallHeader,
} from "../../pages/Dashboard/styles/dashboard.style";
import { BsFillJournalBookmarkFill, BsSearch } from "react-icons/bs";
import Popover from "@mui/material/Popover";
import { StatusBar } from "../../pages/student_mgt/stu_mgt.style";
import Modal from "../../components/modal/Modal";
import { InputWrapper, SmallText } from "../../pages/auth/Login.style";
import { Grid, IconButton, MenuItem, MenuList } from "@mui/material";
import { AuthButton } from "../../pages/auth/Login.style";
import Select from "../select/select";

const RedeploymentTransfer = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [opt, setOpt] = useState("");
  const [showModal, setShowModal] = useState(false);
  const optionsArr = [
    {
      id: 1,
      name: "Value 1",
    },
    {
      id: 2,
      name: "Value 2",
    },
  ];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }}>Apply Redeployment</BigHeader>

      <div>
        <InputWrapper>
          <label htmlFor="current_school">Current School</label>
          <input type="text" placeholder="Current School" />
        </InputWrapper>

        <InputWrapper>
          <label htmlFor="proposed_school">Proposed School</label>
          <Select
            title="Role"
            index="name"
            selected={"Value 1"}
            options={optionsArr}
            setSelected={(val) => {
              console.log(val);
              setOpt(val.name);
            }}
          />
        </InputWrapper>
      </div>

      <div>
        <AuthButton width="100%" mt="0.6rem">
          Apply
        </AuthButton>
      </div>
    </>
  );
  return (
    <>
      {showModal && (
        <Modal content={content} size={"large"} onClose={setShowModal} />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SmallHeader style={{ fontWeight: "bold" }}>
          Redeployment/ Transfer History
        </SmallHeader>

        <AuthButton onClick={() => setShowModal(!showModal)}>
          Apply For Redeployment/ Transfer
        </AuthButton>
      </div>
      <TabContainer style={{ paddingBottom: "0px" }}>
        <TabLinkContainer bg="#EEE0F1">
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search" />
          </Input>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table style={{ borderRadius: "0px" }}>
            <tr
              className="table-head"
              style={{ backgroundColor: "#F0E8F1", color: "black" }}
            >
              <th>Created Date</th>
              <th>Created By</th>
              <th>Current School</th>
              <th>Proposed School</th>
              <th>Effective Date</th>
              <th>Status</th>
              <th></th>
            </tr>
            <tr>
              <td>12/02/2022</td>
              <td>Mrs Adeola Johnson</td>
              <td>CMS Grammar School</td>
              <td>BMD Grammar School</td>
              <td>April 10th, 2023</td>

              <td>
                <StatusBar>Approve</StatusBar>
              </td>

              <td>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              </td>
            </tr>
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem>View Certificate</MenuItem>
            <MenuItem>Download Handover note</MenuItem>
            <MenuItem>Download Proof</MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </>
  );
};

export default RedeploymentTransfer;
