import {
  Avatar,
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import React from "react";

const StudentBio = () => {
  return (
    <>
      <Box sx={{pb:4}} >
        <Grid container spacing={2} sx={{display:'flex', flexDirection:{lg:'row', xs:'column-reverse'}}}>
          <Grid item lg={8} sm={12} xs={12}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  fontWeight: "bolder",
                  fontSize:'12px',
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Last Name
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontWeight: "bolder",
                  color: "#000",
                  fontSize:'12px',
                  fontFamily: "Gilroy-Medium",
                }}
              >
                First Name
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
            <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontWeight: "bolder",
                  color: "#000",
                  fontSize:'12px',
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Other Name
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={4}>
            <Box
              sx={{
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                height: "230px",
                bgcolor: "#671e781a",
                borderRadius: "10px",
              }}
            >
              <Avatar
                variant="rounded"
                sx={{ width: "100%", height: "170px", borderRadius: "10px" }}
              />
              <Button variant="outlined" component="label" sx={{mt:1}}>
                upload picture{" "}
                <input hidden accept="image/*" multiple type="file" />{" "}
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item lg={8} sm={12} xs={12}>
          <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontWeight: "bolder",
                  fontSize:'12px',
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
              Eye Colour
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={8} sm={12} xs={12}>
          <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontSize:'12px',
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Date of Birth
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={8} sm={12} xs={12}>
          <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontSize:'12px',
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
              Home Town
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={8} sm={12} xs={12}>
          <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                  fontSize:'12px',
                }}
              >
               Place of Birth
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={8} sm={12} xs={12}>
          <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontSize:'12px',
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                State of Origin
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={8} sm={12} xs={12}>
          <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontSize:'12px',
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Residential Address
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={8} sm={12} xs={12}>
          <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontSize:'12px',
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Nationality
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={8} sm={12} xs={12}>
          <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontWeight: "bolder",
                  fontSize:'12px',
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Local Government of Origin
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={8} sm={12} xs={12}>
          <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontSize:'12px',
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
            Nearest Bus-stop to your House
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={8} sm={12} xs={12}>
          <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontSize:'12px',
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
               Postal Address(if different from the above)
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={8} sm={12} xs={12}>
          <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontSize:'12px',
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Religion
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={9} sm={12} xs={12}>
          <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  fontSize:'12px',
                  mt:1,
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
              Gender
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          
          <Grid item lg={4} sm={6} xs={6}>
          <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontSize:'12px',
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Height
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={4} sm={6} xs={6}>
          <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontSize:'12px',
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Weight
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={8} sm={12} xs={12}>
          <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontWeight: "bolder",
                  color: "#000",
                  fontSize:'12px',
                  fontFamily: "Gilroy-Medium",
                }}
              >
               Denomination
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={8} sm={12} xs={12}>
          <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontSize:'12px',
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Name and address of church
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={8} sm={12} xs={12}>
          <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontSize:'12px',
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Role of child/ward in place of worship
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={8} sm={12} xs={12}>
          <Box>
              <InputLabel
                sx={{
                  mb: 1.5,
                  mt:1,
                  fontSize:'12px',
                  fontWeight: "bolder",
                  color: "#000",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Name and Phone Number of the Vicar/Pastor
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #671E78",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default StudentBio;
