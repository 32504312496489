import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import {
  TextField,
  List,
  Box,
  IconButton,
  ListItemText,
  Grid,
  Avatar,
  ListItemAvatar,
  ListItemButton,
  Typography,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CachedIcon from "@mui/icons-material/Cached";
import {
  useGetClassworkCommentsQuery,
  useSendClassworkCommentsMutation,
} from "../../../api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import LoaderLine from "../../LoaderLine";
import PropTypes from "prop-types";
import profileImg from "../../../assets//Images/defaultpfp.png";

const useStyles = styled((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  chatContainer: {
    width: "80%",
    maxWidth: 400,
    maxHeight: 400,
    overflow: "auto",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  messageInput: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

const ChatApp = ({ classWorkId }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const userInfo = useSelector((state) => state.auth.user);
  const [onSendMessage] = useSendClassworkCommentsMutation();

  const { data, isLoading, refetch } = useGetClassworkCommentsQuery({
    classWorkId: classWorkId,
  });

  const handleSendMessage = async () => {
    const messageData = {
      comment: newMessage,
      classWorkId: Number(classWorkId),
      senderId: String(userInfo?.staffId),
      senderUserType: "T",
    };
    if (newMessage.trim() !== "") {
      setMessages([...messages, newMessage]);
      setNewMessage("");
      try {
        const res = await onSendMessage(messageData)?.unwrap();
        refetch();
        toast.success(res?.message);
      } catch (err) {
        toast.error("there was an error ", err);
      }
    } else {
      toast.warning("Please enter a valid Message");
    }
  };

  const trails = data?.data
    ? [...data.data].sort(
        (a, b) => new Date(a.commentDate) - new Date(b.commentDate)
      )
    : [];

  useEffect(() => {
    refetch();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {isLoading && <LoaderLine />}
      <Box
        sx={{
          margin: "auto",
          width: "90%",
          height: "400px",
        }}
      >
        <Box align="right">
          <IconButton onClick={refetch}>
            <CachedIcon />
          </IconButton>
        </Box>
        <Grid container spacing={2} sx={{ height: "380px" }}>
          <Grid item lg={4} sx={{ mt: 2 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab {...a11yProps(0)}>
                <ListItemButton
                  sx={{
                    bgcolor: "#eef5e7",
                    borderRight: "2px solid #671e78",
                    display: "flex",
                  }}
                >
                  <ListItemAvatar>
                    <Avatar />
                  </ListItemAvatar>
                  <ListItemText>
                    <Typography
                      sx={{
                        fontFamily: "Gilroy-regular",
                        fontSize: "14px",
                      }}
                    >
                      Student
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </Tab>
            </Tabs>
          </Grid>
          <Grid item lg={8}>
            <CustomTabPanel value={value} index={0}>
              <Box
                sx={{
                  height: "250px",
                  bgcolor: "#E5E5E5b7",
                  my: 2,
                  borderRadius: 2,
                  overflowY: "scroll",
                  p: 2,
                }}
              >
                {data?.data?.length === 0 && (
                  <>
                    <Box>
                      <Typography>No Comment Available</Typography>
                    </Box>
                  </>
                )}
                {data?.data?.length > 0 && (
                  <>
                    <List>
                      {trails?.map((message, index) => {
                        const outbox = message.staffId == userInfo.staffId;
                        const isStudent = message.author === "S";
                        return (
                          <div
                            className={`${
                              isStudent ? "items-start" : "items-end"
                            } flex flex-col my-1`}
                            key={index}
                          >
                            {!outbox &&
                              (isStudent ? (
                                <div className="flex gap-1 items-center mt-1">
                                  <img
                                    src={message.studentImage || profileImg}
                                    className="h-5 w-5 rounded-full"
                                  />
                                  <span className="text-xs ">
                                    {message.studentName}
                                  </span>
                                </div>
                              ) : (
                                <div className="flex gap-1 items-center mt-1">
                                  <img
                                    src={message.teacherImage || profileImg}
                                    className="h-5 w-5 rounded-full"
                                  />
                                  <span className="text-xs ">
                                    {message.teacherName}
                                  </span>
                                </div>
                              ))}
                            <Typography
                              sx={{
                                fontFamily: "Gilroy-regular",
                                textAlign: outbox ? "right" : "left",
                                fontSize: "13px",
                                width: "fit-content",
                                maxWidth: "50%",
                                bgcolor: outbox ? "#fff" : "#671e78",
                                p: 1,
                                borderRadius: "20px",
                                borderTopLeftRadius: !outbox ? 0 : "20px",
                                borderTopRightRadius: outbox ? 0 : "20px",
                                color: !outbox ? "#fff" : "#671e78",
                              }}
                            >
                              {message?.comment}
                            </Typography>
                          </div>
                        );
                      })}
                    </List>
                  </>
                )}
              </Box>
            </CustomTabPanel>
            <Box
              className={classes?.messageInput}
              sx={{ display: "flex", columnGap: 2, alignItems: "center" }}
            >
              <TextField
                fullWidth
                // multiline
                placeholder="Type here..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                InputProps={{
                  style: {
                    fontFamily: "gilroy-regular",
                    fontSize: "13px",
                    borderRadius: "20px",
                    offset: " 0.5px solid #671E78",
                    color: "#333",
                    border: "0.5px solid #671E78",
                    height: "40px",

                    // Replace with your desired font family
                  },
                }}
              />
              <IconButton
                onClick={handleSendMessage}
                sx={{
                  bgcolor: "#671e78",
                  display: "grid",
                  placeItem: "center",
                  height: "40px",
                  width: "40px",
                  "&:hover": {
                    bgcolor: "#671e78b7",
                  },
                }}
              >
                <SendIcon sx={{ color: "#fff", fontSize: "15px" }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ChatApp;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
