import Title from "../../Dashboard/Title";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useLocation, useSearchParams } from "react-router-dom";
import ResultSummary from "./ResultSummary";
import ResultTermPeriod from "./ResultTermPeriod";
import api from "../../../services/ControlPanel";
import { useSelector } from "react-redux";
import { getSessions, getTerms } from "../../../services/Subject";
import { toast } from "react-toastify";
import Modal from "../../modal/Modal";
import moment from "moment";
import Pagination from "../../pagination_one/pagination";
import { BsSearch } from "react-icons/bs";
import { paginateData } from "../../../services/utils";
import {
  AuthButton,
  InputWrapper,
  SmallText,
} from "../../../pages/auth/Login.style";
import Select from "../../select/select";
import {
  SmallHeader,
  TabContainer,
  AccountSummaryWrapper,
  BigHeader,
  Container,
  Icon,
  Input,
  MenuDiv,
  Search,
  TabLinkContainer,
} from "../../../pages/Dashboard/styles/dashboard.style";
import {
  InlineLabelGroup,
  InlineLabelWrapper,
} from "../../../pages/Dashboard/styles/dashboard.style";
import GlobalStyles from "../../../assets/globalStyle";
import colors from "../../../assets/colors.json";
import { useCallback, useState, useEffect } from "react";
import ErrorService from "../../../services/errorService";
import { RssFeed } from "@mui/icons-material";
import { checkAccess } from "../../../services/utils";
import DatePickerComponent from "../../datepicker/DatePicker";
import AttCalendarSetup from "../../AttendanceCalendar/AttCalendarSetup";
import Loader from "../../pageLoader/loader";
const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
  },
});
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const AttendanceSetup = () => {
  const [id, setId] = useState("");
  const [pickDates, setPickDates] = useState([]);
  const [lockLoading, setLockLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffSet, setitemOffSet] = useState(0);
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [loadTerm, setLoadTerm] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState([]);
  const [session, setSession] = useState({
    id: "",
    name: "",
  });
  const [sessions, setSessions] = useState([]);
  const [term, setTerm] = useState({
    id: "",
    name: "",
  });
  const [list, setList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const getData = useCallback(
    async (sessionId, termId) => {
      setLoading(true);
      try {
        const res = await api.getAttendanceSetupDates(
          admToken,
          user.schoolId,
          sessionId,
          termId
        );

        if (res.status === true && res.statusCode === 2 && res?.data) {
          setAllData(res.data);
        }
        (res.status === 500 || res.status === 400 || res.status === false) &&
          ErrorService.displayErrorAlert(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("An Error Occurred!");
      }
    },
    [admToken, user.schoolId]
  );
  const addDatesFunc = useCallback(async () => {
    let obj = {
      attendanceName: "string",
      staffId: user.staffId,
      sessionId: session.id,
      termId: term.id,
      schoolId: user.schoolId,
      selectedDates: pickDates,
    };
    setFormLoading(true);
    try {
      const res = await api.addAttendanceSetupDate(admToken, obj);
      if (res.status === true && res.statusCode === 2) {
        toast.success(res.message);
        getData(session.id, term.id);
        setShowModal(false);
      }
      setFormLoading(false);

      if (
        res.status === 500 ||
        res.status === 400 ||
        res?.data?.status === 400 ||
        !res.status
      ) {
        ErrorService.displayErrorAlert(res.data);
      }
    } catch (error) {
      setFormLoading(false);
      toast.error("An Error Occurred!, Check Your Internet");
    }
  }, [
    admToken,
    getData,
    pickDates,
    session.id,
    term.id,
    user.schoolId,
    user.staffId,
  ]);

  const schoolTerms = useCallback(async () => {
    try {
      const res = await getTerms(admToken);
      res.status && res.statusCode === 2 && setTerms(res.data);
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
      setLoadTerm(false);
    } catch (error) {
      setLoadTerm(false);
    }
  }, [admToken]);
  const schoolSessions = useCallback(async () => {
    try {
      const res = await getSessions(admToken);

      res.status && res.statusCode === 2 && setSessions(res.data);
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);
  useEffect(() => {
    // getData();
    schoolTerms();
    schoolSessions();
  }, [schoolSessions, schoolTerms]);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    paginateData(
      allData,
      setList,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allData, currentPage, itemOffSet]);
  const [dates, setDates] = useState([]);
  const mapISO = (from, to) => {
    let startDate = new Date(from);
    let endDate = new Date(to);

    // Initialize an array to store ISOStrings
    let dateArray = [];

    // Loop through the dates and push ISOStrings to the array
    for (
      let currentDate = startDate;
      currentDate <= endDate;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      dateArray.push(currentDate.toISOString());
    }

    return dateArray;
  };

  const openModal = useCallback(async () => {
    setPickDates([]);
    let startDate = new Date(dateFrom);
    let endDate = new Date(dateTo);
    if (term.id === "" || session.id === "") {
      toast.error("Please Select Both Session and Term Above");
      return;
    }
    if (endDate < startDate) {
      toast.error("The Start Date should be less than the End Date");
      return;
    }

    let fourMonthsLater = new Date(startDate);
    fourMonthsLater.setMonth(fourMonthsLater.getMonth() + 3);
    if (endDate > fourMonthsLater) {
      toast.error("Date Range shouldn't be more than three months");
      return;
    }

    let arr = await mapISO(dateFrom, dateTo);
    setDates(arr);

    setShowModal(true);
  }, [dateFrom, dateTo, session.id, term.id]);
  const [sQuery, setSQuery] = useState("");

  const filterSearch = async (e) => {
    const queryRegex = new RegExp(e.target.value, "i");
    const arr = allData.filter((item) => {
      return Object.values(item).some((val) => queryRegex.test(val));
    });
    setList(arr);
    setPageCount(0);
    // }
  };
  const resetData = () => {
    setCurrentPage(1);
    paginateData(allData, setList, 1, itemOffSet, setitemOffSet, setPageCount);
  };
  const lockFunc = useCallback(
    async (id, bool) => {
      setLockLoading(true);
      try {
        const res = await api.lockAttendanceSetupDate(admToken, id, bool);

        if (res.status && res.statusCode === 2) {
          toast.success(res.message);
          getData(session.id, term.id);
        }
        if (res.status === 400 || res.status === 500 || res.status === false) {
          ErrorService.displayErrorAlert(res);
        }
        setLockLoading(false);
      } catch (error) {
        toast.error("An error occurred");
        setLockLoading(false);
      }
    },
    [admToken, getData, session.id, term.id]
  );

  const delFunc = useCallback(
    async (id) => {
      setDelLoading(true);
      try {
        const res = await api.deleteAttendanceSetupDate(admToken, id);
        if (res.status && res.statusCode === 2) {
          toast.success(res.message);
          if (session.id && term.id) {
            getData(session.id, term.id);
          }
        }
        if (res.status === 400 || res.status === 500 || res.status === false) {
          ErrorService.displayErrorAlert(res);
        }
        setDelLoading(false);
      } catch (error) {
        toast.error("An error occurred");
        setDelLoading(false);
      }
    },
    [admToken, getData, session.id, term.id]
  );
  return (
    <Container>
      <GlobalStyles />

      {showModal && dates.length > 0 && (
        <Modal
          content={
            <AttCalendarSetup
              dates={dates}
              loading={formLoading}
              action={addDatesFunc}
              session={session.name}
              term={term.name}
              allData={allData}
              pickDates={pickDates}
              setPickDates={setPickDates}
            />
          }
          size="large"
          onClose={setShowModal}
          pd={"0px"}
          iColor={"white"}
        />
      )}
      <div>
        <BigHeader
          style={{
            borderBottom: `4px solid ${colors.primary}`,
            paddingBottom: "4px",
          }}
        >
          Attendance Setup
        </BigHeader>
      </div>

      <InlineLabelGroup>
        <InlineLabelWrapper flex={2}>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              {" "}
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title="Session"
                  index="session" // no
                  selected={session.id} // false
                  options={sessions}
                  setSelected={(val) => {
                    setSession({
                      ...session,
                      id: val.id,
                      name: val.session,
                    });

                    // getClasses(val.id);
                    // setAllData([]);

                    setSession({
                      ...session,
                      id: val.id,
                      name: val.session,
                    });
                    if (term.name.length > 0) {
                      getData(val.id, term.id);
                    }
                  }}
                  // color="white"
                />
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title={loadTerm ? "LOading..." : "Term"}
                  index="term" // no
                  selected={term.id} // false
                  options={terms}
                  setSelected={(val) => {
                    setTerm({ ...term, id: val.id, name: val.term });

                    if (session.name.length > 0) {
                      getData(session.id, val.id);
                    }
                    // setAllData([]);
                  }}
                  // color="white"
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </InlineLabelWrapper>
      </InlineLabelGroup>
      <SmallHeader
        style={{ textAlign: "center", color: colors.primary, fontSize: "13px" }}
      >
        {" "}
        {(session.name === "" || term.name === "") &&
          "Please select Term and Session to Get Started"}
      </SmallHeader>

      {checkAccess(accessObj.rights, "Create") && (
        <InlineLabelGroup>
          <InlineLabelWrapper flex={5}>
            <InlineLabelGroup style={{ alignItems: "end" }}>
              <InlineLabelWrapper flex={2}>
                <InputWrapper>
                  <label htmlFor="Date_from">Date From</label>
                  <DatePickerComponent
                    // showTimeSelect={true}
                    selected={dateFrom === "" ? dateFrom : new Date(dateFrom)}
                    setSelected={(val) => {
                      console.log(val);
                      console.log(val.getTime());

                      // wait
                      console.log(
                        new Date(val.getTime() + 7200000).toISOString()
                      );
                      console.log(val.toISOString());
                      console.log(new Date(val).toISOString());
                      setDateFrom(val.toISOString());

                      setDateFrom(
                        new Date(val.getTime() + 7200000).toISOString()
                      );
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper>
                  <label htmlFor="Date_to">Date To</label>
                  <DatePickerComponent
                    // showTimeSelect={true}
                    selected={dateTo === "" ? dateTo : new Date(dateTo)}
                    setSelected={(val) => {
                      setDates([]);
                      setDateTo(
                        new Date(val.getTime() + 7200000).toISOString()
                      );
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={1}>
                <AuthButton
                  width="100%"
                  mt="0.3rem"
                  disabled={dateTo === "" || dateFrom === ""}
                  onClick={openModal}
                >
                  Add Dates
                </AuthButton>
              </InlineLabelWrapper>
            </InlineLabelGroup>
          </InlineLabelWrapper>
        </InlineLabelGroup>
      )}

      <TabContainer
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          paddingBottom: "0px",
        }}
      >
        <TabLinkContainer bg="rgb(238, 224,241)">
          <Input style={{ width: "100%" }}>
            <Icon>
              <BsSearch color={colors.primary} />
            </Icon>
            <Search
              style={{ background: "white" }}
              value={sQuery}
              type="text"
              placeholder="Search For Subject"
              onChange={(e) => {
                setSQuery(e.target.value);
                if (e.target.value.length > 3) {
                  filterSearch(e);
                }

                if (e.target.value === "") {
                  resetData();
                }
              }}
            />
          </Input>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          {loading ? (
            <Loader />
          ) : (
            <table
              style={{
                borderRadius: "0px",
                borderBottomLeftRadius: "20px",
                borderBottomRightRadius: "20px",
              }}
            >
              <tr
                className="tabel-head"
                style={{ color: "black", fontWeight: "bold" }}
              >
                <th>S/N</th>
                <th>Days {allData.length > 0 && <>{allData.length}</>}</th>
                <th></th>
              </tr>
              {list.length > 0 ? (
                list.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <button
                          disabled={lockLoading && id === item.id}
                          onClick={() => {
                            setId(item.id);
                            lockFunc(item.id, item.isLocked ? false : true);
                          }}
                          style={{
                            borderRadius: "9px",
                            border: `1px solid ${colors.primary}`,
                            padding: "0.3rem 0.6rem",
                            color: "white",
                            backgroundColor: colors.primary,
                          }}
                        >
                          {lockLoading && id === item.id
                            ? "Please wait..."
                            : item.isLocked
                            ? "Locked"
                            : "Unlocked"}
                        </button>
                        <button
                          disabled={delLoading && id === item.id}
                          onClick={() => {
                            setId(item.id);
                            delFunc(item.id);
                          }}
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            marginLeft: "5px",
                            borderRadius: "9px",
                            border: `1px solid ${
                              delLoading && id === item.id ? "" : "red"
                            }  `,
                            padding: "0.3rem 0.6rem",
                          }}
                        >
                          {delLoading && id === item.id
                            ? "Please wait..."
                            : "Delete"}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} style={{ textAlign: "center" }}>
                    No Dates Available
                  </td>
                </tr>
              )}
            </table>
          )}
        </AccountSummaryWrapper>
      </TabContainer>

      {pageCount > 1 && (
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            range={3}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </Container>
  );
};

export default AttendanceSetup;
