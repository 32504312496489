import { useState, useEffect, useCallback } from "react";
import {
  getSessions,
  getTerms,
  // GetRemarkClasses,
  TeacherClasses,
  GetPsychoStudents,
  updatePsycho,
  studentPrevPsycho,
} from "../../services/Subject";
import ErrorService from "../../services/errorService";
import { useSelector } from "react-redux";
import FormLoader from "../../components/FormLoader";
import { toast } from "react-toastify";

import colors from "../../assets/colors.json";
import { useMediaQuery } from "react-responsive";
import { InputWrapper, AuthButton } from "../../pages/auth/Login.style";
import Select from "../select/select";
import { ImgTextDiv } from "../../pages/student_mgt/stu_mgt.style";
import Avatar from "../../assets/Images/avatar.jpg";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import {
  AccountSummaryWrapper,
  TabContainer,
  InlineLabelGroup,
  InlineLabelWrapper,
  // InlineFormDiv,
  SmallHeader,
} from "../../pages/Dashboard/styles/dashboard.style";
import { BsFillPlusCircleFill } from "react-icons/bs";
import Modal from "../modal/Modal";
import { ModalFlexHeader } from "../modal/modal.style";
import { checkAccess } from "../../services/utils";
import { Pagination } from "@mui/material";

const Psychomotor = () => {
  const [showModal, setShowModal] = useState(false);
  const isTab = useMediaQuery({ maxWidth: 992 });
  const [loading, setLoading] = useState(false);

  const [formLoading, setFormLoading] = useState(false);
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [showForm, setShowForm] = useState(true);
  const [terms, setTerms] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [allData, setAllData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [marked, setMarked] = useState([]);
  const [loadClass, setLoadClass] = useState(false);
  const [classes, setClasses] = useState([]);
  const [classObj, setClassObj] = useState({
    id: "",
    name: "",
  });
  const [session, setSession] = useState({
    id: "",
    name: "",
  });
  const [term, setTerm] = useState({
    id: "",
    name: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRecords, setPageRecords] = useState([]);
  const handleChange = (event, value) => {
    setCurrentPage(value);
    getStudents(value);
  };

  const getStudentScore = useCallback(
    async (id) => {
      try {
        const res = await studentPrevPsycho(
          admToken,
          user.schoolId,
          session.id,
          term.id,
          classObj.id,
          id
        );
        if (res.status === true && res.statusCode === 2) {
          setMarked(res.data);
          setShowModal(true);
        }
        (res.status === 500 || res.data.status === 400 || !res.status) &&
          ErrorService.displayErrorAlert(res.data);
      } catch (error) {
        console.log(error);
      }
    },
    [admToken, classObj.id, session.id, term.id, user.schoolId]
  );
  const schoolTerms = useCallback(async () => {
    try {
      const res = await getTerms(admToken);
      res.status &&
        res.statusCode === 2 &&
        setTerms(res?.data?.filter((item) => item?.isActive));
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);

  const schoolSessions = useCallback(async () => {
    try {
      const res = await getSessions(admToken);

      res.status && res.statusCode === 2 && setSessions(res.data);
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);
  const getClasses = useCallback(
    async (id) => {
      try {
        setLoadClass(true);
        const res = await TeacherClasses(admToken, user.schoolId);

        // const res = await GetRemarkClasses(admToken, user.schoolId, id);
        // console.log(res);
        res.status && res.statusCode === 2 && setClasses(res.data);
        (res.status === 500 || res.status === 400 || !res.status) &&
          ErrorService.displayErrorAlert(res);
        setLoadClass(false);
      } catch (error) {
        console.log(error);
        setLoadClass(false);
      }
    },

    [admToken, user]
  );

  useEffect(() => {
    schoolTerms();
    schoolSessions();
    getClasses();
  }, [schoolSessions, schoolTerms, getClasses]);
  const [student, setStudent] = useState({ id: "", name: "", admissionNo: "" });
  const [psyRates, setPsyRates] = useState([]);
  const [psyHeadings, setPsyHeadings] = useState([]);
  const [psySubHeadings, setPsySubHeadings] = useState([]);
  const [lock, setLock] = useState(false);
  const getStudents = useCallback(
    async (value) => {
      try {
        setLoading(true);
        const res = await GetPsychoStudents(
          admToken,
          user.schoolId,
          classObj.id,
          session.id,
          term.id,
          0,
          10,
          value ?? currentPage
        );
        // console.log(res);
        if (res.status && res.statusCode === 2) {
          setPageRecords(res?.totalRecord);
          setLock(res.data.activateUpdateButton === 1 ? true : false);
          res.data?.psychomotorRates && Array.isArray(res.data.psychomotorRates)
            ? setPsyRates(res.data.psychomotorRates)
            : setPsyRates([]);
          res.data?.pyschomotorHeadings &&
          Array.isArray(res.data.pyschomotorHeadings)
            ? setPsyHeadings(res.data.pyschomotorHeadings)
            : setPsyHeadings([]);
          res.data?.pyschomotorSubHeadings &&
          Array.isArray(res.data.pyschomotorSubHeadings)
            ? setPsySubHeadings(res.data.pyschomotorSubHeadings)
            : setPsySubHeadings([]);

          if (
            Array.isArray(res.data.studentList) &&
            res.data.studentList.length > 0
          ) {
            setAllData(res.data.studentList);
            setTotalRecords(res.totalRecord);
            setShowForm(false);
          } else {
            toast.error("No Student(s) Available");
          }
        }
        (res.status === 500 || res.status === 400 || !res.status) &&
          ErrorService.displayErrorAlert(res);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
    [admToken, classObj.id, session.id, term.id, user.schoolId]
  );

  const handleClick = (e, psyChocoId) => {
    // console.log(psyChocoId);
    // console.log(parseInt(e.target.value));

    let item = marked.find((i) => i.psychomotorId === psyChocoId);

    if (item) {
      let filteredArr = marked.filter((i) => i.psychomotorId !== psyChocoId);
      // if (e.target.value === "") {
      //   setMarked([...filteredArr]);
      // } else {
      let obj = {
        psychomotorId: psyChocoId,
        psychomotorscore: e.target.value === "" ? 0 : parseInt(e.target.value),
      };
      setMarked([...filteredArr, obj]);
      // }
    } else {
      // if (e.target.value !== "") {
      let obj = {
        psychomotorId: psyChocoId,
        psychomotorscore: e.target.value === "" ? 0 : parseInt(e.target.value),
      };

      setMarked([...marked, obj]);
      // }
    }
  };
  const findAnswerId = (id) => {
    if (marked.length > 0) {
      // const markedAnswers = marked.map
      const option = marked.find((i) => i.psychomotorId === id);
      // console.log(option);
      // return option.psychomotorscore === 0 ? "" : option.psychomotorscore;
      return option ? option.psychomotorscore : "";
    } else {
      return "";
    }
  };
  const assignPsycho = useCallback(async () => {
    // console.log("Hello");
    try {
      setFormLoading(true);
      // console.log(marked);
      let data = {
        classId: Number(classObj.id),
        termId: Number(term.id),
        sessionId: Number(session.id),
        schoolId: Number(user.schoolId),
        studentId: Number(student.id),
        staffId: 0,
        // subjectId: Number(subject.id),
        // sessionId: Number(session.id),
        psychomotorResults: marked,
      };

      const res = await updatePsycho(admToken, data);
      // console.log(res);

      if (res.status && res.statusCode === 2) {
        toast.success(res.message);
        // getStudents(classObj.id);
        setMarked([]);
        getStudentScore(student.id);
        // setShowModal(false);
      }
      (res.status === 500 || res.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res);
      setFormLoading(false);
    } catch (error) {
      console.log(error);
      setFormLoading(false);
      toast.error("Please Try Again Later");
    }
  }, [
    admToken,
    classObj.id,
    getStudentScore,
    marked,
    session.id,
    student.id,
    term.id,
    user.schoolId,
  ]);

  const content = (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          className={`flex flex-col bg-[#671e78] w-full text-white rounded-t-lg  justify-center items-center p-4`}
        >
          <span>
            <span style={{ marginRight: "0.3rem" }}>{student.admissionNo}</span>
            {student.name}
          </span>
          <span>
            {session.name}-{term.name}-{classObj.name}
          </span>
          {/* <span>{classObj.name}</span> */}
        </div>
      </div>
      <TabContainer
        style={{
          borderRadius: "10px",
          paddingBottom: "0px",
          marginTop: "0.3rem",
        }}
      >
        {psyHeadings.length > 0 ? (
          <>
            {psyHeadings.map((i, index) => (
              <AccountSummaryWrapper
                pt="0px"
                key={index}
                style={{
                  marginBottom: "0.5rem",
                }}
              >
                <table>
                  <thead>
                    <tr
                      className="table-head"
                      style={{
                        backgroundColor: colors.primary,
                        color: "white",
                      }}
                    >
                      <th>{i.name}</th>
                      <th>Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {psySubHeadings.length > 0 ? (
                      <>
                        {psySubHeadings.filter((ii) => ii.group === i.id)
                          .length > 0 ? (
                          psySubHeadings
                            .filter((ii) => ii.group === i.id)
                            .map((iii, index) => (
                              <tr key={index}>
                                <td>
                                  <span>{iii.name}</span>
                                </td>
                                <td>
                                  <select
                                    style={{ maxWidth: "200px" }}
                                    name=""
                                    id=""
                                    // value=""
                                    value={findAnswerId(iii.id)}
                                    onChange={(e) => handleClick(e, iii.id)}
                                  >
                                    <option value="" selected>
                                      Choose Score
                                    </option>
                                    {psyRates.length > 0 &&
                                      psyRates.map((it, index) => (
                                        <option
                                          value={it.score}
                                          key={index}
                                          // name={item.remarks}
                                        >
                                          {it.remarks}
                                        </option>
                                      ))}
                                  </select>
                                  {/* <input
                                  type="text"
                                  value={"30"}
                                  style={{ width: "75px" }}
                                /> */}
                                </td>
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td colSpan={6} style={{ textAlign: "center" }}>
                              No Psychomotor Subheadings For This {i.name}
                            </td>
                          </tr>
                        )}
                        {/* // <tr></tr> */}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={6} style={{ textAlign: "center" }}>
                          No Psychomotor Subheadings At the Moment
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </AccountSummaryWrapper>
            ))}
          </>
        ) : (
          <SmallHeader style={{ textAlign: "center" }}>
            No Psychomotor For Teacher To Fill
          </SmallHeader>
        )}
      </TabContainer>
      <div
        style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}
      >
        {checkAccess(accessObj.rights, "Update") && (
          <AuthButton
            onClick={assignPsycho}
            disabled={marked.length === 0 || formLoading || lock}
            width="150px"
          >
            {lock ? (
              "Locked"
            ) : formLoading ? (
              <FormLoader />
            ) : (
              "Upload Psychomotor"
            )}
          </AuthButton>
        )}
      </div>
    </>
  );
  return (
    <>
      {showModal && (
        <Modal
          size={"large"}
          onClose={setShowModal}
          content={content}
          start={true}
          iColor={"black"}
        />
      )}

      <div
        onClick={() => setShowForm(!showForm)}
        style={{
          backgroundColor: colors.primary,
          borderRadius: "10px",
          marginTop: "0.3rem",
          padding: "0.4rem",
          display: "flex",
          justifyContent: "space-between",
          color: "white",
          fontWeight: "bold",
        }}
      >
        <div>
          <span>
            {session.name} {session.name.length > 0 ? "-" : ""} {term.name}{" "}
            {term.name.length > 0 ? "-" : ""}
            {classObj.name}
          </span>
        </div>
        {showForm ? (
          <BiSolidDownArrow color="white" />
        ) : (
          <BiSolidUpArrow color="white" />
        )}
      </div>
      {showForm && (
        <>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={2}>
              <InlineLabelGroup>
                <InlineLabelWrapper flex={1}>
                  {" "}
                  <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                    <Select
                      title="Session"
                      index="session" // no
                      selected={session.id} // false
                      options={sessions.filter((i) => i.isActive)}
                      setSelected={(val) => {
                        setSession({
                          ...session,
                          id: val.id,
                          name: val.session,
                        });
                        // getClasses(val.id);
                        setAllData([]);
                      }}
                      // color="white"
                    />
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                    <Select
                      title="Term"
                      index="term" // no
                      selected={term.id} // false
                      options={terms}
                      setSelected={(val) => {
                        setTerm({ ...term, id: val.id, name: val.term });
                        setAllData([]);
                      }}
                      // color="white"
                    />
                  </InputWrapper>
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title={loadClass ? "Loading" : "Class"}
                  index="className" // no
                  selected={classObj.id} // false
                  options={classes}
                  thirdOption="classId"
                  extraTitle="classArm"
                  setSelected={(val) => {
                    // console.log(val);
                    setAllData([]);
                    setClassObj({
                      ...classObj,
                      id: val.classId,
                      name: `${val.className} ${val.classArm}`,
                    });
                    // getStudents(val.classId);
                  }}
                  // color="white"
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>

          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              <AuthButton
                width="100%"
                mt="0.4rem"
                onClick={() => getStudents()}
                disabled={
                  loading ||
                  classObj.id === "" ||
                  term.id === "" ||
                  session.id === ""
                }
              >
                <div style={{ textAlign: "center" }}>
                  {loading ? (
                    <FormLoader color={colors.primary} />
                  ) : (
                    "Load Student"
                  )}
                </div>
              </AuthButton>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </>
      )}

      <TabContainer style={{ borderRadius: "20px", paddingBottom: "0px" }}>
        <AccountSummaryWrapper pt="0px">
          <table>
            <tr
              className="table-head"
              style={{ backgroundColor: colors.primary, color: "white" }}
            >
              <th>Students ({totalRecords})</th>
              <th></th>
            </tr>
            {allData.length > 0 ? (
              <>
                {allData.map((i, index) => (
                  <tr key={index}>
                    <td style={{ fontWeight: "bold" }}>
                      <ImgTextDiv>
                        <span
                          style={{
                            marginRight: "0.6rem",
                            fontSize: "1.3rem",
                          }}
                        >
                          {index + 1}.
                        </span>
                        <img src={Avatar} alt="" />
                        <div>
                          <p>{i.fullName}</p>
                          <p style={{ fontSize: "0.8rem" }}>{i.admissionNo}</p>
                        </div>
                      </ImgTextDiv>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          setMarked([]);
                          setStudent({
                            ...student,
                            id: i.studentId,
                            name: i.fullName,
                            admissionNo: i.admissionNo,
                          });
                          getStudentScore(i.studentId);
                        }}
                        style={{
                          borderRadius: "8px",
                          padding: "8px",
                          minWidth: "121px",
                          color: "white",
                          backgroundColor: colors.primary,
                          cursor: "pointer",
                          lineHeight: "2.125rem",
                          border: `1px solid ${colors.primary}`,
                          outline: "none",
                          fontWeight: "bold",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <BsFillPlusCircleFill />
                        <span style={{ marginLeft: "5px" }}>
                          Update Psychomotor
                        </span>
                      </button>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  Students List Are Not Available
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      <Pagination
        count={Math.ceil(pageRecords / 10)}
        page={currentPage}
        onChange={handleChange}
      />
    </>
  );
};

export default Psychomotor;
