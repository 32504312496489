/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useState } from "react";
import colors from "../../assets/colors.json";
import Avatar from "../../assets/Images/avatar.jpg";
import { BsSearch } from "react-icons/bs";
import { GiCancel } from "react-icons/gi";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { toast } from "react-toastify";
import {
  TabContainer,
  AccountSummaryWrapper,
  InlineLabelGroup,
  InlineLabelWrapper,
  Search,
  Icon,
  Input,
  SmallHeader,
} from "../../pages/Dashboard/styles/dashboard.style";
import { checkAccess } from "../../services/utils";
import { ImgTextDiv } from "../../pages/student_mgt/stu_mgt.style";
import { AuthButton, InputWrapper } from "../../pages/auth/Login.style";
import Select from "../../components/select/select";
import { useSelector } from "react-redux";
// import { getAllSubjects } from "../../services/MySchool";
import FormLoader from "../../components/FormLoader";
import ErrorService from "../../services/errorService";
import Loader from "../../components/pageLoader/loader";
import {
  TeacherClasses,
  TeacherClassSubject,
  SubjectStudents,
  AssignMultiple,
  getSessions,
} from "../../services/Subject";
import { useMediaQuery } from "react-responsive";

const AssignMultipleSubjects = () => {
  const { admToken, user, accessObj } = useSelector((state) => state.auth);

  const isTab = useMediaQuery({ maxWidth: 992 });

  const [loadClass, setLoadClass] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [list, setList] = useState([]);
  const [allData, setAllData] = useState([]);
  // const [pageLoading, setPageLoading] = useState(true);
  const [marked, setMarked] = useState([]);
  const [classObj, setClassObj] = useState({
    id: "",
    name: "",
    category: "",
  });
  const [session, setSession] = useState({
    id: "",
    name: "",
  });
  const schoolSessions = useCallback(async () => {
    try {
      const res = await getSessions(admToken);
      console.log(res);
      res.status && res.statusCode === 2 && setSessions(res.data);
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);
  const getClasses = useCallback(async () => {
    try {
      setLoadClass(true);
      const res = await TeacherClasses(admToken, user.schoolId);
      console.log(res);
      if (res.status && res.statusCode === 2) {
        setClasses(res.data);
      }
      if (!res.status) {
        ErrorService.displayErrorAlert(res);
      }

      if (res.status === 400 || res.status === 500) {
        ErrorService.displayErrorAlert(res.data);
      }
      setLoadClass(false);
    } catch (error) {
      setLoadClass(false);
      setLoading(false);
      console.log(error);
    }
  }, [admToken, user]);
  const getClassSubjects = useCallback(
    async (par) => {
      try {
        setSubjectsLoading(true);
        const res = await TeacherClassSubject(admToken, user.schoolId, par);
        if (res.status && res.statusCode === 2) {
          setSubjects(res.data);
        } else {
          ErrorService.displayErrorAlert(res);
        }
        setSubjectsLoading(false);
        console.log(res);
      } catch (error) {
        setSubjectsLoading(false);
        console.log(error);
      }
    },
    [admToken, user]
  );

  useEffect(() => {
    getClasses();
    schoolSessions();
  }, [getClasses, schoolSessions]);
  const [subjectsLoading, setSubjectsLoading] = useState(false);

  const getStudents = useCallback(
    async (id) => {
      setLoading(true);
      console.log(session.id);
      setMarked([]);
      try {
        const res = await SubjectStudents(
          admToken,
          user.schoolId,
          id,
          0,
          session.id
        );
        console.log(res);
        if (res.statusCode === 2 && res.status) {
          setAllData(res.data);
          setList(res.data);
          if (res.data.length > 0) {
            setShowForm(false);
          }
        } else {
          ErrorService.displayErrorAlert(res);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.log(error);
      }
    },
    [admToken, session.id, user.schoolId]
  );

  const [subject, setSubject] = useState({
    id: "",
    name: "",
  });

  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const mapSubjectObjects = (par) => {
    let itemInArray = selectedSubjects.filter(
      (item) => item.subjectId == par.subjectId
    );
    let isAvailable = itemInArray.length > 0;
    if (isAvailable === false) {
      let obj = {
        subjectId: par.subjectId,
        subjectName: par.subjectName,
      };
      console.log(selectedSubjects);
      console.log(obj);
      const arr = [...selectedSubjects, obj];
      setSelectedSubjects(arr);
      setSubject({
        ...subject,
        id: "",
        name: "",
      });
    }
  };

  const markAll = () => {
    if (list.length === marked.length) {
      setMarked([]);
    } else {
      const newList = list.map((item) => {
        return { studentId: item.studentId, schoolId: user.schoolId };
      });

      console.log(newList);
      setMarked(newList);
    }
  };
  const toggleMarked = (id) => {
    // console.log(markedItem);
    let item = marked.find((i) => i.studentId === id);

    if (item) {
      setMarked(marked.filter((i) => i.studentId !== id));
    } else {
      let obj = {
        schoolId: user.schoolId,
        studentId: id,
      };
      // console.log(obj);
      setMarked([...marked, obj]);
    }
    // console.log(item);
  };
  const [sQuery, setSQuery] = useState("");
  const filterSearch = async (e) => {
    console.log(e.target.value);
    if (e.target.value.length < 3) {
      // setCurrentPage(1);
      setList(allData);
    } else {
      let arr = [];
      console.log(arr);
      const queryRegex = new RegExp(e.target.value, "i");
      allData.forEach((item) => {
        Object.values(item).forEach((val) =>
          queryRegex.test(val) ? arr.push(item) : ""
        );
      });

      console.log(arr);
      setList(arr);
      // setPageCount(0);
    }
  };

  // useEffect(() => {
  //   paginateData(
  //     allData,
  //     setList,
  //     currentPage,
  //     itemOffSet,
  //     setitemOffSet,
  //     setPageCount
  //   );
  // }, [allData, currentPage]);
  const [formLoading, setFormLoading] = useState(false);

  const mapSubjectIds = useCallback(async () => {
    const arr = await selectedSubjects.map((item) => item.subjectId);
    console.log(arr);
    return arr;
  }, [selectedSubjects]);
  const assignAction = async () => {
    console.log("Hello");
    mapSubjectIds();
    try {
      setFormLoading(true);
      console.log(marked);
      let data = {
        classId: Number(classObj.id),
        // subjectId: Number(subject.id),
        sessionId: Number(session.id),
        selectedStudents: marked,
        subjectIds: await mapSubjectIds(),
      };
      console.log(data);

      const res = await AssignMultiple(admToken, data);
      console.log(res);

      if (res.status && res.statusCode === 2) {
        toast.success(res.message);
        getStudents(classObj.id);
        setMarked([]);
        setSelectedSubjects([]);
      }
      (res.status === 500 || res.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res);
      setFormLoading(false);
    } catch (error) {
      console.log(error);
      setFormLoading(false);

      toast.error("Please Try Again Later");
    }
  };
  return (
    <>
      <div
        onClick={() => setShowForm(!showForm)}
        style={{
          backgroundColor: colors.primary,
          borderRadius: "10px",
          marginTop: "0.3rem",
          padding: "0.4rem",
          display: "flex",
          justifyContent: "space-between",
          color: "white",
          fontWeight: "bold",
        }}
      >
        <div>
          <span>
            {session.name} {session.name.length > 0 ? " - " : ""}{" "}
            {classObj.name}
          </span>
        </div>
        {showForm ? (
          <BiSolidDownArrow color="white" />
        ) : (
          <BiSolidUpArrow color="white" />
        )}
      </div>

      {showForm && (
        <>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={2}>
              <InlineLabelGroup>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                    <Select
                      title="Session"
                      index="session" // no
                      selected={session.id} // false
                      options={sessions.filter((i) => i.isActive)}
                      setSelected={(val) => {
                        console.log(val);
                        setSession({
                          ...session,
                          id: val.id,
                          name: val.session,
                        });
                        setMarked([]);
                        setSelectedSubjects([]);
                        setAllData([]);
                        setList([]);

                        // setClasses([]);
                      }}
                    />
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                    <Select
                      title={loadClass ? "Loading" : "Class"}
                      index="className" // no
                      selected={classObj.id} // false
                      options={classes}
                      thirdOption="classId"
                      extraTitle="classArm"
                      setSelected={(val) => {
                        console.log(val);
                        setMarked([]);
                        setSelectedSubjects([]);
                        setAllData([]);
                        setList([]);
                        setClassObj({
                          ...classObj,
                          id: val.classId,
                          name: `${val.className} ${val.classArm}`,
                          category: val.category,
                        });
                        getClassSubjects(val.category);
                        // getStudents(val.classId);
                      }}
                      // color="white"
                    />
                  </InputWrapper>
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </InlineLabelWrapper>
          </InlineLabelGroup>

          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              {/* <ButtonFlex> */}
              <AuthButton
                width="100%"
                mt="0.4rem"
                onClick={() => getStudents(classObj.id)}
                disabled={loading || classObj.id === "" || session.id === ""}
              >
                <div style={{ textAlign: "center" }}>
                  {loading ? (
                    <FormLoader color={colors.primary} />
                  ) : (
                    "Load Student"
                  )}
                </div>
              </AuthButton>
              {/* </ButtonFlex> */}
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </>
      )}
      {classObj.id !== "" &&
        classObj.name !== "" &&
        classObj.category !== "" &&
        list.length > 0 && (
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title={subjectsLoading ? "loading" : "Subject"}
                  index="subjectName" // no
                  selected={subject.id} // false
                  options={subjects}
                  thirdOption="subjectId" // false
                  setSelected={(val) => {
                    console.log(val);

                    // setClassObj({ ...classObj, id: "", name: "" });
                    setSubject({
                      ...subject,
                      id: val.subjectId,
                      name: val.subjectName,
                    });

                    mapSubjectObjects(val);
                    // getClasses(val.subjectId);
                    // setLoading(false);
                  }}
                  // color="white"
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        )}

      {selectedSubjects.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            alignItems: "center",
            marginTop: "0.3rem",
          }}
        >
          {selectedSubjects.map((item, index) => (
            <div
              key={index}
              style={{
                marginTop: "0.2rem",
                backgroundColor: colors.primary,
                display: "flex",
                alignItems: "center",
                padding: "0.3rem 0.6rem",
                borderRadius: "10px",
                marginRight: "0.2rem",
                flexWrap: "wrap",
              }}
            >
              <SmallHeader
                style={{
                  color: "white",
                  marginRight: "0.2rem",
                  fontSize: isTab ? "13px" : "16px",
                }}
              >
                {item.subjectName}
              </SmallHeader>
              <GiCancel
                color="red"
                style={{ marginLeft: "0.2rem", cursor: "pointer" }}
                onClick={() => {
                  let arr = selectedSubjects.filter(
                    (subject) => subject.subjectId != item.subjectId
                  );

                  setSelectedSubjects(arr);
                }}
              />
            </div>
          ))}
        </div>
      )}

      {loading && <Loader />}
      {!loading && (
        <>
          <TabContainer style={{ borderRadius: "10px", paddingBottom: "0px" }}>
            <AccountSummaryWrapper pt="0px">
              <table>
                <tr
                  className="table-head"
                  style={{ backgroundColor: colors.primary, color: "white" }}
                >
                  <th>
                    <input
                      type="checkbox"
                      style={{
                        borderColor: "white",
                        background: colors.primary,
                      }}
                      onChange={markAll}
                    />
                  </th>
                  <th>Student Name ({list.length}) </th>
                  <th colSpan={2}>
                    <Input>
                      <Icon>
                        <BsSearch color={colors.primary} />
                      </Icon>
                      <Search
                        style={{ background: "white" }}
                        value={sQuery}
                        type="type"
                        placeholder="Search For Student"
                        onChange={(e) => {
                          // console.log(e.target.value);
                          setSQuery(e.target.value);
                          filterSearch(e);
                        }}
                      />
                    </Input>
                  </th>
                  {/* <th></th> */}
                </tr>
                {list.length > 0 ? (
                  list.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          checked={marked.some(
                            (i) => i.studentId === item.studentId
                          )}
                          onChange={() => toggleMarked(item.studentId)}
                        />
                      </td>
                      <td>
                        <ImgTextDiv>
                          <span
                            style={{
                              marginRight: "0.6rem",
                              fontSize: "1.3rem",
                            }}
                          >
                            {index + 1}.
                          </span>
                          <img
                            src={
                              item.profileImagePath === "" ||
                              item.profileImagePath === null
                                ? Avatar
                                : item.profileImagePath
                            }
                            alt=""
                          />
                          <span>{item.fullName} </span>
                        </ImgTextDiv>
                      </td>
                      <td>
                        <span style={{ fontSize: "14px" }}>
                          {item.admissionNo}
                        </span>
                      </td>
                      <td></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: "center" }}>
                      There Are No Student Available
                    </td>
                  </tr>
                )}
              </table>
            </AccountSummaryWrapper>
          </TabContainer>

          {list.length > 0 && (
            <div
              style={{
                display: "flex",
                marginTop: "1rem",
                justifyContent: "end",
                padding: "10px 20px",
              }}
            >
              {checkAccess(accessObj.rights, "Assign") && (
                <>
                  <AuthButton
                    disabled={
                      marked.length === 0 ||
                      formLoading ||
                      selectedSubjects.length === 0
                    }
                    onClick={assignAction}
                    width="150px"
                  >
                    {" "}
                    {formLoading ? <FormLoader /> : "Assign"}
                  </AuthButton>
                  {/* <AuthButton
                    style={{
                      marginLeft: "0.5rem",
                      borderColor: colors.primary,
                    }}
                    color={colors.primary}
                    bg="white"
                    width="150px"
                  >
                    Remove
                  </AuthButton> */}
                </>
              )}
            </div>
          )}

          {/* {pageCount > 1 && (
            <div
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Pagination
                pageCount={pageCount > 1 && pageCount}
                range={3}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )} */}
        </>
      )}
    </>
  );
};

export default AssignMultipleSubjects;
