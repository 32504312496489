import React, { useState } from "react";
import colors from "../../assets/colors.json";
import { TabLink } from "../../pages/Dashboard/styles/dashboard.style";
import { useMediaQuery } from "react-responsive";
import { checkAccess } from "../../services/utils";
import { useSelector } from "react-redux";
import AssignSingleSubject from "../../components/StudentMgt/AssignSingleSubject";
import AssignMultipleSubjects from "../../components/StudentMgt/AssignMultipleSubjects";
import ReportSubject from "../../components/StudentMgt/ReportSubject";
const Subject = () => {
  const [tab, setTab] = useState(1);
  const isTab = useMediaQuery({ maxWidth: 992 });
  const { accessObj } = useSelector((state) => state.auth);
  return (
    <div>
      {(checkAccess(accessObj.menuItems, "Assign Single Subjects") ||
        checkAccess(accessObj.menuItems, "Assign Multiple Subjects") ||
        checkAccess(accessObj.menuItems, "Subject Report")) && (
        <div
          style={{
            width: isTab ? "100%" : "70%",
            display: "flex",
            alignItems: "center",
            padding: "7px 13px",
            backgroundColor: colors.secondary,
            borderRadius: "5px",
            flexWrap: "wrap",
            marginTop: "0.3rem",
          }}
        >
          {checkAccess(accessObj.menuItems, "Assign Single Subjects") && (
            <TabLink isActive={tab === 1} onClick={() => setTab(1)}>
              Assign Single Subject
            </TabLink>
          )}
          {checkAccess(accessObj.menuItems, "Assign Multiple Subjects") && (
            <TabLink isActive={tab === 2} onClick={() => setTab(2)}>
              Assign Multiple Subjects
            </TabLink>
          )}
          {checkAccess(accessObj.menuItems, "Subject Report") && (
            <TabLink isActive={tab === 3} onClick={() => setTab(3)}>
              Subject Report
            </TabLink>
          )}
        </div>
      )}
      {checkAccess(accessObj.menuItems, "Assign Single Subjects") &&
        tab === 1 && <AssignSingleSubject />}
      {checkAccess(accessObj.menuItems, "Assign Multiple Subjects") &&
        tab === 2 && <AssignMultipleSubjects />}
      {checkAccess(accessObj.menuItems, "Subject Report") && tab === 3 && (
        <ReportSubject />
      )}
      {/* {tab === 3 && <CreateSubject />} */}
    </div>
  );
};

export default Subject;
