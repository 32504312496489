import React, { useState, useCallback, useEffect, useRef } from "react";
import Select from "../select/select";
import colors from "../../assets/colors.json";
import { InputWrapper, AuthButton } from "../../pages/auth/Login.style";
import {
  AccountSummaryWrapper,
  InlineLabelGroup,
  InlineLabelWrapper,
  Container,
  Icon,
  Input,
  Search,
  TabContainer,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import { useMediaQuery } from "react-responsive";
import hostelApi from "../../services/hostel";
import { useSelector } from "react-redux";
import ErrorService from "../../services/errorService";
import Loader from "../pageLoader/loader";
import { LiaEllipsisVSolid } from "react-icons/lia";
import GlobalStyles from "../../assets/globalStyle";
import { BsSearch } from "react-icons/bs";
import { getSessions } from "../../services/Subject";
import { LuFileSpreadsheet } from "react-icons/lu";
import PdfDownloader2 from "../PdfDownloader2";

const ExcelJS = require("exceljs");

const Occupants = ({ hostel }) => {
  const { admToken, user } = useSelector((state) => state.auth);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [occupants, setOccupants] = useState([]);
  const [floors, setFloors] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [occupantsLoading, setOccupantsLoading] = useState(false);
  const [fetchOptions, setFetchOptions] = useState({
    floorId: "",
    roomId: "",
    sessionId: "",
  });
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const getFloors = useCallback(async () => {
    setLoading(true);
    if (!hostel) return;
    try {
      const res = await hostelApi.getHostelFloors(
        admToken,
        user.schoolId,
        hostel.hostel_id
      );
      if (res.status && res.statusCode === 2) {
        setFloors(res.data);
      }
      res.status === 500 ||
        res.status === 400 ||
        (!res.status && ErrorService.displayErrorAlert(res));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [admToken, hostel, user.schoolId]);

  const getRooms = useCallback(async () => {
    setLoading(true);
    if (!hostel) return;
    try {
      const res = await hostelApi.getRooms(
        admToken,
        user.schoolId,
        hostel.hostel_id,
        fetchOptions.floorId
      );
      if (res.status && res.statusCode === 2) {
        setRooms(res.data);
      }
      res.status === 500 ||
        res.status === 400 ||
        (!res.status && ErrorService.displayErrorAlert(res));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [admToken, fetchOptions.floorId, hostel, user.schoolId]);
  const schoolSessions = useCallback(async () => {
    try {
      const res = await getSessions(admToken);

      res.status && res.statusCode === 2 && setSessions(res.data);
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);

  const getHostelOccupants = useCallback(
    async (floorId, roomId, sessionId) => {
      setOccupantsLoading(true);
      if (!hostel) return;
      try {
        const res = await hostelApi.getOccupants(
          admToken,
          hostel?.hostel_id,
          floorId,
          roomId,
          sessionId,
          user.schoolId
        );
        if (res.status && res.statusCode === 2) {
          setOccupants(res.data);
        }
        res.status === 500 ||
          res.status === 400 ||
          (!res.status && ErrorService.displayErrorAlert(res));
      } catch (error) {
        console.log(error);
      } finally {
        setOccupantsLoading(false);
      }
    },
    [admToken, hostel, user.schoolId]
  );

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Result sheet");
    sheet.properties.defaultRowHeight = 80;
    sheet.properties.header = "";

    sheet.columns = [
      {
        header: "S/N",
        key: "serialNumber",
        width: "7",
      },
      {
        header: "Name",
        key: "studentName",
        width: "35",
      },
      {
        header: "Admission No",
        key: "admissionNumber",
        width: "15",
      },

      {
        header: "Class",
        key: "className",
        width: "10",
      },
      {
        header: "Hostel",
        key: "hostel",
        width: "20",
      },
      {
        header: "Floor",
        key: "floor",
        width: "20",
      },
      {
        header: "Room",
        key: "room",
        width: "20",
      },
      {
        header: "Bed space",
        key: "bedSpace",
        width: "15",
      },
    ];

    // Add rows to the sheet
    occupants?.forEach((each, index) => {
      const row = {
        serialNumber: index + 1,
        studentName: each.studentName,
        admissionNumber:
          each.admissionNumber === "No Admission Number Yet"
            ? "N/A"
            : each.admissionNumber,
        className: each.className,
        hostel: hostel.hostel_name,
        floor: selectedFloor && selectedFloor.floorName,
        room: selectedRoom && selectedRoom.roomname,
        bedSpace: each.bedSpace,
      };
      sheet.addRow(row);
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Hostel_occupants.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    getFloors();
    getRooms();
    schoolSessions();
  }, [getFloors, getRooms, schoolSessions]);
  return (
    <Container>
      <GlobalStyles />
      <div style={{ height: "600px", overflowY: "scroll" }}>
        <h1 className="text-[1.5rem] pl-6 border-b-[0.5px] border-gray-400 w-[calc(100%+18px)] lg:w-[calc(100%+79px)]  pb-5">
          Occupants of {hostel?.hostel_name}
        </h1>
        <TabContainer
          style={{
            marginTop: "1.2rem",
            background: colors.primary,
            padding: "10px 20px",
          }}
        >
          <InlineLabelGroup>
            {/* <InlineLabelWrapper flex={1}>
            {" "}
            <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
              <Select
                title="Gender"
                index="gender"
                selected={selectedGender.id}
                options={genderOptions}
                setSelected={(val) => {
                  setSelectedGender({
                    ...selectedGender,
                    id: val.id,
                    name: val.gender,
                  });
                }}
                color="white"
                selectPrefix={false}
              />
            </InputWrapper>
          </InlineLabelWrapper> */}
            <InlineLabelWrapper flex={1}>
              {" "}
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title="Session"
                  index="session"
                  selected={fetchOptions.sessionId}
                  options={sessions}
                  setSelected={(val) => {
                    setFetchOptions({
                      ...fetchOptions,
                      sessionId: val.id,
                    });
                  }}
                  color="white"
                  selectPrefix={false}
                />
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={1}>
              {" "}
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title="Floor"
                  index="floorName"
                  thirdOption="floorId"
                  selected={fetchOptions.floorId}
                  options={floors}
                  setSelected={(val) => {
                    setFetchOptions({
                      ...fetchOptions,
                      floorId: val.floorId,
                    });
                    setSelectedFloor(val);
                  }}
                  color="white"
                  selectPrefix={false}
                />
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title={"Room"}
                  index="roomname"
                  selected={fetchOptions.roomId}
                  options={rooms}
                  thirdOption="roomid"
                  setSelected={(val) => {
                    setFetchOptions({
                      ...fetchOptions,
                      roomId: val.roomid,
                    });
                    setSelectedRoom(val);
                  }}
                  color="white"
                  selectPrefix={false}
                />
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={1}>
              <AuthButton
                style={{ fontSize: "14px", fontFamily: "Helvetica" }}
                bg="white"
                mt="0rem"
                color={colors.primary}
                onClick={() =>
                  getHostelOccupants(
                    fetchOptions.floorId,
                    fetchOptions.roomId,
                    fetchOptions.sessionId
                  )
                }
                disabled={
                  !fetchOptions.floorId ||
                  !fetchOptions.roomId ||
                  !fetchOptions.sessionId ||
                  occupantsLoading
                }
                width={isMobile ? "100%" : "90%"}
              >
                {occupantsLoading ? "Loading..." : "Load"}
              </AuthButton>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </TabContainer>
        <TabContainer
          style={{
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
            paddingBottom: "0px",
            marginTop: "50px",
          }}
        >
          <TabLinkContainer bg="rgb(238, 224,241)">
            <div className="flex justify-between items-center !w-full">
              <Input>
                <Icon>
                  <BsSearch />
                </Icon>
                <Search
                  type="text"
                  placeholder="Search For occupants"
                  // value={sQuery}
                  // onChange={(e) => {
                  //   setSQuery(e.target.value);
                  //   if (e.target.value.length > 2) {
                  //     filterSearch(e);
                  //   }

                  //   if (e.target.value === "") {
                  //     resetData();
                  //     console.log("hellow world inside jsx");
                  //   }
                  // }}
                />
              </Input>
              {occupants.length > 0 && (
                <button
                  className="flex gap-1 items-center border p-1"
                  onClick={exportExcelFile}
                >
                  <LuFileSpreadsheet className="w-6 h-6" />
                  Export to excel
                </button>
              )}
            </div>
          </TabLinkContainer>
          <AccountSummaryWrapper pt="0px">
            {occupantsLoading ? (
              <Loader />
            ) : (
              <table
                style={{
                  borderRadius: "0px",
                  borderBottomLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                  // position: "relative",
                }}
              >
                <tr
                  className="tabel-head"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <th className="text-[0.75rem] xl:text-[1.1rem]">S/N</th>
                  <th className="text-[0.75rem] xl:text-[1.1rem]">
                    Student Name
                  </th>
                  <th className="text-[0.75rem] xl:text-[1.1rem]">
                    Admission No
                  </th>
                  <th className="text-[0.75rem] xl:text-[1.1rem]">Category</th>
                  <th className="text-[0.75rem] xl:text-[1.1rem]">Class</th>
                  <th className="text-[0.75rem] xl:text-[1.1rem]">Bedspace</th>
                  <th className="text-[0.75rem] xl:text-[1.1rem]"></th>
                </tr>

                {occupants?.length > 0 ? (
                  occupants.map((each, index) => (
                    <React.Fragment key={index}>
                      <tr className="relative">
                        <td className="text-[0.75rem] xl:text-[1rem]">
                          {index + 1}
                        </td>
                        <td className="text-[0.75rem] xl:text-[1rem]">
                          {each.studentName}
                        </td>
                        <td className="text-[0.75rem] xl:text-[1rem]">
                          {each.admissionNumber}
                        </td>
                        <td className="text-[0.75rem] xl:text-[1rem]">
                          {each.category}
                        </td>
                        <td className="text-[0.75rem] xl:text-[1rem]">
                          {each.className}
                        </td>
                        <td className="text-[0.75rem] xl:text-[1rem]">
                          {each.bedSpace}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td>No occupants</td>
                  </tr>
                )}
              </table>
            )}
          </AccountSummaryWrapper>
        </TabContainer>
      </div>
    </Container>
  );
};

export default Occupants;
