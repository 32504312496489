import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  MenuList,
  Modal,
  Popover,
  TableCell,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import { RiDeleteBin6Line } from "react-icons/ri";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSelector } from "react-redux";
import {
  useCreateClassworkQuestionMutation,
  useGetAllClassworkQuestionQuery,
  useDeleteClassworkQuestionMutation,
} from "../../api";
import EditQuestion from "./modal/EditQuestion";
import Title from "../Dashboard/Title";
import ErrorAlert from "../ErrorAlert";
import LoaderLine from "../LoaderLine";
import SuccessAlert from "../SuccessAlert";
import { toast } from "react-toastify";
import FormLoader from "../FormLoader";

const styleDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gilroy-Regular",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export const Classwork_Second = () => {
  const { data } = useParams();
  const classworkData = JSON.parse(decodeURIComponent(data));
  const [openEdit, setOpenEdit] = useState(false);
  const [prevent, setPrevent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () =>
    classworkData?.isActivated === true ? handlePrevent() : setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const [questionId, setQuestionId] = useState("");
  const [question, setQuestion] = useState();
  const [openModalEdit, setOpenModalEdit] = React.useState(false);
  const handleOpenModalEdit = () => setOpenModalEdit(!openModalEdit);
  const [onDeleteClassworkQuestion] = useDeleteClassworkQuestionMutation();
  const userInfo = useSelector((state) => state.auth.user);
  const { data: classworkQuestions, isLoading } =
    useGetAllClassworkQuestionQuery({
      classWorkId: classworkData?.classWorkId,
      schoolId: userInfo?.schoolId,
    });

  const handlePrevent = () => {
    setPrevent(!prevent);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setQuestionId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const idP = open ? "simple-popover" : undefined;

  const deleteAssignment = async () => {
    if (classworkData?.isActivated === true) {
      handleOpenModalEdit();
    } else {
      setLoading(true);
      try {
        const res = await onDeleteClassworkQuestion({
          classWorkId: classworkData?.classWorkId,
          questionId: questionId,
        });
        if (res) {
          setLoading(false);
          setOpenDelete(false);
          setAnchorEl(null);
          toast?.success(res?.message);
        }
      } catch (error) {
        setLoading(false);
        toast?.error(error);
      }
    }
  };
  return (
    <>
      {loading || (isLoading && <LoaderLine />)}
      <Box
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="grid"
      >
        <Box className="mb-3 lg:mb-5">
          <Title title="Classwork - Add Classwork" />
        </Box>
        <AddQuestion id={classworkData?.classWorkId} />
      </Box>
      <Box
        sx={{
          mt: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Link to="/academicActivities?tab=1">
            <IconButton
              sx={{
                bgcolor: "#671e781a",
                width: "30px",
                height: "30px",
                mr: 2,
              }}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
          </Link>
          <Typography variant="caption" sx={{ fontFamily: "gilroy-regular" }}>
            Back to Classwork
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <TableContainer>
          <Table
            sx={{
              minWidth: 700,
              fontFamily: "Gilroy-Regular",
              border: "1px solid #671E78",
            }}
            aria-label="customized table"
          >
            <TableHead
              sx={{
                backgroundColor: "rgba(103, 30, 120, 0.1)",
                width: "100%",
              }}
            >
              <TableRow>
                <StyledTableCell>S/N</StyledTableCell>
                <StyledTableCell>Question</StyledTableCell>
                <StyledTableCell>
                  <Box className="flex items-center justify-center">
                    <Typography sx={{ fontFamily: "gilroy-regular" }}>
                      {classworkData?.subject} - {classworkData?.classWorkTitle}
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
              {classworkQuestions?.data?.length === 0 ? (
                <>
                  <Box>
                    <Typography>
                      Questions not available, add questions
                    </Typography>
                  </Box>
                </>
              ) : (
                classworkQuestions?.data?.length > 0 &&
                classworkQuestions?.data?.map((question, index) => (
                  <StyledTableRow
                    style={
                      index % 2
                        ? { background: "rgba(103, 30, 120, 0.1)" }
                        : { background: "white" }
                    }
                    key={index}
                  >
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>
                      {question?.question
                        ? question?.question
                        : "--No question found"}
                    </StyledTableCell>{" "}
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton
                        value={question?.questionid}
                        onClick={(event) => {
                          handleClick(event, question?.questionid);
                          setQuestion(question?.question);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Popover
        id={idP}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem
              sx={{ fontFamily: "Gilroy-Regular" }}
              onClick={() => {
                classworkData?.isActivated === true
                  ? handlePrevent()
                  : setOpenEdit(true);
                setAnchorEl(null);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              sx={{ fontFamily: "Gilroy-Regular" }}
              onClick={handleOpenDelete}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Box>
      </Popover>

      {/* <Edit open={openEdit} onClose={handleCloseEdit} /> */}

      <EditQuestion
        open={openEdit}
        onClose={handleCloseEdit}
        id={questionId}
        assignmentId={classworkData?.classWorkId}
        myQuestion={question}
        classworkData={classworkData}
      />

      <Modal
        open={prevent}
        onClose={handlePrevent}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={stylePrevent}>
          <Box align="right" sx={{}}>
            <IconButton onClick={handlePrevent}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box p={3} sx={{ overflowY: "scroll", height: "200px" }}>
            <Box className="w-full h-full">
              <Typography sx={{ fontFamily: "Gilroy-Medium" }}>
                This classwork has been activated, you cannot delete or edit
                this question
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDelete}>
          <Box align="center">
            <RiDeleteBin6Line style={{ fontSize: "42px", color: "#d3302f" }} />
            <Box sx={{ mt: 3 }}>
              <Typography sx={{ fontFamily: "gilroy-bold" }}>
                You're about to delete Question
              </Typography>
            </Box>
            <Typography
              sx={{
                fontFamily: "gilroy-regular",
                color: "#9c9c9c",
                fontSize: "12px",
              }}
            >
              This will delete the Qustion from the database
            </Typography>
            <Typography
              sx={{
                fontFamily: "gilroy-regular",
                color: "#9c9c9c",
                fontSize: "12px",
                mt: 2,
              }}
            >
              Are you sure?
            </Typography>
          </Box>
          <Box align="right" sx={{ mt: 2 }}>
            <Button
              sx={{
                color: "#333",
                textTransform: "initial",
                fontFamily: "gilroy-regular",
              }}
              onClick={handleCloseDelete}
            >
              Cancel
            </Button>
            <Button
              onClick={deleteAssignment}
              disabled={loading}
              sx={{
                bgcolor: "#d3302f",
                color: "#fff",
                ml: 2,
                px: 3,
                textTransform: "initial",
                fontFamily: "gilroy-regular",
                "&:hover": {
                  bgcolor: "#d3302fb7",
                  color: "#fff",
                },
              }}
            >
              {loading ? <FormLoader /> : <span>Delete</span>}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    sx: 200,
    lg: 650,
  },
  bgcolor: "background.paper",
  border: "1px solid #671E78",
  boxShadow: 24,
  p: 2,
  borderRadius: 3,
  height: 600,
  overflow: "scroll",
};
const stylePrevent = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "1px solid #671E78",
  boxShadow: 24,
  p: 2,
  borderRadius: 3,
  height: 200,
  overflow: "scroll",
};

const AddQuestion = () => {
  const { data } = useParams();
  const classworkData = JSON.parse(data);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [prevent, setPrevent] = useState(false);
  const [selectedFile, setSelectedFile] = React.useState("");
  const [selectedFileName, setSelectedFileName] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState(false);
  const [question, setQuestion] = useState("");
  const userInfo = useSelector((state) => state.auth.user);
  const handlePrevent = () => {
    setPrevent(!prevent);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFileName(file.name);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result;
        setSelectedFile(base64Data);
      };
      reader.readAsDataURL(file);
    }
  };

  const [onCreateQuestion] = useCreateClassworkQuestionMutation();
  const handleSubmit = async () => {
    setResponseMessage(false);
    setErrorMessage(false);
    setLoading(true);
    const questData = {
      schoolId: userInfo?.schoolId,
      classWorkId: classworkData?.classWorkId,
      question: question,
      marks: 0,
      questionFile: selectedFile,
    };
    try {
      const res = await onCreateQuestion(questData)?.unwrap();
      if (res) {
        setLoading(false);
        setResponseMessage(true);
        setOpen(false);
        setSelectedFileName("");
        setQuestion("");
      }
    } catch (err) {
      console.log("this is the error from creating questions: ", err);
      setErrorMessage(true);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoaderLine />}
      {responseMessage && (
        <SuccessAlert
          openState={responseMessage}
          message={"Question added Successfully"}
        />
      )}
      {errorMessage && (
        <ErrorAlert
          openState={errorMessage}
          message={"Error, please try again"}
        />
      )}
      <Box className="lg:flex justify-between">
        <Box></Box>
        <Box>
          <Button
            onClick={() => {
              classworkData?.isActivated === true
                ? handlePrevent()
                : handleOpen();
            }}
            variant="contained"
            sx={{
              textTransform: "initial",
              fontFamily: "Gilroy-Bold",
            }}
            className="w-full lg:w-[400px] py-3"
          >
            Add Question
          </Button>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box align="right">
            <IconButton onClick={handleClose}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box align="center" sx={{ mb: 2 }}>
            <Typography sx={{ fontFamily: "gilroy-bold", fontSize: "30px" }}>
              Add Question
            </Typography>
          </Box>
          <Box className="flex items-center justify-center" sx={{ mb: 2 }}>
            <Typography sx={{ fontFamily: "gilroy-regular" }}>
              {classworkData?.subject} - {classworkData?.classWorkTitle}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ mt: 3, px: 3 }}>
            <InputLabel
              sx={{
                fontFamily: "gilroy-regular",
                color: "#333",
                fontSize: "12px",
                mb: 1,
              }}
            >
              Enter Question in the textbox below
            </InputLabel>
            <TextField
              size="large"
              fullWidth
              multiline
              rows={6}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              InputProps={{
                style: {
                  fontFamily: "Gilroy-regular",
                  fontSize: "13px",
                  borderRadius: "5px",
                  offset: " 0.5px solid #671E78",
                  border: " 0.5px solid #671E78",
                  // Replace with your desired font family
                },
              }}
            />
            <Grid
              item
              lg={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "30px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <InputLabel
                  sx={{
                    fontFamily: "gilroy-regular",
                    color: "#333",
                    fontSize: "12px",
                    mb: 1,
                    mt: 3,
                  }}
                >
                  Upload Picture(optional)
                </InputLabel>
                <TextField
                  type="file"
                  fullWidth
                  onChange={handleFileChange}
                  InputProps={{
                    style: {
                      fontFamily: "Gilroy-regular",
                      fontSize: "13px",
                      borderRadius: "5px",
                      offset: " 0.5px solid #671E78",
                      border: " 0.5px solid #671E78",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Box>
          <Button
            disabled={!question}
            variant="contained"
            fullWidth
            sx={{
              mt: 4,
              textTransform: "initial",
              fontFamily: "gilroy-regular",
            }}
            onClick={handleSubmit}
          >
            Add Question
          </Button>
        </Box>
      </Modal>
      <Modal
        open={prevent}
        onClose={handlePrevent}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={stylePrevent}>
          <Box align="right" sx={{}}>
            <IconButton onClick={handlePrevent}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box p={3} sx={{ overflowY: "scroll", height: "200px" }}>
            <Box className="w-full h-full">
              <Typography sx={{ fontFamily: "Gilroy-Medium" }}>
                This classwork has been activated, you cannot add more questions
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
