import React, { useState, createRef } from "react";
import {
  FormFlex,
  FormFlexDiv,
  TabContentWrapper,
  InlineFormDiv,
  AccountSummaryWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
import DatePickerComponent from "../datepicker/DatePicker";
import colors from "../../assets/colors.json";
import PropTypes from "prop-types";
import FormLoader from "../FormLoader";
import { InputWrapper, AuthButton } from "../../pages/auth/Login.style";
// import Select from "../select/select";
import { useMediaQuery } from "react-responsive";
import moment from "moment/moment";

const Certifications = ({
  data,
  obj,
  setObj,
  action,
  showForm,
  formLoading,
  clearFields,
}) => {
  const onPickFileRef = createRef();
  const [fileName, setFileName] = useState("");
  const [base64, setBase64] = useState("");

  const isTab = useMediaQuery({ maxWidth: 992 });

  const pickFile = () => {
    onPickFileRef.current.click();
  };
  const onPickFile = (event) => {
    const files = event.target.files;
    setFileName(files[0].name);
    let filename = files[0].name;
    if (
      filename.includes(".pdf") ||
      filename.includes(".doc") ||
      filename.includes(".docx") ||
      filename.includes(".jpg") ||
      filename.includes(".jpeg") ||
      filename.includes(".png")
    ) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        setFileName(files[0].name);
        setObj({ ...obj, proofFileBase64: fileReader.result });
      });
      fileReader.readAsDataURL(files[0]);
    } else {
      alert("Please upload a document");
    }
  };

  return (
    <TabContentWrapper>
      {showForm && (
        <>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="School">Institution/Body</label>
                <input
                  type="text"
                  placeholder="Institution Body"
                  value={obj.institution}
                  onChange={(e) =>
                    setObj({ ...obj, institution: e.target.value })
                  }
                  readOnly
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Degree">License Name</label>
                <input
                  type="text"
                  placeholder="License Name"
                  value={obj.licenseName}
                  onChange={(e) =>
                    setObj({ ...obj, licenseName: e.target.value })
                  }
                  readOnly
                />
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="Course">Course Name</label>
                <input
                  type="text"
                  placeholder="Course Name"
                  value={obj.courseName}
                  onChange={(e) =>
                    setObj({ ...obj, courseName: e.target.value })
                  }
                  readOnly
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Issuing Body">Issuing Body</label>
                <input
                  type="text"
                  placeholder="Issuing Body"
                  value={obj.issueingBody}
                  onChange={(e) =>
                    setObj({ ...obj, issueingBody: e.target.value })
                  }
                  readOnly
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="">Start Date</label>

                <DatePickerComponent
                  selected={
                    obj.startDate === "" || obj.startDate === null
                      ? ""
                      : new Date(Date.parse(obj.startDate))
                  }
                  setSelected={(val) => setObj({ ...obj, startDate: val })}
                  disabled
                />
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="">End Date</label>

                <DatePickerComponent
                  selected={
                    obj.endDate === "" || obj.endDate === null
                      ? ""
                      : new Date(Date.parse(obj.endDate))
                  }
                  setSelected={(val) => setObj({ ...obj, endDate: val })}
                  disabled
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          <FormFlex>
            <input
              type="file"
              style={{
                display: "none",
              }}
              onChange={onPickFile}
              ref={onPickFileRef}
            />
            <FormFlexDiv>
              <InlineFormDiv style={{ padding: "0px" }} width="100%">
                {/* <div style={{ width: isTab ? "100%" : "70%" }}>
                  <InputWrapper>
                    <label htmlFor="filename">Proof Of Qualification</label>
                    <input
                      type="text"
                      value={obj.proofFileBase64 ? fileName + "" + base64 : ""}
                      disabled={true}
                      placeholder="File Name"
                      readOnly
                    />
                  </InputWrapper>
                </div> */}

                {/* <AuthButton
                  width={isTab ? "100%" : "20%"}
                  onClick={pickFile}
                  bg={colors.secondary}
                  color={colors.primary}
                >
                  Upload File
                </AuthButton> */}
              </InlineFormDiv>
            </FormFlexDiv>
            {/* <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="comments">Comments</label>
                <input
                  type="text"
                  placeholder="Comments"
                  value={"bioData.admission_no"}
                  onChange={(e) => console.log(e.target.value)}
                  disabled={true}
                />
              </InputWrapper>
            </FormFlexDiv> */}
          </FormFlex>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: isTab ? "column" : "row",
            }}
          >
            {/* <AuthButton
              width={isTab ? "100%" : "30%"}
              onClick={action}
              disabled={formLoading}
            >
              {formLoading ? (
                <FormLoader />
              ) : (
                <div>
                  <span>{obj.id ? "Edit" : "Add"} Certification</span>
                </div>
              )}
            </AuthButton> */}
            {obj.id && (
              <AuthButton
                onClick={clearFields}
                width={isTab ? "100%" : "30%"}
                bg="red"
                style={{
                  border: "1px solid red",
                  marginLeft: isTab ? "0px" : "10px",
                  marginTop: isTab ? "15px" : "0px",
                }}
              >
                Clear
              </AuthButton>
            )}
          </div>
        </>
      )}
      <AccountSummaryWrapper>
        <table>
          {data.length > 0 ? (
            data.map((item, index) => (
              <tr style={{ fontWeight: "bold" }} key={index}>
                <td>
                  <div style={{ display: "inline-flex", alignItems: "center" }}>
                    <div
                      style={{
                        height: "5px",
                        width: "5px",
                        padding: "5px",
                        borderRadius: "50%",
                        marginRight: "8px",
                        backgroundColor: colors.primary,
                      }}
                    ></div>
                    <div>
                      <p>{item.issueingBody}</p>
                      <p style={{ fontWeight: "semibold" }}>
                        {item.institution}
                      </p>
                    </div>
                  </div>
                </td>
                <td>{item.courseName}</td>
                <td>{`${moment(item.startDate).format("YYYY")}- ${moment(
                  item.endDate
                ).format("YYYY")}`}</td>
                <td>
                  <button
                    style={{
                      borderWidth: "1px",
                      borderRadius: "5px",
                      borderColor: colors.primary,
                      color: "black",
                      padding: "10px 20px",
                      backgroundColor: "transparent",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      setObj(Object.assign({}, item));
                    }}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr style={{ fontWeight: "bold" }}>
              <td style={{ textAlign: "center" }}>No Ceritification</td>
            </tr>
          )}
        </table>
      </AccountSummaryWrapper>
    </TabContentWrapper>
  );
};

Certifications.propTypes = {
  data: PropTypes.array,
  showForm: PropTypes.bool,
};

Certifications.defaultProps = {
  data: [],
  showForm: false,
};

export default Certifications;
