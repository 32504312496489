import React, { useState, createRef } from "react";
import colors from "../../assets/colors.json";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/system";
import {
  AccountSummaryWrapper,
  BigHeader,
  Icon,
  Input,
  Search,
  SmallHeader,
  TabContainer,
  TabContentWrapper,
  TabLinkContainer,
  FormFlex,
  FormFlexDiv,
  InlineFormDiv,
} from "../../pages/Dashboard/styles/dashboard.style";
import Popover from "@mui/material/Popover";
import DatePickerComponent from "../datepicker/DatePicker";
import PropTypes from "prop-types";
import { ImgTextDiv } from "../../pages/student_mgt/stu_mgt.style";
import Avatar from "../../assets/Images/avatar.jpg";
import {
  InputWrapper,
  ButtonFlex,
  AuthButton,
} from "../../pages/auth/Login.style";
import { IconButton, MenuItem, MenuList } from "@mui/material";
import { BsSearch } from "react-icons/bs";
import Modal from "../../components/modal/Modal";
import { useMediaQuery } from "react-responsive";

const ApprovalLeave = ({ showOnlyList }) => {
  const [showModal, setShowModal] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fileName, setFileName] = useState("");
  const onPickFileRef = createRef();

  const [base64, setBase64] = useState("");
  const [dob, setDob] = useState(new Date(2014, 0, 3));
  const isTab = useMediaQuery({ maxWidth: 992 });
  const pickFile = () => {
    onPickFileRef.current.click();
  };
  const onPickFile = (event) => {
    const files = event.target.files;
    setFileName(files[0].name);
    let filename = files[0].name;
    if (
      filename.includes(".pdf") ||
      filename.includes(".doc") ||
      filename.includes(".docx")
    ) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        setBase64(fileReader.result);
      });
      fileReader.readAsDataURL(files[0]);
    } else {
      alert("Please upload a document");
    }

    console.log("We are working");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }}>Leave Information</BigHeader>
      <SmallHeader
        style={{
          textAlign: "center",
          margin: "0 auto",
          background: colors.primary,
          borderRadius: "5px",
          color: "white",
          width: "fit-content",
          padding: "5px 10px",
        }}
      >
        Annual Leave - Mr James John
      </SmallHeader>

      <TabContentWrapper>
        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="start_date">Start Date</label>
              <DatePickerComponent
                selected={dob}
                setSelected={(val) => {
                  setDob(val);
                  // calculateAge(val);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor="start_date">End Date</label>
              <DatePickerComponent
                selected={dob}
                setSelected={(val) => {
                  setDob(val);
                  // calculateAge(val);
                }}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>

        <FormFlex>
          <FormFlexDiv width="45%">
            <InputWrapper>
              <label htmlFor=""> Reason For Leave</label>
              <textarea name="" id="" cols="30" rows="5"></textarea>
            </InputWrapper>
          </FormFlexDiv>
          <FormFlexDiv
            width="45%"
            style={{
              padding: "0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <InlineFormDiv
              width="100%"
              style={{ padding: "0px", justifyContent: "space-between" }}
            >
              <div style={{ width: isTab ? "100%" : "60%" }}>
                <InputWrapper>
                  <label htmlFor="">Proof</label>
                  <input
                    type="text"
                    value={fileName + "" + base64}
                    disabled={true}
                    placeholder="File Name"
                  />
                </InputWrapper>
              </div>
              <AuthButton
                width={isTab ? "100%" : "30%"}
                onClick={pickFile}
                bg={colors.secondary}
                color={"black"}
              >
                Choose
              </AuthButton>
            </InlineFormDiv>
            <InlineFormDiv
              width="100%"
              style={{ padding: "0px", justifyContent: "space-between" }}
            >
              <div style={{ width: isTab ? "100%" : "60%" }}>
                <InputWrapper>
                  <label htmlFor="">Hand Over Note</label>
                  <input
                    type="text"
                    value={fileName + "" + base64}
                    disabled={true}
                    placeholder="File Name"
                  />
                </InputWrapper>
              </div>
              <AuthButton
                width={isTab ? "100%" : "30%"}
                onClick={pickFile}
                bg={colors.secondary}
                color={"black"}
              >
                Choose
              </AuthButton>
            </InlineFormDiv>
          </FormFlexDiv>
        </FormFlex>

        <TabContainer style={{ borderRadius: "10px" }}>
          <AccountSummaryWrapper pt="0px">
            <table>
              <tr
                className="table-head"
                style={{ color: "black", fontWeight: "bold" }}
              >
                <th>S/N</th>
                <th>File Name</th>
                <th>Upload Date</th>

                <th></th>
              </tr>
              <tr>
                <td>1</td>
                <td>Proof</td>
                <td>12/12/2023</td>
                <td>
                  <ButtonFlex>
                    <button>View</button>
                  </ButtonFlex>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Hand Over Note</td>
                <td>12/12/2023</td>
                <td>
                  <ButtonFlex>
                    <button>View</button>
                  </ButtonFlex>
                </td>
              </tr>
            </table>
          </AccountSummaryWrapper>
        </TabContainer>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <AuthButton width={isTab ? "100%" : "80%"} onSubmit={null}>
            <div>
              <span>Save</span>
            </div>
            {/* Send Code */}
          </AuthButton>
        </div>
      </TabContentWrapper>
    </>
  );
  return (
    <>
      {showModal && (
        <Modal
          content={content}
          size={"large"}
          onClose={setShowModal}
          start={true}
        />
      )}

      <div style={{ marginTop: "0.7rem" }}>
        <TabContainer style={{ paddingBottom: "0px" }}>
          <TabLinkContainer bg="#EEE0F1">
            <Input>
              <Icon>
                <BsSearch />
              </Icon>
              <Search type="text" placeholder="Search" />
            </Input>
          </TabLinkContainer>
          <AccountSummaryWrapper pt="0px">
            <table style={{ borderRadius: "0px" }}>
              <tr
                className="table-head"
                style={{ backgroundColor: "#F0E8F1", color: "black" }}
              >
                <th>
                  <input type="checkbox" />
                </th>
                <th>Staff ID</th>
                <th>Staff Name</th>
                <th>Type</th>
                <th>Duration</th>
                <th>Start Date</th>
                <th>End Date </th>
                <th>Applied on</th>
                <th></th>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>CMS/2022/001</td>
                <td>
                  <ImgTextDiv>
                    <img src={Avatar} alt="" />
                    <span>Adekitan Sandra</span>
                  </ImgTextDiv>
                </td>
                <td>Annual Leave</td>
                <td>2</td>
                <td>April 20th, 2023</td>
                <td>April 23th, 2023</td>
                <td>April 10th, 2023</td>
                <td>
                  <IconButton onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>
                </td>
              </tr>
            </table>
          </AccountSummaryWrapper>
        </TabContainer>

        <div
          style={{
            display: "flex",
            marginTop: "1rem",
            justifyContent: "end",
            padding: "10px 20px",
          }}
        >
          <button
            style={{
              borderRadius: "8px",
              // padding: "8px",
              minWidth: "121px",
              color: "white",
              backgroundColor: colors.primary,
              cursor: "pointer",
              lineHeight: "2.125rem",
              border: `1px solid ${colors.primary}`,
              outline: "none",
              fontWeight: "bold",
            }}
          >
            Approve
          </button>
          <button
            style={{
              marginLeft: "0.5rem",
              borderRadius: "8px",
              fontSize: "16px",
              // padding: "8px",
              minWidth: "121px",
              backgroundColor: "white",
              color: colors.primary,
              cursor: "pointer",
              lineHeight: "2.125rem",
              border: `1px solid ${colors.primary}`,
              outline: "none",
              fontWeight: "bold",
            }}
          >
            Delete
          </button>
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem
              onClick={() => {
                setShowModal(!showModal);
                handleClose();
              }}
            >
              View
            </MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </>
  );
};

ApprovalLeave.propTypes = {
  showOnlyList: PropTypes.bool,
};

ApprovalLeave.defaultProps = {
  showOnlyList: false,
};

export default ApprovalLeave;
