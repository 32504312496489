import React, { useState } from "react";
import {
  TabContainer,
  BigHeader,
  SmallHeader,
  InlineLabelGroup,
  InlineLabelWrapper,
  Input,
  Icon,
  Search,
  TabLinkContainer,
  AccountSummaryWrapper,
  // TabContainer,
} from "../Dashboard/styles/dashboard.style";
import { InputWrapper } from "../auth/Login.style";
import Avatar from "../../assets/Images/avatar.jpg";
import Select from "../../components/select/select";
import { useMediaQuery } from "react-responsive";
import { BsSearch, BsFillPrinterFill } from "react-icons/bs";
import { SiAdobeacrobatreader } from "react-icons/si";
import { AiFillFileExcel } from "react-icons/ai";
import { ImgTextDiv } from "../student_mgt/stu_mgt.style";

const NominalRoll = () => {
  const [opt, setOpt] = useState("");
  const isTab = useMediaQuery({ maxWidth: 992 });

  const optionsArr = [
    {
      id: 1,
      name: "Value 1",
    },
    {
      id: 2,
      name: "Value 2",
    },
  ];
  return (
    <>
      <TabContainer wd={isTab ? "100%" : "70%"} style={{ border: "none" }}>
        <InlineLabelGroup>
          <InlineLabelWrapper flex={4}>
            <InlineLabelGroup>
              {/* Search By School */}
              <InlineLabelWrapper flex={2}>
                <InputWrapper>
                  <Select
                    // color={"white"}
                    title="School"
                    index="name"
                    selected={opt}
                    options={optionsArr}
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              {/* Search Department */}
              <InlineLabelWrapper flex={1}>
                <InputWrapper>
                  <Select
                    // color={"white"}
                    title="School"
                    index="name"
                    selected={opt}
                    options={optionsArr}
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              {/* Search By Unit */}
              <InlineLabelWrapper flex={1}>
                <InputWrapper>
                  <Select
                    // color={"white"}
                    title="School"
                    index="name"
                    selected={opt}
                    options={optionsArr}
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
          </InlineLabelWrapper>
        </InlineLabelGroup>
      </TabContainer>

      <TabContainer style={{ marginTop: "1rem" }}>
        <TabLinkContainer bg="#F0E8F1" style={{ position: "relative" }}>
          <Input>
            <Icon tp="16px">
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search" />
          </Input>
          <div style={{ display: "inline-flex" }} className="div">
            <AiFillFileExcel
              style={{ fontSize: "25px", marginLeft: "0.4rem" }}
            />
            <SiAdobeacrobatreader
              style={{ fontSize: "25px", marginLeft: "0.4rem" }}
            />
            <BsFillPrinterFill
              style={{ fontSize: "25px", marginLeft: "0.4rem" }}
            />
          </div>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table style={{ borderRadius: "0px" }}>
            <tr
              className="table-head"
              style={{ backgroundColor: "#F0E8F1", color: "black" }}
            >
              <th>
                {" "}
                <p style={{ width: "max-content" }}> Staff Name</p>
              </th>
              <th>
                {" "}
                <p style={{ width: "max-content" }}> Sex</p>
              </th>
              <th>
                {" "}
                <p style={{ width: "max-content" }}> Qualification & Date</p>
              </th>
              <th>
                {" "}
                <p style={{ width: "max-content" }}> Current Home Address</p>
              </th>
              <th>
                {" "}
                <p style={{ width: "max-content" }}> Date of Appointment</p>
              </th>
              <th>
                {" "}
                <p style={{ width: "max-content" }}> Date of Confirmation</p>
              </th>
              <th>
                {" "}
                <p style={{ width: "max-content" }}> Subject Taught</p>
              </th>
              <th>
                {" "}
                <p style={{ width: "max-content" }}> Total No of Period</p>
              </th>
              <th>
                {" "}
                <p style={{ width: "max-content" }}> Class Taught</p>
              </th>
              <th>
                {" "}
                <p style={{ width: "max-content" }}> Phone Number</p>
              </th>
              <th>
                {" "}
                <p style={{ width: "max-content" }}> Cadre</p>
              </th>
            </tr>
            <tr>
              <td>
                <ImgTextDiv>
                  <img src={Avatar} alt="" />
                  <span style={{ width: "max-content" }}>Adekitan Sandra</span>
                </ImgTextDiv>
              </td>
              <td>Male</td>
              <td>B sc. Business(EDU)</td>
              <td>12, Adeola Street</td>
              <td>12/02/2022</td>
              <td>12/02/2022</td>
              <td>Math, English</td>
              <td>4</td>
              <td>JSS 2</td>
              <td>08012345678</td>
              <td>
                <p style={{ width: "max-content" }}>Vice Principal</p>
              </td>
            </tr>
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
    </>
  );
};

export default NominalRoll;
