import { useState, useCallback, useEffect } from "react";
import axios from "axios";
import GlobalStyles from "../../assets/globalStyle";
import {
  SmallHeader,
  TabContainer,
  TabContent,
  TabLink,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import {
  Personal,
  Experience,
  Education,
  Certifications,
  NOK,
  UserInformation,
} from "../profile";
import Loader from "../pageLoader/loader";
import colors from "../../assets/colors.json";
import { viewStaffDetails } from "../../services/MySchool";

const Profile = ({ schoolId, staffId, token }) => {
  const [tab, setTab] = useState(6);
  const [loading, setLoading] = useState(true);
  const [bioData, setBioData] = useState(null);
  const [name, setName] = useState("");

  const getStaffProfile = useCallback(
    async (staffId, schoolId, token) => {
      try {
        const endpoint = `https://lasmabadmindev.azurewebsites.net/api/v1/MySchool/ViewStaff-Details?schoolId=${schoolId}&staffId=${staffId}`;
        const res = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.data.status === true && res.data.statusCode === 2) {
          setBioData(res.data.data);
          setName(
            `${res.data.data.persoalData.firstName} ${res.data.data.persoalData.otherName}`
          );
        } else {
          setBioData(null);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
    [schoolId, staffId, token]
  );

  useEffect(
    () => {
      getStaffProfile(staffId, schoolId, token);
    },
    [getStaffProfile, staffId, schoolId, token],
    []
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <GlobalStyles />
      <SmallHeader
        style={{
          fontWeight: "bold",
          color: colors.lightGray,
          marginBottom: "1.9rem",
        }}
      >
        <span style={{ marginRight: "0.9rem" }}>MySchool {">>"}</span>
        <span style={{ marginRight: "0.9rem" }}>School - Staff info</span>
        {name.length > 0 && (
          <>
            <span style={{ marginRight: "0.9rem" }}>
              Staff Info - {name} {">>"}
            </span>
            <span style={{ color: "black" }}>Profile</span>
          </>
        )}
      </SmallHeader>

      <TabContainer>
        <TabLinkContainer>
          <TabLink
            isActive={tab === 6}
            onClick={() => setTab(6)}
            minWidth="15%"
          >
            <p>User Information</p>
          </TabLink>
          <TabLink
            isActive={tab === 1}
            onClick={() => setTab(1)}
            minWidth="15%"
          >
            <p>Personal Data</p>
          </TabLink>{" "}
          <TabLink
            isActive={tab === 2}
            onClick={() => setTab(2)}
            minWidth="15%"
          >
            <p>
              Educational <br />
              Qualification
            </p>
          </TabLink>{" "}
          <TabLink
            isActive={tab === 3}
            onClick={() => setTab(3)}
            minWidth="15%"
          >
            <p>
              Experience <br />
              History
            </p>
          </TabLink>{" "}
          <TabLink
            isActive={tab === 4}
            onClick={() => setTab(4)}
            minWidth="15%"
          >
            <p>Certifications</p>
          </TabLink>{" "}
          <TabLink
            isActive={tab === 5}
            onClick={() => setTab(5)}
            minWidth="15%"
          >
            <p>Next of Kin</p>
          </TabLink>{" "}
        </TabLinkContainer>

        {bioData !== null && (
          <TabContent>
            {tab === 6 && (
              <UserInformation
                data={bioData.userInformation}
                myClasses={bioData.myClasses}
              />
            )}
            {tab === 1 && <Personal data={bioData.persoalData} />}
            {tab === 2 && <Education data={bioData.educations} />}
            {tab === 3 && <Experience data={bioData.experienceHistories} />}
            {tab === 4 && <Certifications data={bioData.certifications} />}
            {tab === 5 && <NOK data={bioData.nextOfKins} />}
          </TabContent>
        )}

        {bioData === null && (
          <SmallHeader
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: colors.primary,
              marginTop: "0.4rem",
            }}
          >
            No Staff Information, Please Try Again Later!!!
          </SmallHeader>
        )}
      </TabContainer>
    </>
  );
};

export default Profile;
