import React, { createRef, useState, useEffect } from "react";
import colors from "../../assets/colors.json";
import { InputWrapper, AuthButton } from "../../pages/auth/Login.style";
import { useMediaQuery } from "react-responsive";
import {
  AccountSummaryWrapper,
  AttachmentFlex,
  AttachmentUploadContainer,
  InlineFormDiv,
  ButtonFlex,
  SmallHeader,
} from "../../pages/Dashboard/styles/dashboard.style";
import PropTypes from "prop-types";
import { AiFillFileAdd } from "react-icons/ai";
import moment from "moment/moment";
import FormLoader from "../FormLoader";

const Attachment = ({
  data,
  showForm,
  action,
  obj,
  setObj,
  loading,
  showSubmitButton,
  submitAction,
  submitLoading,
  bioData,
}) => {
  const onPickFileRef = createRef();
  const [fileName, setFileName] = useState("");
  // const [base64, setBase64] = useState("");
  const isTab = useMediaQuery({ maxWidth: 992 });
  const pickFile = () => {
    onPickFileRef.current.click();
  };
  const onPickFile = (event) => {
    console.log(event);
    const files = event.target.files;
    console.log(files[0]);
    // setObj({ ...obj, fileName });
    setFileName(files[0].name);
    let filename = files[0].name;
    if (
      filename.toLowerCase().includes(".pdf") ||
      filename.toLowerCase().includes(".doc") ||
      filename.toLowerCase().includes(".docx") ||
      filename.toLowerCase().includes(".jpg") ||
      filename.toLowerCase().includes(".jpeg") ||
      filename.toLowerCase().includes(".png")
    ) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        // setBase64(fileReader.result);

        setObj({
          ...obj,
          fileName: filename,
          fileContent: fileReader.result,
          fileContentPath: fileReader.result,
        });
      });
      fileReader.readAsDataURL(files[0]);
    } else {
      alert("Please upload a document");
    }

    console.log("We are working");
  };
  return (
    <AttachmentFlex>
      {showForm && (
        <>
          <AttachmentUploadContainer onClick={pickFile}>
            {obj.fileContent.length === 0 && (
              <AiFillFileAdd
                style={{ color: colors.primary, fontSize: "40px" }}
              />
            )}
            {(fileName.toLowerCase().includes(".doc") ||
              fileName.toLowerCase().includes(".docx") ||
              fileName.toLowerCase().includes(".pdf")) && (
              <AiFillFileAdd
                style={{ color: colors.primary, fontSize: "40px" }}
              />
            )}
            {obj.fileContent.length > 0 &&
              (fileName.toLowerCase().includes(".jpg") ||
                fileName.toLowerCase().includes(".jpeg") ||
                fileName.toLowerCase().includes(".png")) && (
                <img src={obj.fileContent} alt="" />
              )}
            {fileName.includes(".doc" || ".docx" || ".pdf") && (
              <a
                href={obj.fileContent}
                target="_blank"
                rel="noreferrer"
                style={{ color: colors.primary }}
              >
                View File
              </a>
            )}
            {/* <UploadFileRounded /> */}
            {/* <p>Fix Icon Later</p>
            Upload file */}
            <input
              type="file"
              style={{
                display: "none",
              }}
              onChange={onPickFile}
              ref={onPickFileRef}
            />
          </AttachmentUploadContainer>
          <p
            style={{
              fontWeight: "bold",
              color: colors.primary,
              marginTop: "0.2rem",
            }}
          >
            Click On The Box Above
          </p>
          <InlineFormDiv>
            <div style={{ width: isTab ? "100%" : "70%" }}>
              <InputWrapper>
                <label htmlFor="filename">
                  File Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  value={obj.fileName}
                  onChange={(e) => setObj({ ...obj, fileName: e.target.value })}
                  placeholder="Enter File Name e.g Birth Certificate"
                />
              </InputWrapper>
            </div>

            <AuthButton
              width={isTab ? "100%" : "20%"}
              onClick={action}
              disabled={
                loading ||
                obj.file === "" ||
                obj.fileContent === "" ||
                obj.fileContentPath === ""
              }
            >
              {loading ? <FormLoader /> : "Upload File"}
            </AuthButton>
          </InlineFormDiv>
        </>
      )}
      {data === null ? (
        <SmallHeader style={{ textAlign: "center" }}>
          No Student Attachments At The Moment
        </SmallHeader>
      ) : (
        <AccountSummaryWrapper>
          <table>
            <tr
              className="table-head"
              style={{
                color: "black",
              }}
            >
              <th>S/N</th>
              <th>File Name</th>
              <th>Upload Date</th>
              <th></th>
            </tr>
            {data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>

                  <td>{item.fileName}</td>
                  <td>
                    {item.updateDate
                      ? moment(item.updateDate).format("YYYY/MM/DD")
                      : " No Date From Api"}
                  </td>
                  <td>
                    <ButtonFlex
                      style={{
                        justifyContent: "space-around",
                      }}
                    >
                      <a
                        href={
                          item.fileContentPath ||
                          "https://www.adobe.com/support/products/enterprise/knowledgecenter/media/c4611_sample_explain.pdf"
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        View
                      </a>
                      <button
                        style={{
                          color: "white",
                          backgroundColor: "red",
                          borderColor: "red",
                        }}
                      >
                        Delete
                      </button>
                    </ButtonFlex>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} style={{ textAlign: "center" }}>
                  No Attachments At The Moment
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      )}

      {showSubmitButton &&
        bioData.accountStatus !== "3.0" &&
        bioData.accountStatus !== "3" && (
          <AuthButton
            width="100%"
            onClick={() => submitAction(bioData.studentId)}
            disabled={submitLoading}
          >
            {submitLoading ? <FormLoader /> : "Submit Application"}
          </AuthButton>
        )}
    </AttachmentFlex>
  );
};

Attachment.propTypes = {
  data: PropTypes.array,
  showForm: PropTypes.bool,
  showSubmitButton: PropTypes.bool,
  showInfoButton: PropTypes.bool,
};

Attachment.defaultProps = {
  data: [],
  showForm: false,
  showSubmitButton: false,
};

export default Attachment;
