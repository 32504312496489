import React from "react";
import GlobalStyles from "../../assets/globalStyle";
import { SmallHeader } from "../../pages/Dashboard/styles/dashboard.style";
import colors from "../../assets/colors.json";
import { useLocation } from "react-router-dom";
// import AdmissionList from "./AdmissionList";
// import SingleAdmission from "./SingleAdmission";
import { SingleAdmission, AdmissionList } from "./index";
const Admission = () => {
  const location = useLocation();
  const admission_id = new URLSearchParams(location.search).get("admission_id");
  return (
    <>
      <GlobalStyles />
      <SmallHeader
        style={{
          fontWeight: "bold",
          color: colors.lightGray,
          marginBottom: "1.9rem",
        }}
      >
        <span style={{ marginRight: "0.9rem" }}>Parent Managment</span>

        <span style={{ marginRight: "0.9rem" }}>
          Parent Dashboard- Mr James Olu(2) {">>"}
        </span>
        <span style={{ color: "black" }}>Admission</span>
      </SmallHeader>

      {!admission_id ? <AdmissionList /> : <SingleAdmission />}
    </>
  );
};

export default Admission;
