import React, { useState, useEffect, useCallback } from "react";
import { SiAdobeacrobatreader } from "react-icons/si";
import { BsSearch, BsFillPrinterFill } from "react-icons/bs";
import { IconButton, MenuItem, MenuList } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Pagination from "../pagination_one/pagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "../../assets/Images/avatar.jpg";
import { Box } from "@mui/system";
import Popover from "@mui/material/Popover";
import { AiFillFileExcel } from "react-icons/ai";
import { ImgTextDiv, StatusBar } from "../../pages/student_mgt/stu_mgt.style";
import PropTypes from "prop-types";
// import { approveStudent } from "../../services/ParentStudent";
import { paginateData } from "../../services/utils";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { storePrev } from "../../features/auth/authSlice";
import {
  Icon,
  Input,
  TabContainer,
  TabLinkContainer,
  Search,
  AccountSummaryWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
const LinkedUnLinkedStudent = ({ allData, type, action, schoolId }) => {
  const [pageCount, setPageCount] = useState(1);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffSet, setitemOffSet] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [list, setList] = useState([]);
  const [param, setParam] = useState("");
  const [sQuery, setSQuery] = useState("");
  const navigate = useNavigate();
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setParam(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const filterSearch = useCallback(
    async (e) => {
      console.log(e.target.value);
      console.log("working");

      let arr = [];
      const queryRegex = new RegExp(e.target.value, "i");
      allData.forEach((item) => {
        Object.values(item).some((val) =>
          queryRegex.test(val) ? arr.push(item) : ""
        );
      });

      setList(arr);
      setPageCount(0);
    },
    [allData]
  );

  const resetData = () => {
    setCurrentPage(1);
    console.log("reset data working");
    paginateData(allData, setList, 1, itemOffSet, setitemOffSet, setPageCount);
  };

  useEffect(() => {
    console.log(`${currentPage} has changed`);
    paginateData(
      allData,
      setList,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allData, currentPage, itemOffSet]);

  return (
    <>
      <TabContainer
        style={{
          marginTop: "0px",
          borderRadius: "0px 0px 20px 20px",
          borderWidth: "0px 1px 1px 1px",

          // border: "none",
          paddingBottom: "0px",
        }}
      >
        <TabLinkContainer
          bg="#F0E8F1"
          style={{
            position: "relative",
            borderRadius: "0px",
            paddingBottom: "0px",
          }}
        >
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search
              value={sQuery}
              type="type"
              placeholder="Search For Student"
              onChange={(e) => {
                // console.log(e.target.value);
                setSQuery(e.target.value);
                if (e.target.value.length > 2) {
                  filterSearch(e);
                }

                if (e.target.value === "") {
                  resetData();
                  console.log("hellow world inside jsx");
                }
                // filterSearch(e);
              }}
            />
          </Input>
          <div style={{ display: "none" }} className="div">
            <AiFillFileExcel
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
            <SiAdobeacrobatreader
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
            <BsFillPrinterFill
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
          </div>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table
            style={{
              borderRadius: "0px",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <tr
              className="table-head"
              style={{ backgroundColor: "#F0E8F1", color: "black" }}
            >
              <th>S/N</th>

              <th>
                <p style={{ width: "max-content" }}>Admission No</p>
              </th>
              <th>
                <p style={{ width: "max-content" }}>Student Name</p>
              </th>
              <th>
                <p style={{ width: "max-content" }}>Class</p>
              </th>
              <th>Phone</th>
              <th>
                <p style={{ width: "max-content" }}>Email Address</p>
              </th>
              <th>Status</th>
              <th>
                <p style={{ width: "max-content" }}>Created By</p>
              </th>
              <th></th>
            </tr>
            {list.length > 0 ? (
              list.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>

                  <td>{item.admission_no}</td>
                  <td>
                    <ImgTextDiv>
                      <img
                        src={
                          item.profileImagePath !== null
                            ? item.profileImagePath
                            : Avatar
                        }
                        alt=""
                      />
                      <span style={{ width: "max-content" }}>
                        {`${item.firstName} ${item.lastName}`}{" "}
                      </span>
                    </ImgTextDiv>
                  </td>
                  <td>{item.className}</td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.email}</td>
                  <td>
                    <StatusBar status={item.accountStatus}>
                      {item.accountStatus === "8"
                        ? "Admitted"
                        : item.accountStatus === "7"
                        ? "Shortlisted"
                        : item.accountStatus === "6"
                        ? "Declined"
                        : item.accountStatus === "5"
                        ? "In Process"
                        : item.accountStatus === "4"
                        ? "Cancelled"
                        : item.accountStatus === "3"
                        ? "Submitted"
                        : item.accountStatus === "2"
                        ? "Saved"
                        : "Not Confirmed"}
                    </StatusBar>
                  </td>
                  <td>{item.createdBy}</td>
                  <td>
                    <IconButton
                      onClick={(e) =>
                        item.phoneNumber === null || item.phoneNumber === ""
                          ? toast.error(
                              "Sorry Student's Phone number and email has not been updated.Please consult your school administrator!"
                            )
                          : handleClick(e, item.phoneNumber)
                      }
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} style={{ textAlign: "center" }}>
                  No {type} Student Available
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>

      {pageCount > 1 && (
        <div
          style={{
            marginTop: "2px",
            marginBottom: "2px",
          }}
        >
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            currentPage={currentPage - 1}
            range={3}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem
              onClick={() => {
                dispatch(storePrev("/studentmgt?tab=2"));

                navigate(`/student?school_id=${schoolId}&student_id=${param}`);
              }}
            >
              Dashboard
            </MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </>
  );
};

LinkedUnLinkedStudent.propTypes = {
  allData: PropTypes.array,
  type: PropTypes.string,
};

LinkedUnLinkedStudent.defaultProps = {
  allData: [],
  type: "",
};

export default LinkedUnLinkedStudent;
