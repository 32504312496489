import styled from "styled-components";
import colors from "../../../assets/colors.json";

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  font-family: "Gilroy-Regular";
  padding-bottom: 3rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const SmallHeader = styled.p`
  color: #000000;
  font-size: 18px;
  font-family: "Gilroy-Regular";
`;
export const BigHeader = styled.p`
  color: ${colors.black};
  font-size: ${(props) => (props.fs ? props.fs : "36px")};
  /* margin-top: 8px; */
  font-family: "Gilroy-Bold";
  @media (max-width: 768px) {
    font-size: ${(props) => (props.fs ? props.fs : "30px")};
  }
`;
export const TabContainer = styled.div`
  width: ${(props) => (props.wd ? props.wd : "100%")};
  margin-top: 10px;
  border: 1px solid ${colors.primary};
  border-radius: 20px;
  padding-bottom: 20px;
`;
export const TabLinkContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => (props.bg ? props.bg : colors.lightGray)};

  width: ${(props) => (props.wd ? props.wd : "100%")};
  /* opacity: 0.6; */
  padding: 8px 18px;
  border-radius: ${(props) => (props.brtl ? props.brtl : "20px")}
    ${(props) => (props.brtr ? props.brtr : "20px")}
    ${(props) => (props.brbl ? props.brbl : "0px")}
    ${(props) => (props.brbr ? props.brbr : "0px")};
`;
export const TabLink = styled.div`
  margin-top: 0.3rem;
  cursor: pointer;
  min-width: ${(props) => (props.minWidth ? props.minWidth : "20%")};
  font-family: "Gilroy-Bold";
  /* color: ${(props) => (props.color ? props.color : "rgba(0, 0, 0, 0.1)")}; */
  @media (max-width: 992px) {
  }
  /*  */
  @media (max-width: 768px) {
    width: ${(props) => (props.mwWidth ? props.mwWidth : "48%")};
    margin-top: 10px;
  }

  @media (max-width: 576px) {
    width: ${(props) => (props.mwWidth ? props.mwWidth : "100%")};
  }
  text-align: center;
  padding: 12px;
  background: ${(props) => (props.isActive ? "white" : "")};
  border-radius: 10px;
  /* fontfamily: "Gilroy-Regular"; */

  border: ${(props) =>
    props.isActive
      ? `1px solid rgba(46, 65, 56, 0.24)`
      : `0px solid white`} !important;

  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  &:hover {
    color: #000000 !important;
    border-radius: 10px;
    font-size: 14px;
  }
  &:hover > p {
    opacity: 1 !important;
    color: #000000 !important;
  }

  & > p {
    /* color: rgba(0, 0, 0, 0.1) !important; */
    opacity: ${(props) => (props.isActive ? 0.8 : 0.6)} !important;
    font-weight: ${(props) => (props.isActive ? "bold" : "600")} !important;
  }
`;
export const TabContent = styled.div``;
export const FormFlex = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: ${(props) => (props.reverse ? "column-reverse" : "column")};
  }
`;
export const FormFlexDiv = styled.div`
  width: ${(props) => props.width || "50%"};
  padding: 4px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const DateInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid rgb(103, 30, 120);
  border-radius: 4px;

  & > .react-datepicker-ignore-onclickoutside {
    color: white !important;
  }
  & > .react-datepicker-wrapper {
    & > .react-datepicker__input-container {
      & > input {
        border-width: 1px !important;
        border-style: solid !important;
        margin-top: 0px !important;
        color: ${(props) => props.color || ""} !important;
        background: ${(props) => props.bg || ""} !important;
        border-color: ${(props) => props.color || ""} !important;
      }
    }
    width: 100%;
    & > select:focus {
      outline: red;
    }
  }

  & > .react-datepicker-wrapper {
    & > .react-datepicker__input-container {
      & > input::placeholder {
        color: ${(props) => props.color || ""} !important;
      }
    }
  }
`;
export const DateIconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  i,
  svg {
    font-size: 30px;
    color: ${colors.gray};
  }
  svg > path {
    stroke: ${colors.gray};
  }

  background: ${colors.secondary};
  border: ${(props) =>
    props.useBorder ? " 1px solid white" : "none"} !important;
  /* text-align: center;
  margin-top: 5px;
  line-height: 2.335rem;
  margin-left: -5px; */
  z-index: 99;
  padding: 5.5px;
  border-radius: 4px;
`;
export const DatePickerButton = styled.button`
  height: 30px;
  width: 30px;
  margin-right: 7px;
  background: ${colors.primary};
  border: none;
`;
export const DatePickerSelect = styled.select`
  outline: none !important;
  border-color: none !important;
  &:focus {
    border-color: ${colors.lightGray};
  }

  &:active {
    border-color: ${colors.lightGray};
  }

  &:focus-visible {
    border-color: ${colors.lightGray};
  }
`;
export const ImageUploadContainer = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 15px;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
export const ImageWrapper = styled.div`
  width: 230px;
  border-radius: 20px;
  padding-bottom: 10px;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: ${colors.secondary};
  & > img {
    width: 100%;
    height: 200px;
    border-radius: 20px 20px 0px 0px;
  }

  & > div {
    margin-top: 10px;
    height: 30px;
    width: fit-content;
    border-radius: 20px;
    padding: 5px 9px;
  }
`;
export const AttachmentFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  margin-top: 5px;
  @media (max-width: 992px) {
    padding: 3px 10px;
  }
`;
export const AttachmentUploadContainer = styled.div`
  & > img {
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* z-index: -23; */
    /* z-index: ${(props) => props.zin || -23}; */
    z-index: ${(props) => (props.zin ? props.zin : "-23")};
    border-radius: 20px;
    opacity: 0.7;
  }

  position: relative;
  /* background: ${(props) => props.bg || colors.lightGray}; */
  cursor: pointer;
  /* width: 25%; */
  width: ${(props) => props.width || "25%"};
  min-height: 20vh;
  /* background: ${colors.lightGray}; */
  color: ${colors.gray};
  border: 1px solid ${colors.lightGray};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 992px) {
    /* width: 50%; */
    width: ${(props) => props.width || "50%"};
  }
`;
export const InlineFormDiv = styled.div`
  width: ${(props) => props.width || "80%"};
  display: flex;
  align-items: ${(props) => props.ai || "end"};
  justify-content: ${(props) => props.jc || "space-around"};

  @media (max-width: 992px) {
    flex-direction: column;
    width: ${(props) => props.maxwidth || "90%"};
  }
`;
export const AccountSummaryWrapper = styled.div`
  padding-top: ${(props) => props.pt || "20px"};
  width: 100%;
  overflow-x: auto;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;
export const ProfileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  width: 100%;
  cursor: pointer;
  background: ${colors.primary};
  color: white;
  border-radius: 20px;
`;
export const TabContentWrapper = styled.div`
  padding: 0.8rem 1.2rem;
  @media (max-width: 768px) {
    padding: 0.4rem 0.5rem;
  }
`;
export const WrapperLeft = styled.div`
  font-weight: bold;
  width: auto;
  font-size: 15px;
  padding: 0px 0.3rem;
`;
export const WrapperCenter = styled.div`
  flex-grow: 1;
  height: 0.21vh;
  background: ${colors.lightGray};
`;
export const WrapperRight = styled(WrapperLeft)`
  text-align: right;
`;
export const InlineLabelGroup = styled.div`
  display: flex;
  align-items: center;
  /* background: red; */
  @media (max-width: 768px) {
    flex-direction: column;
    & > div {
      width: 100%;
    }
  }
`;
export const InlineLabelWrapper = styled.div`
  flex: ${(props) => props.flex};
  padding: 1px 4px;
`;
export const PageWrapper = styled.div`
  width: ${(props) => props.width || "85%"};
  /* background: red; */
  margin: 0 auto;
  padding: 5px 10px;
  @media (max-width: 992px) {
    width: 100%;
  }
`;
export const AccordionText = styled.p`
  font-family: "Gilroy-Bold";
  font-size: 18px;
  color: #2e4138;
`;

export const Input = styled.div`
  position: relative;
  @media (max-width: 768px) {
    width: ${(props) => props.mwidth || "80%"};
  }
  width: ${(props) => props.width || "60%"};
`;

export const Search = styled.input`
  background: #f6f4f5b3;

  border: none;
  padding: 10px 50px;
  width: 100%;
  height: 47px;
  border-radius: ${(props) => props.br || "100px"};
  border: 1px solid ${colors.primary};
  ::placeholder {
    font-family: "Gilroy-Regular";
    font-size: 16px;
    color: #000000;
  }
`;
export const Icon = styled.span`
  position: absolute;
  left: 23px;
  top: ${(props) => props.tp || "20px"};
`;

// chat

export const ChatDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ProfileImgDiv = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.bg || "rgba(83, 156, 8, 0.1)"} !important;
  /* opacity: 0.1; */
  padding: 0.8rem 0.5rem;
  /* border-right: 4px solid ${colors.primary}; */
  border-right: ${(props) =>
    props.hideBorder === true ? "none" : `4px solid ${colors.primary}`};
  & > p,
  & > div {
    font-size: 16px;
    margin-left: 10px;
    font-weight: bold;
  }
`;

export const ImageDiv = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  & > span {
    height: 12px;
    background: green;
    width: 12px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 1px;
  }
  & > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

export const ChatBoxDiv = styled.div`
  padding: 1rem;
  background: ${(props) => props.bg || "#d9d9d9"} !important;
  border-radius: 24px;
  min-height: 40vh;
  max-height: 45vh;
  overflow-y: overlay;

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #c3c2c2;
  }

  ::-webkit-scrollbar {
    width: 3px;
    height: 7px;
  }
`;

export const ChatBox = styled.div`
  display: flex;
  justify-content: ${(props) => (props.msgType === "inbox" ? "start" : "end")};
  & > div {
    position: relative;
    max-width: 60%;
    @media (max-width: 768px) {
      max-width: 90%;
    }
    padding: 0.4rem 1.9rem 0.4rem 0.9rem;
    background: ${(props) =>
      props.msgType === "inbox" ? colors.primary : props.bg || "white"};
    color: ${(props) =>
      props.msgType === "inbox" ? "white" : props.color || colors.primary};
    font-size: 14px;
    line-height: 17px;
    border-radius: ${(props) =>
      props.msgType === "inbox" ? "0px 30px 30px 30px " : "30px 0px 30px 30px"};
    & > span {
      position: absolute;
      right: 6px;
      top: 2px;
    }
  }
  margin-top: 0.5rem;
`;

export const SignatureBox = styled.div`
  /* background: red; */
  margin-top: 4rem;
  width: 100%;
  & > div {
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 2px;
  }

  & > p {
    margin-top: 5px;
    text-align: center;
    font-family: "Gilroy-Bold";
    font-size: "16px";
    color: #2e4138;
  }
`;

export const LedgerContainer = styled.div`
  min-height: 50vh;
  /* background: red; */
  margin-top: 0.5rem;
  border-radius: 20px;
  border: 1px solid ${colors.primary};
`;

export const LedgerHeader = styled.div`
  background: ${colors.primary};
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  & > div {
    width: fit-content;
    /* background: green; */
    margin: 0 auto;
    padding: 1rem;
    /* background: green; */
    @media (max-width: 768px) {
      /* flex-direction: column; */
      padding: 0.3rem;
      padding-top: 0.5rem;
    }
  }
`;

export const LedgerSchoolName = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  & > div {
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding: 6px;
    /* background: green; */
    @media (max-width: 768px) {
      /* flex-direction: column; */
      padding: 5px;
    }
    & > img {
      width: 60px;
      height: 47px;
      /* background: green; */
      @media (max-width: 768px) {
        width: 44px;
        height: 36px;
      }
    }
  }

  & > span {
    font-size: 30px;
    color: white;
    text-align: center;
    margin-left: 0.5rem;
    /* background: green; */
    @media (max-width: 768px) {
      /* flex-direction: column; */
      margin-left: 0.2rem;
      font-size: 15px;
    }

    font-weight: bold;
    font-family: "Gilroy-Bold";
  }
`;

export const LedgerSchoolInfo = styled.div`
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 0.25rem;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
  & > div {
    width: 48%;
    /* background: red; */
    padding: 0.3rem;
    @media (max-width: 768px) {
      /* flex-direction: column; */
      width: 100%;
    }
  }
`;

export const LedgerSchoolHead = styled.div`
  display: inline-flex;
  justify-content: ${(props) => props.justifyContent || "start"};
  align-items: center;
  color: white;
  width: 100%;
  font-size: 15px;
  /* span {
    font-size: 1px;
  } */

  & > div:nth-child(odd) {
    margin-right: 5px;
  }

  & > div {
    height: fit-content !important;
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const LedgerStudentHeader = styled.div`
  padding: 0.8rem;
  border-radius: 20px;
  & > div {
    display: flex;
    border-radius: 20px;
    background: ${colors.secondary};
    border: 1px solid ${colors.primary};
    height: fit-content;
    width: 100%;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const LedgerStudentDiv = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  &:nth-child(even) {
    & > div {
      border-left: 1px solid ${colors.lightGray};
      padding-left: 0.4rem;
      @media (max-width: 768px) {
        border-left: none !important;
        padding-left: 0rem !important;
      }
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0.3rem;
  }

  img {
    height: 100%;
    width: 160px;
    border-radius: 20px;
    max-width: -webkit-fill-available;
  }
`;

export const LedgerInfoDetails = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
`;

export const LedgerFlexInfo = styled.div`
  flex: ${(props) => props.flex};

  p {
    font-size: 18px;
    font-weight: bold;

    @media (max-width: 768px) {
      font-size: 15px;
    }
  }

  span {
    font-size: 13px;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`;

export const LedgerTable = styled.div`
  padding: 0.8rem;
  width: 100%;
`;

export const ButtonFlex = styled.div`
  display: flex;
  justify-content: space-around;

  button,
  a {
    padding: 6px 11px;
    border-radius: 20px;
    background: white;

    border: 2px solid ${colors.primary};
    outline: none;
    text-decoration: none;

    color: ${colors.primary};
  }
`;

export const ControlPanelNavTab = styled.div`
  font-family: "Gilroy-Bold";
  & a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    img {
      height: 50px;
      width: 50px;
    }
    i,
    svg {
      font-size: 38px;
      color: black;
    }
    svg > path {
      stroke: black;
    }
    span {
      margin-left: 9px;
      color: black;
    }
  }
`;

export const ControlPanelDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  margin-top: 2rem;
`;
export const ControlPanelTab = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  min-width: ${(props) => (props.minWidth ? props.minWidth : "30%")};
  @media (max-width: 768px) {
    width: 48%;
    margin-top: 10px;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
  & a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    img {
      height: 50px;
      width: 50px;
    }
    i,
    svg {
      font-size: 40px;
    }
    svg > path {
      stroke: ${colors.primary};
    }
    span {
      margin-left: 9px;
      color: black;
    }
  }

  font-family: "Gilroy-Bold";
`;

export const ControlPanelLink = styled.div`
  background: ${(props) =>
    props.isActive ? "rgba(255, 255, 255, 0.5)" : "transparent"};
  font-family: "Gilroy-Bold";
  padding: ${(props) => (props.isActive ? "0.6rem" : "0.4rem")};
  font-weight: ${(props) => (props.isActive ? "600" : "500")};
  border-radius: 5px;
  cursor: pointer;
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
  width: 100%;
  display: inline-flex;
  align-items: center;
  i,
  svg {
    font-size: 25px;
    color: black;
  }
  svg > path {
    stroke: black;
  }
  span {
    color: black;
    margin-left: 0.5rem;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.7);
    padding: 0.6rem;
  }
`;
export const SSLink = styled.div`
  background: ${(props) =>
    props.isActive ? "rgba(255, 255, 255, 0.5)" : "transparent"};
  font-family: "Gilroy-Bold";
  padding: ${(props) => (props.isActive ? "0.6rem" : "0.4rem")};
  border-radius: 3px;
  border-left: ${(props) =>
    props.isActive ? "2px solid rgba(255, 255, 255, 1)" : ""};
  cursor: pointer;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  width: 90%;
  display: inline-flex;
  align-items: center;
  color: ${(props) =>
    props.isActive ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.7)"};
  i,
  svg {
    font-size: 25px;
    color: ${(props) =>
      props.isActive ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.7)"};
  }
  svg > path {
    stroke: ${(props) =>
      props.isActive ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.7)"};
  }
  span {
    color: ${(props) =>
      props.isActive ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.7)"};
    margin-left: 0.5rem;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.2);
    padding: 0.6rem;
  }
`;

export const LogoImgSetup = styled.div`
  display: inline-flex;
  margin-top: 10px;
  align-items: start;
  img {
    border: 2px solid ${colors.primary};
    width: 85px;
    height: 85px;
    border-radius: 5px;
    @media (max-width: 768px) {
      width: 65px;
      height: 65px;
    }
  }
  span {
    border-radius: 50%;
    color: white;
    padding: 0.18rem 0.25rem;
    font-weight: semibold;
    background: red;
    margin-left: 4px;
  }
`;

export const SlidingImg = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  justify-content: space-between;
  background: ${colors.secondary};
  margin: 0.4rem 0rem;
  padding: 0.4rem 0.7rem;

  img {
    width: 97px;
    height: 90px;
    border-radius: 5px;
  }
  span {
    margin-left: 5px;
    font-weight: bold;
  }

  div.desc {
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* color: red; */
  }

  div.desc p {
    font-size: 12px;
    -webkit-box-direction: normal;
  }

  @media (max-width: 768px) {
    span:nth-child(even) {
      display: none;
    }
    img {
      width: 69px;
      height: 51px;
    }
    font-size: 13px;
  }

  & > div {
    display: flex;
    align-items: center;
  }
  button {
    background-color: transparent;
    border-radius: 5px;
    border-color: ${colors.primary};
    font-weight: bold;
    outline: none;
    box-shadow: none;
    padding: 0.4rem 0.8rem;
    border-width: 1px;
    @media (max-width: 768px) {
      padding: 0.2rem 0.5rem;
    }
  }
  button:nth-child(odd) {
    margin-right: 5px;
  }
`;

export const SubMenuDiv = styled.div`
  padding: 0.7rem 0.9rem;
  margin: 0.1rem 0rem;
  display: inline-flex;
  justify-content: ${(props) => props.jc || "space-between"};
  position: relative;
  align-items: center;

  & > div {
    position: absolute;
    top: 50%;
    left: 2px;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: ${colors.primary};
  }
`;

export const PaySlipHeader = styled.div`
  background: ${colors.primary};
  display: flex;
  border-radius: 10px 10px 0px 0px;
  align-items: center;
  /* justify-content: space-around; */
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0.8rem 0.7rem;
  }
  padding: 1.8rem 1.7rem;
`;

export const PaySlipDiv = styled.div`
  flex: ${(props) => props.flex || 1};
  padding: 0.3rem 0.7rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const PaySlipImgText = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: start;
  @media (max-width: 768px) {
    justify-content: center;
  }

  & > div {
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding: 6px;
    /* background: green; */
    @media (max-width: 768px) {
      /* flex-direction: column; */
      padding: 5px;
    }
    & > img {
      width: 45px;
      height: 37px;
      /* background: green; */
      @media (max-width: 768px) {
        width: 40px;
        height: 30px;
      }
    }
  }

  & > span {
    font-size: 12px;
    color: white;
    text-align: left;
    margin-left: 0.5rem;
    /* background: green; */
    @media (max-width: 768px) {
      /* flex-direction: column; */
      margin-left: 0.2rem;
      font-size: 11px;
    }
    font-weight: bold;
    font-family: "Gilroy-Bold";
  }
`;

export const PaySlipContentHeader = styled.div`
  background: ${colors.primary};
  font-size: 16px;
  font-weight: bold;
`;

export const PaySlipContentDiv = styled.div`
  background: transparent;
  &:nth-child(even) {
    background-color: ${colors.secondary};
  }
`;
// export const

export const MenuDiv = styled.div`
  /* background: red; */
  /* padding: 1rem;
  background: ${(props) => props.bg || "#d9d9d9"} !important;
  border-radius: 24px; */
  min-height: ${(props) => props.minH || "40vh"};
  max-height: 45vh;
  overflow-y: overlay;

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${colors.primary};
    width: 5px;
    height: 6px;
  }

  ::-webkit-scrollbar {
    display: block !important;
    width: 6px;
    height: 7px;
    background-color: white;
  }
`;
