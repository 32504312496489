import React from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { AcademicData } from "../../assets/data/ControlPanelData";
import { Box } from "@mui/system";
import CPNavbar from "../../components/CPLayouts/CPNavBar";
import { DashboardHeader } from "../../components/Dashboard";
import ResultSummary from "../../components/ControlPanel/Academic/ResultSummary";
import Class from "../../components/ControlPanel/Academic/Class";
import StudentSetup from "../../components/ControlPanel/Academic/StudentSetup";
import { useSelector } from "react-redux";
import { checkAccess } from "../../services/utils";
import Subject from "../../components/ControlPanel/Academic/Subject";
import Result from "../../components/ControlPanel/Academic/Result";
import AttendanceSetup from "../../components/ControlPanel/Academic/AttendanceSetup";
const AcademicSetup = () => {
  const location = useLocation();
  const { accessObj } = useSelector((state) => state.auth);
  const page_id = Number(new URLSearchParams(location.search).get("page")) || 0;
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CPNavbar
        controlNav={() => {
          if (open === true) {
            setOpen(false);
          }
        }}
        isOpen={open}
        title="Academic Setup"
        navData={AcademicData}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { lg: "90%", md: "90%", sm: "100%", xs: "100%" },
        }}
      >
        <DashboardHeader
          // setView={setView}
          // view={view}
          title="Academic Setup"
          navData={AcademicData}
        />
        <Box mt={13} sx={{ mx: "auto", width: isMobile ? "90%" : "85%" }}>
          {checkAccess(accessObj.menuItems, "Academic Setup") &&
            checkAccess(accessObj.menuItems, "Result") &&
            page_id === 1 && <Result />}
          {checkAccess(accessObj.menuItems, "Academic Setup") &&
            checkAccess(accessObj.menuItems, "Class") &&
            page_id === 2 && <Class />}
          {checkAccess(accessObj.menuItems, "Academic Setup") &&
            checkAccess(accessObj.menuItems, "Student") &&
            page_id === 3 && <StudentSetup />}

          {checkAccess(accessObj.menuItems, "Academic Setup") &&
            checkAccess(accessObj.menuItems, "Subjects") &&
            page_id === 4 && <Subject />}

          {checkAccess(accessObj.menuItems, "Academic Setup") &&
            checkAccess(accessObj.menuItems, "Attendance") &&
            page_id === 5 && <AttendanceSetup />}
        </Box>
      </Box>
    </Box>
  );
};

export default AcademicSetup;
