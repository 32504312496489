import {
  Avatar,
  Badge,
  Button,
  Chip,
  createTheme,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import stu1 from "../../assets/Images/avatar.jpg";
// import stu2 from "../../assets/Images/img-student-7.png";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ClassIcon from "@mui/icons-material/Class";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

const theme = createTheme({
  palette: {
    primary: {
      main: "#671e78",
    },
  },
});
const studentData = [
  {
    name: "Adeolu James",
    age: 12,
    school: "Bishop Howells Memorial Grammer School",
    class: "Jss2",
    img: stu1,
  },
  {
    name: "Adeola Thomas",
    age: 12,
    school: "Bishop Howells Memorial Grammer School",
    class: "Jss3",
    img: stu1,
  },
  {
    name: "Adeola Thomas",
    age: 12,
    school: "Bishop Howells Memorial Grammer School",
    class: "Jss3",
    img: stu1,
  },
  {
    name: "Adeola Thomas",
    age: 12,
    school: "Bishop Howells Memorial Grammer School",
    class: "Jss3",
    img: stu1,
  },
  {
    name: "Adeolu James",
    age: 12,
    school: "Bishop Howells Memorial Grammer School",
    class: "Jss2",
    img: stu1,
  },
  {
    name: "Adeolu James",
    age: 12,
    school: "Bishop Howells Memorial Grammer School",
    class: "Jss2",
    img: stu1,
  },
  {
    name: "Adeola Thomas",
    age: 12,
    school: "Bishop Howells Memorial Grammer School",
    class: "Jss3",
    img: stu1,
  },
];

const Student = () => {
  const age = 12;
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box>
          <Typography
            variant="body"
            sx={{ fontFamily: "Gilroy-Medium", fontSize: "26px" }}
          >
            <span style={{ opacity: 0.3, fontFamily: "Gilroy-Regular" }}>
              Parent Management &#62;&#62;
            </span>{" "}
            Parent Dashboard - Mr James Olu(2) &#62;&#62; Student
          </Typography>
        </Box>
        <Box mt={5} px={1}>
          <Grid container spacing={2}>
            {studentData.map((item, index) => (
              <Grid item lg={2} xs={6} key={index}>
                <Box
                  sx={{
                    border: "1px solid #671e78 ",
                    borderRadius: "20px",
                    p: 0.7,
                    pb: 2,
                  }}
                >
                  <Avatar
                    src={item.img}
                    variant="rounded"
                    sx={{
                      width: "100%",
                      height: "130px",
                      borderRadius: "15px",
                    }}
                  />
                  <Box mt={1}>
                    <Typography
                      variant="h6"
                      sx={{ fontFamily: "Gilroy-Bold", fontSize: "15px" }}
                    >
                      {item.name}
                    </Typography>
                    <Chip
                      label={`Aged: ${item.age}`}
                      sx={{
                        fontSize: "9px",
                        height: "16px",
                        bgcolor: "#5e9c091a",
                        color: "#5e9c09",
                      }}
                    />
                    <List>
                      <ListItem sx={{ px: 0, py: 0.5 }}>
                        <ListItemIcon
                          sx={{ color: "black", minWidth: 0, mr: 0.5 }}
                        >
                          <LocationCityIcon sx={{ fontSize: "15px" }} />
                        </ListItemIcon>
                        <Typography
                          variant="caption"
                          sx={{ lineHeight: "12px", fontSize: "10px" }}
                        >
                          {item.school}
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ px: 0, py: 0.5 }}>
                        <ListItemIcon
                          sx={{ color: "black", minWidth: 0, mr: 0.5 }}
                        >
                          <ClassIcon sx={{ fontSize: "15px" }} />
                        </ListItemIcon>
                        <Typography
                          variant="caption"
                          sx={{ lineHeight: "12px", fontSize: "10px" }}
                        >
                          {item.class}
                        </Typography>
                      </ListItem>
                    </List>
                    <Button
                      mt={1}
                      variant="contained"
                      fullWidth
                      sx={{
                        fontSize: "13px",
                        fontFamily: "Gilroy-Medium",
                        textTransform: "initial",
                      }}
                    >
                      <LinkOffIcon sx={{ mr: 1, fontSize: "16px" }} /> Unlink
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}

            <Grid item lg={3}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Button sx={{ textTransform: "initial" }}>
                  <ControlPointIcon />
                  Add more Student
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Student;
