import axios from "axios";

const adtoken = localStorage.getItem("admToken");
// const endpoint = process.env.REACT_APP_API_ENDPOINT_ONE;
const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
const api_version = process.env.REACT_APP_API_VERSION;
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${adtoken}`,
};
const api = {
  getAssignment: async (schoolId) => {
    const config = {
      headers: headers,
    };

    return axios.get(
      `${endpoint_two}/api/v${api_version}/Assignment/AllAssignments?schoolId=${schoolId}&?studentId=1`,
      config
    );
  },
  deleteAssignment: async (id) => {
    return axios.delete(
      `${endpoint_two}/api/v${api_version}/Assignment/DeleteAssignment?Id=${id}`,
      { headers }
    );
  },
  deleteQuestion: async (id) => {
    return axios.delete(
      `${endpoint_two}/api/v${api_version}/Assignment/DeleteQuestionAssignment?Id=${id}`,
      { headers }
    );
  },
  getSubject: async () => {
    return axios.get(
      `${endpoint_two}/api/v${api_version}/Subject/GetAllSubjects`,
      { headers }
    );
  },
};

export default api;
