import { useState, useCallback, useEffect } from "react";
import {
  TabContainer,
  AccountSummaryWrapper,
  InlineLabelGroup,
  InlineLabelWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
import {
  getSessions,
  getPromotionStudents,
  promoteStudent,
  getAllClasses,
} from "../../services/Subject";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import Avatar from "../../assets/Images/avatar.jpg";
import { ImgTextDiv } from "../../pages/student_mgt/stu_mgt.style";
import colors from "../../assets/colors.json";
import { InputWrapper, AuthButton } from "../../pages/auth/Login.style";
import Select from "../select/select";
import ErrorService from "../../services/errorService";
import { useSelector } from "react-redux";
import FormLoader from "../../components/FormLoader";
import { toast } from "react-toastify";
import { checkAccess } from "../../services/utils";
const Promotion = () => {
  const [loading, setLoading] = useState(false);
  const [marked, setMarked] = useState([]);
  const [formLoading, setFormLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [showForm, setShowForm] = useState(true);
  const [allData, setAllData] = useState([]);
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [loadClass, setLoadClass] = useState(false);
  const [classes, setClasses] = useState([]);
  const [classObj, setClassObj] = useState({
    id: "",
    name: "",
  });
  const [session, setSession] = useState({
    id: "",
    name: "",
  });
  const schoolSessions = useCallback(async () => {
    try {
      const res = await getSessions(admToken);

      res.status && res.statusCode === 2 && setSessions(res.data);
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);
  const getClasses = useCallback(
    async (id) => {
      try {
        setLoadClass(true);
        const res = await getAllClasses(admToken, user.schoolId);

        res.status && res.statusCode === 2 && setClasses(res.data);
        (res.status === 500 || res.status === 400 || !res.status) &&
          ErrorService.displayErrorAlert(res);
        setLoadClass(false);
      } catch (error) {
        console.log(error);
        setLoadClass(false);
      }
    },

    [admToken, user]
  );

  useEffect(() => {
    // schoolTerms();
    schoolSessions();
    getClasses();
  }, [schoolSessions, getClasses]);
  const getStudents = useCallback(async () => {
    try {
      // console.log(user.schoolId, classObj.id, session.id);
      setLoading(true);
      const res = await getPromotionStudents(
        admToken,
        user.schoolId,
        classObj.id,
        session.id
      );
      // console.log(res);
      if (res.status && res.statusCode === 2) {
        setAllData(res.data);
      }
      (res.status === 500 || res.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [admToken, classObj.id, session.id, user.schoolId]);
  const toggleMarked = (id) => {
    // console.log(markedItem);
    let item = marked.find((i) => i.studentId === id);

    if (item) {
      setMarked(marked.filter((i) => i.studentId !== id));
    } else {
      let obj = {
        schoolId: user.schoolId,
        studentId: id,
      };
      // console.log(obj);
      setMarked([...marked, obj]);
    }
    // console.log(item);
  };
  const markAll = () => {
    if (marked.length === allData.length) {
      setMarked([]);
    } else {
      const newList = allData.map((item) => {
        return {
          schoolId: user.schoolId,
          studentId: item.studentId,
        };
      });
      setMarked(newList);
    }
  };

  const performAction = useCallback(async () => {
    try {
      setFormLoading(true);
      // console.log(marked);
      let data = {
        classId: Number(classObj.id),
        sessionId: Number(session.id),
        staffId: 0,
        promotedStudents: marked,
      };

      const res = await promoteStudent(admToken, data);
      // console.log(res);

      if (res.status && res.statusCode === 2) {
        toast.success(res.message);
        getStudents();
        setMarked([]);
      }
      (res.status === 500 || res.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res);
      setFormLoading(false);
    } catch (error) {
      console.log(error);
      setFormLoading(false);
      toast.error("Please Try Again Later");
    }
  }, [admToken, classObj.id, getStudents, marked, session.id]);
  return (
    <div>
      <div
        onClick={() => setShowForm(!showForm)}
        style={{
          backgroundColor: colors.primary,
          borderRadius: "10px",
          marginTop: "0.3rem",
          padding: "0.4rem",
          display: "flex",
          justifyContent: "space-between",
          color: "white",
          fontWeight: "bold",
        }}
      >
        <div>
          <span>
            {session.name} {session.name.length > 0 ? "-" : ""}
            {classObj.name}
          </span>
        </div>
        {showForm ? (
          <BiSolidDownArrow color="white" />
        ) : (
          <BiSolidUpArrow color="white" />
        )}
      </div>
      {showForm && (
        <>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={2}>
              <InlineLabelGroup>
                <InlineLabelWrapper flex={1}>
                  {" "}
                  <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                    <Select
                      title="Session"
                      index="session" // no
                      selected={session.id} // false
                      options={sessions.filter((i) => i.isActive)}
                      setSelected={(val) => {
                        setSession({
                          ...session,
                          id: val.id,
                          name: val.session,
                        });
                        // getClasses(val.id);
                        setAllData([]);
                      }}
                      // color="white"
                    />
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                    <Select
                      title={loadClass ? "Loading" : "Class"}
                      index="className" // no
                      selected={classObj.id} // false
                      options={classes}
                      thirdOption="classId"
                      // extraTitle="classArm"
                      setSelected={(val) => {
                        // console.log(val);
                        setAllData([]);
                        setClassObj({
                          ...classObj,
                          id: val.classId,
                          name: `${val.className} ${val.classArm}`,
                        });
                        // getStudents(val.classId);
                      }}
                      // color="white"
                    />
                  </InputWrapper>
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </InlineLabelWrapper>
          </InlineLabelGroup>

          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              <AuthButton
                width="100%"
                mt="0.4rem"
                onClick={() => getStudents()}
                disabled={loading || classObj.id === "" || session.id === ""}
              >
                <div style={{ textAlign: "center" }}>
                  {loading ? (
                    <FormLoader color={colors.primary} />
                  ) : (
                    "Load Student"
                  )}
                </div>
              </AuthButton>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </>
      )}

      <TabContainer style={{ borderRadius: "20px", paddingBottom: "0px" }}>
        <AccountSummaryWrapper pt="0px">
          <table style={{ borderRadius: "20px" }}>
            <tr
              className="table-head"
              style={{ backgroundColor: colors.primary, color: "white" }}
            >
              <th>
                <input
                  type="checkbox"
                  onChange={markAll}
                  checked={marked.length === allData.length}
                  style={{ borderColor: "white", background: colors.primary }}
                />
              </th>
              <th>Students ({allData.length}) </th>
              <th>Cummulative Total</th>
              <th>Average Score</th>
              <th>Position</th>
            </tr>
            {allData.length > 0 ? (
              allData.map((item, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={marked.some(
                        (i) => i.studentId === item.studentId
                      )}
                      onChange={() => toggleMarked(item.studentId)}
                    />
                  </td>
                  <td>
                    <ImgTextDiv>
                      <span
                        style={{
                          marginRight: "0.6rem",
                          fontSize: "1.3rem",
                        }}
                      >
                        {index + 1}.
                      </span>
                      {item?.profileImagePath && (
                        <img
                          src={
                            item.profileImagePath === "" ||
                            item.profileImagePath === null
                              ? Avatar
                              : item.profileImagePath
                          }
                          alt=""
                        />
                      )}

                      <div>
                        <span>{item.fullName} </span>
                        <span style={{ fontSize: "12px", display: "block" }}>
                          {item.admissionNo}
                        </span>
                      </div>
                    </ImgTextDiv>
                  </td>
                  <td>{item.cumulativeScore}%</td>
                  <td>{item.averageScore}%</td>
                  <td>
                    {item?.position && (
                      <span>
                        {item.position !== null || item.position !== ""
                          ? item.position
                          : "No Position From API"}
                      </span>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} style={{ textAlign: "center" }}>
                  There Are No Student Available
                  {/* <input type="checkbox" /> */}
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>

      {allData.length > 0 && (
        <div
          style={{
            display: "flex",
            // marginTop: "1rem",
            justifyContent: "end",
            padding: "10px 20px",
          }}
        >
          {checkAccess(accessObj.rights, "Promote") && (
            <AuthButton
              disabled={marked.length === 0 || formLoading}
              onClick={performAction}
              width="150px"
              mt="0.9rem"
            >
              {" "}
              {formLoading ? <FormLoader /> : "Promote Student(s)"}
            </AuthButton>
          )}
        </div>
      )}
    </div>
  );
};

export default Promotion;
