import React, { useState } from "react";
import { AuthButton, InputWrapper } from "../../pages/auth/Login.style";
import Modal from "../modal/Modal";
import colors from "../../assets/colors.json";
import {
  BigHeader,
  SmallHeader,
  InlineLabelGroup,
  InlineLabelWrapper,
  Input,
  Icon,
  Search,
  TabContainer,
  // Icon,
  // Input,
  // TabContainer,
  TabLinkContainer,
  // Search,
  AccountSummaryWrapper,
  // BigHeader,
  // SmallHeader,
  TabContentWrapper,
  InlineFormDiv,
  ButtonFlex,
  SubMenuDiv,
} from "../../pages/Dashboard/styles/dashboard.style";
import { useMediaQuery } from "react-responsive";
import { Box } from "@mui/system";
import Popover from "@mui/material/Popover";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "../../assets/Images/avatar.jpg";
import Select from "../select/select";
import { SiAdobeacrobatreader } from "react-icons/si";
import { BsSearch, BsFillPrinterFill } from "react-icons/bs";
import { IconButton, MenuItem, MenuList } from "@mui/material";
// import { ButtonFlex } from "../../pages/auth/Login.style";
import DatePickerComponent from "../datepicker/DatePicker";
import { AiFillFileExcel } from "react-icons/ai";
import { ImgTextDiv, StatusBar } from "../../pages/student_mgt/stu_mgt.style";

const PayrollSchedule = () => {
  const [showModal, setShowModal] = useState(false);
  const [payrollModal, setPayrollModal] = useState(false);
  const [opt, setOpt] = useState("");
  const isTab = useMediaQuery({ maxWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [dob, setDob] = useState(new Date(2014, 0, 3));
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const optionsArr = [
    {
      id: 1,
      name: "Value 1",
    },
    {
      id: 2,
      name: "Value 2",
    },
  ];
  const content = (
    <>
      <BigHeader style={{ textAlign: "center" }}>Add New Staff</BigHeader>
      <div
        style={{
          width: isTab ? "90%" : "80%",
          // background: "red",
          margin: "0 auto",
          paddingBottom: "0.5rem",
        }}
      >
        <InputWrapper>
          <label htmlFor="school">School</label>
          <Select
            title="School"
            index="name"
            selected={"Value 1"}
            options={optionsArr}
            setSelected={(val) => {
              console.log(val);
              setOpt(val.name);
            }}
          />
        </InputWrapper>

        <InputWrapper>
          <label htmlFor="">Staff ID/Name</label>
          <Input width="100%">
            <Icon tp="16px">
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search" br="10px" />
          </Input>
        </InputWrapper>

        <InputWrapper>
          <input
            type="text"
            placeholder="Jame Doe"
            onChange={(e) => console.log(e.target.value)}
          />
        </InputWrapper>
      </div>

      <div
        style={{
          borderTop: `1px solid ${colors.gray}`,
          paddingBottom: "0.5rem",
        }}
      >
        <div
          style={{
            width: isTab ? "90%" : "80%",
            // background: "red",
            margin: "0 auto",
          }}
        >
          <InlineLabelGroup>
            <InlineLabelWrapper flex={2}>
              <InputWrapper>
                <label htmlFor="">Basic</label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={6}>
              <InputWrapper>
                <input
                  type="text"
                  placeholder="NGN 36,000"
                  value={"NGN 36,000"}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </div>
      </div>
      <div
        style={{
          borderTop: `1px solid ${colors.gray}`,
          paddingBottom: "0.5rem",
        }}
      >
        <div
          style={{
            width: isTab ? "90%" : "80%",
            // background: "red",
            margin: "0 auto",
          }}
        >
          <SmallHeader style={{ fontWeight: "bold" }}>Allowance</SmallHeader>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={2}>
              <InputWrapper>
                <label htmlFor="">Transport</label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={6}>
              <InputWrapper>
                <input
                  type="text"
                  placeholder="NGN 36,000"
                  value={"NGN 36,000"}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={2}>
              <InputWrapper>
                <label htmlFor="">Wardrope</label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={6}>
              <InputWrapper>
                <input
                  type="text"
                  placeholder="NGN 36,000"
                  value={"NGN 36,000"}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={2}>
              <InputWrapper>
                <label htmlFor="">Overtime</label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={6}>
              <InputWrapper>
                <input
                  type="text"
                  placeholder="NGN 36,000"
                  value={"NGN 36,000"}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </div>
      </div>
      <div
        style={{
          borderTop: `1px solid ${colors.gray}`,
          paddingBottom: "0.5rem",
        }}
      >
        <div
          style={{
            width: isTab ? "90%" : "80%",
            // background: "red",
            margin: "0 auto",
          }}
        >
          <SmallHeader style={{ fontWeight: "bold" }}>Deduction</SmallHeader>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={2}>
              <InputWrapper>
                <label htmlFor="">NHIS</label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={6}>
              <InputWrapper>
                <input
                  type="text"
                  placeholder="NGN 36,000"
                  value={"NGN 36,000"}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={2}>
              <InputWrapper>
                <label htmlFor="">Tax</label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={6}>
              <InputWrapper>
                <input
                  type="text"
                  placeholder="NGN 36,000"
                  value={"NGN 36,000"}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={2}>
              <InputWrapper>
                <label htmlFor="">Cooperative</label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={6}>
              <InputWrapper>
                <input
                  type="text"
                  placeholder="NGN 36,000"
                  value={"NGN 36,000"}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <AuthButton width="80%" mt="0">
          Apply
        </AuthButton>
      </div>
    </>
  );

  const payrollContent = (
    <>
      <BigHeader style={{ textAlign: "center" }}>Payroll Schedule</BigHeader>
      <TabContainer>
        <TabLinkContainer bg="#EEE0F1" style={{ position: "relative" }}>
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search " />
          </Input>
          <div style={{ display: "inline-flex" }} className="div">
            <AiFillFileExcel
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
            <SiAdobeacrobatreader
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
            <BsFillPrinterFill
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
          </div>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table style={{ borderRadius: "0px" }}>
            <tr
              className="table-head"
              style={{ backgroundColor: "#EEE0F1", color: "black" }}
            >
              <th colSpan={6}></th>
              <th>
                <SmallHeader>Basic</SmallHeader>
              </th>
              <th colSpan={5}>
                <SmallHeader style={{ textAlign: "center" }}>
                  Allowance
                </SmallHeader>
              </th>
              <th colSpan={6}>
                <SmallHeader style={{ textAlign: "center" }}>
                  Deductions
                </SmallHeader>
              </th>
            </tr>
            <tr
              className="table-head"
              style={{ backgroundColor: "#EEE0F1", color: "black" }}
            >
              <th>
                <input type="checkbox" />
              </th>
              <th>
                {" "}
                <p style={{ width: "max-content" }}> Staff Name</p>
              </th>
              <th>
                <p style={{ width: "max-content" }}> Staff ID</p>
              </th>
              <th>
                <p style={{ width: "max-content" }}> Unit</p>
              </th>
              <th>Month</th>
              <th>Year</th>
              <th></th>
              <th>Wardrope</th>
              <th>Transport</th>
              <th>Overtime</th>
              <th>
                <p style={{ width: "max-content" }}> Total Allowance</p>
              </th>
              <th>Gross</th>
              <th>NHIS</th>
              <th>Tax</th>
              <th>Cooperative</th>
              <th>Total Deduction</th>
              <th>Net</th>

              <th></th>
            </tr>
            <tr>
              <td>
                <input type="checkbox" />
              </td>
              <td>
                <ImgTextDiv>
                  <img src={Avatar} alt="" />
                  <span style={{ width: "max-content" }}>Adekitan Sandra</span>
                </ImgTextDiv>
              </td>
              <td>124354</td>
              <td>Head Teacher</td>
              <td>January</td>
              <td>2022</td>
              <td>
                <p style={{ width: "max-content" }}>NGN 30,000</p>
              </td>
              <td>
                <p style={{ width: "max-content" }}>NGN 30,000</p>
              </td>
              <td>
                <p style={{ width: "max-content" }}>NGN 30,000</p>
              </td>{" "}
              <td>
                <p style={{ width: "max-content" }}>NGN 30,000</p>
              </td>{" "}
              <td>
                <p style={{ width: "max-content" }}>NGN 90,000</p>
              </td>{" "}
              <td>
                <p style={{ width: "max-content" }}>NGN 90,000</p>
              </td>{" "}
              <td>
                <p style={{ width: "max-content" }}>NGN 30,000</p>
              </td>{" "}
              <td>
                <p style={{ width: "max-content" }}>NGN 30,000</p>
              </td>{" "}
              <td>
                <p style={{ width: "max-content" }}>NGN 30,000</p>
              </td>{" "}
              <td>
                <p style={{ width: "max-content" }}>NGN 90,000</p>
              </td>{" "}
              <td>
                <p style={{ width: "max-content" }}>NGN 90,000</p>
              </td>
              <td>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              </td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" />
              </td>
              <td>
                <ImgTextDiv>
                  <img src={Avatar} alt="" />
                  <span style={{ width: "max-content" }}>Adekitan Sandra</span>
                </ImgTextDiv>
              </td>
              <td>124354</td>
              <td>Head Teacher</td>
              <td>January</td>
              <td>2022</td>
              <td>
                <p style={{ width: "max-content" }}>NGN 30,000</p>
              </td>
              <td>
                <p style={{ width: "max-content" }}>NGN 30,000</p>
              </td>
              <td>
                <p style={{ width: "max-content" }}>NGN 30,000</p>
              </td>{" "}
              <td>
                <p style={{ width: "max-content" }}>NGN 30,000</p>
              </td>{" "}
              <td>
                <p style={{ width: "max-content" }}>NGN 90,000</p>
              </td>{" "}
              <td>
                <p style={{ width: "max-content" }}>NGN 90,000</p>
              </td>{" "}
              <td>
                <p style={{ width: "max-content" }}>NGN 30,000</p>
              </td>{" "}
              <td>
                <p style={{ width: "max-content" }}>NGN 30,000</p>
              </td>{" "}
              <td>
                <p style={{ width: "max-content" }}>NGN 30,000</p>
              </td>{" "}
              <td>
                <p style={{ width: "max-content" }}>NGN 90,000</p>
              </td>{" "}
              <td>
                <p style={{ width: "max-content" }}>NGN 90,000</p>
              </td>
              <td>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              </td>
            </tr>
            <tr
              className="table-head"
              style={{ backgroundColor: "#EEE0F1", color: "black" }}
            >
              <td colSpan={6}>
                <SmallHeader style={{ textAlign: "center" }}>Total</SmallHeader>
              </td>
              <td>
                <SmallHeader
                  style={{ fontWeight: "bold", width: "max-content" }}
                >
                  NGN 30,000
                </SmallHeader>
              </td>
              <td>
                <SmallHeader
                  style={{ fontWeight: "bold", width: "max-content" }}
                >
                  NGN 30,000
                </SmallHeader>
              </td>
              <td>
                <SmallHeader
                  style={{ fontWeight: "bold", width: "max-content" }}
                >
                  NGN 30,000
                </SmallHeader>
              </td>
              <td>
                <SmallHeader
                  style={{ fontWeight: "bold", width: "max-content" }}
                >
                  NGN 30,000
                </SmallHeader>
              </td>
              <td>
                <SmallHeader
                  style={{ fontWeight: "bold", width: "max-content" }}
                >
                  NGN 90,000
                </SmallHeader>
              </td>
              <td>
                <SmallHeader
                  style={{
                    width: "max-content",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    background: colors.primary,
                    color: "white",
                    padding: "4px 8px",
                  }}
                >
                  NGN 90,000
                </SmallHeader>
              </td>
              <td>
                <SmallHeader
                  style={{ fontWeight: "bold", width: "max-content" }}
                >
                  NGN 30,000
                </SmallHeader>
              </td>
              <td>
                <SmallHeader
                  style={{ fontWeight: "bold", width: "max-content" }}
                >
                  NGN 30,000
                </SmallHeader>
              </td>
              <td>
                <SmallHeader
                  style={{ fontWeight: "bold", width: "max-content" }}
                >
                  NGN 30,000
                </SmallHeader>
              </td>
              <td>
                <SmallHeader
                  style={{ fontWeight: "bold", width: "max-content" }}
                >
                  NGN 90,000
                </SmallHeader>
              </td>
              <td>
                <SmallHeader
                  style={{
                    fontWeight: "bold",
                    borderRadius: "5px",
                    width: "max-content",
                    background: colors.primary,
                    color: "white",
                    padding: "4px 8px",
                  }}
                >
                  NGN 90,000
                </SmallHeader>
              </td>

              <td></td>
            </tr>
          </table>
        </AccountSummaryWrapper>
        <div
          style={{
            display: "flex",
            marginTop: "1rem",
            justifyContent: "end",
            padding: "10px 20px",
          }}
        >
          <button
            style={{
              borderRadius: "8px",
              // padding: "8px",
              minWidth: "121px",
              color: "white",
              backgroundColor: colors.primary,
              cursor: "pointer",
              lineHeight: "2.125rem",
              border: `1px solid ${colors.primary}`,
              outline: "none",
              fontWeight: "bold",
            }}
          >
            Approve
          </button>
          <button
            style={{
              marginLeft: "0.5rem",
              borderRadius: "8px",
              fontSize: "16px",
              // padding: "8px",
              minWidth: "121px",
              backgroundColor: "white",
              color: colors.primary,
              cursor: "pointer",
              lineHeight: "2.125rem",
              border: `1px solid ${colors.primary}`,
              outline: "none",
              fontWeight: "bold",
            }}
          >
            Delete
          </button>
        </div>
      </TabContainer>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem>Edit Profile</MenuItem>
            <MenuItem>Attachment</MenuItem>
            <MenuItem>Assign Class</MenuItem>
            <MenuItem>Assign Subject</MenuItem>
            <MenuItem>Weekly Report</MenuItem>
            <MenuItem>Deactivate</MenuItem>
            <MenuItem>Chat</MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </>
  );
  return (
    <>
      {payrollModal && (
        <Modal
          start={true}
          size={"large"}
          content={payrollContent}
          onClose={setPayrollModal}
        />
      )}
      {showModal && (
        <Modal
          start={true}
          content={content}
          size={"large"}
          onClose={setShowModal}
          // pd={"0px"}
        />
      )}
      <div style={{ display: "flex", justifyContent: "end" }}>
        <AuthButton onClick={() => setShowModal(!showModal)}>
          Setup New Staff Payroll
        </AuthButton>
      </div>

      <div
        style={{
          width: isTab ? "100%" : "50%",
          background: colors.primary,
          paddingBottom: "1rem",
          borderRadius: "5px",
        }}
      >
        <InlineLabelGroup>
          <InlineLabelWrapper flex={3}>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper>
                  <label
                    htmlFor=""
                    style={{
                      color: "white",
                    }}
                  >
                    Year
                  </label>
                  <DatePickerComponent
                    bg={colors.primary}
                    useBorder={true}
                    color="white"
                    selected={dob}
                    setSelected={(val) => {
                      setDob(val);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={1}>
                <InputWrapper>
                  <label
                    htmlFor=""
                    style={{
                      color: "white",
                    }}
                  >
                    Month
                  </label>
                  <DatePickerComponent
                    bg={colors.primary}
                    useBorder={true}
                    color="white"
                    selected={dob}
                    setSelected={(val) => {
                      setDob(val);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
          </InlineLabelWrapper>
        </InlineLabelGroup>
      </div>

      <TabContainer style={{ background: colors.primary }}>
        <InlineLabelGroup>
          <InlineLabelWrapper flex={4}>
            <InlineLabelGroup>
              {/* Search By School */}
              <InlineLabelWrapper flex={2}>
                <InputWrapper>
                  <Select
                    color={"white"}
                    title="School"
                    index="name"
                    selected={"Value 1"}
                    options={optionsArr}
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              {/* Search Department */}
              <InlineLabelWrapper flex={1}>
                <InputWrapper>
                  <Select
                    color={"white"}
                    title="School"
                    index="name"
                    selected={"Value 1"}
                    options={optionsArr}
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              {/* Search By Unit */}
              <InlineLabelWrapper flex={1}>
                <InputWrapper>
                  <Select
                    color={"white"}
                    title="School"
                    index="name"
                    selected={"Value 1"}
                    options={optionsArr}
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
          </InlineLabelWrapper>
          <InlineLabelWrapper flex={1}>
            <ButtonFlex>
              <AuthButton
                mt="1rem"
                disabled={false}
                width={isMobile ? "100%" : "90%"}
                onClick={() => setPayrollModal(!payrollModal)}
                color={colors.primary}
                bg="white"
              >
                <div style={{ textAlign: "center" }}>Load</div>
              </AuthButton>
            </ButtonFlex>
          </InlineLabelWrapper>
        </InlineLabelGroup>
      </TabContainer>
    </>
  );
};

export default PayrollSchedule;
