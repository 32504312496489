import React, { useState, useEffect, useCallback } from "react";
import { SiAdobeacrobatreader } from "react-icons/si";
import { BsSearch, BsFillPrinterFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { IconButton, MenuItem, MenuList } from "@mui/material";
import ErrorService from "../../services/errorService";
import Pagination from "../pagination_one/pagination";
import colors from "../../assets/colors.json";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import Popover from "@mui/material/Popover";
import { AiFillFileExcel } from "react-icons/ai";
import { ImgTextDiv, StatusBar } from "../../pages/student_mgt/stu_mgt.style";
import {
  Icon,
  Input,
  TabContainer,
  TabLinkContainer,
  Search,
  AccountSummaryWrapper,
  Container,
} from "../../pages/Dashboard/styles/dashboard.style";
import {
  allParents,
  deleteParent,
  approveParent,
} from "../../services/ParentStudent";
import Loader from "../pageLoader/loader";
import moment from "moment";
import GlobalStyles from "../../assets/globalStyle";
import { paginateData } from "../../services/utils";
import { AuthButton, DeleteButton } from "../../pages/auth/Login.style";
import FormLoader from "../FormLoader";
import { useNavigate } from "react-router-dom";

const CreateParentList = () => {
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(true);
  const { admToken } = useSelector((state) => state.auth);
  const [arr, setArr] = useState([]);
  const [allData, setAllData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffSet, setitemOffSet] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [parentPhone, setParentPhone] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [param, setParam] = useState("");

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setParam(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getParents = useCallback(async () => {
    try {
      setCurrentPage(1);
      const res = await allParents(admToken);
      console.log(res);

      setAllData(res.data);
      // setPageCount(res.pages);
      // setCurrentPage(res.currentPage);
      setPageLoading(false);
    } catch (error) {
      console.log(error);
      setPageLoading(false);
    }
  }, [admToken]);

  useEffect(() => {
    paginateData(
      allData,
      setArr,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allData, currentPage]);

  useEffect(() => {
    getParents();
  }, [getParents]);
  const checkParent = (id) => {
    console.log(id);
    parentPhone === id ? setParentPhone("") : setParentPhone(id);
  };

  const deleteFunc = async () => {
    try {
      setDelLoading(true);

      const res = await deleteParent(admToken, parentPhone);
      setDelLoading(false);
      console.log(res);
      res.status &&
        res.statusCode === 2 &&
        toast.success("Parent Successfully Deleted", {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        }) &&
        getParents() &&
        setParentPhone("");
      res.status === 400 && ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      setDelLoading(false);
      console.log(error);
      toast.error("An Error Ocurred, Try Again Later");
    }
  };
  const approveFunc = async () => {
    try {
      setFormLoading(true);

      const res = await approveParent(admToken, parentPhone);
      setFormLoading(false);
      console.log(res);
      res.status &&
        res.statusCode === 2 &&
        toast.success(res.data || res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        }) &&
        getParents() &&
        setParentPhone("");
      res.status === 400 && ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      setFormLoading(false);
      console.log(error);
      toast.error("An Error Ocurred, Try Again Later");
    }
  };
  if (pageLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Loader />
      </div>
    );
  }
  return (
    <Container>
      <GlobalStyles />
      <TabContainer style={{ marginTop: "1rem", paddingBottom: "0px" }}>
        <TabLinkContainer bg="#F0E8F1" style={{ position: "relative" }}>
          <Input>
            <Icon>
              <BsSearch />
            </Icon>
            <Search type="text" placeholder="Search " />
          </Input>
          <div style={{ display: "inline-flex" }} className="div">
            <AiFillFileExcel
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
            <SiAdobeacrobatreader
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
            <BsFillPrinterFill
              style={{ fontSize: "25px", marginLeft: "0.3rem" }}
            />
          </div>
        </TabLinkContainer>
        <AccountSummaryWrapper pt="0px">
          <table style={{ borderRadius: "0px" }}>
            <tr
              className="table-head"
              style={{ backgroundColor: "#F0E8F1", color: "black" }}
            >
              <th>{/* <input type="checkbox" /> */}</th>
              <th>Full Name</th>
              <th>Phone Number </th>

              <th>Email Address</th>
              <th>Status</th>
              <th>Created By</th>
              <th>Date Created</th>
              <th></th>
            </tr>

            {arr.length > 0 ? (
              arr.map((item, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={parentPhone === item.phoneNumber}
                      // onChange={(e) => {
                      //   console.log("Hello");
                      //   console.log(e);
                      //   parentPhone === item.phoneNumber
                      //     ? setParentPhone("")
                      //     : setParentPhone(item.parentPhone);
                      // }}
                      onChange={() => checkParent(item.phoneNumber)}
                    />
                  </td>

                  <td>
                    <ImgTextDiv>
                      {/* <img
                        src={
                          item.profileImagePath ? item.profileImagePath : Avatar
                        }
                        alt=""
                      /> */}
                      <span>{`${item.fullName}`}</span>
                    </ImgTextDiv>
                  </td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.email}</td>
                  <td>
                    <StatusBar status="saved">
                      {item.createParentStatus || "Saved"}
                    </StatusBar>
                  </td>
                  <td>
                    {item.createdBy === null ? "No Data" : item.createdBy}
                  </td>
                  <td>{moment(item.createdDate).format("MM/DD/YYYY")}</td>
                  <td>
                    <IconButton
                      onClick={(e) => handleClick(e, item.phoneNumber)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} style={{ textAlign: "center" }}>
                  No Parent List
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      <div
        style={{
          display: "flex",
          marginTop: "1rem",
          justifyContent: "end",
          padding: "10px 20px",
        }}
      >
        <AuthButton
          disabled={parentPhone === "" || formLoading}
          onClick={approveFunc}
        >
          {formLoading ? <FormLoader /> : "Approve"}
        </AuthButton>
        <DeleteButton
          style={{ marginLeft: "0.2rem" }}
          disabled={parentPhone === "" || delLoading}
          onClick={deleteFunc}
        >
          {delLoading ? <FormLoader /> : "Delete"}
        </DeleteButton>
      </div>
      {pageCount > 1 && (
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Pagination
            pageCount={pageCount > 1 && pageCount}
            range={3}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem
              onClick={() => {
                navigate(`/parent?parent_id=${param}`);
              }}
            >
              View
            </MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </Container>
  );
};

export default CreateParentList;
