import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import Title from "../../Dashboard/Title";
import { useLocation, useSearchParams } from "react-router-dom";
import CreateSubject from "./CreateSubject";
import AssignClassToSubjectTeacher from "./AssignClassToSubjectTeacher";
const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Subject = () => {
  const location = useLocation();
  const tab_id = Number(new URLSearchParams(location.search).get("tab")) || 0;
  const page_id = Number(new URLSearchParams(location.search).get("page")) || 1;
  const [searchParams, setSearchParams] = useSearchParams();
  const handleChange = (event, newValue) => {
    setSearchParams({ ...searchParams, tab: newValue, page: page_id });
  };
  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <Box>
          <Title title="Staff Account Setup" showCaption={false} />
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tab_id}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Manage Subjects"
                  {...a11yProps(0)}
                  sx={{
                    textTransform: "initial",
                    fontFamily: "Gilroy-Bold",
                    fontSize: "18px",
                  }}
                />
                <Tab
                  // New Staffs
                  label="Assign Subject Teachers To Class"
                  {...a11yProps(1)}
                  sx={{
                    textTransform: "initial",
                    fontFamily: "Gilroy-Bold",
                    fontSize: "18px",
                  }}
                />
              </Tabs>
            </Box>
            {tab_id === 0 && <CreateSubject />}
            {tab_id === 1 && <AssignClassToSubjectTeacher />}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Subject;
