import { createTheme, ThemeProvider, Typography, Grid, FormControl, Select } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { Button, IconButton, MenuItem, MenuList } from '@mui/material'
import { BsSearch } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputBase from '@mui/material/InputBase';
import Pagination from '@mui/material/Pagination';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popover from '@mui/material/Popover';

import Modal from '@mui/material/Modal';

const theme = createTheme({
  palette:{
    primary:{
      main:'#671e78'
    }
  }
})
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  
};

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#FFF',

  border:'1px solid #691e78',
  borderRadius:"5px",
  '&:hover': {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width:'345px',

  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
   
  },

}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily:'Gilroy-Bold'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily:'Gilroy-Regular'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({

  "&:nth-of-type(even)": {
    backgroundColor: ' rgba(103, 30, 120, 0.1)'
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function createData( date, purpose, beneficiary, amount, status) {
  return { date, purpose, beneficiary, amount, status };
}

const rows = [
  createData( '12/2/2023 : 10:20am', 'lorem suium pwisheb','Adeola James','20,000' ),
  createData('12/2/2023 : 10:20am', 'lodn d hdjsj sjdj','Adeola James', '12,000'),
  createData('12/2/2023 : 10:20am', 'lodn d hdjsj sjdj','Adeola James', '12,000'),
  
];
const Reciepts = () => {
  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const [anchorEl, setAnchorEl] = React.useState(null);



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [beneficiary, setBeneficiary] = useState("Beneficiary")

  return (
 <>
 <ThemeProvider theme={theme}>
   <Box>
          <Typography
            variant="body"
            sx={{ fontFamily: "Gilroy-Medium", fontSize: "26px" }}
          >
            <span style={{ opacity: 0.3, fontFamily: "Gilroy-Regular" }}>
              Parent Management &#62;&#62;
            </span>{" "}
            Parent Dashboard - Mr James Olu(2) &#62;&#62; Reciepts
          </Typography>
          <Box>

<Box
        mt={5}
        sx={{
          border: "1px solid #671E78",
          borderRadius: "20px",
     
        }}
      >
                    <Box sx={{ borderRadius:'20px 20px 0px 0px' ,pt:4, background: 'rgba(103, 30, 120, 0.1)', height:'100px', pl:{xs:2.5}}}>
                      <Grid container>
                        <Grid item lg={5}>
<Box sx={{display:'flex', justifyContent:'space-between'}}>

                        <Search sx={{width:{lg:'300px', md:'330px', sm:'auto', xs:'auto', pl:{xs:6}, fontFamily:'Gilroy-Regular'}}}>
    
    <StyledInputBase
      placeholder="Search by reciept name"
      inputProps={{ 'aria-label': 'search' }}
      />
  </Search>
  <FormControl >
        
        <Select
          sx={{fontFamily:'Gilroy-Regular'}}
       placeholder="Bene"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          variant="outlined"
          size="small"
          value={beneficiary}
          onChange={(e)=>setBeneficiary(e.target.value)}
          
        >

          {/* ...................................................... */}
{/* ...................................................... */}
          <MenuItem   sx={{fontFamily:'Gilroy-Regular', }} value="Global Statistics">
       Beneficiary
          </MenuItem>
          <MenuItem value="School 1">
   
              School 1
           
          </MenuItem>
          <MenuItem   sx={{fontFamily:'Gilroy-Regular'}} value="School 2">
   
              School 2
           
          </MenuItem>

          {/* ...................................................... */}
{/* ...................................................... */}
        </Select>
      </FormControl>
      </Box>
  
                        </Grid>
                        <Grid item lg={7}></Grid>
                      </Grid>

                    </Box>
        <TableContainer 
       
        >
          <Table sx={{ minWidth: 700, fontFamily:'Gilroy-Regular' }} aria-label="customized table">
            <TableHead sx={{  backgroundColor: "rgba(103, 30, 120, 0.1)", width:'100%',}}>
  
              <TableRow>
                <StyledTableCell>
                  S/N
                </StyledTableCell>
                <StyledTableCell>Recieptname</StyledTableCell>
                <StyledTableCell >Reciept Id</StyledTableCell>
                <StyledTableCell >
               Date
                </StyledTableCell>
                <StyledTableCell >
                Amount
                </StyledTableCell>
                <StyledTableCell>
               Action
                </StyledTableCell>
             
              </TableRow>
            </TableHead>
            <TableBody sx={{fontFamily:'Gilroy-Regular'}}>
              {rows.map((row, index) => (
                <StyledTableRow key={index} >
                  <StyledTableCell component="th" scope="row">
               {index + 1}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.date}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.purpose}
                  </StyledTableCell>
                  <StyledTableCell > {row.beneficiary}</StyledTableCell>
                  <StyledTableCell >
                     {row.amount}
                  </StyledTableCell>
            
                  <StyledTableCell  >
                  <IconButton onClick={handleClick}>
                        <MoreVertIcon/>
                      </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
     
        </TableContainer>
      </Box>
      <Box sx={{display:'flex', justifyContent:'right', mt:3}}>
        <Pagination count={10} color="primary" />
        </Box>

      
   
</Box>
        </Box>
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
      >
       <Box sx={{bgcolor:'#671E78', color:'#fff', fontFamily:'Gilroy-Regular', px:2}}>
        <MenuList sx={{fontFamily:'Gilroy-Regular'}}>
            <MenuItem onClick={handleOpenModal}>View </MenuItem>
            <MenuItem>Print</MenuItem>
            <MenuItem>Export as PDF</MenuItem>
        </MenuList>
       </Box>
      </Popover>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{
height:'140px', 
bgcolor:'#671e78',
display:'flex',
fontFamily:'Gilroy-Regular'
          }}>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', width:'60%', color:'#fff', fontFamily:'Gilroy-Regular'}}>
              <Typography variant='h4' sx={{fontFamily:'Gilroy-Regular'}}>RECIEPT</Typography>
            </Box>
            <Box sx={{bgcolor:'#4a1556', display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center', width:'40%', color:'#fff'}}>
              <Typography variant='caption' sx={{fontFamily:'Gilroy-Regular'}}>AMOUNT PAID</Typography>
              <Typography variant='h4' sx={{fontFamily:'Gilroy-Regular'}}>NGN 40,000</Typography>
            </Box>

          </Box>
            <Box sx={{height:'600px', p:3}}>
              <Grid container>
                <Grid item lg={6}>
                  <Box>
                    <Typography variant='caption'>BILL TO</Typography><br/>
                    <Typography variant='caption' sx={{fontFamily:'Gilroy-Medium'}}>Gbolapo Adeyemo</Typography><br/>
                    <Typography variant='caption' sx={{fontFamily:'Gilroy-Regular'}}>+2349028818847</Typography><br/>
                    <Typography variant='caption' sx={{fontFamily:'Gilroy-Regular'}}>alimisamuel121@gmail.com</Typography>
                  </Box>
                </Grid>
                <Grid item lg={6}>
                <Box sx={{textAlign:'right'}}>
                    <Typography variant='caption' sx={{fontFamily:'Gilroy-Medium'}}>Reciept Number</Typography><br/>
                    <Typography variant='caption' sx={{fontFamily:'Gilroy-Medium'}}>Reciept Date:</Typography> <span style={{fontFamily:'Gilroy-Regular',fontSize:'13px'}}>August 30, 2023</span><br/>
                    <Typography variant='caption' sx={{fontFamily:'Gilroy-Medium'}}>Reciept Date:</Typography> <span style={{fontFamily:'Gilroy-Regular',fontSize:'13px'}}>August 30, 2023</span>
                  </Box>
                </Grid>
              </Grid>

            </Box>
        </Box>
      </Modal>
        </ThemeProvider>
 </>
  )
}

export default Reciepts