import {
  FormFlex,
  FormFlexDiv,
  TabContainer,
  TabContent,
  TabContentWrapper,
  SmallHeader,
} from "../../pages/Dashboard/styles/dashboard.style";
import { InputWrapper, AuthButton } from "../../pages/auth/Login.style";
import { useMediaQuery } from "react-responsive";
import DatePickerComponent from "../datepicker/DatePicker";
import colors from "../../assets/colors.json";
import PropTypes from "prop-types";
import FormLoader from "../FormLoader";
import moment from "moment/moment";
const Experience = ({
  data,
  obj,
  setObj,
  action,
  showForm,
  formLoading,
  clearFields,
}) => {
  const isTab = useMediaQuery({ maxWidth: 992 });
  const isNumber = (evt) => {
    // console.log(evt);
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  };
  return (
    <TabContentWrapper>
      {showForm && (
        <>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="employer">Employer</label>
                <input
                  type="text"
                  placeholder="Employer"
                  value={obj.employer}
                  onChange={(e) => setObj({ ...obj, employer: e.target.value })}
                  readOnly
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="empolyer">Employer's phone Number</label>
                <input
                  type="text"
                  onKeyDown={(e) => isNumber(e)}
                  placeholder="Employer's Phone Number"
                  value={obj.empPhoneNumber}
                  onChange={(e) =>
                    setObj({ ...obj, empPhoneNumber: e.target.value })
                  }
                  readOnly
                />
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="">Position</label>
                <input
                  type="text"
                  placeholder="Position"
                  value={obj.position}
                  onChange={(e) => setObj({ ...obj, position: e.target.value })}
                  readOnly
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="">Employer's Email</label>
                <input
                  type="text"
                  placeholder="Employer's Email"
                  value={obj.empEmail}
                  onChange={(e) => setObj({ ...obj, empEmail: e.target.value })}
                  readOnly
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="employer's address">Employer's Address</label>
                <textarea
                  cols="30"
                  rows="3"
                  placeholder="Employer's Address"
                  value={obj.empAddress}
                  onChange={(e) =>
                    setObj({ ...obj, empAddress: e.target.value })
                  }
                  readOnly
                ></textarea>
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="">Reason For Leaving</label>
                <textarea
                  cols="30"
                  rows="3"
                  placeholder="Reason For Leaving"
                  value={obj.reasons}
                  onChange={(e) => setObj({ ...obj, reasons: e.target.value })}
                  readOnly
                ></textarea>
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="">Start Date</label>

                <DatePickerComponent
                  // selected={new Date(obj.startDate)}
                  selected={
                    obj.startDate === "" || obj.startDate === null
                      ? ""
                      : new Date(Date.parse(obj.startDate))
                  }
                  setSelected={(val) => setObj({ ...obj, startDate: val })}
                  disabled
                />
              </InputWrapper>

              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <div style={{ width: "fit-content", marginRight: "10px" }}>
                  <input
                    type="checkbox"
                    checked={obj.current}
                    onChange={(e) =>
                      e.target.checked
                        ? setObj({ ...obj, current: true })
                        : setObj({ ...obj, current: false })
                    }
                    // value={obj.current}
                    // onChange={}
                    style={{
                      padding: "0.2rem 0.4rem",
                      width: "min-content",
                      marginTop: "0px",
                    }}
                    disabled
                  />
                </div>
                <div style={{ width: "-webkit-fill-available" }}>
                  <label htmlFor="">I Currently Work Here</label>
                </div>
              </div>
            </FormFlexDiv>
            {!obj.current && (
              <FormFlexDiv>
                <InputWrapper>
                  <label htmlFor="">End Date</label>

                  <DatePickerComponent
                    selected={
                      obj.endDate === "" || obj.endDate === null
                        ? ""
                        : new Date(Date.parse(obj.endDate))
                    }
                    setSelected={(val) => setObj({ ...obj, endDate: val })}
                    disabled
                  />
                </InputWrapper>
              </FormFlexDiv>
            )}
          </FormFlex>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: isTab ? "column" : "row",
            }}
          >
            <AuthButton
              width={isTab ? "100%" : "30%"}
              onClick={action}
              disabled={formLoading}
            >
              {formLoading ? (
                <FormLoader />
              ) : (
                <div>
                  <span> {obj.id ? "Edit" : "Add"} Experience</span>
                </div>
              )}
            </AuthButton>

            {obj.id && (
              <AuthButton
                onClick={clearFields}
                width={isTab ? "100%" : "30%"}
                bg="red"
                style={{
                  border: "1px solid red",
                  marginLeft: isTab ? "0px" : "10px",
                  marginTop: isTab ? "15px" : "0px",
                }}
              >
                Clear
              </AuthButton>
            )}
          </div> */}
        </>
      )}

      <div style={{ marginTop: "30px" }}>
        {data.length > 0 ? (
          data.map((item, index) => (
            <TabContainer style={{ borderRadius: "10px" }} key={index}>
              <div
                style={{
                  width: "100%",
                  padding: "0.5rem 0.8rem",
                  backgroundColor: colors.secondary,
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  display: "inline-flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "5px",
                      width: "5px",
                      padding: "5px",
                      borderRadius: "50%",
                      backgroundColor: colors.primary,
                      marginRight: "9px",
                    }}
                  ></div>
                  <div>
                    <p style={{ fontSize: "21px", fontWeight: "bold" }}>
                      {item.position}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        marginTop: "5px",
                        fontWeight: "semibold",
                      }}
                    >
                      {item.employer} - <span>{item.empAddress}</span>
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        marginTop: "5px",
                        fontWeight: "semibold",
                      }}
                    >
                      {`${moment(item.startDate).format("MMMM YYYY")} -${moment(
                        item.endDate
                      ).format("MMMM YYYY")}`}
                    </p>
                  </div>
                </div>
                <div>
                  <button
                    style={{
                      borderWidth: "1px",
                      borderRadius: "5px",
                      borderColor: colors.primary,
                      color: "black",
                      padding: "10px 20px",
                      backgroundColor: "transparent",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      setObj(Object.assign({}, item));
                    }}
                  >
                    Edit
                  </button>
                </div>
              </div>

              <TabContent>
                <TabContentWrapper>
                  <div
                    style={{
                      padding: "0.3rem 0.6rem",
                    }}
                  >
                    <p>{item.reasons}</p>
                  </div>
                </TabContentWrapper>
              </TabContent>
            </TabContainer>
          ))
        ) : (
          <>
            <SmallHeader style={{ textAlign: "center", fontWeight: "bold" }}>
              No Work Experience From Staff
            </SmallHeader>
          </>
        )}
      </div>
    </TabContentWrapper>
  );
};

Experience.propTypes = {
  data: PropTypes.array,
  showForm: PropTypes.bool,
};

Experience.defaultProps = {
  data: [],
  showForm: false,
};

export default Experience;
