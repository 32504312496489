import { useState, useCallback, useEffect } from "react";
import {
  Container,
  Icon,
  Input,
  Search,
  TabContainer,
  AccountSummaryWrapper,
  TabLinkContainer,
  BigHeader,
} from "../Dashboard/styles/dashboard.style";
import { AiFillFileExcel } from "react-icons/ai";
import { SiAdobeacrobatreader } from "react-icons/si";
import { BsSearch, BsFillPrinterFill, BsArrowLeftShort } from "react-icons/bs";
import colors from "../../assets/colors.json";
import GlobalStyles from "../../assets/globalStyle";
import { Link, useLocation } from "react-router-dom";
import Pagination from "../../components/pagination_one/pagination";
import { GoBackDiv, ImgTextDiv } from "../student_mgt/stu_mgt.style";
import Avatar from "../../assets/Images/avatar.jpg";
import Loader from "../../components/pageLoader/loader";
import { classStudentInfo, getSchoolList } from "../../services/MySchool";
import { useSelector } from "react-redux";
import { paginateData } from "../../services/utils";
const SchoolClass = () => {
  // const location = useLocation();
  // const schoolId = new URLSearchParams(location.search).get("school_id") || "";
  const { admToken, user } = useSelector((state) => state.auth);
  const [itemOffSet, setitemOffSet] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showStudents, setShowStudents] = useState(false);
  const [loading, setLoading] = useState(true);
  const [classList, setClassList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [allStudents, setAllStudents] = useState([]);
  const [students, setStudents] = useState([]);
  const [studentOffSet, setStudentOffSet] = useState(0);
  const [studentPageCount, setStudentPageCount] = useState(0);
  const [studentCurrentPage, setStudentCurrentPage] = useState(1);
  const [classObj, setClassObj] = useState(null);

  const getSchoolClass = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getSchoolList(admToken, "ClassList", user.schoolId);
      console.log(res);
      setAllData(res.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  }, [admToken, user.schoolId]);

  useEffect(() => {
    getSchoolClass();
    // eslint-disable-next-line no-use-before-define
  }, [getSchoolClass]);

  useEffect(() => {
    paginateData(
      allData,
      setClassList,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allData, currentPage, itemOffSet]);

  useEffect(() => {
    paginateData(
      allStudents,
      setStudents,
      studentCurrentPage,
      studentOffSet,
      setStudentOffSet,
      setStudentPageCount
    );
  }, [allStudents, studentCurrentPage, studentOffSet]);

  const getClassStudent = async (obj) => {
    try {
      console.log(obj);
      setStudentCurrentPage(1);
      setClassObj(obj);
      setLoading(true);
      setShowStudents(true);
      const res = await classStudentInfo(admToken, obj.classId, user.schoolId);
      console.log(res);
      setAllStudents(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <GlobalStyles />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          justifyContent: !showStudents ? "center" : "start",
        }}
      >
        {showStudents && classObj !== null && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <GoBackDiv
              onClick={() => {
                setShowStudents(!showStudents);
                setClassObj(null);
                setStudents([]);
              }}
              style={{ cursor: "pointer" }}
            >
              <div>
                <BsArrowLeftShort />
              </div>

              <span>Back To Class</span>
            </GoBackDiv>
            <div>
              <BigHeader>
                {classObj.className} ({classObj.classTeacher})
              </BigHeader>
            </div>
          </div>
        )}
      </div>

      {!showStudents ? (
        <>
          <TabContainer style={{ paddingBottom: "0px" }}>
            <TabLinkContainer bg="#f0e8f1">
              <Input>
                <Icon>
                  <BsSearch />
                </Icon>
                <Search type="text" placeholder="Search" />
              </Input>
            </TabLinkContainer>
            <AccountSummaryWrapper pt="0px">
              <table
                style={{
                  borderRadius: "0px",
                  borderBottomLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                }}
              >
                <tr
                  className="table-head"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  <th>S/N</th>
                  <th>Class Name</th>
                  <th>Number Of Students</th>
                  <th>Class Teacher</th>

                  <th></th>
                </tr>

                {classList.length > 0 ? (
                  classList.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {item.classShortName} ({item.className})
                      </td>
                      <td>{item.studentCount}</td>
                      <td>{item.classTeacher}</td>

                      <td>
                        <button
                          style={{
                            padding: "0.4rem  0.8rem",
                            border: `1px solid ${colors.primary}`,
                            borderRadius: "3rem",
                            fontWeight: "semibold",
                            background: "transparent",
                          }}
                          onClick={() => getClassStudent(item)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} style={{ textAlign: "center" }}>
                      No Classes Created For This School
                    </td>
                  </tr>
                )}
              </table>
            </AccountSummaryWrapper>
          </TabContainer>

          {pageCount > 1 && (
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              <Pagination
                pageCount={pageCount > 1 && pageCount}
                range={3}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </>
      ) : (
        classObj !== null && (
          <>
            <TabContainer style={{ marginTop: "1rem" }}>
              <TabLinkContainer bg="#F0E8F1" style={{ position: "relative" }}>
                <Input>
                  <Icon>
                    <BsSearch />
                  </Icon>
                  <Search type="text" placeholder="Search " />
                </Input>
                <div style={{ display: "inline-flex" }} className="div">
                  <AiFillFileExcel
                    style={{ fontSize: "25px", marginLeft: "0.3rem" }}
                  />
                  <SiAdobeacrobatreader
                    style={{ fontSize: "25px", marginLeft: "0.3rem" }}
                  />
                  <BsFillPrinterFill
                    style={{ fontSize: "25px", marginLeft: "0.3rem" }}
                  />
                </div>
              </TabLinkContainer>
              <AccountSummaryWrapper pt="0px">
                <table style={{ borderRadius: "0px" }}>
                  <tr
                    className="table-head"
                    style={{ backgroundColor: "#F0E8F1", color: "black" }}
                  >
                    <th>Admission No</th>
                    <th>Student Name </th>
                    <th>Phone</th>
                    <th>Email Address</th>

                    <th></th>
                  </tr>
                  {students.length > 0 ? (
                    students.map((item, index) => (
                      <tr key={index}>
                        <td>{item.admissionNumber}</td>
                        <td>
                          <ImgTextDiv>
                            <img src={Avatar} alt="" />
                            <span>{item.name}</span>
                          </ImgTextDiv>
                        </td>
                        <td>{item.phoneNumber && item.phoneNumber}</td>
                        <td>{item.email}</td>

                        <td>
                          {item.phoneNumber !== null && (
                            <Link
                              to={{
                                pathname: `/student`,
                                search: `?school_id=${user.schoolId}&student_id=${item.phoneNumber}`,
                              }}
                              style={{
                                padding: "0.4rem  0.8rem",
                                border: `1px solid ${colors.primary}`,
                                borderRadius: "3rem",
                                fontWeight: "semibold",
                                background: "transparent",
                                color: colors.primary,
                              }}
                            >
                              View
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5} style={{ textAlign: "center" }}>
                        No Students Available For This Class
                      </td>
                    </tr>
                  )}
                </table>
              </AccountSummaryWrapper>
            </TabContainer>

            {studentPageCount > 1 && (
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <Pagination
                  pageCount={studentPageCount > 1 && studentPageCount}
                  range={1}
                  setCurrentPage={setStudentCurrentPage}
                />
              </div>
            )}
          </>
        )
      )}
    </Container>
  );
};

export default SchoolClass;
