/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import {
  TabContainer,
  TabContent,
  TabLink,
  TabLinkContainer,
} from "../../pages/Dashboard/styles/dashboard.style";
import "react-datepicker/dist/react-datepicker.css";
import StudentBioData from "./StudentBioData";
import SocialData from "./SocialData";
import Health from "./Health";
import Attachment from "./Attachment";
import { createStudent, submitApplication } from "../../services/ParentStudent";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { viewStudentDetails } from "../../services/MySchool";
import PropTypes from "prop-types";
import ErrorService from "../../services/errorService";
import {
  checkAccess,
  isObjEmpty,
  validateEmail,
  validateMobileNumber,
} from "../../services/utils";
import Loader from "../pageLoader/loader";
const CreateStudentForm = ({ queryId, setShowForm, setID, schoolId }) => {
  const [tab, setTab] = useState(1);
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [formLoading, setFormLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  let _socObj = {
    schoolId: user.schoolId,
    childPosition_In_Family: "",
    childPosition_On_Mother: "",
    childAmbition: "",
    childEasyThings: "",
    isChildBedWet: true,
    note_On_Child: "",
    isChild_Away_FromHome_Before: true,
    // parentPhoneNumber: "",
  };

  let _attObj = {
    schoolId: user.schoolId,
    fileName: "",
    fileContentPath: "",
    fileContent: "",
  };
  const [attachObj, setAttachObj] = useState(_attObj);

  const [socialObj, setSocialObj] = useState(_socObj);
  let _bioObj = {
    schoolId: user.schoolId,
    studentId: 0,
    admissionId: 0,
    studentAge: 0,
    classId: 0,
    phoneNumber: "",
    email: "",
    profileImagePath: "",
    firstName: "",
    lastName: "",
    otherName: "",
    residentialeAddress: "",
    origin: "",
    gender: "",
    dateOfBirth: "",
    height: "",
    weight: "",
    hometown: "",
    nationality: "",
    localGovernment: "",
    nearestBustop: "",
    religion: "",
    childOrWardWorshipRole: "",
    vicorOrPastorName: "",
    vicorOrPastorPhoneNo: "",
    eyeColor: "",
    denomination: "",
    denominationAddress: "",
  };
  const [bioObj, setBioObj] = useState(_bioObj);
  let _healthObj = {
    schoolId: user.schoolId,
    isSickleCell: false,
    hasEyeDisease: false,
    hasEpilespy: false,
    hasEarDisease: false,
    hasDiabetics: false,
    hasNoseDisease: false,
    hasTuberculosis: false,
    hasRespiratoryDisease: false,
    hasHeartDisease: false,
    hasKidneyDisease: false,
    hasMentalOrNervousDisease: false,
    hasAlergies: false,
    hasDigestiveDisease: false,
    isChild_HandCap: false,
    isChildAdmitted_To_Hospital: false,
    isChildOperatedBefore: false,
    operationDetails: "",
    isChildOnMedication: false,
    familyAilment: "",
    ailmentStatus: "",
    bloodGroup: "",
    medicalAdvise: "",
    genotype: "",
    childAilment: "",
    childAllergicReaction: "",
    bcgStatus: "",
    bcgDate: "2023-05-15T13:48:50.100Z",
    dptStatus: "",
    dptDate: "2023-05-15T13:48:50.100Z",
    oralPolioStatus: "",
    oralPolioDate: "2023-05-15T13:48:50.100Z",
    yellowFeverStatus: "",
    yellowFeverDate: "2023-05-15T13:48:50.100Z",
  };

  const [healthObj, setHealthObj] = useState(_healthObj);
  const createBioData = async (phone) => {
    console.log(bioObj);
    console.log(phone);
    if (bioObj.phoneNumber === "") {
      return toast.error("Please provide a Phone Number for the Student");
    }
    let check1 = validateEmail(bioObj.email, "Student Email");
    let check3 = validateMobileNumber(bioObj.phoneNumber);
    if (!check1 || !check3) {
      return;
    }
    let formIsValid = isObjEmpty(bioObj);
    if (bioObj.studentId === 0) {
      if (!formIsValid) {
        return toast.error("Please Make Sure You Fill All The Form");
      }
    }
    setFormLoading(true);
    try {
      console.log(bioObj);
      const res = await createStudent(
        admToken,
        bioObj,
        "Create-Student-BioData"
      );
      console.log(res);
      setFormLoading(false);
      if (res.status === true && res.statusCode === 2) {
        toast.success(res.message, {
          autoClose: 4000,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setBioObj({ ...bioObj, studentId: res.data.studentId });
        setTab(2);
      }
      if (res.status === 400 || res.status === 500 || res.status === false) {
        ErrorService.displayErrorAlert(res);
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred");
      setFormLoading(false);
    }
  };

  const createSocialData = async () => {
    console.log(bioObj.studentId);
    let formIsValid = isObjEmpty(socialObj);
    if (!formIsValid) {
      return toast.error("Please Make Sure You Fill All The Form");
    }

    console.log(socialObj);
    try {
      setFormLoading(true);
      const res = await createStudent(
        admToken,
        { ...socialObj, studentId: Number(bioObj.studentId) },
        "Create-Student-SocialData"
      );
      setFormLoading(false);

      res.status && res.statusCode === 2 && setTab(3);
      res.status && res.statusCode === 2 && toast.success(res.message);
      if (res.status === 500 || res.status === 400 || res.status === false) {
        ErrorService.displayErrorAlert(res);
      }
      console.log(res);
    } catch (error) {
      setFormLoading(false);

      console.log(error);
    }
  };

  const addAttachment = async () => {
    let formIsValid = isObjEmpty(socialObj);
    if (!formIsValid) {
      return toast.error("Please Make Sure You Fill All The Form");
    }
    try {
      setFormLoading(true);
      console.log(attachObj);
      const res = await createStudent(
        admToken,
        { ...attachObj, studentId: Number(bioObj.studentId) },
        "Create-Student-Attachment"
      );
      setFormLoading(false);
      res.status && res.statusCode === 2 && viewStudent(bioObj.phoneNumber);
      toast.success(res.message, {
        autoClose: 4000,
      });
      setFormLoading(false);
      setAttachObj(_attObj);
      if (res.status === 500 || res.status === 400 || res.status === false) {
        ErrorService.displayErrorAlert(res);
      }

      console.log(res);
    } catch (error) {
      console.log(error);
      toast.error("An Error Ocuured Try Again Later");
      setFormLoading(false);
    }
  };

  const createHealthData = async () => {
    let formIsValid = isObjEmpty(healthObj);
    if (!formIsValid) {
      return toast.error("Please Make Sure You Fill All The Form");
    }
    try {
      setFormLoading(true);
      console.log(healthObj);
      const res = await createStudent(
        admToken,
        { ...healthObj, studentId: Number(bioObj.studentId) },
        "Create-Student-HealthData"
      );
      // res.status && res.statusCode === 2 && viewStudent();
      // toast.success("Student's Social Data Saved");
      setFormLoading(false);
      res.status && res.statusCode === 2 && setTab(4);
      res.status && res.statusCode === 2 && toast.success(res.message);
      if (res.status === 500 || res.status === 400 || res.status === false) {
        ErrorService.displayErrorAlert(res);
      }

      console.log(res);
    } catch (error) {
      console.log(error);
      toast.error("An Error Ocuured Try Again Later");
      setFormLoading(false);
    }
  };
  const viewStudent = useCallback(
    async (id = queryId) => {
      console.log("changed");
      try {
        const res = await viewStudentDetails(admToken, user.schoolId, id);
        console.log(res);
        // const newObj = { ...bioObj, ...res.data.bioData };
        !res.data.status &&
          res.data.statusCode === 4 &&
          toast.error(res.data.message) &&
          setShowForm(false);
        !res.data.status && res.data.statusCode === 4 && setID("");
        // attachments
        res.data.attachmentData !== null &&
          setAttachments(res.data.attachmentData);

        // health data
        res.data.healthData !== null &&
          setHealthObj({ ...healthObj, ...res.data.healthData });

        // bioData
        res.data.bioData !== null &&
          setBioObj({ ...bioObj, ...res.data.bioData });

        // set socialData
        res.data.socialData !== null &&
          setSocialObj({ ...socialObj, ...res.data.socialData });

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
    [
      admToken,
      bioObj,
      healthObj,
      queryId,
      // schoolId,
      setID,
      setShowForm,
      socialObj,
      user,
    ]
  );

  const [subLoading, setSubLoading] = useState(false);
  const subAction = useCallback(
    async (id) => {
      console.log(id);
      console.log(bioObj.studentId);
      console.log(schoolId);
      try {
        setSubLoading(true);
        const res = await submitApplication(admToken, id, user.schoolId);
        console.log(res);
        res.status && res.statusCode === 2 && toast.success(res.message);
        res.status && res.statusCode === 2 && setShowForm(false);

        if (res.status === 500 || res.status === 400 || res.status === false) {
          ErrorService.displayErrorAlert(res);
        }
        setSubLoading(false);
      } catch (error) {
        setSubLoading(false);
      }
    },
    [admToken, bioObj.studentId, schoolId, setShowForm]
  );
  useEffect(() => {
    if (queryId) {
      setLoading(true);
      viewStudent();
      // console.log("Query is Here");
    } else {
      console.log("no queryid");
    }
  }, [queryId]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <TabContainer>
        <TabLinkContainer>
          <TabLink isActive={tab === 1} onClick={() => setTab(1)}>
            <p>Student Bio-data</p>
          </TabLink>
          <TabLink
            isActive={tab === 2}
            onClick={() => setTab(2)}
            // role="button"
            // aria-disabled
            // disabled
            style={{ pointerEvents: bioObj.studentId === 0 ? "none" : "" }}
          >
            <p>Social Data</p>
          </TabLink>
          <TabLink
            isActive={tab === 3}
            onClick={() => setTab(3)}
            style={{ pointerEvents: bioObj.studentId === 0 ? "none" : "" }}
          >
            <p>Health Data</p>
          </TabLink>
          <TabLink
            isActive={tab === 4}
            onClick={() => setTab(4)}
            style={{ pointerEvents: bioObj.studentId === 0 ? "none" : "" }}
          >
            <p>Attachments</p>
          </TabLink>
        </TabLinkContainer>

        <TabContent>
          {tab === 1 && (
            <StudentBioData
              bioData={bioObj}
              setBioData={setBioObj}
              readOnly={false}
              loading={formLoading}
              action={createBioData}
              showInfoButton={
                queryId ? checkAccess(accessObj.rights, "Update") : true
              }
            />
          )}
          {tab === 2 && (
            <SocialData
              bioData={socialObj}
              setBioData={setSocialObj}
              readOnly={false}
              loading={formLoading}
              action={createSocialData}
              showInfoButton={
                queryId ? checkAccess(accessObj.rights, "Update") : true
              }
            />
          )}
          {tab === 3 && (
            <Health
              bioData={healthObj}
              setBioData={setHealthObj}
              action={createHealthData}
              loading={formLoading}
              readOnly={false}
              showInfoButton={
                queryId ? checkAccess(accessObj.rights, "Update") : true
              }
            />
          )}
          {tab === 4 && (
            <Attachment
              showSubmitButton={true}
              showForm={
                queryId ? checkAccess(accessObj.rights, "Update") : true
              }
              data={attachments}
              action={addAttachment}
              loading={formLoading}
              obj={attachObj}
              setObj={setAttachObj}
              submitAction={subAction}
              submitLoading={subLoading}
              bioData={bioObj}
            />
          )}
        </TabContent>
      </TabContainer>
    </>
  );
};

CreateStudentForm.propTypes = {
  queryId: PropTypes.string,
  schoolId: PropTypes.number,
};

CreateStudentForm.defaultProps = {
  queryId: null,
  schoolId: null,
};

export default CreateStudentForm;
