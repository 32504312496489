import {
  Box,
  Button,
  Grid,
  InputLabel,
  Table,
  TableHead,
  TextField,
  TableBody,
  TableRow,
  Select,
  MenuItem,
  Skeleton,
  Snackbar,
  Alert,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import React, { useState, useEffect } from "react";
import api from "../../../services/assignment";
import axios from "axios";
import LoaderLine from "../../LoaderLine";
import Modal from "@mui/material/Modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { useSelector } from "react-redux";
import InputBase from "@mui/material/InputBase";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { IoRemoveCircleOutline, IoAddCircleOutline } from "react-icons/io5";
import { FaSortDown, FaSortUp } from "react-icons/fa";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: 650, xs: "90%" },
  bgcolor: "background.paper",
  border: "1px solid #671E78",
  boxShadow: 24,
  p: 2,
  borderRadius: 3,
  height: { lg: "auto", xs: 600 },
  overflowY: "scroll",
};

const promptStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 300, xs: 200 },
  height: "200px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "1px solid #671E78",
  boxShadow: 24,
  p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gilroy-Regular",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AddQuestion = ({ open, onClose, id, subject, data, refetch }) => {
  const [subjects, setSubjects] = useState([]);
  const [subjectSelected, setSubjectSelected] = useState("");
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [score, setScore] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [loading, setLoading] = useState(false);
  const [subjectLoader, setSubjectLoader] = useState(false);
  const [classLoader, setClassLoader] = useState(false);
  const [subjectID, setSubjectID] = useState(0);
  const [showClasses, setShowClasses] = useState(false);
  const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
  const api_version = process.env.REACT_APP_API_VERSION;
  const userInfo = useSelector((state) => state.auth.user);
  const [subjectTeacher, setSubjectTeacher] = useState(null);
  const adtoken = localStorage.getItem("admToken");
  const handleChange = (event) => {
    setSubjectSelected(event.target.value);
  };
  const { enqueueSnackbar } = useSnackbar();
  const handleSnackbar = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };
  const handleSnackbarError = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  useEffect(() => {
    if (data) {
      setTitle(data?.title);
      setScore(data?.score);
      setDescription(data?.description);
      setSubjectName(data?.subject);
      setSubjectID(data?.subjectId);
      setSelectedClass(data.assignClasses);
      setSubjectSelected(data?.subjectId);
      setSubjectTeacher(data?.subjectTeacher);
    }
  }, [data]);
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file from the input
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result; // This will contain the base64 data
        setSelectedFile(base64Data); // Set the base64 data using setSelectedFile
      };
      // Read the file as a data URL, which will give you the base64 representation
      reader.readAsDataURL(file);
    }
  };

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adtoken}`,
  };

  const fetchSubjects = async () => {
    setSubjectLoader(true);
    try {
      const response = await axios.get(
        `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/SubjectTeacher/MySubjects?schoolId=${userInfo.schoolId}`,
        { headers }
      );
      if (response.status !== 200) {
        setSubjectLoader(false);
        console.log("Error: Unable to fetch data", response);
        return;
      }

      const data = response.data;
      setSubjectLoader(false);
      if (!data?.status) {
        console.log("Error: Data is null or has no status property.");
        return;
      }
      setSubjects(data?.data); // Save the subjects data in the state
    } catch (error) {
      setSubjectLoader(false);
      console.error("Error:", error.message);
    }
  };
  const fetchClasses = async () => {
    setClassLoader(true);
    try {
      const response = await axios.get(
        `${endpoint_two}/api/v${api_version}/ClassesAndSubjects/SubjectTeacher/MySubjectClasses?subjectId=${subjectID}&schoolId=${userInfo.schoolId}`,
        { headers }
      );

      console.log("Error: Unable to fetch data", response);
      if (response.status !== 200) {
        setClassLoader(false);

        return;
      }
      setClassLoader(false);
      const data = response.data;
      if (!data?.status) {
        console.log("Error: Data is null or has no status property.");
        return;
      }
      setClassLoader(false);
      setClasses(data?.data); // Save the subjects data in the state
      // console.log(subjects, "Walle")
    } catch (error) {
      setClassLoader(false);
      console.error("Error:", error.message);
    }
  };
  useEffect(() => {
    fetchSubjects();
    if (subjectID) {
      fetchClasses(); // Call the fetchClasses function when subjectId changes
    }
  }, [subjectID]);

  const handleCreateAssignment = async () => {
    const assignedClasses = selectedClass?.map((each) => ({
      classId: each.classId,
      className: each.className,
      classarm: each.classArm,
    }));
    setLoading(true);

    const url = `${endpoint_two}/api/v${api_version}/Assignment/EditAssignment`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adtoken}`,
    };
    const requestBody = {
      assignmentId: id,
      schoolId: userInfo.schoolId,
      subject: subjectName,
      subjectTeacher,
      title: title,
      score: score,
      description: description,
      subjectId: subjectID,
      classId: data?.classId,
      assignmentUploadFile: selectedFile ?? "",
      post_date: new Date(),
      staffid: userInfo?.staffId,
      postedBy: "",
      assignClasses:
        assignedClasses.length > 0 ? assignedClasses : data?.assignClasses,
    };
    try {
      const response = await axios.patch(url, requestBody, { headers });

      handleSnackbar("success", "Assignment edited successfully ");
      setLoading(false);
      setShowClasses(false);
      refetch();
      onClose();
    } catch (error) {
      handleSnackbarError("error", "Error  editing assignment  ");
    } finally {
      setLoading(false);
    }
  };

  const handleToggleClass = async (classItem) => {
    const isSelected = selectedClass.some(
      (selected) => selected.classId === classItem.classId
    );
    const url = `${endpoint_two}/api/v${api_version}/Assignment/RemoveClass-FromAssignment?classId=${classItem.classId}&assignmentId=${id}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adtoken}`,
    };
    if (isSelected) {
      try {
        const res = await axios.delete(url, { headers });
        // if (res && res.statusCode === 2) {
        setSelectedClass((prevSelected) =>
          prevSelected.filter(
            (selected) => selected.classId !== classItem.classId
          )
        );
        // }
      } catch (error) {
        console.error("Error removing class:", error);
      }
    } else {
      setSelectedClass((prevSelected) => [...prevSelected, classItem]);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {data?.isActivate ? (
          <Box sx={promptStyle}>
            <Box align="right" sx={{}}>
              <IconButton onClick={onClose}>
                <HighlightOffIcon />
              </IconButton>
            </Box>
            <Typography sx={{ fontFamily: "Gilroy-Medium" }}>
              This assignment has been activated, it cannot be edited
            </Typography>
          </Box>
        ) : (
          <Box sx={style}>
            <Box align="right">
              <IconButton onClick={onClose}>
                <HighlightOffIcon />
              </IconButton>
            </Box>
            <Box align="center" sx={{ mb: 3 }}>
              <Typography sx={{ fontFamily: "gilroy-bold", fontSize: "30px" }}>
                Edit Assignment
              </Typography>
              <Typography sx={{ fontFamily: "gilroy-regular" }}>
                {subject}
              </Typography>
            </Box>
            <Divider />
            <>
              {loading && <LoaderLine />}

              <Box sx={{ overflow: "scroll" }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} xs={12}>
                    <Box>
                      <InputLabel
                        sx={{
                          mb: 1.5,
                          mt: 1,
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#000",
                          fontFamily: "Gilroy-Medium",
                        }}
                        onClick={fetchSubjects}
                      >
                        Choose Subject
                      </InputLabel>

                      <Select
                        fullWidth
                        placeholder="dssss"
                        size="small"
                        value={subjectSelected}
                        onChange={handleChange}
                        sx={{
                          fontSize: "13px",
                          border: "0.5px solid #671E78",
                          fontFamily: "gilroy-regular",
                        }}
                        displayEmpty
                        inputProps={{
                          "aria-label": "Without label",
                          style: {
                            fontSize: "13px",
                            borderRadius: "10px",
                            fontFamily: "gilroy-regular",
                            // border: "1px solid #671E78",
                            // Replace with your desired font family
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          <em> Choose Subject</em>
                        </MenuItem>
                        {subjectLoader && (
                          <Box>
                            <Skeleton
                              variant="text"
                              animation="wave"
                              sx={{ fontSize: "2rem", mx: 2 }}
                            />
                            <Skeleton
                              variant="rounded"
                              height={60}
                              sx={{ mx: 2 }}
                            />
                            <Skeleton
                              variant="text"
                              animation="wave"
                              sx={{ fontSize: "1rem", mx: 2 }}
                            />
                          </Box>
                        )}
                        {subjects.length === 0 && !subjectLoader ? (
                          <Box>
                            <Typography
                              sx={{
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                                color: "#333",
                                fontFamily: "Gilroy-Medium",
                              }}
                            >
                              No Subject Available
                            </Typography>
                          </Box>
                        ) : (
                          subjects.length > 0 &&
                          subjects.map((subject) => (
                            <MenuItem
                              value={subject.subjectId}
                              onClick={(e) => {
                                setSubjectName(subject.subjectName);
                                setSubjectID(subject.subjectId);
                                // fetchClasses();
                                setSelectedClass([]);
                              }}
                              sx={{
                                fontFamily: "gilroy-regular",
                                fontSize: "13px",
                              }}
                            >
                              {subject.subjectName}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </Box>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Box position={"relative"}>
                      <button
                        className="mb-[2px] mt-[30px] text-black font-bold flex justify-between w-full border border-primary p-2 rounded"
                        onClick={() => setShowClasses(!showClasses)}
                      >
                        <span> Edit Class</span>
                        {showClasses ? <FaSortUp /> : <FaSortDown />}
                      </button>

                      <div
                        className={`flex flex-col bg-white  mt-5 gap-2 absolute w-full ${
                          showClasses ? "z-10 shadow-md" : "z-0 shadow-none"
                        } py-5 px-3`}
                      >
                        {showClasses &&
                          classes.map((each) => {
                            const isSelected = selectedClass.some(
                              (selected) => selected.classId === each.classId
                            );

                            return (
                              <div
                                key={each.classId}
                                className="flex justify-between items-center gap-3"
                              >
                                {`${each.className} ${
                                  each.classArm ? each.classArm : ""
                                }`}
                                <button onClick={() => handleToggleClass(each)}>
                                  {isSelected ? (
                                    <IoRemoveCircleOutline className="text-red-500 w-5 h-5" />
                                  ) : (
                                    <IoAddCircleOutline className="text-green-500 w-5 h-5" />
                                  )}
                                </button>
                              </div>
                            );
                          })}
                      </div>
                    </Box>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Box>
                      <InputLabel
                        sx={{
                          mb: 1.5,
                          mt: 1,
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#000",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        Title
                      </InputLabel>
                      <TextField
                        fullWidth
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        size="small"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "1px solid #671E78",
                              fontFamily: "gilroy-regular",
                            },
                          },
                        }}
                        InputProps={{
                          style: {
                            fontFamily: "Gilroy-regular",
                            fontSize: "13px",
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box>
                          <InputLabel
                            sx={{
                              mb: 1.5,
                              mt: 1.1,
                              fontSize: "12px",
                              fontWeight: "bolder",
                              color: "#000",
                              fontFamily: "Gilroy-Medium",
                            }}
                          >
                            Time
                          </InputLabel>
                          <TextField
                            disabled
                            fullWidth
                            value={new Date()}
                            size="small"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "1px solid #671E78",
                                },
                              },
                            }}
                            InputProps={{
                              style: {
                                fontFamily: "Gilroy-regular",
                                fontSize: "13px",
                              },
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box>
                          <InputLabel
                            sx={{
                              mb: 1.5,
                              mt: 1,
                              fontSize: "12px",
                              fontWeight: "bolder",
                              color: "#000",
                              fontFamily: "Gilroy-Medium",
                            }}
                          >
                            Score
                          </InputLabel>
                          <TextField
                            fullWidth
                            type="number"
                            size="small"
                            onChange={(e) => setScore(e.target.value)}
                            value={score}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "1px solid #671E78",
                                  fontFamily: "gilroy-regular",
                                },
                              },
                            }}
                            InputProps={{
                              style: {
                                fontFamily: "Gilroy-regular",
                                fontSize: "13px",
                              },
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Box>
                      <InputLabel
                        sx={{
                          mb: 1.5,
                          mt: 1,
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#000",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        Description
                      </InputLabel>
                      <TextField
                        fullWidth
                        multiline
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        rows={3}
                        size="small"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "1px solid #671E78",
                            },
                          },
                        }}
                        InputProps={{
                          style: {
                            fontFamily: "Gilroy-regular",
                            fontSize: "13px",
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <InputLabel
                      sx={{
                        fontFamily: "gilroy-regular",
                        color: "#333",
                        fontSize: "12px",
                        mb: 1,
                        mt: 3,
                      }}
                    >
                      Upload Picture(optional)
                    </InputLabel>
                    <TextField
                      type="file"
                      fullWidth
                      onChange={handleFileChange}
                      InputProps={{
                        style: {
                          fontFamily: "Gilroy-regular",
                          fontSize: "13px",
                          borderRadius: "5px",
                          offset: " 0.5px solid #671E78",
                          border: " 0.5px solid #671E78",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <button
                    disabled={!score || !description || !title}
                    onClick={handleCreateAssignment}
                    className="bg-primary w-full text-white p-3"
                  >
                    Update
                  </button>
                  {/* )} */}
                </Box>
              </Box>
            </>
          </Box>
        )}
      </Modal>
    </>
  );
};

export default AddQuestion;
