import React, { useState } from "react";
import {
  AccountSummaryWrapper,
  FormFlex,
  FormFlexDiv,
  ImageUploadContainer,
  ImageWrapper,
  TabContentWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
import colors from "../../assets/colors.json";
import PropTypes from "prop-types";
import avatar from "../../assets/Images/avatar.jpg";
import ImageUploader from "react-images-upload";

import { InputWrapper, AuthButton } from "../../pages/auth/Login.style";
import Select from "../select/select";
import { useMediaQuery } from "react-responsive";
import Upload from "../../assets/icons/upload";
import FormLoader from "../FormLoader";
const containerStyle = {
  background: "transparent",
  boxShadow: "none",
  padding: "0px",
  justifyContent: "flex-start",
  margin: "0",
};

const style = {
  background: "white",
  color: colors.primary,
};
const NOK = ({
  data,
  obj,
  setObj,
  action,
  showForm,
  formLoading,
  clearFields,
}) => {
  const isTab = useMediaQuery({ maxWidth: 992 });
  const [isReadOnly, setIsReadOnly] = useState(true);

  const optionsArr = [
    {
      id: 1,
      name: "Mr",
    },
    {
      id: 2,
      name: "Mrs",
    },
    {
      id: 3,
      name: "Miss",
    },
    {
      id: 3,
      name: "Dr",
    },
    {
      id: 3,
      name: "Prof",
    },
  ];

  const nokTypes = [
    {
      id: 1,
      name: "Spouse",
    },
    {
      id: 2,
      name: "Child",
    },
    {
      id: 3,
      name: "Relation",
    },
  ];
  const isNumber = (evt) => {
    // console.log(evt);
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  };

  const onDrop = (picture, url) => {
    setObj({ ...obj, profileImageurl: url[0] });
  };
  return (
    <TabContentWrapper>
      {showForm && (
        <>
          <FormFlex reverse={true}>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="Degree">Title</label>
                <Select
                  title="Title"
                  index="name"
                  selected={obj.title}
                  options={optionsArr}
                  setSelected={(val) => {
                    setObj({ ...obj, title: val.name });
                    // console.log(val);

                    // setOpt(val.name);
                  }}
                  disabled={isReadOnly}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="first_name">First Name</label>
                <input
                  type="text"
                  placeholder="First Name"
                  value={obj.firstName}
                  onChange={(e) =>
                    setObj({ ...obj, firstName: e.target.value })
                  }
                  readOnly
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="last_name">Last Name</label>
                <input
                  type="text"
                  placeholder="Last Name"
                  value={obj.surName}
                  onChange={(e) => setObj({ ...obj, surName: e.target.value })}
                  readOnly
                />
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <ImageUploadContainer>
                <ImageWrapper>
                  <img
                    src={
                      obj.profileImageurl.length > 0
                        ? obj.profileImageurl
                        : avatar
                    }
                    alt=""
                  />
                  {/* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      background: "white",
                      height: "fit-content",
                      justifyContent: "center",
                    }}
                  >
                    <Upload />
                    <ImageUploader
                      withIcon={false}
                      onChange={onDrop}
                      buttonText="Upload"
                      label=""
                      input
                      imgExtension={[".jpg", ".png"]}
                      maxFileSize={5242880}
                      singleImage={true}
                      buttonStyles={style}
                      fileContainerStyle={{
                        ...containerStyle,
                      }}
                    />
                  </div> */}
                </ImageWrapper>
              </ImageUploadContainer>
            </FormFlexDiv>
          </FormFlex>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="Degree">Type</label>
                <Select
                  title="Type"
                  index="name"
                  selected={obj.nextKinType}
                  options={nokTypes}
                  setSelected={(val) => {
                    setObj({ ...obj, nextKinType: val.name });
                    // console.log(val);

                    // setOpt(val.name);
                  }}
                  disabled={isReadOnly}
                />
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="Email">NOK Email</label>
                <input
                  type="text"
                  placeholder="Email"
                  value={obj.email}
                  onChange={(e) => setObj({ ...obj, email: e.target.value })}
                  readOnly
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          <FormFlex reverse={true}>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="Address">Address</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="4"
                  value={obj.address}
                  onChange={(e) => setObj({ ...obj, address: e.target.value })}
                  readOnly
                ></textarea>
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="phone-number"> phone Number</label>
                <input
                  type="text"
                  onKeyDown={(e) => isNumber(e)}
                  placeholder="Employer's Phone Number"
                  value={obj.phoneNumber}
                  onChange={(e) =>
                    setObj({ ...obj, phoneNumber: e.target.value })
                  }
                  readOnly
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Relationship">Relationship</label>
                <input
                  type="text"
                  placeholder="Relationship"
                  value={obj.relationShip}
                  onChange={(e) =>
                    setObj({ ...obj, relationShip: e.target.value })
                  }
                  readOnly
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: isTab ? "column" : "row",
            }}
          >
            <AuthButton
              width={isTab ? "100%" : "30%"}
              onClick={action}
              disabled={formLoading}
            >
              {formLoading ? (
                <FormLoader />
              ) : (
                <div>
                  <span> {obj.id ? "Edit" : "Add"} Next of Kin</span>
                </div>
              )}
            </AuthButton>

            {obj.id && (
              <AuthButton
                onClick={clearFields}
                width={isTab ? "100%" : "30%"}
                bg="red"
                style={{
                  border: "1px solid red",
                  marginLeft: isTab ? "0px" : "10px",
                  marginTop: isTab ? "15px" : "0px",
                }}
              >
                Clear
              </AuthButton>
            )}
          </div> */}
        </>
      )}

      {/* <TabContainer></TabContainer> */}
      <AccountSummaryWrapper
        style={{
          border: `1px solid ${colors.primary}`,
          paddingTop: "0px",
          borderRadius: "10px",
          marginTop: "10px",
        }}
      >
        <table>
          <tr className="table-head" style={{ color: "black" }}>
            <th>Name</th>
            <th>Next of Kin Type</th>
            <th>Relationship</th>
            <th>Phone No</th>
            <th></th>
          </tr>
          {data.length > 0 ? (
            data.map((item, index) => (
              <tr key={index}>
                <td>{`${item.title} ${item.firstName} ${item.surName}`}</td>
                <td>{item.nextKinType}</td>
                <td>{item.relationShip}</td>
                <td>{item.phoneNumber}</td>
                <td>
                  <div
                    style={{
                      width: "100%",
                      display: "inline-flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <button
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        padding: "0.3rem 0.6rem",
                        backgroundColor: "transparent",
                        borderColor: colors.primary,
                        borderWidth: "1px",
                        borderRadius: "5px",
                      }}
                    >
                      Delete
                    </button>
                    <button
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        padding: "0.3rem 0.6rem",
                        backgroundColor: "transparent",
                        borderColor: colors.primary,
                        borderWidth: "1px",
                        borderRadius: "5px",
                      }}
                      onClick={() => {
                        setObj(Object.assign({}, item));
                      }}
                    >
                      Edit
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} style={{ textAlign: "center" }}>
                No Next Of Kin For Staff
              </td>
            </tr>
          )}
        </table>
      </AccountSummaryWrapper>
    </TabContentWrapper>
  );
};

NOK.propTypes = {
  data: PropTypes.array,
  showForm: PropTypes.bool,
};

NOK.defaultProps = {
  data: [],
  showForm: false,
};

export default NOK;
