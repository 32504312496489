import styled from "styled-components";
import colors from "../../assets/colors.json";

export const AuthWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height: 100vh;

  /* large screen */
  @media (max-width: 992px) {
    flex-direction: column;
  }
  /*  */
  @media (max-width: 768px) {
  }

  @media (max-width: 576px) {
  }
`;

export const AuthLeftWrapper = styled.div`
  padding-top: 45px !important;
  width: 100%;
  padding: 0px 6rem 0rem 11rem;
  @media (max-width: 992px) {
    width: 100%;
    min-height: 100vh;
  }

  @media (max-width: 768px) {
    padding: 0px 4rem 0rem 5rem;
  }

  @media (max-width: 576px) {
    padding: 0px 2rem 0rem 2rem;
  }
`;

export const LeftWrapperContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > img {
    width: 90px;
    height: 70px;
  }

  & > div {
    font-family: "Gilroy-Bold";
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
  }

  @media (max-width: 992px) {
    justify-content: center;
  }
`;

export const HeaderWrapper = styled.div`
  margin-top: 2rem;
`;

export const BigText = styled.p`
  font-family: "Gilroy-Bold";
  font-size: 48px;
  line-height: 50px;
  color: #000000;
  margin-top: 1.2rem;
  /* phone screen */
  @media (max-width: 576px) {
    font-size: 35px;
    line-height: 35px;
  }
`;
export const MediumText = styled.p`
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  margin-top: 0.3rem;

  opacity: 0.7;

  @media (max-width: 576px) {
    font-size: 15px;
  }
`;
export const RegularText = styled.p`
  font-family: "Gilroy-Bold";
  font-size: 16px;
  line-height: 19px;
  color: #2e4138;
  margin-top: 1.2rem;
`;

export const SmallText = styled.p`
  margin-top: 0.3rem;
  font-weight: 600;
  font-size: 14px;
  font-family: "Gilroy-Regular";
  /* color: ${colors.lightGray}; */
  color: ${(props) => props.color || colors.gray} !important;
`;

// comeback for input styled components

export const InputWrapper = styled.div`
  padding-top: ${(props) => props.pt || "15px"} !important;
  & > label {
    font-family: "Gilroy-Bold";
    font-size: 16px;
    line-height: 19px;
    color: #2e4138;
  }
`;

export const LabelWrapper = styled.label`
  margin-top: 0.3rem;
  color: ${colors.lightGray};
  & > input {
    visibility: hidden;
    display: block;
    height: 0;
    width: 0;
    cursor: pointer;
    position: absolute;
    overflow: hidden;
  }

  & > span {
    height: 15px;
    width: 15px;
    border: 1.6px solid ${colors.primary};
    display: inline-block;
    border-radius: 50px;
    cursor: pointer;
  }

  & > input:checked + span {
    background: ${colors.primary};
  }
`;

export const AuthRightWrapper = styled.div`
  position: relative;
  padding: 40px 3rem 30px 4rem;
  // border-radius: 30px 0 0 30px;
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(
    to top,
    #671e78,
    #895096,
    #aa7fb4,
    #ccafd2,
    #eee0f1
  );
  background-image: url(${(props) => props.bgUrl});
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 992px) {
    display: none;
  }
`;

export const AuthRightOverlay = styled.div`
  z-index: 1;
  position: absolute;
  // border-radius: 30px 0 0 30px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
  background-image: linear-gradient(
    to top,
    #671e78,
    #895096,
    #aa7fb4,
    #ccafd2,
    #eee0f1
  );
`;

export const RightLogoWrapper = styled.div`
  display: flex;
  justify-content: end;
  & > img {
    width: 90px;
    height: 70px;
  }
`;

export const RigthBigText = styled.div`
  font-family: "Gilroy-Bold";
  font-size: 40px;
  line-height: 60px;
  color: #ffffff;
`;

export const CarouselWrapper = styled.div`
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > i {
    color: white;
  }
`;

export const CarouselDots = styled.div`
  cursor: pointer;
  height: 15px;
  width: 15px;
  background: ${(props) => (props.indexSelected ? "white" : "black")};
  opacity: ${(props) => (props.indexSelected ? "1" : "0.2")};
  border: 1px solid;
  border-color: ${(props) =>
    props.indexSelected ? "white" : "black"} !important;
  border-radius: 50%;
  margin-right: 5px;
`;

export const ButtonFlex = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-direction: column-reverse;
  }
`;

export const AuthButton = styled.button`
  margin-top: ${(props) => props.mt || "2rem"} !important;
  border-radius: 10px;
  min-width: ${(props) => props.width || "200px"} !important;
  padding: ${(props) => props.padding || "7px"};
  background: ${(props) => props.bg || colors.primary};
  color: ${(props) => props.color || "white"} !important;
  cursor: pointer;
  line-height: 2.125rem !important;
  font-family: "Gilroy-Bold";
  font-size: 16px;
  line-height: 21px;
  outline: 0px;
  border-width: 1px;
  border: 1px solid ${(props) => props.bg || colors.primary};
  outline: none !important;

  &:disabled {
    cursor: not-allowed;
    background: ${colors.gray};
    color: white !important;
    font-family: "Gilroy-Bold";
    font-size: 18px;
    line-height: 21px;
    border-color: ${colors.lightGray} !important;
    outline: none !important;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    & > span {
      padding-left: ${(props) => props.pl || "15px"};
    }
  }
`;

export const DeleteButton = styled(AuthButton)`
  background: red;
  border: 1px solid red !important;

  &:disabled {
    background: red;
    opacity: 0.4;
  }
`;
